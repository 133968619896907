import colors from 'utils/colors';
import commonStyles from 'utils/commonStyles';

const styles = (theme) => ({
  root: {
    marginBottom: '50px',
  },
  list: {
    maxWidth: '800px',
    maxHeight: '400px',
    overflowY: 'scroll',
  },
  header: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    padding: '16px',
    maxWidth: '800px',
    borderRadius: '10px',
  },
  headerText: {
    color: 'white',
    fontWeight: 'bold',
  },
  listRow: {
    backgroundColor: colors.white,
    boxShadow: theme.shadows[2],
    padding: '0px 16px',
    borderRadius: '10px',
    marginBottom: '4px',
    marginLeft: '8px',
    marginRight: '8px',
  },
  alignCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonGroup: {
    maxWidth: '800px',
  },
  ...commonStyles,
});

export default styles;
