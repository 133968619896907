/* eslint-disable no-param-reassign */
/* eslint-disable import/no-unresolved */
import { createAction, createReducer } from 'utils/reducer';

// Local constants
const PATH = 'paymentStatusConfig/';

const DISABLE_ERRORS = `${PATH}DISABLE_ERRORS`;
const RESET = `${PATH}RESET`;
const CHANGE_CONTROLS = `${PATH}CHANGE_CONTROLS`;

// Global constants for saga
export const PAYMENT_STATUS_CONFIG = {
  GET_STATE_FROM_API: `${PATH}GET_STATE_FROM_API`,
  GET_STATE_FROM_API_SUCCESS: `${PATH}GET_STATE_FROM_API_SUCCESS`,
  GET_STATE_FROM_API_FAIL: `${PATH}GET_STATE_FROM_API_FAIL`,
  SUBMIT_SETTINGS: `${PATH}SUBMIT_SETTINGS`,
  SUBMIT_SETTINGS_SUCCESS: `${PATH}SUBMIT_SETTINGS_SUCCESS`,
  SUBMIT_SETTINGS_FAIL: `${PATH}SUBMIT_SETTINGS_FAIL`,
};

// actions
export const paymentStatusConfigActions = {
  disableErrors: createAction(DISABLE_ERRORS),
  changeControls: createAction(CHANGE_CONTROLS, 'controls', 'invalidControls'),
  // api
  getStateFromApi: createAction(PAYMENT_STATUS_CONFIG.GET_STATE_FROM_API),
  getStateFromApiSuccess: createAction(PAYMENT_STATUS_CONFIG.GET_STATE_FROM_API_SUCCESS, 'payload'),
  getStateFromApiFail: createAction(PAYMENT_STATUS_CONFIG.GET_STATE_FROM_API_FAIL, 'payload'),
  submitSettings: createAction(PAYMENT_STATUS_CONFIG.SUBMIT_SETTINGS),
  submitSettingsSuccess: createAction(PAYMENT_STATUS_CONFIG.SUBMIT_SETTINGS_SUCCESS, 'payload'),
  submitSettingsFail: createAction(PAYMENT_STATUS_CONFIG.SUBMIT_SETTINGS_FAIL, 'payload'),
};

const defaultControls = {
  name: '',
  fields: {
    brand: true,
    model: true,
    fleetName: true,
    monthDeadline: true,
    currency: true,
    totalValue: true,
    totalDays: true,
    additionals: true,
    discounts: true,
  },
};

const initialState = {
  error: false,
  errorMsg: '',
  controls: {
    ...defaultControls,
  },
};

const paymentStatusConfig = createReducer(initialState, {
  [DISABLE_ERRORS]: (state) => ({
    ...state,
    error: false,
    errorMsg: '',
  }),
  [RESET]: (state) => ({
    ...state,
    controls: { ...defaultControls },
  }),
  [CHANGE_CONTROLS]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      ...action.controls,
    },
  }),
  [PAYMENT_STATUS_CONFIG.GET_STATE_FROM_API_SUCCESS]: (state, action) => {
    const {
      brand, model, monthDeadline, currency, totalValue,
      additionals, discounts, fleetName, totalDays,
    } = action.payload.PsSettings;
    return {
      ...state,
      controls: {
        ...state.controls,
        fields: {
          ...state.controls.fields,
          brand,
          model,
          fleetName,
          monthDeadline,
          currency,
          totalValue,
          totalDays,
          additionals,
          discounts,
        },
      },
    };
  },
});

export default paymentStatusConfig;
