import React, { useCallback, useEffect, useMemo } from 'react';

// Material
import { withStyles } from '@material-ui/core';

// Project
import { arrayOfObjectsToObject, composedComponent } from 'utils/functions';
import ScreenTitle from 'commons/components/ScreenTitle';
import useForm from 'utils/hooks/useForm';
import { clientSettingsActions } from './reducer';
import saga from './saga';

// Components

// Utils

// Styles
import styles from './styles';
import ClientSettingsAdminList from './components/ClientSettingsAdminList';
import ClientSettingsSubmitButton from './components/ClientSettingsSubmitButton';
import ClientSettingsButtonGroup from './components/ClientSettingsButtonGroup';

const ClientSettings = (props) => {
  const {
    classes, controls, actions, objects,
  } = props;

  useEffect(() => {
    actions.getStateFromApi();
  }, []);

  const adminList = useMemo(() => Object.values(objects.users).map((user) => ({
    adminId: user.id,
    name: user.name,
    checked: !!controls.contractManagers[user.id]?.checked,
    isMainManager: !!controls.contractManagers[user.id]?.isMainManager,

  })), [objects.users, controls.contractManagers]);

  const { onSubmit, errors } = useForm(controls, null, {
    onChange: () => {},
    validations: {
      contractManagers: [
        {
          check: (value) => Object.values(value).some((item) => item.checked),
          message: 'No se ha seleccionado ningún ejecutivo.',
        },
        {
          check: (value) => Object.values(value).some((item) => item.isMainManager),
          message: 'Debe seleccionar un ejecutivo principal.',
        },
      ],
    },
  });

  const handleChangeCheckBox = useCallback((e) => {
    const { name, checked } = e.target;
    const { contractManagers } = controls;
    const key = Number(name);
    const noneIsChecked = Object.values(contractManagers).every((cm) => !cm.checked);
    const cloneManagers = {
      ...contractManagers,
      [key]: {
        ...contractManagers[key],
        ...(!checked ? { isMainManager: false } : {}),
        ...(checked && noneIsChecked ? { isMainManager: true } : {}),
        checked,
      },
    };
    actions.changeControls({ contractManagers: cloneManagers });
  }, [controls.contractManagers]);

  const handleChangeRadio = useCallback((e) => {
    const { name, checked } = e.target;
    const { contractManagers } = controls;
    const key = Number(name);
    const uncheckedManagers = Object.values(contractManagers)
      .map((cm) => ({ [cm.userId]: { ...cm, isMainManager: false } }));
    const cloneManagers = {
      ...arrayOfObjectsToObject(uncheckedManagers),
      [key]: { ...contractManagers[key], isMainManager: checked },
    };
    actions.changeControls({ contractManagers: cloneManagers });
  }, [controls.contractManagers]);

  const handleSubmit = useCallback(() => {
    onSubmit(() => actions.submitClientSettings());
  }, [onSubmit, actions]);

  return (
    <>
      <ScreenTitle title="SELECCIÓN DE EJECUTIVO" />
      <ClientSettingsAdminList
        classes={classes}
        adminList={adminList}
        handleChangeCheckBox={handleChangeCheckBox}
        handleChangeRadio={handleChangeRadio}
        errors={errors}
      />
      <ClientSettingsButtonGroup classes={classes}>
        <ClientSettingsSubmitButton onClick={handleSubmit} />
      </ClientSettingsButtonGroup>
    </>
  );
};

export default composedComponent(ClientSettings, saga, {
  saga: 'sagaClientSettings',
  middlewares: [withStyles(styles)],
  states: [
    'app.user',
    'app.objects',
    'clientSettings.controls',
    'clientSettings.invalidControls'],
  actions: [clientSettingsActions],
});
