import React, { useRef } from 'react';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Grid from '@material-ui/core/Grid';

const UploadButton = (props) => {
  const fileInput = useRef(null);
  const { onChange } = props;

  const handleFileChange = (event) => {
    const uploadFile = event.target.files.item(0);
    onChange(uploadFile);
  };

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <Grid item sm={6} xs={12}>
      <input
        style={{ display: 'none' }}
        type="file"
        ref={fileInput}
        onChange={handleFileChange}
      />
      <Button
        variant="contained"
        color="primary"
        fullWidth
        startIcon={<CloudUploadIcon />}
        component="span"
        onClick={() => fileInput.current.click()}
      >
        Seleccionar Archivo
      </Button>
    </Grid>
  );
};

export default UploadButton;
