import { Box, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import AddCircleIcon from '@material-ui/icons/AddCircle';

import styles from './styles';

const useStyles = makeStyles(styles);

const NotFleetMessage = (props) => {
  const classes = useStyles(props);
  return (
    <Paper elevation={3} className={classes.root}>
      <Typography
        component="div"
        color="primary"
        className={classes.container}
      >
        <Box
          fontWeight="fontWeightMedium"
          textAlign="justify"
          color="primary"
        >
          Para clasificar sus vehículos, primero seleccione una de las flotas
          dentro del selector
          <Box display="inline" fontWeight={900}>
            {' Flota'}
          </Box>
          .
        </Box>
        <Box
          display="flex"
          flexDirection="columns"
          alignItems="center"
          justifyContent="start"
        >
          <AddCircleIcon color="primary" fontSize="large" />
          <Box className={classes.box}>
            Permite la creación de nuevas flotas en caso de que no existan disponibles.
          </Box>
        </Box>

      </Typography>
    </Paper>
  );
};

export default NotFleetMessage;
