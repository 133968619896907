import { takeLatest, spawn, put } from 'redux-saga/effects';

import {
  apiSuccess, destroy, get, post, update,
} from 'utils/api';
import * as APP from 'commons/reducer';
import { HOME_MARKETING } from '../../HomeMarketing/reducer';

function* getNewsletterHistory() {
  yield takeLatest(HOME_MARKETING.GET_NEWSLETTER_HISTORY, function* () {
    const path = 'api/v1/marketing-home/newsletter';
    const response = yield get(path);
    if (!response.error) {
      yield put(apiSuccess(HOME_MARKETING.GET_NEWSLETTER_HISTORY_SUCCESS, response));
    } else {
      yield put(apiSuccess(
        APP.SET_ERROR,
        { msg: 'Error al obtener el historial de newsletter, intetalo de nuevo más tarde' },
      ));
    }
  });
}

function* addNewsletterHistory() {
  yield takeLatest(HOME_MARKETING.ADD_NEWSLETTER_HISTORY, function* (action) {
    const { setSelectedId, ...payload } = action.payload;
    const path = 'api/v1/marketing-home/newsletter';
    const response = yield post(path, payload);
    if (!response.error) {
      const createdNewsletterId = response.id;
      setSelectedId(createdNewsletterId);
      yield put(apiSuccess(HOME_MARKETING.ADD_NEWSLETTER_HISTORY_SUCCESS, response));
      yield put(apiSuccess(
        APP.SET_SUCCESS,
        { msg: 'Newsletter agregado correctamente' },
      ));
    } else {
      yield put(apiSuccess(
        APP.SET_ERROR,
        { msg: 'Error al agregar el historial de newsletter, intetalo de nuevo más tarde' },
      ));
    }
  });
}

function* updateNewsletterHistory() {
  yield takeLatest(HOME_MARKETING.UPDATE_NEWSLETTER_HISTORY, function* (action) {
    const {
      id: newsletterId, title, url, handleClose,
    } = action.payload;
    const path = `api/v1/marketing-home/newsletter/${newsletterId}`;
    yield put(APP.appActions.setLoader('edit-newsletter', true));
    const response = yield update(path, { title, url });
    if (!response.error) {
      handleClose();
      yield put(APP.appActions.setLoader('edit-newsletter', false));
      yield put(apiSuccess(HOME_MARKETING.UPDATE_NEWSLETTER_HISTORY_SUCCESS, response));
      yield put(apiSuccess(
        APP.SET_SUCCESS,
        { msg: 'Newsletter actualizado correctamente' },
      ));
    } else {
      yield put(APP.appActions.setLoader('edit-newsletter', false));
      yield put(apiSuccess(
        APP.SET_ERROR,
        { msg: 'Error al editar el historial de newsletter, intetalo de nuevo más tarde' },
      ));
    }
  });
}

function* removeNewsletterHistory() {
  yield takeLatest(HOME_MARKETING.REMOVE_NEWSLETTER_HISTORY, function* (action) {
    const { newsletterId } = action.payload;
    const path = `api/v1/marketing-home/newsletter/${newsletterId}`;
    const response = yield destroy(path);
    if (!response.error) {
      yield put(apiSuccess(HOME_MARKETING.REMOVE_NEWSLETTER_HISTORY_SUCCESS, { newsletterId }));
      yield put(apiSuccess(
        APP.SET_SUCCESS,
        { msg: 'Newsletter eliminado correctamente' },
      ));
    } else {
      yield put(apiSuccess(
        APP.SET_ERROR,
        { msg: 'Error al eliminar el historial de newsletter, intetalo de nuevo más tarde' },
      ));
    }
  });
}

export default function* root() {
  yield spawn(getNewsletterHistory);
  yield spawn(addNewsletterHistory);
  yield spawn(updateNewsletterHistory);
  yield spawn(removeNewsletterHistory);
}
