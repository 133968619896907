import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { Title, Table } from '../../../../utils/skeletons';

const useStyles = makeStyles((theme) => ({
  circle: {
    width: '140px',
    height: '140px',
    [theme.breakpoints.down('sm')]: {
      width: '100px',
      height: '100px',
    },
  },
}));

const DashboardSkeleton = () => {
  const classes = useStyles();

  const Circle = () => (
    <Skeleton
      variant="circle"
      className={classes.circle}
    />
  );

  const Rect = () => (
    <Skeleton variant="rect" width="100%" height={50} style={{ marginTop: '48px' }} />
  );

  const Bar = () => {
    const bars = [150, 300, 200, 120, 180, 230];
    return (
      <Grid
        container
        alignItems="flex-end"
      >
        {bars.map((b, index) => {
          const k = `${b}-${index}`;
          return (
            <Grid key={k} item xs={2}>
              <Skeleton
                variant="rect"
                width={50}
                height={b}
              />
            </Grid>
          );
        })}
      </Grid>
    );
  };

  return (
    <Grid
      container
      spacing={1}
    >
      <Grid item xs={12}>
        <Title width={170} />
      </Grid>
      <Grid item xs={6} md={2}>
        <Rect />
      </Grid>
      <Grid item xs={6} md={2}>
        <Rect />
      </Grid>
      <Grid item xs={12} align="center" style={{ marginTop: '32px' }}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Grid container>
              <Grid item xs={12} sm={4}>
                <Circle />
              </Grid>
              <Grid item xs={6} sm={4}>
                <Circle />
              </Grid>
              <Grid item xs={6} sm={4}>
                <Circle />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container>
              <Grid item xs={12} sm={4}>
                <Circle />
              </Grid>
              <Grid item xs={6} sm={4}>
                <Circle />
              </Grid>
              <Grid item xs={6} sm={4}>
                <Circle />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </Grid>
      <Grid item xs={12} md={6} style={{ marginTop: '64px' }}>
        <Bar />
      </Grid>
      <Grid item xs={12} md={6} style={{ marginTop: '64px' }}>
        <Table cells={6} />
      </Grid>

    </Grid>
  );
};

export default DashboardSkeleton;
