import { alpha } from '@material-ui/core/styles/colorManipulator';

const styles = (theme) => ({

  fileName: {
    '& .Mui-disabled': {
      color: theme.palette.primary.main,
    },
  },

  back1: {
    backgroundColor: alpha(theme.palette.primary.main, 0.03),
    marginBottom: theme.spacing(1),
    borderRadius: 8,
  },

  back2: {
    backgroundColor: alpha(theme.palette.primary.main, 0.08),
    marginBottom: theme.spacing(1),
    borderRadius: 8,
  },
  grid1: {
    paddingTop: 16,
    paddingBottom: 16,
  },
  grid2: {
    padding: 12,
  },
});

export default styles;
