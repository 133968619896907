/* eslint-disable no-param-reassign */
/* eslint-disable import/no-unresolved */
import { createAction, createReducer } from 'utils/reducer';

// Local constants
const PATH = 'paymentStatusSections/';

const DISABLE_ERRORS = `${PATH}DISABLE_ERRORS`;
const RESET = `${PATH}RESET`;
const CHANGE_CONTROLS = `${PATH}CHANGE_CONTROLS`;

// Global constants for saga
export const PAYMENT_STATUS_SECTIONS = {
  GET_STATE_FROM_API: `${PATH}GET_STATE_FROM_API`,
  GET_STATE_FROM_API_SUCCESS: `${PATH}GET_STATE_FROM_API_SUCCESS`,
  GET_STATE_FROM_API_FAIL: `${PATH}GET_STATE_FROM_API_FAIL`,
  SUBMIT_SETTINGS: `${PATH}SUBMIT_SETTINGS`,
  SUBMIT_SETTINGS_SUCCESS: `${PATH}SUBMIT_SETTINGS_SUCCESS`,
  SUBMIT_SETTINGS_FAIL: `${PATH}SUBMIT_SETTINGS_FAIL`,
  CHANGE_CONTROLS: `${PATH}CHANGE_CONTROLS`,
};

// actions
export const paymentStatusSectionsActions = {
  disableErrors: createAction(DISABLE_ERRORS),
  changeControls: createAction(CHANGE_CONTROLS, 'controls', 'invalidControls'),
  // api
  changePsSectionsControls: createAction(PAYMENT_STATUS_SECTIONS.CHANGE_CONTROLS, 'key', 'value'),
  getStateFromApi: createAction(PAYMENT_STATUS_SECTIONS.GET_STATE_FROM_API),
  getStateFromApiSuccess: createAction(PAYMENT_STATUS_SECTIONS.SUBMIT_SETTINGS_SUCCESS, 'payload'),
  getStateFromApiFail: createAction(PAYMENT_STATUS_SECTIONS.GET_STATE_FROM_API_FAIL, 'payload'),
  submitSettings: createAction(PAYMENT_STATUS_SECTIONS.SUBMIT_SETTINGS),
  submitSettingsSuccess: createAction(PAYMENT_STATUS_SECTIONS.SUBMIT_SETTINGS_SUCCESS, 'payload'),
  submitSettingsFail: createAction(PAYMENT_STATUS_SECTIONS.SUBMIT_SETTINGS_FAIL, 'payload'),
};

const initialState = {
  error: false,
  errorMsg: '',
  controls: {
    socialReasonId: '',
    holdingId: '',
    psSections: [],
    pendings: false,
    additionals: false,
    extras: false,
  },
};

const paymentStatusSections = createReducer(initialState, {
  [DISABLE_ERRORS]: (state) => ({
    ...state,
    error: false,
    errorMsg: '',
  }),
  [RESET]: (state) => ({
    ...state,
    controls: { ...initialState.controls },
  }),
  [CHANGE_CONTROLS]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      ...action.controls,
    },
  }),
  [PAYMENT_STATUS_SECTIONS.SUBMIT_SETTINGS_SUCCESS]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      psSections: action.payload.PsSections,
    },
  }),
  [PAYMENT_STATUS_SECTIONS.CHANGE_CONTROLS]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      [action.key]: action.value,
    },
  }),
});

export default paymentStatusSections;
