import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import UploadButton from './components/UploadButton';
import { composedComponent } from '../../utils/functions';
import saga from './saga/file.saga';
import { fileActions } from './reducer/uploadFile.reducer';

const Upload = (props) => {
  const [file, setFile] = useState(null);
  const { actions } = props;

  const handleOnSubmit = (event) => {
    event.preventDefault();
    actions.startFileUpload(file);
  };

  const onChange = (File) => {
    setFile(File);
  };

  return (
    <Grid item sm={6} xs={12}>
      <Typography>
        <h1>Subir Archivos</h1>
      </Typography>
      <form onSubmit={handleOnSubmit}>
        <UploadButton actions={actions} onChange={onChange} />
        {
          file?.name && (<Typography variant="caption"><p>{file.name}</p></Typography>)
        }
        <Button type="submit" variant="contained" color="primary" style={{ marginTop: 23 }}>
          Subir archivo
        </Button>
      </form>
    </Grid>
  );
};

export default composedComponent(Upload, saga, {
  saga: 'sagaFile',
  states: [
    'file',
  ],
  actions: [fileActions],
});
