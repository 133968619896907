import {
  put,
  spawn,
  takeLatest,
} from 'redux-saga/effects';
import { apiSuccess, get } from 'utils/api';
import { DOCUMENT } from '../reducer';
import * as APP from '../../../commons/reducer';

function* fetchLicensePlates() {
  yield takeLatest(DOCUMENT.FETCH_LICENSE_PLATES, function* (action) {
    const { socialReasonId } = action.payload;
    const response = yield get(`api/v1/document/plates/${socialReasonId}`);

    if (!response.error) {
      yield put(apiSuccess(DOCUMENT.FETCH_LICENSE_PLATES_SUCCESS, response.vehicles));
    } else {
      yield put(apiSuccess(DOCUMENT.FETCH_LICENSE_PLATES_ERROR));
      yield put(apiSuccess(APP.SET_ERROR, {
        msg: response.statusCode === 403 ? 'Acceso denegado' : 'Error al obtener los datos',
      }));
    }
  });
}

function* fetchSocialReasons() {
  yield takeLatest(DOCUMENT.FETCH_SOCIAL_REASON, function* (action) {
    const {
      holdingId, page, size, searchValue,
    } = action.payload;
    const response = yield get(`api/v1/document/socialReason/${holdingId}?page=${page}&size=${size}&search=${searchValue}`);

    if (!response.error) {
      yield put(apiSuccess(DOCUMENT.FETCH_SOCIAL_REASON_SUCCESS, response));
    } else {
      yield put(apiSuccess(DOCUMENT.FETCH_SOCIAL_REASON_ERROR));
      yield put(apiSuccess(APP.SET_ERROR, {
        msg: response.statusCode === 403 ? 'Acceso denegado' : 'Error al obtener los datos',
      }));
    }
  });
}

function* fetchZips() {
  yield takeLatest(DOCUMENT.FETCH_ZIP, function* (action) {
    const { socialReasonId } = action.payload;
    const response = yield get(`api/v1/document/zip/${socialReasonId}`);

    if (!response.error) {
      yield put(apiSuccess(DOCUMENT.FETCH_ZIP_SUCCESS, response.downloadZip));
    } else {
      yield put(apiSuccess(DOCUMENT.FETCH_ZIP_ERROR));
      yield put(apiSuccess(APP.SET_ERROR, {
        msg: response.statusCode === 403 ? 'Acceso denegado' : 'Error al obtener los datos',
      }));
    }
  });
}

function* fetchZipUrl() {
  yield takeLatest(DOCUMENT.FETCH_ZIP_URL, function* (action) {
    const { zipId } = action.payload;
    const response = yield get(`api/v1/document/zip/url/${zipId}`);
    if (response) {
      yield put(apiSuccess(DOCUMENT.FETCH_ZIP_URL_SUCCESS, response.url));
    } else {
      yield put(apiSuccess(DOCUMENT.FETCH_ZIP_URL_ERROR));
      yield put(apiSuccess(APP.SET_ERROR, {
        msg: response.statusCode === 403 ? 'Acceso denegado' : 'Error al obtener los datos',
      }));
    }
  });
}

export default function* root() {
  yield spawn(fetchLicensePlates);
  yield spawn(fetchSocialReasons);
  yield spawn(fetchZips);
  yield spawn(fetchZipUrl);
}
