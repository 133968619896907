import React, { useEffect, useState } from 'react';
import { composedComponent } from 'utils/functions';
import { appActions } from 'commons/reducer';
import { Box, Grid } from '@material-ui/core';
import { useParams } from 'react-router-dom';

import ScreenTitle from 'commons/components/ScreenTitle';
import CustomFloatButton from 'commons/components/CustomFloatButton';
import HoldingMaintainerSkeleton from 'screens/HoldingMaintainer/components/Skeleton';
import { holdingsActions } from 'screens/HoldingMaintainer/reducer';
import saga from './saga';
import { socialReasonActions } from './reducer';
import BackButton from './components/BackButton';
import HoldingInfoCard from './components/HoldingInfoCard';
import Filter from './components/Filter';
import SocialReasonList from './components/SocialReasonList';
import ActionDialog from './components/ActionDialog';

function HoldingMaintainerDetail({
  controls, actions, pagination, loading, holding, filteredSocialReasons: socialReasons, holdings,
}) {
  const [isEditHoldingDialogOpen, setIsEditHoldingDialogOpen] = useState(false);
  const [isCreateSRDialogOpen, setIsCreateSRDialogOpen] = useState(false);
  const [isEditSRDialogOpen, setIsEditSRDialogOpen] = useState(false);
  const [selectedSR, setSelectedSR] = useState({});
  const handleEditHolding = () => setIsEditHoldingDialogOpen(true);
  const handleCreateSocialReason = () => setIsCreateSRDialogOpen(true);
  const handleEditSocialReason = (socialReason) => {
    setSelectedSR(socialReason);
    setIsEditSRDialogOpen(true);
  };
  const { id: holdingId } = useParams();
  const activeHoldings = holdings ? Object.values(holdings).filter((h) => h.isActive) : [];

  const handleFilter = () => {
    actions.applyFilter();
  };
  const handleClearFilter = () => {
    actions.resetFilter();
  };
  const handleUpdateHolding = (handleClose, hid, name, rut, isActive) => {
    actions.updateHolding({
      name, rut, holdingId: hid, isActive, handleClose,
    });
  };
  const handleAddSocialReason = (handleClose, name, rut) => {
    const payload = {
      name, rut, holdingId: holding.id, holdingRut: holding.rut, handleClose,
    };
    actions.addSocialReason(payload);
  };
  const handleUpdateSocialReason = (handleClose, socialReasonId, name, rut, isActive) => {
    actions.updateSocialReason({
      name, rut, socialReasonId, isActive, handleClose,
    });
  };

  useEffect(() => {
    handleClearFilter();
  }, []);

  useEffect(() => {
    actions.getSocialReasons(holdingId);
  }, [holdingId]);

  if (loading) {
    return <HoldingMaintainerSkeleton />;
  }

  return (
    <>
      <BackButton />
      <Box display="flex" justifyContent="space-between">
        <ScreenTitle title="DETALLE HOLDING" />
      </Box>
      <Grid container>
        <Grid item xs={12}>
          <HoldingInfoCard
            onReassign={actions.reassignHoldingSrs}
            onEditHolding={handleEditHolding}
            holding={holding}
            holdings={activeHoldings}
          />
        </Grid>
      </Grid>
      <Filter
        actions={actions}
        controls={controls}
        socialReasons={socialReasons}
        handleFilter={handleFilter}
        handleClearFilter={handleClearFilter}
      />
      <SocialReasonList
        socialReasons={socialReasons}
        actions={actions}
        controls={controls}
        isLoading={false}
        total={socialReasons.length}
        pagination={pagination}
        onEditSocialReason={handleEditSocialReason}
      />
      <ActionDialog
        title="Agregar razón social"
        isOpen={isCreateSRDialogOpen}
        onClose={() => setIsCreateSRDialogOpen(false)}
        onSubmit={handleAddSocialReason}
        inputsData={{
          label2: 'Ingrese el nuevo nombre',
          value2: '',
          label3: 'Ingrese el nuevo rut',
          value3: '',
        }}
      />
      <ActionDialog
        holdings={activeHoldings}
        onReassignHolding={actions.reassignHolding}
        key={selectedSR.id}
        title="Editar razón social"
        isOpen={isEditSRDialogOpen}
        isEditing
        isActive={selectedSR?.isActive}
        onClose={() => setIsEditSRDialogOpen(false)}
        onSubmit={handleUpdateSocialReason}
        inputsData={{
          label1: 'value',
          value1: selectedSR?.id || '',
          label2: 'Ingrese el nombre',
          value2: selectedSR?.name || '',
          label3: 'Ingrese el rut',
          value3: selectedSR?.rut || '',
          isActive: selectedSR.isActive,
        }}
      />
      <ActionDialog
        title="Editar holding"
        isOpen={isEditHoldingDialogOpen}
        isEditing
        isHolding
        hasActiveSR={socialReasons.some((sr) => sr.isActive)}
        isActive={holding.isActive}
        onClose={() => setIsEditHoldingDialogOpen(false)}
        onSubmit={handleUpdateHolding}
        inputsData={{
          value1: holding.id,
          label2: 'Ingrese el nombre',
          value2: holding.name,
          label3: 'Ingrese el rut',
          value3: holding.rut,
          isActive: holding.isActive,
        }}
      />
      <CustomFloatButton
        handleClick={handleCreateSocialReason}
      />
    </>
  );
}

export default composedComponent(HoldingMaintainerDetail, saga, {
  saga: 'sagaSocialReason',
  states: [
    'app.objects.holdings',
    'holdingMaintainerDetail.loading',
    'holdingMaintainer.holding',
    'holdingMaintainerDetail.filteredSocialReasons',
    'holdingMaintainerDetail.pagination',
    'holdingMaintainerDetail.controls',
  ],
  actions: [socialReasonActions, { updateHolding: holdingsActions.updateHolding }, appActions],
});
