import React, { useEffect, useMemo, useState } from 'react';
import { Box } from '@material-ui/core';

import ScreenTitle from 'commons/components/ScreenTitle';
import { appActions } from 'commons/reducer';
import { composedComponent } from 'utils/functions';
import CustomFloatButton from 'commons/components/CustomFloatButton';
import { reportsActions } from 'screens/Reports/reducer';
import { Skeleton } from '@material-ui/lab';
import roles from 'screens/User/constants';
import ReportsTable from './component/ReportsTable';
import saga from './saga';
import ReportsDialog from './component/ReportsDialog';

function ReportMaintainer({
  actions,
  objects,
  controls,
  reports,
  allReports,
  loading,
  loadingUsers,
  error,
  pagination,
}) {
  const [isReportDialogOpen, setIsReportDialogOpen] = useState(false);

  const holdings = useMemo(() => Object.values(objects.holdings)
    .filter((holding) => holding.isActive)
    .map((holding) => ({ value: holding.id, label: holding.name, key: holding.id })),
  [objects.holdings]);

  const users = useMemo(() => Object.values(objects.users)
    .filter((user) => user.type !== roles.ADMINISTRADOR_DE_CONTRATO),
  [objects.users]);

  useEffect(() => {
    actions.getReports();
  }, []);

  const handleSubmitNewReport = (title, url, handleClose) => {
    actions.createReport({ title, url, handleClose });
  };

  if (loading) {
    return (
      <>
        <Skeleton variant="rect" width="100%" height={100} animation="wave" style={{ marginBottom: 20 }} />
        <Skeleton variant="rect" width="100%" height={500} animation="wave" style={{ marginBottom: 20 }} />
      </>
    );
  }

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <ScreenTitle title="Mantenedor de Reportes" showSubTitle={false} />
      </Box>
      <ReportsTable
        reports={reports}
        allReports={allReports}
        actions={actions}
        users={users}
        controls={controls}
        holdings={holdings}
        loadingUsers={loadingUsers}
        total={0}
        pagination={pagination}
        orderBy={controls.orderBy}
      />
      <CustomFloatButton
        handleClick={() => setIsReportDialogOpen(true)}
      />
      <ReportsDialog
        onSubmit={handleSubmitNewReport}
        isOpen={isReportDialogOpen}
        errors={error}
        onClose={() => {
          actions.clearErrors();
          setIsReportDialogOpen(false);
        }}
      />
    </>
  );
}

export default composedComponent(ReportMaintainer, saga, {
  saga: 'sagaReports',
  states: [
    'app.objects',
    'app.loadingUsers',
    'reports.pagination',
    'reports.controls',
    'reports.loading',
    'reports.reports',
    'reports.allReports',
    'reports.error',
    'reports.pagination',
  ],
  actions: [appActions, reportsActions],
});
