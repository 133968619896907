import React, { useMemo } from 'react';
// Material ui
import {
  Box, Card, Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// Project
import AdditionalsCard
  from 'screens/GeneratePaymentStatus/components/AdditionalsCard';
import ExtrasCard from 'screens/GeneratePaymentStatus/components/ExtrasCard';
import ClientDetailsCard
  from 'screens/GeneratePaymentStatus/components/ClientDetailsCard';
import DetailsCard
  from 'screens/GeneratePaymentStatus/components/DetailsCard';
import TotalDetailsCard
  from 'screens/GeneratePaymentStatus/components/TotalDetailsCard';
import DocumentsByZoneCard
  from 'screens/GeneratePaymentStatus/components/DocumentsByZoneCard';
import PostCard from 'screens/GeneratePaymentStatus/components/PostCard';
import { currencyForm } from 'utils/utils';
import { PAYMENT_STATUS_FLEET, PAYMENT_STATUS_STATUS } from 'utils/enums';
import type from 'utils/type';
import styles from './styles';
import PaymentStatusCard from '../PaymentStatusCard';
import TotalDetailsContainer from '../TotalDetailsContainer';

const useStyles = makeStyles(styles);

const PaymentStatusPreview = ({
  paymentStatusDTO, actions, controls, psId, role, isMobile, psSettings, psSections,
}) => {
  const classes = useStyles();

  const fleetSelectedAndInAdminReview = useMemo(() => controls.fleet !== '0'
      && paymentStatusDTO.fleets[0]?.status === PAYMENT_STATUS_FLEET.ADMIN_REVIEW,
  [paymentStatusDTO]);

  const roleAndStatusAllowedToDelete = useMemo(() => role === type.ADMINISTRADOR_DE_CONTRATO
      && (paymentStatusDTO.status === PAYMENT_STATUS_STATUS.DRAFT || fleetSelectedAndInAdminReview),
  [paymentStatusDTO]);

  const leases = useMemo(() => paymentStatusDTO?.fleets?.map((fleet) => {
    const auxLeases = fleet.leases;
    const licensePlateSearch = controls.search.toLowerCase();
    if (auxLeases.length > 0) {
      return auxLeases
        .filter(
          (lease) => lease.licensePlate.toLowerCase().includes(licensePlateSearch),
        )
        .map(
          (lease) => ({
            ...lease,
            fleetName: fleet.name.length > 0 ? fleet.name : 'Vehículos sin flota',
          }),
        );
    }
    return auxLeases;
  }).flat().sort(
    (a, b) => a.licensePlate.localeCompare(b.licensePlate),
  ), [paymentStatusDTO, controls.search]);

  const totalLeasesClp = useMemo(() => currencyForm.format(
    paymentStatusDTO.fleets?.reduce(
      (a, b) => a + (b.totalLeasesClp), 0,
    ),
  ), [paymentStatusDTO]);

  const previousBalances = useMemo(() => paymentStatusDTO?.fleets?.map((fleet) => {
    const auxBalances = fleet.previousBalances;
    if (auxBalances.length > 0) {
      return auxBalances
        .filter(
          (balance) => balance.licensePlate.toLowerCase().includes(controls.search.toLowerCase()),
        )
        .map(
          (balance) => ({
            ...balance,
            fleetName: fleet.name.length > 0 ? fleet.name : 'Vehículos sin flota',
          }),
        );
    }
    return auxBalances;
  }).flat().sort(
    (a, b) => a.licensePlate.localeCompare(b.licensePlate),
  ), [paymentStatusDTO, controls.search]);

  const totalPreviousBalancesClp = useMemo(() => currencyForm.format(
    paymentStatusDTO.fleets?.reduce(
      (a, b) => a + b.totalPreviousBalancesClp, 0,
    ),
  ), [paymentStatusDTO]);

  const additionals = useMemo(() => paymentStatusDTO?.fleets?.map((fleet) => {
    const auxAdditionals = fleet?.additionals;
    const licensePlateSearch = controls.search.toLowerCase();
    if (auxAdditionals.length > 0) {
      return auxAdditionals
        .filter(
          (additional) => additional.licensePlate.toLowerCase().includes(licensePlateSearch),
        )
        .map((additional) => ({
          ...additional,
          fleetName: fleet.name.length > 0 ? fleet.name : 'Vehículos sin flota',
        }));
    }
    return auxAdditionals;
  }).flat().sort(
    (a, b) => a.licensePlate.localeCompare(b.licensePlate),
  ), [paymentStatusDTO, controls.search]);

  const additionalsTotal = useMemo(() => currencyForm.format(
    paymentStatusDTO.fleets?.reduce((a, b) => a + b.totalAdditionals, 0),
  ), [paymentStatusDTO]);

  const extras = useMemo(() => paymentStatusDTO?.fleets?.map((fleet) => (
    fleet?.extras)).flat(
  ), [paymentStatusDTO]);

  const extrasTotal = useMemo(() => currencyForm.format(
    paymentStatusDTO.fleets?.reduce((a, b) => a + b.totalExtras, 0),
  ), [paymentStatusDTO]);

  const detailsCharges = useMemo(() => paymentStatusDTO?.fleets?.map((fleet) => (
    fleet.leases?.map((lease) => {
      const { charges } = lease;
      if (charges.length > 0) {
        return charges.map((charge) => ({ ...charge, licensePlate: lease.licensePlate }));
      }
      return charges;
    }))).flat(2).sort(
    (a, b) => a.licensePlate.localeCompare(b.licensePlate),
  ), [paymentStatusDTO]);

  const detailsDiscounts = useMemo(() => paymentStatusDTO?.fleets?.map((fleet) => (
    fleet.leases?.map((lease) => {
      const { discounts } = lease;
      if (discounts.length > 0) {
        return discounts.map((discount) => ({ ...discount, licensePlate: lease.licensePlate }));
      }
      return discounts;
    }))).flat(2).sort(
    (a, b) => a.licensePlate.localeCompare(b.licensePlate),
  ), [paymentStatusDTO]);

  const totalNet = useMemo(() => (
    paymentStatusDTO?.fleets?.map(
      (fleet) => fleet.totalClp - fleet.totalPreviousBalancesClp
        - (psSections?.additionals ? 0 : fleet.totalAdditionalsClp)
        - (psSections?.extras ? 0 : fleet.totalExtrasClp),
    ).reduce((a, b) => a + b, 0)
  ), [paymentStatusDTO]);

  const totalDiscounts = useMemo(() => (
    paymentStatusDTO?.fleets?.map((fleet) => fleet.totalDiscounts).reduce((a, b) => a + b, 0)
  ), [paymentStatusDTO]);

  const totalCharges = useMemo(() => (
    paymentStatusDTO?.fleets?.map((fleet) => fleet.totalCharges)
      .reduce((a, b) => a + b, 0)
  ), [paymentStatusDTO]);

  const totalBase = useMemo(() => (
    paymentStatusDTO?.fleets?.map((fleet) => (psSections?.extras ? fleet.totalExtrasClp : 0)
      + (psSections?.additionals ? fleet.totalAdditionalsClp : 0)
      + fleet.totalLeasesCostClp)
      .reduce((a, b) => a + b, 0)
  ), [paymentStatusDTO]);

  const posts = useMemo(() => paymentStatusDTO?.fleets?.map((fleet) => (
    fleet.posts)).flat(
  ), [paymentStatusDTO]);

  return (
    <>
      <Box
        border={isMobile ? 0 : 1}
        className={isMobile ? classes.box1 : classes.box2}
      >
        <Grid container>
          <Grid item xs={12}>
            <ClientDetailsCard
              data={paymentStatusDTO}
              controls={controls}
              role={role}
            />
          </Grid>
          <Grid item xs={12}>
            <PaymentStatusCard
              title="VEHÍCULOS ARRENDADOS"
              type="leases"
              data={leases}
              total={totalLeasesClp}
              isMobile={isMobile}
              psSettings={psSettings}
            />
          </Grid>
          {psSections?.pendings && (
          <Grid item xs={12} hidden={psSections.pendings}>
            <PaymentStatusCard
              title="PENDIENTES PERÍODO ANTERIOR"
              type="previousBalance"
              data={previousBalances}
              total={totalPreviousBalancesClp}
              isMobile={isMobile}
              psSettings={psSettings}
            />
          </Grid>
          )}
          {psSections?.additionals && (
          <Grid item xs={12}>
            <AdditionalsCard
              data={additionals}
              total={additionalsTotal}
              isMobile={isMobile}
            />
          </Grid>
          )}
          {psSections?.extras && (
          <Grid item xs={12}>
            <ExtrasCard
              data={extras}
              isMobile={isMobile}
              total={extrasTotal}
            />
          </Grid>
          )}
        </Grid>
        <Grid container alignItems="stretch" justifyContent="space-between">
          <Grid item xs={12} md={7} component={Card} className={classes.gridDetailsCard}>
            <DetailsCard
              isMobile={isMobile}
              charges={detailsCharges}
              discounts={detailsDiscounts}
              actions={actions}
              controls={controls}
              psId={psId}
              roleAndStatusAllowedToDelete={roleAndStatusAllowedToDelete}
            />
          </Grid>
          <TotalDetailsContainer>
            <TotalDetailsCard
              totalBase={totalBase}
              totalNet={totalNet}
              totalDiscounts={totalDiscounts}
              totalCharges={totalCharges}
            />
          </TotalDetailsContainer>
        </Grid>
      </Box>
      <Grid container alignItems="stretch" justifyContent="space-between">
        <Grid item xs={12} md={6}>
          <PostCard
            isMobile={isMobile}
            data={posts}
            fleets={paymentStatusDTO.fleets}
            actions={actions}
            controls={controls}
            psId={psId}
            role={role}
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <DocumentsByZoneCard
            isMobile={isMobile}
            actions={actions}
            controls={controls}
            psId={psId}
            roleAndStatusAllowedToDelete={roleAndStatusAllowedToDelete}
            fleets={paymentStatusDTO.fleets}
          />
        </Grid>
      </Grid>
    </>

  );
};

export default PaymentStatusPreview;
