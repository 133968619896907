const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: theme.spacing(6),
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  menuContainer: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '30%',
      marginRight: theme.spacing(6),
    },
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(8),
    },
  },
  componentContainer: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '70%',
    },
  },
  button: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(1),
    boxShadow: theme.shadows[4],
    borderRadius: 8,
    background: ({ selected }) => (selected ? theme.palette.primary.main : 'white'),
    cursor: 'pointer',
  },
  buttonText: {
    color: ({ selected = false }) => (!selected ? theme.palette.primary.main : 'white'),
  },

});

export default styles;
