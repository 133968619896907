import React from 'react';

// Material
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';
import SvgIcon from '@material-ui/core/SvgIcon';

// Icons
import CloseIcon from '@material-ui/icons/Close';

// Project
import MultipleFilter from 'commons/components/MultipleFilter';
import SocialReasonsFilter from 'commons/components/MultipleFilter/SocialReasonsFilter';
import styles from './styles';

const ClearIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M16,11h-1V3c0-1.1-0.9-2-2-2h-2C9.9,1,9,1.9,9,3v8H8c-2.76,0-5,2.24-5,5v7h18v-7C21,13.24,18.76,11,16,11z M19,21h-2v-3 c0-0.55-0.45-1-1-1s-1,0.45-1,1v3h-2v-3c0-0.55-0.45-1-1-1s-1,0.45-1,1v3H9v-3c0-0.55-0.45-1-1-1s-1,0.45-1,1v3H5v-5 c0-1.65,1.35-3,3-3h8c1.65,0,3,1.35,3,3V21z" />
  </SvgIcon>
);

const useStyles = makeStyles(styles);

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const MobileDialogFilter = ({
  show,
  onHandleShow,
  controls,
  statusOptions,
  zoneOptions,
  fleetOptions,
  maintenanceOptions,
  dueDateOptions,
  actions,
  isLoading,
  selectValue, handleChangeSocialReason, optionsSocialReason,
}) => {
  const classes = useStyles();

  return (
    <Dialog fullScreen open={show} keepMounted TransitionComponent={Transition}>
      <Box className={classes.dialogContainer}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          className={classes.mobileHeader}
        >
          <Typography variant="h4" color="primary">
            Filtros
          </Typography>
          <IconButton className={classes.iconButton} color="primary" onClick={() => onHandleShow()} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          gridRowGap={8}
        >
          <MultipleFilter
            id="status"
            label="Estado"
            size="medium"
            options={statusOptions}
            value={controls.status}
            onChange={actions.setFilter}
          />
          <MultipleFilter
            id="fleet"
            label="Flota"
            size="medium"
            options={fleetOptions}
            value={controls.fleet}
            onChange={actions.setFilter}
          />
          <MultipleFilter
            id="zone"
            label="Zona"
            size="medium"
            options={zoneOptions}
            value={controls.zone}
            onChange={actions.setFilter}
          />
          <MultipleFilter
            id="maintenance"
            label="Mantención"
            size="medium"
            options={maintenanceOptions}
            value={controls.maintenance}
            onChange={actions.setFilter}
          />
          <MultipleFilter
            id="dueDate"
            label="Vigencia"
            size="medium"
            options={dueDateOptions}
            value={controls.dueDate}
            onChange={actions.setFilter}
          />
          <SocialReasonsFilter
            limitTags={1}
            value={selectValue}
            options={optionsSocialReason}
            onChange={handleChangeSocialReason}
          />
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.filterInputAutocomplete}
            onClick={() => !isLoading && actions.fetchWithFilter() && onHandleShow()}
          >
            <Typography variant="h6">
              Aplicar Filtros
            </Typography>
          </Button>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<ClearIcon />}
            size="large"
            onClick={() => actions.resetFilters()}
          >
            <Typography variant="h6">
              Limpiar Filtros
            </Typography>
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

const Filter = ({
  controls, actions, isLoading, statusOptions,
  zoneOptions, fleetOptions, maintenanceOptions, dueDateOptions,
  selectValue, handleChangeSocialReason, optionsSocialReason,
}) => {
  const classes = useStyles();
  const { mobileFilter } = controls;

  return (
    <>
      <MobileDialogFilter
        show={mobileFilter}
        onHandleShow={actions.setMobileFilter}
        controls={controls}
        fleetOptions={fleetOptions}
        statusOptions={statusOptions}
        zoneOptions={zoneOptions}
        maintenanceOptions={maintenanceOptions}
        dueDateOptions={dueDateOptions}
        actions={actions}
        isLoading={isLoading}
        selectValue={selectValue}
        handleChangeSocialReason={handleChangeSocialReason}
        optionsSocialReason={optionsSocialReason}
      />
      <Grid container spacing={2} className={classes.container}>
        <SocialReasonsFilter
          sm={3}
          filterField
          value={selectValue}
          options={optionsSocialReason}
          onChange={handleChangeSocialReason}
        />
        <Grid item sm={3}>
          <MultipleFilter
            id="status"
            label="Estado"
            options={statusOptions}
            value={controls.status}
            onChange={actions.setFilter}
          />
        </Grid>
        <Grid item sm={3}>
          <MultipleFilter
            id="fleet"
            label="Flota"
            options={fleetOptions}
            value={controls.fleet}
            onChange={actions.setFilter}
          />
        </Grid>
        <Grid item sm={3}>
          <Box height="100%" display="flex" justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => !isLoading && actions.fetchWithFilter()}
            >
              <Box fontWeight="bold">
                Buscar
              </Box>
            </Button>
            <Button
              variant="outlined"
              color="primary"
              className={classes.clearButton}
              onClick={() => actions.resetFilters()}
            >
              <ClearIcon />
            </Button>
          </Box>
        </Grid>
        <Grid item sm={3}>
          <MultipleFilter
            id="zone"
            label="Zona"
            options={zoneOptions}
            value={controls.zone}
            onChange={actions.setFilter}
          />
        </Grid>
        <Grid item sm={3}>
          <MultipleFilter
            id="maintenance"
            label="Mantención"
            options={maintenanceOptions}
            value={controls.maintenance}
            onChange={actions.setFilter}
          />
        </Grid>
        <Grid item sm={3}>
          <MultipleFilter
            id="dueDate"
            label="Vigencia"
            options={dueDateOptions}
            value={controls.dueDate}
            onChange={actions.setFilter}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Filter;
