import { alpha } from '@material-ui/core/styles';

const styles = (theme) => ({
  input: {
    '&::placeholder': {
      color: theme.palette.primary.main,
    },
  },
  autoComplete: {
    '& .MuiOutlinedInput-input': {
      color: theme.palette.primary.main,
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.primary.main,
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
  },
  box: {
    color: alpha(theme.palette.primary.main, 0.35),
    marginRight: '8px',
  },
});

export default styles;
