import { createAction, createReducer } from 'utils/reducer';

const PATH = 'homeMarketing/';

export const HOME_MARKETING = {
  GET_INITIAL_STATE: `${PATH}GET_INITIAL_STATE`,
  GET_INITIAL_STATE_SUCCESS: `${PATH}GET_INITIAL_STATE_SUCCESS`,
  GET_NEWSLETTER_HISTORY: `${PATH}GET_NEWSLETTER_HISTORY`,
  GET_NEWSLETTER_HISTORY_SUCCESS: `${PATH}GET_NEWSLETTER_HISTORY_SUCCESS`,
  GET_NEWSLETTER_HISTORY_ERROR: `${PATH}GET_NEWSLETTER_HISTORY_ERROR`,
  ADD_NEWSLETTER_HISTORY: `${PATH}ADD_NEWSLETTER_HISTORY`,
  ADD_NEWSLETTER_HISTORY_SUCCESS: `${PATH}ADD_NEWSLETTER_HISTORY_SUCCESS`,
  ADD_NEWSLETTER_HISTORY_ERROR: `${PATH}ADD_NEWSLETTER_HISTORY_ERROR`,
  UPDATE_NEWSLETTER_HISTORY: `${PATH}UPDATE_NEWSLETTER_HISTORY`,
  UPDATE_NEWSLETTER_HISTORY_SUCCESS: `${PATH}UPDATE_NEWSLETTER_HISTORY_SUCCESS`,
  UPDATE_NEWSLETTER_HISTORY_ERROR: `${PATH}UPDATE_NEWSLETTER_HISTORY_ERROR`,
  REMOVE_NEWSLETTER_HISTORY: `${PATH}REMOVE_NEWSLETTER_HISTORY`,
  REMOVE_NEWSLETTER_HISTORY_SUCCESS: `${PATH}REMOVE_NEWSLETTER_HISTORY_SUCCESS`,
  REMOVE_NEWSLETTER_HISTORY_ERROR: `${PATH}REMOVE_NEWSLETTER_HISTORY_ERROR`,
  ENABLE_ITEM: `${PATH}ENABLE_ITEM`,
};

export const homeMarketingActions = {
  getInitialState: createAction(HOME_MARKETING.GET_INITIAL_STATE),
  toggleHomeMarketingItem: createAction(HOME_MARKETING.ENABLE_ITEM, 'payload'),
  getNewsletterHistory: createAction(HOME_MARKETING.GET_NEWSLETTER_HISTORY),
  addNewsletterHistory: createAction(HOME_MARKETING.ADD_NEWSLETTER_HISTORY, 'payload'),
  updateNewsletterHistory: createAction(HOME_MARKETING.UPDATE_NEWSLETTER_HISTORY, 'payload'),
  removeNewsletterHistory: createAction(HOME_MARKETING.REMOVE_NEWSLETTER_HISTORY, 'payload'),
};

const initialState = {
  loading: true,
  isNewsletterEnabled: false,
  isSchedulingEnabled: false,
  loadingNewsletterHistory: true,
  newsletterHistory: [],
};

const homeMarketing = createReducer(initialState, {
  [HOME_MARKETING.GET_INITIAL_STATE]: (state) => ({
    ...state,
    loading: true,
  }),
  [HOME_MARKETING.GET_INITIAL_STATE_SUCCESS]: (state, action) => ({
    isNewsletterEnabled: action.payload.newsletter,
    isSchedulingEnabled: action.payload.scheduling,
    loading: false,
  }),
  [HOME_MARKETING.ENABLE_ITEM]: (state, action) => {
    if (action.payload.key === 'newsletter') {
      return {
        ...state,
        isNewsletterEnabled: action.payload.value,
      };
    }
    if (action.payload.key === 'scheduling') {
      return {
        ...state,
        isSchedulingEnabled: action.payload.value,
      };
    }
    return state;
  },
  [HOME_MARKETING.GET_NEWSLETTER_HISTORY]: (state) => ({
    ...state,
    loadingNewsletterHistory: true,
  }),
  [HOME_MARKETING.GET_NEWSLETTER_HISTORY_SUCCESS]: (state, action) => ({
    ...state,
    loadingNewsletterHistory: false,
    newsletterHistory: action.payload,
  }),
  [HOME_MARKETING.ADD_NEWSLETTER_HISTORY_SUCCESS]: (state, action) => ({
    ...state,
    newsletterHistory: [action.payload, ...state.newsletterHistory],
  }),
  [HOME_MARKETING.UPDATE_NEWSLETTER_HISTORY_SUCCESS]: (state, action) => {
    const { id, ...rest } = action.payload;
    const index = state.newsletterHistory.findIndex(({ id: itemId }) => itemId === Number(id));
    const newsletterHistory = [...state.newsletterHistory];
    newsletterHistory[index] = {
      ...newsletterHistory[index],
      ...rest,
    };
    return {
      ...state,
      newsletterHistory,
    };
  },
  [HOME_MARKETING.REMOVE_NEWSLETTER_HISTORY_SUCCESS]: (state, { payload: { newsletterId } }) => ({
    ...state,
    newsletterHistory: state.newsletterHistory.filter(({ id }) => id !== newsletterId),
  }),
});

export default homeMarketing;
