import React, { useEffect, useState } from 'react';

// material
import { Box, useTheme, useMediaQuery } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';

// Project
import CustomPieChart from 'commons/components/CustomPieChart';

// Icons
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { numberFormatter } from 'utils/functions';
import styles from './styles';

const useStyles = makeStyles(styles);

const Indicator = ({
  kpi, controls, actions, isLoading,
}) => {
  const [show, setShow] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();

  useEffect(() => {
    setShow(!isMobile);
  }, [isMobile]);

  const handleClickAvailability = () => {
    if (!isLoading) {
      actions.setFilter({ key: 'status', value: ['OPERATIVO'] });
      actions.fetchWithFilter();
    }
  };

  const handleClickWorkshop = () => {
    if (!isLoading) {
      actions.setFilter({ key: 'status', value: ['TALLER'] });
      actions.fetchWithFilter();
    }
  };

  return (
    <>
      {isMobile && (
        <Button
          variant="contained"
          color="default"
          size="large"
          fullWidth
          onClick={() => setShow(!show)}
          className={classes.buttonIndicator}
          endIcon={show ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        >
          <Box fontWeight={600}>
            Mostrar indicadores KPI
          </Box>
        </Button>
      )}
      <Collapse in={show}>
        <Grid container className={classes.container}>
          <Grid item xs={6} md={3}>

            <CustomPieChart
              isSelected={controls.status.includes('OPERATIVO')}
              percentage={Math.round(kpi.availabilityPercentage)}
              text="% Disponibilidad"
              subText="Mes"
              onClick={handleClickAvailability}
              size={120}
              fontSize={24}
              thickness={5}
            />

          </Grid>
          <Grid item xs={6} md={3}>

            <CustomPieChart
              isSelected={controls.status.includes('TALLER')}
              percentage={Math.round(kpi.workshopPercentage?.percentage)}
              text="% En taller"
              subText={`${kpi.workshopPercentage?.total} Vehículos`}
              onClick={handleClickWorkshop}
              opacity={0.75}
              size={120}
              fontSize={24}
              thickness={5}
            />

          </Grid>
          <Grid item xs={6} md={3} className={classes.indicator}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              boxShadow={3}
              className={classes.boxIndicator}
              borderRadius={16}
              whiteSpace="nowrap"
            >
              <Typography color="primary" variant="h6">
                <Box fontWeight="fontWeightBold" fontSize={30} textAlign="center">
                  {`${kpi.sinisters ? numberFormatter(parseInt(kpi.sinisters, 10)) : 0}`}
                </Box>
                <Box fontWeight="fontWeightMedium" fontSize={28} textAlign="center">
                  Siniestros
                </Box>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} md={3} className={classes.indicator}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              boxShadow={3}
              className={classes.boxIndicator}
              borderRadius={16}
              whiteSpace="nowrap"
            >
              <Typography variant="h6" color="primary">
                <Box fontWeight="fontWeightBold" fontSize={30} textAlign="center">
                  {numberFormatter(Math.round(kpi.averageKm))}
                </Box>
                <Box fontWeight="fontWeightMedium" fontSize={28} textAlign="center">
                  KM Promedio
                </Box>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Collapse>
    </>
  );
};

export default Indicator;
