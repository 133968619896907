import { alpha } from '@material-ui/core';

const styles = (theme) => ({
  bottom: {
    color: '#F9FAFA',
  },
  top: {
    color: ({ opacity, isSelected }) => alpha(theme.palette.primary.main, opacity
      + (isSelected ? -0.2 : 0)),
    position: 'absolute',
    left: 0,
  },
  text: {
    color: ({ opacity, isSelected }) => alpha(theme.palette.primary.main, opacity
      + (isSelected ? -0.2 : 0)),
  },
  box: {
    cursor: 'pointer',
  },
});

export default styles;
