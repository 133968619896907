import React from 'react';
import { InputAdornment, makeStyles, TextField } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import styles from './styles';

const useStyles = makeStyles(styles);

const CustomTextField = ({
  id,
  name,
  label,
  value,
  error,
  helperText,
  type = 'text',
  onChange = (e) => e.preventDefault(),
  variant,
  loading,
  disabled = false,
  disableAutoComplete = false,
  inputProps = false,
  adornment = '',
  size = 'small',
}) => {
  const classes = useStyles();

  return (
    <>
      {!loading
        ? (
          <TextField
            autoComplete={disableAutoComplete ? 'off' : 'on'}
            size={size}
            className={classes.borderColor}
            fullWidth
            id={id}
            value={value}
            name={name}
            label={label}
            type={type}
            variant={variant}
            onChange={onChange}
            error={error}
            helperText={helperText}
            disabled={disabled}
            InputProps={inputProps ? {
              startAdornment: <InputAdornment position="start">{adornment}</InputAdornment>,
            } : {}}
          />
        )
        : (
          <Skeleton
            width="100%"
            height={40}
            className={classes.skeleton}
          />
        )}
    </>
  );
};

export default CustomTextField;
