import React from 'react';
import { useHistory } from 'react-router-dom';
import { IconButton, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const BackButton = ({ path }) => {
  const history = useHistory();

  return (
    <IconButton
      disableRipple
      onClick={() => { history.push(path); }}
    >
      <ArrowBackIcon color="secondary" />
      <Typography color="primary">Volver</Typography>
    </IconButton>
  );
};

export default BackButton;
