const styles = (theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(10),
    right: theme.spacing(2),
  },
  scrollable: {
    right: theme.spacing(3.5),
  },
});

export default styles;
