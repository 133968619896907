const styles = () => ({
  gridChildren: {
    marginBottom: 8,
    padding: '0px 8px',
  },
  boxChildren: {
    marginBottom: 8,
    width: '300px',
    minWidth: '300px',
  },
});

export default styles;
