import React, { useEffect, useMemo } from 'react';
import NotificationsNoneOutlinedIcon
  from '@material-ui/icons/NotificationsNoneOutlined';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import {
  Badge, Button, Popover,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import CustomAccordion from 'commons/components/CustomAccordion';
import socket from 'config/socket';
import styles from './style';

const useStyles = makeStyles(styles);

const Notification = ({
  notifications, totalNotification, handleClickNotification, user, actions,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    socket.on('notifications', () => {
      socket.emit('notifications', user.user.id);
    });
  }, [process.env.REACT_APP_ENDPOINT_SOCKET, user.user.id]);

  useEffect(() => {
    socket.on('notificationsUpdated', (notificationsUpdated) => {
      actions.updateObjects(notificationsUpdated);
    });
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const notificationType = useMemo(() => notifications.reduce((acc, item) => {
    const x = [...(acc[item.type] ?? []), item];
    return { ...acc, [item.type]: x };
  }, {}), [notifications]);

  const {
    MAINTENANCE, DUE_CONTRACT, DRIVER_REQUEST, ...paymentStatusNotifications
  } = notificationType;

  const AccordionType = [
    {
      id: 'MAINTENANCE',
      title: 'Mantención',
    },
    {
      id: 'DUE_CONTRACT',
      title: 'Vencimiento',
    },
    {
      id: 'DRIVER_REQUEST',
      title: 'Solicitudes',
    },
    {
      id: 'PAYMENT_STATUS',
      title: 'Estados de pago',
    },
  ];

  return (
    <>
      <IconButton className={classes.notificationIcon} onClick={handleClick}>
        <Badge overlap="rectangular" badgeContent={totalNotification?.length} color="secondary">
          <NotificationsNoneOutlinedIcon fontSize="large" color="primary" />
        </Badge>
      </IconButton>
      <Popover
        id="notification"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {notifications?.length > 0
          ? (
            <>
              <div className={classes.cleanNotifications}>
                <Button fullWidth onClick={() => actions.cleanNotifications()} variant="outlined">
                  Limpiar notificaciones
                </Button>
              </div>
              {AccordionType.map((a) => {
                if ((a.id === 'MAINTENANCE' || a.id === 'DUE_CONTRACT' || a.id === 'DRIVER_REQUEST') && notificationType[a.id]) {
                  return (
                    <CustomAccordion
                      key={a.id}
                      id={a.id}
                      title={a.title}
                      total={notificationType[a.id]?.length}
                      notifications={notificationType[a.id]}
                      onClickNotification={handleClickNotification}
                      handleClose={handleClose}
                    />
                  );
                }
                if ((notificationType.PAYMENT_STATUS_RELEASED
                || notificationType.PAYMENT_STATUS_FIX_ISSUE
                || notificationType.PAYMENT_STATUS_ISSUE_FIXED
                || notificationType.PAYMENT_STATUS_APPROVED
                || notificationType.PAYMENT_STATUS_CLOSED) && a.id === 'PAYMENT_STATUS') {
                  return (
                    <CustomAccordion
                      key="PAYMENT_STATUS"
                      id="PAYMENT_STATUS"
                      title="Estados de pago"
                      total={
                      (notificationType?.PAYMENT_STATUS_RELEASED?.length || 0)
                      + (notificationType?.PAYMENT_STATUS_CLOSED?.length || 0)
                      + (notificationType?.PAYMENT_STATUS_FIX_ISSUE?.length || 0)
                      + (notificationType?.PAYMENT_STATUS_ISSUE_FIXED?.length || 0)
                      + (notificationType?.PAYMENT_STATUS_APPROVED?.length || 0)
                    }
                      notifications={
                      [].concat(
                        ...Object.keys(paymentStatusNotifications)
                          .map((key) => paymentStatusNotifications[key]),
                      )
                  }
                      onClickNotification={handleClickNotification}
                      handleClose={handleClose}
                    />
                  );
                }
                return false;
              })}
            </>
          )
          : <Typography className={classes.typography}>Sin nuevas notificaciones</Typography>}
      </Popover>
    </>
  );
};

export default Notification;
