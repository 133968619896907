const styles = (theme) => ({
  textContainer: {
    marginLeft: theme.spacing(2),
    width: 230,
  },
  itemIcon: {
    color: theme.palette.primary.main,
  },
  statusIcon: {
    fontSize: 14.3,
  },
  enabled: { color: 'green' },
  disabled: { color: 'red' },
});

export default styles;
