import { createAction, createReducer } from 'utils/reducer';
import * as APP from 'commons/reducer';
import { LOGIN } from 'screens/Login/reducer';

// Local constants
const PATH = 'payment-status/';

// Global constants for saga
export const PAYMENT_STATUS = {
  FETCH_INITIAL_DATA: `${PATH}FETCH_DATA`,
  FETCH_INITIAL_DATA_SUCCESS: `${PATH}FETCH_DATA_SUCCESS`,
  GET_FILTER_DATA: `${PATH}GET_FILTER_DATA`,
  GET_FILTER_DATA_SUCCESS: `${PATH}GET_FILTER_DATA_SUCCESS`,
  CHANGE_CONTROLS: `${PATH}CHANGE_CONTROLS`,
  CLEAR_CONTROLS: `${PATH}CLEAR_CONTROLS`,
  CLEAR_CONTROLS_SUCCESS: `${PATH}CLEAR_CONTROLS_SUCCESS`,
  CHANGE_PAGE: `${PATH}CHANGE_PAGE`,
  CHANGE_PAGE_SUCCESS: `${PATH}CHANGE_PAGE_SUCCESS`,
  FETCH_DATA_WITH_FILTER: `${PATH}FETCH_DATA_WITH_FILTER`,
  FETCH_DATA_WITH_FILTER_SUCCESS: `${PATH}FETCH_DATA_WITH_FILTER_SUCCESS`,
  CHANGE_STATUS: `${PATH}CHANGE_STATUS`,
  CHANGE_STATUS_SUCCESS: `${PATH}CHANGE_STATUS_SUCCESS`,
  GET_USER_HOLDING: `${PATH}GET_USER_HOLDING`,
  GET_USER_HOLDING_SUCCESS: `${PATH}GET_USER_HOLDING_SUCCESS`,
  CLOSE_HOLDING_DIALOG: `${PATH}CLOSE_HOLDING_DIALOG`,
  OPEN_SEARCH_USER: `${PATH}OPEN_SEARCH_USER`,
  CLOSE_PREVIOUS: `${PATH}CLOSE_PREVIOUS`,
  CLOSE_PREVIOUS_SUCCESS: `${PATH}CLOSE_PREVIOUS_SUCCESS`,
  CLOSE_CURRENT: `${PATH}CLOSE_CURRENT`,
  CLOSE_CURRENT_SUCCESS: `${PATH}CLOSE_CURRENT_SUCCESS`,
  CLOSE_CURRENT_ERROR: `${PATH}CLOSE_CURRENT_ERROR`,
};

// actions
export const paymentStatusActions = {
  getInitialData: createAction(PAYMENT_STATUS.FETCH_INITIAL_DATA),
  changeControls: createAction(PAYMENT_STATUS.CHANGE_CONTROLS, 'payload'),
  clearControls: createAction(PAYMENT_STATUS.CLEAR_CONTROLS),
  getFilterData: createAction(PAYMENT_STATUS.GET_FILTER_DATA),
  fetchWithFilter: createAction(PAYMENT_STATUS.FETCH_DATA_WITH_FILTER),
  changePage: createAction(PAYMENT_STATUS.CHANGE_PAGE, 'payload'),
  changeStatus: createAction(PAYMENT_STATUS.CHANGE_STATUS, 'payload'),
  getHoldings: createAction(PAYMENT_STATUS.GET_USER_HOLDING, 'payload'),
  closeHoldingDialog: createAction(PAYMENT_STATUS.CLOSE_HOLDING_DIALOG),
  openUserSearch: createAction(PAYMENT_STATUS.OPEN_SEARCH_USER),
  closePrevious: createAction(PAYMENT_STATUS.CLOSE_PREVIOUS),
  closeCurrent: createAction(PAYMENT_STATUS.CLOSE_CURRENT),
};

const initialState = {
  controls: {
    period: [],
    status: [],
    client: [],
    confirmationClosePreviousPsDialog: false,
    confirmationCloseActualPsDialog: false,
  },
  query: {
    period: [],
    status: [],
    client: [],
  },
  pagination: {
    size: 10,
    page: 1,
    totalPages: 1,
  },
  paymentStatusDTOList: [],
  periods: [],
  clients: [],
  status: [],
  pageLoading: false,
  loading: false,
  loadingFilter: false,
  error: false,
  message: '',
};

const paymentStatus = createReducer(initialState, {
  [PAYMENT_STATUS.FETCH_INITIAL_DATA]: (state) => ({
    ...state,
    loading: true,
    query: {
      ...state.controls,
    },
    controls: {
      ...state.controls,
    },
  }),
  [PAYMENT_STATUS.FETCH_INITIAL_DATA_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    paymentStatusDTOList: action.payload.PaymentStatusDTOList,
    pagination: {
      ...state.pagination,
      totalPages: action.payload.totalPages,
    },
  }),
  [APP.SET_HOLDING_SUCCESS]: (state) => ({
    ...state,
    controls: {
      ...initialState.controls,
    },
    pagination: {
      ...initialState.pagination,
      totalPages: state.pagination.totalPages,
    },
    query: initialState.query,
  }),
  [APP.SET_SOCIAL_REASON_SUCCESS]: (state, action) => ({
    ...state,
    controls: {
      ...initialState.controls,
      client: action.payload.map((sr) => sr.value),
    },
    pagination: {
      ...initialState.pagination,
      totalPages: state.pagination.totalPages,
    },
    query: {
      ...initialState.query,
      client: action.payload.map((sr) => sr.value),
    },
  }),
  [LOGIN.LOGIN_USER_SUCCESS]: (state) => ({
    ...state,
    controls: {
      ...initialState.controls,
    },
    pagination: {
      ...initialState.pagination,
      totalPages: state.pagination.totalPages,
    },
    query: initialState.query,
  }),
  [PAYMENT_STATUS.GET_FILTER_DATA]: (state) => ({
    ...state,
    loadingFilter: true,
  }),
  [PAYMENT_STATUS.GET_FILTER_DATA_SUCCESS]: (state, action) => ({
    ...state,
    loadingFilter: false,
    clients: action.payload.clients,
    periods: action.payload.periods,
    status: action.payload.status,
  }),

  [PAYMENT_STATUS.FETCH_DATA_WITH_FILTER]: (state) => ({
    ...state,
    pagination: {
      ...initialState.pagination,
      totalPages: state.pagination.totalPages,
    },
    query: state.controls,
    pageLoading: true,
  }),
  [PAYMENT_STATUS.FETCH_DATA_WITH_FILTER_SUCCESS]: (state, action) => ({
    ...state,
    pagination: {
      ...state.pagination,
      totalPages: action.payload.totalPages,
    },
    pageLoading: false,
    paymentStatusDTOList: action.payload.PaymentStatusDTOList,
  }),
  [PAYMENT_STATUS.CHANGE_CONTROLS]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      [action.payload.key]: action.payload.value,
    },
  }),
  [PAYMENT_STATUS.CLEAR_CONTROLS]: (state) => ({
    ...state,
    controls: {
      ...initialState.controls,
    },
    query: initialState.query,
    pageLoading: true,
  }),
  [PAYMENT_STATUS.CLEAR_CONTROLS_SUCCESS]: (state, action) => ({
    ...state,
    controls: {
      ...initialState.controls,
    },
    pagination: {
      ...initialState.pagination,
      totalPages: action.payload.totalPages,
    },
    pageLoading: false,
    paymentStatusDTOList: action.payload.PaymentStatusDTOList,
  }),
  [PAYMENT_STATUS.CHANGE_PAGE]: (state) => ({
    ...state,
    pageLoading: true,
  }),
  [PAYMENT_STATUS.CHANGE_PAGE_SUCCESS]: (state, action) => ({
    ...state,
    pagination: {
      ...state.pagination,
      totalPages: action.payload.totalPages,
      page: action.payload.page,
    },
    pageLoading: false,
    paymentStatusDTOList: action.payload.PaymentStatusDTOList,
  }),

  [PAYMENT_STATUS.CLOSE_PREVIOUS]: (state) => ({
    ...state,
    pageLoading: true,
  }),
  [PAYMENT_STATUS.CLOSE_PREVIOUS_SUCCESS]: (state, action) => ({
    ...state,
    pageLoading: false,
    controls: {
      ...state.controls,
      confirmationClosePreviousPsDialog: false,
    },
    paymentStatusDTOList: action.payload.PaymentStatusDTOList,
  }),
  [PAYMENT_STATUS.CLOSE_CURRENT]: (state) => ({
    ...state,
    pageLoading: true,
  }),
  [PAYMENT_STATUS.CLOSE_CURRENT_SUCCESS]: (state, action) => ({
    ...state,
    pageLoading: false,
    controls: {
      ...state.controls,
      confirmationCloseActualPsDialog: false,
    },
    paymentStatusDTOList: action.payload.PaymentStatusDTOList,
  }),
  [PAYMENT_STATUS.CLOSE_CURRENT_ERROR]: (state) => ({
    ...state,
    pageLoading: false,
    controls: {
      ...state.controls,
      confirmationCloseActualPsDialog: false,
    },
  }),
});

export default paymentStatus;
