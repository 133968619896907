import { Grid } from '@material-ui/core';
import TextInputFilter from 'commons/components/TextInputFilter';
import React from 'react';

function HoldingFilter({ holdings, onSelect }) {
  return (
    <Grid item xs={12}>
      <TextInputFilter
        id="holding"
        label="Holding"
        options={holdings}
        onSelect={onSelect}
      />
    </Grid>
  );
}

export default HoldingFilter;
