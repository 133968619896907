import React from 'react';
import {
  Dialog,
  DialogActions, DialogContent, DialogTitle, FormControlLabel, List, ListItem, Checkbox, Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TextInputFilter from 'commons/components/TextInputFilter';
import styles from '../styles';

const useStyles = makeStyles(styles);

function AdminContractHoldingsDialog({
  isOpen, onClose, users, onSelectAdmin, holdings, toggleHolding, onSubmit,
}) {
  const classes = useStyles();

  const getFullName = (user) => `${user.firstName} ${user.lastName}`;
  const usersMapped = users
    .map((user) => ({ id: user.id, value: user.email, label: getFullName(user) }));

  return (
    <Dialog
      open={isOpen}
      fullWidth
    >
      <DialogTitle>Elegir administrador de contrato</DialogTitle>
      <DialogContent>
        <TextInputFilter
          id="admin"
          label="Administrador de contrato"
          onSelect={({ id }) => onSelectAdmin(id)}
          options={usersMapped}
        />
        <List className={classes.list}>
          {holdings.map((holding, index) => (
            <ListItem key={holding.id} className={index % 2 === 0 ? classes.back1 : classes.back2}>
              <FormControlLabel
                id={holding.id}
                className={classes.w}
                control={(
                  <Checkbox
                    checked={holding.checked}
                    onChange={() => toggleHolding(holding.id)}
                  />
              )}
                label={holding.name}
              />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={onClose}>Cancelar</Button>
        <Button variant="contained" color="primary" onClick={onSubmit}>Agregar</Button>
      </DialogActions>
    </Dialog>
  );
}

export default AdminContractHoldingsDialog;
