import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { formatDateForCards } from 'utils/functions';
import GeneralCard from 'commons/components/GeneralCard';
import GeneralMobileCard from 'commons/components/GeneralMobileCard';
import classNames from 'classnames';
import { formatRut } from 'utils/rut';
import styles from './styles';

const useStyles = makeStyles(styles);

const getDataCard = (holding) => [
  {
    key: 0,
    title: 'ID',
    row: `${holding?.id}`,
    width: 120,
    center: true,
  },
  {
    key: 1,
    title: 'RUT',
    row: formatRut(holding.rut),
    width: 130,
  },
  {
    key: 2,
    title: 'Nombre',
    row: holding.name,
    width: 320,
  },
  {
    key: 3,
    title: 'Cantidad de razones sociales',
    row: `${holding.count}`,
    width: 130,
    center: true,
  },
  {
    key: 4,
    title: 'Fecha de creación',
    row: `${formatDateForCards(holding.createdAt)}`,
    width: 180,
    center: true,
  },
  {
    key: 5,
    title: 'Estado',
    row: holding.isActive ? 'ACTIVO' : 'INACTIVO',
    width: 120,
  },
];

const HoldingCardItem = ({
  isMobile,
  icon,
  title,
  row,
  width,
  colors,
  fontWeights,
  centerText = false,
}) => {
  const classes = useStyles();

  return (
    <Box display={!isMobile ? 'inline-block' : 'block'}>
      <Box display="flex" width={!isMobile ? width : '100%'} my={1.3} px={1}>
        {icon ? <Box className={classes.itemIcon}>{icon}</Box> : null}
        <Box width={!isMobile ? width : '100%'} overflow="auto">
          <Typography align="center" component="div" color="primary" noWrap={!isMobile}>
            <Box
              component="span"
              display="flex"
              justifyContent={centerText && !isMobile ? 'center' : 'start'}
              alignItems="center"
              color={colors?.row}
              fontSize={15}
              fontWeight={fontWeights?.row}
              className={classes.text}
            >
              <Typography className={classes.attributeTitle} component="span" variant="h6">
                {isMobile && `${title}: `}
              </Typography>
              {`${row}`}
            </Box>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const HoldingCard = ({
  holding,
  index,
  isMobile,
}) => {
  const dataCard = getDataCard(holding);
  const classes = useStyles();
  const history = useHistory();

  const holdingListItems = dataCard.map((d) => (
    <HoldingCardItem
      key={d.key}
      isMobile={isMobile}
      icon={d.icon}
      row={d.row}
      title={d.title}
      width={d.width}
      colors={d.colors}
      fontWeights={d.fontWeights}
      centerText={d.center}
      justifyContent="center"
      my={2}
    />
  ));

  return (
    <>
      {!isMobile ? (
        <GeneralCard
          className={classNames(classes.root, index % 2 === 0 ? classes.back1 : classes.back2)}
          onClick={() => { history.push(`/holding-maintainer/${holding.id}/social-reasons`); }}
        >
          {holdingListItems}
        </GeneralCard>
      ) : (
        <GeneralMobileCard
          className={classNames(classes.root, index % 2 === 0 ? classes.back1 : classes.back2)}
          flex={false}
          onClick={() => { history.push(`/holding-maintainer/${holding.id}/social-reasons`); }}
        >
          {holdingListItems}
        </GeneralMobileCard>
      )}
      <div />
    </>
  );
};

export default HoldingCard;
