import { alpha } from '@material-ui/core/styles/colorManipulator';

const styles = (theme) => ({
  requestButton: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(1),
    boxShadow: theme.shadows[4],
    borderRadius: 8,
    marginRight: theme.spacing(8),
    cursor: 'pointer',
    background: ({ selected }) => (selected ? theme.palette.primary.main : 'white'),
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(4),
    },
    [theme.breakpoints.down('xs')]: {
      marginRight: theme.spacing(0),
    },

  },
  requestContent: {
    color: ({ selected }) => (!selected ? theme.palette.primary.main : 'white'),
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    marginRight: theme.spacing(1),
  },
  noRequests: {
    borderRadius: 8,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    boxShadow: theme.shadows[4],
    marginRight: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(4),
    },
    [theme.breakpoints.down('xs')]: {
      marginRight: theme.spacing(0),
    },
  },
  skeletonList: {
    borderRadius: 8,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    boxShadow: theme.shadows[4],
    marginRight: theme.spacing(8),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(4),
    },
    [theme.breakpoints.down('xs')]: {
      marginRight: theme.spacing(0),
    },
    backgroundColor: alpha(theme.palette.primary.main, 0.08),
  },
});

export default styles;
