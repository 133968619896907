import React from 'react';
import { useHistory } from 'react-router-dom';

// Material
import Grid from '@material-ui/core/Grid';

// Project
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { composedComponent } from 'utils/functions';
import { appActions } from 'commons/reducer';
import saga from './saga';
import styles from './styles';
// Component
const useStyles = makeStyles(styles);

const NotFound = () => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item justifyContent="center" alignItems="flex-end" xs={8}>
        <Typography className={classes.mainText}>
          Lo sentimos no encontramos la página que buscas
        </Typography>
        <Typography className={classes.secondaryText} display="inline">
          Puedes volver al inicio clickeando
          {' '}
        </Typography>
        <Typography className={classes.linkText} color="secondary" onClick={() => history.push('/')} display="inline">
          aquí
        </Typography>
      </Grid>
      <Grid item xs={4} container justifyContent="center" alignItems="flex-end">
        <Typography className={classes.errorNumber} color="secondary">
          404
        </Typography>
      </Grid>
    </Grid>
  );
};

export default composedComponent(NotFound, saga, {
  states: [
    'app.user',
  ],
  actions: [appActions],
  saga: 'notFoundSaga',
});
