import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import React, { useState } from 'react';
// Icons
import CloseIcon from '@material-ui/icons/Close';

import CustomTextField from 'commons/components/CustomTextField';
import ButtonWithLoader from 'commons/containers/ButtonWithLoader';
import { formatRut, isValidRut } from 'utils/rut';
import styles from './styles';

const useStyles = makeStyles(styles);

const CreateHoldingDialog = ({ isOpen, onClose, onCreate }) => {
  const classes = useStyles();
  const [name, setName] = useState('');
  const [rut, setRut] = useState('');
  const [createWithSocialReason, setCreateWithSocialReason] = useState(false);
  const [errors, setErrors] = useState({ name: '', rut: '' });

  const validateInputs = () => {
    let isValid = true;
    if (!name) {
      setErrors((prevErrors) => ({ ...prevErrors, name: 'Campo vacío' }));
      isValid = false;
    }
    if (!rut) {
      setErrors((prevErrors) => ({ ...prevErrors, rut: 'Campo vacío' }));
      isValid = false;
    } else if (!isValidRut(rut)) {
      setErrors((prevErrors) => ({ ...prevErrors, rut: 'RUT no válido' }));
      isValid = false;
    }
    return isValid;
  };

  const handleClose = () => {
    onClose();
    setName('');
    setRut('');
  };
  const handleSubmit = () => {
    setErrors({ name: '', rut: '' });
    if (!validateInputs()) return;

    onCreate(name, rut, createWithSocialReason);
  };
  const handleChangeName = (e) => setName(e.target.value);
  const handleChangeRut = (e) => {
    const text = e.target.value;
    const formattedRut = formatRut(text);
    setRut(formattedRut);
  };

  return (
    <Dialog
      open={isOpen}
      fullWidth
    >
      <DialogTitle>
        <Grid container alignItems="center">
          <Grid item className={classes.title}>
            Crear holding
          </Grid>
          <Grid item>
            <IconButton
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container component="span" className={classes.inputContainer}>
          <CustomTextField
            id="inputHoldingName"
            autoFocus
            color="primary"
            value={name}
            onChange={handleChangeName}
            required
            label={'Ingrese el nuevo nombre'.toUpperCase()}
            variant="outlined"
            error={!!errors.name}
            helperText={errors.name}
          />
        </Grid>
        <Grid container>
          <CustomTextField
            id="inputHoldingRut"
            autoFocus
            color="primary"
            value={rut}
            onChange={handleChangeRut}
            required
            label={'Ingrese el nuevo rut'.toUpperCase()}
            variant="outlined"
            error={!!errors.rut}
            helperText={errors.rut}
          />
        </Grid>
        <FormControlLabel
          control={(
            <Checkbox
              name="pendings"
              checked={createWithSocialReason}
              onChange={(e) => setCreateWithSocialReason(e.target.checked)}
            />
          )}
          label="Crear Razón social con el mismo nombre y RUT"
        />
      </DialogContent>
      <DialogActions>
        <div className={classes.buttonContainer}>
          <ButtonWithLoader
            buttonId="cancel"
            variant="outlined"
            onClick={handleClose}
          >
            Cancelar
          </ButtonWithLoader>
        </div>
        <div className={classes.buttonContainer}>
          <ButtonWithLoader
            buttonId="create-holding"
            onClick={handleSubmit}
          >
            Crear holding
          </ButtonWithLoader>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default CreateHoldingDialog;
