import {
  Box, Typography,
} from '@material-ui/core';
import React from 'react';

// Material ui
import Pagination from '@material-ui/lab/Pagination';
import { makeStyles } from '@material-ui/core/styles';

// Project
import GeneralCard from 'commons/components/GeneralCard';
import styles from './styles';
import UserCard from '../UserCard';

const useStyles = makeStyles(styles);
const BlueCardItem = ({
  title, width,
}) => {
  const classes = useStyles();
  return (
    <Box display="inline-block">
      <Box
        display="flex"
        width={width}
      >
        <Box
          overflow="auto"
        >
          <Typography
            component="div"
            className={classes.cardTitle}
            noWrap
          >
            <Box
              fontSize={16}
              display="flex"
              alignItems="center"
              height={24}
              fontWeight={600}
            >
              {title}
            </Box>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const OptionMenu = ({
  position = 'center',
}) => (
  <Box
    display="flex"
    alignItems={position}
    justifyContent="center"
  />
);

const UserList = ({
  users, loading, pagination, actions, isMobile,
}) => {
  const classes = useStyles();
  const { totalPages, page } = pagination;
  return (
    <>
      { isMobile
      && (
      <Typography
        variant="h5"
        color="primary"
        className={classes.listTitle}
      >
        Listado de usuarios
      </Typography>
      )}

      <Box
        display="grid"
        gridTemplateRows="auto"
        overflow="auto"
        padding={1}
      >
        {!isMobile && (
          <GeneralCard
            className={classes.card3}
            rightComponent={(
              <OptionMenu />
)}
          >
            <BlueCardItem title="Nombre" width={230} />
            <BlueCardItem title="Rol" width={310} />
            <BlueCardItem title="Teléfono" width={145} />
            <BlueCardItem title="Correo" width={210} />
            <BlueCardItem title="Creación" width={130} />
            <BlueCardItem title="Deshabilitado" width={140} />
          </GeneralCard>
        )}
        { users.map((user, index) => (
          <UserCard
            user={user}
            index={index}
            key={`${user.id}-${user.email}`}
            actions={actions}
            isMobile={isMobile}
          />
        ))}
      </Box>
      <Pagination
        className={classes.paginationContainer}
        onChange={(e, p) => !loading && actions.changePage(p)}
        hidePrevButton={false}
        hideNextButton={false}
        count={totalPages}
        page={page}
        color="primary"
        size="large"
      />
    </>
  );
};

export default UserList;
