const styles = (theme) => ({
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  grid1: {
    paddingTop: 16,
  },
  grid2: {
    paddingBottom: 16,
    paddingTop: 16,
  },
});

export default styles;
