import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import GeneralCard from 'commons/components/GeneralCard';
import { Box, Typography } from '@material-ui/core';
// Icons
import PersonIcon from '@material-ui/icons/Person';
import DownloadDocument from 'commons/icons/components/DownloadDocument';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import { changeFormatDate, randomString } from 'utils/functions';
import CardMobile from 'screens/Service/component/CardMobile';
import GeneralMobileCard from 'commons/components/GeneralMobileCard';
import classNames from 'classnames';
import CustomTooltip from 'commons/components/CustomTooltip';
import styles from './styles';
import DateDescription from '../descriptions';

const useStyles = makeStyles(styles);

const CardItem = ({
  extraContent, content, width = 150,
}) => {
  const classes = useStyles();
  return (
    <Box display="inline-block">
      <Box
        display="flex"
        width={width}
        className={classes.box}
      >
        <Box
          overflow="auto"
        >
          <Typography
            align="center"
            component="div"
            color="primary"
            noWrap
          >

            <Box
              fontStyle="normal"
              fontSize={16}
              display="flex"
              justifyContent="start"
              alignItems="end"
              className={classes.text}
            >
              {content}
            </Box>
            <Box
              fontStyle="normal"
              fontSize={16}
              display="flex"
              justifyContent="start"
              alignItems="end"
              className={classes.text}
            >
              {extraContent}
            </Box>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const RightComponent = () => (
  <Box
    marginLeft="4px"
    marginRight="8px"
  >
    <DownloadDocument
      fontSize="large"
      color="primary"
    />
  </Box>
);

const LeftComponent = ({
  text, status, textEnabled, textDisabled, showStatus = true, client, width,
}) => {
  const classes = useStyles();

  return (
    <Box
      marginRight={1}
      width={width}
      className={classes.box}
    >
      <Box
        marginLeft={2}
      >
        <Typography
          component="div"
          variant="h4"
          color="primary"
          noWrap
        >
          <Box
            component="span"
            fontStyle="normal"
            fontSize={24}
            className={classes.text2}
          >
            {text}
          </Box>
        </Typography>
        {showStatus
          ? (
            <Box display="flex" alignItems="center">
              <FiberManualRecordIcon
                className={classNames(
                  classes.statusIcon,
                  status ? classes.enabled : classes.disabled,
                )}
              />
              <Typography component="div" variant="body2" color="primary">
                <Box component="span" fontSize={10}>
                  {status ? textEnabled : textDisabled}
                </Box>
              </Typography>
            </Box>
          )
          : null }
        <Box display="flex" width="100%">
          <PersonIcon className={classes.personIcon} color="primary" />
          <Typography component="div" variant="body1" noWrap className={classes.text}>
            <Box display="flex" component="span" fontSize={10}>
              {client}
            </Box>
          </Typography>
        </Box>

      </Box>
    </Box>
  );
};

const ServiceCard = ({
  service, openDetail, index, isMobile,
}) => {
  const classes = useStyles();

  const {
    id,
    externalId,
    status,
    workshopName,
    driverName,
    wantedDateStart,
    wantedDateEnd,
    responsibleName,
    vehicle,
  } = service;

  const { licensePlate, Fleet, contractVehicle } = vehicle;
  const { socialReason } = contractVehicle;
  return (
    <>
      {!isMobile ? (
        <GeneralCard
          onClick={() => openDetail(id)}
          className={index % 2 === 0 ? classes.back1 : classes.back2}
          rightComponent={(<RightComponent />)}
        >
          <LeftComponent
            text={externalId}
            status={status}
            textEnabled="ABIERTO"
            textDisabled="CERRADO"
            width={200}
            client={socialReason?.name}
          />
          <CardItem
            key={randomString()}
            content={licensePlate}
            extraContent={Fleet?.name}
            width={150}
          />
          <CardItem
            key={randomString()}
            content={workshopName || 'No informado'}
            extraContent=""
            width={200}
          />
          <CustomTooltip content={<DateDescription />} width={300}>
            <CardItem
              key={randomString()}
              content={wantedDateStart ? `${changeFormatDate(wantedDateStart)} Ingreso` : 'No informado'}
              extraContent={wantedDateEnd ? `${changeFormatDate(wantedDateEnd)} Estimado` : 'No informado'}
              width={170}
            />
          </CustomTooltip>
          <CardItem
            key={randomString()}
            content={driverName || 'No informado'}
            extraContent=""
            width={200}
          />
          <CardItem
            key={randomString()}
            content={responsibleName || 'No informado'}
            extraContent=""
            width={200}
          />
        </GeneralCard>
      ) : (
        <GeneralMobileCard
          className={index % 2 === 0 ? classes.back1 : classes.back2}
          onClick={() => openDetail(id)}
          spacing={1}
        >
          <CardMobile
            service={service}
          />
        </GeneralMobileCard>
      )}
    </>
  );
};

export default ServiceCard;
