import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const DownloadDocument = (props) => (
  <SvgIcon {...props} viewBox="0 0 21 27">
    <path xmlns="http://www.w3.org/2000/svg" d="M13.125 0H2.625C1.18125 0 0.0131253 1.215 0.0131253 2.7L0 24.3C0 25.785 1.16812 27 2.61187 27H18.375C19.8188 27 21 25.785 21 24.3V8.1L13.125 0ZM18.375 24.3H2.625V2.7H11.8125V9.45H18.375V24.3Z" fill="#002855" />
    <path xmlns="http://www.w3.org/2000/svg" d="M13.8994 15.6332L15.75 17.5367L10.4869 22.9502L5.25 17.5367L7.10063 15.6197L9.1875 17.7662L9.1875 12.1502L11.8125 12.1502L11.8125 17.7662L13.8994 15.6332Z" fill="#002855" />
  </SvgIcon>
);

export default DownloadDocument;
