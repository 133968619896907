import React from 'react';
import ServiceCard from 'screens/Service/component/ServiceCard';
import CustomPagination from 'commons/components/CustomPagination';
import {
  Box, Card, Grid, Typography,
} from '@material-ui/core';
import GeneralCard from 'commons/components/GeneralCard';
import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const CardItem = ({
  title, content, xs, md, icon, extraContent, isMobile, align = 'left',
}) => {
  const classes = useStyles();
  return (
    <Grid item xs={xs} md={md} style={{ textAlign: isMobile ? 'left' : align }}>
      { isMobile
        && (
        <Box className={classes.titleIcon}>
          {icon}
          <Typography className={classes.cardTitle}>
            {title}
          </Typography>
        </Box>
        )}
      <Typography>
        {content}
      </Typography>
      <Typography>
        {extraContent}
      </Typography>
    </Grid>
  );
};

const BlueCardItem = ({
  title, width,
}) => {
  const classes = useStyles();
  return (
    <Box display="inline-block">
      <Box
        display="flex"
        width={width}
      >
        <Box
          overflow="auto"
        >
          <Typography
            component="div"
            className={classes.title}
            noWrap
          >
            <Box
              fontSize={16}
              display="flex"
              alignItems="center"
              height={24}
              fontWeight={600}
            >
              {title}
            </Box>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const OptionMenu = ({
  position = 'center',
}) => (
  <Box
    display="flex"
    alignItems={position}
    justifyContent="center"
  />
);

const ServiceList = ({
  services, pagination, handleOpenDetail, handleChangePage, isMobile,
}) => {
  const { page, totalPages } = pagination;
  const classes = useStyles();

  return (
    <>
      <Box
        padding={1}
        display="grid"
        gridTemplateRows="auto"
        gridGap={8}
        overflow="auto"
      >
        {!isMobile && (
          <GeneralCard
            className={classes.card3}
            rightComponent={(
              <OptionMenu />
            )}
          >
            <BlueCardItem title="Razón social" width={200} />
            <BlueCardItem title="Vehículo" width={150} />
            <BlueCardItem title="Taller" width={200} />
            <BlueCardItem title="Plazos" width={170} />
            <BlueCardItem title="Conductor" width={200} />
            <BlueCardItem title="Responsable" width={200} />
          </GeneralCard>
        )}
        {(!services || services.length === 0) && (
          <Card className={classes.noData}>
            <Grid container justifyContent="center">
              <CardItem content="No hay registros disponibles" md={12} xs={12} isMobile={isMobile} align="center" />
            </Grid>
          </Card>
        )}
        {services && services.length > 0 && services.map((service, i) => (
          <ServiceCard
            index={i}
            key={service.id}
            service={service}
            openDetail={handleOpenDetail}
            isMobile={isMobile}
          />
        ))}
      </Box>
      <CustomPagination
        page={page}
        totalPages={totalPages}
        onChange={handleChangePage}
      />
    </>
  );
};

export default ServiceList;
