import React from 'react';
import {
  Box, makeStyles, useMediaQuery, useTheme,
} from '@material-ui/core';
import { randomString } from 'utils/functions';
import { Pagination } from '@material-ui/lab';
import HeaderList from '../HeaderList';
import styles from './styles';
import SocialReasonCard from './SocialReasonCard';

const useStyles = makeStyles(styles);

const SocialReasonList = ({
  socialReasons: AllSocialReasons,
  pagination, actions, controls, isLoading = false, onEditSocialReason,
}) => {
  const { page, size, totalPages } = pagination;
  const socialReasons = AllSocialReasons.slice(
    Math.max(size * (page - 1), 0), Math.max(size * page, 1),
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();

  return (
    <>
      <Box
        display="grid"
        gridTemplateRows="auto"
        overflow="auto"
        padding={0}
      >
        <HeaderList
          orderByValue={controls.orderBy}
          setOrderBy={actions.setOrderBy}
          changeOrderby={actions.changeOrderby}
          isLoading={false}
          total={2}
          onClick={() => {}}
          loading={false}
        />
        {socialReasons && socialReasons.slice(0, 10).map((socialReason, index) => (
          <SocialReasonCard
            socialReason={socialReason}
            key={socialReason?.id || randomString()}
            isMobile={isMobile}
            onEdit={() => onEditSocialReason(socialReason)}
            index={index}
          />
        ))}
      </Box>
      {/* Paginación */}
      {!!totalPages && (
        <Box display="flex" justifyContent="center" alignItems="end" className={classes.paginationContainer}>
          <Pagination
            onChange={(e, p) => !isLoading && actions.changePage(p)}
            hidePrevButton={!page}
            count={totalPages}
            page={page}
            color="primary"
            size={isMobile ? 'small' : 'large'}
          />
        </Box>
      )}
    </>
  );
};

export default SocialReasonList;
