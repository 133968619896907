import BuildOutlinedIcon from '@material-ui/icons/BuildOutlined';
import DueIcon from '@material-ui/icons/LocalCarWashOutlined';
import User from '@material-ui/icons/AccountCircleOutlined';
import React from 'react';
import {
  CheckCircleOutline, Lock,
  NewReleases,
  ThumbUp,
  Visibility,
} from '@material-ui/icons';

const notificationType = {
  MAINTENANCE: (licensePlate) => ({
    icon: <BuildOutlinedIcon />,
    title: 'Notificación de mantención',
    subTitle: `La próxima mantención de su vehículo con patente ${licensePlate} está próxima a cumplirse`,
  }),
  DUE_CONTRACT: (licensePlate) => ({
    icon: <DueIcon />,
    title: 'Vehículo por vencer',
    subTitle: `Su vehículo con patente ${licensePlate} está próximo a vencer`,
  }),
  DRIVER_REQUEST: (userName) => ({
    icon: <User />,
    title: 'Nuevo conductor',
    subTitle: `Tienes solicitudes pendientes de ${userName}`,
  }),
  PAYMENT_STATUS_RELEASED: (paymentStatus, fleet, holdingName) => ({
    icon: <NewReleases />,
    title: 'Estado de pago liberado',
    subTitle: `El estado de pago ${paymentStatus} del holding ${holdingName} ha sido liberado`,
  }),
  PAYMENT_STATUS_FIX_ISSUE: (paymentStatus, fleet, holdingName) => ({
    icon: <Visibility />,
    title: 'Solicitud de revisión de EP',
    subTitle: `En el estado de pago ${paymentStatus} del holding ${holdingName} ${fleet ? `,se ha solicitado revisión de la flota ${fleet}` : ''}`,
  }),
  PAYMENT_STATUS_ISSUE_FIXED: (paymentStatus, fleet, holdingName) => ({
    icon: <CheckCircleOutline />,
    title: 'Solicitud de revisión de EP resuelta',
    subTitle: `En el estado de pago ${paymentStatus} del holding ${holdingName} ${fleet ? `,se ha resuelto la solicitud en la flota ${fleet}` : ''}`,
  }),
  PAYMENT_STATUS_APPROVED: (paymentStatus, fleet, holdingName) => ({
    icon: <ThumbUp />,
    title: 'Estado de pago aprobado',
    subTitle: `El estado de pago ${paymentStatus} del holding ${holdingName} ha sido aprobado`,
  }),
  PAYMENT_STATUS_CLOSED: (paymentStatus, fleet, holdingName) => ({
    icon: <Lock />,
    title: 'Estado de pago cerrado',
    subTitle: `El estado de pago ${paymentStatus} del holding ${holdingName} ha sido cerrado`,
  }),
};

export default notificationType;
