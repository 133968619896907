import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Skeleton } from '@material-ui/lab';
import { Table, Title } from 'utils/skeletons';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';

import styles from './styles';

const useStyles = makeStyles(styles);
const ServiceSkeleton = () => {
  const Filter = () => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const totalFilter = fullScreen ? 1 : 8;
    return (
      <>
        {Array.from(new Array(totalFilter)).map((val, index) => {
          const k = `${index}-service-skeleton`;
          return (
            <Grid key={k} item xs={12} md={3} lg={2}>
              <Skeleton variant="rect" width="100%" height={56} />
            </Grid>
          );
        })}
      </>
    );
  };
  const classes = useStyles();

  const Kpi = () => <Skeleton variant="rect" width="100%" height={104} />;
  return (
    <Grid
      container
      spacing={2}
    >
      <Grid item xs={12}>
        <Title width={130} />
      </Grid>
      <Filter />
      <Grid item xs={12}>
        <Grid
          container
          spacing={2}
        >
          <Grid item xs={6} md={3}>
            <Kpi />
          </Grid>
          <Grid item xs={6} md={3}>
            <Kpi />
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.grid}>
          <Table
            cells={5}
            height={65}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ServiceSkeleton;
