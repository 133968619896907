const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      gap: theme.spacing(2),
    },
  },
  fleetContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,
    boxShadow: theme.shadows[3],
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(2),
    },
  },
  sinisterContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderRadius: 8,
    boxShadow: theme.shadows[3],
    [theme.breakpoints.up('sm')]: {
    },
  },
  firstText: {
    fontSize: 28,
  },
  secondText: {
    fontSize: 22,
  },
  onMouseHover: {
    cursor: ({ callback }) => (callback ? 'pointer' : 'default'),
  },
});

export default styles;
