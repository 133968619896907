const styles = (theme) => ({
  inputField: {
    minWidth: '40%',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      minWidth: '50%',
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
    },
    '& label.Mui-focused': {
      color: theme.palette.primary.main,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.primary.main,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.primary.main,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.primary.main,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
      },
    },
  },
});

export default styles;
