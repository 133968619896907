import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  makeStyles,
  Typography,
  Switch,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import styles from './styles';

// Icons

// Material
const useStyles = makeStyles(styles);

const SaveChangeButton = ({ title, onClick }) => {
  const classes = useStyles();
  return (
    <Box>
      <Button onClick={onClick} fullWidth={false} className={classes.button} variant="contained" color="secondary">
        {title}
      </Button>
    </Box>
  );
};

const Notification = ({ controls, actions }) => {
  const classes = useStyles();
  const { getNotificationData, saveNotificationConfiguration } = actions;

  useEffect(() => {
    getNotificationData();
  }, []);

  const [system, setSystem] = useState({
    vehicleKmInterval: false,
    vehicleDueDateInterval: false,
  });
  const [othersKmList, setOthersKmList] = useState([]);
  const [kmOptions, setKmOptions] = useState({
    default100: false,
    default500: false,
    default1000: false,
    otherKm: false,
  });

  const [othersMonthList, setOthersMonthList] = useState([2, 23, 4, 32]);
  const [monthOptions, setMonthOptions] = useState({
    default1: false,
    default6: false,
    default12: false,
    otherMonth: false,
  });

  useEffect(() => {
    const {
      kmOptions: responseKmOptions,
      monthOptions: responseMonthOptions,
      system: responseSystem,
    } = controls.notification;

    setKmOptions({ ...kmOptions, ...responseKmOptions });
    setOthersKmList(responseKmOptions.othersKmList);

    setMonthOptions({ ...monthOptions, ...responseMonthOptions });
    setOthersMonthList(responseMonthOptions.othersMonthList);

    setSystem({ ...responseSystem });
  }, [controls]);

  const handleSystemChange = ({ target }) => {
    setSystem({ ...system, [target.name]: target.checked });
  };

  const {
    vehicleKmInterval,
    vehicleDueDateInterval,
  } = system;

  const handleSaveChanges = () => {
    const notification = {
      kmOptions: { ...kmOptions, othersKmList },
      monthOptions: { ...monthOptions, othersMonthList },
      system,
    };
    saveNotificationConfiguration(notification);
  };

  return (
    <>
      <Box className={classes.root}>
        <Typography component="div" color="primary">
          <Box marginBottom={3} fontSize={22}>
            NOTIFICACIONES
          </Box>
        </Typography>
        <FormGroup onChange={handleSystemChange}>
          <FormControlLabel
            control={(
              <Switch
                color="primary"
                checked={vehicleKmInterval}
                name="vehicleKmInterval"
              />
)}
            label="Kilometraje "
          />
          <FormControlLabel
            control={(
              <Switch
                color="primary"
                checked={vehicleDueDateInterval}
                name="vehicleDueDateInterval"
              />
          )}
            label="Devolución"
          />
        </FormGroup>
      </Box>
      <SaveChangeButton title="Guardar Cambios" onClick={() => handleSaveChanges()} />
    </>
  );
};

export default Notification;
