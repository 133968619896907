import { alpha } from '@material-ui/core/styles';

const styles = (theme) => ({
  boxRoot: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid',
    borderRadius: '8px',
    borderColor: alpha(theme.palette.primary.main, 0.75),
    color: theme.palette.primary.main,
    width: '72px',
    height: '32px',
  },
  icon: {
    cursor: 'pointer',
  },
});

export default styles;
