import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography, makeStyles,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';

import BackButton from 'commons/components/BackButton';
import { homeMarketingActions } from 'screens/HomeMarketing/reducer';
import { composedComponent } from 'utils/functions';
import CustomTextField from 'commons/components/CustomTextField';
import ButtonWithLoader from 'commons/containers/ButtonWithLoader';
import roles from 'screens/User/constants';
import styles from './styles';
import NewsletterPagination from './NewsletterPagination';
import saga from './saga';

const useStyles = makeStyles(styles);

const isValidUrl = (urlString) => {
  const urlPattern = new RegExp('^(https?:\\/\\/)' // validate protocol
  + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' // validate domain name
  + '((\\d{1,3}\\.){3}\\d{1,3}))' // validate OR ip (v4) address
  + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' // validate port and path
  + '(\\?[;&a-z\\d%_.~+=-]*)?'); // validate query string
  return !!urlPattern.test(urlString);
};

const AddNewsletterDialog = ({ open, handleClose, handleSubmit }) => {
  const [errors, setErrors] = useState({ title: '', url: '' });
  const [title, setTitle] = useState('');
  const [url, setUrl] = useState('');
  const classes = useStyles();

  const handleCleanState = () => {
    setTitle('');
    setUrl('');
    setErrors({ title: '', url: '' });
  };

  const closeDialog = () => {
    handleCleanState();
    handleClose();
  };

  return (
    <Dialog open={open} onClose={closeDialog} fullWidth>
      <DialogTitle>
        <Grid container alignItems="center">
          <Grid item className={classes.title}>
            Crear Newsletter
          </Grid>
          <Grid item>
            <IconButton
              onClick={closeDialog}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container component="div" className={classes.inputContainer}>
          <CustomTextField
            id="inputTitle"
            autoFocus
            color="primary"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
            label={'Ingrese el nuevo título'.toUpperCase()}
            variant="outlined"
            error={!!errors.title}
            helperText={errors.title}
          />
        </Grid>
        <Grid container component="div" className={classes.inputContainer}>
          <CustomTextField
            id="inputUrl"
            autoFocus
            color="primary"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            required
            label={'Ingrese la nueva url'.toUpperCase()}
            variant="outlined"
            error={!!errors.url}
            helperText={errors.url}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <div className={classes.buttonContainer}>
          <ButtonWithLoader
            buttonId="cancel"
            variant="outlined"
            onClick={closeDialog}
          >
            Cancelar
          </ButtonWithLoader>
        </div>
        <div className={classes.buttonContainer}>
          <ButtonWithLoader
            buttonId="create-holding"
            onClick={() => {
              if (!title) {
                setErrors({ ...errors, title: 'El título es requerido' });
                return;
              }
              if (!url || !isValidUrl(url)) {
                setErrors({ ...errors, url: 'La URL no es válida (ejemplo: https://www.example.com)' });
                return;
              }
              handleSubmit({ title, url });
              handleCleanState();
            }}
          >
            Crear newsletter
          </ButtonWithLoader>
        </div>
      </DialogActions>
    </Dialog>
  );
};

function Newsletter({
  actions, user, newsletterHistory, loadingNewsletterHistory,
}) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedId, setSelectedId] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { type: role } = user.user;

  const handleIframeLoad = () => {
    setIsLoading(false);
  };

  function checkURL(url) {
    return (url?.match(/\.(jpeg|jpg|gif|png)$/) !== null);
  }

  const handleAddNewsletter = ({ title, url }) => {
    actions.addNewsletterHistory({
      title, url, date: (new Date().toISOString()), setSelectedId,
    });
    setIsDialogOpen(false);
  };

  useEffect(() => {
    actions.getNewsletterHistory();
  }, []);

  useEffect(() => {
    if (loadingNewsletterHistory === false) {
      setSelectedId(newsletterHistory[0]?.id);
    }
  }, [loadingNewsletterHistory]);

  if (loadingNewsletterHistory || loadingNewsletterHistory === undefined) {
    return (
      <>
        <BackButton path="/" />
        <Skeleton variant="rect" width="100%" height="100%" />
      </>
    );
  }

  return (
    <>
      <BackButton path="/" />
      <div className={classes.container}>
        <div className={classes.iframeContainer}>
          {(isLoading) && <Skeleton variant="rect" width="100%" height="100%" />}
          {(!isLoading
            && checkURL(newsletterHistory.find((item) => item.id === selectedId)?.url)) ? (
              <img
                src={newsletterHistory.find((item) => item.id === selectedId)?.url}
                alt="newsletter"
                className={classes.img}
              />
            )
            : (
              <iframe
                onLoad={handleIframeLoad}
                style={{ display: isLoading ? 'none' : 'block' }}
                src={newsletterHistory.find((item) => item.id === selectedId)?.url}
                title="Mi Iframe"
              />
            )}
        </div>
      </div>
      {role === roles.ADMINISTRADOR_PORTAL_SALFA && (
        <>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => setIsDialogOpen(true)}
          >
            Agregar Newsletter
          </Button>
          <AddNewsletterDialog
            open={isDialogOpen}
            handleClose={() => setIsDialogOpen(false)}
            handleSubmit={handleAddNewsletter}
          />
        </>
      )}
      <Typography variant="h3" color="textPrimary">
        Newsletters históricos
      </Typography>
      <NewsletterPagination
        newsletterHistory={newsletterHistory}
        selectedId={selectedId}
        setSelectedId={setSelectedId}
        handleEditDialog={() => actions.openEditDialog()}
        actions={actions}
        role={role}
      />
    </>
  );
}

export default composedComponent(Newsletter, saga, {
  saga: 'sagaNewsletter',
  states: [
    'app.user',
    'homeMarketing.loadingNewsletterHistory',
    'homeMarketing.newsletterHistory',
  ],
  actions: [homeMarketingActions],
});
