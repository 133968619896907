import { alpha } from '@material-ui/core/styles/colorManipulator';

const styles = (theme) => ({
  back1: {
    backgroundColor: alpha(theme.palette.primary.main, 0.00),
  },
  back2: {
    backgroundColor: alpha(theme.palette.primary.main, 0.05),
  },
  indicator: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

  },
  boxIndicator: {
    margin: theme.spacing(2),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(1),
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(3),
    },
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(4),
    },
  },
  graphContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },
  progressBar: {
    [theme.breakpoints.up('xs')]: {
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      margin: theme.spacing(1),
    },
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
    [theme.breakpoints.up('xl')]: {
      paddingTop: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingLeft: theme.spacing(4),
    },
  },
  maintenanceTitle: {
    overflow: 'auto',
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  soonTitle: {
    display: 'inline',
    color: theme.palette.text.disabled,
  },
  BarGraph: {
    borderRadius: '8px',
    marginTop: theme.spacing(3),
    padding: '24px',
    height: '80%',
    [theme.breakpoints.down('sm')]: {
      padding: '16px',
    },
  },
  kpiContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      gap: theme.spacing(3),
    },
  },
  kpiElement: {
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(7),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  kpiContainer1: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '36%',
    },
  },
  kpiContainer2: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '28%',
    },
  },
  kpiContainer3: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '36%',
    },
  },
  title: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(1),
  },
});

export default styles;
