const styles = (theme) => ({
  pagination: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: ' end',
  },
});

export default styles;
