import React, { useState } from 'react';

// Material
import { Grid, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

// Icons
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditSharpIcon from '@material-ui/icons/EditSharp';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import { maxStringValue, minStringValue, valueHasData } from 'utils/formValidations';
import styles from './styles';

// Project
import useForm from '../../../../utils/hooks/useForm';
import FleetForm from '../FleetForm';

const useStyles = makeStyles(styles);

export default function Configuration({
  actions,
  controls,
  modals,
  holding,
  fleets = [],
  onChangeInput,
  availableFleetAdmins,
  allFleetAdmins,
  socialReason,
  socialReasons,
}) {
  const {
    creating, editing, fleet,
  } = controls;
  const {
    setCreateMode, setEditMode, createFleet,
    changeFleet, editFleet, cancelFleet, deleteFleet,
  } = actions;
  const classes = useStyles();

  const [show, setShow] = useState(false);

  const {
    onChange, onSubmit, errors, resetErrors,
  } = useForm(controls, null, {
    onChange: onChangeInput,
    validations: {
      inputFleet: [
        {
          check: (value) => valueHasData(value),
          message: 'Ingrese un nombre.',
        },
        {
          check: (value) => minStringValue(value, 4),
          message: 'Nombre mínimo de 4 caracteres.',
        },
        {
          check: (value) => maxStringValue(value, 50),
          message: 'Nombre máximo de 50 caracteres',
        },
      ],
      socialReasonId: [
        {
          check: (value) => valueHasData(value),
          message: 'Debe seleccionar razón social.',
        },
      ],
    },
  });

  const handleCreateFleet = () => {
    onSubmit(() => createFleet());
  };

  const handleEditFleet = () => {
    onSubmit(() => editFleet());
  };

  const handleToggleModal = (module) => {
    actions.toggleModals(module);
  };

  const handleToggleModalFleetForm = () => {
    handleToggleModal('fleetForm');
    if (modals.fleetForm) actions.cancelFleet();
  };

  const handleOpenEditFleetForm = () => {
    const selectedFleet = fleets.find((f) => f.id === fleet);
    setEditMode({
      userId: selectedFleet?.user?.id || '',
      socialReasonId: selectedFleet?.socialReason?.id || '',
      inputFleet: selectedFleet.name,
      selectedFleet,
    });
    handleToggleModalFleetForm();
  };

  const ConfirmationDeleteDialog = () => (
    <Dialog
      open={show}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">¿Desea eliminar la flota seleccionada?</DialogTitle>
      <DialogContent>

        <Typography className={classes.typography}>
          Todos los vehículos pertenecientes a la flota actual serán desasignados.
        </Typography>

      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => setShow(false)} color="primary">
          CANCELAR
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setShow(false);
            deleteFleet();
          }}
          color="secondary"
          autoFocus
        >
          ACEPTAR
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <Grid container spacing={2} className={classes.container}>

      <Grid item sm={5} className={classes.fleetContainer}>
        <FleetForm
          open={modals.fleetForm}
          classes={classes}
          controls={controls}
          availableFleetAdmins={availableFleetAdmins}
          allFleetAdmins={allFleetAdmins}
          onChange={onChange}
          errors={errors}
          handleClose={handleToggleModalFleetForm}
          handleCreateFleet={handleCreateFleet}
          handleEditFleet={handleEditFleet}
          resetErrors={resetErrors}
          currentSocialReason={socialReason}
          socialReasons={socialReasons}
        />
        {/* FLEET CRUD */}
        <FormControl
          fullWidth
          variant="outlined"
          disabled={!holding}
        >
          <InputLabel className={classes.inputLabel} id="demo-simple-select-outlined-label">Flota</InputLabel>
          <Select
            color="primary"
            className={classes.select}
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={fleet}
            onChange={(e) => { changeFleet(e.target.value); }}
            label="Flota"
          >
            <MenuItem key={`${holding}-777`} value="">
              <em>Ninguno</em>
            </MenuItem>
            {fleets.map((f) => <MenuItem key={f.id} value={f.id}>{f.name}</MenuItem>)}
          </Select>
        </FormControl>
      </Grid>
      <Grid item sm={4} md={3}>
        <ConfirmationDeleteDialog />
        {/* FLEET BUTTONS */}
        {!creating && !editing
          ? (
            <>
              <IconButton onClick={() => { setCreateMode(); handleToggleModalFleetForm(); }}>
                <AddCircleIcon color="primary" fontSize="large" />
              </IconButton>
              {fleet
              && (
                <>
                  <IconButton onClick={handleOpenEditFleetForm}>
                    <EditSharpIcon color="primary" fontSize="large" />
                  </IconButton>
                  <IconButton onClick={() => setShow(true)}>
                    <DeleteForeverIcon color="primary" fontSize="large" />
                  </IconButton>
                </>
              )}
            </>
          )
          : (
            <>
              <IconButton onClick={() => {}}>
                <DoneIcon color="primary" fontSize="large" />
              </IconButton>
              <IconButton onClick={() => cancelFleet()}>
                <CloseIcon color="primary" fontSize="large" />
              </IconButton>
            </>
          )}
      </Grid>
    </Grid>
  );
}
