import { createAction, createReducer } from 'utils/reducer';

// Local constants
const PATH = 'request/';

// Global constants for saga
export const REQUEST = {
  FETCH_INITIAL_DATA: `${PATH}FETCH_INITIAL_DATA`,
  FETCH_INITIAL_DATA_SUCCESS: `${PATH}FETCH_INITIAL_DATA_SUCCESS`,
  SELECT_REQUEST: `${PATH}SELECT_REQUEST`,
  DOWN_DRIVER_REQUEST: `${PATH}DOWN_DRIVER_REQUEST`,
  DOWN_DRIVER_REQUEST_ERROR: `${PATH}DOWN_DRIVER_REQUEST_ERROR`,
  DOWN_DRIVER_REQUEST_SUCCESS: `${PATH}DOWN_DRIVER_REQUEST_SUCCESS`,
};

// actions
export const requestActions = {
  getInitialData: createAction(REQUEST.FETCH_INITIAL_DATA),
  selectRequest: createAction(REQUEST.SELECT_REQUEST, 'payload'),
  downDriverRequest: createAction(REQUEST.DOWN_DRIVER_REQUEST),
};

const initialState = {
  controls: {
    selected: null,
  },
  requests: [],
  firstFetch: true,
  loading: false,
  requestLoading: false,
  error: false,
  message: '',
};

const request = createReducer(initialState, {
  [REQUEST.FETCH_INITIAL_DATA]: (state) => ({
    ...state,
    loading: true,
  }),
  [REQUEST.FETCH_INITIAL_DATA_SUCCESS]: (state, action) => ({
    ...state,
    requests: action.payload,
    loading: false,
  }),
  [REQUEST.SELECT_REQUEST]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      selected: action.payload,
    },
  }),
  [REQUEST.DOWN_DRIVER_REQUEST]: (state) => ({
    ...state,
    requestLoading: true,
  }),
  [REQUEST.DOWN_DRIVER_REQUEST_SUCCESS]: (state, action) => ({
    ...state,
    requestLoading: false,
    requests: state.requests.filter((r) => r.id !== action.payload),
    controls: {
      ...initialState.controls,
    },
  }),
  [REQUEST.DOWN_DRIVER_REQUEST_ERROR]: (state) => ({
    ...state,
    requestLoading: false,
    controls: {
      ...initialState.controls,
    },
  }),
});

export default request;
