import { alpha } from '@material-ui/core/styles';

const styles = (theme) => ({
  input: {
    '&::placeholder': {
      color: theme.palette.primary.main,
    },
  },
  autoComplete: {
    '& .MuiOutlinedInput-input': {
      color: theme.palette.primary.main,
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.primary.main,
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
  },
  box: {
    color: alpha(theme.palette.primary.main, 0.35),
    marginRight: '8px',
  },
  borderColor: {
    margin: 0,
    padding: 0,
    '& .MuiFormLabel-root': {
      color: theme.palette.primary.main,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.primary.main,
      },
    },
  },
  input2: {
    margin: 0,
    padding: 0,
  },
});

export default styles;
