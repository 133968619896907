import React from 'react';
import Button from '@material-ui/core/Button';
import ConfirmationDialog from 'commons/components/ConfirmationDialog';

// Material

// icons

const BtnWork = ({
  controls, actions, psId, status,
}) => {
  const handleClose = () => {
    actions.changeControls('confirmationWorkDialog', false);
  };

  const handleSubmit = () => {
    actions.workPaymentStatus(status, psId);
  };

  return (
    <>
      <ConfirmationDialog open={controls.confirmationWorkDialog} onClose={handleClose} onConfirm={handleSubmit} content="¿Desea trabajar el estado de pago?" />
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={() => {
          actions.changeControls('confirmationWorkDialog', true);
        }}
      >
        TRABAJAR ESTADO DE PAGO
      </Button>
    </>
  );
};

export default BtnWork;
