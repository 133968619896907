import React from 'react';
import {
  Grid, IconButton, Input, InputAdornment,
} from '@material-ui/core';

// Material
import Typography from '@material-ui/core/Typography';
// icons
import {
  Clear, GetApp,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import ConfirmationDialog from 'commons/components/ConfirmationDialog';
import styles from './styles';

const useStyles = makeStyles(styles);

const DocumentsByZoneCard = ({
  fleets, actions, controls, psId, roleAndStatusAllowedToDelete,
}) => {
  const classes = useStyles();
  const handleDelete = () => {
    actions.deleteFleetsDocument(controls.fleetsDocumentId, psId);
  };

  const handleClose = () => {
    actions.changeControls('confirmationFleetsDialog', false);
  };

  return (
    <>
      <ConfirmationDialog open={controls.confirmationFleetsDialog} onClose={handleClose} onConfirm={handleDelete} content="¿Desea eliminar el documento?" />
      <Grid
        container
        className={classes.containerDocuments}
      >
        <Grid item xs={12}>
          <Typography variant="h6" color="primary">
            ARCHIVOS ADJUNTOS
          </Typography>
        </Grid>
        {fleets?.map((fleet) => (
          <Grid key={fleet.id} container>
            {fleets.length > 1 && (
              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  color="primary"
                >
                  {fleet.name ? 'Flota' : 'Vehículos sin flota'}
                  {' '}
                  {fleet.name}
                  :
                </Typography>
              </Grid>
            ) }
            {fleet.documents?.map((document) => (
              <Grid key={document.id} item xs={12}>
                <Input
                  key={document.name}
                  className={classes.fileName}
                  value={document.name.slice(37, document.name.length)}
                  fullWidth
                  disabled
                  disableUnderline
                  startAdornment={(
                    <InputAdornment position="start">
                      <IconButton color="primary" onClick={() => window.open(document.path, '_blank', 'noopener,noreferrer')}>
                        <GetApp />
                      </IconButton>
                    </InputAdornment>
                  )}
                  endAdornment={roleAndStatusAllowedToDelete
                    && (
                    <InputAdornment position="end">
                      <IconButton
                        color="primary"
                        onClick={() => {
                          actions.changeControls('confirmationFleetsDialog', true);
                          actions.changeControls('fleetsDocumentId', document.id);
                        }}
                      >
                        <Clear />
                      </IconButton>
                    </InputAdornment>
                    )}
                />
              </Grid>
            ))}
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default DocumentsByZoneCard;
