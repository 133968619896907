import { alpha } from '@material-ui/core/styles';

const styles = (theme) => ({
  backButton: {
    marginLeft: '-16px',
  },
  detailVehicleText: {
    paddingLeft: '175px',
    color: '#002855',
    fontSize: '2em',
  },
  mainContainerTopSection: {
    paddingTop: '30px',
  },
  vehicleItem: {
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '16px',
    },
    paddingLeft: '64px',
    paddingBottom: '18px',
  },
  bigKpiBox: {
    boxShadow: '1px 1px 6px grey',
    width: '290px',
    height: '167px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    borderRadius: '10px',
    alignItems: 'center',
    color: '#002855',
    marginRight: '35px',
  },

  smallKpiBox: {
    boxShadow: '1px 1px 6px grey',
    width: '290px',
    height: '126px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    borderRadius: '10px',
    alignItems: 'center',
    margin: '0px 20px 0px 20px',
    color: '#002855',
  },

  verySmallKpiBox: {
    backgroundColor: '#002855',
    color: '#fff',
    boxShadow: '1px 1px 6px grey',
    width: '290px',
    height: '95px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    borderRadius: '10px',
    alignItems: 'center',
    marginRight: '35px',
  },
  kpiTextContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  kpiText: {
    fontWeight: 'bold',
    fontSize: '1.5em',
  },
  rowTextStyle: { lineHeight: '12px' },
  middleSectionContainer: {

    [theme.breakpoints.up('xs')]: {
      paddingLeft: '64px',
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '16px',
      width: 'calc(100% + 32px)',
      marginLeft: '-16px',
    },
    justifyContent: 'space-between',
    color: '#002855',
    backgroundColor: '#ededed',
    marginTop: '50px',
    paddingTop: '12px',
    width: 'calc(100% + 128px)',
    marginLeft: '-64px',
    paddingBottom: '6px',
  },
  middleSectionIcon: {
    paddingRight: '10px',
    fontSize: '2em',
  },
  divContainer: {
    position: 'relative',
  },
  alertIcon: {
    fill: '#58b256',
    fontSize: '1.2rem',
    marginLeft: '22px',
    marginTop: '20px',
    position: 'absolute',
    paddingLeft: '0px',
  },
  middleSectionGroupContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  bottomSectionContainer: {
    flexDirection: 'row',
  },
  bottomSectionInnerContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '35px',
  },
  uploadDocumentsContainer: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#002855',
    color: '#fff',
    padding: '5px 5px 5px 15px',
    marginTop: '10px',
  },
  uploadDocumentIcon: {
    padding: '10px 20px 10px 10px',
  },
  uploadDocumentInnerContainer: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#ededed',
    color: '#002855',
  },
  uploadDocumentInnerContainerNoColor: {
    display: 'flex',
    alignItems: 'center',
    color: '#002855',
  },
  comercialConditionsContainer: {
    boxShadow: '1px 1px 6px grey',
    padding: '15px 15px 15px 15px',
    marginTop: '10px',
    color: '#002855',
  },

  comercialConditionsTypo: {
    paddingBottom: '3px',
    paddingTop: '3px',
  },
  comercialConditionsInnerContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  statusIcon: {
    marginRight: theme.spacing(1),
    fontSize: 20,
  },
  OPERATIVO: { color: 'green' },
  REEMPLAZADO: { color: 'orange' },
  TALLER: { color: 'red' },

  boxChangeDriver: {
    backgroundColor: theme.palette.primary.main,
    marginLeft: theme.spacing(1),
    padding: theme.spacing(1),
    borderRadius: '4px',
    cursor: 'pointer',
  },
  buttonChangeDriver: {
    color: '#fff',
  },
  boxTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  chip: {
    backgroundColor: alpha(theme.palette.primary.main, 0.08),
    color: theme.palette.primary.main,
    [theme.breakpoints.down('xs')]: {
      marginTop: '16px',
    },
  },
  cardContent: {
    textAlign: 'center',
  },
  cardContentGrid: {
    display: 'flex',
    justifyContent: 'center',
  },
  calendarIcon: {
    fontSize: '1.8em',
    paddingRight: '6px',
    paddingTop: '6px',
  },
  cardContentTypography: {
    fontSize: '1.8em',
    fontWeight: 'bold',
  },
  cardContentTypography2: {
    fontSize: '2.7em',
    fontWeight: 'bold',
  },
  cardContentGrid2: {
    paddingTop: '20px',
  },
  card: {
    color: '#fff',
    height: '95px',
    cursor: 'pointer',
  },
  cardContent2: {
    textAlign: 'center',
    backgroundColor: theme.palette.primary.main,
  },
  cardContentTypography3: {
    fontSize: '1.5em',
    fontWeight: 'bold',
    color: '#fff',
  },
  grid2: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buildIcon: {
    paddingRight: '5px',
  },
  gridRowCard: {
    paddingTop: '53px',
  },
});

export default styles;
