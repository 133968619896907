import { createAction, createReducer } from 'utils/reducer';
import * as APP from '../../../commons/reducer';

// Local constants
const PATH = 'vehicle/';

// Global constants for saga
export const VEHICLE = {
  FETCH_DATA: `${PATH}FETCH_DATA`,
  FETCH_DATA_SUCCESS: `${PATH}FETCH_DATA_SUCCESS`,
  CHANGE_PAGE: `${PATH}CHANGE_PAGE`,
  CHANGE_PAGE_SUCCESS: `${PATH}CHANGE_PAGE_SUCCESS`,
  FETCH_DATA_WITH_FILTER: `${PATH}FETCH_DATA_WITH_FILTER`,
  FETCH_DATA_WITH_FILTER_SUCCESS: `${PATH}FETCH_DATA_WITH_FILTER_SUCCESS`,
  SET_MOBILE_FILTER: `${PATH}SET_MOBILE_FILTER`,
  SET_FILTER: `${PATH}SET_FILTER`,
  SET_ORDER_BY: `${PATH}SET_ORDER_BY`,
  SET_ORDER_BY_SUCCESS: `${PATH}SET_ORDER_BY_SUCCESS`,
  SET_ORDER_BY_NO_FETCH: `${PATH}SET_ORDER_BY_NO_FETCH`,
  CHANGE_ORDER_BY: `${PATH}CHANGE_ORDER_BY`,
  CHANGE_ORDER_BY_SUCCESS: `${PATH}CHANGE_ORDER_BY_SUCCESS`,
  RESET_FILTER: `${PATH}RESET_FILTER`,
  DOWNLOAD_EXCEL: `${PATH}DOWNLOAD_EXCEL`,
  DOWNLOAD_EXCEL_ERROR: `${PATH}DOWNLOAD_EXCEL_ERROR`,
  DOWNLOAD_EXCEL_SUCCESS: `${PATH}DOWNLOAD_EXCEL_SUCCESS`,
  DOWNLOAD_DUPLICATED_EXCEL: `${PATH}DOWNLOAD_DUPLICATED_EXCEL`,
  DOWNLOAD_DUPLICATED_EXCEL_ERROR: `${PATH}DOWNLOAD_DUPLICATED_EXCEL_ERROR`,
  DOWNLOAD_DUPLICATED_EXCEL_SUCCESS: `${PATH}DOWNLOAD_DUPLICATED_EXCEL_SUCCESS`,
  RESET_STATE: `${PATH}RESET_STATE`,
  // Driver button
  CREATE_REQUEST: `${PATH}CREATE_REQUEST`,
  CREATE_REQUEST_SUCCESS: `${PATH}CREATE_REQUEST_SUCCESS`,
  CREATE_REQUEST_ERROR: `${PATH}CREATE_REQUEST_ERROR`,
  ASSIGN_DRIVER: `${PATH}ASSIGN_DRIVER`,
  ASSIGN_DRIVER_ERROR: `${PATH}ASSIGN_DRIVER_ERROR`,
  ASSIGN_DRIVER_SUCCESS: `${PATH}ASSIGN_DRIVER_SUCCESS`,
  VALIDATE_DRIVER_RUN: `${PATH}VALIDATE_DRIVER_RUN`,
  CHANGE_CONTROLS_ASSIGN: `${PATH}CHANGE_CONTROLS_ASSIGN`,
  SELECT_DRIVER: `${PATH}SELECT_DRIVER`,
  OPEN_MODAL: `${PATH}OPEN_MODAL`,
  CLOSE_MODAL: `${PATH}CLOSE_MODAL`,
  RESET_CONTROLS: `${PATH}RESET_CONTROLS`,
  FETCH_DRIVER: `${PATH}FETCH_DRIVER`,
  FETCH_DRIVER_ERROR: `${PATH}FETCH_DRIVER_ERROR`,
  FETCH_DRIVER_SUCCESS: `${PATH}FETCH_DRIVER_SUCCESS`,
  SET_IS_CHANGE: `${PATH}SET_IS_CHANGE`,
};

// actions
export const vehicleActions = {
  getVehicles: createAction(VEHICLE.FETCH_DATA),
  changePage: createAction(VEHICLE.CHANGE_PAGE, 'payload'),
  setFilter: createAction(VEHICLE.SET_FILTER, 'payload'),
  fetchWithFilter: createAction(VEHICLE.FETCH_DATA_WITH_FILTER),
  resetFilters: createAction(VEHICLE.RESET_FILTER),
  setMobileFilter: createAction(VEHICLE.SET_MOBILE_FILTER),
  setOrderBy: createAction(VEHICLE.SET_ORDER_BY, 'payload'),
  setOrderByNoFetch: createAction(VEHICLE.SET_ORDER_BY_NO_FETCH, 'payload'),
  changeOrderBy: createAction(VEHICLE.CHANGE_ORDER_BY),
  downloadExcel: createAction(VEHICLE.DOWNLOAD_EXCEL),
  downloadDuplicatedExcel: createAction(VEHICLE.DOWNLOAD_DUPLICATED_EXCEL),
  resetState: createAction(VEHICLE.RESET_STATE),
  // Driver button
  assignDriver: createAction(VEHICLE.ASSIGN_DRIVER, 'payload'),
  createRequest: createAction(VEHICLE.CREATE_REQUEST, 'payload'),
  validateDriverRun: createAction(VEHICLE.VALIDATE_DRIVER_RUN),
  changeControls: createAction(VEHICLE.CHANGE_CONTROLS_ASSIGN, 'payload'),
  selectDriver: createAction(VEHICLE.SELECT_DRIVER, 'payload'),
  openDriverModal: createAction(VEHICLE.OPEN_MODAL),
  closeDriverModal: createAction(VEHICLE.CLOSE_MODAL),
  resetControls: createAction(VEHICLE.RESET_CONTROLS),
  fetchDrivers: createAction(VEHICLE.FETCH_DRIVER, 'payload'),
  setIsChange: createAction(VEHICLE.SET_IS_CHANGE, 'payload'),

};

const initialState = {
  kpi: {
    availabilityPercentage: 0,
    workshopPercentage: 0,
    sinesters: 0,
    averageKm: 0,
  },
  filtersData: {
    status: [],
    fleets: [],
    zones: [],
    maintenance: [],
    dueDate: [],
  },
  socialReason: [],
  controls: {
    mobileFilter: false,
    reset: false,
    orderBy: '',
    desc: true,
    status: [],
    zone: [],
    fleet: [],
    maintenance: [],
    dueDate: [],
    resetValues: true,
    socialReason: [],
  },
  query: {
    desc: true,
    status: null,
    zone: null,
    fleet: null,
    maintenance: null,
    dueDate: null,
  },
  pagination: {
    size: 7,
    page: 1,
    totalPages: 20,
  },
  // Driver button
  drivers: [],
  isOpen: false,
  loadingDrivers: false,
  errorDrivers: false,
  loadingAssign: false,
  errorAssign: false,
  successAssign: false,
  isChange: true,
  driverExist: false,
  loadingRequest: false,
  successRequest: false,
  formControlsChange: {
    vehicle: null,
    driverRun: null,
    driverName: null,
  },
  formControlsRequest: {
    run: '',
    name: '',
    email: '',
    phone: '',
    socialReason: null,
  },

  firstFetch: true,
  loading: false,
  loadingData: false,
  pageLoading: false,
  error: false,
  message: '',
  loadingDownload: false,
  loadingDownloadDuplicated: false,
};

const vehicle = createReducer(initialState, {
  [VEHICLE.FETCH_DATA]: (state) => ({
    ...state,
    firstFetch: true,
    controls: {
      ...state.controls,
      mobileFilter: state.controls.mobileFilter,
      reset: !state.controls.reset,
    },
    query: {
      ...state.controls,
    },
    pagination: {
      ...state.pagination,
      page: 1,
    },
    loading: true,
  }),
  [VEHICLE.FETCH_DATA_SUCCESS]: (state, action) => ({
    ...state,
    kpi: action.payload.kpi,
    filtersData: {
      ...state.filtersData,
      zones: action.payload.zones,
      status: action.payload.status,
      maintenance: action.payload.maintenance,
      dueDate: action.payload.dueDate,
      fleets: action.payload.fleets,
    },
    pagination: {
      ...state.pagination,
      page: 1,
      totalPages: action.payload.totalPages,
    },
    firstFetch: false,
    loading: false,
    loadingData: true,
  }),
  [VEHICLE.CHANGE_PAGE]: (state) => ({
    ...state,
    pageLoading: true,
  }),
  [VEHICLE.CHANGE_PAGE_SUCCESS]: (state, action) => ({
    ...state,
    pagination: {
      ...state.pagination,
      totalPages: action.payload.totalPages,
      page: action.payload.page,
    },
    pageLoading: false,
  }),
  [VEHICLE.FETCH_DATA_WITH_FILTER]: (state) => ({
    ...state,
    query: {
      ...state.controls,
    },
    loading: true,
  }),
  [VEHICLE.FETCH_DATA_WITH_FILTER_SUCCESS]: (state, action) => ({
    ...state,
    pagination: {
      ...initialState.pagination,
      totalPages: action.payload.totalPages,
    },
    kpi: action.payload.kpi,
    loading: false,
  }),
  [VEHICLE.SET_FILTER]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      [action.payload.key]: action.payload.value,
    },
  }),
  [VEHICLE.RESET_FILTER]: (state) => ({
    ...state,
    loading: true,
    controls: {
      ...initialState.controls,
      socialReason: state.socialReason,
      mobileFilter: state.controls.mobileFilter,
      reset: !state.controls.reset,
    },
    pagination: {
      ...initialState.pagination,
      totalPages: state.pagination.totalPages,
    },
    query: initialState.query,
  }),
  [APP.SET_HOLDING]: (state) => ({
    ...state,
    controls: {
      ...initialState.controls,
      mobileFilter: state.controls.mobileFilter,
      reset: !state.controls.reset,
    },
    pagination: {
      ...initialState.pagination,
      totalPages: state.pagination.totalPages,
    },
    query: initialState.query,
  }),
  [VEHICLE.SET_MOBILE_FILTER]: (state) => ({
    ...state,
    controls: {
      ...state.controls,
      mobileFilter: !state.controls.mobileFilter,
    },
  }),
  [VEHICLE.SET_ORDER_BY_NO_FETCH]: (state, action) => ({
    ...initialState,
    controls: {
      ...state.controls,
      orderBy: action.payload.key,
      desc: action.payload.desc,
    },
  }),
  [VEHICLE.SET_ORDER_BY]: (state, action) => ({
    ...state,
    pageLoading: true,
    controls: {
      ...state.controls,
      orderBy: action.payload,
      desc: !state.controls.desc,
    },
  }),
  [VEHICLE.SET_ORDER_BY_SUCCESS]: (state) => ({
    ...state,
    pageLoading: false,
  }),
  [VEHICLE.CHANGE_ORDER_BY]: (state) => ({
    ...state,
    pageLoading: true,
    controls: {
      ...state.controls,
      desc: !state.controls.desc,
    },
    query: {
      ...state.controls,
      desc: !state.controls.desc,
    },
  }),
  [VEHICLE.CHANGE_ORDER_BY_SUCCESS]: (state) => ({
    ...state,
    pageLoading: false,
  }),
  [VEHICLE.DOWNLOAD_EXCEL]: (state) => ({
    ...state,
    loadingDownload: true,
  }),
  [VEHICLE.DOWNLOAD_EXCEL_SUCCESS]: (state) => ({
    ...state,
    loadingDownload: false,
  }),
  [VEHICLE.DOWNLOAD_EXCEL_ERROR]: (state) => ({
    ...state,
    loadingDownload: false,
  }),
  [VEHICLE.DOWNLOAD_DUPLICATED_EXCEL]: (state) => ({
    ...state,
    loadingDownloadDuplicated: true,
  }),
  [VEHICLE.DOWNLOAD_DUPLICATED_EXCEL_SUCCESS]: (state) => ({
    ...state,
    loadingDownloadDuplicated: false,
  }),
  [VEHICLE.DOWNLOAD_DUPLICATED_EXCEL_ERROR]: (state) => ({
    ...state,
    loadingDownloadDuplicated: false,
  }),
  [VEHICLE.RESET_STATE]: () => ({
    ...initialState,
  }),
  // Driver button
  [VEHICLE.ASSIGN_DRIVER]: (state) => ({
    ...state,
    loadingAssign: true,
    errorAssign: false,
  }),
  [VEHICLE.ASSIGN_DRIVER_SUCCESS]: (state) => ({
    ...state,
    successAssign: true,
    isOpen: false,
    loadingAssign: false,
    errorAssign: false,
  }),
  [VEHICLE.ASSIGN_DRIVER_ERROR]: (state) => ({
    ...state,
    loadingAssign: false,
    errorAssign: true,
  }),
  [VEHICLE.CREATE_REQUEST]: (state) => ({
    ...state,
    loadingRequest: true,
  }),
  [VEHICLE.CREATE_REQUEST_SUCCESS]: (state) => ({
    ...state,
    loadingRequest: false,
    successRequest: true,
  }),
  [VEHICLE.CREATE_REQUEST_ERROR]: (state) => ({
    ...state,
    loadingRequest: false,
  }),
  [VEHICLE.VALIDATE_DRIVER_RUN]: (state) => ({
    ...state,
    isChange: true,
    driverExist: true,
  }),
  [VEHICLE.CHANGE_CONTROLS_ASSIGN]: (state, action) => ({
    ...state,
    formControlsRequest: {
      ...state.formControlsRequest,
      ...action.payload,
    },
  }),
  [VEHICLE.SELECT_DRIVER]: (state, action) => ({
    ...state,
    formControlsChange: {
      ...state.formControlsChange,
      ...action.payload,
    },
  }),
  [VEHICLE.OPEN_MODAL]: (state) => ({
    ...state,
    isOpen: true,
  }),
  [VEHICLE.CLOSE_MODAL]: (state) => ({
    ...state,
    isOpen: false,
    isChange: true,
  }),
  [VEHICLE.RESET_CONTROLS]: (state) => ({
    ...state,
    formControlsChange: initialState.formControlsChange,
    formControlsRequest: initialState.formControlsRequest,
    loading: false,
    error: false,
    loadingDrivers: false,
    loadingAssign: false,
    errorAssign: false,
    successAssign: false,
    isChange: true,
    driverExist: false,
    loadingRequest: false,
    successRequest: false,
  }),
  [VEHICLE.FETCH_DRIVER]: (state) => ({
    ...state,
    loadingDrivers: true,
    errorDrivers: false,
  }),
  [VEHICLE.FETCH_DRIVER_SUCCESS]: (state, action) => ({
    ...state,
    drivers: action.payload,
    loadingDrivers: false,
    errorDrivers: false,
  }),
  [VEHICLE.FETCH_DRIVER_ERROR]: (state) => ({
    ...state,
    loadingDrivers: false,
    errorDrivers: true,
  }),
  [VEHICLE.SET_IS_CHANGE]: (state, action) => ({
    ...state,
    isChange: action.payload,
  }),

  [APP.SET_SOCIAL_REASON_SUCCESS]: (state, action) => ({
    ...state,
    socialReason: action.payload,
    controls: {
      ...state.controls,
      socialReason: action.payload,
    },
  }),
});

export default vehicle;
