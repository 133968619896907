import {
  Button, Card, Grid, makeStyles,
} from '@material-ui/core';
import React, { useState } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { formatRut } from 'utils/rut';
import CardItem from '../HoldingInfoCardItem';
import styles from './styles';
import ReassignDialog from './ReassignDialog';

const useStyles = makeStyles(styles);

const HoldingInfoCard = ({
  holding, onEditHolding, onReassign, holdings,
}) => {
  const [isReassignDialogOpen, setIsReassignDialogOpen] = useState(false);
  const classes = useStyles();

  const handleCloseDialog = () => setIsReassignDialogOpen(false);
  const handleOpenDialog = () => setIsReassignDialogOpen(true);

  return (
    <>
      <Card key={holding.id} className={classNames(classes.back1, classes.boxShadow)}>
        <Grid container alignItems="flex-start" className={classes.back1}>
          <Grid item md={4} xs={12} container>
            <CardItem title="HOLDING ID: " content={holding.id} />
            <CardItem title="NOMBRE: " content={holding.name} />
            <CardItem title="RUT: " content={formatRut(holding.rut)} />
          </Grid>
          <Grid item md={4} xs={12} container>
            <CardItem title="FECHA DE CREACIÓN: " content={moment.utc(holding.createdAt).format('MM-YYYY')} />
            <CardItem title="ESTADO: " content={holding.isActive ? 'ACTIVO' : 'INACTIVO'} />
          </Grid>
          <Grid
            item
            md={2}
            xs={12}
            container
            alignItems="center"
            justifyContent="center"
            className={classes.btnContainer}
          >
            <Button
              variant="contained"
              color="secondary"
              size="large"
              onClick={handleOpenDialog}
              className={classes.filterButton}
            >
              MOVER HOLDING
            </Button>
          </Grid>
          <Grid item md={2} xs={12} container alignItems="center" justifyContent="center" className={classes.btnContainer}>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              onClick={onEditHolding}
              className={classes.filterButton}
            >
              EDITAR
            </Button>
          </Grid>
        </Grid>
      </Card>
      <ReassignDialog
        isOpen={isReassignDialogOpen}
        holdings={holdings}
        holding={holding}
        onClose={handleCloseDialog}
        onSubmit={onReassign}
      />
    </>
  );
};

export default HoldingInfoCard;
