import { createAction, createReducer } from 'utils/reducer';

// Local constants
const PATH = 'account/';

// Global constants for saga
export const ACCOUNT = {
  POST_UPDATE_PASSWORD: `${PATH}POST_UPDATE_PASSWORD`,
  POST_UPDATE_PASSWORD_SUCCESS: `${PATH}POST_UPDATE_PASSWORD_SUCCESS`,
  POST_UPDATE_PASSWORD_ERROR: `${PATH}POST_UPDATE_PASSWORD_ERROR`,
  GET_STATE_FROM_API_USER: `${PATH}GET_STATE_FROM_API_USER`,
  GET_STATE_FROM_API_USER_SUCCESS: `${PATH}GET_STATE_FROM_API_USER_SUCCESS`,
  GET_STATE_FROM_API_USER_ERROR: `${PATH}GET_STATE_FROM_API_USER_ERROR`,
  SET_FORM_CONTROLS: `${PATH}SET_FORM_CONTROLS`,
  RESET_FORM_CONTROLS: `${PATH}RESET_FORM_CONTROLS`,
  GET_NOTIFICATION: `${PATH}GET_NOTIFICATION`,
  GET_NOTIFICATION_SUCCESS: `${PATH}GET_NOTIFICATION_SUCCESS`,
  UPDATE_NOTIFICATION: `${PATH}UPDATE_NOTIFICATION`,
  UPDATE_NOTIFICATION_SUCCESS: `${PATH}UPDATE_NOTIFICATION_SUCCESS`,
  GET_NOTIFICATION_SETTINGS: `${PATH}GET_NOTIFICATION_SETTINGS`,
  GET_NOTIFICATION_SETTINGS_SUCCESS: `${PATH}GET_NOTIFICATION_SETTINGS_SUCCESS`,
  GET_NOTIFICATION_SETTINGS_ERROR: `${PATH}GET_NOTIFICATION_SETTINGS_ERROR`,
};

// actions
export const accountActions = {
  changePassword: createAction(ACCOUNT.POST_UPDATE_PASSWORD, 'formControl'),
  setFormControls: createAction(ACCOUNT.SET_FORM_CONTROLS, 'payload'),
  getStateFromApiUser: createAction(ACCOUNT.GET_STATE_FROM_API_USER),
  getNotification: createAction(ACCOUNT.GET_NOTIFICATION),
  getNotificationSettings: createAction(ACCOUNT.GET_NOTIFICATION_SETTINGS),
  updateNotification: createAction(ACCOUNT.UPDATE_NOTIFICATION, 'payload'),
};

const initialState = {
  controls: {
    holding: [],
    email: '',
    type: '',
    first_name: '',
    last_name: '',
    phone: '',
    password: '',
    verifyPassword: '',
  },
  formControl: {
    currentPassword: '',
    newPassword: '',
    verifyPassword: '',
    weeklyReport: false,
  },
  notification: {
    email: true,
    kmNotification: [],
    dueDateNotification: [],
    loading: false,
    error: false,
  },
  loading: false,
  error: false,
  message: '',
};

const account = createReducer(initialState, {
  [ACCOUNT.GET_STATE_FROM_API_USER]: (state) => ({
    ...state,
    loading: true,
  }),
  [ACCOUNT.GET_STATE_FROM_API_USER_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    controls: action.payload?.user,
  }),
  [ACCOUNT.GET_STATE_FROM_API_USER_ERROR]: (state) => ({
    ...state,
    loading: false,
    error: true,
  }),
  [ACCOUNT.SET_FORM_CONTROLS]: (state, action) => ({
    ...state,
    formControl: {
      ...state.formControl,
      ...action.payload,
    },
  }),
  [ACCOUNT.POST_UPDATE_PASSWORD]: (state) => ({
    ...state,
    loading: true,
  }),
  [ACCOUNT.POST_UPDATE_PASSWORD_SUCCESS]: (state) => ({
    ...state,
    loading: false,
  }),
  [ACCOUNT.RESET_FORM_CONTROLS]: () => ({
    ...initialState,
  }),
  [ACCOUNT.UPDATE_NOTIFICATION]: (state) => ({
    ...state,
    loading: true,
  }),
  [ACCOUNT.UPDATE_NOTIFICATION_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    formControl: {
      ...state.formControl,
      ...action.payload,
    },
  }),
  [ACCOUNT.GET_NOTIFICATION]: (state) => ({
    ...state,
    loading: true,
  }),
  [ACCOUNT.GET_NOTIFICATION_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    formControl: {
      ...state.formControl,
      ...action.payload,
    },
  }),
  [ACCOUNT.GET_NOTIFICATION_SETTINGS]: (state) => ({
    ...state,
    notification: {
      ...state.notification,
      loading: true,
      error: false,
    },
  }),
  [ACCOUNT.GET_NOTIFICATION_SETTINGS_SUCCESS]: (state, action) => ({
    ...state,
    notification: {
      ...state.notification,
      ...action.payload,
      loading: false,
      error: false,
    },
  }),
  [ACCOUNT.GET_NOTIFICATION_SETTINGS_ERROR]: (state) => ({
    ...state,
    notification: {
      ...state.notification,
      loading: false,
      error: true,
    },
  }),
});

export default account;
