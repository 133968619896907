import React from 'react';

import { makeStyles } from '@material-ui/styles';

import Tooltip from '@material-ui/core/Tooltip';
import styles from './styles';

const useStyles = makeStyles(styles);

const CustomTooltip = ({
  children, content, arrow = true, placement = 'bottom', width = 200,
}) => {
  const classes = useStyles({ width });
  return (
    <Tooltip
      interactive
      placement={placement}
      classes={classes}
      arrow={arrow}
      title={<>{content}</>}
    >
      <div>
        {children}
      </div>
    </Tooltip>
  );
};

export default CustomTooltip;
