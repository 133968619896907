const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  boxContainer: {
    backgroundColor: theme.palette.background.default,
  },
  globalContainer: {
    flex: 1,
    marginTop: theme.spacing(12),
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(14),
      marginLeft: theme.spacing(8),
      marginRight: theme.spacing(8),
    },
  },
});

export default styles;
