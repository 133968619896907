import React, { useEffect, useMemo, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import ScreenTitle from 'commons/components/ScreenTitle';
import {
  Box, Typography, useMediaQuery, useTheme,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { makeStyles } from '@material-ui/styles';
import { composedComponent, getParameterByName } from 'utils/functions';
import { workOrderActions } from 'screens/WorkOrder/reducer';
import Filter from 'screens/WorkOrder/component/Filter';
import WorkOrderList from 'screens/WorkOrder/component/List';
import DialogFilter from 'commons/components/DialogFilter';
import WORK_ORDER_TYPES from 'utils/workOrderType';
import DownloadButton from 'commons/components/DownloadButton';
import useSocialReasonFilter from 'commons/components/MultipleFilter/useSocialReasonFilter';
import { screenTime } from 'utils/utils';
import Skeleton from './component/Skeleton';
import styles from './styles';
import saga from './saga';

const useStyles = makeStyles(styles);

const WorkOrder = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [lastHolding, setLastHolding] = useState(null);

  const {
    actions,
    controls,
    types,
    pagination,
    objects,
    holding,
    socialReason,
    isOpen,
    location,
    loading,
    loadingData,
    loadingDownload,
  } = props;

  const { optionsSocialReason } = useSocialReasonFilter(objects.socialReasons);
  const selectValue = controls.socialReason;
  const handleChangeSocialReason = ({ value }) => actions.workChangeControls({ key: 'socialReason', value });

  const prevHoldingDiff = typeof lastHolding === 'number' && holding !== lastHolding;

  useEffect(() => {
    if (!prevHoldingDiff) {
      const service = getParameterByName('service', location.search);
      const type = getParameterByName('type', location.search);

      if (service) actions.workSetFilter({ key: 'service', value: [parseInt(service, 10)] });
      if (Object.values(WORK_ORDER_TYPES).includes(type)) actions.workSetFilter({ key: 'type', value: [type] });

      setLastHolding(holding.id);
    }
  }, []);

  useEffect(() => screenTime('work-orders'), []);

  useEffect(() => {
    if (holding?.id) {
      if (controls.resetValues) {
        actions.workGetInitialData();
        actions.workResetControls();
      } else {
        actions.workFetchWithFilter();
        actions.workSetFilter({ key: 'resetValues', value: true });
      }
    }
  }, [holding, socialReason]);

  const vehicles = useMemo(() => Object.values(objects.vehicles).map((v) => ({
    value: v.id,
    label: v.licensePlate,
  })), [objects.vehicles]);

  const services = useMemo(() => Object.values(objects.services).map((s) => ({
    value: s.id,
    label: s.externalId,
  })), [objects.services]);

  const dataTypes = useMemo(() => types?.map((d) => ({
    value: d,
    label: d,
  })), [types]);

  const handleFilter = () => {
    actions.workFetchWithFilter();
  };

  const handleChangePagination = (page) => {
    actions.workChangePage(page);
  };

  const handleClearFilter = (page) => {
    actions.workClearControls(page);
  };

  const handleDownload = () => {
    actions.workDownloadExcel();
  };

  return (
    !loadingData || loading ? <Skeleton />
      : (
        <>
          <Grid
            container
            justifyContent="space-between"
          >
            <Grid item>
              <ScreenTitle title="TIPOS DE DETENCIÓN" />
            </Grid>
            <Grid item>
              <Typography color="primary" variant="h4">
                <Box
                  display="flex"
                  flexDirection="columns"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box fontWeight={500} fontSize={18}>
                    <Link to="/services" className={classes.link}>
                      VER TODOS LAS DETENCIONES
                    </Link>
                  </Box>
                  <ArrowBackIosIcon color="primary" fontSize="medium" />
                </Box>
              </Typography>
            </Grid>
          </Grid>
          {!isMobile
            ? (
              <Filter
                controls={controls}
                onChange={actions.workChangeControls}
                vehicles={vehicles}
                services={services}
                types={dataTypes}
                handleFilter={handleFilter}
                handleClearFilter={handleClearFilter}
                handleChangeSocialReason={handleChangeSocialReason}
                optionsSocialReason={optionsSocialReason}
                selectValue={selectValue}
              />
            )
            : (
              <DialogFilter
                isOpen={isOpen}
                open={actions.workOpenFilter}
                close={actions.workCloseFilter}
              >
                <Filter
                  controls={controls}
                  onChange={actions.workChangeControls}
                  vehicles={vehicles}
                  services={services}
                  types={dataTypes}
                  handleFilter={handleFilter}
                  handleClearFilter={handleClearFilter}
                  socialReasons={objects.socialReasons}
                  currentSocialReason={socialReason}
                  handleChangeSocialReason={handleChangeSocialReason}
                  optionsSocialReason={optionsSocialReason}
                  selectValue={selectValue}
                />
              </DialogFilter>
            )}
          <Grid
            container
          >
            <Grid item xs={12} align="right">
              <DownloadButton
                total={Object.values(objects.workOrders).length}
                onClick={handleDownload}
                loading={loadingDownload}
              />
            </Grid>
          </Grid>
          <WorkOrderList
            workOrders={Object.values(objects.workOrders)}
            pagination={pagination}
            handleChangePagination={handleChangePagination}
            isMobile={isMobile}
          />
        </>
      )
  );
};

export default composedComponent(WorkOrder, saga, {
  saga: 'sagaWorkOrder',
  states: [
    'workOrder.loading',
    'workOrder.loadingData',
    'workOrder.controls',
    'workOrder.types',
    'workOrder.pagination',
    'workOrder.isOpen',
    'workOrder.query',
    'workOrder.loadingDownload',
    'app.objects',
    'app.holding',
    'app.socialReason',
  ],
  actions: [workOrderActions],
});
