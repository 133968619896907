import React, { useMemo } from 'react';
import CustomDialogFullScreen from 'commons/components/CustomDialogFullScreen';
import CustomAccordion from 'commons/components/CustomAccordion';
import Typography from '@material-ui/core/Typography';

const NotificationMobile = ({
  isOpen, clear, notifications, handleClick, handleClose,
}) => {
  const notificationType = useMemo(() => notifications.reduce((acc, item) => {
    const x = [...(acc[item.type] ?? []), item];
    return { ...acc, [item.type]: x };
  }, {}), [notifications]);

  const {
    MAINTENANCE, DUE_CONTRACT, DRIVER_REQUEST, ...paymentStatusNotifications
  } = notificationType;

  const AccordionType = [
    {
      id: 'MAINTENANCE',
      title: 'Mantención',
    },
    {
      id: 'DUE_CONTRACT',
      title: 'Vencimiento',
    },
    {
      id: 'DRIVER_REQUEST',
      title: 'Solicitudes',
    },
    {
      id: 'PAYMENT_STATUS',
      title: 'Estados de pago',
    },
  ];

  return (
    <CustomDialogFullScreen
      isOpen={isOpen}
      clear={clear}
      title="Notificaciones"
    >
      {notifications?.length > 0
        ? (
          AccordionType.map((a) => {
            if ((a.id === 'MAINTENANCE' || a.id === 'DUE_CONTRACT' || a.id === 'DRIVER_REQUEST') && notificationType[a.id]) {
              return (
                <CustomAccordion
                  key={a.id}
                  id={a.id}
                  title={a.title}
                  total={notificationType[a.id]?.length}
                  notifications={notificationType[a.id]}
                  onClickNotification={handleClick}
                  handleClose={handleClose}
                />
              );
            }
            if ((notificationType.PAYMENT_STATUS_RELEASED
              || notificationType.PAYMENT_STATUS_FIX_ISSUE
              || notificationType.PAYMENT_STATUS_ISSUE_FIXED
              || notificationType.PAYMENT_STATUS_APPROVED
              || notificationType.PAYMENT_STATUS_CLOSED) && a.id === 'PAYMENT_STATUS') {
              return (
                <CustomAccordion
                  key="PAYMENT_STATUS"
                  id="PAYMENT_STATUS"
                  title="Estados de pago"
                  total={
                    (notificationType?.PAYMENT_STATUS_RELEASED?.length || 0)
                    + (notificationType?.PAYMENT_STATUS_CLOSED?.length || 0)
                    + (notificationType?.PAYMENT_STATUS_FIX_ISSUE?.length || 0)
                    + (notificationType?.PAYMENT_STATUS_ISSUE_FIXED?.length || 0)
                    + (notificationType?.PAYMENT_STATUS_APPROVED?.length || 0)
                  }
                  notifications={
                    [].concat(
                      ...Object.keys(paymentStatusNotifications)
                        .map((key) => paymentStatusNotifications[key]),
                    )
                  }
                  onClickNotification={handleClick}
                  handleClose={handleClose}
                />
              );
            }
            return false;
          }))
        : (
          <Typography variant="h4" color="primary">
            Sin nuevas
            notificaciones
          </Typography>
        )}
    </CustomDialogFullScreen>
  );
};

export default NotificationMobile;
