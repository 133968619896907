import React, { useCallback, useEffect } from 'react';

// Material
import { withStyles } from '@material-ui/core';
// import Grid from '@mui/material/Grid';

// Project
import { composedComponent } from 'utils/functions';
import ScreenTitle from 'commons/components/ScreenTitle';
import { paymentStatusConfigActions } from './reducer';
import saga from './saga';

// Components

// Utils

// Styles
import styles from './styles';
import Form from './components/Form.js';

const PaymentStatusConfig = (props) => {
  const {
    classes, controls, actions,
  } = props;

  useEffect(() => {
    actions.getStateFromApi();
  }, []);

  const handleFieldChange = useCallback((e) => {
    const { name, checked } = e.target;
    actions.changeControls({ fields: { ...controls.fields, [name]: checked } });
  }, [actions, controls]);

  const handleSubmit = useCallback(() => {
    actions.submitSettings();
  }, [actions]);

  return (
    <>
      <ScreenTitle title="CONFIGURACIÓN DEL ESTADO DE PAGO" />
      <Form
        classes={classes}
        controls={controls}
        handleFieldChange={handleFieldChange}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

export default composedComponent(PaymentStatusConfig, saga, {
  saga: 'sagaPaymentStatusConfig',
  middlewares: [withStyles(styles)],
  states: [
    'app.user',
    'app.objects',
    'paymentStatusConfig.controls'],
  actions: [paymentStatusConfigActions],
});
