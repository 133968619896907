import React, { useCallback, useEffect, useMemo } from 'react';
import Form from 'screens/Quote/component/Form';
import { quoteActions } from 'screens/Quote/reducer';
import { appActions } from 'commons/reducer';
import { composedComponent, validateEmail, validateTaxNumber } from 'utils/functions';
import saga from 'screens/Quote/saga';
import useForm from 'utils/hooks/useForm';
import { useHistory } from 'react-router-dom';

const Quote = (props) => {
  const {
    loading, formControls, socialReasons, actions, success, user,
  } = props;
  const history = useHistory();

  useEffect(() => {
    actions.getSocialReasons();
  }, []);

  useEffect(() => {
    if (success) {
      actions.resetControls();
      history.push('/');
    }
  }, [success]);

  useEffect(() => {
    actions.resetControls();
  }, []);

  const socialReasonsData = useMemo(() => socialReasons.map((s) => ({
    label: s.name,
    value: s.name,
    taxNumber: s.rut,
  })), [socialReasons]);

  useEffect(() => {
    const dataUser = user.user || null;

    if (dataUser && socialReasonsData.length > 0) {
      const { name, email, phone } = dataUser;
      actions.getInitialData({
        contactClient: name,
        contactEmail: email,
        phone,
        ccu: socialReasonsData[0]?.label,
      });
    }
  }, [socialReasonsData]);

  const changeControls = useCallback((name, value) => {
    actions.changeControls({ [name]: value });
  }, []);

  const changeVehicleTypes = (key, attribute, value) => {
    actions.changeVehicleTypes({ key, attribute, value });
  };

  const { onChange, onSubmit, errors } = useForm(formControls, null, {
    onChange: changeControls,
    validations: {
      ccu: [
        {
          check: (value) => !!value && value.trim().length > 0,
          message: 'La Razón social es requerida',
        },
      ],
      taxNumber: [
        {
          check: (value) => !!value && value.trim().length > 0,
          message: 'El RUT es requerido',
        },
        {
          check: (value) => validateTaxNumber(value),
          message: 'El RUT es inválido',
        },
      ],
      contactClient: [
        {
          check: (value) => !!value && value.trim().length > 0,
          message: 'El Contacto es requerido',
        },
      ],
      contactEmail: [
        {
          check: (value) => !!value && value.trim().length > 0,
          message: 'EL Mail es requerido',
        },
        {
          check: (value) => validateEmail(value),
          message: 'EL Mail es inválido',
        },
      ],
      phone: [
        {
          check: (value) => !!value && value.trim().length > 0,
          message: 'El Teléfono es requerido',
        },
        {
          check: (value) => !(value.trim().length < 9),
          message: 'Ingrese al menos 9 números',
        },
      ],
      location: [
        {
          check: (value) => !!value && value.trim().length > 0,
          message: 'El Lugar de entrega es requerido',
        },
      ],
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(() => actions.sentQuote(formControls));
  };

  return (
    <Form
      controls={formControls}
      onChange={onChange}
      changeControls={actions.changeControls}
      errors={errors}
      onSubmit={handleSubmit}
      changeVehicleTypes={changeVehicleTypes}
      loading={loading}
      socialReasons={socialReasonsData}
    />
  );
};

export default composedComponent(Quote, saga, {
  states: [
    'quote.loading',
    'quote.success',
    'quote.formControls',
    'quote.socialReasons',
    'app.user',
  ],
  actions: [quoteActions, appActions],
  saga: 'quoteSaga',
});
