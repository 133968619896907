import * as APP from 'commons/reducer';
import { createAction, createReducer } from 'utils/reducer';

// Local constants
const PATH = 'workOrder/';

// Global constants for saga
export const WORKORDER = {
  FETCH_INITIAL_DATA: `${PATH}FETCH_INITIAL_DATA`,
  FETCH_INITIAL_DATA_SUCCESS: `${PATH}FETCH_DATA_SUCCESS`,
  CHANGE_CONTROLS: `${PATH}CHANGE_CONTROLS`,
  RESET_CONTROLS: `${PATH}RESET_CONTROLS`,
  CLEAR_CONTROLS: `${PATH}CLEAR_CONTROLS`,
  CLEAR_CONTROLS_SUCCESS: `${PATH}CLEAR_CONTROLS_SUCCESS`,
  // Change page
  CHANGE_PAGE: `${PATH}CHANGE_PAGE`,
  CHANGE_PAGE_SUCCESS: `${PATH}CHANGE_PAGE_SUCCESS`,
  // Filters
  FETCH_DATA_WITH_FILTER: `${PATH}FETCH_DATA_WITH_FILTER`,
  FETCH_DATA_WITH_FILTER_SUCCESS: `${PATH}FETCH_DATA_WITH_FILTER_SUCCESS`,
  // Modal Filter mobile
  OPEN_FILTER: `${PATH}OPEN_FILTER`,
  CLOSE_FILTER: `${PATH}CLOSE_FILTER`,
  SET_FILTER: `${PATH}SET_FILTER`,
  // Download Excel
  DOWNLOAD_EXCEL: `${PATH}DOWNLOAD_EXCEL`,
  DOWNLOAD_EXCEL_ERROR: `${PATH}DOWNLOAD_EXCEL_ERROR`,
  DOWNLOAD_EXCEL_SUCCESS: `${PATH}DOWNLOAD_EXCEL_SUCCESS`,
};

// actions
export const workOrderActions = {
  workGetInitialData: createAction(WORKORDER.FETCH_INITIAL_DATA),
  workChangeControls: createAction(WORKORDER.CHANGE_CONTROLS, 'payload'),
  workResetControls: createAction(WORKORDER.RESET_CONTROLS),
  workClearControls: createAction(WORKORDER.CLEAR_CONTROLS),
  workFetchWithFilter: createAction(WORKORDER.FETCH_DATA_WITH_FILTER),
  workChangePage: createAction(WORKORDER.CHANGE_PAGE, 'payload'),
  workOpenFilter: createAction(WORKORDER.OPEN_FILTER),
  workCloseFilter: createAction(WORKORDER.CLOSE_FILTER),
  workSetFilter: createAction(WORKORDER.SET_FILTER, 'payload'),
  workDownloadExcel: createAction(WORKORDER.DOWNLOAD_EXCEL),
};

const initialState = {
  controls: {
    vehicle: [],
    service: [],
    type: [],
    status: [],
    resetValues: true,
    socialReason: [],
  },
  socialReason: [],
  query: {
    vehicle: [],
    service: [],
    type: [],
    status: [],
  },
  pagination: {
    size: 8,
    page: 1,
    totalPages: 1,
  },
  types: [],
  // Modal filter
  isOpen: false,
  firstFetch: true,
  pageLoading: false,
  loading: false,
  loadingData: false,
  error: false,
  message: '',
  loadingDownload: false,
};

const workOrder = createReducer(initialState, {
  [WORKORDER.FETCH_INITIAL_DATA]: (state) => ({
    ...state,
    firstFetch: true,
    loading: true,
  }),
  [WORKORDER.FETCH_INITIAL_DATA_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    types: Array.isArray(action.payload.types) ? action.payload.types : [],
    pagination: {
      ...state.pagination,
      totalPages: action.payload.totalPages,
    },
    workshops: action.payload.workshop,
    firstFetch: false,
    loadingData: true,
  }),
  [WORKORDER.CHANGE_CONTROLS]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      [action.payload.key]: action.payload.value,
    },
  }),
  [WORKORDER.FETCH_DATA_WITH_FILTER]: (state) => ({
    ...state,
    pagination: {
      ...initialState.pagination,
      totalPages: state.pagination.totalPages,
    },
    query: state.controls,
    pageLoading: true,
    loading: true,
  }),
  [WORKORDER.FETCH_DATA_WITH_FILTER_SUCCESS]: (state, action) => ({
    ...state,
    pagination: {
      ...state.pagination,
      totalPages: action.payload.totalPages,
    },
    loading: false,
    loadingData: true,
    pageLoading: false,
  }),
  [WORKORDER.RESET_CONTROLS]: (state) => ({
    ...state,
    controls: {
      ...initialState.controls,
      socialReason: state.socialReason,
    },
    query: {
      ...initialState.query,
    },
  }),
  [WORKORDER.CLEAR_CONTROLS]: (state) => ({
    ...state,
    controls: {
      ...initialState.controls,
    },
    query: initialState.query,
    pageLoading: true,
  }),
  [WORKORDER.CLEAR_CONTROLS_SUCCESS]: (state, action) => ({
    ...state,
    controls: {
      ...initialState.controls,
    },
    pagination: {
      ...initialState.pagination,
      totalPages: action.payload.totalPages,
    },
    pageLoading: false,
  }),

  [WORKORDER.CHANGE_PAGE]: (state) => ({
    ...state,
    pageLoading: true,
  }),

  [WORKORDER.CHANGE_PAGE_SUCCESS]: (state, action) => ({
    ...state,
    pagination: {
      ...state.pagination,
      totalPages: action.payload.totalPages,
      page: action.payload.page,
    },
    pageLoading: false,
  }),
  // Modal filter mobile
  [WORKORDER.OPEN_FILTER]: (state) => ({
    ...state,
    isOpen: true,
  }),
  [WORKORDER.CLOSE_FILTER]: (state) => ({
    ...state,
    isOpen: false,
  }),
  [WORKORDER.SET_FILTER]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      [action.payload.key]: action.payload.value,
    },
  }),
  [WORKORDER.DOWNLOAD_EXCEL]: (state) => ({
    ...state,
    loadingDownload: true,
  }),
  [WORKORDER.DOWNLOAD_EXCEL_SUCCESS]: (state) => ({
    ...state,
    loadingDownload: false,
  }),
  [WORKORDER.DOWNLOAD_EXCEL_ERROR]: (state) => ({
    ...state,
    loadingDownload: false,
  }),
  [APP.SET_SOCIAL_REASON_SUCCESS]: (state, action) => ({
    ...state,
    socialReason: action.payload,
    controls: {
      ...state.controls,
      socialReason: action.payload,
    },
  }),
});

export default workOrder;
