import React from 'react';
import {
  Button,
  Dialog, DialogActions, DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import ButtonWithLoader from 'commons/containers/ButtonWithLoader';

const ConfirmationDialog = ({
  open, onClose, onConfirm, content,
}) => (
  <Dialog
    open={open}
    onClose={onClose}
  >
    <DialogTitle>Confirmación</DialogTitle>
    <DialogContent>
      <DialogContentText>
        {content}
      </DialogContentText>
    </DialogContent>
    <DialogActions style={{ margin: 16 }}>
      <Button autoFocus onClick={onClose} variant="outlined" color="secondary">
        Cancelar
      </Button>
      <ButtonWithLoader
        buttonId="confirmation-button"
        onClick={onConfirm}
        color="primary"
        variant="contained"
      >
        Confirmar
      </ButtonWithLoader>

    </DialogActions>
  </Dialog>
);
export default ConfirmationDialog;
