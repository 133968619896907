import * as APP from 'commons/reducer';
import { createAction, createReducer } from 'utils/reducer';

// Local constants
const PATH = 'service/';

// Global constants for saga
export const SERVICE = {
  FETCH_INITIAL_DATA: `${PATH}FETCH_INITIAL_DATA`,
  FETCH_INITIAL_DATA_SUCCESS: `${PATH}FETCH_DATA_SUCCESS`,
  CHANGE_CONTROLS: `${PATH}CHANGE_CONTROLS`,
  CLEAR_CONTROLS: `${PATH}CLEAR_CONTROLS`,
  CLEAR_CONTROLS_SUCCESS: `${PATH}CLEAR_CONTROLS_SUCCESS`,
  RESET_CONTROLS: `${PATH}RESET_CONTROLS`,
  // Change page
  CHANGE_PAGE: `${PATH}CHANGE_PAGE`,
  CHANGE_PAGE_SUCCESS: `${PATH}CHANGE_PAGE_SUCCESS`,
  // Filters
  FETCH_DATA_WITH_FILTER: `${PATH}FETCH_DATA_WITH_FILTER`,
  FETCH_DATA_WITH_FILTER_SUCCESS: `${PATH}FETCH_DATA_WITH_FILTER_SUCCESS`,
  SET_VEHICLE_FILTER: `${PATH}SET_VEHICLE_FILTER`,
  // Modal Filter mobile
  OPEN_FILTER: `${PATH}OPEN_FILTER`,
  CLOSE_FILTER: `${PATH}CLOSE_FILTER`,
  // Modal detail service
  OPEN_DETAIL: `${PATH}OPEN_DETAIL`,
  CLOSE_DETAIL: `${PATH}CLOSE_DETAIL`,
  // Order
  SET_ORDER_BY: `${PATH}SET_ORDER_BY`,
  SET_ORDER_BY_SUCCESS: `${PATH}SET_ORDER_BY_SUCCESS`,
  // Change order desc or asc
  CHANGE_ORDER_BY: `${PATH}CHANGE_ORDER_BY`,
  CHANGE_ORDER_BY_SUCCESS: `${PATH}CHANGE_ORDER_BY_SUCCESS`,
  // Download Excel
  DOWNLOAD_EXCEL: `${PATH}DOWNLOAD_EXCEL`,
  DOWNLOAD_EXCEL_ERROR: `${PATH}DOWNLOAD_EXCEL_ERROR`,
  DOWNLOAD_EXCEL_SUCCESS: `${PATH}DOWNLOAD_EXCEL_SUCCESS`,
};

// actions
export const serviceActions = {
  getInitialData: createAction(SERVICE.FETCH_INITIAL_DATA, 'payload'),
  changeControls: createAction(SERVICE.CHANGE_CONTROLS, 'payload'),
  clearControls: createAction(SERVICE.CLEAR_CONTROLS),
  fetchWithFilter: createAction(SERVICE.FETCH_DATA_WITH_FILTER),
  changePage: createAction(SERVICE.CHANGE_PAGE, 'payload'),
  openFilter: createAction(SERVICE.OPEN_FILTER),
  closeFilter: createAction(SERVICE.CLOSE_FILTER),
  openDetail: createAction(SERVICE.OPEN_DETAIL),
  closeDetail: createAction(SERVICE.CLOSE_DETAIL),
  setOrderBy: createAction(SERVICE.SET_ORDER_BY, 'payload'),
  changeOrderBy: createAction(SERVICE.CHANGE_ORDER_BY),
  resetControl: createAction(SERVICE.RESET_CONTROLS),
  downloadExcel: createAction(SERVICE.DOWNLOAD_EXCEL),
};

const initialState = {
  controls: {
    vehicle: [],
    workshop: [],
    fleet: [],
    dateRange: [null, null],
    selectedDate: 'faultDateStart',
    status: [],
    orderBy: '',
    desc: true,
    socialReason: [],
  },
  socialReason: [],
  query: {
    vehicle: [],
    workshop: [],
    fleet: [],
    wantedStartDate: null,
    wantedEndDate: null,
    status: [],
    orderBy: '',
    desc: true,
  },
  kpi: {
    totalMaintenance: 0,
    outOfDateServices: 0,
  },
  pagination: {
    size: 5,
    page: 1,
    totalPages: 1,
  },
  workshops: [],
  openDetail: false,
  // Modal filter
  isOpen: false,
  firstFetch: true,
  pageLoading: false,
  loading: false,
  loadingData: false,
  error: false,
  message: '',
  loadingDownload: false,
};
const service = createReducer(initialState, {
  [SERVICE.FETCH_INITIAL_DATA]: (state) => ({
    ...state,
    firstFetch: true,
    loading: true,
    pagination: {
      ...state.pagination,
      page: 1,
    },
  }),
  [SERVICE.SET_VEHICLE_FILTER]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      vehicle: action.payload,
    },
    query: {
      ...state.query,
      vehicle: action.payload,
    },
  }),
  [SERVICE.FETCH_INITIAL_DATA_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    kpi: action.payload.kpi,
    pagination: {
      ...state.pagination,
      totalPages: action.payload.totalPages,
      page: 1,
    },
    workshops: action.payload.workshop,
    firstFetch: false,
    loadingData: true,
  }),
  [SERVICE.CHANGE_CONTROLS]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      [action.payload.key]: action.payload.value,
    },
  }),
  [SERVICE.RESET_CONTROLS]: (state) => ({
    ...state,
    controls: {
      ...initialState.controls,
      socialReason: state.socialReason,
    },
    query: {
      ...initialState.query,
    },
  }),
  [SERVICE.FETCH_DATA_WITH_FILTER]: (state) => ({
    ...state,
    pagination: {
      ...initialState.pagination,
      totalPages: state.pagination.totalPages,
    },
    query: { ...state.controls },
    pageLoading: true,
  }),
  [SERVICE.FETCH_DATA_WITH_FILTER_SUCCESS]: (state, action) => ({
    ...state,
    pagination: {
      ...state.pagination,
      totalPages: action.payload.totalPages,
    },
    pageLoading: false,
  }),
  [SERVICE.CLEAR_CONTROLS]: (state) => ({
    ...state,
    controls: {
      ...initialState.controls,
    },
    query: { ...initialState.query },
    pageLoading: true,
  }),
  [SERVICE.CLEAR_CONTROLS_SUCCESS]: (state, action) => ({
    ...state,
    controls: {
      ...initialState.controls,
    },
    pagination: {
      ...initialState.pagination,
      totalPages: action.payload.totalPages,
    },
    pageLoading: false,
  }),

  [SERVICE.CHANGE_PAGE]: (state) => ({
    ...state,
    pageLoading: true,
  }),

  [SERVICE.CHANGE_PAGE_SUCCESS]: (state, action) => ({
    ...state,
    pagination: {
      ...state.pagination,
      totalPages: action.payload.totalPages,
      page: action.payload.page,
    },
    pageLoading: false,
  }),
  // Modal filter mobile
  [SERVICE.OPEN_FILTER]: (state) => ({
    ...state,
    isOpen: true,
  }),
  [SERVICE.CLOSE_FILTER]: (state) => ({
    ...state,
    isOpen: false,
  }),
  [SERVICE.OPEN_DETAIL]: (state) => ({
    ...state,
    openDetail: true,
  }),
  [SERVICE.CLOSE_DETAIL]: (state) => ({
    ...state,
    openDetail: false,
  }),
  [SERVICE.SET_ORDER_BY]: (state, action) => ({
    ...state,
    loading: true,
    controls: {
      ...state.controls,
      orderBy: action.payload,
    },
    query: {
      ...state.controls,
      orderBy: action.payload,
    },
  }),
  [SERVICE.SET_ORDER_BY_SUCCESS]: (state) => ({
    ...state,
    loading: false,
  }),
  [SERVICE.CHANGE_ORDER_BY]: (state) => ({
    ...state,
    loading: true,
    controls: {
      ...state.controls,
      desc: !state.controls.desc,
    },
    query: {
      ...state.controls,
      desc: !state.controls.desc,
    },
  }),
  [SERVICE.CHANGE_ORDER_BY_SUCCESS]: (state) => ({
    ...state,
    loading: false,
  }),
  [SERVICE.DOWNLOAD_EXCEL]: (state) => ({
    ...state,
    loadingDownload: true,
  }),
  [SERVICE.DOWNLOAD_EXCEL_SUCCESS]: (state) => ({
    ...state,
    loadingDownload: false,
  }),
  [SERVICE.DOWNLOAD_EXCEL_ERROR]: (state) => ({
    ...state,
    loadingDownload: false,
  }),
  [APP.SET_SOCIAL_REASON_SUCCESS]: (state, action) => ({
    ...state,
    socialReason: action.payload,
    controls: {
      ...state.controls,
      socialReason: action.payload,
    },
  }),
});

export default service;
