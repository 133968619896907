import commonStyles from 'utils/commonStyles';

const styles = (theme) => ({
  autoComplete: {
    '& .MuiOutlinedInput-input': {
      color: theme.palette.primary.main,
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.primary.main,
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
  },
  fleetIconButton: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: theme.palette.grey[500],
  },
  fleetGrid: {
    marginBottom: '24px',
  },
  formHelperText: {
    color: '#f35549',
    marginLeft: '16px',
  },
  fleetDiv: {
    marginTop: '24px',
    marginBottom: '16px',
  },
  fleetDiv2: {
    marginRight: '12px',
  },
  link: {
    fontWeight: 'bold',
  },
  ...commonStyles,
});

export default styles;
