import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Table, Title } from 'utils/skeletons';
import { Skeleton } from '@material-ui/lab';
import { randomString } from 'utils/functions';
import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const HoldingMaintainerSkeleton = () => {
  const Filter = () => (
    Array.from(new Array(4)).map(() => (
      <Grid key={randomString()} item xs={3}>
        <Skeleton variant="rect" width="100%" height={50} />
      </Grid>
    ))
  );

  const Rect = () => <Skeleton variant="rect" width="100%" height={80} />;
  const classes = useStyles();
  return (
    <Grid
      container
      spacing={1}
      justifyContent="flex-end"
    >
      <Grid item xs={12}>
        <Title width={100} />
      </Grid>
      <Filter />
      <Grid item xs={4} md={2} className={classes.grid}>
        <Rect />
      </Grid>
      <Grid item xs={12} align="center">
        <Table cells={5} />
      </Grid>

    </Grid>
  );
};

export default HoldingMaintainerSkeleton;
