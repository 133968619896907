import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextFieldFilled from 'commons/components/TextFiledFilled';
import VehicleType from 'screens/Quote/component/VehicleType';
import {
  FormHelperText,
  Input,
  TextField,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import RequirementCheck from 'screens/Quote/component/RequirementCheck';
import { formatTaxNumber, onlyNumber } from 'utils/functions';

import { makeStyles } from '@material-ui/core/styles';
import CustomAutoComplete from 'commons/components/CustomAutoComplete';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import styles from './styles';

const useStyles = makeStyles(styles);

const generalDetails = [
  {
    id: 1,
    name: 'color',
    label: 'Color',
    multiple: true,
  },
  {
    id: 2,
    name: 'deductible',
    label: 'Deducible',
    multiple: true,
  },
  {
    id: 3,
    name: 'equipment',
    label: 'Equipamiento',
    multiple: true,
  },
  {
    id: 4,
    name: 'monthlyKilometers',
    label: 'Kilometraje mensual',
    multiple: true,
  },
  {
    id: 5,
    name: 'leaseTerm',
    label: 'Plazo arriendo',
    multiple: false,
  },
  {
    id: 6,
    name: 'useType',
    label: 'Tipo de uso (Carretera/Empresa)',
    multiple: false,
  },
  {
    id: 7,
    name: 'management',
    label: 'Es para gerencia (Si/No)',
    multiple: false,
  },
];

const Form = ({
  controls, onChange, onSubmit, errors, changeVehicleTypes, changeControls, loading, socialReasons,
}) => {
  const classes = useStyles();
  const Title = ({ title, margin }) => (
    <Grid item xs={12} className={margin ? classes.grid : ''}>
      <Typography color="primary" variant="h5">
        {title}
      </Typography>
    </Grid>
  );

  const onChangeCheck = (e) => {
    const { id, checked } = e.target;
    changeVehicleTypes(id, 'checked', checked);
    changeVehicleTypes(id, 'quantity', 1);
  };

  const handleClickIcon = (id, value) => {
    changeVehicleTypes(id, 'quantity', value);
  };

  useEffect(() => {
    const data = socialReasons.filter((s) => s.label === controls.ccu);

    changeControls({ taxNumber: formatTaxNumber(data[0]?.taxNumber || '') });
  },
  [controls.ccu, controls.taxNumber]);

  return (
    <form onSubmit={onSubmit} autoComplete="off">
      <Grid container spacing={3}>
        <Title title="DATOS SOLICITANTE COTIZACIÓN" />
        <Grid item xs={12} sm={3}>
          <CustomAutoComplete
            id="ccu"
            name="ccu"
            value={controls.ccu}
            error={!!errors.ccu}
            helperText={errors.ccu}
            label="Razón social"
            onChange={onChange}
            options={socialReasons}
            size="small"
            disableClearable
            className={classes.autoCompleteForm}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextFieldFilled
            id="taxNumber"
            name="taxNumber"
            inputPropsClassName={classes.textField}
            label="RUT"
            value={controls.taxNumber}
            onChange={(e) => onChange(e, formatTaxNumber)}
            error={!!errors.taxNumber}
            helperText={errors.taxNumber && errors.taxNumber[0]}
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextFieldFilled
            id="contactEmail"
            inputPropsClassName={classes.textField}
            label="Mail contacto"
            value={controls.contactEmail}
            onChange={onChange}
            error={!!errors.contactEmail}
            helperText={errors.contactEmail && errors.contactEmail[0]}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextFieldFilled
            id="phone"
            label="Teléfono"
            inputPropsClassName={classes.textField}
            value={onlyNumber(controls.phone)}
            onChange={(e) => onChange(e, onlyNumber)}
            error={!!errors.phone}
            helperText={errors.phone && errors.phone[0]}
          />
        </Grid>
        <div
          className={classes.gridContainerRequest}
        >
          <div className={classes.gridItemRequest}>
            <Grid item xs={12}>
              <Grid container alignItems="center" className={classes.container}>
                <Grid item xs={12} sm={12} md={6}>
                  <Box
                    display="flex"
                  >
                    <Typography color="primary" variant="h5">
                      Tipo de requerimiento
                    </Typography>
                    <RequirementCheck
                      id="requirementType"
                      name="requirementType"
                      value={controls.requirementType}
                      onChange={changeControls}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                  <Box
                    display="flex"
                  >
                    <Box>
                      <Typography color="primary" variant="h5">
                        Lugar de entrega
                      </Typography>
                    </Box>
                    <Box flexGrow={1}>
                      <FormControl
                        error={errors.location}
                        fullWidth
                      >
                        <Input
                          fullWidth
                          color="primary"
                          id="location"
                          name="location"
                          label="Lugar de entrega"
                          value={controls.location}
                          onChange={onChange}
                          error={!!errors.location}
                          className={classes.inputLocation}
                        />
                        {errors.location
                        && (
                        <FormHelperText
                          id="error"
                          className={classes.inputLocation}
                        >
                          {errors.location[0]}
                        </FormHelperText>
                        )}
                      </FormControl>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>

          </div>
        </div>
        <Title title="Tipo de vehículo" margin />

        <Grid item xs={12}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            flexWrap="wrap"
          >
            {Object.keys(controls.vehicleTypes).map((v) => (
              <Box
                className={classes.boxVehicle}
                key={v}
              >
                <VehicleType
                  key={v}
                  id={v}
                  label={controls.vehicleTypes[v].name}
                  checked={controls.vehicleTypes[v].checked}
                  quantity={controls.vehicleTypes[v].quantity}
                  onChangeCheck={onChangeCheck}
                  handleClickIcon={handleClickIcon}
                />
              </Box>
            ))}
          </Box>

        </Grid>
        <Title title="Observaciones" />
        <Grid item xs={12} md={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                id="observations"
                name="observations"
                InputProps={{ className: classes.textField }}
                label="Observaciones"
                value={controls.observations}
                onChange={onChange}
                multiline
                minRows={3}
                maxRows={5}
                variant="filled"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={3}>
            {generalDetails.map((g) => (
              <Grid item xs={12} md={6} key={g.id}>
                <TextField
                  fullWidth
                  minRows={2}
                  maxRows={3}
                  onChange={onChange}
                  size="small"
                  variant="filled"
                  id={g.name}
                  name={g.name}
                  label={g.label}
                  multiline={g.multiple}
                  value={controls[g.name]}
                  InputProps={{ className: classes.textField }}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} align="right">
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            // style={{ marginTop: !isSmallScreen ? '64px' : '' }}
            disabled={loading}
          >
            Enviar cotización
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default Form;
