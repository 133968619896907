import {
  Grid, Modal, Paper, makeStyles, IconButton, Typography, List, ListItemText, ListItem, Box,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { Skeleton } from '@material-ui/lab';
import styles from './styles';

const useStyles = makeStyles(styles);

function LicensePlateModal({
  isOpen, handleClose, vehicles, isLoading,
}) {
  const classes = useStyles();
  return (
    <div>
      <Modal open={isOpen} onClose={handleClose}>
        <Paper className={classes.papper}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item container md={12} className={classes.header}>
              <Grid item style={{ paddingLeft: '16px' }}>
                <Typography
                  variant="h4"
                  fontWeight="bold"
                >
                  Patentes incluidas
                </Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            {isLoading ? (
              <Box className={classes.skeleton}>
                <Skeleton animation="wave" />
              </Box>

            ) : (
              <Grid item md={8} className={classes.body}>
                {vehicles?.length !== 0
                  ? (
                    <List>
                      {vehicles?.map((item) => (
                        <ListItem key={item.licensePlate}>
                          <ListItemText primary={`${item.licensePlate} - ${item.brand} - ${item.model}`} />
                        </ListItem>
                      ))}
                    </List>
                  )
                  : (
                    <Grid item container className={classes.noItem}>
                      <Grid item>
                        <Typography>
                          Esta razón social no tiene vehículos activos asociados
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
              </Grid>
            )}
          </Grid>
        </Paper>
      </Modal>
    </div>
  );
}
export default LicensePlateModal;
