import camelize from 'camelcase-keys-deep';

export default class Fleet {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  get vehicles() {
    let response = [];
    if (this.id) {
      response = this.env().Vehicle.findAllBy('fleetId', this.id);
    }
    return response;
  }

  get user() {
    return this.userId ? this.env().User.findBy('id', this.userId) : null;
  }

  get socialReason() {
    return this.socialReasonId ? this.env().SocialReason.findBy('id', this.socialReasonId) : null;
  }
}
