import {
  Box, useMediaQuery, useTheme,
} from '@material-ui/core';
import React from 'react';

// Material
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

// Project
import SwapVertIcon from '@material-ui/icons/SwapVert';

// icons
import ImportExportIcon from '@material-ui/icons/ImportExport';
import classNames from 'classnames';
import styles from './styles';

const useStyles = makeStyles(styles);

export const DASHBOARD_COLS = {
  RUT: 'RUT',
  HOLDING: 'Holding',
  TOTAL_VEHICLES: 'Flota Total',
  DISPONIBILITY: '% Disponibilidad',
  MECHANIC: '% Taller',
  REPLACEMENTS: '% Reemplazos',
  SINIESTROS: 'Siniestros',
  PATENTS: 'Patentes detenidas',
};

const headers = [
  {
    label: DASHBOARD_COLS.RUT, key: DASHBOARD_COLS.RUT, width: 120, canSort: true,
  },
  {
    label: DASHBOARD_COLS.HOLDING, key: DASHBOARD_COLS.HOLDING, width: 240, canSort: true,
  },
  { label: DASHBOARD_COLS.TOTAL_VEHICLES, key: DASHBOARD_COLS.TOTAL_VEHICLES, width: 125 },
  { label: DASHBOARD_COLS.DISPONIBILITY, key: DASHBOARD_COLS.DISPONIBILITY, width: 160 },
  { label: DASHBOARD_COLS.MECHANIC, key: DASHBOARD_COLS.MECHANIC, width: 100 },
  { label: DASHBOARD_COLS.REPLACEMENTS, key: DASHBOARD_COLS.REPLACEMENTS, width: 140 },
  { label: DASHBOARD_COLS.SINIESTROS, key: DASHBOARD_COLS.SINIESTROS, width: 115 },
  { label: DASHBOARD_COLS.PATENTS, key: DASHBOARD_COLS.PATENTS, width: 160 },
];

const Header = ({
  label, width, setOrderBy, canSort,
}) => {
  const classes = useStyles();

  const handleOrderBy = () => {
    setOrderBy({ label });
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="center"
      width={width}
    >
      <Button
        fullWidth
        color="primary"
        className={classNames(classes.button, classes.center)}
        size="small"
        endIcon={canSort && <ImportExportIcon className={classes.icon} />}
        onClick={canSort && handleOrderBy}
      >
        <Box fontWeight={700} color="white">
          {label}
        </Box>
      </Button>
    </Box>
  );
};

const HeaderList = ({
  setOrderBy,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (!isMobile ? (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      paddingRight={1}
      paddingLeft={1}
      className={classes.root}
    >
      {headers.map((header) => (
        <Header
          setOrderBy={setOrderBy}
          label={header.label}
          keyValue={header.key}
          key={header.key}
          width={header.width}
          center={header.center}
          canSort={header.canSort}
        />
      ))}
    </Box>
  ) : (
    <Box>
      <Box display="flex" className={classes.mobileOrderFilter}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => {}}
        >
          <SwapVertIcon />
        </Button>
      </Box>
      <Box
        justifyContent="space-between"
      >
        <Box
          className={classes.mobileListTitle}
        >
          <Typography variant="h5" color="primary">
            Listado de holdings
          </Typography>
        </Box>
      </Box>
    </Box>
  ));
};

export default HeaderList;
