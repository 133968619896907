import { Box } from '@material-ui/core';
import React from 'react';

// Material UI
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';

import useForm from 'utils/hooks/useForm';

import { validatePassword } from '../../../../utils/functions';

const ChangePassword = ({
  classes, formControl, onChangeInput, changePassword,
}) => {
  const { currentPassword, newPassword, verifyPassword } = formControl;

  const { onChange, onSubmit, errors } = useForm(formControl, null, {
    onChange: onChangeInput,
    validations: {
      currentPassword: [
        {
          check: (value) => !!value,
          message: 'La contraseña es requerida',
        },
      ],
      newPassword: [
        {
          check: (value) => !!value,
          message: 'La nueva contraseña es requerida',
        },
        {
          check: (value) => !!value && value.trim().length >= 6,
          message: 'El mínimo es de 8 caracteres',
        },
        {
          check: (value) => !!value && value.trim().length <= 20,
          message: 'El máximo es de 20 caracteres',
        },
        {
          check: (value) => validatePassword(value),
          message: 'La contraseña debe tener al menos una mayúscula y un número',
        },
      ],
      verifyPassword: [
        {
          check: (value) => !!value,
          message: 'La verificación de contraseña es requerida',
        },
        {
          check: (value) => value === newPassword,
          message: 'Las contraseñas deben coincidir',
        },
      ],
    },
  });

  const InputTitle = ({ text }) => (
    <Typography
      color="primary"
      variant="h5"
      className={classes.passwordInputTitle}
    >
      {text}
    </Typography>
  );

  const handleChangePassword = (e) => {
    e.preventDefault();
    onSubmit(() => changePassword(formControl));
  };

  return (
    // TODO: Change text input to primary color(main)
    <Box className={classes.passwordContainer}>
      <FormControl className={classes.formContainer}>
        <InputTitle text="Contraseña" />
        <TextField
          id="currentPassword"
          value={currentPassword}
          label="Contraseña"
          type="password"
          size="small"
          className={classes.passwordInput}
          InputProps={{ className: classes.inputProps }}
          onChange={onChange}
          variant="outlined"
          color="primary"
          required
          error={errors.currentPassword}
          helperText={errors.currentPassword && errors.currentPassword[0]}
        />
        <InputTitle text="Nueva contraseña" />
        <TextField
          id="newPassword"
          value={newPassword}
          size="small"
          label="Nueva contraseña"
          type="password"
          className={classes.passwordInput}
          onChange={onChange}
          variant="outlined"
          required
          error={errors.newPassword}
          helperText={errors.newPassword && errors.newPassword[0]}
        />
        <InputTitle text="Repetir nueva contraseña" />
        <TextField
          id="verifyPassword"
          value={verifyPassword}
          size="small"
          type="password"
          label="Repetir nueva contraseña"
          className={classes.passwordInput}
          onChange={onChange}
          variant="outlined"
          required
          error={errors.verifyPassword}
          helperText={errors.verifyPassword && errors.verifyPassword[0]}
        />
        <Typography variant="caption" color="primary">
          <Box>
            La contraseña debe tener un mínimo de 8 caracteres.
          </Box>
          <Box>
            Debe contener al menos una letra mayúscula y un número
          </Box>
        </Typography>
      </FormControl>
      <Box className={classes.passwordButtonContainer}>
        <Button
          size="medium"
          color="secondary"
          variant="contained"
          onClick={handleChangePassword}
          className={classes.passwordButton}
        >
          CAMBIAR CONTRASEÑA
        </Button>
      </Box>
    </Box>
  );
};

export default ChangePassword;
