import React, { useState } from 'react';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import {
  Button, Grid, Typography, makeStyles, useMediaQuery, useTheme,
} from '@material-ui/core';
import classNames from 'classnames';

import ContextMenu from 'commons/containers/ContextMenu';
import roles from 'screens/User/constants';
import styles from './styles';
import UpdateNewsletterDialog from './Dialogs/EditDialog';

const useStyles = makeStyles(styles);

const PaginationItem = ({
  id, title, url, selectedId, setSelectedId, onDelete, onUpdate, isMobile, role,
}) => {
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const classes = useStyles();

  const handleUpdateItem = ({ title: newTitle, url: newUrl, handleClose }) => {
    onUpdate({
      title: newTitle, url: newUrl, id, handleClose,
    });
  };

  if (!title) {
    return (
      <div className={classes.emptyItem} />
    );
  }

  return (
    <ContextMenu
      role={role}
      key={id}
      width={isMobile ? '50%' : '20%'}
      options={[
        { text: 'Editar', action: () => { setIsEditDialogOpen(true); } },
        { text: 'Eliminar', action: onDelete },
      ]}
    >
      <div
        className={classNames({
          [classes.paginationItem]: Boolean(id),
          [classes.paginationItemSelected]: selectedId === id,
          [classes.paginationItemDisabled]: !id,
        })}
      >
        <Grid
          item
          onClick={() => {
            if (!id) return;
            setSelectedId(id);
          }}
        >
          <div className={classes.paginationItemText}>
            <Typography color="primary" variant="h6" fontWeight="bold" className={classes.overflowEllipsis}>
              {title && title}
            </Typography>
          </div>
        </Grid>
        {role === roles.ADMINISTRADOR_PORTAL_SALFA && (
        <Grid item className={classes.paginationItemActions}>
          <Button
            color="primary"
            variant="text"
            onClick={() => { setIsEditDialogOpen(true); }}
          >
            Editar
          </Button>
          <Button onClick={onDelete} color="secondary" variant="text">Eliminar</Button>
        </Grid>
        )}
      </div>
      <UpdateNewsletterDialog
        open={isEditDialogOpen ?? false}
        id={id}
        currTitle={title}
        currUrl={url}
        handleSubmit={handleUpdateItem}
        handleClose={() => setIsEditDialogOpen(false)}
      />
    </ContextMenu>
  );
};

/**
 * @param {Object} props
 * @param {Object[]} props.newsletterHistory - Historial de newsletters.
 * @param {string} props.newsletterHistory[].id - ID del newsletter.
 * @param {string} props.newsletterHistory[].title - Título del newsletter.
 * @param {string} props.newsletterHistory[].date - Fecha del newsletter.
 * @param {string} props.newsletterHistory[].url - URL del newsletter.
 */
function NewsletterPagination({
  newsletterHistory = [], selectedId, setSelectedId, actions, role,
}) {
  const [startIndex, setStartIndex] = useState(0);
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const paginationLength = isMobile ? 2 : 5;
  let newsletterHistoryProcessed = newsletterHistory;
  if (newsletterHistory.length < paginationLength) {
    newsletterHistoryProcessed = newsletterHistory.concat(
      Array(paginationLength - newsletterHistory.length).fill({}),
    );
  }
  const paginationItems = newsletterHistoryProcessed.slice(
    startIndex, startIndex + paginationLength,
  );

  const paginationActions = {
    prev: (index) => (index > 0 ? index - 1 : 0),
    next: (index) => (index < newsletterHistory.length - paginationLength ? index + 1 : index),
  };

  /**
   * @param {'prev' | 'next'} action - Acción de paginación ('prev' o 'next').
   */
  const handleChangePage = (action) => {
    setStartIndex(paginationActions[action]);
  };

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center" wrap="nowrap" className={classes.pagination}>
        <ChevronLeft className={classes.icon} onClick={() => handleChangePage('prev')} />
        {paginationItems.map((page) => (
          <PaginationItem
            key={page.id ?? Math.random()}
            role={role}
            id={page.id}
            date={page.date}
            title={page.title}
            url={page.url}
            selectedId={selectedId}
            setSelectedId={setSelectedId}
            isMobile={isMobile}
            onDelete={() => {
              actions.removeNewsletterHistory({ newsletterId: page.id });
            }}
            onUpdate={actions.updateNewsletterHistory}
          />
        ))}
        <ChevronRight className={classes.icon} onClick={() => handleChangePage('next')} />
      </Grid>
      <Typography>
        Total:
        {' '}
        {newsletterHistory.length}
        {' '}
        newsletters
      </Typography>
    </>
  );
}

export default NewsletterPagination;
