import React, { useEffect, useState } from 'react';

import {
  USERS_KEYS, USERS_LABELS,
} from 'screens/ReportsMaintainer/constants';
import {
  Box, Grid, useMediaQuery, useTheme,
} from '@material-ui/core';
import CustomCheckBox from 'commons/components/CustomCheckBox';
import { Skeleton } from '@material-ui/lab';
import UsersListHeader from './header';
import UsersList from './list';
import HoldingFilter from './HoldingFilter';
import UserSearch from './UserSearch';

const headers = [
  {
    label: USERS_LABELS.NAME, key: USERS_KEYS.NAME, width: 230, center: true,
  },
  {
    label: USERS_LABELS.EMAIL, key: USERS_KEYS.EMAIL, width: 220, center: true,
  },
  {
    label: USERS_LABELS.ROLE, key: USERS_KEYS.ROLE, width: 250, center: true,
  },
  {
    label: USERS_LABELS.IS_REPORT_AVAILABLE,
    key: USERS_KEYS.IS_REPORT_AVAILABLE,
    width: 240,
    center: true,
  },
];

const getDataCard = (user) => [
  {
    key: 0,
    title: 'Nombre',
    row: `${user.firstName} ${user.lastName}`,
    width: 230,
    center: true,
  },
  {
    key: 1,
    title: 'Email',
    row: user.email,
    width: 220,
    center: true,
  },
  {
    key: 2,
    title: 'Rol',
    row: user.type,
    width: 250,
  },
  {
    key: 3,
    title: 'Reporte Habilitado',
    row: user.hasPermission,
    width: 240,
    isCheckbox: true,
    center: true,
  },
];

function UsersTable({
  users, actions, controls, total, pagination, holdings, reportId, loadingUsers,
}) {
  const [selectedHolding, setSelectedHolding] = useState('');
  const [allSelected, setAllSelected] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (selectedHolding === '' || !selectedHolding.value) return;

    actions.getUsers(selectedHolding.value);
  }, [selectedHolding]);

  const handleSelectAll = (checked) => {
    setAllSelected(checked);
    actions.setActiveReportUsers({ granted: checked, reportId, holdingId: selectedHolding?.value });
  };

  return (
    <>
      <HoldingFilter
        holdings={holdings}
        onSelect={setSelectedHolding}
      />
      {selectedHolding && loadingUsers && (
        <Box mt={2}>
          <Skeleton variant="rect" height={200} />
        </Box>
      )}
      {selectedHolding && !loadingUsers && (
        <>
          {!isMobile ? (
            <Grid container justifyContent="space-between" alignItems="flex-end">
              <UserSearch
                isOpen={isSearchOpen}
                value={searchValue}
                onChange={(text) => setSearchValue(text)}
                onClickIcon={() => setIsSearchOpen(true)}
                onSearch={(text) => actions.usersSetSearch(text)}
              />
              <CustomCheckBox
                labelPlacement="start"
                checked={allSelected}
                id="all"
                label="Seleccionar todos"
                name="all"
                onChange={(e, checked) => handleSelectAll(checked)}
              />
            </Grid>
          ) : (
            <>
              <UserSearch
                isOpen={isSearchOpen}
                value={searchValue}
                onChange={(text) => setSearchValue(text)}
                onClickIcon={() => setIsSearchOpen(true)}
                onSearch={(text) => actions.usersSetSearch(text)}
              />
              <CustomCheckBox
                labelPlacement="start"
                checked={allSelected}
                id="all"
                label="Seleccionar todos"
                name="all"
                onChange={(e, checked) => handleSelectAll(checked)}
              />
            </>
          )}
          <UsersListHeader actions={actions} controls={controls} headers={headers} />
          <UsersList
            actions={actions}
            reportId={reportId}
            users={users}
            total={total}
            allSelected={allSelected}
            pagination={pagination}
            getDataCard={getDataCard}
          />
        </>
      )}
    </>
  );
}

export default UsersTable;
