import React from 'react';
import {
  Box, Button, Typography, makeStyles,
} from '@material-ui/core';
import classNames from 'classnames';
import GeneralCard from 'commons/components/GeneralCard';
import { formatDateForCards } from 'utils/functions';
import { formatRut } from 'utils/rut';
import GeneralMobileCard from 'commons/components/GeneralMobileCard';
import { SOCIAL_REASON_COLS } from 'screens/HoldingMaintainerDetail/constants';
import styles from './styles';

const useStyles = makeStyles(styles);

const getDataCard = (socialReason) => [
  {
    key: 0,
    center: true,
    title: SOCIAL_REASON_COLS.ID,
    row: `${socialReason?.id ?? 1}`,
    width: 120,
  },
  {
    key: 1,
    title: SOCIAL_REASON_COLS.RUT,
    row: formatRut(socialReason.rut),
    width: 150,
  },
  {
    key: 2,
    title: SOCIAL_REASON_COLS.NAME,
    row: socialReason.name,
    width: 280,
  },
  {
    key: 3,
    center: true,
    title: SOCIAL_REASON_COLS.CREATED_AT,
    row: `${formatDateForCards(socialReason.createdAt)}`,
    width: 200,
  },
  {
    key: 4,
    center: true,
    title: SOCIAL_REASON_COLS.STATE,
    row: socialReason.isActive ? 'ACTIVO' : 'INACTIVO',
    width: 130,
  },
];

const SocialReasonCardItem = ({
  icon, row, width, colors, fontWeights, isMobile, title, centerText = false,
}) => {
  const classes = useStyles();

  return (
    <Box display={!isMobile ? 'inline-block' : 'block'}>
      <Box display="flex" width={!isMobile ? width : '100%'} my={1.4} px={1}>
        {icon ? <Box className={classes.itemIcon}>{icon}</Box> : null}
        <Box width={!isMobile ? width : '100%'} overflow="auto">
          <Typography align="center" component="div" color="primary" noWrap={!isMobile}>
            <Box
              component="span"
              display="flex"
              justifyContent={centerText && !isMobile ? 'center' : 'start'}
              alignItems="center"
              color={colors?.row}
              fontSize={15}
              fontWeight={fontWeights?.row}
              className={classes.text}
            >
              <Typography className={classes.attributeTitle} component="span" variant="h6">
                {isMobile && `${title}: `}
              </Typography>
              {`${row}`}
            </Box>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const SocialReasonCard = ({
  onEdit,
  socialReason,
  index,
  isMobile,
}) => {
  const dataCard = getDataCard(socialReason);
  const classes = useStyles();

  const socialReasonsListItems = dataCard.map((d) => (
    <SocialReasonCardItem
      key={d.key}
      isMobile={isMobile}
      row={d.row}
      width={d.width}
      colors={d.colors}
      fontWeights={d.fontWeights}
      centerText={d.center}
      title={d.title}
      my={2}
    />
  ));

  return (
    <>
      {!isMobile ? (
        <GeneralCard
          className={classNames(classes.root, index % 2 === 0 ? classes.back1 : classes.back2)}
          rightComponent={(
            <Button className={classes.editRSButton} onClick={onEdit} variant="contained" color="primary">
              Editar
            </Button>
          )}
        >
          {socialReasonsListItems}
        </GeneralCard>
      ) : (
        <GeneralMobileCard
          className={classNames(classes.root, index % 2 === 0 ? classes.back1 : classes.back2)}
          flex={false}
          rightComponent={(
            <Button className={classes.editRSButton} onClick={onEdit} variant="contained" color="primary">
              Editar
            </Button>
          )}
        >
          {socialReasonsListItems}
        </GeneralMobileCard>
      )}
      <div />
    </>
  );
};

export default SocialReasonCard;
