import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Skeleton } from '@material-ui/lab';
import { randomString } from 'utils/functions';
import { Title } from 'utils/skeletons';
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';

const useStyles = makeStyles(styles);
const FleetSkeleton = () => {
  const classes = useStyles();
  const Cards = () => (
    Array.from(new Array(6)).map(() => (
      <Grid key={randomString()} item xs={12} md={6}>
        <Skeleton
          variant="rect"
          width="100%"
          height={120}
        />
      </Grid>
    ))
  );
  return (
    <Grid
      container
      spacing={3}
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={12}>
        <Title width={300} />
      </Grid>
      <Grid item xs={9} md={6} className={classes.grid}>
        <Skeleton
          variant="rect"
          width="100%"
          height={50}
        />
      </Grid>
      <Grid item xs={3} md={2} className={classes.grid}>
        <Skeleton
          variant="circle"
          width={40}
          height={40}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid container spacing={1}>
          <Grid item xs={6} md={6}>
            <Skeleton
              variant="rect"
              width="80%"
              height={40}
            />
          </Grid>
          <Grid item xs={6} md={6} align="right">
            <Skeleton
              variant="rect"
              width="80%"
              height={40}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid container spacing={1}>
          <Grid item xs={6} md={6}>
            <Skeleton
              variant="rect"
              width="80%"
              height={40}
            />
          </Grid>
          <Grid item xs={6} md={6} align="right">
            <Skeleton
              variant="rect"
              width="80%"
              height={40}
            />
          </Grid>
        </Grid>
      </Grid>
      <Cards />
    </Grid>
  );
};

export default FleetSkeleton;
