import {
  Grid, Typography, makeStyles,
} from '@material-ui/core';
import React from 'react';
// Icons
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import LocalTaxiOutlinedIcon from '@material-ui/icons/LocalTaxiOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import SwapHorizontalCircleRoundedIcon from '@material-ui/icons/SwapHorizontalCircleRounded';
import CreateIcon from '@material-ui/icons/Create';
import classNames from 'classnames';
import Box from '@material-ui/core/Box';
import DriverSwitch from 'screens/VehicleDetail/Components/DriverSwitch';
import { toLowerCase } from '../../../../utils/functions';

import styles from '../../styles';

const useStyles = makeStyles(styles);
export default function VehicleDetailRow({
  vehicle, openModal, changeShared, isShared, loadingShared,
}) {
  const {
    id,
    status,
    hasReplacement,
    zone,
    assignedToName,
    assignedToRun,
    'ContractVehicle.externalId': contract,
    'Fleet.name': fleetName,
  } = vehicle;
  const classes = useStyles();

  return (
    <Grid
      container
      className={classes.middleSectionContainer}
      spacing={1}
    >
      <Grid
        item
        xs={12}
        md={2}
        className={classes.middleSectionGroupContainer}

      >
        <FiberManualRecordIcon
          className={classNames(classes.statusIcon, classes[vehicle.status])}
        />
        <Grid>
          <Typography className={classes.rowTextStyle}>ESTADO</Typography>
          <Typography>{(status) ? toLowerCase(status) : 'No informado'}</Typography>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        md={2}
        className={classes.middleSectionGroupContainer}
      >
        <DriverSwitch
          label="Asignación"
          checked={isShared}
          vehicleId={id}
          changeShared={changeShared}
          loading={loadingShared}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={2}
        className={classes.middleSectionGroupContainer}
      >
        <Grid>
          <AccountCircleOutlinedIcon className={classes.middleSectionIcon} />
        </Grid>
        <Grid>
          <Typography className={classes.rowTextStyle}>{assignedToName || 'No informado'}</Typography>
          <Typography>{assignedToRun || 'No informado'}</Typography>
        </Grid>
        <Grid>
          <Box
            display="flex"
            className={classes.boxChangeDriver}
            boxShadow={3}
            alignItems="center"
            onClick={openModal}
          >
            <CreateIcon className={classes.buttonChangeDriver} />
          </Box>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        md={2}
        className={classes.middleSectionGroupContainer}
      >
        <Grid>
          <LocalTaxiOutlinedIcon className={classes.middleSectionIcon} />
        </Grid>
        <Grid>
          <Typography className={classes.rowTextStyle}>{fleetName || 'No informado'}</Typography>
          <Typography>{zone || 'No informado'}</Typography>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        md={2}
        className={classes.middleSectionGroupContainer}
      >
        <Grid>
          <DescriptionOutlinedIcon className={classes.middleSectionIcon} />
        </Grid>
        <Grid>
          <Typography className={classes.rowTextStyle}>Contrato</Typography>
          <Typography>{contract || 'No informado'}</Typography>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        md={2}
        className={classes.middleSectionGroupContainer}
      >
        <Grid>
          <div className={classes.divContainer}>
            <SwapHorizontalCircleRoundedIcon className={classes.alertIcon} />
            <LocalTaxiOutlinedIcon className={classes.middleSectionIcon} />
          </div>
        </Grid>
        <Grid>
          <Typography className={classes.rowTextStyle}>Patente de reemplazo</Typography>
          <Typography>{(hasReplacement) || 'No informado'}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
