import React from 'react';
import Button from '@material-ui/core/Button';
import {
  Box, Typography, useMediaQuery, useTheme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';
import styles from './styles';
import CustomTooltip from '../CustomTooltip';

const useStyles = makeStyles(styles);

const CustomFilterButton = ({ onClick, clear }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const cleanFilterText = <Typography>Limpiar filtros</Typography>;

  const ClearIcon = () => (
    <SvgIcon>
      <path d="M16,11h-1V3c0-1.1-0.9-2-2-2h-2C9.9,1,9,1.9,9,3v8H8c-2.76,0-5,2.24-5,5v7h18v-7C21,13.24,18.76,11,16,11z M19,21h-2v-3 c0-0.55-0.45-1-1-1s-1,0.45-1,1v3h-2v-3c0-0.55-0.45-1-1-1s-1,0.45-1,1v3H9v-3c0-0.55-0.45-1-1-1s-1,0.45-1,1v3H5v-5 c0-1.65,1.35-3,3-3h8c1.65,0,3,1.35,3,3V21z" />
    </SvgIcon>
  );

  const classes = useStyles();
  return (
    <Box
      display="flex"
      justifyContent="end"
      height={40}
      width="100%"
    >
      <Box
        className={classes.buttonGroup}
      >
        <CustomTooltip content={cleanFilterText}>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            className={classes.clearButton}
            onClick={clear}
          >
            <ClearIcon color="primary" />
          </Button>
        </CustomTooltip>
        <Button
          variant="contained"
          color="primary"
          size="large"
          fullWidth={isMobile}
          onClick={onClick}
        >
          Buscar
        </Button>

      </Box>
    </Box>
  );
};

export default CustomFilterButton;
