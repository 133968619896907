const styles = (theme) => ({
  root: {
    padding: theme.spacing(4),
    marginBottom: theme.spacing(1),
    boxShadow: theme.shadows[4],
    borderRadius: 8,
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    background: theme.palette.primary.main,
  },
  addButton: {
    marginLeft: theme.spacing(1),
  },
  kmDefaults: {
    display: 'flex',
    flexDirection: 'row',
  },
  kmOthers: {

  },
  chipContainer: {
    display: 'inline-flex',
    marginTop: theme.spacing(1),
    '& > *': {
      marginRight: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
    },
  },

  inputNumber: {
    '& .MuiOutlinedInput-input': {
      color: theme.palette.primary.main,
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.primary.main,
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
    '&:hover .MuiOutlinedInput-input': {
      color: theme.palette.primary.main,
    },
    '&:hover .MuiInputLabel-root': {
      color: theme.palette.primary.main,
    },
    '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
      color: theme.palette.primary.main,
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: theme.palette.primary.main,
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
  },
  button: {
    display: 'flex',
    float: 'right',
    fontWeight: theme.typography.fontWeightMedium,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  formControl: {
    width: '100%',
  },
});

export default styles;
