import { alpha } from '@material-ui/core/styles/colorManipulator';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    boxShadow: theme.shadows[4],
    '&:hover': {
      cursor: 'pointer',
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
    },
  },
  back1: {
    backgroundColor: alpha(theme.palette.primary.main, 0.03),
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.12),
    },
  },
  back2: {
    backgroundColor: alpha(theme.palette.primary.main, 0.08),
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.12),
    },
  },
  paginationContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  text: {
    overflowX: 'auto',
  },
  editRSButton: {
    width: '80px',
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(1),
    },
  },
  attributeTitle: {
    marginRight: theme.spacing(1),
  },
});

export default styles;
