import React from 'react';

// Material
import { Grid } from '@material-ui/core';

// Project
import type from 'utils/type';
import BtnWork from 'screens/GeneratePaymentStatus/components/Buttons/BtnWork';
import BtnRelease
  from 'screens/GeneratePaymentStatus/components/Buttons/BtnRelease';
import BtnSendRevisionToClient
  from 'screens/GeneratePaymentStatus/components/Buttons/BtnSendRevisionToClient';
import BtnClose
  from 'screens/GeneratePaymentStatus/components/Buttons/BtnClose';
import BtnRequireRevision
  from 'screens/GeneratePaymentStatus/components/Buttons/BtnRequireRevision';
import BtnApprove
  from 'screens/GeneratePaymentStatus/components/Buttons/BtnApprove';
import BtnAcceptFleet
  from 'screens/GeneratePaymentStatus/components/Buttons/BtnAcceptFleet';
import { PAYMENT_STATUS_FLEET, PAYMENT_STATUS_STATUS } from 'utils/enums';

// icons

const ButtonsOptions = ({
  role, paymentStatusDTO, controls, actions, psId,
}) => {
  const allFleetsOnApproveOrClientReview = paymentStatusDTO?.fleets?.filter(
    (fleet) => fleet.status !== PAYMENT_STATUS_FLEET.APPROVED
      && fleet.status !== PAYMENT_STATUS_FLEET.CUSTOMER_REVIEW,
  );

  const disableRevisionToClient = controls.fleet === '0' || paymentStatusDTO.fleets[0]?.status !== PAYMENT_STATUS_FLEET.ADMIN_REVIEW;

  const disableRevisionOrAccept = controls.fleet === '0' || paymentStatusDTO.fleets[0]?.status !== PAYMENT_STATUS_FLEET.CUSTOMER_REVIEW;

  return (
    <Grid
      container
      justifyContent="flex-end"
      spacing={1}
    >
      {role === type.ADMINISTRADOR_DE_CONTRATO
        && (
          <>
            {paymentStatusDTO.status === PAYMENT_STATUS_STATUS.NOT_INITIALIZED
              && (
                <Grid item container justifyContent="flex-end" xs={12} md={3}>
                  <BtnWork
                    controls={controls}
                    actions={actions}
                    psId={psId}
                    status={paymentStatusDTO.status}
                  />
                </Grid>
              )}
            {paymentStatusDTO.status === PAYMENT_STATUS_STATUS.DRAFT
              && (
                <Grid item container justifyContent="flex-end" xs={12} md={3}>
                  <BtnRelease
                    controls={controls}
                    actions={actions}
                    psId={psId}
                    status={paymentStatusDTO.status}
                  />
                </Grid>
              )}
            {paymentStatusDTO.status === PAYMENT_STATUS_STATUS.REVIEW
              && (
                <Grid item container justifyContent="flex-end" xs={12} md={3}>
                  <BtnSendRevisionToClient
                    actions={actions}
                    disabled={disableRevisionToClient}
                  />
                </Grid>
              )}
            {paymentStatusDTO.status === PAYMENT_STATUS_STATUS.APPROVED
              && (
                <Grid item container justifyContent="flex-end" xs={12} md={3}>
                  <BtnClose
                    controls={controls}
                    actions={actions}
                    psId={psId}
                    status={paymentStatusDTO.status}
                  />
                </Grid>
              )}
          </>
        )}
      {role === type.GESTOR_DE_FLOTA_DECISOR
        && (
          <>
            {paymentStatusDTO.status === PAYMENT_STATUS_STATUS.REVIEW
              && (
                <>
                  <Grid item container justifyContent="flex-end" xs={12} md={3}>
                    <BtnRequireRevision
                      disabled={disableRevisionOrAccept}
                      actions={actions}
                    />
                  </Grid>
                  <Grid item container justifyContent="flex-end" xs={12} md={3}>
                    <BtnAcceptFleet
                      disabled={disableRevisionOrAccept}
                      controls={controls}
                      actions={actions}
                      psId={psId}
                    />
                  </Grid>
                  <Grid item container justifyContent="flex-end" xs={12} md={3}>
                    <BtnApprove
                      disabled={!!allFleetsOnApproveOrClientReview.length > 0}
                      controls={controls}
                      actions={actions}
                      psId={psId}
                      status={paymentStatusDTO.status}
                    />
                  </Grid>
                </>
              )}
          </>
        )}
      {role === type.ENCARGADO_DE_FLOTA_SECCION
        && (
          <>
            {paymentStatusDTO.status === PAYMENT_STATUS_STATUS.REVIEW
              && (
                <>
                  <Grid item container justifyContent="flex-end" xs={12} md={3}>
                    <BtnRequireRevision
                      actions={actions}
                      disabled={disableRevisionOrAccept}
                    />
                  </Grid>
                  <Grid item container justifyContent="flex-end" xs={12} md={3}>
                    <BtnAcceptFleet
                      controls={controls}
                      actions={actions}
                      psId={psId}
                      disabled={disableRevisionOrAccept}
                    />
                  </Grid>
                </>
              )}
          </>
        )}
    </Grid>
  );
};

export default ButtonsOptions;
