import React from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';

const CustomCheckBox = ({
  label, onChange, name, checked, id, labelPlacement = 'end',
}) => (
  <FormControlLabel
    style={{ color: '#002855' }}
    labelPlacement={labelPlacement}
    control={(
      <Checkbox
        checked={checked}
        id={id}
        name={name}
        color="primary"
        onChange={onChange}
      />
      )}
    label={label}
  />
);

export default CustomCheckBox;
