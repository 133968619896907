import { createAction, createReducer } from 'utils/reducer';

// Local constants
const PATH = 'payment-status/new';

// Global constants for saga
export const NEW_PAYMENT_STATUS = {
  FETCH_PAYMENT_STATUS: `${PATH}FETCH_PAYMENT_STATUS`,
  FETCH_PAYMENT_STATUS_SUCCESS: `${PATH}FETCH_PAYMENT_STATUS_SUCCESS`,
  CHANGE_CONTROLS: `${PATH}CHANGE_CONTROLS`,
  CHANGE_EXTRAS_DIALOG_CONTROLS: `${PATH}CHANGE_EXTRAS_DIALOG_CONTROLS`,
  CHANGE_DOCUMENTS_DIALOG_CONTROLS: `${PATH}CHANGE_DOCUMENTS_DIALOG_CONTROLS`,
  CHANGE_POST_DIALOG_CONTROLS: `${PATH}CHANGE_POST_DIALOG_CONTROLS`,
  CHANGE_APPROVE_DIALOG_CONTROLS: `${PATH}CHANGE_APPROVE_DIALOG_CONTROLS`,
  SUBMIT_EXTRAS: `${PATH}SUBMIT_EXTRAS`,
  SUBMIT_EXTRAS_SUCCESS: `${PATH}SUBMIT_EXTRAS_SUCCESS`,
  SUBMIT_DOCUMENTS: `${PATH}SUBMIT_DOCUMENTS`,
  SUBMIT_DOCUMENTS_SUCCESS: `${PATH}SUBMIT_DOCUMENTS_SUCCESS`,
  SUBMIT_POST: `${PATH}SUBMIT_POST`,
  SUBMIT_POST_SUCCESS: `${PATH}SUBMIT_POST_SUCCESS`,
  DELETE_EXTRAS_DOCUMENT: `${PATH}DELETE_EXTRAS_DOCUMENT`,
  DELETE_EXTRAS_DOCUMENT_SUCCESS: `${PATH}DELETE_EXTRAS_DOCUMENT_SUCCESS`,
  DELETE_FLEETS_DOCUMENT: `${PATH}DELETE_FLEETS_DOCUMENT`,
  DELETE_FLEETS_DOCUMENT_SUCCESS: `${PATH}DELETE_FLEETS_DOCUMENT_SUCCESS`,
  DELETE_EXTRA: `${PATH}DELETE_EXTRA`,
  DELETE_EXTRA_SUCCESS: `${PATH}DELETE_EXTRA_SUCCESS`,
  WORK_PAYMENT_STATUS: `${PATH}WORK_PAYMENT_STATUS`,
  WORK_PAYMENT_STATUS_SUCCESS: `${PATH}WORK_PAYMENT_STATUS_SUCCESS`,
  RELEASE_PAYMENT_STATUS: `${PATH}RELEASE_PAYMENT_STATUS`,
  RELEASE_PAYMENT_STATUS_SUCCESS: `${PATH}RELEASE_PAYMENT_STATUS_SUCCESS`,
  ACCEPT_FLEET: `${PATH}ACCEPT_FLEET`,
  ACCEPT_FLEET_SUCCESS: `${PATH}ACCEPT_FLEET_SUCCESS`,
  APPROVE_PAYMENT_STATUS: `${PATH}APPROVE_PAYMENT_STATUS`,
  APPROVE_PAYMENT_STATUS_SUCCESS: `${PATH}APPROVE_PAYMENT_STATUS_SUCCESS`,
  CLOSE_PAYMENT_STATUS: `${PATH}CLOSE_PAYMENT_STATUS`,
  CLOSE_PAYMENT_STATUS_SUCCESS: `${PATH}CLOSE_PAYMENT_STATUS_SUCCESS`,
  CHANGE_SOCIAL_REASON: `${PATH}CHANGE_SOCIAL_REASON`,
  CHANGE_FLEET_ID: `${PATH}CHANGE_FLEET_ID`,
  RESET_CONTROLS: `${PATH}RESET_CONTROLS`,

  CLEAR_CONTROLS: `${PATH}CLEAR_CONTROLS`,
  CLEAR_CONTROLS_SUCCESS: `${PATH}CLEAR_CONTROLS_SUCCESS`,
  CHANGE_PAGE: `${PATH}CHANGE_PAGE`,
  CHANGE_PAGE_SUCCESS: `${PATH}CHANGE_PAGE_SUCCESS`,
  FETCH_DATA_WITH_FILTER: `${PATH}FETCH_DATA_WITH_FILTER`,
  FETCH_DATA_WITH_FILTER_SUCCESS: `${PATH}FETCH_DATA_WITH_FILTER_SUCCESS`,
  GET_SOCIAL_REASONS: `${PATH}GET_SOCIAL_REASONS`,
  GET_SOCIAL_REASONS_SUCCESS: `${PATH}GET_SOCIAL_REASONS_SUCCESS`,
  CHANGE_FLEET: `${PATH}CHANGE_FLEET`,
  GET_FLEETS: `${PATH}GET_FLEETS`,
  GET_FLEETS_SUCCESS: `${PATH}GET_FLEETS_SUCCESS`,
  GET_USER_HOLDING: `${PATH}GET_USER_HOLDING`,
  GET_USER_HOLDING_SUCCESS: `${PATH}GET_USER_HOLDING_SUCCESS`,
  CLOSE_HOLDING_DIALOG: `${PATH}CLOSE_HOLDING_DIALOG`,
  OPEN_SEARCH_USER: `${PATH}OPEN_SEARCH_USER`,
  FETCH_PAYMENT_STATUS_FAIL: `${PATH}FETCH_PAYMENT_STATUS_FAIL`,
};

// actions
export const newPaymentStatusActions = {
  getPaymentStatus: createAction(NEW_PAYMENT_STATUS.FETCH_PAYMENT_STATUS, 'id'),
  changeControls: createAction(NEW_PAYMENT_STATUS.CHANGE_CONTROLS, 'key', 'value'),
  changeExtrasDialogControls: createAction(NEW_PAYMENT_STATUS.CHANGE_EXTRAS_DIALOG_CONTROLS, 'key', 'value'),
  changeDocumentsDialogControls: createAction(NEW_PAYMENT_STATUS.CHANGE_DOCUMENTS_DIALOG_CONTROLS, 'key', 'value'),
  changePostDialogControls: createAction(NEW_PAYMENT_STATUS.CHANGE_POST_DIALOG_CONTROLS, 'key', 'value'),
  changeApproveDialogControls: createAction(NEW_PAYMENT_STATUS.CHANGE_APPROVE_DIALOG_CONTROLS, 'key', 'value'),
  submitExtras: createAction(NEW_PAYMENT_STATUS.SUBMIT_EXTRAS, 'extrasDialogControls', 'psId'),
  submitDocuments: createAction(NEW_PAYMENT_STATUS.SUBMIT_DOCUMENTS, 'documentsDialogControls', 'psId'),
  deleteExtrasDocument: createAction(NEW_PAYMENT_STATUS.DELETE_EXTRAS_DOCUMENT, 'id', 'psId'),
  deleteExtra: createAction(NEW_PAYMENT_STATUS.DELETE_EXTRA, 'id', 'psId'),
  deleteFleetsDocument: createAction(NEW_PAYMENT_STATUS.DELETE_FLEETS_DOCUMENT, 'id', 'psId'),
  workPaymentStatus: createAction(NEW_PAYMENT_STATUS.WORK_PAYMENT_STATUS, 'status', 'psId'),
  releasePaymentStatus: createAction(NEW_PAYMENT_STATUS.RELEASE_PAYMENT_STATUS, 'status', 'psId'),
  acceptFleet: createAction(NEW_PAYMENT_STATUS.ACCEPT_FLEET, 'psId', 'psFleetId'),
  approvePaymentStatus: createAction(NEW_PAYMENT_STATUS.APPROVE_PAYMENT_STATUS, 'approveDialogControls', 'psId', 'status'),
  closePaymentStatus: createAction(NEW_PAYMENT_STATUS.CLOSE_PAYMENT_STATUS, 'status', 'psId'),
  submitPost: createAction(NEW_PAYMENT_STATUS.SUBMIT_POST, 'postDialogControls', 'psId', 'psFleetId', 'role'),
  resetControls: createAction(NEW_PAYMENT_STATUS.RESET_CONTROLS),
};

const initialState = {
  controls: {
    extrasDialog: false,
    documentsDialog: false,
    postDialog: false,
    approveDialog: false,
    confirmationExtrasDialog: false,
    confirmationDeleteExtrasDialog: false,
    extrasDocumentId: 0,
    confirmationFleetsDialog: false,
    fleetsDocumentId: 0,
    confirmationWorkDialog: false,
    confirmationReleaseDialog: false,
    confirmationAcceptFleetDialog: false,
    confirmationCloseDialog: false,
    socialReason: '',
    fleet: '0',
    status: '',
    extraId: 0,
    search: '',
  },
  extrasDialogControls: {
    inactiveDays: '',
    licensePlate: '',
    selectedLease: '',
    extraType: 0,
    amount: '',
    topAmount: 0,
    description: '',
    attachments: [],
  },
  documentsDialogControls: {
    attachments: [],
    filesIndex: [],
  },
  postDialogControls: {
    observation: '',
    attachments: [],
  },
  approveDialogControls: {
    oc: '',
    hes: '',
    attachments: [],
  },
  paymentStatusDTO: [],
  socialReasonPaymentStatusDTO: [],
  psSettingsDTO: {},
  psSectionsDTO: {},
  fleets: [],
  error: false,
  loading: true,
};

const newPaymentStatus = createReducer(initialState, {
  [NEW_PAYMENT_STATUS.FETCH_PAYMENT_STATUS]: (state) => ({
    ...state,
    loading: true,
    error: false,
  }),
  [NEW_PAYMENT_STATUS.FETCH_PAYMENT_STATUS_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    paymentStatusDTO: action.payload.PaymentStatusDTO,
    socialReasonPaymentStatusDTO: action.payload.SocialReasonPaymentStatusDTO,
    psSettingsDTO: action.payload.PsSettings,
    psSectionsDTO: action.payload.PsSections,
    controls: {
      ...initialState.controls,
    },
  }),
  [NEW_PAYMENT_STATUS.RESET_CONTROLS]: (state) => ({
    ...state,
    error: false,
  }),
  [NEW_PAYMENT_STATUS.CHANGE_CONTROLS]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      [action.key]: action.value,
    },
  }),
  [NEW_PAYMENT_STATUS.CHANGE_EXTRAS_DIALOG_CONTROLS]: (state, action) => ({
    ...state,
    extrasDialogControls: {
      ...state.extrasDialogControls,
      [action.key]: action.value,
    },
  }),
  [NEW_PAYMENT_STATUS.CHANGE_DOCUMENTS_DIALOG_CONTROLS]: (state, action) => ({
    ...state,
    documentsDialogControls: {
      ...state.documentsDialogControls,
      [action.key]: action.value,
    },
  }),
  [NEW_PAYMENT_STATUS.CHANGE_POST_DIALOG_CONTROLS]: (state, action) => ({
    ...state,
    postDialogControls: {
      ...state.postDialogControls,
      [action.key]: action.value,
    },
  }),
  [NEW_PAYMENT_STATUS.CHANGE_APPROVE_DIALOG_CONTROLS]: (state, action) => ({
    ...state,
    approveDialogControls: {
      ...state.approveDialogControls,
      [action.key]: action.value,
    },
  }),
  [NEW_PAYMENT_STATUS.CHANGE_SOCIAL_REASON]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      socialReason: action.payload,
    },
  }),
  [NEW_PAYMENT_STATUS.CHANGE_FLEET_ID]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      fleet: action.payload,
    },
  }),
  [NEW_PAYMENT_STATUS.SUBMIT_EXTRAS]: (state) => ({
    ...state,
  }),
  [NEW_PAYMENT_STATUS.SUBMIT_EXTRAS_SUCCESS]: (state) => ({
    ...state,
    extrasDialogControls: {
      ...state.extrasDialogControls,
      licensePlate: '',
      selectedLease: '',
      extraType: 0,
      amount: '',
      topAmount: 0,
      description: '',
      attachments: [],
    },
    controls: {
      ...state.controls,
      extrasDialog: false,
    },
  }),
  [NEW_PAYMENT_STATUS.SUBMIT_POST]: (state) => ({
    ...state,
  }),
  [NEW_PAYMENT_STATUS.SUBMIT_POST_SUCCESS]: (state) => ({
    ...state,
    postDialogControls: {
      ...state.postDialogControls,
      observation: '',
      attachments: [],
    },
    controls: {
      ...state.controls,
      postDialog: false,
    },
  }),
  [NEW_PAYMENT_STATUS.SUBMIT_DOCUMENTS]: (state) => ({
    ...state,
  }),
  [NEW_PAYMENT_STATUS.SUBMIT_DOCUMENTS_SUCCESS]: (state) => ({
    ...state,
    documentsDialogControls: {
      ...state.documentsDialogControls,
      attachments: [],
      filesIndex: [],
    },
    controls: {
      ...state.controls,
      documentsDialog: false,
    },
  }),
  [NEW_PAYMENT_STATUS.DELETE_EXTRAS_DOCUMENT]: (state) => ({
    ...state,
  }),
  [NEW_PAYMENT_STATUS.DELETE_EXTRAS_DOCUMENT_SUCCESS]: (state) => ({
    ...state,
    controls: {
      ...state.controls,
      confirmationExtrasDialog: false,
      extrasDocumentId: 0,
    },
  }),
  [NEW_PAYMENT_STATUS.DELETE_FLEETS_DOCUMENT]: (state) => ({
    ...state,
  }),
  [NEW_PAYMENT_STATUS.DELETE_FLEETS_DOCUMENT_SUCCESS]: (state) => ({
    ...state,
    controls: {
      ...state.controls,
      confirmationFleetsDialog: false,
      fleetsDocumentId: 0,
    },
  }),
  [NEW_PAYMENT_STATUS.WORK_PAYMENT_STATUS]: (state) => ({
    ...state,
  }),
  [NEW_PAYMENT_STATUS.WORK_PAYMENT_STATUS_SUCCESS]: (state) => ({
    ...state,
    controls: {
      ...state.controls,
      confirmationWorkDialog: false,
    },
  }),
  [NEW_PAYMENT_STATUS.RELEASE_PAYMENT_STATUS]: (state) => ({
    ...state,
  }),
  [NEW_PAYMENT_STATUS.RELEASE_PAYMENT_STATUS_SUCCESS]: (state) => ({
    ...state,
    controls: {
      ...state.controls,
      confirmationReleaseDialog: false,
    },
  }),
  [NEW_PAYMENT_STATUS.ACCEPT_FLEET]: (state) => ({
    ...state,
  }),
  [NEW_PAYMENT_STATUS.ACCEPT_FLEET_SUCCESS]: (state) => ({
    ...state,
    controls: {
      ...state.controls,
      confirmationAcceptFleetDialog: false,
    },
    approveDialogControls: {
      ...state.approveDialogControls,
      oc: '',
      hes: '',
      attachments: [],
    },
  }),
  [NEW_PAYMENT_STATUS.APPROVE_PAYMENT_STATUS]: (state) => ({
    ...state,
  }),
  [NEW_PAYMENT_STATUS.APPROVE_PAYMENT_STATUS_SUCCESS]: (state) => ({
    ...state,
    controls: {
      ...state.controls,
      approveDialog: false,
    },
  }),
  [NEW_PAYMENT_STATUS.CLOSE_PAYMENT_STATUS]: (state) => ({
    ...state,
  }),
  [NEW_PAYMENT_STATUS.CLOSE_PAYMENT_STATUS_SUCCESS]: (state) => ({
    ...state,
    controls: {
      ...state.controls,
      confirmationCloseDialog: false,
    },
  }),
  [NEW_PAYMENT_STATUS.DELETE_EXTRA]: (state) => ({
    ...state,
  }),
  [NEW_PAYMENT_STATUS.DELETE_EXTRA_SUCCESS]: (state) => ({
    ...state,
    controls: {
      ...state.controls,
      confirmationDeleteExtrasDialog: false,
      extraId: 0,
    },
  }),
  [NEW_PAYMENT_STATUS.FETCH_PAYMENT_STATUS_FAIL]: (state) => ({
    ...state,
    error: true,
    loading: false,
  }),
});

export default newPaymentStatus;
