import { Box, makeStyles, Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';

import styles from './styles';

const useStyles = makeStyles(styles);
const GeneralCardItem = ({
  icon,
  title,
  firstRow,
  secondRow = null,
  width,
  loading = false,
  colors,
  fontWeights,
}) => {
  const classes = useStyles();

  return (
    <Box display="inline-block">
      <Box display="flex" width={width}>
        {icon ? <Box className={classes.itemIcon}>{icon}</Box> : null}
        {icon && loading ? <Skeleton /> : null}
        <Box overflow="auto">
          <Typography component="div" color="primary" noWrap>
            {title ? (
              <Box
                component="span"
                fontSize={16}
                display="flex"
                justifyContent="start"
                alignItems="center"
                height={24}
                fontWeight={900}
              >
                {title}
              </Box>
            ) : null}
            {title && loading ? <Skeleton /> : null}
            {firstRow ? (
              <Box
                component="span"
                display="flex"
                justifyContent="start"
                alignItems="end"
                color={colors?.firstRow}
                fontWeight={fontWeights?.firstRow}
                textOverflow="ellipsis"
              >
                {firstRow}
              </Box>
            ) : null}
            {firstRow && loading ? <Skeleton /> : null}
            <Box
              component="span"
              display="flex"
              justifyContent="start"
              alignItems="end"
              color={colors?.secondRow}
              fontWeight={fontWeights?.secondRow}
              textOverflow="ellipsis"
            >
              {secondRow}
            </Box>
            {secondRow && loading ? <Skeleton /> : null}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default GeneralCardItem;
