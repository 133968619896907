import theme from 'App/theme';

const colors = {
  red: '#e53935',
  green: '#4CAF50',
  grey: '#babdbe',
  lightGray: '#f3f3f3',
  darkblue: '#002855',
  yellow: '#FFA701',
  OPERATIVO: 'green',
  REEMPLAZADO: 'orange',
  'EN TRANSFERENCIA': 'green',
  TALLER: theme.palette.secondary.main,
};

export default colors;
