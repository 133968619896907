import { alpha } from '@material-ui/core/styles/colorManipulator';
import Dashboard from '../../commons/assets/images/home-salfa-fleet.webp';
import Newsletter from '../../commons/assets/images/home-newsletter.webp';
import Scheduling from '../../commons/assets/images/home-scheduling.webp';
import Reports from '../../commons/assets/images/home-reports.webp';

const styles = (theme) => ({
  container: {
    fontFamily: ['Roboto', 'sans-serif'].join(','),
    fontWeight: '700',
    fontSize: 32,
    maxWidth: '1200px',
    width: '100%',
    margin: '0 auto',
    height: 'calc(100vh - 210px)',
    maxHeight: '690px',
    justifyContent: 'center',
  },
  activeCard: {
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
    },
  },
  inactiveCard: {
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
  },
  logo: {
    marginBottom: theme.spacing(2),
  },
  text: {
    bottom: '10px',
    backgroundColor: theme.palette.primary.main,
    padding: '10px',
    marginBottom: '12px',
    marginLeft: '10px',
    marginRight: '10px',
    borderRadius: '10px',
    textAlign: 'center',
    '& span': {
      color: 'white',
    },
    '&:visited': {
      color: 'white',
    },
    color: 'white',
    textDecoration: 'none',
    fontWeight: 'bold',
  },
  link: {
    '&:visited': {
      color: theme.palette.primary.main,
    },
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
  cardMobile: {
    height: '25vh',
    marginBottom: '20px',
  },
  disabled: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  card: {
    '&:hover': {
      transform: 'scale(1.04)',
    },
    transition: 'all 0.3s ease',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    borderRadius: '30px',
    overflow: 'hidden',
    // add shadow to the card
    boxShadow: theme.shadows[5],
    height: '100%',
  },
  dashboard: {
    backgroundImage: `url(${Dashboard})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  reports: {
    backgroundImage: `url(${Reports})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  right: {
    height: '100%',
    margin: 0,
    padding: 0,
  },
  newsletter: {
    backgroundImage: `url(${Newsletter})`,
    marginTop: '10px',
    height: 'calc(100% - 10px)',
  },
  newsletterMobile: {
    backgroundImage: `url(${Newsletter})`,
  },
  scheduling: {
    backgroundImage: `url(${Scheduling})`,
    marginBottom: '10px',
    height: 'calc(100% - 10px)',
  },
  schedulingMobile: {
    backgroundImage: `url(${Scheduling})`,
  },
  checkbox: {
    position: 'absolute',
    zIndex: 1,
    left: '50%',
    backgroundColor: 'white',
    margin: '10px 0',
    '&:hover': {
      backgroundColor: 'white',
      cursor: 'pointer',
    },
    '&:focus': {
      backgroundColor: 'white',
      cursor: 'pointer',
    },
    '&:active': {
      backgroundColor: 'white',
      cursor: 'pointer',
      boxShadow: 'none',
    },
  },
  checkboxContainer: {
    position: 'relative',
  },
});

export default styles;
