import React from 'react';
import {
  Route, Switch,
} from 'react-router-dom';
import NotFound from 'screens/NotFound';
import Auth from './Auth';
import Paths from '../../utils/paths';

const Routes = ({ user, loading }) => {
  const { shared } = Paths;
  const userRoute = Paths[user?.type];

  return (
    <>
      <Auth user={user} loading={loading}>
        <Switch>
          {userRoute?.map(({ path, component }) => (
            <Route
              key={path}
              exact
              path={path}
              component={component}
            />
          ))}
          <Route path="*" component={NotFound} />
        </Switch>
      </Auth>
      <Switch>
        {shared.map(({ path, component }) => (
          <Route
            key={path}
            exact
            path={path}
            component={component}
          />
        ))}
      </Switch>
    </>
  );
};

export default Routes;
