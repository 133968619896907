import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { render } from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import 'fontsource-roboto';
import { LocalizationProvider } from '@material-ui/pickers';

// utils
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns';
import esLocale from 'date-fns/locale/es';
import configureStore, { ReactReduxContext } from './config/configureStore';

// components
import App from './App';

// styles
import './commons/assets/styles/styles.scss';

// init sentry
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_KEY,
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: process.env.REACT_APP_TRACES_SAMPLE_RATE || 0.1,
});

const store = configureStore();
const persistor = persistStore(store);

const renderApp = () => render(
  <Provider store={store} context={ReactReduxContext}>
    <PersistGate loading={null} persistor={persistor}>
      <LocalizationProvider dateAdapter={DateFnsUtils} locale={esLocale}>
        <BrowserRouter>
          <App context={ReactReduxContext} />
        </BrowserRouter>
      </LocalizationProvider>
    </PersistGate>
  </Provider>,
  document.querySelector('#root'),
);

renderApp();

if (process.env.NODE_ENV !== 'production') {
  if (module.hot) {
    // Support hot reloading of components
    // and display an overlay for runtime errors
    module.hot.accept('./App', () => {
      renderApp();
    });
  }
}
