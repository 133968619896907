const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
  sectionContainer: {
    padding: theme.spacing(4),
    borderRadius: 8,
    boxShadow: theme.shadows[4],
  },
  button: {
    display: 'flex',
    float: 'right',
    fontWeight: theme.typography.fontWeightMedium,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  formControl: {
    display: 'flex',
    width: '100%',
  },
  formGroup: {
    display: 'flex',
    backgroundColor: 'red',
  },
  space: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
});

export default styles;
