import { createAction, createReducer } from 'utils/reducer';
import { FILTER_OPTIONS, HOLDING_ATTR } from '../constants';

// Local constants
const PATH = 'holding/';

// Global constants for saga
export const HOLDING = {
  SELECT_HOLDING: `${PATH}SELECT_HOLDING`,
  ADD_HOLDING: `${PATH}ADD_HOLDING`,
  ADD_HOLDING_SUCCESS: `${PATH}ADD_HOLDING_SUCCESS`,
  ADD_HOLDING_ERROR: `${PATH}ADD_HOLDING_ERROR`,
  ADD_HOLDING_SR: `${PATH}ADD_HOLDING_SR`,
  ADD_HOLDING_SR_SUCCESS: `${PATH}ADD_HOLDING_SR_SUCCESS`,
  ADD_HOLDING_SR_ERROR: `${PATH}ADD_HOLDING_SR_ERROR`,
  UPDATE_HOLDING: `${PATH}UPDATE_HOLDING`,
  UPDATE_HOLDING_SUCCESS: `${PATH}UPDATE_HOLDING_SUCCESS`,
  DEACTIVATE: `${PATH}DEACTIVATE`,
  PAGINATION_SETUP_LOAD: `${PATH}PAGINATION_SETUP_LOAD`,
  GENERATE_REPORT: `${PATH}GENERATE_REPORT`,
  CHANGE_PAGE: `${PATH}CHANGE_PAGE`,
  SET_MOBILE_FILTER: `${PATH}SET_MOBILE_FILTER`,
  SET_FILTER: `${PATH}SET_FILTER`,
  APPLY_FILTER: `${PATH}APPLY_FILTER`,
  SET_ORDER_BY: `${PATH}SET_ORDER_BY`,
  CHANGE_ORDER_BY: `${PATH}CHANGE_ORDER_BY`,
  RESET_FILTER: `${PATH}RESET_FILTER`,
  RESET_STATE: `${PATH}RESET_STATE`,
  RESET_CONTROLS: `${PATH}RESET_CONTROLS`,
};

// actions
export const holdingsActions = {
  selectHolding: createAction(HOLDING.SELECT_HOLDING, 'payload'),
  addHolding: createAction(HOLDING.ADD_HOLDING, 'payload'),
  addHoldingSuccess: createAction(HOLDING.ADD_HOLDING_SUCCESS, 'payload'),
  addHoldingError: createAction(HOLDING.ADD_HOLDING_ERROR, 'payload'),
  addHoldingSR: createAction(HOLDING.ADD_HOLDING_SR, 'payload'),
  updateHolding: createAction(HOLDING.UPDATE_HOLDING, 'payload'),
  updateHoldingSuccess: createAction(HOLDING.UPDATE_HOLDING_SUCCESS, 'payload'),
  paginationSetup: createAction(HOLDING.PAGINATION_SETUP_LOAD, 'holdingCount'),
  generateReport: createAction(HOLDING.GENERATE_REPORT),
  changePage: createAction(HOLDING.CHANGE_PAGE, 'payload'),
  setMobileFilter: createAction(HOLDING.SET_MOBILE_FILTER),
  setFilter: createAction(HOLDING.SET_FILTER, 'payload'),
  resetFilter: createAction(HOLDING.RESET_FILTER),
  applyFilter: createAction(HOLDING.APPLY_FILTER),
  setOrderBy: createAction(HOLDING.SET_ORDER_BY, 'payload'),
  changeOrderBy: createAction(HOLDING.CHANGE_ORDER_BY),
  resetState: createAction(HOLDING.RESET_STATE),
};

const initialState = {
  holding: {},
  controls: {
    [FILTER_OPTIONS.NAME]: '',
    [FILTER_OPTIONS.STATUS]: [true],
    [FILTER_OPTIONS.DATE]: null,
    mobileFilter: false,
    orderBy: 'rut',
    desc: true,
    reset: false,
    resetValues: true,
  },
  pagination: {
    size: 10,
    page: 1,
    totalPages: 20,
  },
  loading: false,
  loadingData: false,
  pageLoading: false,
};

const holdingMaintainer = createReducer(initialState, {
  [HOLDING.SELECT_HOLDING]: (state, action) => ({
    ...state,
    holding: action.payload.holding,
  }),
  [HOLDING.ADD_HOLDING_SUCCESS]: () => {},
  [HOLDING.ADD_HOLDING_ERROR]: () => {},
  [HOLDING.DEACTIVATE]: (state) => ({
    ...state,
    holding: { ...state.holding, isActive: false },
  }),
  [HOLDING.UPDATE_HOLDING_SUCCESS]: (state, action) => ({
    ...state,
    holding: action.payload,
  }),
  [HOLDING.PAGINATION_SETUP_LOAD]: (state, action) => ({
    ...state,
    pagination: {
      ...state.pagination,
      totalPages: action.holdingCount > 0
        ? Math.trunc((action.holdingCount - 1) / state.pagination.size) + 1 : 1,
    },
  }),
  [HOLDING.CHANGE_PAGE]: (state, action) => ({
    ...state,
    pagination: {
      ...state.pagination,
      page: action.payload,
    },
  }),
  [HOLDING.SET_MOBILE_FILTER]: (state) => ({
    ...state,
    controls: {
      ...state.controls,
      mobileFilter: !state.controls.mobileFilter,
    },
  }),
  [HOLDING.SET_FILTER]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      [action.payload.key]: action.payload.value,
    },
  }),
  [HOLDING.RESET_FILTER]: (state) => ({
    ...state,
    controls: {
      ...state.controls,
      orderBy: 'rut',
      [FILTER_OPTIONS.NAME]: '',
      [FILTER_OPTIONS.STATUS]: [true],
      [FILTER_OPTIONS.DATE]: null,
    },
    pagination: {
      ...state.pagination,
      page: 1,
    },
  }),
  [HOLDING.APPLY_FILTER]: (state) => ({
    ...state,
    pagination: {
      ...state.pagination,
      page: 1,
    },
  }),
  [HOLDING.SET_ORDER_BY]: (state, action) => {
    if (!(Object.values(HOLDING_ATTR).includes(action.payload))) return state;

    return {
      ...state,
      controls: {
        ...state.controls,
        orderBy: action.payload,
      },
    };
  },
  [HOLDING.CHANGE_ORDER_BY]: (state) => ({
    ...state,
    controls: {
      ...state.controls,
      desc: !state.controls.desc,
    },
  }),
});

export default holdingMaintainer;
