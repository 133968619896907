import React, { useEffect, useCallback, useMemo } from 'react';
// Material
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';

// Project
import ScreenTitle from 'commons/components/ScreenTitle';
import { appActions } from 'commons/reducer';
import Skeleton from 'screens/Fleets/components/Skeleton';
import { composedComponent } from 'utils/functions';
import { screenTime } from 'utils/utils';
import Configuration from './components/Configuration';
import Administration from './components/Administration';
import Actions from './components/Actions';
import saga from './saga';
import { fleetsActions } from './reducer';
import styles from './styles';
import NotFleetMessage from './components/NotFleetMessage';
import types from '../../utils/type';

const useStyles = makeStyles(styles);

const toAssignTimeAnimation = { appear: 10000, enter: 500, exit: 0 };
const toAssignNotAvailableTimeAnimation = { appear: 10000, enter: 500, exit: 0 };

const Fleets = ({
  objects, controls, modals, actions, loading, loadingData, holding, socialReason, socialReasons,
}) => {
  const classes = useStyles();

  useEffect(() => {
    if (holding?.id) {
      actions.getData();
    }
  }, [holding]);

  useEffect(() => screenTime('fleets'), []);

  useEffect(() => () => { actions.resetObject('vehicles'); }, []);

  const fleets = useMemo(() => Object.values(objects.fleets), [objects.fleets]);
  const selectedFleet = fleets.find((f) => f.id === controls?.fleet);

  const availableVehicles = useMemo(() => Object.values(objects.vehicles)
    .filter((v) => !v.fleetId)
    .filter((v) => v.contract?.socialReasonId === (selectedFleet
      ? selectedFleet?.socialReasonId
      : v.contract?.socialReasonId)),
  [objects.vehicles]);

  const assignedVehicles = useMemo(() => Object.keys(objects.vehicles)
    .map((key) => ({ ...objects.vehicles[key] }))
    .filter((v) => v.fleetId === controls.fleet),
  [objects.vehicles]);

  const allFleetAdmins = useMemo(() => Object.values(objects.users)
    .filter((u) => u.type === types.ENCARGADO_DE_FLOTA_SECCION), [objects.users]);
  const availableFleetAdmins = useMemo(() => allFleetAdmins
    .filter((u) => fleets.every((f) => f.userId !== u.id)), [allFleetAdmins, fleets]);

  const onChangeInput = useCallback((name, value) => {
    actions.setFormControls({ [name]: value });
  }, []);

  const handleSelectAssign = useCallback((value) => {
    const found = controls.toAssign.find((a) => a === value);
    if (!found) {
      actions.addAssign(value);
    } else {
      actions.removeAssign(value);
    }
  }, [controls]);

  const handleSelectDeallocate = useCallback((value) => {
    const found = controls.toDeallocate.find((a) => a === value);
    if (!found) {
      actions.addDeallocate(value);
    } else {
      actions.removeDeallocate(value);
    }
  }, [controls]);

  const handleAllAssign = useCallback(() => {
    if (controls.toAssign.length < availableVehicles.length) {
      actions.assignAll(availableVehicles.map((a) => a.id));
    } else {
      actions.assignAll([]);
    }
  }, [availableVehicles, controls]);

  const handleAllDeallocate = useCallback(() => {
    if (controls.toDeallocate.length < assignedVehicles.length) {
      actions.deallocateAll(assignedVehicles.map((a) => a.id));
    } else {
      actions.deallocateAll([]);
    }
  }, [assignedVehicles, controls]);

  return (
    !loadingData ? <Skeleton />
      : (
        <>
          <ScreenTitle title="CONFIGURACIÓN FLOTA" />

          <Configuration
            holding={holding}
            socialReason={socialReason}
            socialReasons={socialReasons}
            fleets={fleets}
            availableFleetAdmins={availableFleetAdmins}
            allFleetAdmins={allFleetAdmins}
            actions={actions}
            controls={controls}
            modals={modals}
            onChangeInput={onChangeInput}
          />

          <Grid container className={classes.gridContainer}>
            <Grid item xs={12} sm={5} className={classes.gridItem}>
              <Administration
                data={availableVehicles}
                selected={controls.toAssign}
                title="Disponibles"
                handleSelect={handleSelectAssign}
                handleAll={handleAllAssign}
              />
            </Grid>
            <Slide
              direction="up"
              in={!controls.fleet}
              unmountOnExit
              timeout={toAssignNotAvailableTimeAnimation}
            >
              <Grid item xs={12} sm={7}>
                <NotFleetMessage />
              </Grid>
            </Slide>
            <Slide
              direction="up"
              in={!!controls.fleet}
              mountOnEnter
              unmountOnExit
              timeout={toAssignTimeAnimation}
            >
              <Grid item xs={12} sm={2} className={classes.gridActionItem}>
                <Actions
                  loading={loading}
                  actions={actions}
                  controls={controls}
                />
              </Grid>
            </Slide>
            <Slide
              direction="up"
              in={!!controls.fleet}
              mountOnEnter
              unmountOnExit
              timeout={toAssignTimeAnimation}
            >
              <Grid item xs={12} sm={5} className={classes.gridItem}>
                <Administration
                  data={assignedVehicles}
                  selected={controls.toDeallocate}
                  title="Asignados"
                  handleSelect={handleSelectDeallocate}
                  handleAll={handleAllDeallocate}
                />
              </Grid>
            </Slide>
          </Grid>
        </>
      )
  );
};

export default composedComponent(Fleets, saga, {
  saga: 'sagaFleet',
  states: [
    'fleets.kpi',
    'fleets.pagination',
    'fleets.filtersData',
    'fleets.controls',
    'fleets.modals',
    'fleets.loading',
    'fleets.loadingData',
    'fleets.actions',
    'app.objects',
    'app.holding',
    'app.socialReason',
    'app.objects.socialReasons',
  ],
  actions: [fleetsActions, appActions],
});
