import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Skeleton } from '@material-ui/lab';
import MuiPhoneNumber from 'material-ui-phone-number';
import styles from './styles';

const useStyles = makeStyles(styles);

const CustomPhoneInput = ({
  id,
  name,
  label,
  value,
  error,
  helperText,
  type = 'text',
  onChange = (e) => e.preventDefault(),
  variant,
  loading,
  disabled = false,
  size = 'small',
  defaultCountry = 'cl',
  countryCodeEditable = true,
}) => {
  const classes = useStyles();

  return (
    <>
      {!loading
        ? (
          <MuiPhoneNumber
            defaultCountry={defaultCountry}
            countryCodeEditable={countryCodeEditable}
            size={size}
            className={classes.borderColor}
            fullWidth
            id={id}
            value={value}
            name={name}
            label={label}
            type={type}
            variant={variant}
            onChange={onChange}
            error={error}
            helperText={helperText}
            disabled={disabled}
          />
        )
        : (
          <Skeleton
            width="100%"
            height={40}
            className={classes.skeleton}
          />
        )}
    </>
  );
};
export default CustomPhoneInput;
