/* eslint-disable func-names */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/named */
import {
  takeLatest, spawn, put, select,
} from 'redux-saga/effects';
import { get, post, apiSuccess } from 'utils/api';

import { appActions } from 'commons/reducer';
import * as APP from '../../../commons/reducer';

// Reducer
import { CLIENT_SETTINGS } from '../reducer';

const getHolding = (state) => state.app.holding.id;
const getControls = (state) => state.clientSettings.controls;

function* getStateFromApi() {
  yield takeLatest(CLIENT_SETTINGS.GET_STATE_FROM_API, function* () {
    yield put(appActions.setLoader('fetch_data', true));
    const holding = yield select(getHolding);
    const response = yield get(`api/v1/client/admins/${holding}`);
    if (!response.error) {
      yield put(apiSuccess(CLIENT_SETTINGS.GET_STATE_FROM_API_SUCCESS, response));
      yield put(apiSuccess(APP.GET_STATE_REPLACE_FROM_API_SUCCESS, response));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { msg: 'Error al obtener los ejecutivos' }));
    }
    yield put(appActions.setLoader('fetch_data', false));
  });
}

function* submitClientSettings() {
  yield takeLatest(CLIENT_SETTINGS.SUBMIT_CLIENT_SETTINGS, function* () {
    yield put(appActions.setLoader('submit', true));
    const { contractManagers } = yield select(getControls);
    const response = yield post('api/v1/client/admins/', contractManagers);
    if (!response.error) {
      yield put(apiSuccess(CLIENT_SETTINGS.SUBMIT_CLIENT_SETTINGS_SUCCESS, response));
      yield put(apiSuccess(APP.GET_STATE_REPLACE_FROM_API_SUCCESS, response));
      yield put(apiSuccess(APP.GET_EXECUTIVE_SUCCESS, response));
      yield put(apiSuccess(APP.SET_SUCCESS, { msg: 'Guardado!' }));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { msg: 'Error al seleccionar los ejecutivos' }));
    }
    yield put(appActions.setLoader('submit', false));
  });
}

export default function* root() {
  yield spawn(getStateFromApi);
  yield spawn(submitClientSettings);
}
