import React, { useEffect, useMemo, useState } from 'react';
import ScreenTitle from 'commons/components/ScreenTitle';
import Grid from '@material-ui/core/Grid';
import {
  Box, Chip, FormHelperText, IconButton, Typography,
} from '@material-ui/core';
import CustomSelect from 'commons/components/CustomSelect';
import Divider from '@material-ui/core/Divider';
import CustomTextField from 'commons/components/CustomTextField';
import CustomAutoComplete from 'commons/components/CustomAutoComplete';
import { alpha, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AutoCompleteMultiple from 'commons/components/AutoCompleteMultiple';
import { useHistory } from 'react-router-dom';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CustomPhoneInput from 'commons/components/CustomPhoneInput';
import AdminContractHoldingsDialog from './AdminContractHoldingsDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  chip: {
    background: alpha(theme.palette.primary.main, 0.08),
  },
  backButton: {
    marginLeft: '-64px',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '-16px',
    },
  },
  error: {
    color: '#f35549',
    marginLeft: '56px',
  },
}));

const Form = ({
  isEdit,
  buttonMessage,
  roles,
  holdings,
  contractAdmins,
  onSelectAdmin,
  toggleHolding,
  onAddHoldingsTemplate,
  resetTempHoldings,
  fetchContractAdmins,
  controls,
  onChange,
  loading,
  onSubmit,
  error,
  errors,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [isAdminContractDialogOpen, setIsAdminContractDialogOpen] = useState(false);

  useEffect(() => {
    if (isEdit && error) {
      history.push('/user');
    }
  }, [isEdit, error]);

  const adminContract = useMemo(() => controls.rol === 'ADMINISTRADOR_DE_CONTRATO',
    [controls.rol]);

  const admin = useMemo(() => controls.rol === 'ADMINISTRADOR_PORTAL_SALFA',
    [controls.rol]);

  const onChangeDelete = (value) => {
    const currentHoldings = controls.holdings.filter((h) => h !== value);
    onChange({ target: { id: 'holdings', value: currentHoldings, type: 'text' }, currentTarget: { id: 'holdings' } });
  };

  const handlePhoneNumber = (phone) => {
    const id = 'phone';
    onChange({ target: { id, value: phone || '' }, currentTarget: { id } });
  };

  const handleOpenDialog = () => {
    fetchContractAdmins();
    setIsAdminContractDialogOpen(true);
  };
  const handleCloseDialog = () => {
    setIsAdminContractDialogOpen(false);
    resetTempHoldings();
  };
  const handleSubmitDialog = () => {
    setIsAdminContractDialogOpen(false);
    onAddHoldingsTemplate();
  };

  const SubTitle = ({ title }) => (
    <Typography color="primary" variant="h4">
      <Box fontWeight={300} fontSize={16}>
        {title}
      </Box>
    </Typography>
  );

  return (
    <form onSubmit={onSubmit} autoComplete="off">
      <Grid container spacing={3}>
        <Grid item xs={12} className={classes.backButton}>
          <IconButton disableRipple onClick={() => { history.goBack(); }}>
            <ArrowBackIcon color="secondary" />
            <Typography color="primary">Volver</Typography>
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <ScreenTitle
            title={isEdit ? 'EDITAR INFORMACIÓN DE USUARIO' : 'CREACIÓN DE USUARIO NUEVO'}
            showSubTitle={false}
          />
        </Grid>
        <Grid item xs={12}>
          <SubTitle
            title={isEdit
              ? 'Aquí puedes editar la informacion de usuario que desees.'
              : 'Completar la información del nuevo usuario para enviar email con acceso al portal.'}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={6}>
            <Grid item xs={12} md={4}>
              <CustomSelect
                label="Rol"
                id="rol"
                name="rol"
                variant="outlined"
                onChange={onChange}
                value={controls.rol}
                options={roles}
                error={!!errors.rol}
                helperText={errors.rol}
                disabled={isEdit}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={6}>
            {adminContract
          && (
            <>
              <Grid item xs={12} md={4}>
                <AutoCompleteMultiple
                  id="holdings"
                  name="holdings"
                  label="Holding"
                  value={controls.holdings}
                  options={holdings}
                  onChange={onChange}
                  error={!!errors.holdings}
                  helperText={errors.holdings && errors.holdings[0]}
                />
              </Grid>
              <Grid item md={8}>
                <div className={classes.root}>
                  {holdings.filter((h) => controls.holdings.includes(h.value)).map((h) => (
                    <Chip
                      key={h.value}
                      label={h.label}
                      value={h.value}
                      className={classes.chip}
                      onDelete={() => onChangeDelete(h.value)}
                    />
                  ))}
                </div>
              </Grid>
            </>
          )}
          </Grid>
        </Grid>
        {adminContract
      && (
        <>
          <Grid item xs={12}>
            <Button
              onClick={() => handleOpenDialog()}
              variant="contained"
              color="primary"
            >
              Agregar holdings de otro administrador

            </Button>
          </Grid>
          <AdminContractHoldingsDialog
            users={contractAdmins}
            isOpen={isAdminContractDialogOpen}
            onSelectAdmin={onSelectAdmin}
            holdings={controls.tempHoldings}
            toggleHolding={toggleHolding}
            onClose={handleCloseDialog}
            onSubmit={handleSubmitDialog}
          />
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </>
      )}
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            spacing={6}
          >
            {(!adminContract && !admin)
          && (
          <Grid item xs={12} md={4}>
            <CustomAutoComplete
              id="holding"
              name="holding"
              label="Holding"
              value={controls.holding}
              options={holdings}
              onChange={onChange}
              error={!!errors.holding}
              helperText={errors.holding && errors.holding[0]}
            />
          </Grid>
          )}
            <Grid item xs={12} md={4}>
              <CustomTextField
                id="email"
                name="email"
                label="Email"
                type="text"
                variant="outlined"
                value={controls.email}
                onChange={onChange}
                error={!!errors.email}
                helperText={errors.email && errors.email[0]}
                disabled={isEdit}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomTextField
                id="name"
                name="name"
                label="Nombre"
                type="text"
                variant="outlined"
                value={controls.name}
                onChange={onChange}
                error={!!errors.name}
                helperText={errors.name && errors.name[0]}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomTextField
                id="lastName"
                name="lastName"
                label="Apellido"
                type="text"
                variant="outlined"
                value={controls.lastName}
                onChange={onChange}
                error={!!errors.lastName}
                helperText={errors.lastName && errors.lastName[0]}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomPhoneInput
                onChange={handlePhoneNumber}
                error={!!errors.phone}
                value={controls.phone}
                id="phone"
                name="phone"
                variant="outlined"
                size="small"
              />
              <FormHelperText
                id="phoneError"
                className={classes.error}
              >
                {errors.phone && errors.phone[0]}
              </FormHelperText>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} align="right">
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            disabled={loading}
          >
            {buttonMessage}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
export default Form;
