import {
  takeLatest, spawn, put, select,
} from 'redux-saga/effects';
import { apiSuccess, get, post } from '../../../utils/api';

import { ACCOUNT } from '../reducer';
import * as APP from '../../../commons/reducer';

const getUser = (state) => state.app.user.user;

function* getStateFromApiUser() {
  yield takeLatest(ACCOUNT.GET_STATE_FROM_API_USER, function* getStateFromApiUserApi() {
    const response = yield get('api/v1/auth/user');

    if (!response.error) {
      yield put(apiSuccess(ACCOUNT.GET_STATE_FROM_API_USER_SUCCESS, response));
    } else {
      yield put(apiSuccess(ACCOUNT.GET_STATE_FROM_API_USER_ERROR));
    }
  });
}

function* changePassword() {
  yield takeLatest(ACCOUNT.POST_UPDATE_PASSWORD, function* (action) {
    const response = yield post('api/v1/auth/change-password', action.formControl);
    if (!response.error) {
      yield put(apiSuccess(APP.SET_SUCCESS, { msg: response.message }));
      yield put(apiSuccess(ACCOUNT.RESET_FORM_CONTROLS));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { msg: JSON.parse(response.errorMsg).message }));
    }
  });
}

function* getNotification() {
  yield takeLatest(ACCOUNT.GET_NOTIFICATION, function* () {
    const response = yield get('api/v1/users/notification');
    if (!response.error) {
      yield put(apiSuccess(ACCOUNT.GET_NOTIFICATION_SUCCESS, response));
    } else {
      const { errorMsg } = response;
      yield put(apiSuccess(APP.SET_ERROR, { msg: errorMsg }));
    }
  });
}

function* updateNotification() {
  yield takeLatest(ACCOUNT.UPDATE_NOTIFICATION, function* (action) {
    const { id: userId } = yield select(getUser);
    const requestBody = { ...action.payload, userId };

    const response = yield post('api/v1/users/notification', requestBody);
    if (!response.error) {
      const { msg } = response;
      yield put(apiSuccess(APP.SET_SUCCESS, { msg }));
      yield put(apiSuccess(ACCOUNT.UPDATE_NOTIFICATION_SUCCESS, action.payload));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { msg: response.errorMsg }));
    }
  });
}

function* getNotificationSettings() {
  yield takeLatest(ACCOUNT.GET_NOTIFICATION_SETTINGS, function* () {
    const { id: userId } = yield select(getUser);
    const path = `/api/v1/users/notifications-setting/${userId}`;
    const response = yield get(path);
    if (!response.error) {
      const { msg } = response;
      yield put(apiSuccess(APP.SET_SUCCESS, { msg }));
      yield put(apiSuccess(ACCOUNT.GET_NOTIFICATION_SETTINGS_SUCCESS, response));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { msg: response.errorMsg }));
    }
  });
}

export default function* root() {
  yield spawn(getStateFromApiUser);
  yield spawn(changePassword);
  yield spawn(updateNotification);
  yield spawn(getNotification);
  yield spawn(getNotificationSettings);
}
