import { applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';

export const sagaMiddleware = createSagaMiddleware();

const middlewares = [
  sagaMiddleware,
];

export default applyMiddleware(...middlewares);
