/* eslint-disable no-param-reassign */
/* eslint-disable import/no-unresolved */
import { createAction, createReducer } from 'utils/reducer';

// Local constants
const PATH = 'clientSettings/';

const DISABLE_ERRORS = `${PATH}DISABLE_ERRORS`;
const RESET = `${PATH}RESET`;
const CHANGE_CONTROLS = `${PATH}CHANGE_CONTROLS`;

// Global constants for saga
export const CLIENT_SETTINGS = {
  GET_STATE_FROM_API: `${PATH}GET_STATE_FROM_API`,
  GET_STATE_FROM_API_SUCCESS: `${PATH}GET_STATE_FROM_API_SUCCESS`,
  GET_STATE_FROM_API_FAIL: `${PATH}GET_STATE_FROM_API_FAIL`,
  SUBMIT_CLIENT_SETTINGS: `${PATH}SUBMIT_CLIENT_SETTINGS`,
  SUBMIT_CLIENT_SETTINGS_SUCCESS: `${PATH}SUBMIT_CLIENT_SETTINGS_SUCCESS`,
  SUBMIT_CLIENT_SETTINGS_FAIL: `${PATH}SUBMIT_CLIENT_SETTINGS_FAIL`,
};

// actions
export const clientSettingsActions = {
  disableErrors: createAction(DISABLE_ERRORS),
  changeControls: createAction(CHANGE_CONTROLS, 'controls'),
  // api
  getStateFromApi: createAction(CLIENT_SETTINGS.GET_STATE_FROM_API),
  getStateFromApiSuccess: createAction(CLIENT_SETTINGS.GET_STATE_FROM_API_SUCCESS, 'payload'),
  getStateFromApiFail: createAction(CLIENT_SETTINGS.GET_STATE_FROM_API_FAIL, 'payload'),
  submitClientSettings: createAction(CLIENT_SETTINGS.SUBMIT_CLIENT_SETTINGS),
  submitClientSettingsSuccess: createAction(CLIENT_SETTINGS.SUBMIT_CLIENT_SETTINGS_SUCCESS, 'payload'),
  submitClientSettingsFail: createAction(CLIENT_SETTINGS.SUBMIT_CLIENT_SETTINGS_FAIL, 'payload'),
};

const defaultControls = {
  contractManagers: {},
};

const defaultInvalidControls = {
  name: false,
};

const initialState = {
  error: false,
  errorMsg: '',
  controls: {
    ...defaultControls,
  },
  invalidControls: {
    ...defaultInvalidControls,
  },
};

const clientSettings = createReducer(initialState, {
  [DISABLE_ERRORS]: (state) => ({
    ...state,
    error: false,
    errorMsg: '',
  }),
  [RESET]: (state) => ({
    ...state,
    controls: { ...defaultControls },
    invalidControls: { ...defaultInvalidControls },
  }),
  [CHANGE_CONTROLS]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      ...action.controls,
    },
  }),
  [CLIENT_SETTINGS.GET_STATE_FROM_API_SUCCESS]: (state, action) => {
    const { contractManagers, users } = action.payload.objects;
    return {
      ...state,
      controls: {
        ...state.controls,
        contractManagers: Object.assign({}, ...Object.values(users).map((user) => ({
          [user.id]: {
            userId: user.id,
            checked: !!Object.values(contractManagers).find((cm) => cm.userId === user.id),
            isMainManager: Object.values(contractManagers)
              .find((cm) => cm?.userId === user.id)?.isMainManager,
          },
        }))),
      },
    };
  },
});

export default clientSettings;
