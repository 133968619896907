import React from 'react';
import {
  Button,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid,
} from '@material-ui/core';
import ButtonWithLoader from 'commons/containers/ButtonWithLoader';

const ClosePreviousPsDialog = ({ controls, actions, disabledSubmit }) => {
  const handleSubmit = () => {
    actions.closePrevious();
  };
  const handleClose = () => {
    actions.changeControls({ key: 'confirmationClosePreviousPsDialog', value: false });
  };
  return (
    <Dialog open={controls.confirmationClosePreviousPsDialog} onClose={handleClose} fullWidth>
      <DialogTitle>CERRAR ESTADOS DE PAGO</DialogTitle>
      <DialogContent>
        <DialogContentText>
          ¿Está seguro que desea cerrar los Estados de pago de periodos anteriores?
        </DialogContentText>
        <DialogActions>
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleClose}
              >
                CANCELAR
              </Button>
            </Grid>
            <Grid item>
              <ButtonWithLoader
                buttonId="close-previous"
                onClick={handleSubmit}
                disabled={disabledSubmit}
                color="secondary"
                variant="contained"
              >
                Cerrar estados de pago
              </ButtonWithLoader>
            </Grid>
          </Grid>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default ClosePreviousPsDialog;
