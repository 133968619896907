const theme = {
  palette: {
    primary: {
      main: '#002855', // blue
      light: '#5b7592',
      contrastText: '#fff',
      disabled: '#f2f2f2',
    },
    secondary: {
      main: '#E10600', // salfa red
      light: '#e95348',
      contrastText: '#fff',
    },
    background: {
      default: '#fff',
    },
    common: {
      white: '#FFFFFF',
    },
  },
  typography: {
    fontFamily: [
      'Open Sans',
    ].join(','),
    body1: {
      fontWeight: 500,
      fontSize: 14,
      color: '#002855',
    },
    body2: {
      fontWeight: 500,
      fontSize: 12,
      color: '#002855',
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: 16,
      color: '#002855',
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: 15,
      color: '#002855',
    },
    h1: {
      fontSize: '42px',
    },
    h2: {
      fontSize: '36px',
    },
    h3: {
      fontSize: '30px',
    },
    h4: {
      fontSize: 22,
      cursor: 'default',
    },
    h5: {
      fontSize: '21px',
    },
    h6: {
      fontSize: 15,
      fontWeight: 700,
      cursor: 'default',
    },
  },
};

export default theme;
