import {
  takeLatest, spawn, put, select,
} from 'redux-saga/effects';
import {
  apiSuccess, downloadFileBlob, get, post,
} from 'utils/api';

import format from 'date-fns/format';
import { USER } from '../reducer';
import * as APP from '../../../commons/reducer';

const getSize = (state) => state.user.pagination.size;
const getPage = (state) => state.user.pagination.page;
const getQuery = (state) => state.user.query;

const getFirstFetch = (state) => state.user.firstFetch;

function* generatePathUser({
  page,
  size,
  route,
}) {
  const pageToSend = page > 0 ? page : (yield select(getPage));
  const sizeToSend = size > 0 ? size : (yield select(getSize));
  let path = `${route}?size=${sizeToSend}&page=${pageToSend}`;

  const {
    holding,
    startDate,
    disabledDate,
    status,
    type,
    userName,
  } = yield select(getQuery);

  if (holding) path += `&holding=${holding}`;
  if (startDate) path += `&startDate=${startDate}`;
  if (disabledDate) path += `&disabledDate=${disabledDate}`;
  if (status) path += `&status=${status}`;
  if (type) path += `&type=${type}`;
  if (userName) path += `&userName=${userName}`;

  return path;
}

function* requestUsers(
  page = 0,
  size = 0,
) {
  const pageToSend = page > 0 ? page : (yield select(getPage));
  const sizeToSend = size > 0 ? size : (yield select(getSize));
  let path = `api/v1/users?size=${sizeToSend}&page=${pageToSend}`;

  const firstFetch = yield select(getFirstFetch);

  const {
    holding,
    startDate,
    disabledDate,
    status,
    type,
    userName,
  } = yield select(getQuery);

  if (firstFetch) path += `&firstFetch=${firstFetch ? 'true' : 'false'}`;
  if (holding) path += `&holding=${holding}`;
  if (startDate) path += `&startDate=${startDate}`;
  if (disabledDate) path += `&disabledDate=${disabledDate}`;
  if (status) path += `&status=${status}`;
  if (type) path += `&type=${type}`;
  if (userName) path += `&userName=${userName}`;

  return { page, size, ...yield get(path) };
}

function* getInitialData() {
  yield takeLatest(USER.FETCH_INITIAL_DATA, function* () {
    // request
    const response = yield requestUsers();
    if (!response.error) {
      yield put(apiSuccess(USER.FETCH_INITIAL_DATA_SUCCESS, response));
      yield put(apiSuccess(APP.GET_STATE_REPLACE_FROM_API_SUCCESS, response));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: 'Error en al cargar usuarios, por favor intentelo más tarde.' }));
    }
  });
}

function* changePage() {
  yield takeLatest(USER.CHANGE_PAGE, function* (action) {
    const page = action.payload;
    const response = yield requestUsers(page);

    if (!response.error) {
      yield put(apiSuccess(USER.CHANGE_PAGE_SUCCESS, response));
      yield put(apiSuccess(APP.GET_STATE_REPLACE_FROM_API_SUCCESS, response));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: 'Error en al cargar usuarios, por favor intentelo más tarde.' }));
    }
  });
}

function* clearControls() {
  yield takeLatest(USER.CLEAR_CONTROLS, function* () {
    const page = 1;
    const response = yield requestUsers(page);
    if (!response.error) {
      yield put(apiSuccess(USER.CLEAR_CONTROLS_SUCCESS, response));
      yield put(apiSuccess(APP.GET_STATE_REPLACE_FROM_API_SUCCESS, response));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: 'Error en al cargar usuarios, por favor intentelo más tarde.' }));
    }
  });
}

function* fetchWithFilter() {
  yield takeLatest(USER.FETCH_DATA_WITH_FILTER, function* () {
    const page = 1;
    const response = yield requestUsers(page);
    if (!response.error) {
      yield put(apiSuccess(USER.FETCH_DATA_WITH_FILTER_SUCCESS, response));
      yield put(apiSuccess(APP.GET_STATE_REPLACE_FROM_API_SUCCESS, response));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: 'Error en al cargar usuarios, por favor intentelo más tarde.' }));
    }
  });
}

function* changeStatus() {
  yield takeLatest(USER.CHANGE_STATUS, function* (action) {
    const path = 'api/v1/users/status';
    const userId = action.payload;
    const response = yield post(path, { userId });

    if (!response.error) {
      yield put(apiSuccess(USER.CHANGE_STATUS_SUCCESS));
      const { isActive } = response;
      const userAction = isActive ? 'habilitado' : 'deshabilitado';
      yield put(apiSuccess(APP.SET_SUCCESS, { msg: `Usuario ${userAction} con éxito` }));
      const response2 = yield requestUsers();
      if (!response2.error) {
        yield put(apiSuccess(APP.GET_STATE_REPLACE_FROM_API_SUCCESS, response2));
      } else {
        yield put(apiSuccess(APP.SET_ERROR, { msg: 'Error interno, inténtelo más tarde.' }));
      }
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { msg: response.errorMsg }));
    }
  });
}

function* downloadExcel() {
  yield takeLatest(USER.DOWNLOAD_EXCEL, function* () {
    const path = yield generatePathUser({
      route: 'api/v1/users/generate-excel',
    });

    const date = format(new Date(), 'yyyy-MM-dd');
    const response = yield downloadFileBlob(path, `Usuarios_${date}`);

    if (!response.error) {
      yield put(apiSuccess(USER.DOWNLOAD_EXCEL_SUCCESS));
      yield put(apiSuccess(APP.SET_SUCCESS, { msg: 'Descarga exitosa' }));
    } else {
      yield put(apiSuccess(USER.DOWNLOAD_EXCEL_ERROR));
      yield put(apiSuccess(APP.SET_ERROR, { msg: 'Error en la descarga' }));
    }
  });
}

function* getHoldings() {
  yield takeLatest(USER.GET_USER_HOLDING, function* (action) {
    const userId = action.payload;
    const path = `api/v1/holdings/social-reason?userId=${userId}`;
    const response = yield get(path);
    if (!response.error) {
      yield put(apiSuccess(USER.GET_USER_HOLDING_SUCCESS, response));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: 'Error interno, inténtelo más tarde.' }));
    }
  });
}

export default function* root() {
  yield spawn(getInitialData);
  yield spawn(fetchWithFilter);
  yield spawn(clearControls);
  yield spawn(changePage);
  yield spawn(changeStatus);
  yield spawn(getHoldings);
  yield spawn(downloadExcel);
}
