const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paperAnchorLeft: {
    width: 300,
  },
  itemIcon: {
    color: theme.palette.primary.main,
  },
  drawTitle: {
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  primaryItem: {
    color: theme.palette.primary.main,
    fontWeight: 200,
  },
  mobileItems: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
});

export default styles;
