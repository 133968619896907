import { Box } from '@material-ui/core';
import React from 'react';
import SalfaImage from '../commons/assets/images/salfa-icon.png';

const SalfaIcon = ({ width }) => (
  <Box>
    <img src={SalfaImage} alt="salfa" style={{ width }} />
  </Box>
);

export default SalfaIcon;
