import { put, spawn, takeLatest } from 'redux-saga/effects';

import { apiSuccess, get, post } from 'utils/api';
import * as APP from 'commons/reducer';
import { NAVBAR } from 'commons/components/NavBar/reducer';

function* getHoldings() {
  yield takeLatest(NAVBAR.GET_HOLDINGS, function* () {
    const response = yield get('api/v1/holdings/');

    if (!response.error) {
      yield put(apiSuccess(NAVBAR.GET_HOLDINGS_SUCCESS));
      yield put(apiSuccess(APP.GET_STATE_REPLACE_FROM_API_SUCCESS, response));
    } else {
      yield put(apiSuccess(NAVBAR.GET_HOLDINGS_ERROR));
      yield put(apiSuccess(APP.SET_ERROR, { msg: 'Error al obtener los datos' }));
    }
  });
}

function* getSocialReason() {
  yield takeLatest(NAVBAR.GET_SOCIAL_REASON, function* (action) {
    const id = action.payload;
    const response = yield get(`api/v1/social-reasons?holdingId=${id}`);
    if (!response.error) {
      yield put(apiSuccess(NAVBAR.GET_SOCIAL_REASON_SUCCESS));
      yield put(apiSuccess(APP.GET_STATE_REPLACE_FROM_API_SUCCESS, response));
    } else {
      yield put(apiSuccess(NAVBAR.GET_SOCIAL_REASON_ERROR));
      yield put(apiSuccess(APP.SET_ERROR, { msg: 'Error al obtener los datos' }));
    }
  });
}

function* clearNotifications() {
  yield takeLatest(NAVBAR.CLEAN_NOTIFICATIONS, function* () {
    const response = yield post('api/v1/notifications/clear');
    if (!response.error) {
      yield put(apiSuccess(NAVBAR.CLEAN_NOTIFICATIONS_SUCCESS));
    } else {
      yield put(apiSuccess(NAVBAR.CLEAN_NOTIFICATIONS_ERROR));
      yield put(apiSuccess(APP.SET_ERROR, { msg: 'Error al limpiar notificaciones, inténtalo más tarde' }));
    }
  });
}

export default function* root() {
  yield spawn(getHoldings);
  yield spawn(getSocialReason);
  yield spawn(clearNotifications);
}
