const styles = (theme) => ({
  screenContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  gridContainer: {
    height: '100%',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(8),
  },
  grid: {
    height: '100%',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'inline',
    },
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '& .MuiButton-label': {
      justifyContent: 'start',
    },
    gap: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(8),
      flexDirection: 'column',
      justifyContent: 'start',
      height: '80%',
    },
  },
  componentZone: {
    paddingTop: theme.spacing(2),
    height: '100%',
    [theme.breakpoints.up('md')]: {
      borderLeft: '1px outset',
      borderColor: '#A1A1A1',
      borderTop: '0px',
      paddingTop: theme.spacing(0),
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(0),
    },
  },
  button: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(1),
    boxShadow: theme.shadows[4],
    borderRadius: 8,
    [theme.breakpoints.up('sm')]: {
      width: '100%',
    },
    background: ({ selected }) => (selected ? theme.palette.primary.main : 'white'),
    cursor: 'pointer',
  },
  buttonText: {
    color: ({ selected }) => (!selected ? theme.palette.primary.main : 'white'),
  },
  detailsContainer: {
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing(8),
    },
  },
  itemHeader: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  detailContainer: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(8),
    },
  },
  iconDetail: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  titleDetail: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
  },
  infoDetail: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'start',
  },
  passwordContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxShadow: theme.shadows[3],
    padding: theme.spacing(5),
    height: '80%',
    [theme.breakpoints.up('md')]: {
      alignItems: 'start',
      flexDirection: 'row',
    },
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
  },
  passwordInputTitle: {
    fontWeight: 400,
    maxWidth: 300,
    minWidth: 230,
  },
  passwordInput: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    minWidth: 230,
    '& .MuiInputBase-root': {
      color: theme.palette.primary.main,
    },
    '& .MuiFormLabel-root': {
      color: theme.palette.primary.main,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.primary.main,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.primary.main,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
      },
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 300,
    },
  },
  inputProps: {
    color: theme.palette.primary.main,
  },
  passwordButtonContainer: {
    display: 'flex',
    flexDirection: 'column-reverse',
    justifyContent: 'end',
    alignItems: 'end',
    height: '100%',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      maxWidth: 327.88,
    },

  },
  passwordButton: {
    maxWidth: 200,
  },
});

export default styles;
