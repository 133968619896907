const styles = (theme) => ({
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  clearButton: {
    marginLeft: theme.spacing(2),
    border: '3px solid',
  },

  tabs: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '& .MuiTabs-indicator': {
      backgroundColor: theme.palette.secondary.main,
    },
    marginBottom: 8,
  },

  autoComplete: {
    '& .MuiOutlinedInput-input': {
      color: theme.palette.primary.main,
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.primary.main,
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
    '&:hover .MuiOutlinedInput-input': {
      color: theme.palette.primary.main,
    },
    '&:hover .MuiInputLabel-root': {
      color: theme.palette.primary.main,
    },
    '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
      color: theme.palette.primary.main,
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: theme.palette.primary.main,
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
  },

  button: {
    float: 'right',
    marginBottom: 16,
  },

  fileButton: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },

  fileName: {
    '& .Mui-disabled': {
      color: theme.palette.primary.main,
    },
  },
  fileSize: {
    fontSize: 12,
  },
});

export default styles;
