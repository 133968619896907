const styles = (theme) => ({
  autoComplete: {
    '& .MuiFormLabel-root': {
      color: theme.palette.primary.main,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.primary.main,
      },
    },
  },
  link: {
    cursor: 'pointer',
  },
  button: {
    marginTop: '16px',
  },
});

export default styles;
