import { alpha } from '@material-ui/core/styles/colorManipulator';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(4),
    minWidth: 536,
    [theme.breakpoints.down('sm')]: {
      minWidth: 0,
    },
  },
  clearButton: {
    height: '100%',
    marginLeft: theme.spacing(2),
    border: '3px solid',
  },
  holdingCard: {
    marginTop: theme.spacing(1),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    boxShadow: theme.shadows[4],
  },
  back1: {
    backgroundColor: alpha(theme.palette.primary.main, 0.03),
  },
  back2: {
    backgroundColor: alpha(theme.palette.primary.main, 0.08),
  },
  chips: {
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  holdingName: {
    overflowX: 'auto',
    overflowY: 'hidden',
  },
});

export default styles;
