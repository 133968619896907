const styles = () => ({
  cardGrid: {
    minWidth: '300px',
  },
  cardTitle: {
    fontWeight: 600,
  },
});

export default styles;
