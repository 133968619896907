import React from 'react';
import {
  Box, Dialog, IconButton, makeStyles, Typography,
} from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';
import styles from './styles';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);
const useStyles = makeStyles(styles);

const CustomDialogFullScreen = ({
  isOpen, clear, children, title,
}) => {
  const classes = useStyles();
  return (
    <Dialog
      fullScreen
      open={isOpen}
      TransitionComponent={Transition}
    >
      <Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          className={classes.mobileHeader}
        >
          <Typography variant="h4" color="primary">
            {title}
          </Typography>
          <IconButton
            className={classes.iconButton}
            color="primary"
            onClick={clear}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          className={classes.content}
        >
          {children}
        </Box>
      </Box>
    </Dialog>
  );
};

export default CustomDialogFullScreen;
