import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
// Icon
import CloseIcon from '@material-ui/icons/Close';

import { Box } from '@material-ui/core';
import styles from './styles';

const useStyles = makeStyles(styles);

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function CustomDialog({
  title = 'Title', onCloseDialog, contentBody, openRef,
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
    if (typeof handleClose === 'function') onCloseDialog();
  };

  useEffect(() => {
    setOpen(openRef);
  }, [openRef]);

  return (
    <div>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <Box className={classes.dialogHeader}>
          <Typography color="primary" variant="h6" className={classes.title}>
            <Box fontSize={28}>
              {title}
            </Box>
          </Typography>
          <IconButton color="primary" onClick={handleClose} aria-label="close">
            <CloseIcon fontSize="large" />
          </IconButton>
        </Box>
        {contentBody || null}
      </Dialog>
    </div>
  );
}
