import { Box, Grid } from '@material-ui/core';
import React from 'react';

import Typography from '@material-ui/core/Typography';

// Icons
import LocationCityIcon from '@material-ui/icons/LocationCity';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import PhoneEnabledOutlinedIcon from '@material-ui/icons/PhoneEnabledOutlined';

import { phoneFormat } from 'utils/functions';

const items = {
  holding: {
    icon: <LocationCityIcon />,
    title: 'Holding',
    details: ['Importadora cafe do brasil S.A.', 'Lari obras y servicios SPA', 'Zener Austral Limitada'],
  },
  type: {
    icon: <FingerprintIcon />,
    title: 'Rol',
  },
  firstName: {
    icon: <PersonOutlineOutlinedIcon />,
    title: 'Nombre',
  },
  lastName: {
    icon: <PersonOutlineOutlinedIcon />,
    title: 'Apellido',
  },
  email: {
    icon: <EmailOutlinedIcon />,
    title: 'Email',
  },
  phone: {
    icon: <PhoneEnabledOutlinedIcon />,
    title: 'Teléfono',
  },
};

const holdingList = (data, type) => (
  type !== 'ADMINISTRADOR_PORTAL_SALFA'
    ? (
      <ul>
        {data.map((d) => (
          <li style={{ marginLeft: '-24px' }}>
            {d.name}
          </li>
        ))}
      </ul>
    )
    : 'Cuenta con acceso a todos los holdings'
);

const chooseOutput = (key, user, holdings) => {
  if (key === 'type') return user[key].replace(/_/g, ' ');
  if (key === 'holding') return holdingList(holdings, user.type);
  if (key === 'phone') return phoneFormat(user[key] || '');
  if (key === 'type') return user[key].replace(/_/g, ' ');
  return user[key];
};

const Details = ({
  classes, user, holdings,
}) => (
  <>
    <Box boxShadow={3}>
      <Grid container className={classes.detailsContainer}>
        {Object.entries(items)
          .map(([key, value]) => (
            <Grid
              item
              key={key}
              xs={12}
              sm={6}
              className={classes.detailContainer}
            >
              <Grid container>
                <Grid item xs={3} md={5} className={classes.iconDetail}>
                  {React.cloneElement(value.icon, { color: 'primary' })}
                </Grid>
                <Grid item xs={9} md={7} className={classes.titleItem}>
                  <Typography component="div" color="primary" variant="h6">
                    <Box fontWeight="fontWeightBold" fontSize={20}>
                      {value.title}
                    </Box>
                  </Typography>
                </Grid>
                <Grid item xs={3} md={5} />
                <Grid item xs={9} md={7} className={classes.infoDetail}>
                  <Typography component="div" color="primary" variant="body1">
                    <Box fontSize={14}>
                      {chooseOutput(key, user, holdings)}
                    </Box>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}
      </Grid>
    </Box>
  </>
);

export default Details;
