import {
  Box, Button, Dialog, IconButton, makeStyles, Typography,
} from '@material-ui/core';

// Material
import Slide from '@material-ui/core/Slide';
import SvgIcon from '@material-ui/core/SvgIcon';
import TextField from '@material-ui/core/TextField';

// Icons
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';

// Project
import CustomSelect from 'commons/components/CustomSelect';
import DateInput from 'commons/components/DateInput';
import Autocomplete from '@material-ui/lab/Autocomplete';

import UserSearch from 'screens/User/components/UserSearch';
import styles from './styles';

const useStyles = makeStyles(styles);

const ClearIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M16,11h-1V3c0-1.1-0.9-2-2-2h-2C9.9,1,9,1.9,9,3v8H8c-2.76,0-5,2.24-5,5v7h18v-7C21,13.24,18.76,11,16,11z M19,21h-2v-3 c0-0.55-0.45-1-1-1s-1,0.45-1,1v3h-2v-3c0-0.55-0.45-1-1-1s-1,0.45-1,1v3H9v-3c0-0.55-0.45-1-1-1s-1,0.45-1,1v3H5v-5 c0-1.65,1.35-3,3-3h8c1.65,0,3,1.35,3,3V21z" />
  </SvgIcon>
);

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const MobileFilter = ({
  controls, actions, holdings, roles, statusType, isLoading, show, setShow,
}) => {
  const classes = useStyles();

  const { changeControls, fetchWithFilter } = actions;
  const {
    holding, startDate, disabledDate, type, status, userName,
  } = controls;

  return (
    <Dialog fullScreen open={show} keepMounted TransitionComponent={Transition}>
      <Box className={classes.dialogContainer}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          className={classes.mobileHeader}
        >
          <Typography variant="h4" color="primary">
            Filtros
          </Typography>
          <IconButton className={classes.iconButton} color="primary" onClick={() => setShow(false)} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          className={classes.inputs}
        >
          <Autocomplete
            value={holdings.find((h) => h.id === holding) ?? null}
            className={classes.autoComplete}
            id="combo-box-demo"
            onChange={(e, value) => {
              if (!value) changeControls('holding', null);
              else changeControls('holding', value.id);
            }}
            options={holdings}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => <TextField {...params} label="Holding" variant="outlined" />}
          />
          <DateInput
            value={startDate}
            className={classes.autoComplete}
            handleInput={(value) => {
              if (!value) changeControls('startDate', null);
              else changeControls('startDate', value);
            }}
            fullWidth
            label="Creación"
          />
          <DateInput
            value={disabledDate}
            className={classes.autoComplete}
            handleInput={(value) => {
              if (!value) changeControls('disabledDate', null);
              else changeControls('disabledDate', value);
            }}
            fullWidth
            label="Deshabilitado"
          />
          <CustomSelect
            label="Estado"
            id="status"
            name="status"
            variant="outlined"
            onChange={(target) => {
              changeControls('status', target.target.value);
            }}
            value={status}
            options={statusType}
            size="medium"
          />
          <CustomSelect
            label="Rol"
            id="rol"
            name="rol"
            variant="outlined"
            onChange={(target) => {
              changeControls('type', target.target.value);
            }}
            value={type}
            options={roles}
            size="medium"
          />
          <UserSearch
            isOpen
            value={userName}
            onChange={changeControls}
            onKeyPress={fetchWithFilter}
          />
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => {
              if (!isLoading) {
                fetchWithFilter();
                setShow(false);
              }
            }}
            className={classes.filterInputAutocomplete}
          >
            <Typography variant="h6">
              Aplicar Filtros
            </Typography>
          </Button>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<ClearIcon />}
            size="large"
            onClick={() => {
              if (!isLoading) {
                actions.clearControls();
                setShow(false);
              }
            }}
          >
            <Typography variant="h6">
              Limpiar Filtros
            </Typography>
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default MobileFilter;
