import {
  Box, Card, Grid, IconButton, useMediaQuery, useTheme,
} from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';

// Material
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import Pagination from '@material-ui/lab/Pagination';

// icons
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PersonIcon from '@material-ui/icons/Person';
import BookmarksOutlinedIcon from '@material-ui/icons/BookmarksOutlined';
import BuildOutlinedIcon from '@material-ui/icons/BuildOutlined';

// Project
import {
  numberFormatter,
  formatDateForCards,
  isDate,
  compareDate,
  randomString,
} from 'utils/functions';
import GeneralCard from 'commons/components/GeneralCard';
import GeneralCardItem from 'commons/components/GeneralCardItem';
import CustomTooltip from 'commons/components/CustomTooltip';
import moment from 'moment';
import CreateIcon from '@material-ui/icons/Create';
import styles from './styles';
import HeaderList from '../HeaderList';
import LateMaintenanceText from '../descriptions';
import LicensePlateColumn from '../LicensePlateColumn';

const useStyles = makeStyles(styles);

const kmIsPassed = (km1, km2) => {
  if (!km1 && !km2) return false;

  return km2 > km1;
};

const CardItem = ({
  title, content, xs, md, icon, extraContent, isMobile, align = 'left',
}) => {
  const classes = useStyles();
  return (
    <Grid item xs={xs} md={md} style={{ textAlign: isMobile ? 'left' : align }}>
      { isMobile
        && (
        <Box className={classes.titleIcon}>
          {icon}
          <Typography className={classes.cardTitle}>
            {title}
          </Typography>
        </Box>
        )}
      <Typography>
        {content}
      </Typography>
      <Typography>
        {extraContent}
      </Typography>
    </Grid>
  );
};
const DriverData = ({
  text, vehicle, handleOpenModal, vehicleSelected,
}) => {
  const classes = useStyles();
  return (
    <>
      <Typography inline="true">
        {`${text} `}
      </Typography>
      <IconButton
        size="small"
        onClick={(e) => {
          // eslint-disable-next-line no-param-reassign
          vehicleSelected.current = vehicle;
          return handleOpenModal(e, vehicle);
        }}
        className={classes.boxChangeDriver}
      >
        <CreateIcon className={classes.buttonChangeDriver} />
      </IconButton>
    </>
  );
};

const ClientBelowGeneralCard = ({ client }) => {
  const classes = useStyles();
  return (
    <Box marginLeft={2} display="flex">
      <PersonIcon className={classes.personIcon} color="primary" />
      <Typography component="div" variant="body2">
        <Box component="span" fontSize={10} marginLeft={0.5}>
          {client}
        </Box>
      </Typography>
    </Box>
  );
};

const VehicleCard = ({
  vehicle,
  index,
  handleOpenModal,
  vehicleSelected,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const client = vehicle?.contractVehicleSocialReasonName;
  const { deliveryDate, vehicleTariffDuration: duration } = vehicle;
  const expirationDate = deliveryDate && duration ? moment(deliveryDate).add(duration, 'months').format('YYYY-MM-DD') : null;

  const dataCard = [
    {
      key: 1,
      icon: <LocalShippingOutlinedIcon />,
      // title: 'Vehículo',
      firstRow: `${vehicle?.brand || 'N/A'} - ${vehicle?.model}`,
      secondRow: `Año ${vehicle?.year} - ${vehicle?.color}`,
      width: 370,
    },
    {
      key: 2,
      icon: <LocationOnIcon />,
      // title: 'Asignación',
      firstRow: vehicle?.fleetName || 'Sin flota',
      secondRow: vehicle?.zone || 'No informado',
      width: 200,
    },
    {
      key: 3,
      icon: <PersonIcon />,
      // title: 'Encargado',
      firstRow: vehicle?.assignedToName || 'No informado',
      secondRow: vehicle?.assignedToRun ? DriverData({
        text: vehicle?.assignedToRun, vehicle, handleOpenModal, vehicleSelected,
      }) : DriverData({
        text: '-',
        vehicle,
        handleOpenModal,
        vehicleSelected,
      }),
      width: 200,
    },
    {
      key: 4,
      icon: <BookmarksOutlinedIcon />,
      // title: 'Vigencia',
      firstRow: isDate(vehicle?.deliveryDate) ? `${formatDateForCards(vehicle?.deliveryDate)} Entrega` : 'No informado',
      secondRow: isDate(expirationDate) ? `${formatDateForCards(expirationDate)} Vencimiento` : 'No informado',
      colors: {
        secondRow: expirationDate && compareDate(expirationDate, new Date())
          ? theme.palette.secondary.main : theme.palette.primary.main,
      },
      width: 215,
    },
    {
      key: 5,
      icon: <BuildOutlinedIcon
        style={{
          color: kmIsPassed(vehicle?.nextMaintenance, vehicle?.km)
            ? theme.palette.secondary.main : theme.palette.primary.main,
        }}
      />,
      // title: 'Mantención',
      firstRow: `${numberFormatter(vehicle?.nextMaintenance)} Km` || 'No informado',
      secondRow: `${numberFormatter(vehicle?.km)} Km` || 'No informado',
      width: 140,
      colors: {
        firstRow: kmIsPassed(vehicle?.nextMaintenance, vehicle?.km)
          ? theme.palette.secondary.main : theme.palette.primary.main,
        secondRow: kmIsPassed(vehicle?.nextMaintenance, vehicle?.km)
          ? theme.palette.secondary.main : theme.palette.primary.main,
      },
      fontWeights: {
        firstRow: 'bold',
      },
      tooltip: <LateMaintenanceText />,
    },
  ];

  return (
    <>

      { !isMobile ? (
        <GeneralCard
          className={classNames(classes.root, index % 2 === 0 ? classes.back1 : classes.back2)}
          onClick={() => { history.push(`/vehicles/${vehicle.licensePlate}`); }}
          renderBelow={
            <ClientBelowGeneralCard client={client} />
          }
        >
          <LicensePlateColumn
            licensePlate={vehicle?.licensePlate}
            status={vehicle?.status}
            width={120}
          />
          {
            dataCard.map((d) => {
              // TODO: Refactor
              if (d.tooltip) {
                return (
                  <CustomTooltip key={d.key} content={d.tooltip}>
                    <GeneralCardItem
                      key={d.key}
                      icon={d.icon}
                      title={d.title}
                      firstRow={d.firstRow}
                      secondRow={d.secondRow}
                      width={d.width}
                      colors={d.colors}
                      fontWeights={d.fontWeights}
                    />
                  </CustomTooltip>
                );
              }

              return (
                <GeneralCardItem
                  key={d.key}
                  icon={d.icon}
                  title={d.title}
                  firstRow={d.firstRow}
                  secondRow={d.secondRow}
                  width={d.width}
                  colors={d.colors}
                  fontWeights={d.fontWeights}
                />
              );
            })
          }

        </GeneralCard>
      ) : (
        // TODO: crear componente
        <Grid
          container
          className={classNames(classes.root, index % 2 === 0 ? classes.back1 : classes.back2)}
          onClick={() => { history.push(`/vehicles/${vehicle?.licensePlate}`); }}
        >
          <Grid item xs={12} sm={6} className={classes.upperElement}>
            <Box display="flex" alignItems="center" justifyContent="start">
              <LocalShippingOutlinedIcon className={classes.mobileIcon} />
              <Typography component="div" variant="body1" color="primary">
                <Box component="span" fontWeight={600}>
                  {vehicle?.licensePlate || 'No informada'}
                </Box>
                <Box component="span" fontWeight="fontWeightMedium">
                  {` | ${vehicle?.fleetName || 'Sin flota'}`}
                </Box>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.upperElement}>
            <Box display="flex" alignItems="center" justifyContent="start">
              <LocationOnIcon
                className={classes.mobileIcon}
              />
              <Box component="span" fontWeight="fontWeightMedium">
                <Typography variant="body1" color="primary">
                  {vehicle?.zone || 'No informado'}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={5} sm={6}>
            <Box display="flex" alignItems="start">
              <FiberManualRecordIcon
                className={classNames(classes.statusIcon, classes[`${vehicle?.status}`])}
              />
              <Typography component="div" variant="body2" color="primary">
                <Box component="span" fontWeight={600}>
                  Estado
                </Box>
                <Box>
                  { vehicle?.status ? vehicle?.status[0] + vehicle?.status.slice(1).toLowerCase() : 'No informado' }
                </Box>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={7} sm={6}>
            <Box display="flex" alignItems="center">
              <BookmarksOutlinedIcon className={classes.mobileIcon} />
              <Typography component="div" variant="body2" color="primary" noWrap>
                <Box>
                  {vehicle?.deliveryDate ? `${formatDateForCards(vehicle?.deliveryDate)} Entrega` : 'No informado'}
                </Box>
                <Box>
                  {expirationDate ? `${formatDateForCards(expirationDate)} Vencimiento` : 'No informado'}
                </Box>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      ) }
    </>
  );
};

const VehicleList = (props) => {
  const {
    vehicles,
    pagination,
    actions,
    controls,
    isLoading,
    total,
    handleDownload,
    handleDownloadDuplicated,
    loading,
    role,
    vehicleSelected,
  } = props;

  const { totalPages, page } = pagination;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();

  const handleOpenModal = (e, vehicle) => {
    e.stopPropagation();
    actions.selectDriver({
      vehicle: vehicle?.id,
    });
    actions.openDriverModal();
  };

  return (
    <>
      <Box
        display="grid"
        gridTemplateRows="auto"
        overflow="auto"
        padding={1}
        paddingTop={0}
      >
        <HeaderList
          orderByValue={controls.orderBy}
          setOrderBy={actions.setOrderBy}
          changeOrderby={actions.changeOrderBy}
          isLoading={isLoading}
          total={total}
          handleDownload={handleDownload}
          handleDownloadDuplicated={handleDownloadDuplicated}
          loading={loading}
          role={role}
        />
        {(!vehicles || vehicles.length === 0) && !loading && (
          <Card className={classes.noData}>
            <Grid container justifyContent="center">
              <CardItem content="No hay registros disponibles" md={12} xs={12} isMobile={isMobile} align="center" />
            </Grid>
          </Card>
        )}
        {vehicles && vehicles.length > 0 && vehicles.map((vehicle, index) => (
          <VehicleCard
            vehicle={vehicle}
            key={vehicle?.id || randomString()}
            index={index}
            handleOpenModal={handleOpenModal}
            vehicleSelected={vehicleSelected}
          />
        ))}
      </Box>

      {/* Paginación */}
      {!!totalPages
         && (
         <Box display="flex" justifyContent="center" alignItems="end" className={classes.paginationContainer}>
           <Pagination
             onChange={(e, p) => !isLoading && actions.changePage(p)}
             hidePrevButton={!page}
             hideNextButton={page >= totalPages}
             count={totalPages}
             page={page}
             color="primary"
             size={isMobile ? 'small' : 'large'}
           />
         </Box>
         )}

    </>
  );
};

export default VehicleList;
