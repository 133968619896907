const styles = (theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(0),
      width: '71.42%',
      float: 'right',
    },
  },
  container: {
    padding: theme.spacing(2),
  },
  box: {
    marginLeft: 16,
  },
});

export default styles;
