import React, { useEffect, useMemo, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import Typography from '@material-ui/core/Typography';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const DashBoardGraph = ({ operationsZone }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [zones, setZones] = useState([]);
  const [dataAvailable, setDataAvailable] = useState([]);
  const [dataNotAvailable, setDataNotAvailable] = useState([]);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const newZones = [];
    const newDataAvailable = [];
    const newNotAvailable = [];
    Object.keys(operationsZone).forEach((key) => {
      newZones.push(`${key.slice(4)}`);
      newDataAvailable.push(operationsZone[key].available);
      newNotAvailable.push(operationsZone[key].notAvailable);
    });
    setZones(newZones);
    setDataAvailable(newDataAvailable);
    setDataNotAvailable(newNotAvailable);
  }, [operationsZone]);

  const data = useMemo(() => ({
    labels: zones,
    datasets: [
      {
        stack: 'stack1',
        label: 'Operativos',
        data: dataAvailable,
        backgroundColor: theme.palette.primary.main,
        arPercentage: 0.5,
        categoryPercentage: 0.5,
      },
      {
        stack: 'stack1',
        label: 'No operativos',
        data: dataNotAvailable,
        backgroundColor: theme.palette.error.main,
        arPercentage: 0.5,
        categoryPercentage: 0.5,
      },
    ],
  }), [zones, dataAvailable, dataNotAvailable]);

  const options = useMemo(() => ({
    indexAxis: isMobile ? 'y' : 'x',
    responsive: true,
    scales: {
      x: {
        ticks: {
          display: true,
        },
        grid: {
          borderWidth: 2,
          borderColor: theme.palette.primary.main,
          display: false,
        },
      },
      y:
         {
           ticks: {
             display: true,
           },
           grid: {
             borderWidth: 2,
             borderColor: theme.palette.primary.main,
             display: false,
           },

         },
    },
  }), [operationsZone, isMobile]);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box className={classes.operationOutsideContainer}>
        <Typography color="primary" variant="h6">
          Zona de operación
        </Typography>
        <Box
          className={classes.operationInsideContainer}
        >
          <Bar data={data} options={options} />
        </Box>
      </Box>
    </Box>
  );
};

export default DashBoardGraph;
