import React from 'react';
import {
  Grid, IconButton, Modal, Paper, Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { capitalize } from 'utils/utils';

const PreviewModal = ({
  isOpen, name, licencePlate, handleClose, url,
}) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    p: 4,
    width: '90%',
    height: '90vh',
    overflow: 'hidden',
  };

  const iframeStyle = {
    width: '100%',
    height: '90vh',
    border: 'none',
  };

  return (
    <div>
      <Modal open={isOpen} onClose={handleClose}>
        <Grid container justifyContent="center" alignItems="center">
          <Paper style={style}>
            <Grid item container md={12} alignItems="center" justifyContent="space-between">
              <Grid item style={{ paddingLeft: '16px' }}>
                <Typography
                  variant="h4"
                  fontWeight="bold"
                >
                  {`${capitalize(name)} (${licencePlate})`}
                </Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item>
              <iframe
                title="name"
                src={url}
                style={iframeStyle}
              />
            </Grid>
          </Paper>
        </Grid>
      </Modal>
    </div>
  );
};

export default PreviewModal;
