import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';

import GeneralCard from 'commons/components/GeneralCard';
import GeneralMobileCard from 'commons/components/GeneralMobileCard';
import classNames from 'classnames';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import { DASHBOARD_COLS } from '.';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    boxShadow: theme.shadows[4],
    '&:hover': {
      cursor: 'pointer',
    },
  },
  back1: {
    backgroundColor: alpha(theme.palette.primary.main, 0.03),
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.12),
    },
  },
  back2: {
    backgroundColor: alpha(theme.palette.primary.main, 0.08),
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.12),
    },
  },
  paginationContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  text: {
    overflowX: 'auto',
  },
  attributeTitle: {
    marginRight: theme.spacing(1),
  },
}));

/**
 * Returns an array of data cards for a given holding.
 * @param {object} holding - The holding object.
 * @param {string} holding.name - The name of the holding.
 * @param {number} holding.totalVehicles - The total number of vehicles in the holding.
 * @param {string} holding.disponibility - The availability of the holding.
 * @param {string} holding.mechanic - The mechanic associated with the holding.
 * @param {string} holding.replacement - The replacement information for the holding.
 * @param {number} holding.siniestros - The number of accidents or incidents for the holding.
 * @param {number} holding.patents - The number of patents associated with the holding.
 *
 * @returns {{ key: number, title: string, row: string, width: number }[]}
 */
const getDataCard = (holding) => [
  {
    key: 0,
    title: DASHBOARD_COLS.RUT,
    row: holding.rut,
    width: 120,
  },
  {
    key: 1,
    title: DASHBOARD_COLS.HOLDING,
    row: holding.name,
    width: 240,
  },
  {
    key: 2,
    title: DASHBOARD_COLS.TOTAL_VEHICLES,
    row: holding.totalVehicles,
    width: 125,
  },
  {
    key: 3,
    title: DASHBOARD_COLS.DISPONIBILITY,
    row: holding.disponibility,
    width: 160,
  },
  {
    key: 4,
    title: DASHBOARD_COLS.MECHANIC,
    row: holding.workshop,
    width: 100,
  },
  {
    key: 5,
    title: DASHBOARD_COLS.REPLACEMENTS,
    row: holding.replacement,
    width: 140,
  },
  {
    key: 6,
    title: DASHBOARD_COLS.SINIESTROS,
    row: holding.sinister,
    width: 115,
  },
  {
    key: 7,
    title: DASHBOARD_COLS.PATENTS,
    row: holding.patents,
    width: 160,
  },
];

const HoldingCardItem = ({
  isMobile,
  icon,
  title,
  row,
  width,
  colors,
  fontWeights,
  centerText = true,
}) => {
  const classes = useStyles();

  return (
    <Box display={!isMobile ? 'inline-block' : 'block'}>
      <Box display="flex" width={!isMobile ? width : '100%'} my={1.3}>
        {icon ? <Box className={classes.itemIcon}>{icon}</Box> : null}
        <Box width={!isMobile ? width : '100%'} overflow="auto">
          <Typography align="center" component="div" color="primary" noWrap={!isMobile}>
            <Box
              component="span"
              display="flex"
              justifyContent={centerText && !isMobile ? 'center' : 'start'}
              alignItems="center"
              color={colors?.row}
              fontSize={15}
              fontWeight={fontWeights?.row}
              className={classes.text}
            >
              <Typography className={classes.attributeTitle} component="span" variant="h6">
                {isMobile && `${title}: `}
              </Typography>
              {`${row}`}
            </Box>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const HoldingCard = ({
  holding,
  index,
  isMobile,
  onClick,
}) => {
  const dataCard = getDataCard(holding);
  const classes = useStyles();

  const handleClick = () => {
    onClick(holding);
  };

  const holdingListItems = dataCard.map((d) => (
    <HoldingCardItem
      key={d.key}
      isMobile={isMobile}
      row={d.row}
      title={d.title}
      width={d.width}
      centerText={typeof d.row === 'string' ? d.row.length < 25 : true}
      justifyContent="center"
      my={2}
    />
  ));

  return (
    <>
      {!isMobile ? (
        <GeneralCard
          className={classNames(classes.root, index % 2 === 0 ? classes.back1 : classes.back2)}
          onClick={handleClick}
        >
          {holdingListItems}
        </GeneralCard>
      ) : (
        <GeneralMobileCard
          className={classNames(classes.root, index % 2 === 0 ? classes.back1 : classes.back2)}
          flex={false}
          onClick={handleClick}
        >
          {holdingListItems}
        </GeneralMobileCard>
      )}
      <div />
    </>
  );
};

export default HoldingCard;
