import {
  Button,
  Dialog, DialogActions, DialogContent, DialogTitle, Typography,
} from '@material-ui/core';
import React from 'react';

function DeactivateDialog({ isOpen, onClose, action }) {
  const handleClick = () => {
    onClose();
    action();
  };

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth>
      <DialogTitle color="secondary">Alerta</DialogTitle>
      <DialogContent>
        <Typography>Este holding tiene una o más razones sociales activas.</Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={onClose}>
          Cancelar
        </Button>
        <Button variant="contained" color="primary" onClick={handleClick}>
          Continuar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeactivateDialog;
