import {
  Box, Grid, makeStyles, useMediaQuery, useTheme,
} from '@material-ui/core';
import React, { useCallback, useEffect, useMemo } from 'react';
// Material

// Project
import ScreenTitle from 'commons/components/ScreenTitle';
import { composedComponent, useQuery } from 'utils/functions';
import CustomDialog from 'commons/components/CustomDialog';
import RequestList from './component/RequestList';
import DriverRequest from './component/DriverRequest';

import saga from './saga';

import styles from './styles';
import { requestActions } from './reducer';

const useStyles = makeStyles(styles);

const Request = ({
  actions, requests, controls, holding, loading,
}) => {
  const { selected } = controls;
  const { getInitialData, selectRequest, downDriverRequest } = actions;

  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const query = useQuery();

  useEffect(() => {
    getInitialData();
  }, [holding]);

  useEffect(() => {
    const requestId = Number.parseInt(query.get('id'), 10);

    if (typeof requestId === 'number') {
      selectRequest(requestId);
    }
  }, [query]);

  const handleSelect = useCallback(
    (id) => { selectRequest(id); }, [],
  );

  const handleDriverRequest = useCallback(
    () => { downDriverRequest(); }, [],
  );

  const handleOnCloseDialog = () => {
    selectRequest(null);
  };

  const selectedRequest = useMemo(() => requests.find((r) => r.id === selected),
    [selected, requests]);

  return (
    <Box height="100%">
      <ScreenTitle title="Solicitudes" />
      <Grid container className={classes.container}>
        <Grid item xs={12} sm={5} md={4} lg={3} className={classes.listContainer}>
          <RequestList
            handleSelect={handleSelect}
            requests={requests}
            selected={selected}
            loading={loading}
          />
        </Grid>
        {!isMobile && selectedRequest ? (
          <Grid item sm={7} md={8} lg={9}>
            <DriverRequest
              request={selectedRequest}
              onDownRequest={handleDriverRequest}
            />
          </Grid>
        ) : null }
        {isMobile && selectedRequest ? (
          <CustomDialog
            onCloseDialog={handleOnCloseDialog}
            contentBody={(
              <DriverRequest
                request={selectedRequest}
                onDownRequest={handleDriverRequest}
              />
)}
            title="Nuevo conductor"
            openRef={selected || false}
          />
        ) : null }
      </Grid>
    </Box>
  );
};

export default composedComponent(Request, saga, {
  saga: 'sagaRequest',
  states: [
    'request.controls',
    'request.requests',
    'request.loading',
    'app.holding',
  ],
  actions: [requestActions],
});
