import React from 'react';
import {
  Chip,
  Grid, IconButton,
} from '@material-ui/core';

// Material
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

// icons
import ConfirmationDialog from 'commons/components/ConfirmationDialog';
import { currencyForm } from 'utils/utils';
import { Clear } from '@material-ui/icons';
import styles from './style';

const useStyles = makeStyles(styles);

const CardItem = ({
  title, content, size, actions, extra, roleAndStatusAllowedToDelete,
}) => {
  const classes = useStyles();
  return (
    <Grid item xs={size}>
      <Typography display="inline" className={classes.cardTitle}>
        {title}
      </Typography>
      <Typography display="inline">
        {content}
      </Typography>
      {roleAndStatusAllowedToDelete
      && (
        <IconButton
          color="primary"
          onClick={() => {
            actions.changeControls('confirmationDeleteExtrasDialog', true);
            actions.changeControls('extraId', extra.id);
          }}
        >
          <Clear />
        </IconButton>
      )}
    </Grid>
  );
};

const MobileCardItem = ({
  title, content, actions, extra, description, roleAndStatusAllowedToDelete,
}) => {
  const classes = useStyles();
  return (
    <>
      <Grid item xs={12} container justifyContent="space-between">
        <Typography display="inline" className={classes.cardTitle}>
          {title}
        </Typography>
        {roleAndStatusAllowedToDelete
        && (
          <IconButton
            size="small"
            color="primary"
            onClick={() => {
              actions.changeControls('confirmationDeleteExtrasDialog', true);
              actions.changeControls('extraId', extra.id);
            }}
          >
            <Clear fontSize="small" />
          </IconButton>
        )}
      </Grid>
      <Grid item xs={12}>
        <Typography display="inline">
          {content}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography display="inline">
          {description}
        </Typography>
      </Grid>
    </>
  );
};

const DetailsCard = ({
  charges,
  discounts,
  actions,
  controls,
  psId,
  isMobile,
  roleAndStatusAllowedToDelete,
}) => {
  const classes = useStyles();
  const handleDelete = () => {
    actions.deleteExtrasDocument(controls.extrasDocumentId, psId);
  };

  const handleClose = () => {
    actions.changeControls('confirmationExtrasDialog', false);
  };

  const handleDeleteDelete = () => {
    actions.deleteExtra(controls.extraId, psId);
  };

  const handleCloseDelete = () => {
    actions.changeControls('confirmationDeleteExtrasDialog', false);
  };

  return (
    <>
      <ConfirmationDialog open={controls.confirmationExtrasDialog} onClose={handleClose} onConfirm={handleDelete} content="¿Desea eliminar el documento?" />
      <ConfirmationDialog open={controls.confirmationDeleteExtrasDialog} onClose={handleCloseDelete} onConfirm={handleDeleteDelete} content="¿Desea eliminar el extra?" />
      <Grid container className={classes.gridTitle}>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            color="primary"
            className={classes.listTitle}
          >
            DETALLE
          </Typography>
        </Grid>
        {charges?.map((charge) => (
          <Grid key={charge.id} item container className={classes.gridMobileCard}>
            {
                  isMobile
                    ? (
                      <MobileCardItem
                        key={charge.id}
                        title={`${charge.licensePlate}: `}
                        content={`Cargo adicional: ${currencyForm.format(charge.value)}`}
                        description={`Descripción: ${charge.description}`}
                        actions={actions}
                        extra={charge}
                        roleAndStatusAllowedToDelete={roleAndStatusAllowedToDelete}
                      />
                    )
                    : (
                      <CardItem
                        key={charge.id}
                        size={12}
                        title={`${charge.licensePlate}: `}
                        content={`Cargo adicional: ${currencyForm.format(charge.value)}, ${charge.description}`}
                        actions={actions}
                        extra={charge}
                        roleAndStatusAllowedToDelete={roleAndStatusAllowedToDelete}
                      />
                    )
                }
            <Grid item xs={12}>
              <Grid container spacing={1}>
                {charge.documents?.map((document) => (
                  <Grid key={document.id} item>
                    { roleAndStatusAllowedToDelete
                      ? (
                        <Chip
                          onClick={() => window.open(document.path, '_blank', 'noopener,noreferrer')}
                          key={document.id}
                          variant="outlined"
                          label={document.name?.slice(37, document.name?.length) || 'Sin nombre'}
                          size="small"
                          onDelete={() => {
                            actions.changeControls('confirmationExtrasDialog', true);
                            actions.changeControls('extrasDocumentId', document.id);
                          }}
                          color="primary"
                        />
                      )
                      : (
                        <Chip
                          onClick={() => window.open(document.path, '_blank', 'noopener,noreferrer')}
                          key={document.id}
                          variant="outlined"
                          label={document.name?.slice(37, document.name?.length) || 'Sin nombre'}
                          size="small"
                          color="primary"
                        />
                      )}
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        ))}
        {discounts?.map((discount) => (
          <Grid key={discount.id} item container className={classes.gridMobileCard}>
            <Grid item xs={12}>
              {
                isMobile
                  ? (
                    <MobileCardItem
                      key={discount.id}
                      title={`${discount.licensePlate}: `}
                      content={`Descuento: ${currencyForm.format(discount.value)}`}
                      description={`Descripción: ${discount.description}`}
                      actions={actions}
                      extra={discount}
                      roleAndStatusAllowedToDelete={roleAndStatusAllowedToDelete}
                    />
                  )
                  : (
                    <CardItem
                      key={discount.id}
                      size={12}
                      title={`${discount.licensePlate}: `}
                      content={`Descuento: ${currencyForm.format(discount.value)}, ${discount.description}`}
                      actions={actions}
                      extra={discount}
                      roleAndStatusAllowedToDelete={roleAndStatusAllowedToDelete}
                    />
                  )
              }
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                {discount.documents?.map((document) => (
                  <Grid key={document.id} item>
                    { roleAndStatusAllowedToDelete
                      ? (
                        <Chip
                          onClick={() => window.open(document.path, '_blank', 'noopener,noreferrer')}
                          key={document.id}
                          variant="outlined"
                          label={document.name?.slice(37, document.name?.length) || 'Sin nombre'}
                          size="small"
                          onDelete={() => {
                            actions.changeControls('confirmationExtrasDialog', true);
                            actions.changeControls('extrasDocumentId', document.id);
                          }}
                          color="primary"
                        />
                      ) : (
                        <Chip
                          onClick={() => window.open(document.path, '_blank', 'noopener,noreferrer')}
                          key={document.id}
                          variant="outlined"
                          label={document.name?.slice(37, document.name?.length) || 'Sin nombre'}
                          size="small"
                          color="primary"
                        />
                      )}
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default DetailsCard;
