import React from 'react';

// Material ui
import Pagination from '@material-ui/lab/Pagination';
import { makeStyles } from '@material-ui/core/styles';

// Project

import styles from './styles';
import PaymentStatusCard from '../PaymentStatusCard';

const useStyles = makeStyles(styles);

const PaymentStatusList = ({
  loading, pagination, actions, paymentStatusDTOList, isMobile,
}) => {
  const classes = useStyles();
  const { totalPages, page } = pagination;
  return (
    <>
      <PaymentStatusCard paymentStatusDTOList={paymentStatusDTOList} isMobile={isMobile} />
      <Pagination
        className={classes.paginationContainer}
        onChange={(e, p) => !loading && actions.changePage(p)}
        hidePrevButton={false}
        hideNextButton={false}
        count={totalPages}
        page={page}
        color="primary"
        size="large"
      />
    </>
  );
};

export default PaymentStatusList;
