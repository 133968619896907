import React from 'react';
import {
  Accordion, AccordionDetails, AccordionSummary,
  Box,
  Card, Grid,
} from '@material-ui/core';

// Material
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

// icons
import { currencyForm } from 'utils/utils';
import { ExpandMore } from '@material-ui/icons';
import styles from './style';
import BlueCardItem from '../BlueCardItem';
import CardItem from '../CardItem';

const useStyles = makeStyles(styles);

const AdditionalsCard = ({ data, isMobile, total }) => {
  const classes = useStyles();

  const content = (
    <>
      {!isMobile
  && (
    <Card className={classes.back3}>
      <Grid container justifyContent="space-around" alignItems="center" className={classes.back3}>
        <BlueCardItem md={1} xs={6} title="Patente" />
        <BlueCardItem md={1} xs={6} />
        <BlueCardItem md={1} xs={6} />
        <BlueCardItem md={2} xs={12} title="Glosa" />
        <BlueCardItem md={1} xs={6} />
        <BlueCardItem md={1} xs={6} />
        <BlueCardItem md={1} xs={6} />
        <BlueCardItem md={1} xs={6} title="Total" right />
      </Grid>
    </Card>
  ) }
      {data?.length > 0 && (
      <Box className={classes.scrolling}>
        { data?.map((additional) => (
          <Card key={data.id} className={classes.back1}>
            <Grid container justifyContent="space-around">
              <CardItem title="Patente" content={additional.saleItem.licensePlate} md={1} xs={6} isMobile={isMobile} />
              <CardItem md={1} xs={6} isMobile={isMobile} />
              <CardItem md={1} xs={6} isMobile={isMobile} />
              <CardItem title="Glosa" content={additional.description} md={2} xs={12} isMobile={isMobile} />
              <CardItem md={1} xs={6} isMobile={isMobile} />
              <CardItem md={1} xs={6} isMobile={isMobile} />
              <CardItem md={1} xs={6} isMobile={isMobile} />
              <CardItem title="Total" content={currencyForm.format(additional.totalValue)} md={1} xs={6} isMobile={isMobile} right />
            </Grid>
          </Card>
        ))}
      </Box>
      )}

      {data?.length === 0 && (
      <Box className={classes.scrolling}>
        {isMobile
          ? (
            <Grid container justifyContent="center">
              <Typography>No hay registros disponibles</Typography>
            </Grid>
          ) : (
            <Card className={classes.back1}>
              <Grid container justifyContent="center">
                <CardItem content="No hay registros disponibles" md={12} xs={12} isMobile={isMobile} center />
              </Grid>
            </Card>
          )}
      </Box>
      )}
    </>
  );

  return (
    <>
      {isMobile ? (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              variant="h6"
              color="primary"
            >
              ADICIONALES
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {content}
          </AccordionDetails>
        </Accordion>
      )
        : (
          <>
            <Typography
              variant="h6"
              color="primary"
              className={classes.listTitle}
            >
              ADICIONALES
            </Typography>
            { content }
          </>
        )}

      <Card className={classes.back2}>
        <Grid container justifyContent="space-around" alignItems="center" className={classes.back2}>
          <BlueCardItem md={1} xs={6} />
          <BlueCardItem md={1} xs={6} />
          <BlueCardItem md={1} xs={6} />
          <BlueCardItem md={1} xs={6} />
          <BlueCardItem md={1} xs={6} />
          <BlueCardItem md={1} xs={6} />
          <BlueCardItem md={1} xs={6} />
          <BlueCardItem
            title="SUBTOTAL NETO MENSUAL"
            md={2}
            xs={8}
          />
          <BlueCardItem
            title={total}
            md={1}
            xs={4}
            right
          />
        </Grid>
      </Card>
    </>
  );
};

export default AdditionalsCard;
