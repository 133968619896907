import { put, spawn, takeLatest } from 'redux-saga/effects';
import { format } from 'date-fns';
import {
  post, apiSuccess, downloadFileBlob,
} from 'utils/api';
import * as APP from 'commons/reducer';
import { SOCIAL_REASON } from 'screens/HoldingMaintainerDetail/reducer';
import { HOLDING } from '../reducer';

function* addHolding() {
  yield takeLatest(HOLDING.ADD_HOLDING, function* ({ payload }) {
    yield put(APP.appActions.setLoader('create-holding', true));
    const { name, rut, history } = payload;
    const response = yield post('api/v1/holdings', { name, rut });
    if (!response.error) {
      yield put(apiSuccess(HOLDING.ADD_HOLDING_SUCCESS, response));
      yield put(apiSuccess(APP.ADD_HOLDING_SUCCESS, response));
      history?.push(`/holding-maintainer/${response.id}/social-reasons`);
    } else {
      yield put(apiSuccess(HOLDING.ADD_HOLDING_ERROR));
      yield put(apiSuccess(APP.SET_ERROR, { msg: 'Error al crear holding. El RUT ingresado ya existe en la base de datos.' }));
    }
    yield put(APP.appActions.setLoader('create-holding', false));
  });
}

function* addHoldingSR() {
  yield takeLatest(HOLDING.ADD_HOLDING_SR, function* ({ payload }) {
    yield put(APP.appActions.setLoader('create-holding', true));
    const { name, rut, history } = payload;
    const response = yield post('api/v1/holdings', { name, rut, withSocialReason: true });
    if (!response.error) {
      yield put(apiSuccess(HOLDING.ADD_HOLDING_SUCCESS, response.holding));
      yield put(apiSuccess(APP.ADD_HOLDING_SUCCESS, { ...response.holding, count: 1 }));
      yield put(apiSuccess(SOCIAL_REASON.ADD_SOCIAL_REASON_SUCCESS, response.socialReason));
      history?.push(`/holding-maintainer/${response.holding.id}/social-reasons`);
    } else {
      yield put(apiSuccess(HOLDING.ADD_HOLDING_ERROR));
      yield put(apiSuccess(APP.SET_ERROR, { msg: 'Error al crear holding. El RUT ingresado ya existe en la base de datos.' }));
    }
    yield put(APP.appActions.setLoader('create-holding', false));
  });
}

function* downloadExcel() {
  yield takeLatest(HOLDING.GENERATE_REPORT, function* () {
    const path = 'api/v1/holdings/generate-excel';
    const date = format(new Date(), 'yyyyMMddHHmmss');
    const response = yield downloadFileBlob(path, `${date}_holdings_razones_sociales`);

    if (!response.error) {
      yield put(apiSuccess(APP.SET_SUCCESS, { msg: 'Descarga exitosa' }));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { msg: 'Error en la descarga' }));
    }
  });
}

export default function* root() {
  yield spawn(addHolding);
  yield spawn(addHoldingSR);
  yield spawn(downloadExcel);
}
