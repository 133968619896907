import React from 'react';
import {
  Box, InputAdornment, TextField,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';

import styles from './styles';

const useStyles = makeStyles(styles);

function InputSearch({ handleChange }) {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <TextField
        className={classes.textField}
        id="social-reason-input"
        label="Razón social"
        variant="outlined"
        onChange={handleChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon color="primary" />
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
}

export default InputSearch;
