import React from 'react';
import { Box } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const CustomOrder = ({
  orderByValue, options, onChange, ChangeOrderBy,
}) => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      justifyContent="left"
      alignItems="end"
      height="100%"
      className={classes.order}
    >
      <Button
        variant="contained"
        color="primary"
        size="large"
        className={classes.orderButton}
        onClick={ChangeOrderBy}
      >
        <SwapVertIcon />
      </Button>
      <FormControl size="small" variant="outlined" className={classes.orderInput}>
        <InputLabel htmlFor="outlined-age-native-simple">Ordenar Por</InputLabel>
        <Select
          value={orderByValue}
          color="primary"
          onChange={(e) => onChange(e.target.value)}
          label="Ordernar por"
        >

          {options.map((o) => (
            <MenuItem key={o.key} value={o.value}>{o.label}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default CustomOrder;
