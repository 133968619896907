import React from 'react';
import {
  Box, Grid,
} from '@material-ui/core';

// Material
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Divider from '@material-ui/core/Divider';

// project
import GeneralCard from 'commons/components/GeneralCard';
import format from 'date-fns/format';
import isValid from 'date-fns/isValid';
import type from 'utils/type';

// icons
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import PersonIcon from '@material-ui/icons/Person';
import PhoneRoundedIcon from '@material-ui/icons/PhoneRounded';
import EmailIcon from '@material-ui/icons/Email';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useHistory } from 'react-router-dom';
import GeneralMobileCard from 'commons/components/GeneralMobileCard';
import styles from './style';

const useStyles = makeStyles(styles);

const CardItem = ({
  title, content, width = 150,
}) => {
  const classes = useStyles();
  return (
    <Box display="inline-block">
      <Box
        display="flex"
        width={width}
      >
        <Box
          overflow="auto"
        >
          <Typography
            component="div"
            color="primary"
            noWrap
          >
            <Box
              fontSize={16}
              display="flex"
              justifyContent="start"
              alignItems="center"
              height={24}
              fontWeight={900}
            >
              {title}
            </Box>
            <Box
              fontStyle="normal"
              fontSize={16}
              display="flex"
              justifyContent="start"
              alignItems="end"
              className={classes.content}
            >
              {content}
            </Box>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const NameStatus = ({ name, isActive }) => {
  const classes = useStyles();
  return (
    <Box
      borderColor="primary.main"
      className={classes.nameStatus}
    >
      <Grid
        item
        sm={2}
        className={classes.nameContainer}
      >
        <Typography
          component="div"
          variant="h4"
          color="primary"
          className={classes.name}
          noWrap
        >
          <Box
            fontStyle="normal"
            fontSize={18}
            className={classes.boxName}
          >
            { `${name}`}
          </Box>
        </Typography>
        <Box display="flex" alignItems="center">
          <FiberManualRecordIcon
            className={classNames(
              classes.statusIcon,
              isActive ? classes.HABILITADO : classes.DESABILITADO,
            )}
          />
          <Typography variant="body2" color="primary">
            {isActive ? 'HABILITADO' : 'DESHABILITADO'}
          </Typography>
        </Box>
      </Grid>
    </Box>
  );
};

const OptionMenu = ({
  user = null, changeStatus, getHoldings, position = 'center',
}) => {
  const classes = useStyles();

  const { id, isActive } = user;
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      display="flex"
      alignItems={position}
      justifyContent="center"
    >
      <IconButton
        ref={anchorEl}
        onClick={handleClick}
        className={classes.iconButton}
      >

        <MoreVertIcon fontSize="large" color="primary" />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >

        {user.type !== type.ADMINISTRADOR_PORTAL_SALFA
          ? (
            <Box>
              <MenuItem onClick={() => {
                getHoldings(id);
                handleClose();
              }}
              >
                Holding

              </MenuItem>
              <Divider />
            </Box>
          )
          : null}
        <MenuItem onClick={() => {
          history.push(`/user/edit/${id}`);
          handleClose();
        }}
        >
          Editar
        </MenuItem>
        <MenuItem onClick={() => {
          changeStatus(id);
          handleClose();
        }}
        >
          {isActive ? 'Deshabilitar' : 'Habilitar'}
        </MenuItem>
      </Menu>
    </Box>
  );
};

const UserCard = ({
  user, index, actions, isMobile,
}) => {
  const classes = useStyles();
  const { changeStatus, getHoldings } = actions;

  const created = user.createdAt ? new Date(user.createdAt) : undefined;
  const disabled = user.disabledDate ? new Date(user.disabledDate) : undefined;
  return !isMobile ? (
    <GeneralCard
      leftComponent={(
        <NameStatus
          name={`${user.firstName} ${user.lastName}`}
          isActive={user.isActive}
        />
      )}
      rightComponent={(
        <OptionMenu
          user={user}
          changeStatus={changeStatus}
          getHoldings={getHoldings}
        />
      )}
      className={index % 2 === 0 ? classes.back1 : classes.back2}
    >
      <CardItem
        content={user.type.replace(/_/g, ' ')}
        width={310}
      />
      <CardItem
        content={user.phone || 'No informado'}
        width={145}
      />
      <CardItem
        content={user.email || 'No informado'}
        width={210}
      />
      <CardItem
        content={isValid(created) ? format(created, 'yyyy-MM-dd') : 'No informado'}
        width={130}
      />
      <CardItem
        content={isValid(disabled) ? format(disabled, 'yyyy-MM-dd') : 'No informado'}
        width={140}
      />
    </GeneralCard>
  ) : (
    <GeneralMobileCard
      spacing={1}
      rightComponent={(
        <OptionMenu
          user={user}
          changeStatus={changeStatus}
          getHoldings={getHoldings}
          position="end"
        />
      )}
      className={index % 2 === 0 ? classes.back1 : classes.back2}
    >
      <Grid item xs={12}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="start"
        >
          <PersonIcon className={classes.mobileIcon} />
          <Typography component="div" variant="body1" color="primary">

            <Box component="span" fontSize={14} fontWeight={600}>
              {`${user.firstName} ${user.lastName}` || 'No informado'}
            </Box>
            <Box component="span" fontWeight="fontWeightMedium">
              {` | ${user.type.replace(/_/g, ' ')}`}
            </Box>
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={7}>
        <Box display="flex" alignItems="center" justifyContent="start">
          <PhoneRoundedIcon
            className={classes.mobileIcon}
          />
          <Typography component="div" variant="body1" color="primary">
            <Box component="span" fontWeight="fontWeightMedium">
              {user.phone || 'No informado'}
            </Box>
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={5}>
        <Box display="flex" alignItems="center" justifyContent="start">
          <EmailIcon
            className={classes.mobileIcon}
          />
          <Typography component="div" variant="body1" color="primary">
            <Box component="span" fontWeight="fontWeightMedium">
              {user.email || 'No informado'}
            </Box>
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={7}>
        <Box display="flex" alignItems="start">
          <FiberManualRecordIcon
            className={classNames(classes.statusIcon,
              user.isActive ? classes.HABILITADO : classes.DESABILITADO)}
          />
          <Typography component="div" variant="body2" color="primary">
            <Box component="span" fontWeight={700} fontSize={14}>
              Estado
            </Box>
            <Box>
              {user.isActive ? 'HABILITADO' : 'DESHABILITADO'}
            </Box>
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={5}>
        <Typography
          component="div"
          variant="h4"
          color="primary"
          noWrap
        >
          <Box
            display="flex"
            flexDirection="column"
          >
            <Box
              fontStyle="normal"
              display="flex"
              alignItems="center"
              fontSize={14}
              overflow="auto"
            >
              <EventAvailableIcon color="primary" />
              {isValid(created) ? format(created, 'yyyy-MM-dd') : 'No informado'}
            </Box>
            <Box
              fontStyle="normal"
              display="flex"
              alignItems="center"
              fontSize={14}
              overflow="auto"
            >
              <EventBusyIcon color="primary" />
              {isValid(disabled) ? format(disabled, 'yyyy-MM-dd') : 'No informado'}
            </Box>
          </Box>
        </Typography>
      </Grid>
    </GeneralMobileCard>
  );
};

export default UserCard;
