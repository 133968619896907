import { alpha } from '@material-ui/core/styles';

const styles = (theme) => ({
  borderColor: {
    '& .MuiFormLabel-root': {
      color: theme.palette.primary.main,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.primary.main,
      },
    },
  },
  list: {
    marginTop: theme.spacing(2),
  },
  back1: {
    backgroundColor: alpha(theme.palette.primary.main, 0.03),
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.05),
    },
  },
  back2: {
    backgroundColor: alpha(theme.palette.primary.main, 0.08),
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
    },
  },
  w: {
    width: '100%',
  },
});

export default styles;
