import { alpha } from '@material-ui/core/styles';

const styles = (theme) => ({
  textField: {
    background: alpha(theme.palette.primary.main, 0.15),
  },
  autoCompleteForm: {
    '& .MuiInputBase-input': {
      height: '1.7em',
    },
  },
  boxVehicle: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '50%',
    },
    [theme.breakpoints.up('md')]: {
      width: '20%',
    },
  },
  gridContainerRequest: {
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100% + 32px)',
      marginLeft: '-28px',
      marginRight: '-28px',
    },
    backgroundColor: '#F5F5F5',
    marginLeft: '-52px',
    marginRight: '-52px',
    width: 'calc(100% + 128px)',
    marginTop: '8px',

  },
  gridItemRequest: {
    [theme.breakpoints.down('xs')]: {
      marginLeft: '42px',
      marginRight: '42px',
    },
    marginLeft: '64px',
    marginRight: '88px',
  },
  inputLocation: {
    marginLeft: '24px',
  },
  fullWidth: {
    width: '100%',
  },
  container: {
    [theme.breakpoints.down('md')]: {
      height: '100%',
    },
    height: '92px',
  },
  grid: {
    marginTop: '32px',
  },
});

export default styles;
