import {
  Grid, IconButton, Typography, withStyles,
  Box,
  Card,
} from '@material-ui/core';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PersonIcon from '@material-ui/icons/Person';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useHistory, useLocation } from 'react-router-dom';
import Modal from 'screens/VehicleDetail/Components/Modal';
import useForm from 'utils/hooks/useForm';
import Chip from '@material-ui/core/Chip';
import {
  composedComponent,
  validateEmail,
} from 'utils/functions';
import { isValidRut } from 'utils/rut';
import { capitalize, screenTime } from 'utils/utils';
import Skeleton from './Components/Skeleton';
import ScreenTitle from '../../commons/components/ScreenTitle';
import VehicleDetailCards from './Components/VehicleDetailsCards/index';

import VehicleDetailRow from './Components/VehicleDetailsRow/index';
import styles from './styles';
import saga from './saga';
import { vehicleDetailActions } from './reducer';
import PreviewModal from './Components/PreviewModal/PreviewModal';

function VehicleDetail(props) {
  const {
    classes,
    actions,
    vehicle,
    loading,
    error,
    isOpen,
    drivers,
    loadingDrivers,
    loadingAssign,
    formControlsChange,
    successAssign,
    errorDrivers,
    isChange,
    formControlsRequest,
    driverExist,
    loadingRequest,
    successRequest,
    isShared,
    loadingShared,
    documents,
    documentUrl,
  } = props;

  const history = useHistory();
  const location = useLocation();
  const initialActualDocument = {
    isView: false,
    isDownload: false,
    documentId: 0,
    docName: '',
    name: '',
  };

  const [actualDocument, setActualDocument] = useState(initialActualDocument);
  const [isOpenPreview, setIsOpenPreview] = useState(false);

  const resetActualDocument = () => setActualDocument(initialActualDocument);

  useEffect(() => {
    actions.resetControls();
  }, []);

  useEffect(() => screenTime('vehicles-details'), []);

  useEffect(() => {
    // descarga
    if (actualDocument.isDownload && !!documentUrl && documentUrl !== '') {
      fetch(documentUrl)
        .then((response) => response.blob())
        .then((myBlob) => {
          const a = document.createElement('a');
          a.href = window.URL.createObjectURL(myBlob);
          a.download = actualDocument.docName;
          a.click();
        });
      resetActualDocument();
    }
    // visualizar
    if (actualDocument.isView && !!documentUrl && documentUrl !== '') {
      // ejecutar logica para iframe
      setIsOpenPreview(true);
    }
  }, [documentUrl]);

  useEffect(() => {
    if (error) {
      history.push('/');
      actions.resetControls();
    }
  }, [error]);

  useEffect(() => {
    actions.getVehicleDetail(location.pathname);
    actions.getDocuments(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    if (successAssign) {
      actions.resetControls();
      // change current driver
      actions.changeDriver({
        assignedToName: formControlsChange.driverName,
        assignedToRun: formControlsChange.driverRun,
      });
    }
  },
  [
    successAssign,
  ]);

  useEffect(() => {
    if (successRequest) {
      actions.resetControls();
      actions.closeModal();
    }
  },
  [
    successRequest,
  ]);

  useEffect(() => {
    if (Object.keys(vehicle).length > 0) {
      actions.fetchDrivers(vehicle['ContractVehicle.SocialReason.id']);
    }
  }, [vehicle]);

  const dataDrivers = useMemo(() => drivers.map((d) => ({
    value: d.run,
    label: d.name,
  })), [drivers]);

  const handleOpenModal = () => {
    actions.selectDriver({
      vehicle: vehicle?.id,
    });
    actions.openModal();
  };

  const handleCloseModal = () => {
    actions.closeModal();
    actions.resetControls();
  };

  const handleSelectDriver = (data) => {
    actions.selectDriver(data);
  };

  const handleAssignDriver = () => {
    actions.assignDriver(formControlsChange);
  };

  const handleSetIsChange = (value) => {
    actions.changeControls({ socialReason: vehicle['ContractVehicle.SocialReason.id'] || '' });
    actions.setIsChange(value);
  };

  const changeControls = useCallback((name, value) => {
    actions.changeControls({ [name]: value });
  }, []);

  const { onChange, onSubmit, errors } = useForm(formControlsRequest, null, {
    onChange: changeControls,
    validations: {
      email: [
        {
          check: (value) => !!value && value.trim().length > 0,
          message: 'El Email es requerido',
        },
        {
          check: (value) => validateEmail(value),
          message: 'El Email es inválido',
        },
      ],
      run: [
        {
          check: (value) => !!value && value.trim().length > 0,
          message: 'El RUT es requerido',
        },
        {
          check: (value) => isValidRut(value),
          message: 'El RUT es inválido',
        },
      ],
      name: [
        {
          check: (value) => !!value && value.trim().length > 0,
          message: 'El Nombre es requerido',
        },
        {
          check: (value) => value.trim().split(' ').length > 1,
          message: 'Ingrese un nombre y apellido',
        },
      ],
      phone: [
        {
          check: (value) => !!value && value.trim().length > 0,
          message: 'El Teléfono es requerido',
        },
        {
          check: (value) => !(value.trim().length < 9),
          message: 'Ingrese un mínimo de 9 números',
        },
        {
          check: (value) => !(value.trim().length > 11),
          message: 'Ingrese un máximo de 11 números',
        },
      ],
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const driver = dataDrivers.filter((d) => d.value === formControlsRequest.run.replace(/[.]/g, ''));

    if (driver.length > 0) {
      actions.validateDriverRun();
    } else {
      onSubmit(() => actions.createRequest(formControlsRequest));
    }
  };

  const titleContentRow = ({ title, content }) => (
    <Grid item style={{ marginRight: '10px', marginLeft: '10px' }}>
      <Grid
        container
      >
        <Typography className={classes.comercialConditionsTypo} style={{ fontWeight: 'bold' }}>
          {title}
          {' '}
        </Typography>
        <Typography className={classes.comercialConditionsTypo} style={{ paddingLeft: '5px' }}>{content}</Typography>
      </Grid>
    </Grid>

  );

  const handleView = ({ id, name }) => async () => {
    // realizar consulta para visualizar archivo en ifrae
    await actions.getDocumentUrl({ documentId: id });

    setActualDocument({
      ...actualDocument,
      isView: true,
      documentId: id,
      name,
    });
  };

  const handleDownload = ({ id, docName }) => async () => {
    await actions.getDocumentUrl({ documentId: id });
    setActualDocument({
      ...actualDocument,
      isDownload: true,
      documentId: id,
      docName,
    });
  };

  const handleClosePreview = () => {
    setIsOpenPreview(false);
    resetActualDocument();
  };

  const documentsContent = (listDocuments) => {
    // const sortedDocuments = [...listDocuments].sort((a, b) => {
    //   if (a.DocumentType.name === b.DocumentType.name) {
    //     if (a.fileName.includes('_2DA') && !b.fileName.includes('_2DA')) return 1;
    //     if (!a.fileName.includes('_2DA') && b.fileName.includes('_2DA')) return -1;
    //   }
    //   return 0;
    // });
    const sortedDocuments = [...listDocuments].sort((a, b) => a.id - b.id);

    const documentsCount = {};

    return (
      <Card>
        <Grid container justifyContent="center">

          {sortedDocuments.map((document, index) => {
            const documentTypeName = document.DocumentType.name;

            // Ignorar archivos que tienen '_1' en su nombre
            if (document.fileName.includes('_1')) {
              return;
            }

            if (!documentsCount[documentTypeName]) {
              documentsCount[documentTypeName] = 0;
            }
            documentsCount[documentTypeName] += 1;

            const displayName = documentsCount[documentTypeName] > 1
              ? `${capitalize(documentTypeName)} 2da` : capitalize(documentTypeName);

            // eslint-disable-next-line consistent-return
            return (
              <Grid
                key={document.id}
                item
                container
                xs={10}
                style={{ borderBottom: listDocuments.length - 1 === index ? 'none' : '1px solid #002855', color: '#002855', padding: '10px 0' }}
              >
                <Grid item container justifyContent="space-between" alignItems="center">
                  <Grid
                    item
                    container
                    md={8}
                    lg={9}
                    alignItems="center"
                    style={{ cursor: 'pointer' }}
                    onClick={handleView({ id: document.id, name: document.DocumentType.name })}
                  >
                    <Grid item style={{ paddingRight: 20 }} xs={1}>
                      <PictureAsPdfIcon />
                    </Grid>
                    <Grid item xs={11}>
                      <Typography className={classes.comercialConditionsTypo} style={{ paddingLeft: '5px' }}>{displayName}</Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    md={4}
                    lg={3}
                    alignItems="center"
                    spacing={1}
                    justifyContent="flex-end"
                    style={{ cursor: 'pointer' }}
                    onClick={handleDownload({ id: document.id, docName: document.fileName })}
                  >
                    <Grid item>
                      <GetAppIcon />
                    </Grid>
                    <Grid item>
                      <Typography className={classes.comercialConditionsTypo} style={{ paddingLeft: '5px' }}>Descargar</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Card>
    );
  };

  const noDocumentsData = () => (
    <Card>
      <Grid container justifyContent="center">
        <Grid item>
          <Typography className={classes.comercialConditionsTypo}>
            No hay documentos disponibles
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );

  return (
    loading ? <Skeleton />
      : (
        <Box className={classes.screenContainer} height={1}>

          <IconButton
            className={classes.backButton}
            disableRipple
            onClick={() => { history.push('/vehicles'); }}
          >
            <ArrowBackIcon color="secondary" />
            <Typography color="primary">Volver</Typography>
          </IconButton>
          <Box
            className={classes.boxTitle}
          >
            <Box>
              <ScreenTitle title="DETALLE DE VEHÍCULO" />
            </Box>
            <Box>
              <Chip
                className={classes.chip}
                label={(
                  <Box display="flex" alignItems="center">
                    <PersonIcon fontSize="small" />
                    <Box fontWeight="fontWeightBold">
                      CLIENTE
                    </Box>
                    <Box ml="8px">
                      {vehicle['ContractVehicle.SocialReason.name']}
                    </Box>
                  </Box>
                )}
              />
            </Box>
          </Box>
          <Box className={classes.gridContainer}>
            <Grid item>
              <VehicleDetailCards vehicle={vehicle} actions={actions} />
            </Grid>

            <Grid item xs={12}>
              <VehicleDetailRow
                vehicle={vehicle}
                openModal={handleOpenModal}
                changeShared={actions.setShared}
                isShared={isShared}
                loadingShared={loadingShared}
              />

            </Grid>
            <Grid
              container
              justifyContent="space-between"
              className={classes.bottomSectionContainer}
              spacing={2}
            >

              <Grid item xs={12} style={{ marginTop: '37px', boxSizing: 'border-box' }}>

                <Grid container item justifyContent="space-between" spacing={2}>
                  <Grid item container spacing={2} md={6}>
                    <Grid
                      item
                      md={12}
                      style={{
                        display: 'flex', alignItems: 'center', justifyContent: 'space-between',
                      }}
                    >
                      <Typography color="primary" style={{ fontSize: '1.5em' }}>Documentos</Typography>
                    </Grid>
                    <Grid item md={12}>
                      {documents?.DownloadableDocument?.length
                        ? documentsContent(documents.DownloadableDocument)
                        : noDocumentsData()}
                    </Grid>
                  </Grid>
                  <Grid item container spacing={2} md={6}>
                    <Grid
                      item
                      md={12}
                      style={{
                        display: 'flex', alignItems: 'center', justifyContent: 'space-between',
                      }}
                    >
                      <Typography color="primary" style={{ fontSize: '1.5em' }}>Condiciones comerciales</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Card>
                        {titleContentRow({ title: 'VIN:', content: vehicle.externalId || 'No informado' })}
                        {titleContentRow({ title: 'Rendimiento neumático:', content: vehicle['VehicleTariff.tiresPerformanceKm'] || 'No informado' })}
                        {titleContentRow({ title: `Valor contratado [${vehicle['VehicleTariff.agreedCurrency'] || 'No informado'}]:`, content: vehicle['VehicleTariff.contractedValue'] || 'No informado' })}
                        {titleContentRow({ title: 'Deducible:', content: vehicle.deducibleUf || 'No informado' })}
                        {titleContentRow({ title: '% de Reemplazo:', content: vehicle['VehicleTariff.replacementPercentage'] || 'No informado' })}
                        {titleContentRow({ title: 'Tarifa:', content: vehicle['VehicleTariff.rate'] || 'No informado' })}
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

            </Grid>
          </Box>
          <Modal
            isOpen={isOpen}
            close={handleCloseModal}
            // Change driver props
            isChange={isChange}
            drivers={dataDrivers}
            handleSelectDriver={handleSelectDriver}
            loadingDrivers={loadingDrivers}
            loadingAssign={loadingAssign}
            currentDriver={formControlsChange.driverRun}
            handleAssignDriver={handleAssignDriver}
            errorDrivers={errorDrivers}
            handleSetIsChange={handleSetIsChange}
            driverExist={driverExist}
            // Request driver props
            socialReason={vehicle['ContractVehicle.SocialReason.Holding.name'] || ''}
            onChange={onChange}
            handleSubmit={handleSubmit}
            errors={errors}
            controls={formControlsRequest}
            loadingRequest={loadingRequest}
          />
          <PreviewModal
            isOpen={isOpenPreview}
            name={actualDocument.name}
            licencePlate={vehicle.licensePlate}
            handleClose={handleClosePreview}
            url={documentUrl}
          />
        </Box>
      )
  );
}

export default composedComponent(VehicleDetail, saga, {
  saga: 'sagaVehicleDetail',
  middlewares: [withStyles(styles)],
  states: [
    'vehicleDetail.vehicle',
    'vehicleDetail.loading',
    'vehicleDetail.error',
    'vehicleDetail.message',
    'vehicleDetail.isOpen',
    'vehicleDetail.drivers',
    'vehicleDetail.loadingDrivers',
    'vehicleDetail.loadingAssign',
    'vehicleDetail.formControlsChange',
    'vehicleDetail.successAssign',
    'vehicleDetail.errorDrivers',
    'vehicleDetail.isChange',
    'vehicleDetail.formControlsRequest',
    'vehicleDetail.driverExist',
    'vehicleDetail.loadingRequest',
    'vehicleDetail.successRequest',
    'vehicleDetail.isShared',
    'vehicleDetail.loadingShared',
    'vehicleDetail.documents',
    'vehicleDetail.documentUrl',
  ],
  actions: [vehicleDetailActions],
});
