import React from 'react';
import {
  Box, FormHelperText, Grid, Hidden,
} from '@material-ui/core';
import ClientSettingsAdminRow from '../ClientSettingsAdminRow';
import ClientSettingsAdminHeader from '../ClientSettingsAdminHeader';

const ClientSettingsAdminList = ({
  classes, adminList, handleChangeCheckBox, handleChangeRadio, errors,
}) => (
  <Box className={classes.mt20}>
    <Hidden xsDown>
      <ClientSettingsAdminHeader classes={classes} />
    </Hidden>
    <Grid container className={classes.list}>
      {adminList && adminList.map((item) => (
        <ClientSettingsAdminRow
          key={item.adminId}
          classes={classes}
          data={item}
          handleChangeCheckBox={handleChangeCheckBox}
          handleChangeRadio={handleChangeRadio}
        />
      ))}
    </Grid>
    { errors?.contractManagers?.length > 0
    && <FormHelperText error>{errors.contractManagers[0]}</FormHelperText>}
  </Box>
);

export default ClientSettingsAdminList;
