import React, { useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';
import styles from './styles';

const useStyles = makeStyles(styles);
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const LabelText = (label, all) => {
  const classes = useStyles();
  return (
    <Box
      display="flex"
    >
      {all
        ? (
          <>
            <Box className={classes.box}>
              {`${label}: `}
            </Box>
            <Box>
              TODOS
            </Box>
          </>
        )
        : label}
    </Box>
  );
};

const MultipleFilter = ({
  id = '',
  label = '',
  size = 'small',
  options = [],
  value = [],
  limitTags = 1,
  onChange = (data) => data,
}) => {
  const classes = useStyles();
  const [all, setAll] = useState(true);

  const handleChange = (e, data) => {
    const values = data.map((d) => d.value);
    onChange({ key: id, value: values });
  };

  const handleFocus = () => {
    setAll(false);
  };

  const handleBlur = () => {
    if (value?.length === 0) { setAll(true); }
  };

  return (
    <Autocomplete
      size={size}
      multiple
      value={options.filter(({ value: v }) => value.includes(v))}
      limitTags={limitTags}
      id={id}
      options={options}
      disableCloseOnSelect
      onChange={handleChange}
      getOptionLabel={(option) => option.label}
      getOptionSelected={(option, v) => option.value === v.value}
      className={classes.autoComplete}
      noOptionsText="No se encontraron resultados"
      renderOption={(option, { selected }) => (
        <>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
            color="primary"
          />
          {option.label}
        </>
      )}
      renderTags={(v, getTagProps) => v.map((option, index) => (
        <Chip
          color="primary"
          variant="default"
          label={option.label}
          size={size}
          {...getTagProps({ index })}
        />
      ))}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          variant="outlined"
          label={LabelText(label, all)}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      )}
    />
  );
};
export default MultipleFilter;
