import React from 'react';
import {
  Box, Button, Grid, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import GeneralCard from 'commons/components/GeneralCard';
import styles from './styles';

const useStyles = makeStyles(styles);

function SocialReasonCard({
  index, name, rut, handleClickView, handleClickDownload,
}) {
  const classes = useStyles();
  return (
    <GeneralCard
      className={classNames(classes.root, index % 2 === 0 ? classes.back1 : classes.back2)}
    >
      <Grid
        container
        className={classes.container}
      >
        <Grid item sm={6} className={classes.item}>
          <Typography variant="h4">{name}</Typography>
        </Grid>
        <Grid item sm={2} className={classes.item}>
          <Typography>{rut}</Typography>
        </Grid>
        <Grid item sm={2}>
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={handleClickView}
          >
            <Box fontWeight="bold">
              PATENTES
            </Box>
          </Button>
        </Grid>
        <Grid item sm={2}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleClickDownload}
          >
            <Box fontWeight="bold">
              DESCARGAR
            </Box>
          </Button>
        </Grid>
      </Grid>
    </GeneralCard>
  );
}

export default SocialReasonCard;
