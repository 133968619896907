import { takeLatest, spawn, put } from 'redux-saga/effects';

import { apiSuccess, get, post } from 'utils/api';
import * as APP from 'commons/reducer';
import { HOME_MARKETING } from '../reducer';

function* getInitialState() {
  yield takeLatest(HOME_MARKETING.GET_INITIAL_STATE, function* () {
    const response = yield get('api/v1/marketing-home');

    if (!response.error) {
      yield put(apiSuccess(HOME_MARKETING.GET_INITIAL_STATE_SUCCESS, response));
    } else {
      yield put(apiSuccess(
        APP.SET_ERROR,
        { msg: 'Error al obtener el estado inicial, intetalo de nuevo más tarde' },
      ));
    }
  });
}

function* toggleHomeMarketingItem() {
  yield takeLatest(HOME_MARKETING.ENABLE_ITEM, function* (action) {
    yield post('api/v1/marketing-home', action.payload);
  });
}

export default function* root() {
  yield spawn(getInitialState);
  yield spawn(toggleHomeMarketingItem);
}
