import React, { useEffect, useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { Fab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import styles from './styles';

const useStyles = makeStyles(styles);

const rootEl = document.documentElement;

const CustomFloatButton = ({ handleClick }) => {
  const classes = useStyles();
  const [hasScrollbar, setHasScrollbar] = useState(rootEl.clientHeight < rootEl.scrollHeight);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      setHasScrollbar(rootEl.clientHeight < rootEl.scrollHeight);
    });
    resizeObserver.observe(rootEl);
    return () => resizeObserver.disconnect();
  }, []);

  return (
    <Fab
      color="primary"
      className={classNames(classes.fab, hasScrollbar && classes.scrollable)}
      onClick={handleClick}
    >
      <AddIcon />
    </Fab>
  );
};

export default CustomFloatButton;
