import { isBefore } from 'date-fns';

/**
 * @param {string} name filter input
 */
export const filterName = (name) => (holding) => {
  if (!name) return true;

  return holding.name.toLowerCase().includes(name.toLowerCase())
        || name.toLowerCase().includes(holding.name.toLowerCase());
};

/**
  * @param {string[]} status filter input
  */
export const filterStatus = (status) => (holding) => {
  if (!status.length) return true;

  return status.includes(holding.isActive);
};

/**
 * @param {string} date filter input (format: yyyy/mm/dd)
 */
export const filterDate = (date) => (holding) => {
  if (!date) return true;

  return isBefore(new Date(date), new Date(holding.createdAt));
};
