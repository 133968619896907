import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React from 'react';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import GeneralCard from 'commons/components/GeneralCard';
import { SOCIAL_REASON_ATTR, SOCIAL_REASON_COLS } from 'screens/HoldingMaintainerDetail/constants';
import classNames from 'classnames';
import styles from './styles';

const useStyles = makeStyles(styles);

const headers = [
  {
    label: 'ID Razón social', key: SOCIAL_REASON_ATTR.ID, width: 120, center: true,
  },
  { label: 'Rut', key: SOCIAL_REASON_ATTR.RUT, width: 150 },
  { label: 'Nombre', key: SOCIAL_REASON_ATTR.NAME, width: 280 },
  {
    label: 'Fecha de creación', key: SOCIAL_REASON_ATTR.CREATED_AT, width: 200, center: true,
  },
  {
    label: 'Estado', key: SOCIAL_REASON_ATTR.STATE, width: 130, center: true,
  },
];

const OptionMenu = ({
  position = 'center',
}) => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      alignItems={position}
      justifyContent="center"
      className={classes.option}
    />
  );
};

const Header = ({
  setOrderBy, changeOrderby, label, keyValue, orderByValue, width, center = false,
}) => {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      flexDirection="row"
      width={width}
    >
      <Button
        fullWidth
        color="primary"
        className={classNames(classes.button, center && classes.center)}
        size="small"
        endIcon={<ImportExportIcon className={classes.icon} />}
        onClick={() => {
          if (orderByValue === keyValue) {
            changeOrderby();
          } else if (orderByValue === '' || orderByValue !== keyValue) {
            setOrderBy(keyValue);
          }
        }}
      >
        <Box fontWeight={700} color="white">
          {label}
        </Box>
      </Button>
    </Box>
  );
};

const HeaderList = ({
  setOrderBy, changeOrderby, orderByValue = null,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (!isMobile ? (
    <GeneralCard
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      px={1}
      rightComponent={(<OptionMenu />)}
      className={classes.root}
    >
      {headers.map((header) => (
        <Box display="inline-block" key={header.key}>
          <Header
            orderByValue={orderByValue}
            setOrderBy={setOrderBy}
            changeOrderby={changeOrderby}
            label={header.label}
            keyValue={header.key}
            key={header.key}
            width={header.width}
            center={header.center}
          />
        </Box>
      ))}
    </GeneralCard>
  ) : (
    <Box>
      <Box display="flex" className={classes.mobileOrderFilter}>
        <FormControl variant="outlined" fullWidth className={classes.mobileOrderInput}>
          <InputLabel htmlFor="outlined-age-native-simple">Ordenar Por</InputLabel>
          <Select
            value={orderByValue}
            color="primary"
            onChange={(e) => setOrderBy(e.target.value)}
            label="Ordernar por"
            inputProps={{
              name: 'Ordenar por',
              id: 'outlined-age-native-simple',
            }}
          >
            <MenuItem aria-label="None" value="">Ninguno</MenuItem>
            <MenuItem value={SOCIAL_REASON_ATTR.ID}>{SOCIAL_REASON_COLS.ID}</MenuItem>
            <MenuItem value={SOCIAL_REASON_ATTR.RUT}>{SOCIAL_REASON_COLS.RUT}</MenuItem>
            <MenuItem value={SOCIAL_REASON_ATTR.NAME}>{SOCIAL_REASON_COLS.NAME}</MenuItem>
            <MenuItem value={SOCIAL_REASON_ATTR.CREATED_AT}>
              {SOCIAL_REASON_COLS.CREATED_AT}
            </MenuItem>
            <MenuItem value={SOCIAL_REASON_ATTR.STATE}>{SOCIAL_REASON_COLS.STATE}</MenuItem>
          </Select>
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => changeOrderby()}
        >
          <SwapVertIcon />
        </Button>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        width="100%"
      >
        <Box
          className={classes.mobileListTitle}
        >
          <Typography variant="h5" color="primary">
            Listado de razones sociales
          </Typography>
        </Box>
      </Box>
    </Box>
  ));
};

export default HeaderList;
