import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';

function HomeMarketingSkeleton() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Skeleton variant="rect" width="100%" height={200} />
      </Grid>
      <Grid item xs={12} md={6} sm={6}>
        <Skeleton variant="rect" width="100%" height={200} />
      </Grid>
      <Grid item xs={12} md={6} sm={6}>
        <Skeleton variant="rect" width="100%" height={200} />
      </Grid>
    </Grid>
  );
}

export default HomeMarketingSkeleton;
