import React from 'react';
import CustomPagination from 'commons/components/CustomPagination';
import WorkOrderCard from 'screens/WorkOrder/component/WorkOrderCard';
import {
  Box, Card, Grid, Typography,
} from '@material-ui/core';

const CardItem = ({
  title, content, xs, md, icon, extraContent, isMobile, align = 'left',
}) => {
  const classes = {};
  return (
    <Grid item xs={xs} md={md} style={{ textAlign: isMobile ? 'left' : align }}>
      { isMobile
        && (
        <Box className={classes.titleIcon}>
          {icon}
          <Typography className={classes.cardTitle}>
            {title}
          </Typography>
        </Box>
        )}
      <Typography>
        {content}
      </Typography>
      <Typography>
        {extraContent}
      </Typography>
    </Grid>
  );
};

const WorkOrderList = ({
  workOrders, pagination, handleChangePagination, isMobile,
}) => {
  const { page, totalPages } = pagination;

  return (
    <>
      <Box
        padding={1}
        display="grid"
        gridTemplateRows="auto"
        gridGap={8}
        overflow="auto"
      >
        {workOrders.length === 0 && (
          <Card>
            <Grid container justifyContent="center">
              <CardItem content="No hay registros disponibles" md={12} xs={12} isMobile={isMobile} align="center" />
            </Grid>
          </Card>
        )}
        {workOrders.map((workOrder, i) => (
          <WorkOrderCard
            key={workOrder.id}
            index={i}
            workOrder={workOrder}
            isMobile={isMobile}
          />
        ))}
      </Box>
      <CustomPagination
        page={page}
        totalPages={totalPages}
        onChange={handleChangePagination}
      />
    </>
  );
};

export default WorkOrderList;
