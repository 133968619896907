import React from 'react';
import { FormControlLabel, Radio } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';

const useStyles = makeStyles(styles);
const RequirementCheck = ({ name, value, onChange }) => {
  const classes = useStyles();

  const handleChange = (e) => {
    onChange({ [name]: e.target.value });
  };

  const options = [
    {
      id: 1,
      value: 'Contrato Nuevo',
    },
    {
      id: 2,
      value: 'Renovación',
    },
  ];

  return (
    <div>
      {options.map((p) => (
        <FormControlLabel
          key={p.id}
          className={classes.formControlLabel}
          control={(
            <Radio
              checked={value === p.value}
              id={name}
              name={name}
              value={p.value}
              color="primary"
              onChange={handleChange}
            />
        )}
          label={p.value}
        />
      ))}
    </div>
  );
};

export default RequirementCheck;
