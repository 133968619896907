import React from 'react';
import {
  Box, FormHelperText, InputLabel, makeStyles, MenuItem, Select,
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import styles from './styles';

const useStyles = makeStyles(styles);

const CustomSelect = ({
  id,
  label,
  name,
  variant = 'outlined',
  options = [],
  onChange,
  value,
  error,
  helperText,
  disabled = false,
  size = 'small',
}) => {
  const classes = useStyles();

  const handleChange = (e) => {
    onChange({ target: { id, value: e.target.value, type: 'select' }, currentTarget: { id } });
  };

  const renderValue = () => (options.length > 0) && options?.find((v) => v.value === value)?.label;
  return (
    <Box>
      <FormControl
        fullWidth
        variant={variant}
        size={size}
        className={classes.borderColor}
        error={error}
      >
        <InputLabel id={name}>{label}</InputLabel>
        <Select
          id={name}
          name={name}
          value={value}
          renderValue={renderValue}
          label={label}
          onChange={handleChange}
          disabled={disabled}
        >
          {options.map((p) => (
            <MenuItem
              key={p.id}
              value={p.value}
            >
              {p.label}
            </MenuItem>
          ))}
        </Select>
        {error && <FormHelperText>{helperText}</FormHelperText> }
      </FormControl>
    </Box>
  );
};

export default CustomSelect;
