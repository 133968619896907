import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import styles from './styles';

const useStyles = makeStyles(styles);

const LabelText = (label, all) => {
  const classes = useStyles();
  return (
    <Box
      display="flex"
    >
      {all
        ? (
          <>
            <Box className={classes.box}>
              {`${label}: `}
            </Box>
            <Box>
              TODOS
            </Box>
          </>
        )
        : label}
    </Box>
  );
};

const TextInputFilter = ({
  id, label, size = 'small', options, value, onChange = () => {}, onSelect = () => {},
}) => {
  const classes = useStyles();
  const [all, setAll] = useState(!value);

  const handleChange = (e, text) => {
    if (!e) {
      return;
    }

    onChange({ key: id, value: text });
  };

  const handleSelect = (e, selected) => {
    onSelect(selected);
  };

  const handleFocus = () => {
    setAll(false);
    onChange({ key: id, value: '' });
  };

  const handleBlur = () => {
    if (value?.length === 0) { setAll(true); }
  };

  return (
    <Autocomplete
      size={size}
      id={id}
      value={null}
      inputValue={value}
      options={options}
      clearOnBlur={false}
      onInputChange={handleChange}
      onChange={handleSelect}
      getOptionLabel={(option) => option.label}
      getOptionSelected={(option, v) => option.value === v.value}
      className={classes.autoComplete}
      noOptionsText="No se encontraron resultados"
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          variant="outlined"
          label={LabelText(label, all)}
          onClick={handleFocus}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      )}
    />
  );
};

export default TextInputFilter;
