import React from 'react';
import Grid from '@material-ui/core/Grid';
import CustomCheckBox from 'commons/components/CustomCheckBox';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import styles from './styles';

const useStyles = makeStyles(styles);
const VehicleType = ({
  id,
  label,
  checked,
  quantity,
  onChangeCheck,
  handleClickIcon,
}) => {
  const classes = useStyles();

  const handleClickAdd = (VehicleId, VehicleQuantity) => {
    handleClickIcon(VehicleId, VehicleQuantity + 1);
  };

  const handleClickRemove = (VehicleId, VehicleQuantity) => {
    if ((VehicleQuantity - 1) > 0) handleClickIcon(VehicleId, VehicleQuantity - 1);
  };

  return (
    <Grid container alignItems="center">
      <Grid item xs={6}>
        <CustomCheckBox
          id={id}
          checked={checked}
          label={label}
          name={label}
          onChange={(e) => onChangeCheck(e)}
        />
      </Grid>

      <Grid item xs={6}>
        <Box
          className={classes.boxRoot}
        >
          <RemoveIcon
            className={classes.icon}
            color={checked ? 'primary' : 'disabled'}
            onClick={() => checked && handleClickRemove(id, quantity)}
          />
          <Typography>
            {quantity}
          </Typography>
          <AddIcon
            className={classes.icon}
            color={checked ? 'primary' : 'disabled'}
            onClick={() => checked && handleClickAdd(id, quantity)}
          />

        </Box>
      </Grid>
    </Grid>
  );
};

export default VehicleType;
