import {
  Box, useMediaQuery, useTheme,
} from '@material-ui/core';
import React from 'react';

// Material
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

// Project
import SwapVertIcon from '@material-ui/icons/SwapVert';

// icons
import ImportExportIcon from '@material-ui/icons/ImportExport';
import DownloadButton from 'commons/components/DownloadButton';
import { HOLDING_ATTR, HOLDING_COLS } from 'screens/HoldingMaintainer/constants';
import classNames from 'classnames';
import styles from './styles';

const useStyles = makeStyles(styles);

const headers = [
  {
    label: HOLDING_COLS.ID, key: HOLDING_ATTR.ID, width: 120, center: true,
  },
  { label: HOLDING_COLS.RUT, key: HOLDING_ATTR.RUT, width: 130 },
  { label: HOLDING_COLS.NAME, key: HOLDING_ATTR.NAME, width: 320 },
  {
    label: HOLDING_COLS.SR_COUNT, key: HOLDING_ATTR.SR_COUNT, width: 130, center: true,
  },
  {
    label: HOLDING_COLS.CREATED_AT, key: HOLDING_ATTR.CREATED_AT, width: 180, center: true,
  },
  { label: HOLDING_COLS.STATE, key: HOLDING_ATTR.STATE, width: 120 },
];

const Header = ({
  setOrderBy, changeOrderby, label, keyValue, orderByValue, width, center = false,
}) => {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="center"
      width={width}
    >
      <Button
        fullWidth
        color="primary"
        className={classNames(classes.button, center && classes.center)}
        size="small"
        endIcon={<ImportExportIcon className={classes.icon} />}
        onClick={() => {
          if (orderByValue === keyValue) {
            changeOrderby();
          } else if (orderByValue === '' || orderByValue !== keyValue) {
            setOrderBy(keyValue);
          }
        }}
      >
        <Box fontWeight={700} color="white">
          {label}
        </Box>
      </Button>
    </Box>
  );
};

const HeaderList = ({
  setOrderBy, changeOrderby, orderByValue, handleDownload,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (!isMobile ? (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      paddingRight={1}
      paddingLeft={2}
      className={classes.root}
    >
      {headers.map((header) => (
        <Header
          orderByValue={orderByValue}
          setOrderBy={setOrderBy}
          changeOrderby={changeOrderby}
          label={header.label}
          keyValue={header.key}
          key={header.key}
          width={header.width}
          center={header.center}
        />
      ))}
    </Box>
  ) : (
    <Box>
      <Box display="flex" className={classes.mobileOrderFilter}>
        <FormControl variant="outlined" fullWidth className={classes.mobileOrderInput}>
          <InputLabel htmlFor="outlined-age-native-simple">Ordenar Por</InputLabel>
          <Select
            value={orderByValue ?? ''}
            color="primary"
            onChange={(e) => setOrderBy(e.target.value)}
            label="Ordernar por"
            inputProps={{
              name: 'Ordenar por',
              id: 'outlined-age-native-simple',
            }}
          >
            <MenuItem aria-label="None" value="">Ninguno</MenuItem>
            <MenuItem value={HOLDING_ATTR.ID}>{HOLDING_COLS.ID}</MenuItem>
            <MenuItem value={HOLDING_ATTR.NAME}>{HOLDING_COLS.NAME}</MenuItem>
            <MenuItem value={HOLDING_ATTR.RUT}>{HOLDING_COLS.RUT.toUpperCase()}</MenuItem>
            <MenuItem value={HOLDING_ATTR.SR_COUNT}>{HOLDING_COLS.SR_COUNT}</MenuItem>
            <MenuItem value={HOLDING_ATTR.CREATED_AT}>{HOLDING_COLS.CREATED_AT}</MenuItem>
            <MenuItem value={HOLDING_ATTR.STATE}>{HOLDING_COLS.STATE}</MenuItem>
          </Select>
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => changeOrderby()}
        >
          <SwapVertIcon />
        </Button>
      </Box>
      <Box
        justifyContent="space-between"
      >
        <Box
          className={classes.mobileListTitle}
        >
          <Typography variant="h5" color="primary">
            Listado de holdings
          </Typography>
        </Box>
        <Box>
          <DownloadButton
            total={2}
            onClick={handleDownload}
            loading={false}
            text="Descargar reporte"
          />
        </Box>
      </Box>
    </Box>
  ));
};

export default HeaderList;
