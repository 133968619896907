/* eslint-disable func-names */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/named */
import {
  takeLatest, spawn, put, select,
} from 'redux-saga/effects';
import {
  apiSuccess,
  get, post,
} from 'utils/api';

import controlledCall from 'utils/services/controlledSaga';
import { appActions } from 'commons/reducer';
import * as APP from '../../../commons/reducer';

// Reducer
import { PAYMENT_STATUS_CONFIG, paymentStatusConfigActions } from '../reducer';

const getControls = (state) => state.paymentStatusConfig.controls;

function* getStateFromApi() {
  yield takeLatest(PAYMENT_STATUS_CONFIG.GET_STATE_FROM_API, function* (action) {
    yield controlledCall(
      get,
      'api/v1/payment-status/settings',
      action.controls,
      paymentStatusConfigActions.getStateFromApiSuccess,
      paymentStatusConfigActions.getStateFromApiFail,
    );
  });
}

function* submitSettings() {
  yield takeLatest(PAYMENT_STATUS_CONFIG.SUBMIT_SETTINGS, function* () {
    yield put(appActions.setLoader('submit_settings', true));
    const { fields } = yield select(getControls);
    const response = yield post('api/v1/payment-status/update-settings', fields);
    if (!response.error) {
      yield put(apiSuccess(PAYMENT_STATUS_CONFIG.SUBMIT_SETTINGS_SUCCESS, response));
      yield put(apiSuccess(APP.SET_SUCCESS, { msg: 'Guardado exitosamente' }));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { msg: 'Error al guardar' }));
    }
    yield put(appActions.setLoader('submit_settings', false));
  });
}

export default function* root() {
  yield spawn(getStateFromApi);
  yield spawn(submitSettings);
}
