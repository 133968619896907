import {
  takeLatest, spawn, put, select,
} from 'redux-saga/effects';
import { generatePath, getIds } from 'utils/functions';
import format from 'date-fns/format';
import { apiSuccess, downloadFileBlob, get } from 'utils/api';

import { WORKORDER } from '../reducer';
import * as APP from '../../../commons/reducer';

const getSize = (state) => state.workOrder.pagination.size;
const getPage = (state) => state.workOrder.pagination.page;
const getSocialReason = (state) => state.workOrder.controls.socialReason;
const getQuery = (state) => state.workOrder.query;
const getHolding = (state) => state.app.holding.id;

const getFirstFetch = (state) => state.workOrder.firstFetch;

function* generatePathWorkOrders({
  page,
  size,
  route,
}) {
  const pageToSend = page > 0 ? page : (yield select(getPage));
  const sizeToSend = size > 0 ? size : (yield select(getSize));
  let path = `${route}?size=${sizeToSend}&page=${pageToSend}`;

  const firstFetch = yield select(getFirstFetch);

  const {
    vehicle,
    service,
    type,
    status,
  } = yield select(getQuery);

  const holding = yield select(getHolding);
  const socialReasonIds = yield select(getSocialReason);
  const pathSocialReason = getIds(socialReasonIds).map((d) => `&socialReason=${d}`).join('').slice(1);
  // Generate path for filters
  const vehiclePath = generatePath(vehicle, 'vehicle');
  const servicePath = generatePath(service, 'service');
  const typePath = generatePath(type, 'type');
  const statusPath = generatePath(status, 'status');

  if (typeof firstFetch === 'boolean') path += `&firstFetch=${firstFetch ? 'true' : 'false'}`;
  if (holding) path += `&holding=${holding}`;
  if (socialReasonIds.length > 0) path += `&${pathSocialReason}`;
  if (vehicle) path += vehiclePath;
  if (service) path += servicePath;
  if (type) path += typePath;
  if (status) path += statusPath;
  return path;
}

function* requestWorkOrders(
  page = 0,
  size = 0,
) {
  const path = yield generatePathWorkOrders({
    page, size, route: 'api/v1/work-order',
  });
  return { page, size, ...yield get(path) };
}

function* getInitialData() {
  yield takeLatest(WORKORDER.FETCH_INITIAL_DATA, function* () {
    const response = yield requestWorkOrders();

    if (!response.error) {
      yield put(apiSuccess(WORKORDER.FETCH_INITIAL_DATA_SUCCESS, response));
      yield put(apiSuccess(APP.GET_STATE_REPLACE_FROM_API_SUCCESS, response));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: 'Error al cargar las OT, por favor intentelo más tarde.' }));
    }
  });
}

function* changePage() {
  yield takeLatest(WORKORDER.CHANGE_PAGE, function* (action) {
    const page = action.payload;
    const response = yield requestWorkOrders(page);
    if (!response.error) {
      yield put(apiSuccess(WORKORDER.CHANGE_PAGE_SUCCESS, response));
      yield put(apiSuccess(APP.GET_STATE_REPLACE_FROM_API_SUCCESS, response));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: 'Error al cargar las OT, por favor intentelo más tarde.' }));
    }
  });
}

function* fetchWithFilter() {
  yield takeLatest(WORKORDER.FETCH_DATA_WITH_FILTER, function* () {
    const page = 1;
    const response = yield requestWorkOrders(page);
    if (!response.error) {
      yield put(apiSuccess(WORKORDER.FETCH_DATA_WITH_FILTER_SUCCESS, response));
      yield put(apiSuccess(APP.GET_STATE_REPLACE_FROM_API_SUCCESS, response));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: 'Error al cargar las OT, por favor intentelo más tarde.' }));
    }
  });
}

function* clearControls() {
  yield takeLatest(WORKORDER.CLEAR_CONTROLS, function* () {
    const page = 1;
    const response = yield requestWorkOrders(page);
    if (!response.error) {
      yield put(apiSuccess(WORKORDER.CLEAR_CONTROLS_SUCCESS, response));
      yield put(apiSuccess(APP.GET_STATE_REPLACE_FROM_API_SUCCESS, response));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: 'Error al cargar las OT, por favor intentelo más tarde.' }));
    }
  });
}

function* downloadExcel() {
  yield takeLatest(WORKORDER.DOWNLOAD_EXCEL, function* () {
    const path = yield generatePathWorkOrders({
      route: 'api/v1/work-order/generate-excel',
    });
    const date = format(new Date(), 'yyyy-MM-dd');

    const response = yield downloadFileBlob(path, `Tipos_de_detencion_${date}`);

    if (!response.error) {
      yield put(apiSuccess(WORKORDER.DOWNLOAD_EXCEL_SUCCESS));
      yield put(apiSuccess(APP.SET_SUCCESS, { msg: 'Descarga exitosa' }));
    } else {
      yield put(apiSuccess(WORKORDER.DOWNLOAD_EXCEL_ERROR));
      yield put(apiSuccess(APP.SET_ERROR, { msg: 'Error en la descarga' }));
    }
  });
}

export default function* root() {
  yield spawn(getInitialData);
  yield spawn(fetchWithFilter);
  yield spawn(changePage);
  yield spawn(clearControls);
  yield spawn(downloadExcel);
}
