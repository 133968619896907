import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';
import {
  Box, TextField, useMediaQuery, useTheme,
} from '@material-ui/core';
import CustomFilterButton from 'commons/components/CustomFilterButton';
import MultipleFilter from 'commons/components/MultipleFilter';
import DialogFilter from 'commons/components/DialogFilter';
import { MobileDatePicker } from '@material-ui/pickers';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { format as fndFormat } from 'date-fns';
import TextInputFilter from 'commons/components/TextInputFilter';
import styles from './styles';

const useStyles = makeStyles(styles);

function Filter({
  controls,
  actions,
  socialReasons,
  handleFilter,
  handleClearFilter,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const handleOpenDialog = () => setIsFilterOpen(true);
  const handleCloseDialog = () => setIsFilterOpen(false);
  const handleSubmit = () => {
    handleFilter();
    handleCloseDialog();
  };

  const socialReasonMapper = (socialReason) => ({
    id: socialReason.id, value: socialReason.name, label: socialReason.name,
  });

  const optionStatus = [
    { id: 1, value: true, label: 'ACTIVO' },
    { id: 2, value: false, label: 'INACTIVO' },
  ];

  const filterFields = (
    <Grid container spacing={2} className={classes.container}>
      <Grid item xs={12} md={3} lg={3}>
        <TextInputFilter
          id="socialReason"
          label="Razón Social"
          options={socialReasons.map(socialReasonMapper)}
          value={controls.socialReason}
          onChange={actions.setFilter}
        />
      </Grid>
      <Grid item xs={12} md={3} lg={3}>
        <MultipleFilter
          id="status"
          label="Estado"
          options={optionStatus}
          value={controls.status}
          onChange={actions.setFilter}
        />
      </Grid>
      <Grid item xs={12} md={3} lg={3}>
        <MobileDatePicker
          label="Fecha de creación"
          renderInput={(props) => (
            <Box className={classes.dateContainer}>
              <TextField
                {...props}
                className={classes.inputDate}
                fullWidth
                variant="outlined"
                helperText={false}
                size="small"
                InputProps={{
                  endAdornment: (
                    <DateRangeIcon color="primary" />
                  ),
                }}
              />
            </Box>
          )}
          value={controls.date}
          onChange={(value) => {
            const format = 'yyyy/MM/dd';
            actions.setFilter({ key: 'date', value: fndFormat(value, format) });
          }}
        />
      </Grid>

      <Grid item xs={12} md={3} lg={3}>
        <CustomFilterButton
          onClick={handleSubmit}
          clear={handleClearFilter}
        />
      </Grid>
    </Grid>
  );

  if (!isMobile) return filterFields;

  return (
    <DialogFilter
      isOpen={isFilterOpen}
      open={handleOpenDialog}
      close={handleCloseDialog}
    >
      {filterFields}
    </DialogFilter>
  );
}

export default Filter;
