import { createAction, createReducer } from 'utils/reducer';

// Local constants
const PATH = 'system/';

// Global constants for saga
export const SYSTEM = {
  GET_NOTIFICATION_DATA: `${PATH}GET_NOTIFICATION_DATA`,
  GET_NOTIFICATION_DATA_FINISHED: `${PATH}GET_NOTIFICATION_DATA_FINISHED`,
  SAVE_NOTIFICATION_CONFIGURATION: `${PATH}SAVE_NOTIFICATION_CONFIGURATION`,
  SAVE_NOTIFICATION_CONFIGURATION_SUCCESS: `${PATH}SAVE_NOTIFICATION_CONFIGURATION_SUCCESS`,
  GET_WHATSAPP_DATA: `${PATH}GET_WHATSAPP_DATA`,
  GET_WHATSAPP_DATA_FINISHED: `${PATH}GET_WHATSAPP_DATA_FINISHED`,
  SAVE_WHATSAPP_CONFIGURATION: `${PATH}SAVE_WHATSAPP_CONFIGURATION`,
  SAVE_WHATSAPP_CONFIGURATION_SUCCESS: `${PATH}SAVE_WHATSAPP_CONFIGURATION_SUCCESS`,
  REMOVE_KM_OPTION: `${PATH}REMOVE_KM_OPTION`,
};

// actions
export const systemActions = {
  getNotificationData: createAction(SYSTEM.GET_NOTIFICATION_DATA),
  saveNotificationConfiguration: createAction(SYSTEM.SAVE_NOTIFICATION_CONFIGURATION, 'payload'),
  getWhatsappData: createAction(SYSTEM.GET_WHATSAPP_DATA),
  saveWhatsappConfiguration: createAction(SYSTEM.SAVE_WHATSAPP_CONFIGURATION, 'payload'),

};

const initialState = {
  controls: {
    notification: {
      kmOptions: {
        default100: false,
        default500: false,
        default1000: false,
        othersKmList: [],
      },
      monthOptions: {
        default1: false,
        default6: false,
        default12: false,
        othersMonthList: [],
      },
    },
  },
  requests: [],
  firstFetch: true,
  loading: false,
  requestLoading: false,
  error: false,
  message: '',
};

const system = createReducer(initialState, {
  [SYSTEM.GET_NOTIFICATION_DATA]: (state) => ({
    ...state,
    loading: true,
  }),
  [SYSTEM.GET_NOTIFICATION_DATA_FINISHED]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      notification: action.payload,
    },
    loading: false,
  }),
  [SYSTEM.SAVE_NOTIFICATION_CONFIGURATION]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      notification: action.payload,
    },
    loading: true,
  }),
  [SYSTEM.SAVE_NOTIFICATION_CONFIGURATION_SUCCESS]: (state) => ({
    ...state,
    loading: true,
  }),
  [SYSTEM.GET_WHATSAPP_DATA]: (state) => ({
    ...state,
    loading: true,
  }),
  [SYSTEM.GET_WHATSAPP_DATA_FINISHED]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      whatsapp: action.payload,
    },
    loading: false,
  }),
  [SYSTEM.SAVE_WHATSAPP_CONFIGURATION]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      whatsapp: action.payload,
    },
    loading: true,
  }),
  [SYSTEM.SAVE_WHATSAPP_CONFIGURATION_SUCCESS]: (state) => ({
    ...state,
    loading: true,
  }),
});

export default system;
