const styles = (theme) => ({
  back1: {
    backgroundColor: theme.palette.primary.secondary,
    marginBottom: theme.spacing(1),
    borderRadius: 8,
    minHeight: theme.spacing(10),
    padding: 8,
  },
  boxShadow: {
    boxShadow: '1px 1px #777,  -0.5px -0.5px #ddd',
  },
  btnContainer: {
    alignSelf: 'stretch',
    marginTop: theme.spacing(1),
  },
  title: {
    flex: 1,
  },
  inputContainer: {
    marginBottom: '18px',
  },
  reassignButton: {
    marginLeft: '9px',
  },
});

export default styles;
