import React, {
  useCallback, useEffect, useState, useRef,
} from 'react';

// material
import { makeStyles } from '@material-ui/core/styles';

// icons
import SearchIcon from '@material-ui/icons/Search';
// Project
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { CircularProgress, InputAdornment, TextField } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import { useHistory } from 'react-router-dom';

import { debounce } from 'utils/functions';
import styles from './styles';

const useStyles = makeStyles(styles);

const SearchField = ({
  handleGetVehicles, loadingSearch, dataVehicles, handleCloseSearch,
}) => {
  const history = useHistory();
  const [userQuery, setUserQuery] = useState('');
  const [loading, setLoading] = useState(false);

  const classes = useStyles();

  const timer = useRef();

  useEffect(() => {
    if (!loadingSearch) {
      setLoading(false);
    }
  }, [loadingSearch]);

  const getVehicles = (e) => {
    setUserQuery(e.target.value || '');
    setLoading(true);
  };

  const updateQuery = () => {
    handleGetVehicles(userQuery);
  };

  const delayedQuery = useCallback(debounce(updateQuery, 2000, timer), [userQuery]);

  useEffect(() => {
    if (userQuery?.length !== 0) {
      delayedQuery();
    } else {
      setLoading(false);
    }
    // Cancel the debounce on useEffect cleanup.
    return () => clearTimeout(timer.current);
  }, [userQuery, delayedQuery]);

  const onChange = (e, data) => {
    if (data.serial !== undefined) {
      history.push(`/vehicles/${data.serial}`);
    } else { setLoading(false); }
  };

  const markText = (text, value, id) => {
    const parts = text
      .split(new RegExp(`(${value})`, 'gi'))
      .map((c, i) => ({ c, id: i }));

    return (
      <Typography noWrap>
        {parts.map((part) => {
          if (typeof value !== 'string') return part.c;
          return part.c.toLowerCase() === value.toLowerCase()
            ? <b key={`${part.c}-${part.id}-${id}`} style={{ color: '#002855' }}>{part.c}</b>
            : part.c;
        })}
      </Typography>
    );
  };

  const filterOptions = createFilterOptions({
    trim: true,
    ignoreCase: true,
    stringify: (option) => option.label,
  });

  return (
    <Autocomplete
      fullWidth
      disableClearable
      freeSolo
      filterOptions={filterOptions}
      loading={dataVehicles?.length === 0}
      loadingText="No se encontraron resultados"
      options={userQuery?.length !== 0 ? dataVehicles : []}
      getOptionLabel={(option) => (option?.label ? option.label : '')}
      getOptionDisabled={() => loading}
      renderOption={(option) => {
        const {
          serial, model, name, rut,
        } = option;
        const settedText = [serial, model, name, rut].filter(Boolean).join(', ');
        return markText(
          settedText,
          userQuery,
          option.serial,
        );
      }}
      onInputChange={getVehicles}
      onChange={onChange}
      renderInput={(params) => (
        <TextField
          {...params}
          className={classes.inputField}
          onBlur={handleCloseSearch}
          variant="outlined"
          color="primary"
          placeholder="Patente, Modelo, Nombre Conductor, RUT Conductor"
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon fontSize="large" color="primary" />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {loading ? <CircularProgress color="primary" size={20} /> : null}
                {params.InputProps.endAdornment}
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

export default SearchField;
