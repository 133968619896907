import React from 'react';
import {
  Box, Dialog, IconButton, makeStyles, Typography, useMediaQuery,
} from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';
import ChangeDriver from 'screens/VehicleDetail/Components/ChangeDriver';

import RequestDriver from 'screens/VehicleDetail/Components/RequestDriver';
import styles from './styles';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);
const useStyles = makeStyles(styles);

const Modal = ({
  isOpen,
  close,
  isChange = true,
  drivers,
  loadingDrivers,
  loadingAssign,
  handleSelectDriver,
  currentDriver,
  handleAssignDriver,
  errorDrivers,
  handleSetIsChange,
  driverExist,
  // Request driver
  socialReason,
  onChange,
  handleSubmit,
  errors,
  controls,
  loadingRequest,
}) => {
  const classes = useStyles();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  return (
    <Dialog
      fullScreen={isMobile}
      open={isOpen}
      TransitionComponent={Transition}
    >
      <Box className={classes.dialogContainer}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          className={classes.mobileHeader}
        >
          <Typography variant="h4" color="primary">
            {isChange ? 'Cambiar conductor' : 'Solicitar nuevo conductor'}
          </Typography>
          <IconButton
            className={classes.iconButton}
            color="primary"
            onClick={close}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>

        </Box>
        <Box className={classes.divider} />
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          className={classes.content}
        >
          {isChange
            ? (
              <ChangeDriver
                drivers={drivers}
                loadingDrivers={loadingDrivers}
                loadingAssign={loadingAssign}
                onChange={handleSelectDriver}
                value={currentDriver}
                assignDriver={handleAssignDriver}
                errorDrivers={errorDrivers}
                setIsChange={handleSetIsChange}
                driverExist={driverExist}
              />
            )
            : (
              <RequestDriver
                socialReason={socialReason}
                onChange={onChange}
                handleSubmit={handleSubmit}
                errors={errors}
                controls={controls}
                loadingRequest={loadingRequest}
              />
            )}
        </Box>
      </Box>
    </Dialog>
  );
};

export default Modal;
