import { Skeleton } from '@material-ui/lab';
import React from 'react';

import { makeStyles } from '@material-ui/core';
import BackButton from 'commons/components/BackButton';
import styles from './styles';

const useStyles = makeStyles(styles);

function Scheduling() {
  const classes = useStyles();
  const [isLoading, setIsLoading] = React.useState(true);
  const handleLoadIFrame = () => {
    setIsLoading(false);
  };

  return (
    <>
      <BackButton path="/" />
      <div className={classes.container}>
        <div className={classes.iframeContainer}>
          {isLoading && <Skeleton variant="rect" width="100%" height="100%" />}
          <iframe
            src="https://salfarent.site.agendapro.com/cl"
            style={{ display: isLoading ? 'none' : 'block' }}
            onLoad={handleLoadIFrame}
            title="Portal Agendamiento"
          />
        </div>
      </div>
    </>
  );
}

export default Scheduling;
