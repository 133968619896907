import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import classNames from 'classnames';
import styles from './styles';

const useStyles = makeStyles(styles);
const LeftComponent = ({
  text, status, textEnabled, textDisabled, showStatus = true, width = null,
}) => {
  const classes = useStyles();

  return (
    <Box
      marginRight={1}
      width={width}
    >
      <Grid
        item
        sm={2}
        className={classes.textContainer}
      >
        <Typography
          component="div"
          variant="h4"
          color="primary"
          style={{ width: 230 }}
          noWrap
        >
          <Box
            component="span"
            fontStyle="normal"
            fontSize={24}
            style={{
              overflowX: 'auto',
              overflowY: 'hidden',
            }}
          >
            {text}
          </Box>
        </Typography>
        {showStatus
          ? (
            <Box display="flex" alignItems="center">
              <FiberManualRecordIcon
                className={classNames(
                  classes.statusIcon,
                  status ? classes.enabled : classes.disabled,
                )}
              />
              <Typography component="div" variant="body2" color="primary">
                <Box component="span" fontSize={10}>
                  {status ? textEnabled : textDisabled}
                </Box>
              </Typography>
            </Box>
          )
          : null }
      </Grid>
    </Box>
  );
};

export default LeftComponent;
