const styles = () => ({
  mainText: {
    fontSize: 64,
    fontWeight: 'bold',
  },
  secondaryText: {
    fontSize: 32,
    fontWeight: 'bold',
  },
  linkText: {
    fontSize: 32,
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  errorNumber: {
    fontSize: 96,
    textAlign: 'center',
    fontWeight: 'bold',
  },
});

export default styles;
