import { createAction, createReducer } from 'utils/reducer';

const PATH = 'document/';

export const DOCUMENT = {
  FETCH_LICENSE_PLATES: `${PATH}FETCH_LICENSE_PLATES`,
  FETCH_LICENSE_PLATES_SUCCESS: `${PATH}FETCH_LICENSE_PLATES_SUCCESS`,
  FETCH_LICENSE_PLATES_ERROR: `${PATH}FETCH_LICENSE_PLATES_ERROR`,
  RESET_LICENSE_PLATES: `${PATH}RESET_LICENSE_PLATES`,
  FETCH_SOCIAL_REASON: `${PATH}FETCH_SOCIAL_REASON`,
  FETCH_SOCIAL_REASON_SUCCESS: `${PATH}FETCH_SOCIAL_REASON_SUCCESS`,
  FETCH_SOCIAL_REASON_ERROR: `${PATH}FETCH_SOCIAL_REASON_ERROR`,
  RESET_SOCIAL_REASON: `${PATH}RESET_SOCIAL_REASON`,
  CHANGE_PAGE: `${PATH}CHANGE_PAGE`,
  FETCH_ZIP: `${PATH}FETCH_ZIP`,
  FETCH_ZIP_SUCCESS: `${PATH}FETCH_ZIP_SUCCESS`,
  FETCH_ZIP_ERROR: `${PATH}FETCH_ZIP_ERROR`,
  RESET_ZIP: `${PATH}RESET_ZIP`,
  FETCH_ZIP_URL: `${PATH}FETCH_ZIP_URL`,
  FETCH_ZIP_URL_SUCCESS: `${PATH}FETCH_ZIP_URL_SUCCESS`,
  FETCH_ZIP_URL_ERROR: `${PATH}FETCH_ZIP_URL_ERROR`,
};

export const documentActions = {
  getLicensePlate: createAction(DOCUMENT.FETCH_LICENSE_PLATES, 'payload'),
  resetLicensePlate: createAction(DOCUMENT.RESET_LICENSE_PLATES),
  getSocialReason: createAction(DOCUMENT.FETCH_SOCIAL_REASON, 'payload'),
  changePage: createAction(DOCUMENT.CHANGE_PAGE, 'payload'),
  getZip: createAction(DOCUMENT.FETCH_ZIP, 'payload'),
  resetZip: createAction(DOCUMENT.RESET_ZIP),
  getZipUrl: createAction(DOCUMENT.FETCH_ZIP_URL, 'payload'),
};

const initialState = {
  licensePlates: [],
  licensePlatesLoading: false,
  licensePlateError: false,
  socialReasons: [],
  socialReasonsLoading: false,
  socialReasonsError: false,
  zips: {},
  zipsLoading: false,
  zipsError: false,
  pagination: {
    page: 1,
    size: 7,
    totalPages: 20,
  },
  zipUrl: '',
  loadingZipUrl: false,
  errorZipUrl: false,
};

const document = createReducer(initialState, {
  [DOCUMENT.FETCH_LICENSE_PLATES]: (state) => ({
    ...state,
    licensePlatesLoading: true,
  }),
  [DOCUMENT.FETCH_LICENSE_PLATES_SUCCESS]: (state, action) => ({
    ...state,
    licensePlatesLoading: false,
    licensePlates: action.payload,
  }),
  [DOCUMENT.FETCH_LICENSE_PLATES_ERROR]: (state) => ({
    ...state,
    licensePlatesLoading: false,
    licensePlateError: true,
  }),
  [DOCUMENT.RESET_LICENSE_PLATES]: (state) => ({
    ...state,
    licensePlates: [],
    licensePlatesLoading: false,
    licensePlateError: false,
  }),
  [DOCUMENT.FETCH_SOCIAL_REASON]: (state) => ({
    ...state,
    socialReasonsLoading: true,
  }),
  [DOCUMENT.FETCH_SOCIAL_REASON_SUCCESS]: (state, action) => ({
    ...state,
    socialReasonsLoading: false,
    socialReasons: action.payload.objects.socialReason,
    pagination: {
      ...state.pagination,
      totalPages: action.payload.pagination.totalPages,
    },
  }),
  [DOCUMENT.FETCH_SOCIAL_REASON_ERROR]: (state) => ({
    ...state,
    socialReasonsLoading: false,
    socialReasonsError: true,
  }),
  [DOCUMENT.RESET_SOCIAL_REASON]: (state) => ({
    ...state,
    socialReasons: [],
    socialReasonsLoading: false,
    socialReasonsError: false,
    pagination: {
      ...state.pagination,
      page: 1,
    },
  }),
  [DOCUMENT.CHANGE_PAGE]: (state, action) => ({
    ...state,
    pagination: {
      ...state.pagination,
      page: action.payload,
    },
  }),
  [DOCUMENT.FETCH_ZIP]: (state) => ({
    ...state,
    zipsLoading: true,
  }),
  [DOCUMENT.FETCH_ZIP_SUCCESS]: (state, action) => ({
    ...state,
    zipsLoading: false,
    zips: action.payload,
  }),
  [DOCUMENT.FETCH_ZIP_ERROR]: (state) => ({
    ...state,
    zipsLoading: false,
    zipsError: true,
  }),
  [DOCUMENT.RESET_ZIP]: (state) => ({
    ...state,
    zips: [],
    zipsLoading: false,
    zipsError: false,
  }),
  [DOCUMENT.FETCH_ZIP_URL]: (state) => ({
    ...state,
    loadingZipUrl: true,
  }),
  [DOCUMENT.FETCH_ZIP_URL_SUCCESS]: (state, action) => ({
    ...state,
    loadingZipUrl: false,
    zipUrl: action.payload,
  }),
  [DOCUMENT.FETCH_ZIP_URL_ERROR]: (state) => ({
    ...state,
    loadingZipUrl: false,
    errorZipUrl: true,
  }),
});

export default document;
