import React from 'react';
import { Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import BuildIcon from '@material-ui/icons/Build';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import classNames from 'classnames';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import Car from '@material-ui/icons/DirectionsCarOutlined';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import { makeStyles } from '@material-ui/styles';
import { changeFormatDate } from 'utils/functions';
import styles from './styles';

const useStyles = makeStyles(styles);
const CardMobile = ({ service }) => {
  const classes = useStyles();
  const {
    externalId,
    status,
    workshopName,
    wantedDateStart,
    wantedDateEnd,
    vehicle,
  } = service;

  const { licensePlate, fleet } = vehicle;

  return (
    <Grid container className={classes.grid}>
      <Grid item xs={12}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="start"
        >
          <FiberManualRecordIcon
            className={classNames(classes.statusIcon,
              status ? classes.enabled : classes.disabled)}
          />
          <Typography component="div" color="primary" noWrap>
            <Box overflow="auto">
              <Box component="span" fontSize={14} fontWeight={600}>
                {externalId || 'No informado'}
              </Box>
              <Box component="span" fontWeight="fontWeightMedium">
                {` | ${status ? 'ABIERTO' : 'CERRADO'}`}
              </Box>
            </Box>
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={12}>
                <Box display="flex" alignItems="center" justifyContent="start">
                  <Car
                    className={classes.mobileIcon}
                  />
                  <Typography component="div" color="primary" noWrap>
                    <Box overflow="auto">
                      <Box component="span" fontSize={14} fontWeight={600}>
                        {licensePlate || 'No informado'}
                      </Box>
                      <Box component="span" fontWeight="fontWeightMedium">
                        {` | ${fleet?.name || 'Sin flota'}`}
                      </Box>
                    </Box>
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" alignItems="center" justifyContent="start">
                  <BuildIcon
                    className={classes.mobileIcon}
                  />
                  <Typography component="div" variant="body2" color="primary">
                    <Box>
                      {workshopName || 'No informado'}
                    </Box>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  component="div"
                  variant="h4"
                  color="primary"
                  noWrap
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="end"
                  >
                    <Box
                      fontStyle="normal"
                      display="flex"
                      alignItems="center"
                      fontSize={16}
                    >
                      <EventAvailableIcon color="primary" />
                      {changeFormatDate(wantedDateStart)}
                    </Box>
                  </Box>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  component="div"
                  variant="h4"
                  color="primary"
                  noWrap
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="end"
                  >
                    <Box
                      fontStyle="normal"
                      display="flex"
                      alignItems="center"
                      fontSize={16}
                    >
                      <EventBusyIcon color="primary" />
                      {changeFormatDate(wantedDateEnd)}
                    </Box>
                  </Box>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CardMobile;
