export const currencyForm = new Intl.NumberFormat('es-CL', { currency: 'CLP', style: 'currency' });
export const fileSizeLimit = 26214400;
export const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export const screenTime = (screenUrl) => {
  // Enviar evento de inicio de pantalla
  window.gtag('event', 'Inicio de Pantalla', {
    page_path: `/${screenUrl}`,
    event_label: `Carga de pantalla (${screenUrl})`,
  });

  // Devolver una función que permitirá enviar el evento de fin al descargar la pantalla
  return () => {
    window.gtag('event', 'Fin de Pantalla', {
      page_path: `/${screenUrl}`,
      event_label: `Descarga de pantalla (${screenUrl})`,
    });
  };
};
