import React, { useEffect, useState } from 'react';
import {
  Box,
  withStyles,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import ScreenTitle from 'commons/components/ScreenTitle';

import {
  composedComponent,
} from 'utils/functions';
import { appActions } from 'commons/reducer';
import { documentActions } from './reducer';
import saga from './saga';

import styles from './styles';
import InputSearch from './components/InputSearch';
import SocialReasonCard from './components/SocialReasonTable/SocialReasonCard';
import SocialReasonHeader from './components/SocialReasonTable/SocialReasonHeader';
import LicensePlateModal from './components/LicensePlateModal';
import DownloadModal from './components/DownloadModal';

function Document(props) {
  const {
    classes,
    actions,
    licensePlates,
    socialReasons,
    holding,
    pagination,
    socialReasonsLoading,
    zips,
    zipUrl,
    licensePlatesLoading,
    zipsLoading,
  } = props;

  const initialActualDocument = {
    documentId: 0,
    docName: '',
  };

  const [isOpenPlatesModal, setIsOpenPlatesModal] = useState(false);
  const [isOpenDownloadModal, setIsOpenDownloadModal] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [actualDocument, setActualDocument] = useState(initialActualDocument);

  const resetActualDocument = () => setActualDocument(initialActualDocument);

  useEffect(() => {
    actions.getSocialReason({
      holdingId: holding.id,
      page: pagination.page,
      size: pagination.size,
      searchValue,
    });
  }, [holding, pagination.page]);

  useEffect(() => {
    const getData = setTimeout(() => {
      actions.getSocialReason({
        holdingId: holding.id,
        page: pagination.page,
        size: pagination.size,
        searchValue,
      });
      actions.changePage(1);
    }, 300);

    return () => clearTimeout(getData);
  }, [searchValue]);

  const handleChangeSearchValue = (e) => {
    setSearchValue(e.target.value);
  };

  const handleClickView = (id) => () => {
    actions.getLicensePlate({ socialReasonId: id });
    setIsOpenPlatesModal(true);
  };

  const handleClickDownload = (id) => async () => {
    actions.getZip({ socialReasonId: id });
    actions.getLicensePlate({ socialReasonId: id });
    setIsOpenDownloadModal(true);
  };

  const handleClosePlates = () => {
    setIsOpenPlatesModal(false);
    actions.resetLicensePlate();
  };
  const handleCloseDownload = () => {
    actions.resetZip();
    actions.resetLicensePlate();
    setIsOpenDownloadModal(false);
  };
  useEffect(() => {
    if (!!zipUrl && zipUrl !== '') {
      fetch(zipUrl)
        .then((response) => response.blob())
        .then((myBlob) => {
          const a = document.createElement('a');
          a.href = window.URL.createObjectURL(myBlob);
          a.download = actualDocument.docName;
          a.click();
        });
      resetActualDocument();
    }
  }, [zipUrl]);
  const handleDownload = () => async (id, docName) => {
    await actions.getZipUrl({ zipId: id });
    setActualDocument({
      ...actualDocument,
      documentId: id,
      docName,
    });
    setIsOpenDownloadModal(false);
  };

  return (
    <Box className={classes.screenContainer} height={1}>
      <Box display="flex" justifyContent="space-between">
        <ScreenTitle title="DOCUMENTOS" showSubTitle={false} />
      </Box>
      <Box className={classes.bodyContainer}>
        <InputSearch handleChange={handleChangeSearchValue} />
        <SocialReasonHeader />
        {socialReasons?.map((socialReason, index) => (
          <SocialReasonCard
            index={index}
            name={socialReason.name}
            rut={socialReason.rut}
            id={socialReason.id}
            key={socialReason.id}
            handleClickView={handleClickView(socialReason.id)}
            handleClickDownload={handleClickDownload(socialReason.id)}
          />
        ))}
      </Box>
      <Box className={classes.paginationContainer}>
        <Pagination
          onChange={(e, p) => !socialReasonsLoading && actions.changePage(p)}
          hidePrevButton={pagination.page <= 1}
          hideNextButton={pagination.page >= pagination.totalPages}
          count={pagination.totalPages}
          page={pagination.page}
          color="primary"
        />
      </Box>
      <LicensePlateModal
        isOpen={isOpenPlatesModal}
        handleClose={handleClosePlates}
        vehicles={licensePlates}
        isLoading={licensePlatesLoading}
      />
      <DownloadModal
        isOpen={isOpenDownloadModal}
        handleClose={handleCloseDownload}
        documents={zips}
        handleClickDownload={handleDownload()}
        vehicles={licensePlates}
        isLoading={zipsLoading}
      />
    </Box>
  );
}
export default composedComponent(Document, saga, {
  saga: 'sagaDocument',
  middlewares: [withStyles(styles)],
  states: [
    'document.licensePlates',
    'document.socialReasons',
    'document.pagination',
    'document.socialReasonsLoading',
    'document.zips',
    'document.zipUrl',
    'document.licensePlatesLoading',
    'document.zipsLoading',
    'app.holding',
  ],
  actions: [documentActions, appActions],
});
