import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Title } from 'utils/skeletons';

import styles from '../../styles';

const useStyles = makeStyles(styles);
const VehicleDetailSkeleton = () => {
  const classes = useStyles();
  const Rect = () => {
    const reactArray = [1, 2, 3, 4, 5, 6];
    return (
      reactArray.map((r) => (
        <Grid item xs={6} md={4} align="right" key={r}>
          <Skeleton variant="rect" width="80%" height={100} />
        </Grid>
      ))
    );
  };
  return (
    <Grid
      container
      spacing={1}
    >
      <Grid item xs={12} className={classes.grid}>
        <Title width={270} />
      </Grid>
      <Grid item xs={12} md={4}>
        <Grid
          container
          spacing={3}
        >
          <Grid item xs={12}>
            <Skeleton variant="rect" width="100%" height={160} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rect" width="100%" height={80} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={8}>
        <Grid
          container
          spacing={6}
        >
          <Rect />
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.grid}>
        <Skeleton variant="rect" width="100%" height={40} />
      </Grid>
    </Grid>
  );
};

export default VehicleDetailSkeleton;
