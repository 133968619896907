import React from 'react';
import Button from '@material-ui/core/Button';
import ConfirmationDialog from 'commons/components/ConfirmationDialog';

// Material

// icons

const BtnClose = ({
  controls, actions, psId, status,
}) => {
  const handleClose = () => {
    actions.changeControls('confirmationCloseDialog', false);
  };

  const handleSubmit = () => {
    actions.closePaymentStatus(status, psId);
  };

  return (
    <>
      <ConfirmationDialog open={controls.confirmationCloseDialog} onClose={handleClose} onConfirm={handleSubmit} content="¿Desea cerrar el estado de pago?" />
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={() => {
          actions.changeControls('confirmationCloseDialog', true);
        }}
      >
        CERRAR ESTADO DE PAGO
      </Button>
    </>
  );
};

export default BtnClose;
