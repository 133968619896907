import React from 'react';
import {
  Box,
  CircularProgress, Hidden, Typography, Button, Grid, TextField,
} from '@material-ui/core';
import useForm from 'utils/hooks/useForm';
import { validateEmail } from 'utils/functions';

import bannerPerson from '../../../../commons/assets/images/bannerPerson.png';

const RecoveryForm = (props) => {
  const {
    classes, onChangeText, controls, email, loading, actions,
  } = props;

  const { onChange, onSubmit, errors } = useForm(controls, null, {
    onChange: onChangeText,
    validations: {
      email: [
        {
          check: (value) => !!value && value.trim().length > 0,
          message: 'Correo electrónico requerido',
        },
        {
          check: (value) => validateEmail(value || ''),
          message: 'Correo electrónico inválido',
        },
      ],
    },
  });

  const onClickVerifyEmail = (event) => {
    event.preventDefault();
    onSubmit(() => {
      actions.verifyEmail(controls);
    });
  };

  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Hidden mdUp>
          <Grid item xs={12}>
            <img
              src={bannerPerson}
              className={classes.imagePerson}
              alt="bannerPerson"
            />
          </Grid>
        </Hidden>
        <Grid item lg={4} md={5} xs={12}>
          <form onSubmit={onClickVerifyEmail}>
            <Grid container>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="center">
                  <Box className={classes.item}>
                    <Typography variant="caption" color="primary" noWrap>
                      <Box className={classes.customTitle}>Recuperar contraseña</Box>
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="center">
                  <Box className={classes.item}>
                    <TextField
                      className={classes.field}
                      autoFocus
                      fullWidth
                      id="email"
                      key="email"
                      type="email"
                      label="Ingresar Email"
                      onChange={onChange}
                      variant="outlined"
                      value={email}
                      error={errors.email}
                      helperText={errors.email && errors.email[0]}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="center">
                  <Box className={classes.item}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="secondary"
                      className={classes.button}
                    >
                      {loading ? <CircularProgress /> : 'Ingresar'}
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Grid>
        <Grid item lg={8} md={7} className={classes.grid}>
          <Hidden smDown>
            <Typography className={classes.imageOverlayText}>
              <p>Expertos en mantener</p>
              <p>tu flota operacional</p>
            </Typography>
          </Hidden>
          <Hidden smDown>
            <img
              src={bannerPerson}
              className={classes.bannerPerson}
              alt="bannerPerson"
            />
          </Hidden>
        </Grid>
      </Grid>
    </>
  );
};

export default RecoveryForm;
