const styles = (theme) => ({
  container: {
    marginTop: theme.spacing(2),
  },
  buttonIndicator: {
    marginTop: theme.spacing(2),
    backgroundColor: '#fff',
    color: theme.palette.primary.main,
    '&  > *': {
      justifyContent: 'space-between',
    },
  },
  indicator: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(0),
    },
  },
  boxIndicator: {
    padding: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4),
    },
  },
});

export default styles;
