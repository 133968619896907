const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    boxShadow: theme.shadows[5],
  },
  spaceBetween: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
});

export default styles;
