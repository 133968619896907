const styles = (theme) => ({

  fileName: {
    '& .Mui-disabled': {
      color: theme.palette.primary.main,
    },
  },
  containerDocuments: {
    paddingTop: 16,
    paddingBottom: 16,
  },
});

export default styles;
