import React from 'react';

import { Box, Typography } from '@material-ui/core';

const LateMaintenanceText = () => (
  <>
    <Typography color="primary" noWrap>
      <Box fontSize={12} fontWeight={700}>
        Kilometraje superior:
      </Box>
      <Box fontSize={12}>
        Próxima mantención
      </Box>
      <Box fontSize={12} fontWeight={700}>
        Kilometraje inferor:
      </Box>
      <Box fontSize={12}>
        Actual
      </Box>
    </Typography>
  </>
);

export default LateMaintenanceText;
