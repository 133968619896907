import { Box, makeStyles } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import React from 'react';
import styles from './styles';

const useStyles = makeStyles(styles);

function ReportsPagination({
  handleChange, pagination, isMobile,
}) {
  const classes = useStyles();
  const { totalPages, page } = pagination;

  if (!totalPages || totalPages <= 1) {
    return null;
  }

  return (
    <Box display="flex" justifyContent="center" alignItems="end" className={classes.paginationContainer}>
      <Pagination
        onChange={(e, p) => handleChange(p)}
        hidePrevButton={!page}
        count={totalPages}
        page={page}
        color="primary"
        size={isMobile ? 'small' : 'large'}
      />
    </Box>
  );
}

export default ReportsPagination;
