import React from 'react';
import notificationType from 'utils/notificationType';
import { Box, Card } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { makeStyles } from '@material-ui/core/styles';
import { formatNotificationTime } from 'utils/functions';
import styles from './styles';

const useStyles = makeStyles(styles);

const CardNotification = ({
  data, maxWidth, handleClick, handleClose,
}) => {
  const classes = useStyles();

  const {
    idUserNotification, read, type, value, valueClick, extraValue, holdingName, holdingId,
  } = data;
  const { icon, title, subTitle } = notificationType[type](value, extraValue, holdingName);
  const holding = holdingId ? { name: holdingName, id: holdingId } : null;

  const handleClickRead = () => {
    handleClick(valueClick, idUserNotification, 'read', read, type, holding);
    handleClose();
  };

  const handleClickDeleted = () => {
    handleClick(valueClick, idUserNotification, 'deleted', read, type);
  };

  return (
    <Box
      p={1}
      maxWidth={maxWidth}
      className={classes.box}
      boxShadow="48px"
    >
      <Card style={{ backgroundColor: read ? 'fff' : '#d9dfe5' }}>
        <CardContent>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={3} sm={2}>
              <IconButton
                color="primary"
                className={classes.icon}
                onClick={handleClickRead}
              >
                {icon}
              </IconButton>
            </Grid>
            <Grid item xs={7} sm={8} onClick={handleClickRead}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography className={classes.title}>
                    {title}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.subTitle}>
                    {subTitle}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.time}>
                    {formatNotificationTime(data.createdAt)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2} sm={2} align="right">
              {type !== 'DRIVER_REQUEST' && (
              <IconButton
                color="primary"
                className={classes.icon}
                onClick={handleClickDeleted}
              >
                <CloseRoundedIcon className={classes.iconRight} />
              </IconButton>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default CardNotification;
