const styles = (theme) => ({
  filterButton: {
    backgroundColor: '#fff',
    color: theme.palette.primary.main,
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  exteriorSection: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(-8),
    marginRight: theme.spacing(-8),
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(-2),
      marginRight: theme.spacing(-2),
    },
  },
  insideSection: {
    marginLeft: theme.spacing(8),
    marginRight: theme.spacing(8),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },

});

export default styles;
