const styles = (theme) => ({

  kpiContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  maintenanceContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    borderRadius: 16,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginRight: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(0),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    [theme.breakpoints.up('md')]: {
      paddingBottom: theme.spacing(0),
    },
  },
  outOfDateContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    borderRadius: 16,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(4),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    [theme.breakpoints.up('md')]: {
      paddingBottom: theme.spacing(0),
    },
  },
  maintenanceFirstRow: {
    fontSize: 28,
  },
  maintenanceSecondRow: {
    fontSize: 10,
    [theme.breakpoints.up('sm')]: {
      fontSize: 20,
    },
  },
  indicator: {
    // display: 'flex',
    alignItems: 'center',
    // justifyContent: 'center',
    // marginTop: theme.spacing(2),
    // marginBottom: theme.spacing(6),
    width: '100%',
    height: '100%',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    padding: theme.spacing(1),

  },
  boxIndicator: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
});

export default styles;
