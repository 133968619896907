import {
  Grid, Typography, makeStyles, Box,

} from '@material-ui/core';
import React from 'react';
import BuildOutlinedIcon from '@material-ui/icons/BuildOutlined';
import Card from '@material-ui/core/Card';
import Hidden from '@material-ui/core/Hidden';

import CardContent from '@material-ui/core/CardContent';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { useHistory } from 'react-router-dom';
import {
  formatDateForCards, numberFormatter,
} from 'utils/functions';
import moment from 'moment/moment';
import VehicleDetailCardsMobile from './mobile';

import styles from '../../styles';

const useStyles = makeStyles(styles);

export default function VehicleDetailCards({ vehicle, actions }) {
  const {
    licensePlate, brand, model, year, km, deliveryDate, color, totalServices,

  } = vehicle;

  const duration = vehicle['VehicleTariff.duration'];
  const expirationDate = deliveryDate && duration ? moment(deliveryDate).add(duration, 'months').format('YYYY-MM-DD') : null;
  const { goToServices } = actions;
  const replacementHours = vehicle['VehicleTariff.replacementHours'];

  const classes = useStyles();
  const history = useHistory();

  function oneRowCardGenerator(content) {
    const {
      title, body, date, outOfDate,
    } = content;
    return (
      <Card>
        <CardContent className={classes.cardContent}>
          <Grid item xs={12} className={classes.cardContentGrid}>
            {date ? <CalendarTodayIcon color={outOfDate ? 'secondary' : 'primary'} className={classes.calendarIcon} /> : null}
            <Typography className={classes.cardContentTypography} color={outOfDate ? 'secondary' : 'primary'}>{title}</Typography>
          </Grid>
          <Typography color={outOfDate ? 'secondary' : 'primary'}>{body}</Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Grid container className={classes.mainContainerTopSection}>
      <Grid
        item
        container
        justifyContent="space-between"
      >
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <Grid
            container
            direction="column"
          >
            <Grid>
              <Card elevation={5}>
                <CardContent className={classes.cardContent}>
                  <Typography className={classes.cardContentTypography2} color="primary">{licensePlate || 'No informado'}</Typography>
                  <Typography color="primary">
                    {brand || 'No informado'}
                    {' '}
                    -
                    {' '}
                    {model || 'No informado'}
                  </Typography>
                  <Typography color="primary">
                    Año
                    {' '}
                    {year || 'No informado'}
                    {' '}
                    -
                    {' '}
                    {color || 'No informado'}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid className={classes.cardContentGrid2}>
              <Card
                elevation={5}
                className={classes.card}
                onClick={() => totalServices && goToServices({ history })}
              >
                <CardContent className={classes.cardContent2}>
                  <Typography
                    className={classes.cardContentTypography3}
                    color="primary"
                  >
                    {totalServices || 0}
                  </Typography>
                  <Grid item xs={12} className={classes.grid2}>
                    <BuildOutlinedIcon className={classes.buildIcon} />
                    <Typography variant="h6">
                      <Box color="white">
                        Detenciones
                      </Box>
                    </Typography>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Hidden xsDown>
          <Grid item xs={5} sm={5} md={3} lg={2}>
            <Grid
              container
              direction="column"
            >
              <Grid item>
                {oneRowCardGenerator({
                  title: '-', body: '-', date: false, outOfDate: false,
                })}
              </Grid>
              <Grid item className={classes.gridRowCard}>
                {oneRowCardGenerator({ title: (deliveryDate) ? formatDateForCards(deliveryDate) : 'No informado', body: 'Entrega', date: true })}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={2}>
            <Hidden smDown>
              <Grid
                container
                direction="column"
                justifyContent="space-between"
              >
                <Grid item>
                  {oneRowCardGenerator({ title: `${numberFormatter(km) || '- '} Km`, body: 'Kilometraje' })}
                </Grid>
                <Grid item className={classes.gridRowCard}>
                  {oneRowCardGenerator({
                    title: (expirationDate) ? formatDateForCards(expirationDate) : 'No informado',
                    body: 'Vencimiento',
                    date: true,
                    outOfDate: (expirationDate) ? new Date(expirationDate) <= new Date() : false,
                  })}
                </Grid>
              </Grid>
            </Hidden>
            <Hidden mdUp>
              <Grid
                container
                direction="row"
                justifyContent="space-evenly"
                className={classes.cardContentGrid2}
              >
                <Grid item xs={12} sm={5}>
                  {oneRowCardGenerator({ title: `${numberFormatter(km) || '- '} Km`, body: 'Kilometraje' })}
                </Grid>
                <Grid item xs={12} sm={5}>
                  {oneRowCardGenerator({
                    title: (expirationDate) ? formatDateForCards(expirationDate) : 'No informado',
                    body: 'Vencimiento',
                    date: true,
                    outOfDate: (expirationDate) ? new Date(expirationDate) <= new Date() : false,
                  })}
                </Grid>
              </Grid>
            </Hidden>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={2}>
            <Hidden mdDown>
              <Grid
                container
                direction="column"
                justifyContent="space-between"
              >
                <Grid item>
                  {oneRowCardGenerator({ title: `${replacementHours || 0} Hrs.`, body: 'Horas de reemplazo' })}
                </Grid>
                <Grid item className={classes.gridRowCard} />
              </Grid>
            </Hidden>
            <Hidden lgUp>
              <Grid
                container
                direction="row"
                justifyContent="space-evenly"
                className={classes.cardContentGrid2}
              >
                <Grid item xs={12} sm={5}>
                  {oneRowCardGenerator({ title: `${replacementHours || 0} Hrs.`, body: 'Horas de reemplazo' })}
                </Grid>
                <Grid item xs={12} sm={5} />
              </Grid>
            </Hidden>
          </Grid>
        </Hidden>
        <Hidden smUp>
          <Grid container>
            <VehicleDetailCardsMobile vehicle={vehicle} />
          </Grid>
        </Hidden>
      </Grid>
      <Grid />
    </Grid>
  );
}
