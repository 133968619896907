import { alpha } from '@material-ui/core/styles/colorManipulator';

const styles = (theme) => ({
  tooltip: {
    maxWidth: ({ width }) => (width || 300),
    backgroundColor: 'white',
    border: `1px solid ${theme.palette.primary.main}`,
  },
  arrow: {
    color: alpha(theme.palette.primary.main, 0.2),
  },
});

export default styles;
