const NEWSLETTER_PAGINATION_HEIGHT = '85px';

const styles = () => ({
  container: {
    height: '600px',
  },
  iframeContainer: {
    width: '100%',
    height: '100%',
    '& iframe': {
      width: '100%',
      height: '100%',
    },
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
  },
  button: {
    display: 'block',
    margin: '16px auto',
  },
  icon: {
    height: NEWSLETTER_PAGINATION_HEIGHT,
    padding: 0,
    backgroundColor: '#ddd',
    '&:hover': {
      backgroundColor: '#eee',
    },
  },
  pagination: {
    backgroundColor: '#ccc',
    marginTop: '16px',
  },
  emptyItem: {
    width: '20%',
    height: NEWSLETTER_PAGINATION_HEIGHT,
    borderLeft: '1px solid #777',
  },
  paginationItem: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    height: NEWSLETTER_PAGINATION_HEIGHT,
    borderLeft: '1px solid #777',
    '&:hover': {
      backgroundColor: '#eee',
    },
  },
  paginationItemDisabled: {
    width: '20%',
    height: NEWSLETTER_PAGINATION_HEIGHT,
    borderLeft: '1px solid #777',
    backgroundColor: '#999',
  },
  paginationItemSelected: {
    backgroundColor: '#eee',
  },
  paginationItemText: {
    marginTop: '8px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    cursor: 'default',
    textAlign: 'center',
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#333',
  },
  paginationItemActions: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    marginTop: '8px',
  },
  paginationArrow: {
    margin: 0,
    padding: 0,
  },
  title: {
    flex: 1,
  },
  inputContainer: {
    marginBottom: '24px',
  },
  buttonContainer: {
    marginRight: '12px',
  },
  overflowEllipsis: {
    padding: '0 4px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: '1.2',
  },
  lineHeight: {
    lineHeight: '1.2',
  },
});

export default styles;
