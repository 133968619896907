import { Box } from '@material-ui/core';
import React from 'react';

// Material
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import styles from './styles';

const useStyles = makeStyles(styles);

const GeneralCard = ({
  leftComponent = null,
  rightComponent = null,
  justifyContent = 'space-between',
  children,
  className = {},
  onClick = () => {},
  renderBelow = null,
}) => {
  const classes = useStyles();

  return (
    <Box
      className={classNames(classes.root, className)}
      onClick={onClick}
    >
      <Box
        display="inline-flex"
        alignItems="center"
        justifyContent={justifyContent}
      >
        {leftComponent || null}
        {children}
        {rightComponent || null}
      </Box>
      {renderBelow || null}
    </Box>
  );
};

export default GeneralCard;
