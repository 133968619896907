import React from 'react';

import { REPORTS_KEYS, REPORTS_LABELS } from 'screens/ReportsMaintainer/constants';
import Header from './header';
import ReportsList from './list';
import Filter from '../Filter';
import Pagination from './pagination';

const headers = [
  {
    label: REPORTS_LABELS.PUBLISH_DATE,
    key: REPORTS_KEYS.PUBLISH_DATE,
    width: 200,
    center: true,
    canOrder: true,
  },
  {
    label: REPORTS_LABELS.NAME, key: REPORTS_KEYS.NAME, width: 260, center: true, canOrder: true,
  },
  {
    label: REPORTS_LABELS.URL, key: REPORTS_KEYS.URL, width: 200, center: true, canOrder: false,
  },
];

const getDataCard = (report) => [
  {
    key: REPORTS_KEYS.PUBLISH_DATE,
    title: 'Fecha Publicación',
    row: `${report?.createdAt}`,
    width: 200,
    center: true,
  },
  {
    key: REPORTS_KEYS.NAME,
    title: 'Nombre',
    row: report.name,
    width: 260,
    center: true,
  },
  {
    key: REPORTS_KEYS.URL,
    title: 'URL',
    row: report.url,
    width: 200,
    isUrl: true,
  },
];

function ReportsTable({
  reports, allReports, actions, controls, total, pagination, holdings, users, loadingUsers, orderBy,
}) {
  const handleChangePage = (page) => {
    actions.changePage(page);
  };
  const { page } = pagination;

  return (
    <>
      <Filter
        controls={controls}
        actions={actions}
        reports={allReports}
        handleFilter={() => { actions.applyFilters(); }}
        handleClearFilter={() => actions.resetFilters()}
      />
      <Header actions={actions} controls={controls} headers={headers} orderByValue={orderBy} />
      <ReportsList
        reports={reports}
        total={total}
        users={users}
        page={page}
        getDataCard={getDataCard}
        holdings={holdings}
        actions={actions}
        loadingUsers={loadingUsers}
      />
      <Pagination pagination={pagination} handleChange={handleChangePage} />
    </>
  );
}

export default ReportsTable;
