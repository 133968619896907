// @ts-nocheck
import React from 'react';
// material

import { MobileDatePicker } from '@material-ui/pickers';
// utils
import { format as fndFormat } from 'date-fns';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import DateRangeIcon from '@material-ui/icons/DateRange';
import styles from './styles';

// Icons

const useStyles = makeStyles(styles);

function DateInput({
  label, value, handleInput, fullWidth, showFormat = 'yyyy/MM/dd', format = 'yyyy/MM/dd',
}) {
  const classes = useStyles();
  return (

    <MobileDatePicker
      clearable
      fullWidth={fullWidth || false}
      InputProps={{
        endAdornment: (
          <DateRangeIcon color="primary" />
        ),
      }}
      value={value || null}
      label={label || 'Fecha'}
      onChange={(v) => (v ? handleInput(fndFormat(v, format)) : handleInput(v))}
      inputFormat={showFormat}
      renderInput={(props) => (
        <TextField
          fullWidth={fullWidth || false}
          variant="outlined"
          className={classes.input}
          {...props}
        />
      )}
    />

  );
}

export default DateInput;
