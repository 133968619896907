import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

// material
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Box, useMediaQuery } from '@material-ui/core';

// Icons
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';

// Project
import Menu from 'commons/components/NavBar/components/Menu';
import { composedComponent } from 'utils/functions';
import { appActions } from 'commons/reducer';
import type from 'utils/type';
import Notification from 'commons/components/NavBar/components/Notification';
import saga from './saga';
import { navbarActions } from './reducer/index';

import styles from './styles';
import logo from '../../assets/images/salfa-rent-logo.png';
import logoReduced from '../../assets/images/salfa-rent-logo-reduced.png';

import Admin from './components/Admin';
import SearchField from '../SearchField';

const useStyles = makeStyles(styles);

const NavBar = (props) => {
  const {
    toggleDrawer,
    user,
    handleGetVehicles,
    dataVehicles,
    loadingSearch,
    search,
    openSearch,
    closeSearch,
    actions,
    openHolding,
    socialReasons,
    socialReason,
    holdings, // Objects.holdings
    holding, // current holding
    logOut,
    notifications,
    totalNotification,
    executive,
    handleClickNotification,
  } = props;
  const classes = useStyles(props);
  const history = useHistory();
  const screenMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const rol = user.user.type;

  useEffect(() => {
    actions.getHoldings();
    actions.getUserReports();
  }, []);

  useEffect(() => {
    if (holding) {
      actions.getSocialReason(holding.id);
    }
  }, [holding]);

  const handleOpenSearch = () => {
    openSearch();
  };

  const handleCloseSearch = () => {
    closeSearch();
  };

  const holdingsData = useMemo(() => Object.values(holdings)
    .filter((h) => h?.isActive)
    .map((h) => ({
      label: `${h.name}, ${h.rut}`,
      value: h.id,
    })), [holdings]);

  return (
    <AppBar
      position="fixed"
      color="inherit"
    >
      <Toolbar>
        <IconButton
          onClick={toggleDrawer}
          edge="start"
          className={classes.menuButton}
          color="primary"
          aria-label="menu"
        >
          <MenuIcon />
        </IconButton>
        <IconButton onClick={() => history.push('/')} className={classes.bigIcon}>
          <img src={logo} alt="" />
        </IconButton>
        <IconButton onClick={() => history.push('/')} className={classes.reducedIcon}>
          <img style={{ height: '35px', transform: 'scale(0.8)' }} src={logoReduced} alt="" />
        </IconButton>

        {(!screenMobile || search)
        && (
        <SearchField
          loadingSearch={loadingSearch}
          dataVehicles={dataVehicles}
          handleGetVehicles={handleGetVehicles}
          handleCloseSearch={handleCloseSearch}
        />
        )}

        <IconButton
          className={classes.searchIcon}
          onClick={handleOpenSearch}
          style={{ display: search ? 'none' : '' }}
        >
          <SearchIcon
            color="primary"
            fontSize="large"
          />
        </IconButton>

        {/* Separation Left / Right */}
        <Typography variant="h6" className={classes.title} />

        {/* Quote */}
        {(rol === type.GESTOR_DE_FLOTA_DECISOR && rol === type.ENCARGADO_DE_FLOTA_SECCION)
        && (
          !search && (
          <Button
            variant="contained"
            color="primary"
            className={classes.renewButton}
            onClick={() => history.push('/quote')}
          >
            <Typography component="div">
              <Box whiteSpace="nowrap" color="white" fontWeight={700}>
                COTIZA / RENUEVA
              </Box>
            </Typography>
          </Button>
          )
        )}

        {/* assigned executive */}
        {(rol === type.GESTOR_DE_FLOTA_DECISOR || rol === type.ENCARGADO_DE_FLOTA_SECCION)
        && (
        <Admin
          executive={executive}
          role={rol}
        />
        )}

        {/* Notifications */}
        {(rol === type.GESTOR_DE_FLOTA_DECISOR
            || rol === type.ADMINISTRADOR_DE_CONTRATO || rol === type.ENCARGADO_DE_FLOTA_SECCION)
        && (
          <Notification
            notifications={notifications}
            totalNotification={totalNotification}
            handleClickNotification={handleClickNotification}
            user={user}
            actions={actions}
          />
        )}
        <Menu
          email={user.user?.email}
          isOpen={openHolding}
          open={actions.openModal}
          close={actions.closeModal}
          setHolding={actions.setHolding}
          setSocialReason={actions.setSocialReason}
          holdings={holdingsData}
          socialReasons={socialReasons}
          currentHoldingId={holding?.id}
          currentSocialReason={socialReason}
          getSocialReason={actions.getSocialReason}
          logOut={logOut}
        />

      </Toolbar>
    </AppBar>

  );
};

export default composedComponent(NavBar, saga, {
  states: [
    'app.objects.holdings',
    'app.objects.socialReasons',
    'app.holding',
    'app.socialReason',
    'app.modals.openHolding',
  ],
  actions: [navbarActions, appActions],
  saga: 'navbarSaga',
});
