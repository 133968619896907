/* eslint-disable no-use-before-define */
export const formatRut = (rut) => {
  if (!rut) return '';
  if (rut.length > 12) return rut.substring(0, 12);

  const rutPattern = /(\d{1,3})(\d{3})(\d{3})(\d{1}|K)/i;
  const numPattern = /\B(?=(\d{3})+(?!\d))/g;
  const formattedText = rut.replace(/[^\dk]/gi, '');

  if (rutPattern.test(formattedText)) {
    return formattedText.replace(rutPattern, '$1.$2.$3-$4').toUpperCase();
  }

  return formattedText.replace(/k/g, '').replace(numPattern, '.').toUpperCase();
};

export const isValidRut = (rut) => {
  const rutPattern = /^\d{1,3}\.?\d{3}\.?\d{3}-(\d|K)$/i;
  if (!rutPattern.test(rut)) {
    return false;
  }

  const rutNumbers = rut.replace(/\./g, '').split('-')[0];
  const verificationDigit = rut.split('-')[1].toUpperCase();
  const expectedVd = getExpectedVerificationDigit(rutNumbers);

  if (expectedVd !== verificationDigit) {
    return false;
  }

  return true;
};

const getExpectedVerificationDigit = (numbers) => {
  let sum = 0;
  let mul = 2;

  // eslint-disable-next-line no-plusplus
  for (let i = 1; i <= numbers.length; i++) {
    sum += parseInt(numbers.charAt(numbers.length - i), 10) * mul;
    if (mul < 7) {
      mul += 1;
    } else {
      mul = 2;
    }
  }
  const res = sum % 11;

  if (res === 0) return '0';
  if (res === 1) return 'K';

  return `${11 - res}`;
};
