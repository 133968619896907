import React from 'react';
import { Button, Grid } from '@material-ui/core';
import type from 'utils/type';

const ClosePreviousPsButton = ({ actions, role }) => {
  if (role !== type.ADMINISTRADOR_DE_CONTRATO
    && role !== type.ADMINISTRADOR_PORTAL_SALFA) {
    return null;
  }

  return (
    <Grid container justifyContent="flex-end" spacing={2}>
      {role === type.ADMINISTRADOR_PORTAL_SALFA && (
        <Grid item>
          <Button
            onClick={() => actions.changeControls({ key: 'confirmationCloseActualPsDialog', value: true })}
            color="secondary"
            variant="outlined"
          >
            CERRAR ESTADOS MES ACTUAL
          </Button>
        </Grid>
      )}
      <Grid item>
        <Button
          onClick={() => actions.changeControls({ key: 'confirmationClosePreviousPsDialog', value: true })}
          color="secondary"
          variant="outlined"
        >
          CERRAR ESTADOS MESES ANTERIORES
        </Button>
      </Grid>
    </Grid>
  );
};

export default ClosePreviousPsButton;
