import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Skeleton } from '@material-ui/lab';
import Typography from '@material-ui/core/Typography';

const FilterSkeleton = () => (
  <Grid container>
    <Grid item xs={12}>
      <Typography component="div" variant="h4">
        <Skeleton width={370} />
      </Typography>
      <Typography component="div" variant="h4">
        <Skeleton width={370} />
      </Typography>
      <Typography component="div" variant="h4">
        <Skeleton width={370} />
      </Typography>
    </Grid>
  </Grid>
);

export default FilterSkeleton;
