const styles = (theme) => ({
  container: {
    overflow: 'hidden',
  },
  card: {
    display: 'flex',
    width: '50%',
    justifyContent: 'center',
  },
  mobileLoginContainer: {
    paddingLeft: '16px',
  },
  item: {
    maxWidth: 400,
    width: '80%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  field: {
    width: '80%',
    maxWidth: 400,
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    '& .MuiInputBase-root': {
      color: theme.palette.primary.main,
    },
    '& .MuiFormLabel-root': {
      color: theme.palette.primary.main,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.primary.main,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.primary.main,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
      },
    },
  },
  customTitle: {
    fontSize: '2rem',
    textAlign: 'left',
    marginBottom: '5px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      textAlign: 'center',
    },
  },
  inputs: {
    display: 'flex',
    flexDirection: 'column',
    width: '70%',
    marginTop: '5%',
  },
  image: {
    width: '280px',
    marginLeft: 'auto',
    marginRight: 'auto',
    objectFit: 'cover',
    maxWidth: '100%',
  },
  loading: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '30px',
    marginBottom: '50px',
  },
  textfield: {
    marginTop: '10px',
  },
  button: {
    marginTop: '16px',
    width: '100%',
    height: '50px',
    maxWidth: 400,
    margin: 'auto',
  },
  chip: {
    '& .MuiChip-label': {
      fontWeight: 'bold',
    },
    borderRadius: 4,
    position: 'absolute',
  },
  banner: {
    height: '30vh',
    objectFit: 'cover',
    width: '100%',
  },
  banner2: {
    height: '100vh',
    width: '100%',
    objectFit: 'cover',
  },
  banner2Container: {
    [theme.breakpoints.up('md')]: {
      height: '100vh',
    },
  },
  paragraph: {
    fontSize: '3rem',
    fontWeight: 800,
  },
  paragraph2: {
    fontSize: '3rem',
    fontWeight: 800,
    textDecoration: 'underline',
    textDecorationColor: 'red',
    textDecorationThickness: '10px',
    textDecorationSkipInk: 'none',
  },
  bannerSalfaLogo: {
    objectFit: 'cover',
    marginTop: '10px',
  },
  div: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: 32,
  },
  typography: {
    position: 'absolute',
    paddingTop: '160vh',
    color: '#fff',
    lineHeight: '10px',
  },
  box: {
    paddingRight: 128,
  },
  typography2: {
    color: 'red',
  },
  link: {
    color: 'inherit',
    textDecoration: 'inherit',
  },
});

export default styles;
