import {
  Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, makeStyles,
} from '@material-ui/core';
import React, { useEffect, useReducer, useState } from 'react';
// Icons
import CloseIcon from '@material-ui/icons/Close';

import ButtonWithLoader from 'commons/containers/ButtonWithLoader';
import { formatRut, isValidRut } from 'utils/rut';
import CustomTextField from 'commons/components/CustomTextField';
import styles from './styles';
import DeactivateDialog from './DeactivateDialog';
import ReassignHoldingDialog from './ReassignHoldingDialog';

const SET_INPUT_1 = 'SET_INPUT_1';
const SET_INPUT_2 = 'SET_INPUT_2';
const SET_INPUT_3 = 'SET_INPUT_3';
const RESET_STATE = 'RESET_STATE';
const SET_ERRORS = 'SET_ERRORS';

function reducer(state, action) {
  if (action.type === RESET_STATE) return { ...action.payload };
  if (action.type === SET_INPUT_1) return { ...state, value1: action.payload };
  if (action.type === SET_INPUT_2) return { ...state, value2: action.payload };
  if (action.type === SET_INPUT_3) return { ...state, value3: formatRut(action.payload) };
  if (action.type === SET_ERRORS) {
    return {
      ...state,
      errors:
    { ...state.errors, ...action.payload },
    };
  }
  throw Error('Unknown action.');
}

const useStyles = makeStyles(styles);

const ActionDialog = ({
  isOpen,
  title,
  inputsData,
  onSubmit,
  onClose,
  onReassignHolding,
  hasActiveSR,
  isHolding = false,
  isEditing = false,
  holdings,
}) => {
  const {
    value1, label2, value2, label3, value3, isActive,
  } = inputsData;
  const [state, dispatch] = useReducer(reducer, {
    value1, value2, value3: formatRut(value3), errors: {},
  });
  const [isDeactiveDialogOpen, setIsDeactiveDialogOpen] = useState(false);
  const [isReassignHoldingDialogOpen, setIsReassignHoldingDialogOpen] = useState(false);
  const classes = useStyles();

  const validateInput = (label, value, isRut = false) => {
    dispatch({ type: SET_ERRORS, payload: { [label]: '' } });
    if (!value) {
      dispatch({ type: SET_ERRORS, payload: { [label]: 'Campo vacío' } });
      return false;
    }
    if (isRut && !isValidRut(value)) {
      dispatch({ type: SET_ERRORS, payload: { [label]: 'RUT no válido' } });
      return false;
    }
    return true;
  };

  const handleClose = () => {
    onClose();
  };
  const handleInputChange = (actionType) => (e) => {
    dispatch({ type: actionType, payload: e.target.value });
  };
  const handleSubmit = () => {
    const args = isEditing
      ? [state.value1, state.value2, state.value3, isActive] : [state.value2, state.value3];
    if (!validateInput(label2, state.value2) || !validateInput(label3, state.value3, true)) {
      validateInput(label3, state.value3, true);
      return;
    }
    onSubmit(handleClose, ...args);
  };
  const handleClickDeactivateButton = () => {
    if (isActive && isHolding && hasActiveSR) {
      setIsDeactiveDialogOpen(true);
      return;
    }
    // eslint-disable-next-line no-use-before-define
    handleToggleIsActivate();
  };
  const handleToggleIsActivate = () => {
    const args = [value1, value2, value3, !isActive];
    onSubmit(handleClose, ...args);
  };
  const handleOpenReassignHoldingDialog = () => {
    setIsReassignHoldingDialogOpen(true);
  };
  const handleReassignHolding = (holdingId, close) => {
    onReassignHolding({
      socialReasonId: value1,
      holdingId,
      handleClose: () => {
        close();
        handleClose();
      },
    });
  };

  useEffect(() => {
    if (isOpen) {
      dispatch({
        type: RESET_STATE,
        payload: {
          value1, value2, value3: formatRut(value3), errors: {},
        },
      });
    }
  }, [isOpen]);

  return (
    <Dialog
      open={isOpen}
      fullWidth
    >
      <DialogTitle>
        <Grid container alignItems="center">
          <Grid item className={classes.title}>
            {title}
          </Grid>
          <Grid item>
            <IconButton
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {isEditing && (
        <Grid container className={classes.inputContainer}>
          <CustomTextField
            disabled
            id="1"
            autoFocus
            color="primary"
            value={state.value1}
            onChange={handleInputChange(SET_INPUT_1)}
            required
            label="ID"
            variant="outlined"
            error={false}
            helperText=""
          />
        </Grid>
        )}
        <Grid container className={classes.inputContainer}>
          <CustomTextField
            id="2"
            autoFocus
            color="primary"
            value={state.value2}
            onChange={handleInputChange(SET_INPUT_2)}
            required
            label={label2.toUpperCase()}
            variant="outlined"
            error={!!state.errors[label2]}
            helperText={state.errors[label2]}
          />
        </Grid>
        <Grid container className={classes.inputContainer}>
          <CustomTextField
            id="3"
            autoFocus
            disabled={isEditing}
            color="primary"
            value={state.value3}
            onChange={handleInputChange(SET_INPUT_3)}
            required
            label={'Rut'.toUpperCase()}
            variant="outlined"
            error={!!state.errors[label3]}
            helperText={state.errors[label3]}
          />
        </Grid>
        {isEditing && (
        <Grid container className={classes.inputContainer}>
          <ButtonWithLoader
            buttonId="deactivate"
            variant="contained"
            onClick={handleClickDeactivateButton}
          >
            {isActive ? 'Deshabilitar' : 'Habilitar'}
          </ButtonWithLoader>
          {!isHolding && (
            <>
              <ButtonWithLoader
                buttonId="reassign"
                variant="contained"
                color="secondary"
                className={classes.reassignButton}
                onClick={handleOpenReassignHoldingDialog}
              >
                Reasignar holding
              </ButtonWithLoader>
              <ReassignHoldingDialog
                isOpen={isReassignHoldingDialogOpen}
                onClose={() => setIsReassignHoldingDialogOpen(false)}
                title="Reasignar holding"
                holdings={holdings}
                onSubmit={handleReassignHolding}
              />
            </>
          )}
          <DeactivateDialog
            isOpen={isDeactiveDialogOpen}
            action={handleToggleIsActivate}
            onClose={() => setIsDeactiveDialogOpen(false)}
          />
        </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <div className={classes.buttonContainer}>
          <ButtonWithLoader
            buttonId="cancel"
            variant="outlined"
            onClick={handleClose}
          >
            Cancelar
          </ButtonWithLoader>
        </div>
        <div className={classes.buttonContainer}>
          <ButtonWithLoader
            buttonId="create-fleet"
            onClick={handleSubmit}
          >
            {title}
          </ButtonWithLoader>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default ActionDialog;
