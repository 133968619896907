import React from 'react';

// material
import Typography from '@material-ui/core/Typography';
import { Badge, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Project
import { phoneFormat } from 'utils/functions';
import { Link } from 'react-router-dom';
import type from 'utils/type';
import styles from './style';

const useStyles = makeStyles(styles);

const Admin = ({ executive, role }, props) => {
  const classes = useStyles(props);
  const { name, email, phone } = executive;
  return (
    <Box fontSize={12} display="flex" flexDirection="column" className={classes.container}>
      <Typography component="span" color="primary" noWrap>
        <Box fontSize={10} fontWeight="fontWeightBold" className={classes.adminText}>
          Ejecutivo asignado
        </Box>
        <Box fontWeight="fontWeightMedium" fontSize={12} className={classes.text}>
          {!name ? (
            <Badge color="secondary" overlap="rectangular" variant="dot">
              {role === type.ENCARGADO_DE_FLOTA_SECCION ? <Typography>No informado</Typography> : <Link to="/client-settings">No informado</Link>}

            </Badge>
          ) : name}
        </Box>
        <Box fontWeight="fontWeightMedium" fontSize={12} className={classes.text}>
          <a className={classes.linkContacts} href={`mailto:${email}?subject=Mail from salfa.cl`} target="_blank" rel="noreferrer">
            {email}
          </a>
        </Box>
        <Box fontWeight="fontWeightMedium" fontSize={12} className={classes.text}>
          <a className={classes.linkContacts} href={`tel:${phone}`}>{ phoneFormat(phone || '') }</a>
        </Box>
      </Typography>
    </Box>
  );
};

export default Admin;
