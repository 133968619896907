const styles = () => ({
  papper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    p: 4,
    width: '40%',
    height: '70vh',
    overflow: 'hidden',
  },
  noItem: {
    height: '50vh',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  skeleton: {
    margin: '16px',
    width: '60%',
  },
  header: {
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '8px',
  },
  body: {
    maxHeight: '50vh',
    overflowY: 'auto',
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    msOverflowStyle: 'none',
    marginTop: '16px',
  },
  margin: 'auto',
});

export default styles;
