import React from 'react';
import { Autocomplete } from '@material-ui/lab';
import { makeStyles, TextField } from '@material-ui/core';
import classNames from 'classnames';
import styles from './styles';

const useStyles = makeStyles(styles);

const CustomAutoComplete = ({
  id,
  name,
  label,
  options,
  onChange,
  value,
  error,
  helperText,
  size = 'small',
  disableClearable,
  fullWidth = true,
  className = {},
}) => {
  const handleChange = (e, newValue) => {
    onChange({ target: { id, value: newValue?.value || '', type: 'select' }, currentTarget: { id } });
  };
  const classes = useStyles();

  return (
    <Autocomplete
      disableClearable={disableClearable}
      fullWidth={fullWidth}
      id={id}
      name={name}
      value={options.find((c) => c.value === value) ?? null}
      onChange={handleChange}
      options={options}
      getOptionLabel={(option) => (option.label ? option.label : '')}
      getOptionSelected={(option, v) => option.value || v?.value === '' || ''}
      noOptionsText="No se encontraron resultados"
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          size={size}
          className={classNames(classes.borderColor, className)}
          error={error}
          helperText={helperText}
        />
      )}
    />
  );
};

export default CustomAutoComplete;
