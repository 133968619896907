import { createStore, compose, combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import { createContext } from 'react';
import localForage from 'localforage';

// project

import userSetting from 'screens/UserSetting/reducer';
import navbar from 'commons/components/NavBar/reducer';
import service from 'screens/Service/reducer';
import workOrder from 'screens/WorkOrder/reducer';
import system from 'screens/System/reducer';
import paymentStatus from 'screens/PaymentStatus/reducer';
import { clone } from 'utils/functions';
import holdingMaintainer from 'screens/HoldingMaintainer/reducer';
import holdingMaintainerDetail from 'screens/HoldingMaintainerDetail/reducer';
import homeMarketing from 'screens/HomeMarketing/reducer';
import enhancers, { sagaMiddleware } from './middleware';

// reducers
import app from '../commons/reducer';
import login from '../screens/Login/reducer';
import files from '../screens/Upload/reducer/uploadFile.reducer';
import recoveryPassword from '../screens/RecoveryPassword/reducer';
import account from '../screens/Account/reducer';
import dashBoard from '../screens/DashBoard/reducer';
import vehicle from '../screens/Vehicle/reducer';
import vehicleDetail from '../screens/VehicleDetail/reducer';
import fleets from '../screens/Fleets/reducer';
import quote from '../screens/Quote/reducer';
import user from '../screens/User/reducer';
import request from '../screens/Request/reducer';
import newPaymentStatus from '../screens/GeneratePaymentStatus/reducer';
import paymentStatusConfig from '../screens/PaymentStatusConfig/reducer';
import clientSettings from '../screens/ClientSettings/reducer';
import paymentStatusSections from '../screens/PaymentStatusSections/reducer';
import reports from '../screens/Reports/reducer';
import document from '../screens/Document/reducer';

export const ReactReduxContext = createContext({});

export default function configureStore(initialState = {}) {
  const persistConfig = {
    key: 'root',
    storage: localForage,
    whitelist: [],
  };

  // create reducer
  const reducers = combineReducers(clone(clone.OBJECT, {
    login,
    app,
    files,
    recoveryPassword,
    account,
    dashBoard,
    vehicle,
    vehicleDetail,
    fleets,
    quote,
    userSetting,
    user,
    navbar,
    service,
    workOrder,
    request,
    system,
    paymentStatus,
    newPaymentStatus,
    paymentStatusConfig,
    clientSettings,
    paymentStatusSections,
    holdingMaintainer,
    holdingMaintainerDetail,
    homeMarketing,
    reports,
    document,
  }));

  const pReducer = persistReducer(persistConfig, reducers);

  const store = createStore(
    pReducer,
    initialState,
    compose(
      enhancers,
      /* eslint-disable no-underscore-dangle */
      typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : (f) => f,
      /* eslint-enable */
    ),

  );

  // Extensions
  store.runSaga = sagaMiddleware.run;
  store.injectedSagas = {}; // Saga registry

  return store;
}
