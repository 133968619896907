import { createAction, createReducer } from 'utils/reducer';

// Local constants
const PATH = 'navbar/';

// Global constants for saga
export const NAVBAR = {
  GET_HOLDINGS: `${PATH}GET_HOLDINGS`,
  GET_HOLDINGS_ERROR: `${PATH}GET_HOLDINGS_ERROR`,
  GET_HOLDINGS_SUCCESS: `${PATH}GET_HOLDINGS_SUCCESS`,
  GET_SOCIAL_REASON: `${PATH}GET_SOCIAL_REASON`,
  GET_SOCIAL_REASON_ERROR: `${PATH}GET_SOCIAL_REASON_ERROR`,
  GET_SOCIAL_REASON_SUCCESS: `${PATH}GET_SOCIAL_REASON_SUCCESS`,
  CLEAN_NOTIFICATIONS: `${PATH}CLEAN_NOTIFICATIONS`,
  CLEAN_NOTIFICATIONS_SUCCESS: `${PATH}CLEAN_NOTIFICATIONS_SUCCESS`,
  CLEAN_NOTIFICATIONS_ERROR: `${PATH}CLEAN_NOTIFICATIONS_ERROR`,
};

// actions
export const navbarActions = {
  getHoldings: createAction(NAVBAR.GET_HOLDINGS),
  getSocialReason: createAction(NAVBAR.GET_SOCIAL_REASON, 'payload'),
  cleanNotifications: createAction(NAVBAR.CLEAN_NOTIFICATIONS),
};

const initialState = {
  executive: {},
  loading: false,
  error: false,
};

const navbar = createReducer(initialState, {
  [NAVBAR.GET_HOLDINGS]: (state) => ({
    ...state,
    loading: true,
    error: false,
  }),
  [NAVBAR.GET_HOLDINGS_SUCCESS]: (state) => ({
    ...state,
    loading: false,
    error: false,
  }),
  [NAVBAR.GET_HOLDINGS_ERROR]: (state) => ({
    ...state,
    loading: false,
    error: true,
  }),
  [NAVBAR.GET_SOCIAL_REASON]: (state) => ({
    ...state,
    loading: true,
    error: false,
  }),
  [NAVBAR.GET_SOCIAL_REASON_SUCCESS]: (state) => ({
    ...state,
    loading: false,
    error: false,
  }),
  [NAVBAR.GET_SOCIAL_REASON_ERROR]: (state) => ({
    ...state,
    loading: false,
    error: true,
  }),
  [NAVBAR.CLEAN_NOTIFICATIONS]: (state) => ({
    ...state,
    loading: true,
  }),
  [NAVBAR.CLEAN_NOTIFICATIONS_SUCCESS]: (state) => ({
    ...state,
    loading: false,
  }),
  [NAVBAR.CLEAN_NOTIFICATIONS_ERROR]: (state) => ({
    ...state,
    loading: false,
  }),
});

export default navbar;
