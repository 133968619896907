import {
  takeLatest, spawn, put,
} from 'redux-saga/effects';

// Project
import * as APP from 'commons/reducer';
import apiRequest, { apiSuccess, get, update } from '../../utils/api';
import {
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_FAIL, // LOG_OUT,
  GET_USERS,
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,
  GET_USER_REPORTS,
  MATCH_VEHICLES,
  MATCH_VEHICLES_SUCCESS,
  MATCH_VEHICLES_ERROR,
  GET_NOTIFICATION,
  GET_NOTIFICATION_ERROR,
  GET_INITIAL_HOLDING,
  GET_INITIAL_HOLDING_SUCCESS,
  GET_INITIAL_HOLDING_ERROR,
  GET_EXECUTIVE,
  GET_EXECUTIVE_SUCCESS,
  GET_EXECUTIVE_ERROR,
  SET_HOLDING,
  SET_HOLDING_SUCCESS, SET_SOCIAL_REASON, SET_SOCIAL_REASON_SUCCESS,
} from '../reducer';

// function* logout() {
//   yield takeLatest(LOG_OUT, function* logoutApi() {
//     yield apiRequest('/logout', { method: 'get' });
//   });
// }

function* getUser() {
  yield takeLatest(GET_USER, function* getUserApi() {
    const response = yield apiRequest('api/v1/auth/user', { method: 'get' });
    if (!response.error) {
      yield put(apiSuccess(GET_USER_SUCCESS, response));
    } else {
      localStorage.removeItem('user');
      yield put(apiSuccess(GET_USER_FAIL));
    }
  });
}

function* getUsers() {
  yield takeLatest(GET_USERS, function* (action) {
    const holdingId = action.payload;
    const response = yield apiRequest(`api/v1/holdings/${holdingId}/users`, { method: 'get' });
    if (!response.error) {
      yield put(apiSuccess(GET_USERS_SUCCESS, response));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { msg: 'Error al obtener usuarios' }));
      yield put(apiSuccess(GET_USERS_FAIL));
    }
  });
}

function* getUserReports() {
  yield takeLatest(GET_USER_REPORTS, function* () {
    const response = yield apiRequest('api/v1/reports/user', { method: 'get' });
    if (!response.error) {
      yield put(apiSuccess(APP.GET_USER_REPORTS_SUCCESS, response));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { msg: 'Error al obtener reportes' }));
      yield put(apiSuccess(APP.GET_USER_REPORTS_SUCCESS, []));
    }
  });
}

function* getInitialHolding() {
  yield takeLatest(GET_INITIAL_HOLDING, function* () {
    const response = yield get('api/v1/holdings/initial');
    if (!response.error) {
      // Save holding in sessionStorage
      sessionStorage.setItem('holding', JSON.stringify(response.holding));

      yield put(apiSuccess(GET_INITIAL_HOLDING_SUCCESS, response));
    } else {
      yield put(apiSuccess(GET_INITIAL_HOLDING_ERROR));
    }
  });
}

function* setHolding() {
  yield takeLatest(SET_HOLDING, function* (action) {
    if (!action.payload) return;
    const { id, name, cb } = action.payload;
    const holding = { id, name };
    sessionStorage.setItem('holding', JSON.stringify(holding));
    yield put(apiSuccess(SET_HOLDING_SUCCESS, holding));
    if (cb) cb();
  });
}

function* setSocialReason() {
  yield takeLatest(SET_SOCIAL_REASON, function* (action) {
    const socialReasons = action.payload;
    yield put(apiSuccess(SET_SOCIAL_REASON_SUCCESS, socialReasons));
  });
}

function* matchVehicles() {
  yield takeLatest(MATCH_VEHICLES, function* (action) {
    const value = action.payload;
    const response = yield get(`api/v1/searches/vehicles?value=${value}`);

    if (!response.error) {
      yield put(apiSuccess(MATCH_VEHICLES_SUCCESS, response));
    } else {
      yield put(apiSuccess(MATCH_VEHICLES_ERROR));
    }
  });
}

function* getNotification() {
  yield takeLatest(GET_NOTIFICATION, function* () {
    const response = yield get('api/v1/notifications');
    if (!response.error) {
      yield put(apiSuccess(APP.GET_STATE_REPLACE_FROM_API_SUCCESS, response));
    } else {
      yield put(apiSuccess(GET_NOTIFICATION_ERROR));
    }
  });
}

function* updateNotification() {
  yield takeLatest(APP.UPDATE_NOTIFICATION, function* (action) {
    const { id, actionType } = action.payload;
    const response = yield update(`api/v1/notifications?id=${id}&type=${actionType}`);
    if (!response.error) {
      yield put(apiSuccess(APP.GET_NOTIFICATION));
    } else {
      yield put(apiSuccess(APP.UPDATE_NOTIFICATION_ERROR));
    }
  });
}

function* getExecutive() {
  yield takeLatest(GET_EXECUTIVE, function* () {
    const response = yield get('api/v1/users/executive');
    if (!response.error) {
      yield put(apiSuccess(GET_EXECUTIVE_SUCCESS, response));
    } else {
      yield put(apiSuccess(GET_EXECUTIVE_ERROR));
      yield put(apiSuccess(APP.SET_ERROR, { msg: 'Error al obtener el ejecutivo' }));
    }
  });
}

export default function* root() {
  // yield spawn(logout);
  yield spawn(getUser);
  yield spawn(getUsers);
  yield spawn(matchVehicles);
  yield spawn(getNotification);
  yield spawn(updateNotification);
  yield spawn(getInitialHolding);
  yield spawn(getExecutive);
  yield spawn(setHolding);
  yield spawn(setSocialReason);
  yield spawn(getUserReports);
}
