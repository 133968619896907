import React, { useState } from 'react';
import {
  Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, makeStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import CustomTextField from 'commons/components/CustomTextField';
import ButtonWithLoader from 'commons/containers/ButtonWithLoader';
import styles from '../styles';

const useStyles = makeStyles(styles);

const isValidUrl = (urlString) => {
  const urlPattern = new RegExp('^(https?:\\/\\/)' // validate protocol
  + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' // validate domain name
  + '((\\d{1,3}\\.){3}\\d{1,3}))' // validate OR ip (v4) address
  + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' // validate port and path
  + '(\\?[;&a-z\\d%_.~+=-]*)?'); // validate query string
  return !!urlPattern.test(urlString);
};

const UpdateNewsletterDialog = ({
  open, handleClose, handleSubmit, currTitle, currUrl,
}) => {
  const [errors, setErrors] = useState({ title: '', url: '' });
  const [title, setTitle] = useState(currTitle);
  const [url, setUrl] = useState(currUrl);
  const classes = useStyles();

  const handleCleanState = () => {
    setTitle(currTitle);
    setUrl(currUrl);
    setErrors({ title: '', url: '' });
  };

  const closeDialog = () => {
    handleCleanState();
    handleClose();
  };

  return (
    <Dialog open={open} onClose={closeDialog} fullWidth>
      <DialogTitle>
        <Grid container alignItems="center">
          <Grid item className={classes.title}>
            Actualizar Newsletter
          </Grid>
          <Grid item>
            <IconButton
              onClick={closeDialog}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container component="div" className={classes.inputContainer}>
          <CustomTextField
            id="inputTitle"
            autoFocus
            color="primary"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
            label={'Ingrese el nuevo título'.toUpperCase()}
            variant="outlined"
            error={!!errors.title}
            helperText={errors.title}
          />
        </Grid>
        <Grid container component="div" className={classes.inputContainer}>
          <CustomTextField
            id="inputUrl"
            autoFocus
            color="primary"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            required
            label={'Ingrese la nueva url'.toUpperCase()}
            variant="outlined"
            error={!!errors.url}
            helperText={errors.url}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <div className={classes.buttonContainer}>
          <ButtonWithLoader
            buttonId="cancel"
            variant="outlined"
            onClick={closeDialog}
          >
            Cancelar
          </ButtonWithLoader>
        </div>
        <div className={classes.buttonContainer}>
          <ButtonWithLoader
            buttonId="edit-newsletter"
            onClick={() => {
              if (!title) {
                setErrors({ ...errors, title: 'El título es requerido' });
                return;
              }
              if (!url || !isValidUrl(url)) {
                setErrors({ ...errors, url: 'La URL no es válida (ejemplo: https://www.example.com)' });
                return;
              }
              setErrors({ title: '', url: '' });
              handleSubmit({ title, url, handleClose });
            }}
          >
            Actualizar newsletter
          </ButtonWithLoader>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateNewsletterDialog;
