import { takeLatest, spawn, put } from 'redux-saga/effects';

import {
  apiSuccess, destroy, get, post, update,
} from 'utils/api';
import * as APP from 'commons/reducer';
import { REPORTS } from '../../Reports/reducer';

function* getReports() {
  yield takeLatest(REPORTS.GET_REPORTS, function* () {
    const response = yield get('api/v1/reports');
    if (!response.error) {
      yield put(apiSuccess(REPORTS.GET_REPORTS_SUCCESS, response));
    } else {
      yield put(
        apiSuccess(APP.SET_ERROR, 'Error al obtener reportes, inténtelo más tarde'),
      );
    }
  });
}

function* createReport() {
  yield takeLatest(REPORTS.CREATE_REPORT, function* (action) {
    const { title, url, handleClose } = action.payload;
    const response = yield post('api/v1/reports', { title, url });
    if (!response.error) {
      handleClose();
      yield put(apiSuccess(REPORTS.CREATE_REPORT_SUCCESS, response));
      yield put(apiSuccess(APP.SET_SUCCESS, { msg: 'Reporte creado correctamente' }));
    } else {
      yield put(apiSuccess(REPORTS.CREATE_REPORT_ERROR, { title, url, error: response.error }));
    }
  });
}

function* editReport() {
  yield takeLatest(REPORTS.EDIT_REPORT, function* (action) {
    const {
      id, title, url, handleClose,
    } = action.payload;
    const response = yield update(`api/v1/reports/${id}`, { title, url });
    if (!response.error) {
      handleClose();
      yield put(apiSuccess(REPORTS.EDIT_REPORT_SUCCESS, response));
      yield put(apiSuccess(APP.SET_SUCCESS, { msg: 'Reporte editado correctamente' }));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { msg: 'Ya existe un reporte con ese título' }));
      yield put(apiSuccess(REPORTS.EDIT_REPORT_ERROR, { title, url }));
    }
  });
}

function* deleteReport() {
  yield takeLatest(REPORTS.DELETE_REPORT, function* (action) {
    const { id, index } = action.payload;
    const response = yield destroy(`api/v1/reports/${id}`);
    if (!response.error) {
      yield put(apiSuccess(REPORTS.DELETE_REPORT_SUCCESS, response));
      yield put(apiSuccess(APP.SET_SUCCESS, { msg: 'Reporte eliminado correctamente' }));
    } else {
      yield put(apiSuccess(REPORTS.DELETE_REPORT_ERROR, { id, index }));
      yield put(apiSuccess(APP.SET_ERROR, { msg: 'Error al eliminar reporte' }));
    }
  });
}

function* updatePermissions() {
  yield takeLatest(REPORTS.SET_ACTIVE_REPORT, function* (action) {
    const {
      userId, reportId, holdingId, granted: grantPermission,
    } = action.payload;
    if (!userId) return;
    const response = yield update(`api/v1/reports/${userId}/permissions`, {
      userId, reportId, holdingId, grantPermission,
    });
    if (!response.error) {
      yield put(apiSuccess(APP.SET_SUCCESS, { msg: 'Permisos actualizados correctamente' }));
      yield put(apiSuccess(REPORTS.SET_ACTIVE_REPORT_SUCCESS, response));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { msg: 'Error al editar permisos' }));
    }
  });
}

function* updatePermissionsHolding() {
  yield takeLatest(REPORTS.SET_ACTIVE_REPORT_USERS, function* (action) {
    const {
      reportId, holdingId, granted: grantPermission,
    } = action.payload;
    const response = yield update('api/v1/reports/all/permissions', {
      reportId, holdingId, grantPermission, selectAll: true,
    });
    if (!response.error) {
      yield put(apiSuccess(REPORTS.SET_ACTIVE_REPORT_USERS_SUCCESS, response));
      yield put(apiSuccess(APP.SET_SUCCESS, { msg: 'Permisos actualizados correctamente' }));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { msg: 'Error al editar permisos' }));
    }
  });
}

export default function* root() {
  yield spawn(getReports);
  yield spawn(createReport);
  yield spawn(editReport);
  yield spawn(deleteReport);
  yield spawn(updatePermissions);
  yield spawn(updatePermissionsHolding);
}
