import React, { useMemo } from 'react';
import {
  Button,
  Dialog, DialogContent,
  DialogContentText,
  DialogTitle, Grid, IconButton, Input, InputAdornment, Tab, Tabs,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CustomTextField from 'commons/components/CustomTextField';
import { AttachFile, FileCopy, RemoveCircle } from '@material-ui/icons';
import { v4 as uuidv4 } from 'uuid';
import ButtonWithLoader from 'commons/containers/ButtonWithLoader';
import { fileSizeLimit } from 'utils/utils';
import CustomAutoComplete from 'commons/components/CustomAutoComplete';
import styles from './styles';

const useStyles = makeStyles(styles);
const ExtrasDialog = (props) => {
  const {
    controls, actions, extrasDialogControls, paymentStatusDTO, psId,
  } = props;
  const classes = useStyles();
  const leases = paymentStatusDTO?.fleets?.map((psf) => (psf.leases)).flat();
  const handleClose = () => {
    actions.changeControls('extrasDialog', false);
  };
  const handleChange = (e) => {
    const lease = leases?.filter((ls) => ls.id === e.target.value);
    if (lease) {
      actions.changeExtrasDialogControls('selectedLease', lease[0]?.id);
      actions.changeExtrasDialogControls('licensePlate', lease[0]?.licensePlate);
      actions.changeExtrasDialogControls('topAmount', lease[0]?.totalValueClp);
    }
  };
  const handleFile = (e) => {
    if (!e.target.files) {
      return;
    }
    let updatedAttachments = extrasDialogControls.attachments;
    [...e.target.files].forEach((file) => {
      if (file.size <= fileSizeLimit) {
        const renamedFile = new File([file], `${uuidv4()}-${file.name}`, { type: file.type });
        updatedAttachments = [...updatedAttachments, renamedFile];
      }
    });
    actions.changeExtrasDialogControls('attachments', updatedAttachments);
    e.target.value = null;
  };

  const deleteFile = (attName) => () => {
    const updatedAttachments = extrasDialogControls.attachments.filter(
      (att) => att.name !== attName,
    );
    actions.changeExtrasDialogControls('attachments', updatedAttachments);
  };

  const handleInactiveDaysChange = (e) => {
    const inactiveDays = e.target.value;
    const isValidInteger = (num) => /^\d+$/.test(num) || num === '';
    if (extrasDialogControls.extraType === 1 && isValidInteger(inactiveDays)) {
      actions.changeExtrasDialogControls('inactiveDays', inactiveDays);
    }
  };
  const handleAmountChange = (e) => {
    const amount = e.target.value;
    if (extrasDialogControls.extraType === 1) {
      if (Number(amount) <= extrasDialogControls.topAmount && Number(amount) >= 0) {
        actions.changeExtrasDialogControls('amount', amount);
      }
    } else {
      actions.changeExtrasDialogControls('amount', amount);
    }
  };

  const handleSubmit = () => {
    actions.submitExtras(extrasDialogControls, psId);
  };

  const licensePlateMap = useMemo(() => (leases?.map(
    (ls) => ({ id: ls.id, value: ls.id, label: ls.licensePlate }),
  )), [leases]);

  const disabledSubmit = !extrasDialogControls.description?.length
    || !extrasDialogControls.amount?.length
    || !extrasDialogControls.licensePlate?.length;

  const disabledAmount = !extrasDialogControls.licensePlate?.length;

  const discountCalculation = ({ inactiveDays, monthDays, totalValue }) => {
    const dailyValue = Math.ceil(totalValue / monthDays);
    return dailyValue * inactiveDays;
  };
  const handleCalculateDiscount = () => {
    const lease = leases.find((l) => l.licensePlate === extrasDialogControls.licensePlate);
    const discount = discountCalculation({
      inactiveDays: extrasDialogControls.inactiveDays,
      monthDays: lease.totalDays,
      totalValue: lease.totalValueClp,
    });
    actions.changeExtrasDialogControls('amount', discount.toString());
  };

  return (
    <>
      <Dialog open={controls.extrasDialog} onClose={handleClose} fullWidth>
        <DialogTitle>
          <Typography
            variant="h6"
            color="primary"
            className={classes.listTitle}
          >
            AGREGAR EXTRAS
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Tabs
                className={classes.tabs}
                value={extrasDialogControls.extraType}
                indicatorColor="secondary"
                variant="fullWidth"
                onChange={(e, newValue) => {
                  actions.changeExtrasDialogControls('amount', '');
                  actions.changeExtrasDialogControls('extraType', newValue);
                }}
              >
                <Tab label="Cargo adicional" />
                <Tab label="Descuento" />
              </Tabs>
            </Grid>
            <Grid item xs={12}>
              <CustomAutoComplete
                id="licensePlate"
                name="licensePlate"
                label="Patente"
                size="medium"
                variant="outlined"
                value={extrasDialogControls.selectedLease}
                options={licensePlateMap}
                onChange={handleChange}
              />
            </Grid>
            {extrasDialogControls.extraType === 1 && (
              <>
                <Grid item xs={8}>
                  <CustomTextField
                    id="inactiveDays"
                    autoFocus
                    color="primary"
                    value={extrasDialogControls.inactiveDays}
                    onChange={handleInactiveDaysChange}
                    type="number"
                    disabled={disabledAmount}
                    label="Dias inactivos"
                    variant="outlined"
                    size="medium"
                  />
                </Grid>
                <Grid item xs={4}>
                  <Button
                    className={classes.discountButton}
                    variant="contained"
                    disabled={disabledAmount}
                    onClick={handleCalculateDiscount}
                    component="label"
                    fullWidth
                  >
                    Calcular
                  </Button>
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <CustomTextField
                id="amount"
                autoFocus
                color="primary"
                value={extrasDialogControls.amount}
                onChange={handleAmountChange}
                type="number"
                required
                disabled={disabledAmount}
                label="Monto"
                variant="outlined"
                size="medium"
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                id="description"
                autoFocus
                color="primary"
                value={extrasDialogControls.description}
                onChange={(e) => {
                  actions.changeExtrasDialogControls('description', e.target.value);
                }}
                required
                label="Descripción"
                variant="outlined"
                size="medium"
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                className={classes.fileButton}
                variant="contained"
                component="label"
                endIcon={<AttachFile />}
                fullWidth
              >
                Seleccionar documentos
                <input
                  type="file"
                  hidden
                  multiple
                  onChange={handleFile}
                />
              </Button>
              <Typography
                variant="subtitle2"
                color="gray"
                className={classes.fileSize}
              >
                El tamaño máximo de cada archivo es de 25MB.
                Si supera este límite, el archivo no se adjuntará.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {extrasDialogControls.attachments?.map((att) => (
                <Input
                  key={att.name}
                  className={classes.fileName}
                  value={att.name.slice(37, att.name.length)}
                  fullWidth
                  disabled
                  startAdornment={(
                    <InputAdornment position="start">
                      <FileCopy color="primary" />
                    </InputAdornment>
                )}
                  endAdornment={(
                    <InputAdornment position="end">
                      <IconButton color="primary" onClick={deleteFile(att.name)}>
                        <RemoveCircle />
                      </IconButton>
                    </InputAdornment>
                )}
                />
              ))}
            </Grid>
            <Grid item xs={12}>
              <ButtonWithLoader
                className={classes.button}
                buttonId="extras-button"
                onClick={handleSubmit}
                disabled={disabledSubmit}
                color="primary"
                variant="contained"
              >
                AGREGAR
              </ButtonWithLoader>

            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default ExtrasDialog;
