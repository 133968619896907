const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(1),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
    },
  },
  nameContainer: {
    marginLeft: theme.spacing(2),
    width: 230,
  },
  optionIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  itemIcon: {
    color: theme.palette.primary.main,
  },
  statusIcon: {
    fontSize: 20,
  },
  statusMobileIcon: {
    fontSize: 20,
  },
  mobileIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
    fontSize: 24,
  },
  HABILITADO: { color: 'green' },
  DESABILITADO: { color: 'red' },
  iconSquare: {
    display: 'flex',
    justifyContent: 'end',
    color: theme.palette.primary.main,
  },
  back1: {
    backgroundColor: theme.palette.primary.secondary,
    borderRadius: 8,
    minHeight: theme.spacing(10),
  },
  back2: {
    backgroundColor: theme.palette.primary.main,
    marginBottom: theme.spacing(1),
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    minHeight: theme.spacing(4),
  },
  whiteTypography: {
    color: '#FFFFFF',
  },
  cardContainer: {
    minWidth: '300px',
  },
  cardTitle: {
    fontWeight: 600,
  },
  cardContent: {
    textAlign: 'right',
  },
  gridContainer: {
    padding: 8,
  },
});

export default styles;
