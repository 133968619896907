import React from 'react';
import {
  Button,
  Dialog, DialogContent,
  DialogContentText,
  Grid, IconButton, Input, InputAdornment,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { AttachFile, FileCopy, RemoveCircle } from '@material-ui/icons';
import { v4 as uuidv4 } from 'uuid';

import ButtonWithLoader from 'commons/containers/ButtonWithLoader';
import { fileSizeLimit } from 'utils/utils';
import styles from './styles';

const useStyles = makeStyles(styles);
const DocumentsDialog = (props) => {
  const {
    controls, actions, documentsDialogControls, paymentStatusDTO, psId,
  } = props;
  const classes = useStyles();

  const handleClose = () => {
    actions.changeControls('documentsDialog', false);
  };

  const handleFile = (fl) => (e) => {
    if (!e.target.files) {
      return;
    }
    let updatedAttachments = documentsDialogControls.attachments;
    let fleetFiles = documentsDialogControls.filesIndex;
    [...e.target.files].forEach((file) => {
      if (file.size <= fileSizeLimit) {
        const renamedFile = new File([file], `${uuidv4()}-${file.name}`, { type: file.type });
        updatedAttachments = [...updatedAttachments, renamedFile];
        if (fl) {
          const fleetFile = { id: fl, name: renamedFile.name };
          fleetFiles = [...fleetFiles, fleetFile];
        }
      }
    });
    actions.changeDocumentsDialogControls('filesIndex', fleetFiles);
    actions.changeDocumentsDialogControls('attachments', updatedAttachments);
    e.target.value = null;
  };

  const deleteFile = (attName) => () => {
    const updatedAttachments = documentsDialogControls.attachments.filter(
      (att) => att.name !== attName,
    );
    const updatedFilesIndex = documentsDialogControls.filesIndex.filter(
      (fi) => fi.name !== attName,
    );

    actions.changeDocumentsDialogControls('filesIndex', updatedFilesIndex);
    actions.changeDocumentsDialogControls('attachments', updatedAttachments);
  };

  const handleSubmit = () => {
    actions.submitDocuments(documentsDialogControls, psId);
  };

  const disabledSubmit = !documentsDialogControls?.attachments?.length
    || !documentsDialogControls?.filesIndex?.length;

  return (
    <Dialog open={controls.documentsDialog} onClose={handleClose} fullWidth>
      <Typography
        variant="h6"
        color="primary"
        className={classes.title}
      >
        ADJUNTAR DOCUMENTOS
      </Typography>
      <Typography
        variant="subtitle1"
        color="primary"
        className={classes.subTitle}
      >
        Adjunte los archivos correspondientes para cada Flota del cliente
      </Typography>
      {paymentStatusDTO?.fleets?.length === 1
        && (
          <DialogContent>
            <DialogContentText />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Button
                  className={classes.fileButton}
                  variant="contained"
                  component="label"
                  endIcon={<AttachFile />}
                  fullWidth
                >
                  Seleccionar documentos
                  <input
                    type="file"
                    multiple
                    hidden
                    onChange={handleFile(paymentStatusDTO.fleets[0].id)}
                  />
                </Button>
                <Typography
                  variant="subtitle2"
                  color="gray"
                  className={classes.fileSize}
                >
                  El tamaño máximo de cada archivo es de 25MB.
                  Si supera este límite, el archivo no se adjuntará.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {documentsDialogControls.attachments?.map((att) => (
                  <Input
                    key={att.name}
                    className={classes.fileName}
                    value={att.name.slice(37, att?.name?.length)}
                    fullWidth
                    disabled
                    startAdornment={(
                      <InputAdornment position="start">
                        <FileCopy color="primary" />
                      </InputAdornment>
                      )}
                    endAdornment={(
                      <InputAdornment position="end" onClick={deleteFile(att.name)}>
                        <IconButton color="primary">
                          <RemoveCircle />
                        </IconButton>
                      </InputAdornment>
                      )}
                  />
                ))}
              </Grid>
              <Grid item xs={12}>
                <ButtonWithLoader
                  className={classes.button}
                  buttonId="documents-button"
                  onClick={handleSubmit}
                  color="primary"
                  variant="contained"
                >
                  AGREGAR
                </ButtonWithLoader>
              </Grid>
            </Grid>
          </DialogContent>
        )}
      {paymentStatusDTO?.fleets?.length > 1
        && (
          <DialogContent>
            <DialogContentText />
            <Grid container spacing={2}>
              {paymentStatusDTO.fleets.map((fl) => {
                const indexFiltered = documentsDialogControls.filesIndex.filter(
                  (fi) => fi.id === fl.id,
                ).map(
                  (fif) => fif.name,
                );
                return (
                  <React.Fragment key={fl.id}>
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        color="primary"
                        className={classes.zoneTitle}
                      >
                        Flota
                        {' '}
                        {fl.name}
                        :
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        className={classes.fileButton}
                        variant="contained"
                        component="label"
                        endIcon={<AttachFile />}
                        fullWidth
                      >
                        Seleccionar documentos
                        <input
                          type="file"
                          hidden
                          multiple
                          onChange={handleFile(fl.id)}
                        />
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      {documentsDialogControls.attachments.filter(
                        (attch) => indexFiltered.includes(attch.name),
                      ).map((att) => (
                        <Input
                          key={att.name}
                          className={classes.fileName}
                          value={att.name.slice(37, att?.name?.length)}
                          fullWidth
                          disabled
                          startAdornment={(
                            <InputAdornment position="start">
                              <FileCopy color="primary" />
                            </InputAdornment>
                      )}
                          endAdornment={(
                            <InputAdornment position="end" onClick={deleteFile(att.name)}>
                              <IconButton color="primary">
                                <RemoveCircle />
                              </IconButton>
                            </InputAdornment>
                      )}
                        />
                      ))}
                    </Grid>
                  </React.Fragment>
                );
              })}
              <Grid item xs={12}>
                <ButtonWithLoader
                  className={classes.button}
                  buttonId="documents-button"
                  onClick={handleSubmit}
                  color="primary"
                  variant="contained"
                  disabled={disabledSubmit}
                >
                  AGREGAR
                </ButtonWithLoader>
              </Grid>
            </Grid>
          </DialogContent>
        )}
    </Dialog>
  );
};
export default DocumentsDialog;
