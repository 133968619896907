const styles = (theme) => ({
  dialogContainer: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
  mobileHeader: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  iconButton: {
    transform: 'scale(1.7)',
  },
  content: {
    '& > *': {
      marginTop: theme.spacing(1.5),
    },
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
});

export default styles;
