import React from 'react';
import Button from '@material-ui/core/Button';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styles from '../styles';

const useStyles = makeStyles(styles);
// Material

// icons

const BtnApprove = ({
  actions, disabled,
}) => {
  const classes = useStyles();
  return (
    <Tooltip interactive placement="top" title={disabled ? 'Hay flotas que no están aprobadas o en revisión salfa' : ''}>
      <div className={classes.divWidth}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          disabled={disabled}
          onClick={() => {
            actions.changeControls('approveDialog', true);
          }}
        >
          APROBAR ESTADO DE PAGO
        </Button>
      </div>
    </Tooltip>

  );
};

export default BtnApprove;
