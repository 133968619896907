import React, { useEffect } from 'react';
import ScreenTitle from 'commons/components/ScreenTitle';
import {
  Box, Button,
  Card,
  CardActionArea, CardActions,
  Grid, makeStyles,
  Typography,
} from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import { PlayArrow } from '@material-ui/icons';
import { composedComponent } from 'utils/functions';
import saga from 'commons/saga';
import { appActions } from 'commons/reducer';
import type from 'utils/type';
import { screenTime } from 'utils/utils';
import styles from './styles';

const useStyles = makeStyles(styles);
const videoTutorials = [
  {
    id: 18,
    name: 'Introducción',
    description: 'Introducción al portal Salfa Fleet.',
    link: 'https://drive.google.com/file/d/1Naoyw6ileizSF7ykapdHhB5eVJAhCIPc/view',
    roles: [type.ADMINISTRADOR_PORTAL_SALFA, type.ADMINISTRADOR_DE_CONTRATO,
      type.GESTOR_DE_FLOTA_DECISOR, type.ENCARGADO_DE_FLOTA_SECCION],
  },
  {
    id: 8,
    name: 'Cambiar contraseña',
    description: 'Cómo cambiar tu contraseña.',
    link: 'https://drive.google.com/file/d/1NS6Y0lzb5iS9m3rij-QZh09trZBk9i2n/view',
    roles: [type.ADMINISTRADOR_PORTAL_SALFA, type.ADMINISTRADOR_DE_CONTRATO,
      type.GESTOR_DE_FLOTA_DECISOR, type.ENCARGADO_DE_FLOTA_SECCION],
  },
  {
    id: 1,
    name: 'Notificaciones',
    description: 'Introducción a las notificaciones y su funcionamiento.',
    link: 'https://drive.google.com/file/d/172hQn0Nd2XtqAUcRNkesGjjEy8Xlz1Wy/view',
    roles: [type.ADMINISTRADOR_PORTAL_SALFA, type.ADMINISTRADOR_DE_CONTRATO,
      type.GESTOR_DE_FLOTA_DECISOR, type.ENCARGADO_DE_FLOTA_SECCION],
  },
  {
    id: 11,
    name: 'Descripción de usuarios',
    description: 'Descripción de los tipos de usuarios en el portal.',
    link: 'https://drive.google.com/file/d/1AaRVeqEaBwVFJcdeijttA6SqAjF_QULw/view',
    roles: [type.ADMINISTRADOR_PORTAL_SALFA, type.ADMINISTRADOR_DE_CONTRATO],
  },
  {
    id: 16,
    name: 'Creación de usuario',
    description: 'Cómo crear un usuario en el portal Salfa Fleet.',
    link: 'https://drive.google.com/file/d/1l4DVXNxiJNlf179oSlxfaQrV7nANQi3z/view',
    roles: [type.GESTOR_DE_FLOTA_DECISOR],
  },
  {
    id: 3,
    name: 'Creación de usuario',
    description: 'Cómo crear un usuario en el portal Salfa Fleet.',
    link: 'https://drive.google.com/file/d/1yVfX9EelrN6oEPcY6ec2vxqYLiwlz-I-/view',
    roles: [type.ADMINISTRADOR_DE_CONTRATO],
  },
  {
    id: 4,
    name: 'Creación de usuario',
    description: 'Cómo crear un usuario en el portal Salfa Fleet.',
    link: 'https://drive.google.com/file/d/1RmiqrbZoRvDc5OQ44FPuFbbJ2NRatX2h/view',
    roles: [type.ADMINISTRADOR_PORTAL_SALFA],
  },
  {
    id: 13,
    name: 'Editar usuario',
    description: 'Cómo editar un usuario en el portal.',
    link: 'https://drive.google.com/file/d/1Dwlqfqu2QZ4Ycwi-8u3s_fv9mu-W_uNd/view',
    roles: [type.ADMINISTRADOR_PORTAL_SALFA, type.ADMINISTRADOR_DE_CONTRATO,
      type.GESTOR_DE_FLOTA_DECISOR],
  },
  {
    id: 15,
    name: 'Eliminar usuario',
    description: 'Cómo eliminar un usuario en el portal.',
    link: 'https://drive.google.com/file/d/1l4DVXNxiJNlf179oSlxfaQrV7nANQi3z/view',
    roles: [type.ADMINISTRADOR_PORTAL_SALFA, type.ADMINISTRADOR_DE_CONTRATO,
      type.GESTOR_DE_FLOTA_DECISOR],
  },
  {
    id: 10,
    name: 'Creación de flota',
    description: 'Cómo crear una flota en el portal.',
    link: 'https://drive.google.com/file/d/1TjOYJ1ZrYlJidNDQ53eV2w5O-vEwHBNW/view',
    roles: [type.ADMINISTRADOR_PORTAL_SALFA, type.ADMINISTRADOR_DE_CONTRATO,
      type.GESTOR_DE_FLOTA_DECISOR],
  },
  {
    id: 12,
    name: 'Editar flota',
    description: 'Cómo editar una flota en el portal.',
    link: 'https://drive.google.com/file/d/1KGtxvsGjJ-sNcjBBxBTxmc6UI---s0Sn/view',
    roles: [type.ADMINISTRADOR_PORTAL_SALFA, type.ADMINISTRADOR_DE_CONTRATO,
      type.GESTOR_DE_FLOTA_DECISOR],
  },
  {
    id: 14,
    name: 'Eliminar flota',
    description: 'Cómo eliminar una flota en el portal.',
    link: 'https://drive.google.com/file/d/1XMS5ag8gW91vDPkL5jJ0Q0IuNcXIzQ-Y/view',
    roles: [type.ADMINISTRADOR_PORTAL_SALFA, type.ADMINISTRADOR_DE_CONTRATO,
      type.GESTOR_DE_FLOTA_DECISOR],
  },
  {
    id: 21,
    name: 'Secciones del estado de pago',
    description: 'Descripción de las secciones del estado de pago.',
    link: 'https://drive.google.com/file/d/1ytczwTVtf6CrnFMoVl9L8iiun7UGbPtc/view',
    roles: [type.ADMINISTRADOR_PORTAL_SALFA, type.ADMINISTRADOR_DE_CONTRATO],
  },
  {
    id: 17,
    name: 'Inicializar estado de pago',
    description: 'Cómo inicializar un estado de pago.',
    link: 'https://drive.google.com/file/d/1Yj7XAuYQNGtN_SkIMBnVnCIbP4ShBC4-/view',
    roles: [type.ADMINISTRADOR_PORTAL_SALFA, type.ADMINISTRADOR_DE_CONTRATO],
  },
  {
    id: 5,
    name: 'Agregar extras al estado de pago',
    description: 'Cómo agregar extras a un vehículo en el estado de pago.',
    link: 'https://drive.google.com/file/d/1OCP9F4CXrIUgGcm6KkVFOb-8AzDaoPkw/view',
    roles: [type.ADMINISTRADOR_PORTAL_SALFA, type.ADMINISTRADOR_DE_CONTRATO,
      type.GESTOR_DE_FLOTA_DECISOR, type.ENCARGADO_DE_FLOTA_SECCION],
  },
  {
    id: 19,
    name: 'Liberar estado de pago',
    description: 'Cómo liberar un estado de pago.',
    link: 'https://drive.google.com/file/d/1WKyttffhTJvyzsCpOzMiDP6TVFhiapSR/view',
    roles: [type.ADMINISTRADOR_PORTAL_SALFA, type.ADMINISTRADOR_DE_CONTRATO],
  },
  {
    id: 2,
    name: 'Adjuntar documentos',
    description: 'Cómo adjuntar documentos a un estado de pago.',
    link: 'https://drive.google.com/file/d/1u70oDjWc_cuu5pv-aLiKr_wbgsoXxDW-/view',
    roles: [type.ADMINISTRADOR_PORTAL_SALFA, type.ADMINISTRADOR_DE_CONTRATO,
      type.GESTOR_DE_FLOTA_DECISOR, type.ENCARGADO_DE_FLOTA_SECCION],
  },
  {
    id: 22,
    name: 'Solicitud de corrección del estado de pago',
    description: 'Cómo solicitar una corrección de estado de pago.',
    link: 'https://drive.google.com/file/d/1NhqpaZF4E1HMpw-YktWOY6WwCnmfkwVA/view',
    roles: [type.ADMINISTRADOR_PORTAL_SALFA, type.ADMINISTRADOR_DE_CONTRATO],
  },
  {
    id: 20,
    name: 'Corrección de estado de pago realizada',
    description: 'Cómo corregir un estado de pago.',
    link: 'https://drive.google.com/file/d/1UwuwhiAIZB_3ogwEzMM7-wewi_B5QGH-/view',
    roles: [type.ADMINISTRADOR_PORTAL_SALFA, type.ADMINISTRADOR_DE_CONTRATO],
  },
  {
    id: 7,
    name: 'Aprobar flota del estado de pago',
    description: 'Cómo aprobar una flota en el estado de pago.',
    link: 'https://drive.google.com/file/d/1NaLJaDjC6PsPmSK_5eXCHAJ00x8rEeQ7/view',
    roles: [type.ADMINISTRADOR_PORTAL_SALFA, type.ADMINISTRADOR_DE_CONTRATO,
      type.GESTOR_DE_FLOTA_DECISOR, type.ENCARGADO_DE_FLOTA_SECCION],
  },
  {
    id: 6,
    name: 'Aprobar estado de pago completo',
    description: 'Cómo aprobar un estado de pago.',
    link: 'https://drive.google.com/file/d/1F6uIbLJijqcKb25kaF6hqnYSKd27bfiC/view',
    roles: [type.ADMINISTRADOR_PORTAL_SALFA, type.ADMINISTRADOR_DE_CONTRATO,
      type.GESTOR_DE_FLOTA_DECISOR, type.ENCARGADO_DE_FLOTA_SECCION],
  },
  {
    id: 9,
    name: 'Cerrar estado de pago',
    description: 'Cómo cerrar un estado de pago.',
    link: 'https://drive.google.com/file/d/1X-RjzGVxoJl00tjXZuDMA0YJ9wNpSIFy/view',
    roles: [type.ADMINISTRADOR_PORTAL_SALFA, type.ADMINISTRADOR_DE_CONTRATO],
  },
];

function VideoTutorials({ user }) {
  const { type: role } = user.user;
  const classes = useStyles();
  useEffect(() => screenTime('video-tutorials'), []);

  return (
    <>
      <Box display="flex" justifyContent="space-between" mb={2}>
        <ScreenTitle title="VIDEO TUTORIALES" showSubTitle={false} />
      </Box>
      <Grid container spacing={2} alignItems="stretch">
        {videoTutorials.map((video) => {
          if (video?.roles?.includes(role)) {
            return (
              <Grid item xs={12} sm={6} md={4} lg={3} key={video.id}>
                <Card className={classes.card}>
                  <CardActionArea>
                    <CardContent className={classes.cardContent}>
                      <Typography
                        gutterBottom
                        variant="h4"
                        component="h2"
                        color="primary"
                      >
                        {video.name}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        {video.description}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions className={classes.cardActions}>
                    <Button
                      fullWidth
                      size="small"
                      color="primary"
                      variant="contained"
                      endIcon={<PlayArrow />}
                      onClick={() => window.open(video.link, '_blank', 'noopener,noreferrer')}
                    >
                      Ir al video
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            );
          }
          return null;
        })}
      </Grid>
    </>
  );
}

export default composedComponent(VideoTutorials, saga, {
  saga: 'sagaVideoTutorials',
  states: [
    'app.user',
  ],
  actions: [appActions],
});
