const styles = (theme) => ({
  filterButton: {
    backgroundColor: '#fff',
    color: theme.palette.primary.main,
    marginTop: theme.spacing(2),
    display: 'flex',
    '&  > *': {
      justifyContent: 'space-between',
    },
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
});

export default styles;
