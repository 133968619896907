const styles = (theme) => ({
  statusIcon: {
    fontSize: 20,
    marginRight: theme.spacing(1),
  },
  enabled: { color: 'green' },
  disabled: { color: 'red' },
  mobileIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
    fontSize: 24,
  },

});

export default styles;
