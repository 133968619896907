import React from 'react';
import {
  Button,
  Dialog, DialogContent,
  DialogContentText,
  DialogTitle, Grid, IconButton, Input, InputAdornment,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CustomTextField from 'commons/components/CustomTextField';
import { AttachFile, FileCopy, RemoveCircle } from '@material-ui/icons';
import { v4 as uuidv4 } from 'uuid';
import ButtonWithLoader from 'commons/containers/ButtonWithLoader';
import type from 'utils/type';
import { fileSizeLimit } from 'utils/utils';
import styles from './styles';

const useStyles = makeStyles(styles);

const PostDialog = ({
  controls, actions, postDialogControls, psId, role,
}) => {
  const classes = useStyles();

  const handleClose = () => {
    actions.changeControls('postDialog', false);
  };

  const handleFile = (e) => {
    if (!e.target.files) {
      return;
    }
    let updatedAttachments = postDialogControls.attachments;
    [...e.target.files].forEach((file) => {
      if (file.size <= fileSizeLimit) {
        const renamedFile = new File([file], `${uuidv4()}-${file.name}`, { type: file.type });
        updatedAttachments = [...updatedAttachments, renamedFile];
      }
    });
    actions.changePostDialogControls('attachments', updatedAttachments);
    e.target.value = null;
  };

  const deleteFile = (attName) => () => {
    const updatedAttachments = postDialogControls.attachments.filter(
      (att) => att.name !== attName,
    );
    actions.changePostDialogControls('attachments', updatedAttachments);
  };

  const handleSubmit = () => {
    actions.submitPost(postDialogControls, psId, Number(controls.fleet), role);
  };

  const disabledSubmit = !postDialogControls.observation.length > 0;
  return (
    <>
      <Dialog open={controls.postDialog} onClose={handleClose} fullWidth>
        <DialogTitle>
          <Typography
            variant="h6"
            color="primary"
            className={classes.listTitle}
          >
            {role === type.ADMINISTRADOR_DE_CONTRATO ? 'RESPONDER REVISIÓN' : 'ENVIAR A REVISIÓN'}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Button
                className={classes.fileButton}
                variant="contained"
                component="label"
                endIcon={<AttachFile />}
                fullWidth
              >
                Seleccionar documentos
                <input
                  type="file"
                  hidden
                  multiple
                  onChange={handleFile}
                />
              </Button>
              <Typography
                variant="subtitle2"
                color="gray"
                className={classes.typography}
              >
                El tamaño máximo de cada archivo es de 25MB.
                Si supera este límite, el archivo no se adjuntará.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {postDialogControls.attachments?.map((att) => (
                <Input
                  key={att.name}
                  className={classes.fileName}
                  value={att.name.slice(37, att.name.length)}
                  fullWidth
                  disabled
                  startAdornment={(
                    <InputAdornment position="start">
                      <FileCopy color="primary" />
                    </InputAdornment>
                  )}
                  endAdornment={(
                    <InputAdornment position="end">
                      <IconButton color="primary" onClick={deleteFile(att.name)}>
                        <RemoveCircle />
                      </IconButton>
                    </InputAdornment>
                  )}
                />
              ))}
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                color="primary"
                className={classes.listTitle}
              >
                Observaciones
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                id="observation"
                autoFocus
                color="primary"
                value={postDialogControls.observation}
                onChange={(e) => {
                  actions.changePostDialogControls('observation', e.target.value);
                }}
                required
                label="Observaciones"
                variant="outlined"
                size="medium"
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClose}
              >
                CANCELAR
              </Button>
              <ButtonWithLoader
                className={classes.button}
                buttonId="post-button"
                onClick={handleSubmit}
                disabled={disabledSubmit}
                color="primary"
                variant="contained"
              >
                ENVIAR
              </ButtonWithLoader>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default PostDialog;
