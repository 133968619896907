import { takeLatest, spawn, put } from 'redux-saga/effects';
import apiRequest, { apiSuccess } from 'utils/api';
import * as APP from 'commons/reducer';
import { RECOVERYPASSWORD } from '../reducer';

function* verifyEmail() {
  yield takeLatest(RECOVERYPASSWORD.POST_VERIFY_EMAIL, function* (action) {
    const body = JSON.stringify(action.controls);
    const response = yield apiRequest('api/v1/auth/reset-password', {
      method: 'post',
      body,
    }, true, true);
    if (!response.error) {
      yield put(
        apiSuccess(RECOVERYPASSWORD.POST_VERIFY_EMAIL_SUCCESS, 'Si su correo está registrado, recibirá un mensaje para cambiar su contraseña'),
      );
      // localStorage.setItem('user', JSON.stringify({ token: response.token }));
    } else {
      yield put(
        apiSuccess(RECOVERYPASSWORD.POST_VERIFY_EMAIL_FAIL, response),
      );
    }
  });
}

function* updatePassword() {
  yield takeLatest(RECOVERYPASSWORD.POST_UPDATE_PASSWORD, function* (action) {
    const body = JSON.stringify(action.controls);
    const response = yield apiRequest('api/v1/auth/generate-password', {
      method: 'post',
      body,
    }, true, true);
    if (!response.error) {
      yield put(
        apiSuccess(RECOVERYPASSWORD.POST_UPDATE_PASSWORD_SUCCESS, 'Su contraseña ha sido actualizada correctamente, ahora puede iniciar sesión.'),
      );
      yield put(RECOVERYPASSWORD.RESET_CONTROLS);
    } else {
      yield put(
        apiSuccess(APP.SET_ERROR, { errorMsg: 'El proceso de recuperación a expirado' }),
      );
      yield put(apiSuccess(RECOVERYPASSWORD.POST_UPDATE_PASSWORD_SUCCESS, response));
    }
  });
}

export default function* root() {
  yield spawn(verifyEmail);
  yield spawn(updatePassword);
}
