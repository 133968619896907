import React from 'react';
import {
  Box, Card, Grid, Hidden,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const TotalDetailsContainer = ({ children }) => {
  const classes = useStyles(); return (
    <>
      <Hidden mdUp>
        <Grid
          item
          xs={12}
          md={3}
          component={Card}
          className={classes.gridChildren}
        >
          {children}
        </Grid>
      </Hidden>
      <Hidden smDown>
        <Box
          component={Card}
          paddingX="16px"
          className={classes.boxChildren}
        >
          {children}
        </Box>
      </Hidden>
    </>
  );
};

export default TotalDetailsContainer;
