const styles = (theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: theme.spacing(6),
  },
  menuButton: {
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(0.5),
    },
  },
  title: {
    flexGrow: 1,
  },
  bigIcon: {
    display: 'none',
    transform: 'scale(1)',
    padding: 0,
    marginRight: theme.spacing(2),
    '&:hover': {
      backgroundColor: '#fff',
      color: '#FFF',
    },
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  reducedIcon: {
    display: 'block',
    '&:hover': {
      backgroundColor: '#fff',
      color: '#FFF',
    },
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
    paddingLeft: theme.spacing(0),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  profileContainer: {
    '&:hover': {
      backgroundColor: '#fff',
      color: '#FFF',
    },
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  renewButton: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    minWidth: 180,
    borderRadius: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      height: 53,
    },
  },
  notificationIcon: {
    '&:hover': {
      background: 'none',
      color: '#FFF',
    },
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
    marginLeft: theme.spacing(2),
    transform: 'scale(1.4)',
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  profileButton: {
    '&:hover': {
      background: 'none',
      color: '#FFF',
    },
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
  },
  profileIcon: {
    marginRight: theme.spacing(1),
    transform: 'scale(1.4)',
  },
  searchIcon: {
    padding: theme.spacing(0),
    display: 'block',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
});

export default styles;
