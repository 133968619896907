import { alpha } from '@material-ui/core/styles/colorManipulator';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    boxShadow: theme.shadows[4],
    '&:hover': {
      cursor: 'pointer',
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
    },
  },
  back1: {
    backgroundColor: alpha(theme.palette.primary.main, 0.03),
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.12),
    },
  },
  back2: {
    backgroundColor: alpha(theme.palette.primary.main, 0.08),
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.12),
    },
  },
  header: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    height: '50px',
    display: 'flex',
    marginTop: '16px',
  },
  container: {
    display: 'flex',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 16px',
  },
  button: {
    width: '80%',
  },
});

export default styles;
