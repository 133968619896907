import commonStyles from 'utils/commonStyles';

const styles = () => ({
  title: {
    flex: 1,
  },
  inputContainer: {
    marginBottom: '18px',
  },
  reassignButton: {
    marginLeft: '9px',
  },
  subtitle: {
    marginBottom: '18px',
  },
  ...commonStyles,
});

export default styles;
