import React from 'react';

import { Typography } from '@material-ui/core';

export const AvailabilityText = () => (
  <>
    <Typography component="div" color="primary">
      <Typography style={{ fontSize: 12, fontWeight: 700 }}>
        Disponibilidad promedio mensual de un vehículo
      </Typography>
      <Typography style={{ fontSize: 12 }}>
        Para calcularla, se hace lo siguiente para cada vehículo:
      </Typography>
      <Typography style={{ fontSize: 12 }}>
        Disponibilidad mes del vehículo =
        (días arrendado
        - días en taller
        + días reemplazados)
        / Días del mes
      </Typography>
      <Typography style={{ fontSize: 12 }}>
        Finalmente se suman todas las *Disponibilidades* y se les saca el promedio:
      </Typography>
      <Typography style={{ fontSize: 12 }}>
        Disponibilidad mes = (Disponibilidades / Flota total ) x 100%
      </Typography>
    </Typography>
  </>
);

const ToolTipText = ({ children }) => (
  <Typography style={{ fontSize: 12, fontWeight: 700 }} color="primary">
    {children}
  </Typography>
);
export const WorkshopText = () => (
  <ToolTipText>Vehículos en taller</ToolTipText>
);

export const ReplacementText = () => (
  <ToolTipText>Vehículos reemplazados</ToolTipText>
);

export const ValidityText = () => (
  <ToolTipText>Vehículos con la fecha de vigencia ya vencida</ToolTipText>
);

export const LateMaintenanceText = () => (
  <ToolTipText>Vehículos con la mantención atrasada</ToolTipText>

);

export const PreventiveText = () => (
  <ToolTipText>Tipos de detenciones preventivos activos</ToolTipText>
);
  /*
  PreventiveText,
  CorrectiveText,
  MaintenanceTableText,
  */
export const CorrectiveText = () => (
  <ToolTipText>Tipos de detenciones correctivos activos</ToolTipText>
);

export const SinisterText = () => (
  <ToolTipText>Tipos de detenciones de siniestros activos</ToolTipText>
);

export const MaintenanceTableText = () => (
  <ToolTipText>Top 10 vehículos con la mantención  más atrasada</ToolTipText>
);
