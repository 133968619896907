const styles = (theme) => ({
  card: {
    display: 'flex',
    width: '50%',
    justifyContent: 'center',
  },
  mobileLoginContainer: {
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '0px',
      marginRight: '0px',
      marginLeft: '0px',
    },
  },
  imageOverlayText: {
    position: 'absolute',
    color: '#ffffff',
    textAlign: 'right',
    lineHeight: '25px',
    top: '40%',
    left: '16px',
    fontSize: '3rem',
  },
  item: {
    maxWidth: 400,
    width: '80%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  field: {
    marginTop: theme.spacing(2),
    '& .MuiInputBase-root': {
      color: theme.palette.primary.main,
    },
    '& .MuiFormLabel-root': {
      color: theme.palette.primary.main,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.primary.main,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.primary.main,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
      },
    },
  },
  customTitle: {
    fontSize: '2rem',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(6),
      textAlign: 'center',
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    position: 'absolute',
  },
  inputs: {
    display: 'flex',
    flexDirection: 'column',
    width: '70%',
    marginTop: '5%',
  },
  image: {
    width: '280px',
    marginLeft: 'auto',
    marginRight: 'auto',
    objectFit: 'contain',
    maxWidth: '100%',
  },
  customHint: {
    marginTop: theme.spacing(1),
    textAlign: 'left',
  },
  loading: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '30px',
    marginBottom: '50px',
  },
  textfield: {
    marginTop: '10px',
  },
  imageOverlayTextGeneratePassword: {
    position: 'absolute',
    textAlign: 'right',
    lineHeight: '25px',
    bottom: '8px',
    right: '16px',
    fontSize: '3rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '2rem',
    },
  },
  button: {
    marginTop: theme.spacing(7),
    height: '50px',
    marginBottom: theme.spacing(8),
  },
  imagePerson: {
    height: '30vh',
    width: '100%',
    objectFit: 'cover',
  },
  grid: {
    position: 'relative',
  },
  bannerPerson: {
    height: '100vh',
    width: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
  divPassword: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'start',
  },
  paragraph: {
    fontSize: '3rem',
  },
});

export default styles;
