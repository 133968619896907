import React, {
  useCallback, useEffect, useMemo, useRef,
} from 'react';
// Material
import { Box, withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';

// Icon
import FilterListOutlinedIcon from '@material-ui/icons/FilterListOutlined';

// Project
import ScreenTitle from 'commons/components/ScreenTitle';
import Skeleton from 'screens/Vehicle/components/Skeleton';
import { appActions } from 'commons/reducer';
import DownloadButton from 'commons/components/DownloadButton';
import Hidden from '@material-ui/core/Hidden';
import { composedComponent, validateEmail } from 'utils/functions';
import useSocialReasonFilter from 'commons/components/MultipleFilter/useSocialReasonFilter';
import type from 'utils/type';
import Modal from 'screens/VehicleDetail/Components/Modal';
import useForm from 'utils/hooks/useForm';
import { isValidRut } from 'utils/rut';
import { screenTime } from 'utils/utils';
import saga from './saga';
import { vehicleActions } from './reducer';
import VehicleList from './components/VehicleList';
import Filter from './components/Filter';
import Indicator from './components/Indicator';
import styles from './styles';

const Vehicle = ({
  classes,
  holding,
  socialReason,
  controls,
  filtersData,
  actions,
  objects,
  order,
  kpi,
  pagination,
  loading,
  loadingData,
  pageLoading,
  loadingDownload,
  loadingDownloadDuplicated,
  user,
  formControlsChange,
  drivers,
  formControlsRequest, isOpen,
  loadingDrivers, errorDrivers, loadingAssign,
  successAssign, isChange, driverExist, loadingRequest,
  successRequest,
}) => {
  const { optionsSocialReason } = useSocialReasonFilter(objects.socialReasons);
  const selectValue = controls.socialReason;
  const vehicleSelected = useRef(null);

  const handleAssignDriver = () => {
    actions.assignDriver(formControlsChange);
  };

  const handleSelectDriver = (data) => {
    actions.selectDriver(data);
  };
  const handleCloseModal = () => {
    actions.closeDriverModal();
    actions.resetControls();
  };

  const handleSetIsChange = (value) => {
    actions.changeControls({ socialReason: vehicleSelected.current?.contractVehicleSocialReasonId || '' });
    actions.setIsChange(value);
  };

  const changeControls = useCallback((name, value) => {
    actions.changeControls({ [name]: value });
  }, []);

  const { onChange, onSubmit, errors } = useForm(formControlsRequest, null, {
    onChange: changeControls,
    validations: {
      email: [
        {
          check: (value) => !!value && value.trim().length > 0,
          message: 'El Email es requerido',
        },
        {
          check: (value) => validateEmail(value),
          message: 'El Email es inválido',
        },
      ],
      run: [
        {
          check: (value) => !!value && value.trim().length > 0,
          message: 'El RUT es requerido',
        },
        {
          check: (value) => isValidRut(value),
          message: 'El RUT es inválido',
        },
      ],
      name: [
        {
          check: (value) => !!value && value.trim().length > 0,
          message: 'El Nombre es requerido',
        },
        {
          check: (value) => value.trim().split(' ').length > 1,
          message: 'Ingrese un nombre y apellido',
        },
      ],
      phone: [
        {
          check: (value) => !!value && value.trim().length > 0,
          message: 'El Teléfono es requerido',
        },
        {
          check: (value) => !(value.trim().length < 9),
          message: 'Ingrese un mínimo de 9 números',
        },
        {
          check: (value) => !(value.trim().length > 11),
          message: 'Ingrese un máximo de 11 números',
        },
      ],
    },
  });

  const dataDrivers = useMemo(() => drivers.map((d) => ({
    value: d.run,
    label: d.name,
  })), [drivers]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const driver = dataDrivers.filter((d) => d.value === formControlsRequest.run.replace(/[.]/g, ''));

    if (driver.length > 0) {
      actions.validateDriverRun();
    } else {
      onSubmit(() => actions.createRequest(formControlsRequest));
    }
  };
  const handleChangeSocialReason = ({ value }) => actions.setFilter({ key: 'socialReason', value });

  useEffect(() => {
    if (holding?.id) {
      actions.getVehicles();
      if (controls.resetValues) actions.resetFilters();
      actions.setFilter({ key: 'resetValues', value: true });
    }
  }, [holding, socialReason]);

  useEffect(() => () => { actions.resetObject('vehicles'); }, []);

  useEffect(() => screenTime('vehicles'), []);

  useEffect(() => {
    if (successAssign) {
      actions.resetControls();
      if (holding?.id) {
        actions.getVehicles();
      }
    }
  },
  [
    successAssign, holding,
  ]);

  useEffect(() => {
    if (successRequest) {
      actions.resetControls();
      actions.closeDriverModal();
    }
  },
  [
    successRequest,
  ]);

  useEffect(() => {
    if (Object.keys(vehicleSelected.current || {}).length > 0) {
      actions.fetchDrivers(vehicleSelected.current?.contractVehicleSocialReasonId);
    }
  }, [vehicleSelected.current]);

  const vehicles = useMemo(() => (Object.values(objects.vehicles).length > 0
    ? order?.vehicles?.map((s) => objects.vehicles[s])
    : []),
  [objects.vehicles]);

  const fleetOptions = useMemo(() => Object.values(objects.fleets)
    .map(({ name, id }) => ({ label: name, value: id })),
  [objects.fleets]);

  const statusOptions = useMemo(() => Object.values(filtersData.status)
    .map(({ name }) => ({ label: name, value: name })), [filtersData.status]);

  const zoneOptions = useMemo(() => Object.values(filtersData.zones)
    .map(({ name }) => ({ label: name, value: name })), [filtersData.zones]);

  const maintenanceOptions = useMemo(() => Object.values(filtersData.maintenance)
    .map(({ name }) => ({ label: name, value: name })), [filtersData.maintenance]);

  const dueDateOptions = useMemo(() => Object.values(filtersData.dueDate)
    .map(({ name }) => ({ label: name, value: name })), [filtersData.dueDate]);

  const handleDownload = () => {
    actions.downloadExcel();
  };
  const handleDownloadDuplicated = () => {
    actions.downloadDuplicatedExcel();
  };

  const role = user?.user?.type;
  return (
    !loadingData || loading ? <Skeleton />
      : (
        <Box className={classes.screenContainer} height={1}>
          <Box display="flex" justifyContent="space-between">
            <ScreenTitle title="FLOTA" />
            <Button
              variant="contained"
              color="default"
              size="large"
              onClick={() => actions.setMobileFilter()}
              className={classes.filterButton}
              startIcon={<FilterListOutlinedIcon />}
            >
              Filtros
            </Button>
          </Box>
          <Box className={classes.exteriorSection}>
            <Box className={classes.insideSection}>
              <Indicator
                kpi={kpi}
                controls={controls}
                actions={actions}
                isLoading={loading}
              />
              <Filter
                fleetOptions={fleetOptions}
                statusOptions={statusOptions}
                zoneOptions={zoneOptions}
                maintenanceOptions={maintenanceOptions}
                dueDateOptions={dueDateOptions}
                controls={controls}
                actions={actions}
                isLoading={loading}
                selectValue={selectValue}
                handleChangeSocialReason={handleChangeSocialReason}
                optionsSocialReason={optionsSocialReason}
              />
            </Box>
          </Box>
          <Hidden smDown>
            <Box
              marginTop={1}
              marginBottom={0.3}
              display="flex"
              justifyContent="end"
            >
              {role === type.ADMINISTRADOR_PORTAL_SALFA && (
              <DownloadButton
                total={vehicles.length}
                onClick={handleDownloadDuplicated}
                loading={loadingDownloadDuplicated}
                text="Descargar duplicados"
              />
              )}
              <DownloadButton
                total={vehicles.length}
                onClick={handleDownload}
                loading={loadingDownload}
              />
            </Box>
          </Hidden>

          <VehicleList
            vehicles={vehicles}
            pagination={pagination}
            actions={actions}
            controls={controls}
            isLoading={loading || pageLoading}
            total={vehicles.length}
            handleDownload={handleDownload}
            handleDownloadDuplicated={handleDownloadDuplicated}
            loading={loadingDownload}
            role={role}
            formControlsChange={formControlsChange}
            drivers={drivers}
            formControlsRequest={formControlsRequest}
            isOpen={isOpen}
            loadingDrivers={loadingDrivers}
            errorDrivers={errorDrivers}
            loadingAssign={loadingAssign}
            isChange={isChange}
            driverExist={driverExist}
            loadingRequest={loadingRequest}
            vehicleSelected={vehicleSelected}
          />
          <Modal
            isOpen={isOpen}
            close={handleCloseModal}
            // Change driver props
            isChange={isChange}
            drivers={dataDrivers}
            handleSelectDriver={handleSelectDriver}
            loadingDrivers={loadingDrivers}
            loadingAssign={loadingAssign}
            currentDriver={formControlsChange.driverRun}
            handleAssignDriver={handleAssignDriver}
            errorDrivers={errorDrivers}
            handleSetIsChange={handleSetIsChange}
            driverExist={driverExist}
            // Request driver props
            socialReason={vehicleSelected.current?.contractVehicleSocialReasonName || ''}
            onChange={onChange}
            handleSubmit={handleSubmit}
            errors={errors}
            controls={formControlsRequest}
            loadingRequest={loadingRequest}
          />
        </Box>
      )
  );
};

export default composedComponent(Vehicle, saga, {
  saga: 'sagaVehicle',
  middlewares: [withStyles(styles)],
  states: [
    'vehicle.kpi',
    'vehicle.pagination',
    'vehicle.filtersData',
    'vehicle.controls',
    'vehicle.loading',
    'vehicle.loadingData',
    'vehicle.query',
    'vehicle.loadingDownload',
    'vehicle.loadingDownloadDuplicated',
    'vehicle.formControlsChange',
    'vehicle.drivers',
    'vehicle.formControlsRequest',
    'vehicle.isOpen',
    'vehicle.loadingDrivers',
    'vehicle.errorDrivers',
    'vehicle.loadingAssign',
    'vehicle.errorAssign',
    'vehicle.successAssign',
    'vehicle.isChange',
    'vehicle.driverExist',
    'vehicle.loadingRequest',
    'vehicle.successRequest',
    'vehicle.error',
    'app.objects',
    'app.order',
    'app.holding',
    'app.socialReason',
    'app.user',
  ],
  actions: [vehicleActions, appActions],
});
