import React from 'react';

import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';

// Icons
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import KeyboardArrowLeftRoundedIcon from '@material-ui/icons/KeyboardArrowLeftRounded';

import styles from './styles';

const useStyles = makeStyles(styles);

function Actions({ loading, actions, controls }) {
  const classes = useStyles();
  const { toAssign, toDeallocate } = controls;
  const { sendAssign, sendDeallocate } = actions;
  return (
    <Box
      className={classes.container}
    >
      <Avatar
        onClick={() => !loading && toDeallocate.length > 0 && sendDeallocate()}
        className={classes.leftIcon}
      >
        <KeyboardArrowLeftRoundedIcon fontSize="large" />
      </Avatar>
      <Avatar
        onClick={() => !loading && toAssign.length > 0 && sendAssign()}
        className={classes.rightIcon}
      >
        <KeyboardArrowRightRoundedIcon fontSize="large" />
      </Avatar>

    </Box>
  );
}

export default Actions;
