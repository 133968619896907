import {
  Box, Dialog, IconButton, makeStyles, Typography,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import classNames from 'classnames';

// Icons
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';

import styles from './styles';

const useStyles = makeStyles(styles);

const HoldingDialog = ({ holdings = [], show, closeDialog }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();

  return (
    <Dialog
      fullScreen={fullScreen}
      open={show}
      aria-labelledby="scroll-dialog-title"
    >
      <Box className={classes.root}>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h4" color="primary">
            Holdings
          </Typography>
          <IconButton color="primary" onClick={() => closeDialog()} aria-label="close">
            <CloseIcon fontSize="large" />
          </IconButton>
        </Box>
        <Box display="flex" flexDirection="column">
          {holdings.map((h, index) => {
            const { SocialReason } = h;
            return (
              <Box
                className={classNames(
                  classes.holdingCard,
                  index % 2 === 0 ? classes.back1 : classes.back2,
                )}
              >
                <Box>
                  <Typography
                    component="div"
                    variant="h4"
                    color="primary"
                    noWrap
                  >
                    <Box
                      fontStyle="normal"
                      fontSize={24}
                      className={classes.holdingName}
                    >
                      {h.name}
                    </Box>
                  </Typography>
                </Box>
                <Box
                  display="inline"
                  className={classes.chips}
                >

                  {SocialReason.map((s) => (
                    <Chip color="primary" label={s.rut} size="small" />
                  ))}
                </Box>

              </Box>
            );
          })}
        </Box>
      </Box>
    </Dialog>
  );
};

export default HoldingDialog;
