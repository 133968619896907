import {
  FormControl,
  FormControlLabel,
  FormGroup,
  makeStyles,
  Checkbox,
  IconButton,
  TextField,
  Box,
  Chip,
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';

import AddIcon from '@material-ui/icons/Add';
import { randomUUID } from 'utils/functions';
import styles from './styles';

const ChipList = ({ list, rightDescription, onDelete }) => {
  const classes = makeStyles(styles)();
  const handleDelete = useCallback((value) => {
    if (typeof onDelete === 'function') onDelete(value);
  }, [list]);

  return list ? [...list].sort((a, b) => a - b).map((value) => (
    <Box className={classes.chipContainer} key={randomUUID()}>
      <Chip
        label={`${value} ${rightDescription}`}
        color="primary"
        onDelete={() => handleDelete(value)}
      />
    </Box>
  )) : null;
};

/*
data = [
      { label: '100km', checked: false },
      { label: '500km', checked: false },
      { label: '1000km', checked: false },
    ];
*/

const NotificationInterval = ({
  label, id, saveState, addsLabel, data, defaultsValues,
}) => {
  const [state, setState] = useState(defaultsValues.map((i) => ({ label: i, checked: true })));
  const [adds, setAdds] = useState(data.filter((item) => !defaultsValues.includes(item.label)));
  const [otherValues, setOtherValues] = useState(false);
  const [nInput, setNInput] = useState('');
  const classes = makeStyles(styles)();

  const handleToggleOtherValues = () => {
    setOtherValues(!otherValues);
  };

  const handleAddOtherValue = () => {
    const newList = [...new Set([...adds, nInput])];
    setAdds(newList);
    // update redux state
  };

  const handleDeleteOtherValue = (value) => {
    const newList = adds.filter((v) => v !== value);
    setAdds(newList);
    // update redux state
  };

  useEffect(() => {
    saveState(id, state);
  }, [state]);

  return (
    <>
      <FormControl className={classes.formControl}>
        <FormGroup className={classes.kmDefaults}>
          {state.map((item) => (
            <FormControlLabel
              key={item.label}
              label={id === 'km' ? `${item.label} km` : `${item.label} meses`}
              control={(
                <Checkbox
                  color="primary"
                  checked={item.checked}
                  onChange={() => {
                    setState(state.map((i) => ({
                      ...i,
                      checked: i.label === item.label ? !i.checked : i.checked,
                    })));
                  }}
                />
              )}
            />
          ))}
        </FormGroup>
        <FormGroup className={classes.kmDefaults}>
          <FormControlLabel
            control={<Checkbox color="primary" checked={otherValues} onChange={handleToggleOtherValues} name="otherValues" />}
            label="Otros valores"
          />
          {otherValues
          && (
            <FormGroup className={classes.kmDefaults}>
              <TextField
                onChange={({ target }) => { setNInput(target.value); }}
                id="filled-number"
                label={label}
                type="number"
                color="primary"
                size="small"
                variant="outlined"
              />
              <IconButton
                color="primary"
                size="small"
                className={classes.addButton}
                onClick={handleAddOtherValue}
              >
                <AddIcon color="primary" />
              </IconButton>
            </FormGroup>
          )}
        </FormGroup>
      </FormControl>
      <ChipList
        list={adds}
        rightDescription={addsLabel}
        onDelete={handleDeleteOtherValue}
      />
    </>
  );
};

export default NotificationInterval;
