import {
  takeLatest, spawn, put, select,
} from 'redux-saga/effects';

import { generatePath, getIds } from 'utils/functions';
import format from 'date-fns/format';
import { apiSuccess, downloadFileBlob, get } from 'utils/api';

import { addDays } from 'date-fns';
import { SERVICE } from '../reducer';
import * as APP from '../../../commons/reducer';

const getSize = (state) => state.service.pagination.size;
const getPage = (state) => state.service.pagination.page;
const getQuery = (state) => state.service.query;
const getSocialReason = (state) => state.service.controls.socialReason;
const getFirstFetch = (state) => state.service.firstFetch;
const getHolding = (state) => state.app.holding.id;

function* generatePathService({
  page,
  size,
  route,
  getAll = false,
}) {
  const pageToSend = page > 0 ? page : (yield select(getPage));
  const sizeToSend = size > 0 ? size : (yield select(getSize));
  let path = `${route}?size=${sizeToSend}&page=${pageToSend}`;

  const firstFetch = yield select(getFirstFetch);

  const {
    vehicle,
    workshop,
    fleet,
    dateRange,
    selectedDate,
    status,
    orderBy,
    desc,
  } = yield select(getQuery);

  const holding = yield select(getHolding);
  const socialReasonIds = yield select(getSocialReason);
  const pathSocialReason = getIds(socialReasonIds).map((d) => `&socialReason=${d}`).join('').slice(1);
  // Generate path for filters
  const vehiclePath = generatePath(vehicle, 'vehicle');
  const workshopPath = generatePath(workshop, 'workshop');
  const fleetPath = generatePath(fleet, 'fleet');
  const statusPath = generatePath(status, 'status');
  const datePath = generatePath(
    dateRange?.map((d) => (d ? format(addDays(new Date(d), 0), 'yyyy-MM-dd') : null)), 'dateRange',
  );

  if (getAll) path += '&getAll=true';
  if (firstFetch) path += `&firstFetch=${firstFetch ? 'true' : 'false'}`;
  if (holding) path += `&holding=${holding}`;
  if (socialReasonIds.length > 0) path += `&${pathSocialReason}`;
  if (datePath) path += `&${datePath}`;
  if (selectedDate) path += `&selectedDate=${selectedDate}`;
  if (vehicle) path += vehiclePath;
  if (workshop) path += workshopPath;
  if (fleet) path += fleetPath;
  if (status) path += statusPath;
  if (orderBy) path += `&orderBy=${orderBy}`;
  path += `&desc=${desc ? 'true' : 'false'}`;

  return path;
}

function* requestServices(
  page = 0,
  size = 0,
  getAll = false,
) {
  const path = yield generatePathService({
    page, size, route: 'api/v1/services', getAll,
  });
  return { page, size, ...yield get(path) };
}

function* getInitialData() {
  yield takeLatest(SERVICE.FETCH_INITIAL_DATA, function* (action) {
    if (action.payload) {
      yield put(apiSuccess(SERVICE.SET_VEHICLE_FILTER, action.payload));
    }

    const response = yield requestServices();

    if (!response.error) {
      yield put(apiSuccess(SERVICE.FETCH_INITIAL_DATA_SUCCESS, response));
      yield put(apiSuccess(APP.GET_STATE_REPLACE_FROM_API_SUCCESS, response));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: 'Error al cargar los servicios, por favor intentelo más tarde.' }));
    }
  });
}

function* changePage() {
  yield takeLatest(SERVICE.CHANGE_PAGE, function* (action) {
    const page = action.payload;
    const response = yield requestServices(page);

    if (!response.error) {
      yield put(apiSuccess(SERVICE.CHANGE_PAGE_SUCCESS, response));
      yield put(apiSuccess(APP.GET_STATE_REPLACE_FROM_API_SUCCESS, response));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: 'Error al cargar los servicios, por favor intentelo más tarde.' }));
    }
  });
}

function* fetchWithFilter() {
  yield takeLatest(SERVICE.FETCH_DATA_WITH_FILTER, function* () {
    const page = 1;
    const response = yield requestServices(page);
    if (!response.error) {
      yield put(apiSuccess(SERVICE.FETCH_DATA_WITH_FILTER_SUCCESS, response));
      yield put(apiSuccess(APP.GET_STATE_REPLACE_FROM_API_SUCCESS, response));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: 'Error al cargar los servicios, por favor intentelo más tarde.' }));
    }
  });
}

function* clearControls() {
  yield takeLatest(SERVICE.CLEAR_CONTROLS, function* () {
    const page = 1;
    const response = yield requestServices(page);
    if (!response.error) {
      yield put(apiSuccess(SERVICE.CLEAR_CONTROLS_SUCCESS, response));
      yield put(apiSuccess(APP.GET_STATE_REPLACE_FROM_API_SUCCESS, response));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: 'Error al cargar los servicios, por favor intentelo más tarde.' }));
    }
  });
}

function* setOrderBy() {
  yield takeLatest(SERVICE.SET_ORDER_BY, function* () {
    const response = yield requestServices();
    if (!response.error) {
      yield put(apiSuccess(SERVICE.SET_ORDER_BY_SUCCESS));
      yield put(apiSuccess(APP.GET_STATE_REPLACE_FROM_API_SUCCESS, response));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: 'Error al cargar los servicios, por favor intentelo más tarde.' }));
    }
  });
}

function* changeOrderBy() {
  yield takeLatest(SERVICE.CHANGE_ORDER_BY, function* () {
    const response = yield requestServices();
    if (!response.error) {
      yield put(apiSuccess(SERVICE.CHANGE_ORDER_BY_SUCCESS));
      yield put(apiSuccess(APP.GET_STATE_REPLACE_FROM_API_SUCCESS, response));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: 'Error al cargar los servicios, por favor intentelo más tarde.' }));
    }
  });
}

function* downloadExcel() {
  yield takeLatest(SERVICE.DOWNLOAD_EXCEL, function* () {
    const [page, size, getAll] = [0, 0, true];
    const { services } = yield requestServices(page, size, getAll);

    const path = yield generatePathService({
      route: 'api/v1/services/generate-excel',
    });
    const date = format(new Date(), 'yyyy-MM-dd');

    const response = yield downloadFileBlob(path, `Detenciones_${date}`, services, true);

    if (!response.error) {
      yield put(apiSuccess(SERVICE.DOWNLOAD_EXCEL_SUCCESS));
      yield put(apiSuccess(APP.SET_SUCCESS, { msg: 'Descarga exitosa' }));
    } else {
      yield put(apiSuccess(SERVICE.DOWNLOAD_EXCEL_ERROR));
      yield put(apiSuccess(APP.SET_ERROR, { msg: 'Error en la descarga' }));
    }
  });
}

export default function* root() {
  yield spawn(getInitialData);
  yield spawn(fetchWithFilter);
  yield spawn(changePage);
  yield spawn(clearControls);
  yield spawn(setOrderBy);
  yield spawn(changeOrderBy);
  yield spawn(downloadExcel);
}
