import {
  takeLatest, put, select, spawn,
} from 'redux-saga/effects';

import { apiSuccess, get, destroy } from 'utils/api';
import * as APP from '../../../commons/reducer';
import { REQUEST } from '../reducer';

const getHolding = (state) => state.app.holding;
const getControls = (state) => state.request.controls;

function* getInitialData() {
  yield takeLatest(REQUEST.FETCH_INITIAL_DATA, function* () {
    const { id } = yield select(getHolding);
    const path = `api/v1/request/driver?holding=${id}`;
    const response = yield get(path);
    if (!response.error) {
      yield put(apiSuccess(REQUEST.FETCH_INITIAL_DATA_SUCCESS, response));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: 'Error al cargar las solicitudes, intentelo más tarde.' }));
    }
  });
}

function* downDriverRequest() {
  yield takeLatest(REQUEST.DOWN_DRIVER_REQUEST, function* () {
    const { selected: id } = yield select(getControls);
    const path = `api/v1/request/driver?request=${id}`;
    const response = yield destroy(path);
    if (!response.error) {
      yield put(apiSuccess(REQUEST.DOWN_DRIVER_REQUEST_SUCCESS, id));
      yield put(apiSuccess(APP.SET_SUCCESS, { msg: 'Solicitud dada de baja con éxito.' }));
    } else {
      const { statusCode } = response;

      if (statusCode === 404) {
        yield put(apiSuccess(REQUEST.DOWN_DRIVER_REQUEST_SUCCESS, id));
        yield put(apiSuccess(APP.SET_ERROR, { errorMsg: 'La solicitud ya fue dada de baja.' }));
      } else {
        yield put(apiSuccess(REQUEST.DOWN_DRIVER_REQUEST_ERROR));
        yield put(apiSuccess(APP.SET_ERROR, { errorMsg: response.errorMsg }));
      }
    }
  });
}

export default function* root() {
  yield spawn(getInitialData);
  yield spawn(downDriverRequest);
}
