import { takeLatest, spawn, put } from 'redux-saga/effects';
import {
  apiSuccess, post, get, update,
} from 'utils/api';
import * as APP from 'commons/reducer';

import { USERSETTING } from 'screens/UserSetting/reducer';

function* fetchData() {
  yield takeLatest(USERSETTING.FETCH_INITIAL_DATA, function* () {
    const response = yield get('api/v1/users/initial-data');
    if (!response.error) {
      yield put(apiSuccess(USERSETTING.FETCH_INITIAL_DATA_SUCCESS, response.roles));
      yield put(apiSuccess(APP.GET_STATE_REPLACE_FROM_API_SUCCESS, response));
    } else {
      yield put(apiSuccess(USERSETTING.FETCH_INITIAL_DATA_ERROR));
      yield put(apiSuccess(APP.SET_ERROR, { msg: 'Error al obtener los datos' }));
    }
  });
}

function* fetchUser() {
  yield takeLatest(USERSETTING.FETCH_USER, function* (action) {
    const id = action.payload;
    const response = yield get(`api/v1/users/get-user?id=${id}`);
    if (!response.error) {
      yield put(apiSuccess(USERSETTING.FETCH_USER_SUCCESS, response.dataUser));
    } else {
      yield put(apiSuccess(USERSETTING.FETCH_USER_ERROR));
      yield put(apiSuccess(APP.SET_ERROR, { msg: 'Error al obtener los datos' }));
    }
  });
}

function* createUser() {
  yield takeLatest(USERSETTING.CREATE_USER, function* (action) {
    const body = action.payload;
    const response = yield post('api/v1/users/', body);
    if (!response.error) {
      yield put(apiSuccess(USERSETTING.CREATE_USER_SUCCESS));
      yield put(apiSuccess(APP.SET_SUCCESS, { msg: 'Usuario creado exitosamente' }));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { msg: 'El Email ingresado ya pertenece al sistema' }));
      yield put(apiSuccess(USERSETTING.CREATE_USER_ERROR));
    }
  });
}

function* editUser() {
  yield takeLatest(USERSETTING.EDIT_USER, function* (action) {
    const body = action.payload;
    const response = yield update('api/v1/users/', body);
    if (!response.error) {
      yield put(apiSuccess(APP.SET_SUCCESS, { msg: 'Información editada exitosamente' }));
      yield put(apiSuccess(USERSETTING.EDIT_USER_SUCCESS));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { msg: 'Error al editar el usuario' }));
      yield put(apiSuccess(USERSETTING.EDIT_USER_ERROR));
    }
  });
}

function* fetchUserHoldings() {
  yield takeLatest(USERSETTING.FETCH_USER_HOLDINGS, function* (action) {
    const id = action.payload;
    const response = yield get(`api/v1/holdings/social-reason?userId=${id}&includeSocialReasons=false`);
    if (!response.error) {
      yield put(apiSuccess(USERSETTING.FETCH_USER_HOLDINGS_SUCCESS, response));
    } else {
      yield put(apiSuccess(USERSETTING.FETCH_USER_HOLDINGS_ERROR));
    }
  });
}

function* fetchContractAdmins() {
  yield takeLatest(USERSETTING.FETCH_CONTRACT_ADMINS, function* () {
    const path = 'api/v1/users/contract-admins';
    const response = yield get(path);
    if (!response.error) {
      yield put(apiSuccess(USERSETTING.FETCH_CONTRACT_ADMINS_SUCCESS, response));
    } else {
      yield put(apiSuccess(
        APP.SET_ERROR,
        { errorMsg: 'Error al obtener los administradores de contratos.' },
      ));
    }
  });
}

export default function* root() {
  yield spawn(fetchData);
  yield spawn(fetchUser);
  yield spawn(createUser);
  yield spawn(editUser);
  yield spawn(fetchUserHoldings);
  yield spawn(fetchContractAdmins);
}
