import React, { useState, useEffect, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import produce from 'immer';

// Material
import {
  CircularProgress,
  Typography,
  Snackbar,
  withStyles,
  Box, Chip, InputAdornment, IconButton,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import Hidden from '@material-ui/core/Hidden';

// Project
import useForm from 'utils/hooks/useForm';
import { appActions } from 'commons/reducer';
import { composedComponent } from 'utils/functions';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import styles from './styles';
import saga from './saga';
import { loginActions } from './reducer';
import newBannerSalfaLogin from '../../commons/assets/images/newBannerSalfaLogin.jpg';
import bannerSalfaLogo from '../../commons/assets/images/salfa-rent-logo.png';

const Login = (props) => {
  const {
    classes, actions, controls, user, error, errorMsg, success, successMsg,
  } = props;
  const history = useHistory();
  const [state, setState] = useState({
    email: '',
    password: '',
  });

  useEffect(() => {
    if (user) {
      history.replace('/');
    }
  }, [user]);

  const onChangeText = useCallback((id, value) => {
    // eslint-disable-next-line no-param-reassign
    setState(produce((draft) => { draft[id] = value?.trim(); }));
  }, []);

  const { onChange, onSubmit, errors } = useForm(state, null, {
    onChange: onChangeText,
    validations: {
      password: [{ check: (value) => value != null && value.trim().length > 0, message: 'Contraseña obligatoria' }],
      email: [{ check: (value) => value != null && value.trim().length > 0, message: 'Email obligatorio' }],
    },
  });

  function onClickLogin(event) {
    event.preventDefault();
    onSubmit(() => actions.login(state, controls.redirectUrl));
  }

  return (
    <>
      <Grid container alignItems="center" className={classes.container}>
        <Hidden mdUp xsDown>
          <Grid item xs={12}>
            <img
              src={newBannerSalfaLogin}
              alt="img"
              className={classes.banner}
            />
          </Grid>
        </Hidden>
        <Grid item lg={8} md={7} xs={12} className={classes.banner2Container}>
          <div className={classes.div}>
            <Hidden smDown>
              <Typography
                className={classes.typography}
                variant="caption"
              >
                <p className={classes.paragraph}>Manejamos todo</p>
                <p className={classes.paragraph}>lo que pase con tu flota,</p>
                <p className={classes.paragraph2}>para que no lo hagas tú</p>
              </Typography>
            </Hidden>
          </div>
          <Hidden smDown>
            <img
              src={newBannerSalfaLogin}
              className={classes.banner2}
              alt="newBannerSalfaLogin"
            />
          </Hidden>
        </Grid>
        <Grid
          item
          lg={4}
          md={5}
        >
          <form onSubmit={onClickLogin}>
            <Grid container spacing={2} className={classes.mobileLoginContainer}>
              {/* Logo SalfaRent */}
              <Grid item xs={12}>
                <Box display="flex" alignItems="center" justifyContent="center">
                  <img
                    src={bannerSalfaLogo}
                    alt="img"
                    className={classes.bannerSalfaLogo}
                  />
                </Box>
                <Box display="flex" alignItems="center" justifyContent="flex-end" className={classes.box}>
                  <Chip label="Leasing operativo" color="secondary" size="small" className={classes.chip} />
                </Box>
              </Grid>

              {/* Titulo iniciar sesion */}
              <Grid item xs={12}>
                <Box display="flex" justifyContent="center">
                  <Box className={classes.item}>
                    <Typography variant="caption" color="primary">
                      <Box className={classes.customTitle}>Inicia sesión</Box>
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <TextField
                    className={classes.field}
                    autoFocus
                    fullWidth
                    autoComplete="email"
                    onChange={onChange}
                    value={state.email}
                    id="email"
                    label="Correo"
                    variant="outlined"
                    error={errors.email}
                    helperText={errors.email}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <TextField
                    fullWidth
                    error={errors.password}
                    helperText={errors.password}
                    className={classes.field}
                    type={controls.showPassword ? 'text' : 'password'}
                    name="password"
                    autoComplete="off"
                    onChange={onChange}
                    id="password"
                    label="Contraseña"
                    value={state.password}
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => {
                              actions.controlsChanged({
                                showPassword: !controls.showPassword,
                              });
                            }}
                            edge="end"
                          >
                            {controls.showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Grid>
              {controls.error && (
              <Grid item xs={12}>
                <Typography align="center" variant="caption">
                  Credenciales inválidas
                </Typography>
              </Grid>
              )}
              <Grid item xs={12}>
                <Box display="flex" justifyContent="center">
                  <Box className={classes.item}>
                    <Typography variant="caption" color="primary">
                      <Link to="/recovery-password" className={classes.link}>
                        <Box>Recuperar contraseña</Box>
                      </Link>
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="center">
                  <Box className={classes.item}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="secondary"
                      className={classes.button}
                    >
                      {controls.loading ? <CircularProgress /> : 'Ingresar'}
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={error}
        autoHideDuration={3000}
        onClose={actions.toggleError}
      >
        <Alert onClose={actions.toggleError} severity="error">
          {errorMsg}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={success}
        autoHideDuration={3000}
        onClose={actions.toggleSuccess}
      >
        <Alert onClose={actions.toggleSuccess} severity="success">
          {successMsg}
        </Alert>
      </Snackbar>
    </>
  );
};

export default composedComponent(Login, saga, {
  saga: 'sagaLogin',
  middlewares: [withStyles(styles)],
  states: [
    'login.controls',
    'app.user',
    'app.errorMsg',
    'app.error',
    'app.successMsg',
    'app.success',
  ],
  actions: [loginActions, appActions],
});
