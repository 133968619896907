import React from 'react';
import { Box, Typography } from '@material-ui/core';
import BuildIcon from '@material-ui/icons/Build';
import DateRangeIcon from '@material-ui/icons/DateRange';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import SpeedIcon from '@material-ui/icons/Speed';
import SubjectIcon from '@material-ui/icons/Subject';
import { changeFormatDate, numberFormatter } from 'utils/functions';
import GeneralCard from 'commons/components/GeneralCard';
import LeftComponent from 'commons/components/LeftComponent';
import GeneralCardItem from 'commons/components/GeneralCardItem';
import DownloadDocument from 'commons/icons/components/DownloadDocument';

import { makeStyles } from '@material-ui/styles';
import GeneralMobileCard from 'commons/components/GeneralMobileCard';
import CardMobile from 'screens/WorkOrder/component/CardMobile';
import workOrderType from 'utils/workOrderType';
import styles from './styles';

const useStyles = makeStyles(styles);

const VehicleBelowGeneralCard = ({ licensePlate }) => {
  const classes = useStyles();
  return (
    <Box marginLeft={2} display="flex">
      <DriveEtaIcon className={classes.driveIcon} color="primary" />
      <Typography component="div" variant="body1">
        <Box component="span" fontSize={10}>{licensePlate}</Box>
      </Typography>
    </Box>
  );
};

const RightComponent = ({ type }) => (
  <Box marginLeft="8px" marginRight="8px">
    {type === workOrderType.ZRMA ? (
      <DownloadDocument color="primary" fontSize="large" />
    ) : (
      <Box marginRight="34px" />
    )}
  </Box>
);

const WorkOrderCard = ({ workOrder, index, isMobile }) => {
  const classes = useStyles();

  const {
    externalId, date, type, description, km, status, service,
  } = workOrder;

  const dataCard = [
    {
      key: 1,
      icon: <BuildIcon />,
      title: 'Tipo',
      firstRow: type || 'No informado',
      width: 150,
    },
    {
      key: 2,
      icon: <DateRangeIcon />,
      title: 'Fecha',
      firstRow: changeFormatDate(date),
      width: 150,
    },
    {
      key: 3,
      icon: <SpeedIcon />,
      title: 'Kilometraje',
      firstRow: km ? `${numberFormatter(km)} Km` : 'No informado',
      width: 150,
    },
    {
      key: 4,
      icon: <SubjectIcon />,
      title: 'Descripción',
      firstRow: description || 'No informado',
      width: 400,
    },
  ];

  return (
    <>
      {!isMobile ? (
        <GeneralCard
          className={index % 2 === 0 ? classes.back1 : classes.back2}
          renderBelow={<VehicleBelowGeneralCard licensePlate={service?.vehicle?.licensePlate} />}
          leftComponent={(
            <LeftComponent
              text={externalId}
              status={status}
              textEnabled="ABIERTO"
              textDisabled="CERRADO"
              width={200}
            />
          )}
          rightComponent={<RightComponent type={type} />}
        >
          {dataCard.map((d) => (
            <GeneralCardItem
              key={d.key}
              icon={d.icon}
              title={d.title}
              firstRow={d.firstRow}
              secondRow={d.secondRow}
              width={d.width}
            />
          ))}
        </GeneralCard>
      ) : (
        <GeneralMobileCard className={index % 2 === 0 ? classes.back1 : classes.back2}>
          <CardMobile workOrder={workOrder} />
        </GeneralMobileCard>
      )}
    </>
  );
};

export default WorkOrderCard;
