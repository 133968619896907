import {
  Box, Button, makeStyles, Typography,
} from '@material-ui/core';
import GeneralCardItem from 'commons/components/GeneralCardItem';
import React, { useState } from 'react';

// Project

// Icons
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import BusinessIcon from '@material-ui/icons/Business';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import PhoneEnabledOutlinedIcon from '@material-ui/icons/PhoneEnabledOutlined';
import MailOutlineOutlinedIcon from '@material-ui/icons/MailOutlineOutlined';
import styles from './styles';

const useStyles = makeStyles(styles);

const DriverRequest = ({ request, onDownRequest }) => {
  const classes = useStyles();
  const [confirmation, setConfirmation] = useState(false);
  const {
    SocialReason,
    User,
    name, run, phone, email,
  } = request;

  const handleDownRequest = () => {
    if (typeof onDownRequest === 'function') onDownRequest();
  };

  const Title = ({ text }) => (
    <Typography variant="h6" color="primary">
      <Box fontSize={16}>
        {text}
      </Box>
    </Typography>
  );

  return (
    <Box className={classes.container}>
      <Box className={classes.detailContainer}>
        <Title text=" Información del solicitante." />
        <Box className={classes.contentContainer}>
          <Box className={classes.itemContainer}>
            <GeneralCardItem
              icon={<BusinessIcon />}
              title="Razón Social"
              firstRow={SocialReason.name}
              secondRow={SocialReason.rut}
            />
          </Box>
          <Box className={classes.itemContainer}>
            <GeneralCardItem
              icon={<AccountCircleOutlinedIcon />}
              title="Usuario"
              firstRow={`${User?.firstName} ${User?.lastName}`}
              secondRow={User?.type?.replace(/_/g, ' ')}
            />
          </Box>
        </Box>
      </Box>
      <Box className={classes.detailContainer}>
        <Title text="Información del conductor para ser ingresada a SAP." />
        <Box className={classes.contentContainer}>
          <Box className={classes.itemContainer}>
            <GeneralCardItem
              icon={<PersonOutlineIcon />}
              title="Nombre"
              firstRow={name}
              secondRow={run}
            />
          </Box>
          <Box className={classes.itemContainer}>
            <GeneralCardItem
              icon={<PhoneEnabledOutlinedIcon />}
              title="Teléfono"
              firstRow={phone || 'No informado'}
            />
          </Box>
          <Box className={classes.itemContainer}>
            <GeneralCardItem
              icon={<MailOutlineOutlinedIcon />}
              title="Email"
              firstRow={email || 'No informado'}
            />
          </Box>
        </Box>
      </Box>
      {
        confirmation
          ? (
            <Box className={classes.confirmationContainer}>
              <Typography variant="h6" color="primary">
                <Box fontSize={16}>
                  ¿Está seguro de esta acción?
                </Box>
              </Typography>
              <Button variant="contained" color="primary" onClick={() => setConfirmation(!confirmation)}>
                <Box fontSize={16} fontWeight="fontWeightRegular">
                  NO
                </Box>
              </Button>
              <Button variant="contained" color="secondary" onClick={() => handleDownRequest()}>
                <Box fontSize={16} fontWeight="fontWeightRegular">
                  SI
                </Box>
              </Button>
            </Box>
          )
          : (
            <Box className={classes.buttonContainer}>
              <Button variant="contained" color="secondary" onClick={() => setConfirmation(!confirmation)}>
                DAR DE BAJA SOLICITUD
              </Button>
            </Box>
          )
      }
    </Box>
  );
};

export default DriverRequest;
