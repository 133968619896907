import {
  takeLatest, put, spawn, select,
} from 'redux-saga/effects';

import { apiSuccess, get, post } from '../../../utils/api';
import * as APP from '../../../commons/reducer';
import { SYSTEM } from '../reducer';

const getNotificationConfiguration = (state) => state.system.controls.notification;
const getWhatsappConfiguration = (state) => state.system.controls.whatsapp;

function* getNotificationData() {
  yield takeLatest(SYSTEM.GET_NOTIFICATION_DATA, function* () {
    const path = 'api/v1/notifications/configuration';
    const response = yield get(path);
    if (!response.error) {
      yield put(apiSuccess(SYSTEM.GET_NOTIFICATION_DATA_FINISHED, response));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { msg: response.errorMsg }));
    }
  });
}

function* saveNotificationConfiguration() {
  yield takeLatest(SYSTEM.SAVE_NOTIFICATION_CONFIGURATION, function* () {
    const path = 'api/v1/notifications/configuration';
    const body = yield select(getNotificationConfiguration);
    const response = yield post(path, body);
    if (!response.error) {
      const { msg } = response;
      yield put(apiSuccess(APP.SET_SUCCESS, { msg }));
      yield put(apiSuccess(SYSTEM.SAVE_NOTIFICATION_CONFIGURATION_SUCCESS));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { msg: response.errorMsg }));
    }
  });
}

function* getWhatsappData() {
  yield takeLatest(SYSTEM.GET_WHATSAPP_DATA, function* () {
    const path = 'api/v1/notifications/configuration/whatsapp';
    const response = yield get(path);
    if (!response.error) {
      yield put(apiSuccess(SYSTEM.GET_WHATSAPP_DATA_FINISHED, response));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { msg: response.errorMsg }));
    }
  });
}

function* saveWhatsappConfiguration() {
  yield takeLatest(SYSTEM.SAVE_WHATSAPP_CONFIGURATION, function* () {
    const path = 'api/v1/notifications/configuration/whatsapp';
    const body = yield select(getWhatsappConfiguration);
    const response = yield post(path, body);
    if (!response.error) {
      const { msg } = response;
      yield put(apiSuccess(APP.SET_SUCCESS, { msg }));
      yield put(apiSuccess(SYSTEM.SAVE_WHATSAPP_CONFIGURATION_SUCCESS));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { msg: response.errorMsg }));
    }
  });
}

export default function* root() {
  yield spawn(getNotificationData);
  yield spawn(saveNotificationConfiguration);
  yield spawn(getWhatsappData);
  yield spawn(saveWhatsappConfiguration);
}
