const styles = (theme) => ({
  graphContainer: {
    width: '100%',
  },
  operationInsideContainer: {
    overflow: 'auto',
    marginTop: theme.spacing(4),
    height: '100%',
    width: '100%',
  },
  operationOutsideContainer: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 500,
    },
  },
});

export default styles;
