import { useMemo } from 'react';

export default function useSocialReasonFilter(socialReasons) {
  const optionsSocialReason = useMemo(() => {
    if (!socialReasons) return [];

    return Object.values(socialReasons).map((h) => ({
      label: h.name,
      value: h.id,
    }));
  }, [socialReasons]);

  return { optionsSocialReason };
}
