import React, { useState } from 'react';

// Material
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Box } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import Typography from '@material-ui/core/Typography';

// Project

// Icons
import SearchIcon from '@material-ui/icons/Search';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import styles from './styles';

const useStyles = makeStyles(styles);

const VehicleList = ({ data = [], selected = [], handleSelect }) => {
  const classes = useStyles();

  return (
    <List role="list" className={classes.itemsContainer}>
      {data.map((value) => (
        <Box
          boxShadow={3}
          key={value.id}
          className={classes.listItem}
          onClick={() => handleSelect(value.id)}
        >
          <ListItem button>
            <ListItemIcon>
              <Checkbox
                color="primary"
                checked={!!selected.find((s) => s === value.id)}
                disableRipple
              />
            </ListItemIcon>
            <ListItemText
              primary={(
                <Typography color="primary" className={classes.licensePlate}>
                  {value.licensePlate}
                </Typography>
                )}
              secondary={(
                <>
                  <Typography
                    color="primary"
                    noWrap
                    className={classes.vehicleModel}
                  >
                    { `${value.brand ? `${value.brand} -` : ''} ${value.model}`}
                  </Typography>
                  <Typography
                    color="primary"
                    noWrap
                    className={classes.assignedToName}
                  >
                    { `${value.assignedToName ? value.assignedToName : 'No informado'}`}
                  </Typography>
                </>
                  )}
            />
          </ListItem>
        </Box>
      ))}
    </List>
  );
};

export default function Administration({
  handleSelect,
  handleAll,
  data = [],
  selected = [],
  title = 'title',
}) {
  const [search, setSearch] = useState('');
  const [sortDesc, setSortDesc] = useState(false);

  const sortFunction = (a, b) => {
    if (a.licensePlate < b.licensePlate) {
      return sortDesc ? 1 : -1;
    } if (a.licensePlate > b.licensePlate) {
      return sortDesc ? -1 : 1;
    }
    return 0;
  };

  const classes = useStyles();
  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        className={classes.filterContainer}
      >
        <Typography color="primary" variant="h6" className={classes.typography} noWrap>
          {title}
        </Typography>
        <TextField
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          color="primary"
          size="small"
          variant="outlined"
          className={classes.searchField}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="primary" />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        className={classes.thirdContainer}
      >
        <FormControlLabel
          control={(
            <Checkbox
              color="primary"
              checked={
                data.length > 0
                && selected.length > 0
                && data.length === selected.length
              }
              onClick={handleAll}
              name="checkedA"
            />
        )}
          label={(
            <Box textOverflow="ellipsis">
              Seleccionar todo
            </Box>
          )}
        />
        <Button
          variant="contained"
          color="primary"
          endIcon={<SwapVertIcon />}
          onClick={() => setSortDesc(!sortDesc)}
        >
          Ordenar
        </Button>
      </Box>
      <VehicleList
        handleSelect={handleSelect}
        selected={selected}
        data={search === ''
          ? data.sort(sortFunction)
          : data
            .filter((value) => value.licensePlate.includes(search.toUpperCase()))
            .sort(sortFunction)}
      />
    </Box>
  );
}
