import {
  takeLatest, spawn, put, select,
} from 'redux-saga/effects';
import controlledCall from 'utils/services/controlledSaga';
import { apiSuccess, get, post } from 'utils/api';
import {
  PAYMENT_STATUS_SECTIONS,
  paymentStatusSectionsActions,
} from 'screens/PaymentStatusSections/reducer';
import { appActions } from 'commons/reducer';
import * as APP from 'commons/reducer';

const getControls = (state) => state.paymentStatusSections.controls;
const getHolding = (state) => state.app.holding.id;

function* getStateFromApi() {
  yield takeLatest(PAYMENT_STATUS_SECTIONS.GET_STATE_FROM_API, function* () {
    const holdingId = yield select(getHolding);
    yield controlledCall(
      get,
      'api/v1/social-reasons/settings',
      `holdingId=${holdingId}`,
      paymentStatusSectionsActions.getStateFromApiSuccess,
      paymentStatusSectionsActions.getStateFromApiFail,
    );
  });
}

function* submitSettings() {
  yield takeLatest(PAYMENT_STATUS_SECTIONS.SUBMIT_SETTINGS, function* () {
    yield put(appActions.setLoader('submit_sections', true));
    const {
      socialReasonId, pendings, additionals, extras,
    } = yield select(getControls);
    const holdingId = yield select(getHolding);
    const response = yield post('api/v1/social-reasons/update-settings', {
      socialReasonId, holdingId, pendings, additionals, extras,
    });
    if (!response.error) {
      yield put(apiSuccess(PAYMENT_STATUS_SECTIONS.SUBMIT_SETTINGS_SUCCESS, response));
      yield put(apiSuccess(APP.SET_SUCCESS, { msg: 'Guardado exitosamente' }));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { msg: 'Error al guardar' }));
    }
    yield put(appActions.setLoader('submit_sections', false));
  });
}
export default function* root() {
  yield spawn(getStateFromApi);
  yield spawn(submitSettings);
}
