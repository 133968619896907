import React, { useEffect, useMemo, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  useMediaQuery,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Autocomplete } from '@material-ui/lab';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import SocialReasonsFilter from 'commons/components/MultipleFilter/SocialReasonsFilter';
import styles from './styles';

const useStyles = makeStyles(styles);

const ModalHolding = ({
  isOpen,
  close,
  options,
  setHolding,
  setSocialReason,
  currentHoldingId,
  socialReasons,
  getSocialReason,
  currentSocialReason,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();

  const [value, setValue] = useState({});
  const [holdingValue, setHoldingValue] = useState(currentHoldingId);
  const [socialReasonValue, setSocialReasonValue] = useState(currentSocialReason);

  const getIds = (data) => [].concat(...data.map((d) => d.value));

  const handleChange = (e, data) => {
    if (data) {
      setHoldingValue(data.value);
      setValue({
        id: data.value,
        name: data.label,
      });
      setSocialReasonValue([]);
    }
  };

  const handleSetHolding = () => {
    if (Object.keys(value).length !== 0) {
      setHolding(value);
    }
    setSocialReason(socialReasonValue);

    close('openHolding');
  };

  const handleChangeSocialReason = ({ value: data }) => {
    if (data) {
      setSocialReasonValue(data);
    }
  };

  useEffect(() => {
    setHoldingValue(currentHoldingId);
    setSocialReasonValue(currentSocialReason);
  }, [isOpen]);

  useEffect(() => {
    if (holdingValue) {
      getSocialReason(holdingValue);
    }
  }, [holdingValue]);

  const optionsSocialReason = useMemo(() => Object.values(socialReasons).map((h) => ({
    label: h.name,
    value: h.id,
  })), [socialReasons]);

  const selectValue = useMemo(() => optionsSocialReason
    .filter((o) => getIds(socialReasonValue).includes(o.value)),
  [socialReasonValue, isOpen]);

  return (
    <Dialog
      onClose={() => close('openHolding')}
      open={isOpen}
      fullScreen={fullScreen}
    >
      <DialogTitle
        onClose={() => close('openHolding')}
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h4" color="primary">
            Holding
          </Typography>
          <IconButton
            color="primary"
            onClick={() => close('openHolding')}
            aria-label="close"
          >
            <CloseIcon fontSize="large" />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent style={{ overflow: 'hidden' }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography color="primary">
              Actualmente usted se encuentra posicionado en el siguiente Holding.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              fullWidth
              value={options.find((c) => c.value === holdingValue) ?? null}
              onChange={handleChange}
              options={options}
              getOptionLabel={(option) => (option.label ? option.label : '')}
              noOptionsText="No se encontraron resultados"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Holdings"
                  variant="outlined"
                  className={classes.borderColor}
                />
              )}
            />
          </Grid>
          <SocialReasonsFilter
            limitTags={3}
            value={selectValue}
            options={optionsSocialReason}
            onChange={handleChangeSocialReason}
          />
          <Grid item xs={12}>
            <Typography color="primary">
              Cuando se seleccione otro holding, se volverán a cargar los nuevos
              datos.
            </Typography>
          </Grid>
          <Grid item xs={12} align="right">
            <Button
              color="primary"
              variant="contained"
              onClick={handleSetHolding}
            >
              Aplicar cambios
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ModalHolding;
