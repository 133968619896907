import camelize from 'camelcase-keys-deep';

export default class Holding {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  get socialReasons() {
    let response = [];
    if (this.id) {
      response = this.env().SocialReason.findAllBy('holdingId', this.id);
    }
    return response;
  }

  get fleets() {
    let response = [];
    if (this.id) {
      response = this.env().Fleet.findAllBy('holdingId', this.id);
    }
    return response;
  }
}
