import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { FILTER_OPTIONS } from './constants';
import { filterDate, filterName, filterStatus } from './components/Filter/functions';
import sortHoldingsBy from './sort';

export default function useHoldings(allHoldings, controls, actions) {
  const history = useHistory();
  const [holdings, setHoldings] = useState({
    holdings: [],
    processedHoldings: [],
  });

  const filterHoldings = (holdingsArray) => holdingsArray
    .filter(filterName(controls[FILTER_OPTIONS.NAME]))
    .filter(filterStatus(controls[FILTER_OPTIONS.STATUS]))
    .filter(filterDate(controls[FILTER_OPTIONS.DATE]));

  // initialize holdings list
  useEffect(() => {
    setHoldings((prevHoldings) => {
      if (prevHoldings.holdings.length) return prevHoldings;

      const sortedList = sortHoldingsBy(
        Object.values(allHoldings), controls.orderBy, controls.desc,
      );
      const filteredList = filterHoldings(sortedList);

      return {
        holdings: sortedList,
        processedHoldings: filteredList,
      };
    });
  }, [allHoldings]);

  useEffect(() => {
    setHoldings((prevHoldings) => ({
      holdings: sortHoldingsBy(prevHoldings.holdings, controls.orderBy, controls.desc),
      processedHoldings:
        sortHoldingsBy(prevHoldings.processedHoldings, controls.orderBy, controls.desc),
    }));
  }, [controls.orderBy, controls.desc]);

  useEffect(() => {
    if (holdings.processedHoldings) {
      actions.paginationSetup(holdings.processedHoldings.length);
    }
  }, [holdings.processedHoldings.length]);

  const handleFilter = () => {
    actions.applyFilter();
    setHoldings((prevHoldings) => ({
      holdings: prevHoldings.holdings,
      processedHoldings: filterHoldings(prevHoldings.holdings),
    }));
  };

  const handleClearFilter = () => {
    setHoldings((prevHoldings) => ({
      holdings: prevHoldings.holdings,
      processedHoldings: prevHoldings.holdings,
    }));
    actions.resetFilter();
  };

  const handleCreateHolding = (name, rut, withSocialReason) => (
    withSocialReason
      ? actions.addHoldingSR({ name, rut, history })
      : actions.addHolding({ name, rut, history })
  );

  return {
    processedHoldings: holdings.processedHoldings,
    holdings: holdings.holdings,
    handleFilter,
    handleClearFilter,
    handleCreateHolding,
  };
}
