import React from 'react';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Typography, Box, Tooltip } from '@material-ui/core';
import { connect } from 'react-redux';
import { ReactReduxContext } from 'config/configureStore';

const ScreenTitle = ({
  title, showSubTitle = true, holding, socialReason,
}) => (
  <Box>
    <Typography color="primary" variant="h4">
      <Box fontWeight={300} fontSize={28}>
        {title}
      </Box>
    </Typography>
    {showSubTitle
      && (
        <Typography color="primary" component="div">
          <Box
            display="flex"
            alignItems="center"
            fontSize={12}
          >
            <BookmarkIcon fontSize="small" />
            <Tooltip title={
              socialReason.length > 0 ? (
                <Typography component="div">
                  {socialReason.map((s) => (
                    <Box key={s.value} fontSize={12}>
                      -
                      {' '}
                      {s.label}
                    </Box>
                  ))}
                </Typography>
              ) : ''
            }
            >
              <Box>
                {holding?.name}
              </Box>
            </Tooltip>
            <ArrowDropDownIcon />
          </Box>
        </Typography>
      )}
  </Box>
);

const msp = (state) => ({
  holding: state.app.holding,
  socialReason: state.app.socialReason,
});
export default connect(
  msp,
  null,
  null,
  { context: ReactReduxContext },
)(ScreenTitle);
