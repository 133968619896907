import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import SvgIcon from '@material-ui/core/SvgIcon';

const DownloadIcon = () => (
  <SvgIcon>
    <svg width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.82605 10.02C5.76389 9.94169 5.68669 9.87657 5.59899 9.82851C5.51129 9.78044 5.41487 9.7504 5.31539 9.74015C5.21591 9.72991 5.11538 9.73966 5.01973 9.76883C4.92407 9.79801 4.83521 9.84602 4.75839 9.91004C4.68156 9.97406 4.61831 10.0528 4.57237 10.1416C4.52642 10.2305 4.4987 10.3276 4.49084 10.4273C4.48299 10.527 4.49515 10.6272 4.52661 10.7222C4.55807 10.8171 4.6082 10.9048 4.67405 10.98L8.02355 15L4.67405 19.02C4.55235 19.1734 4.49546 19.3683 4.51552 19.5631C4.53559 19.7578 4.63102 19.937 4.78143 20.0624C4.93185 20.1877 5.12532 20.2493 5.32051 20.2339C5.5157 20.2185 5.69713 20.1274 5.82605 19.98L9.00005 16.1715L12.174 19.9815C12.3016 20.1343 12.4845 20.2302 12.6827 20.248C12.7808 20.2569 12.8797 20.2463 12.9738 20.2169C13.0678 20.1875 13.1552 20.1399 13.2308 20.0768C13.3064 20.0136 13.3689 19.9362 13.4146 19.849C13.4604 19.7617 13.4884 19.6663 13.4973 19.5681C13.5061 19.47 13.4956 19.3711 13.4662 19.2771C13.4368 19.183 13.3892 19.0957 13.326 19.02L9.97655 15L13.326 10.98C13.4477 10.8267 13.5046 10.6318 13.4846 10.437C13.4645 10.2422 13.3691 10.063 13.2187 9.9377C13.0683 9.81235 12.8748 9.7508 12.6796 9.76618C12.4844 9.78157 12.303 9.87267 12.174 10.02L9.00005 13.8285L5.82605 10.0185V10.02Z" fill="#002855" />
      <path d="M18 21V6.75L11.25 0H3C2.20435 0 1.44129 0.316071 0.87868 0.87868C0.31607 1.44129 0 2.20435 0 3V21C0 21.7956 0.31607 22.5587 0.87868 23.1213C1.44129 23.6839 2.20435 24 3 24H15C15.7956 24 16.5587 23.6839 17.1213 23.1213C17.6839 22.5587 18 21.7956 18 21ZM11.25 4.5C11.25 5.09674 11.4871 5.66903 11.909 6.09099C12.331 6.51295 12.9033 6.75 13.5 6.75H16.5V21C16.5 21.3978 16.342 21.7794 16.0607 22.0607C15.7794 22.342 15.3978 22.5 15 22.5H3C2.60218 22.5 2.22064 22.342 1.93934 22.0607C1.65804 21.7794 1.5 21.3978 1.5 21V3C1.5 2.60218 1.65804 2.22064 1.93934 1.93934C2.22064 1.65804 2.60218 1.5 3 1.5H11.25V4.5Z" fill="#002855" />
    </svg>
  </SvgIcon>
);

const DownloadButton = ({
  loading, total, onClick, text = 'Descargar tabla',
}) => {
  const handleClick = () => {
    onClick();
  };
  return (
    <Button
      color="primary"
      onClick={handleClick}
      startIcon={<DownloadIcon />}
      disabled={!(total > 0) || loading}
    >
      <Box fontWeight={600}>
        {text}
      </Box>
    </Button>
  );
};

export default DownloadButton;
