import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';
import CustomFilterButton from 'commons/components/CustomFilterButton';
import MultipleFilter from 'commons/components/MultipleFilter';
import SocialReasonsFilter from 'commons/components/MultipleFilter/SocialReasonsFilter';
import styles from './styles';

const useStyles = makeStyles(styles);

const Filter = ({
  controls, onChange, vehicles, services, types, handleFilter, handleClearFilter,
  handleChangeSocialReason, optionsSocialReason, selectValue,
}) => {
  const classes = useStyles();
  const optionStatus = [
    { id: 1, value: true, label: 'ABIERTO' },
    { id: 2, value: false, label: 'CERRADO' },
  ];

  return (
    <Grid container spacing={1} className={classes.container}>
      <SocialReasonsFilter
        xs={12}
        md={4}
        lg={3}
        filterField
        value={selectValue || []}
        options={optionsSocialReason}
        onChange={handleChangeSocialReason}
      />
      <Grid item xs={12} md={4} lg={3}>
        <MultipleFilter
          id="service"
          label="Detención"
          value={controls.service}
          options={services}
          onChange={onChange}
        />
      </Grid>
      <Grid item xs={12} md={4} lg={2}>
        <MultipleFilter
          id="status"
          label="Estado"
          value={controls.status}
          options={optionStatus}
          onChange={onChange}
        />
      </Grid>
      <Grid item xs={12} md={4} lg={2}>
        <MultipleFilter
          id="vehicle"
          label="Patente"
          value={controls.vehicle}
          options={vehicles}
          onChange={onChange}
        />
      </Grid>
      <Grid item xs={12} md={4} lg={2}>
        <MultipleFilter
          id="type"
          label="Tipo"
          value={controls.type}
          options={types}
          onChange={onChange}
        />
      </Grid>

      <Grid item xs={12} md={12} lg={12}>
        <CustomFilterButton
          onClick={handleFilter}
          clear={handleClearFilter}
        />
      </Grid>
    </Grid>
  );
};

export default Filter;
