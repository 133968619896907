const styles = (theme) => ({
  card: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paginationContainer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: ' end',
  },
  card3: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 0,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(4),
  },
  back3: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 0,
  },
  listTitle: {
    marginBottom: theme.spacing(2),
  },
  whiteTypography: {
    color: '#FFFFFF',
  },
  title: {
    color: 'white',
  },
});

export default styles;
