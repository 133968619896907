import camelize from 'camelcase-keys-deep';

export default class Vehicle {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  get contract() {
    return this.contractId ? this.env().Contract.findBy('id', this.contractId) : null;
  }
}
