import { spawn, takeLatest, put } from 'redux-saga/effects';
import { fileActions, FILE_TYPES } from '../reducer/uploadFile.reducer';

function* UploadFile() {
  yield takeLatest(FILE_TYPES.START_FILE_UPLOAD, function* (action) {
    const { payload } = action;
    const data = new FormData();
    data.append('file', payload);

    yield put(fileActions.endFileUpload());
  });
}

export default function* auctionManagerSaga() {
  yield spawn(UploadFile);
}
