import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';

import styles from './styles';

const useStyles = makeStyles(styles);

const CustomPieChart = ({
  percentage = 0,
  insideText = null,
  text = '',
  subText = '',
  onClick = () => {},
  opacity = 1,
  size,
  fontSize,
  titleFontSize = 16,
  subtitleFontSize = 14,
  thickness = 5.5,
  isSelected = false,
  showPercentage = true,
  extraText = '',
}) => {
  const classes = useStyles({ opacity, isSelected });

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          position="relative"
          display="inline-flex"
          className={classes.box}
          onClick={onClick}
        >
          {/* Graphic */}
          <CircularProgress
            variant="determinate"
            className={classes.bottom}
            thickness={thickness}
            size={size}
            value={100}
          />
          <CircularProgress
            variant="determinate"
            className={classes.top}
            size={size}
            thickness={thickness}
            value={percentage}
          />
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"

          >
            <Typography
              component="div"
            >
              <Box className={classes.text} display="inline-block" fontSize={fontSize} fontWeight="bold" lineHeight="normal">
                {insideText || `${percentage}${showPercentage ? '%' : ''}`}
              </Box>
            </Typography>
          </Box>
        </Box>

        <Typography variant="h6" noWrap>
          <Box color="primary.main" textAlign="center" fontSize={titleFontSize}>
            {text}
            <br />
            {extraText}
          </Box>
          <Box textAlign="center" color="text.secondary" fontSize={subtitleFontSize}>
            {subText || <Box height="22.39px" />}
          </Box>
        </Typography>
      </Box>

    </>
  );
};

export default CustomPieChart;
