import React from 'react';
import Grid from '@material-ui/core/Grid';

import { Table } from '../../../../utils/skeletons';

const TableSkeleton = () => (
  <Grid
    container
  >
    <Grid item xs={12} md={12} style={{ marginTop: '12px' }}>
      <Table cells={8} />
    </Grid>
  </Grid>
);

export default TableSkeleton;
