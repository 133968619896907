import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Table, Title } from 'utils/skeletons';
import { Skeleton } from '@material-ui/lab';

const WorkOrderSkeleton = () => {
  const Filter = () => (
    <>
      <Grid item xs={12} md={3} lg={2}>
        <Skeleton variant="rect" width="100%" height={56} />
      </Grid>
      <Grid item xs={12} md={3} lg={2}>
        <Skeleton variant="rect" width="100%" height={56} />
      </Grid>
      <Grid item xs={12} md={3} lg={2}>
        <Skeleton variant="rect" width="100%" height={56} />
      </Grid>
      <Grid item xs={12} md={3} lg={2}>
        <Skeleton variant="rect" width="100%" height={56} />
      </Grid>
    </>
  );

  return (
    <Grid
      container
      spacing={2}
    >
      <Grid item xs={12}>
        <Title width={240} />
      </Grid>
      <Filter />
      <Grid item xs={12}>
        <Table
          cells={5}
          height={65}
        />
      </Grid>
    </Grid>
  );
};

export default WorkOrderSkeleton;
