import React from 'react';
import { LinearProgress } from '@material-ui/core';
import { Redirect, useLocation } from 'react-router-dom';
import AuthUserContext from '../../commons/context/user';
import Layout from '../../commons/containers/Layout';

const Auth = ({ user, loading, children }) => {
  const location = useLocation();

  if (user) {
    if (location.pathname === '/recovery-password' || location.pathname === '/update-password') {
      localStorage.clear();
      sessionStorage.clear();
      return <Redirect to={`${location.pathname + location.search}`} />;
    }
    if (location.pathname === '/login') {
      return <Redirect to="/" />;
    }

    return (
      <Layout>
        <AuthUserContext.Provider value={user}>{children}</AuthUserContext.Provider>
      </Layout>
    );
  }

  if (loading) {
    return <LinearProgress />;
  }

  if (location.pathname === '/recovery-password' || location.pathname === '/update-password') {
    localStorage.clear();
    sessionStorage.clear();
    return <Redirect to={`${location.pathname + location.search}`} />;
  }

  return <Redirect to="/login" />;
};
export default Auth;
