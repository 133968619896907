import commonStyles from '../../utils/commonStyles';

const styles = () => ({
  root: {
    marginBottom: '50px',
  },
  formRoot: {
    marginTop: '20px',
  },
  checkbox: {
    marginBottom: '24px',
  },
  ...commonStyles,
});

export default styles;
