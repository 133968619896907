export const SOCIAL_REASON_COLS = {
  ID: 'ID Razon Social',
  RUT: 'RUT',
  NAME: 'Nombre',
  CREATED_AT: 'Fecha de creación',
  STATE: 'Estado',
};

export const SOCIAL_REASON_ATTR = {
  ID: 'id',
  RUT: 'rut',
  NAME: 'name',
  CREATED_AT: 'createdAt',
  STATE: 'isActive',
};
