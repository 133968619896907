import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Box from '@material-ui/core/Box';
import CardNotification from 'commons/components/CardNotification';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',

  },
  heading: {
    flexBasis: '95%',
    flexShrink: 0,
    [theme.breakpoints.down('xs')]: {
      flexBasis: '90%',
    },
  },
  total: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    borderRadius: '50%',
    m: 1,
    border: 1,
    width: '24px',
    height: '24px',
  },
  title: {
    color: theme.palette.primary.main,
  },
  accordionSummary: {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
}));

const CustomAccordion = ({
  title, id, total, notifications, onClickNotification, handleClose,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const sortByDate = (a, b) => new Date(b.createdAt) - new Date(a.createdAt);

  return (
    <div className={classes.root}>
      <Accordion expanded={expanded === id} onChange={handleChange(id)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          id={id}
          className={classes.accordionSummary}
        >
          <Typography className={classes.heading}>
            <span className={classes.title}>{title}</span>
          </Typography>

          <Box
            className={classes.total}
          >
            <Typography className={classes.heading} align="center">
              <span style={{ color: 'white' }}>
                {total}
              </span>
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            display="flex"
            flexDirection="column"
          >
            {notifications.sort(sortByDate).map((n) => (
              <CardNotification
                key={n.id}
                data={n}
                maxWidth="500px"
                handleClick={onClickNotification}
                handleClose={handleClose}
              />
            ))}
          </Box>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default CustomAccordion;
