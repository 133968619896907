import { Box, makeStyles, Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { randomString } from 'utils/functions';

// icons
import PersonAddIcon from '@material-ui/icons/PersonAdd';

import React from 'react';
import styles from './styles';

const useStyles = makeStyles(styles);
const RequestList = ({
  requests, selected, handleSelect, loading = true,
}) => {
  const DriverRequestItem = ({ request }) => {
    const { id } = request;
    const classes = useStyles({ selected: selected === id });
    return (
      <Box
        className={classes.requestButton}
        onClick={() => handleSelect(id)}
      >
        <Typography variant="h6" noWrap>
          <Box className={classes.requestContent} fontSize={16} fontWeight="normal">
            NUEVO CONDUCTOR
          </Box>
        </Typography>
        <PersonAddIcon className={classes.requestContent} />
      </Box>
    );
  };

  const classes = useStyles({ selected });

  if (loading) {
    const skeletons = [];
    for (let i = 0; i <= 5; i = 1 + i) {
      skeletons.push(<Skeleton key={randomString()} variant="rect" className={classes.skeletonList} />);
    }
    return skeletons;
  }
  if (requests.length === 0) {
    return (
      <Box className={classes.noRequests}>
        <Typography color="primary" variant="h6">
          <Box fontSize={16} fontWeight="normal" textAlign="center">
            Sin solicitudes pendientes.
          </Box>
        </Typography>
      </Box>
    );
  }

  return (
    <>
      {requests.map((request) => (
        <DriverRequestItem
          key={request.id}
          request={request}
        />
      ))}
    </>
  );
};

export default RequestList;
