import {
  Button, Dialog, DialogActions, DialogContent,
  DialogTitle, Grid, IconButton, TextField, makeStyles,
} from '@material-ui/core';
import React, { useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { Autocomplete } from '@material-ui/lab';

import styles from './styles';

const useStyles = makeStyles(styles);

const ReassignDialog = ({
  isOpen, holdings, onSubmit, onClose, holding,
}) => {
  const [selectedHolding, setSelectedHolding] = useState(null);
  const [error, setError] = useState(false);
  const classes = useStyles();

  const handleClose = () => {
    setSelectedHolding(null);
    onClose();
  };
  const handleSelectHolding = (e, value) => {
    setError(false);
    setSelectedHolding(value);
  };
  const handleSubmit = () => {
    if (!selectedHolding) {
      setError(true);
    } else {
      setError(false);
      onSubmit({ sourceId: holding.id, targetId: selectedHolding.id, handleClose });
    }
  };

  return (
    <Dialog fullWidth className={classes.reassignDialog} open={isOpen} onClose={onClose}>
      <DialogTitle>
        <Grid container alignItems="center">
          <Grid item className={classes.title}>
            Mover holding
          </Grid>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Autocomplete
          noOptionsText="No se encontraron resultados"
          options={holdings}
          value={selectedHolding}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Holding objetivo"
              variant="outlined"
              error={error}
              helperText={error ? 'Seleccione un holding' : ''}
              required
            />
          )}
          onChange={handleSelectHolding}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          onClick={handleSubmit}
          className={classes.filterButton}
        >
          mover
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReassignDialog;
