const styles = (theme) => ({
  root: {
    padding: theme.spacing(4),
    marginBottom: theme.spacing(1),
    boxShadow: theme.shadows[4],
    borderRadius: 8,
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    background: theme.palette.primary.main,
  },
  button: {
    display: 'flex',
    float: 'right',
    fontWeight: theme.typography.fontWeightMedium,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
});

export default styles;
