import React, { useEffect } from 'react';

// Project
import ScreenTitle from 'commons/components/ScreenTitle';
import { composedComponent } from 'utils/functions';
import { paymentStatusActions } from 'screens/PaymentStatus/reducer';
import ListSkeleton from 'screens/PaymentStatus/components/Skeleton';
import { useMediaQuery, useTheme } from '@material-ui/core';
import Filter from './components/Filter';
import PaymentStatusList from './components/PaymentStatusList';
import saga from './saga';
import ClosePreviousPsButton from './components/ClosePreviousPsButton';
import ClosePreviousPsDialog from './components/ClosePreviousPsDialog';
import CloseActualPsDialog from './components/CloseActualPsDialog';

// Material

// icons
const PaymentStatus = ({
  loading,
  pageLoading,
  controls,
  pagination,
  actions,
  paymentStatusDTOList,
  status,
  loadingFilter,
  clients,
  periods,
  holding,
  socialReason,
  user,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const role = user?.user?.type;

  useEffect(() => {
    if (holding?.id) {
      actions.getInitialData();
    }
  }, [holding,
    socialReason, user]);

  useEffect(() => {
    if (holding?.id) {
      actions.getFilterData();
    }
  }, [holding,
    socialReason]);

  return (
    loading ? <ListSkeleton />
      : (
        <>
          <ScreenTitle title="ESTADOS DE PAGO" />
          <Filter
            clients={clients}
            periods={periods}
            status={status}
            controls={controls}
            actions={actions}
            isLoading={loading}
            pageLoading={pageLoading}
            loadingFilter={loadingFilter}
            isMobile={isMobile}
          />
          <PaymentStatusList
            paymentStatusDTOList={paymentStatusDTOList}
            pagination={pagination}
            actions={actions}
            loading={loading}
            isMobile={isMobile}
          />
          <ClosePreviousPsButton actions={actions} role={role} />
          <ClosePreviousPsDialog
            controls={controls}
            actions={actions}
            disabledSubmit={pageLoading}
          />
          <CloseActualPsDialog
            controls={controls}
            actions={actions}
            disabledSubmit={pageLoading}
          />
        </>
      )
  );
};

export default composedComponent(PaymentStatus, saga, {
  saga: 'sagaPaymentStatus',
  states: [
    'paymentStatus.controls',
    'paymentStatus.roles',
    'paymentStatus.paymentStatusDTOList',
    'paymentStatus.clients',
    'paymentStatus.periods',
    'paymentStatus.status',
    'paymentStatus.pagination',
    'paymentStatus.loading',
    'paymentStatus.pageLoading',
    'paymentStatus.loadingFilter',
    'app.objects',
    'app.user',
    'app.holding',
    'app.socialReason',
  ],
  actions: [paymentStatusActions],
});
