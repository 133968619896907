import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import CustomPieChart from 'commons/components/CustomPieChart';
import styles from './styles';

const useStyles = makeStyles(styles);

// TODO: set to general components
const useWidth = () => {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || 'xs'
  );
};

const size = {
  xs: { graphic: 100, font: 10 },
  sm: { graphic: 100, font: 14 },
  md: { graphic: 120, font: 14 },
  lg: { graphic: 120, font: 16 },
  xl: { graphic: 120, font: 16 },
};

const IndicatorZone = ({ kpi }) => {
  const classes = useStyles();
  const { totalMaintenance, totalVehicles } = kpi;
  const width = useWidth();

  return (
    <Box className={classes.kpiContainer}>
      <CustomPieChart
        percentage={Math.round((totalMaintenance / totalVehicles) * 100) || 0}
        insideText={totalMaintenance || 0}
        text="Vehículos en mantención"
        size={size[width].graphic}
        titleFontSize={size[width].font}
        fontSize={28}
        showPercentage={false}
      />
    </Box>
  );
};

export default IndicatorZone;
