import React from 'react';
import { Grid, Typography } from '@material-ui/core';

const ClientSettingsAdminHeader = ({ classes }) => (

  <Grid container className={classes.header}>
    <Grid item xs={12} sm={6}>
      <Typography className={classes.headerText}>Nombre</Typography>
    </Grid>
    <Grid item xs={12} sm={6} className={classes.alignCenter}>
      <Typography className={classes.headerText}>Seleccionar principal</Typography>
    </Grid>
  </Grid>
);

export default ClientSettingsAdminHeader;
