import React, { useMemo } from 'react';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from '@material-ui/core';
import CustomAutoComplete from 'commons/components/CustomAutoComplete';
import ButtonWithLoader from 'commons/containers/ButtonWithLoader';

const Form = ({
  classes, controls, handleChange, handleSubmit, handleCheckboxChange,
}) => {
  const {
    socialReasonId, psSections, pendings, additionals, extras,
  } = controls;

  const autoCompleteOptions = useMemo(() => psSections.map((psSection) => ({
    id: psSection.socialReasonId,
    value: psSection.socialReasonId,
    label: psSection['SocialReason.name'],
  })),
  [psSections, socialReasonId]);

  const disableCheckboxes = useMemo(() => socialReasonId === '', [socialReasonId]);

  return (
    <>
      <Typography variant="h4" color="primary" className={classes.mb20}>Seleccione Razón social</Typography>
      <Grid container spacing={4}>
        <Grid item md={2} xs={12}>
          <CustomAutoComplete
            id="socialReason"
            name="socialReason"
            label="Razón social"
            size="small"
            fullWidth={false}
            variant="outlined"
            value={socialReasonId}
            options={autoCompleteOptions}
            onChange={handleChange}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <FormControlLabel
            control={<Checkbox checked={pendings} name="pendings" onChange={handleCheckboxChange} />}
            label="Período anterior"
            disabled={disableCheckboxes}
          />
          <FormControlLabel
            control={<Checkbox checked={additionals} name="additionals" onChange={handleCheckboxChange} />}
            label="Adicionales"
            disabled={disableCheckboxes}
          />
          <FormControlLabel
            control={<Checkbox checked={extras} name="extras" onChange={handleCheckboxChange} />}
            label="Extras"
            disabled={disableCheckboxes}
          />
        </Grid>
        <Grid item md={2} xs={12}>
          <ButtonWithLoader
            id="submit_sections"
            variant="contained"
            color="secondary"
            size="large"
            onClick={handleSubmit}
            disabled={disableCheckboxes}
          >
            Guardar cambios
          </ButtonWithLoader>
        </Grid>
      </Grid>
    </>
  );
};

export default Form;
