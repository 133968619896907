import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Project
import ScreenTitle from 'commons/components/ScreenTitle';
import CustomFloatButton from 'commons/components/CustomFloatButton';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import FilterListOutlinedIcon from '@material-ui/icons/FilterListOutlined';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from 'screens/User/components/Skeleton';
import Grid from '@material-ui/core/Grid';
import UserSearch from 'screens/User/components/UserSearch';
import Hidden from '@material-ui/core/Hidden';
import { composedComponent } from 'utils/functions';
import DownloadButton from 'commons/components/DownloadButton';
import HoldingDialog from './components/HoldingDialog';
import MobileFilter from './components/MobileFilter';
import Filter from './components/Filter';
import UserList from './components/UserList';
import saga from './saga';
import { userActions } from './reducer';

import styles from './styles';

// Material

// icons

const useStyles = makeStyles(styles);
const User = ({
  loading,
  pageLoading,
  controls,
  roles,
  pagination,
  objects,
  actions,
  userHoldings,
  isOpen,
  holding,
  socialReason,
  user,
  loadingDownload,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    actions.getInitialData();
  }, [holding, user, socialReason]);

  const rolesTypes = useMemo(() => Object.values(roles).map((t, i) => ({
    id: i,
    value: t,
    label: t.replace(/_/g, ' '),
  })), [roles]);

  const status = [
    { id: 1, value: 'enabled', label: 'HABILITADO' },
    { id: 2, value: 'disabled', label: 'DESHABILITADO' },
  ];

  const handleDownload = () => {
    actions.downloadExcel();
  };

  const users = Object.keys(objects.users)
    .map((key) => ({ ...objects.users[key] }));

  const holdings = Object.keys(objects.holdings)
    .filter((h) => h.isActive)
    .map((key) => ({ ...objects.holdings[key] }));

  const [show, setShow] = useState(false);

  const handleOpenUserSearch = () => {
    actions.openUserSearch();
  };

  return (
    loading ? <Skeleton />
      : (
        <>

          <ScreenTitle title="CONFIGURACIÓN USUARIOS" showSubTitle={false} />

          <Filter
            holdings={holdings}
            roles={rolesTypes}
            statusType={status}
            controls={controls}
            actions={actions}
            isLoading={loading}
            pageLoading={pageLoading}
          />
          <Button
            variant="contained"
            color="default"
            size="large"
            fullWidth
            onClick={() => setShow(true)}
            className={classes.filterButton}
            startIcon={<FilterListOutlinedIcon />}
          >
            Filtros
          </Button>
          <MobileFilter
            holdings={holdings}
            roles={rolesTypes}
            statusType={status}
            controls={controls}
            actions={actions}
            show={show}
            setShow={setShow}
            isLoading={loading || pageLoading}
          />

          <HoldingDialog
            holdings={userHoldings}
            show={controls.holdingDialog}
            closeDialog={actions.closeHoldingDialog}
          />
          <Hidden smDown>
            <Grid container>
              <Grid item xs={12} md={6}>
                <UserSearch
                  isOpen={isOpen}
                  value={controls.userName}
                  onChange={actions.changeControls}
                  onClickIcon={handleOpenUserSearch}
                  onKeyPress={actions.fetchWithFilter}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  marginTop={1}
                  marginBottom={0.3}
                  display="flex"
                  justifyContent="end"
                >
                  <DownloadButton
                    total={users.length}
                    onClick={handleDownload}
                    loading={loadingDownload}
                  />
                </Box>
              </Grid>
            </Grid>
          </Hidden>

          <UserList
            pagination={pagination}
            isMobile={isMobile}
            actions={actions}
            loading={loading}
            users={users}
          />

          <CustomFloatButton
            handleClick={() => history.push('user/create')}
          />
        </>
      )

  );
};

export default composedComponent(User, saga, {
  saga: 'sagaUser',
  states: [
    'user.controls',
    'user.roles',
    'user.pagination',
    'user.loading',
    'user.pageLoading',
    'user.userHoldings',
    'user.isOpen',
    'user.loadingDownload',
    'app.objects',
    'app.user',
    'app.holding',
    'app.socialReason',
  ],
  actions: [userActions],
});
