import React, { useEffect } from 'react';

// Material
import { withStyles } from '@material-ui/core';
// import Grid from '@mui/material/Grid';

// Project
import { composedComponent } from 'utils/functions';
import ScreenTitle from 'commons/components/ScreenTitle';

// Components
import {
  paymentStatusSectionsActions,
} from 'screens/PaymentStatusSections/reducer';
import Form from './components/Form';
// Utils
import saga from './saga';

// Styles
import styles from './styles';

const PaymentStatusSections = (props) => {
  const {
    classes, actions, controls, holding, socialReason,
  } = props;
  useEffect(() => {
    actions.getStateFromApi();
    actions.changePsSectionsControls('socialReasonId', '');
  }, [holding, socialReason]);
  const handleChange = (e) => {
    actions.changePsSectionsControls('socialReasonId', e.target.value);
    const socialReasonCheckboxes = controls.psSections.find((psSection) => (
      psSection.socialReasonId === e.target.value));
    actions.changePsSectionsControls('pendings', socialReasonCheckboxes.pendings);
    actions.changePsSectionsControls('additionals', socialReasonCheckboxes.additionals);
    actions.changePsSectionsControls('extras', socialReasonCheckboxes.extras);
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    actions.changePsSectionsControls(name, checked);
  };

  const handleSubmit = () => {
    actions.submitSettings();
  };

  return (
    <>
      <ScreenTitle title="CONFIGURACIÓN SECCIONES DEL ESTADO DE PAGO" />
      <Form
        classes={classes}
        controls={controls}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        handleCheckboxChange={handleCheckboxChange}
      />
    </>
  );
};

export default composedComponent(PaymentStatusSections, saga, {
  saga: 'sagaPaymentStatusSections',
  middlewares: [withStyles(styles)],
  states: [
    'app.user',
    'app.objects',
    'app.holding',
    'app.socialReason',
    'paymentStatusSections.controls',
  ],
  actions: [paymentStatusSectionsActions],
});
