export const PAYMENT_STATUS = Object.freeze({
  0: 'No inicializado',
  1: 'Borrador',
  2: 'Revisión',
  // 3: 'Revisión salfa',
  4: 'Aprobado',
  5: 'Cerrado',
});

export const FLEET_STATUS = Object.freeze({
  0: 'No inicializada',
  1: 'Borrador',
  2: 'Revisión Cliente',
  3: 'Revisión Salfa',
  4: 'Aprobada',
  5: 'Cerrada',
});

export const PAYMENT_STATUS_STATUS = Object.freeze({
  NOT_INITIALIZED: 0,
  DRAFT: 1,
  REVIEW: 2,
  APPROVED: 4,
  CLOSED: 5,
});

export const PAYMENT_STATUS_FLEET = Object.freeze({
  DRAFT: 1,
  CUSTOMER_REVIEW: 2,
  ADMIN_REVIEW: 3,
  APPROVED: 4,
  CLOSED: 5,
});

export const LEASE_DETAIL = Object.freeze({
  ADDITIONAL: 1,
  DISCOUNT: 2,
});
