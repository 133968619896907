import { createAction, createReducer } from 'utils/reducer';

// Local constants
const PATH = 'userSetting/';

const CHANGE_CONTROLS = `${PATH}CHANGE_CONTROLS`;

// Global constants for saga
export const USERSETTING = {
  // Geat holding and rol data
  FETCH_INITIAL_DATA: `${PATH}FETCH_INITIAL_DATA`,
  FETCH_INITIAL_DATA_SUCCESS: `${PATH}FETCH_INITIAL_DATA_SUCCESS`,
  FETCH_INITIAL_DATA_ERROR: `${PATH}FETCH_INITIAL_DATA_ERROR`,
  FETCH_USER: `${PATH}FETCH_USER`,
  FETCH_USER_SUCCESS: `${PATH}FETCH_USER_SUCCESS`,
  FETCH_USER_ERROR: `${PATH}FETCH_USER_ERROR`,
  FETCH_CONTRACT_ADMINS: `${PATH}FETCH_CONTRACT_ADMINS`,
  FETCH_CONTRACT_ADMINS_SUCCESS: `${PATH}FETCH_CONTRACT_ADMINS_SUCCESS`,
  CREATE_USER: `${PATH}CREATE_USER`,
  CREATE_USER_SUCCESS: `${PATH}CREATE_USER_SUCCESS`,
  CREATE_USER_ERROR: `${PATH}CREATE_USER_ERROR`,
  EDIT_USER: `${PATH}EDIT_USER`,
  EDIT_USER_SUCCESS: `${PATH}EDIT_USER_SUCCESS`,
  EDIT_USER_ERROR: `${PATH}EDIT_USER_ERROR`,
  FETCH_USER_HOLDINGS: `${PATH}FETCH_USER_HOLDINGS`,
  FETCH_USER_HOLDINGS_SUCCESS: `${PATH}FETCH_USER_HOLDINGS_SUCCESS`,
  FETCH_USER_HOLDINGS_ERROR: `${PATH}FETCH_USER_HOLDINGS_ERROR`,
  TOGGLE_TEMP_HOLDING: `${PATH}TOGGLE_TEMP_HOLDING`,
  ADD_TEMP_HOLDINGS: `${PATH}ADD_TEMP_HOLDINGS`,
  RESET_TEMP_HOLDINGS: `${PATH}RESET_TEMP_HOLDINGS`,
  RESET_CONTROLS: `${PATH}RESET_CONTROLS`,
};

// actions
export const userSettingActions = {
  fetchData: createAction(USERSETTING.FETCH_INITIAL_DATA, 'payload'),
  fetchUser: createAction(USERSETTING.FETCH_USER, 'payload'),
  fetchUserHoldings: createAction(USERSETTING.FETCH_USER_HOLDINGS, 'payload'),
  toggleTempHolding: createAction(USERSETTING.TOGGLE_TEMP_HOLDING, 'payload'),
  addTempHoldings: createAction(USERSETTING.ADD_TEMP_HOLDINGS),
  resetTempHoldings: createAction(USERSETTING.RESET_TEMP_HOLDINGS),
  createUser: createAction(USERSETTING.CREATE_USER, 'payload'),
  editUser: createAction(USERSETTING.EDIT_USER, 'payload'),
  changeControls: createAction(CHANGE_CONTROLS, 'payload'),
  resetControls: createAction(USERSETTING.RESET_CONTROLS),
  fetchContractAdmins: createAction(USERSETTING.FETCH_CONTRACT_ADMINS),
};

const initialState = {
  formControls: {
    rol: '',
    tempHoldings: [],
    holdings: [],
    holding: 0,
    email: '',
    name: '',
    lastName: '',
    phone: '',
  },
  roles: {},
  contractAdmins: [],
  success: false,
  loading: false,
  initialLoading: false,
  error: false,
};

const userSetting = createReducer(initialState, {
  [CHANGE_CONTROLS]: (state, action) => ({
    ...state,
    formControls: {
      ...state.formControls,
      ...action.payload,
    },
  }),
  [USERSETTING.RESET_CONTROLS]: (state) => ({
    ...state,
    formControls: { ...initialState.formControls },
    success: false,
  }),
  [USERSETTING.FETCH_INITIAL_DATA]: (state) => ({
    ...state,
    initialLoading: true,
    error: false,
  }),
  [USERSETTING.FETCH_INITIAL_DATA_ERROR]: (state) => ({
    ...state,
    initialLoading: false,
    error: true,
  }),
  [USERSETTING.FETCH_INITIAL_DATA_SUCCESS]: (state, action) => ({
    ...state,
    initialLoading: false,
    error: false,
    roles: action.payload,
  }),
  [USERSETTING.FETCH_USER]: (state) => ({
    ...state,
    initialLoading: true,
    error: false,
  }),
  [USERSETTING.FETCH_USER_ERROR]: (state) => ({
    ...state,
    initialLoading: false,
    error: true,
  }),
  [USERSETTING.FETCH_USER_SUCCESS]: (state, action) => ({
    ...state,
    initialLoading: false,
    error: false,
    formControls: {
      ...state.formControls,
      ...action.payload,
    },
  }),
  [USERSETTING.FETCH_USER_HOLDINGS_SUCCESS]: (state, action) => ({
    ...state,
    formControls: {
      ...state.formControls,
      tempHoldings: action.payload.map((h) => ({ ...h, checked: true })),
    },
  }),
  [USERSETTING.TOGGLE_TEMP_HOLDING]: (state, action) => {
    const tempHoldingsCopy = [...state.formControls.tempHoldings];
    const updatedHoldingIndex = tempHoldingsCopy.findIndex((h) => h.id === action.payload);
    tempHoldingsCopy[updatedHoldingIndex] = {
      ...tempHoldingsCopy[updatedHoldingIndex],
      checked: !tempHoldingsCopy[updatedHoldingIndex].checked,
    };

    return {
      ...state,
      formControls: {
        ...state.formControls,
        tempHoldings: tempHoldingsCopy,
      },
    };
  },
  [USERSETTING.RESET_TEMP_HOLDINGS]: (state) => ({
    ...state,
    formControls: {
      ...state.formControls,
      tempHoldings: [],
    },
  }),
  [USERSETTING.ADD_TEMP_HOLDINGS]: (state) => ({
    ...state,
    formControls: {
      ...state.formControls,
      holdings: [
        ...state.formControls.holdings,
        ...state.formControls.tempHoldings.filter((h) => h.checked).map((h) => h.id),
      ],
      tempHoldings: [],
    },
  }),
  [USERSETTING.CREATE_USER]: (state) => ({
    ...state,
    loading: true,
    error: false,
  }),
  [USERSETTING.CREATE_USER_ERROR]: (state) => ({
    ...state,
    loading: false,
    error: true,
  }),
  [USERSETTING.CREATE_USER_SUCCESS]: (state) => ({
    ...state,
    loading: false,
    error: false,
    success: true,
  }),
  [USERSETTING.EDIT_USER]: (state) => ({
    ...state,
    loading: true,
    error: false,
  }),
  [USERSETTING.EDIT_USER_ERROR]: (state) => ({
    ...state,
    loading: false,
    error: true,
  }),
  [USERSETTING.EDIT_USER_SUCCESS]: (state) => ({
    ...state,
    loading: false,
    error: false,
    success: true,
  }),
  [USERSETTING.FETCH_CONTRACT_ADMINS_SUCCESS]: (state, action) => ({
    ...state,
    contractAdmins: action.payload,
  }),
});

export default userSetting;
