import { put, spawn, takeLatest } from 'redux-saga/effects';
import {
  apiSuccess, get, post, update,
} from 'utils/api';
import * as APP from 'commons/reducer';
import { HOLDING } from 'screens/HoldingMaintainer/reducer';
import { SOCIAL_REASON } from '../reducer';

function* changePage() {
  yield takeLatest(SOCIAL_REASON.CHANGE_PAGE, function* () {});
}

function* fetchSocialReasons() {
  yield takeLatest(SOCIAL_REASON.GET_SOCIAL_REASONS, function* (action) {
    const response = yield get(`api/v1/holdings/${action.holdingId}`);
    if (!response.error) {
      yield put(apiSuccess(HOLDING.SELECT_HOLDING, response));
      yield put(apiSuccess(SOCIAL_REASON.GET_SOCIAL_REASONS_SUCCESS, response));
    } else {
      yield put(apiSuccess(SOCIAL_REASON.GET_SOCIAL_REASONS_ERROR));
      yield put(apiSuccess(APP.SET_ERROR, { msg: 'Error al obtener razones sociales' }));
    }
  });
}

function* addSocialReason() {
  yield takeLatest(SOCIAL_REASON.ADD_SOCIAL_REASON, function* ({ payload }) {
    const {
      name, rut, holdingId, holdingRut: externalId, handleClose,
    } = payload;
    const response = yield post('api/v1/social-reasons', {
      name, rut, holdingId, externalId,
    });
    if (!response.error) {
      handleClose();
      yield put(apiSuccess(SOCIAL_REASON.ADD_SOCIAL_REASON_SUCCESS, response));
      yield put(apiSuccess(SOCIAL_REASON.APPLY_FILTER));
    } else {
      yield put(apiSuccess(SOCIAL_REASON.ADD_SOCIAL_REASON_ERROR));
      yield put(apiSuccess(APP.SET_ERROR, { msg: 'Error al crear razón social. El RUT ingresado ya existe en la base de datos.' }));
    }
  });
}

function* updateSocialReason() {
  yield takeLatest(SOCIAL_REASON.UPDATE_SOCIAL_REASON, function* ({ payload }) {
    const {
      name, rut, isActive, socialReasonId, handleClose,
    } = payload;
    const response = yield update(`api/v1/social-reasons/${socialReasonId}`, { name, rut, isActive });
    if (!response.error) {
      handleClose();
      yield put(apiSuccess(SOCIAL_REASON.UPDATE_SOCIAL_REASON_SUCCESS, response));
      yield put(apiSuccess(APP.UPDATE_SOCIAL_REASON_SUCCESS, response));
      yield put(apiSuccess(SOCIAL_REASON.APPLY_FILTER));
    } else {
      yield put(apiSuccess(SOCIAL_REASON.UPDATE_SOCIAL_REASON_ERROR));
      yield put(apiSuccess(APP.SET_ERROR, { msg: 'Error al editar razón social' }));
    }
  });
}

const msgMapper = {
  'Social Reason not found': 'No se encontró la razón social',
  'Holding not found': 'No se encontró el holding',
  'Social Reason has active fleets': 'La razón social tiene una o más flotas activas',
  'Social Reason has open payment status': 'La razón social tiene estados de pago asociados activos',
  'Social Reason has open driver request': 'La razón social tiene una solicitud de conductor activa',
};

function* reassignHolding() {
  yield takeLatest(SOCIAL_REASON.REASSIGN_HOLDING, function* ({ payload }) {
    const { socialReasonId, holdingId, handleClose } = payload;
    const response = yield update(
      `api/v1/social-reasons/${socialReasonId}/holding`, { holdingId },
    );

    if (!response.error) {
      handleClose();
      yield put(apiSuccess(SOCIAL_REASON.REASSIGN_HOLDING_SUCCESS, response));
    } else {
      yield put(apiSuccess(SOCIAL_REASON.REASSIGN_HOLDING_ERROR));
      const errorMsg = JSON.parse(response.errorMsg).message;
      yield put(
        apiSuccess(APP.SET_ERROR, { msg: msgMapper[errorMsg] ?? 'Error al reasignar holding' }),
      );
    }
  });
}

const msgMapperHolding = {
  'Social Reason not found': 'No se encontró la razón social',
  'Holding not found': 'No se encontró el holding',
  'Social Reason has active fleets': 'Una razón social tiene una o más flotas activas',
  'Social Reason has open payment status': 'El holding tiene estados de pago activos',
  'Social Reason has open driver request': 'Una razón social tiene una solicitud de conductor activa',
};

function* reassignHoldingSrs() {
  yield takeLatest(SOCIAL_REASON.REASSIGN_HOLDING_SRS, function* ({ payload }) {
    const { sourceId, targetId: targetHoldingId, handleClose } = payload;
    const response = yield update(
      `api/v1/holdings/${sourceId}/merge`, { targetHoldingId },
    );

    if (!response.error) {
      yield put(apiSuccess(HOLDING.DEACTIVATE));
      yield put(apiSuccess(SOCIAL_REASON.REASSIGN_HOLDING_SRS_SUCCESS, response));
      handleClose();
    } else {
      yield put(apiSuccess(SOCIAL_REASON.REASSIGN_HOLDING_SRS_ERROR));
      const errorMsg = JSON.parse(response.errorMsg).message;
      yield put(
        apiSuccess(APP.SET_ERROR, { msg: msgMapperHolding[errorMsg] ?? 'Error al reasignar holding' }),
      );
    }
  });
}

function* updateHolding() {
  yield takeLatest(HOLDING.UPDATE_HOLDING, function* ({ payload }) {
    const {
      name, rut, holdingId, isActive, handleClose,
    } = payload;
    const response = yield update(`api/v1/holdings/${holdingId}`, { name, rut, isActive });
    if (!response.error) {
      handleClose();
      yield put(apiSuccess(HOLDING.UPDATE_HOLDING_SUCCESS, response));
      yield put(apiSuccess(APP.UPDATE_HOLDING_SUCCESS, response));
    } else {
      yield put(apiSuccess(HOLDING.UPDATE_HOLDING_ERROR));
      yield put(apiSuccess(APP.SET_ERROR, { msg: 'Error al editar holding' }));
    }
  });
}

export default function* root() {
  yield spawn(changePage);
  yield spawn(fetchSocialReasons);
  yield spawn(addSocialReason);
  yield spawn(updateSocialReason);
  yield spawn(reassignHolding);
  yield spawn(reassignHoldingSrs);
  yield spawn(updateHolding);
}
