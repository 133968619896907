import React, { useMemo } from 'react';
// Material
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

// Project
import CustomSelect from 'commons/components/CustomSelect';
import type from 'utils/type';
import { PAYMENT_STATUS_FLEET, PAYMENT_STATUS_STATUS } from 'utils/enums';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { Close, Search } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import styles from './style';

const useStyles = makeStyles(styles);

const Filter = ({
  controls, actions, fleets, role, paymentStatusDTO, isMobile,
}) => {
  const { changeControls } = actions;
  const classes = useStyles();

  const fleetsOptions = useMemo(() => {
    if (fleets?.length > 0) {
      return [...(fleets?.length !== 1 ? [{ id: '0', value: '0', label: 'Todas las flotas' }] : []),
        ...fleets.map((fl) => ({ id: fl.id, value: fl.id, label: fl.name?.length === 0 ? 'Vehículos sin flota' : fl.name }))];
    }
    return [];
  }, [fleets]);

  const handleClickOpenExtras = () => {
    changeControls('extrasDialog', true);
  };

  const handleClickOpenDocuments = () => {
    changeControls('documentsDialog', true);
  };

  const rolesAllowedFleetsSelect = [
    type.ADMINISTRADOR_DE_CONTRATO, type.GESTOR_DE_FLOTA_DECISOR, type.ADMINISTRADOR_PORTAL_SALFA,
  ];

  const fleetSelectedAndInAdminReview = controls.fleet !== '0' && paymentStatusDTO.fleets[0]?.status === PAYMENT_STATUS_FLEET.ADMIN_REVIEW;

  const roleAndStatusAllowedToAdd = role === type.ADMINISTRADOR_DE_CONTRATO
    && (paymentStatusDTO.status === PAYMENT_STATUS_STATUS.DRAFT || fleetSelectedAndInAdminReview);

  return (

    <Grid
      container
      spacing={2}
      className={isMobile ? classes.grid1 : classes.grid2}
    >
      {
        rolesAllowedFleetsSelect.includes(role)
          ? (
            <Grid item xs={12} md={3}>
              <CustomSelect
                disabled={fleets?.length === 1}
                label="Flota"
                id="fleet"
                name="fleet"
                variant="outlined"
                onChange={(target) => {
                  changeControls('fleet', target.target.value);
                }}
                value={controls.fleet}
                options={fleetsOptions}
                size="medium"
              />
            </Grid>
          ) : <Grid item xs={12} md={3} />
      }
      <Grid item xs={12} md={3}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Buscar patente"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search color="primary" />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => changeControls('search', '')}>
                  <Close color="primary" />
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            changeControls('search', e.target.value);
          }}
          value={controls.search}
        />
      </Grid>
      {
        roleAndStatusAllowedToAdd
          && (
            <>
              <Grid item container justifyContent="flex-end" xs={12} md={3}>
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  fullWidth
                  onClick={handleClickOpenDocuments}
                >
                  Cargar documento
                </Button>
              </Grid>
              <Grid item container justifyContent="flex-end" xs={12} md={3}>
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  fullWidth
                  onClick={handleClickOpenExtras}
                >
                  Agregar extra
                </Button>
              </Grid>
            </>
          )
}
    </Grid>
  );
};

export default Filter;
