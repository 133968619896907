import { Menu, MenuItem } from '@material-ui/core';
import React, { useState, useEffect, useRef } from 'react';

import roles from 'screens/User/constants';

/**
 * @param {object} props
 * @param {React.ReactNode} props.children
 * @param {string} props.width
 * @param {{text: string, action: () => void}[]} props.options
 * @returns {JSX.Element}
 */
const ContextMenu = ({
  children, width, options, role,
}) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [open, setOpen] = useState(false);
  const componentRef = useRef(null);

  const handleClick = (event) => {
    event.preventDefault();
    setPosition({ x: event.clientX, y: event.clientY });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAction = (action) => {
    action();
    handleClose();
  };

  useEffect(() => {
    const handleContextMenu = (event) => {
      if (componentRef.current && componentRef.current.contains(event.target)) {
        event.preventDefault();
        setOpen(true);
      } else {
        setOpen(false);
      }
    };

    window.addEventListener('contextmenu', handleContextMenu);

    return () => {
      window.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);

  if (role !== roles.ADMINISTRADOR_PORTAL_SALFA) return <div style={{ width }}>{children}</div>;

  return (
    <div onContextMenu={handleClick} style={{ width }} ref={componentRef}>
      <Menu
        open={open}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={{ top: position.y, left: position.x }}
        disableAutoFocus
        autoFocus={false}
      >
        {options.map((option, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <MenuItem selected={false} key={idx} onClick={() => handleAction(option.action)}>
            {option.text}
          </MenuItem>
        ))}
      </Menu>
      {children}
    </div>
  );
};

export default ContextMenu;
