import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  makeStyles,
  Switch,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import styles from './styles';
import NotificationInterval from '../NotificationInterval';

const useStyles = makeStyles(styles);

const defaultValues = {
  km: [100, 500, 1000],
  contract: [1, 3, 6],
};

const SubTitle = ({ title }) => (
  <Typography component="div" color="primary">
    <Box fontSize={16}>
      {title}
    </Box>
  </Typography>
);

const SaveChangeButton = ({ title, onClick }) => {
  const classes = useStyles();
  return (
    <Box>
      <Button onClick={onClick} fullWidth={false} className={classes.button} variant="contained" color="secondary">
        {title}
      </Button>
    </Box>
  );
};

const Title = ({ name }) => (
  <Typography color="primary">
    <Box marginBottom={6} fontSize={22}>
      {name}
    </Box>
  </Typography>
);

const Notification = ({ loading, actions }) => {
  const classes = useStyles();
  const [emailEnabled, setEmailEnabled] = useState(true);
  const { getNotification, getNotificationSettings, saveNotificationSettings } = actions;

  useEffect(() => {
    getNotification();
    getNotificationSettings();
  }, []);

  const handleNotificationChange = (event) => {
    if (!loading) {
      setEmailEnabled(event.target.checked);
    }
  };

  const handleOnSave = () => {
    saveNotificationSettings();
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.sectionContainer}>
        <Title name="Estados de Pago" />
        <FormControl className={classes.formControl} component="fieldset">
          <FormControlLabel
            control={<Switch color="primary" checked={emailEnabled} onChange={handleNotificationChange} name="kmNotification" />}
            label="Email"
          />
        </FormControl>
      </Box>
      <Box className={classes.sectionContainer}>
        <Title name="Vencimientos de contrato | Próximas mantenciones" />
        <Box className={classes.space}>
          <SubTitle title="Kilometraje" />
          <NotificationInterval data={[]} label="Kilometros" id="km" saveState={() => {}} addsLabel="km" defaultsValues={defaultValues.km} />
        </Box>
        <Divider />
        <Box className={classes.space}>
          <SubTitle title="Vencimiento" />
          <NotificationInterval data={[]} label="Meses" id="tiempo" saveState={() => {}} addsLabel="meses" defaultsValues={defaultValues.contract} />
        </Box>
      </Box>
      <SaveChangeButton
        onClick={(key, state) => handleOnSave(key, state)}
        title="GUARDAR CAMBIOS"
      />
    </Box>
  );
};

export default Notification;
