import React from 'react';
import {
  FormControlLabel, FormGroup, styled, Switch, Typography,
} from '@material-ui/core';

import { renderToStaticMarkup } from 'react-dom/server';

const SharedIcon = () => (
  <svg width="28" height="28" viewBox="0 0 34 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.4968 2.72954C19.4968 1.2461 18.2439 0 16.7523 0C15.2607 0 14.0078 1.2461 14.0078 2.72954C14.0078 4.21299 15.2607 5.45909 16.7523 5.45909C18.2439 5.45909 19.4968 4.27233 19.4968 2.72954Z" fill="#fff" />
    <path d="M22.1816 10.115C21.2867 8.03814 19.1388 6.55469 16.6926 6.55469C14.2464 6.55469 12.1581 8.03814 11.2035 10.115C10.9649 10.5897 11.3228 11.1237 11.8598 11.1237H21.4656C22.0623 11.183 22.4203 10.649 22.1816 10.115Z" fill="#fff" />
    <path d="M30.625 14.9705C30.625 13.4871 29.372 12.241 27.8804 12.241C26.3889 12.241 25.1359 13.4871 25.1359 14.9705C25.1359 16.454 26.3889 17.7001 27.8804 17.7001C29.372 17.7001 30.625 16.5133 30.625 14.9705Z" fill="#fff" />
    <path d="M33.3098 22.3559C32.4148 20.2791 30.2669 18.7957 27.8207 18.7957C25.3745 18.7957 23.2863 20.2791 22.3317 22.3559C22.093 22.8306 22.451 23.3647 22.988 23.3647H32.5938C33.1905 23.424 33.5484 22.89 33.3098 22.3559Z" fill="#fff" />
    <path d="M8.36862 14.9705C8.36862 13.4871 7.11569 12.241 5.6241 12.241C4.13251 12.241 2.87958 13.4871 2.87958 14.9705C2.87958 16.454 4.13251 17.7001 5.6241 17.7001C7.11569 17.7001 8.36862 16.5133 8.36862 14.9705Z" fill="#fff" />
    <path d="M11.0534 22.3559C10.1585 20.2791 8.01059 18.7957 5.56439 18.7957C3.11819 18.7957 1.02996 20.2791 0.0753471 22.3559C-0.163307 22.8306 0.194674 23.3647 0.731646 23.3647H10.3375C10.9341 23.424 11.2921 22.89 11.0534 22.3559Z" fill="#fff" />
    <path d="M12.241 24.6689C13.6041 25.2645 15.1096 25.5949 16.6922 25.5949C18.2749 25.5949 19.7804 25.2645 21.1435 24.6689" stroke="#fff" strokeLinecap="round" />
    <path d="M22.2564 23.9257L21.4995 26.0001L20.0951 23.4729L22.2564 23.9257Z" fill="#fff" />
    <path d="M27.4366 11.2117C26.9975 9.7904 26.2646 8.43447 25.2311 7.23585C24.1976 6.03723 22.9643 5.1128 21.6231 4.46936" stroke="#fff" strokeLinecap="round" />
    <path d="M20.3334 4.11203L22.3987 3.33063L21.4018 6.04452L20.3334 4.11203Z" fill="#fff" />
    <path d="M12.9699 4.27881C11.585 4.8219 10.2871 5.65315 9.16827 6.77255C8.04944 7.89195 7.21885 9.19032 6.67647 10.5755" stroke="#fff" strokeLinecap="round" />
    <path d="M6.41553 11.8878L5.48338 9.88602L8.26362 10.6792L6.41553 11.8878Z" fill="#fff" />
  </svg>
);

const OnlyDriverSvg = () => (
  <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.3686 7.72954C14.3686 6.2461 13.1157 5 11.6241 5C10.1325 5 8.87958 6.2461 8.87958 7.72954C8.87958 9.21299 10.1325 10.4591 11.6241 10.4591C13.1157 10.4591 14.3686 9.27233 14.3686 7.72954Z" fill="#fff" />
    <path d="M17.0534 15.115C16.1585 13.0381 14.0106 11.5547 11.5644 11.5547C9.11819 11.5547 7.02996 13.0381 6.07535 15.115C5.83669 15.5897 6.19467 16.1237 6.73165 16.1237H16.3375C16.9341 16.183 17.2921 15.649 17.0534 15.115Z" fill="#fff" />
    <circle cx="11.5" cy="11.5" r="11" stroke="#fff" />
  </svg>
);

const svgStringRight = encodeURIComponent(renderToStaticMarkup(<OnlyDriverSvg />));
const svgStringLeft = encodeURIComponent(renderToStaticMarkup(<SharedIcon />));

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url("data:image/svg+xml,${svgStringLeft}")`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#aab4be',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.primary.main,
    width: 32,
    height: 32,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url("data:image/svg+xml,${svgStringRight}")`,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: '#aab4be',
    borderRadius: 20 / 2,
  },
}));

const DriverSwitch = ({
  label, checked, vehicleId, changeShared, loading,
}) => {
  const handleChange = (e) => {
    changeShared({ vehicle: vehicleId, shared: e.target.checked });
  };

  return (
    <FormGroup>
      <FormControlLabel
        control={(
          <MaterialUISwitch
            sx={{ m: 1 }}
            checked={checked}
            onChange={handleChange}
            disabled={loading}
          />
)}
        label={(
          <>
            <Typography>
              {label}
            </Typography>
            <Typography style={{ marginTop: '-8px' }}>
              {checked ? 'Compartida' : 'Única'}
            </Typography>
          </>
)}
      />
    </FormGroup>
  );
};

export default DriverSwitch;
