import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import styles from './styles';

const useStyles = makeStyles(styles);
const LicensePlateColumn = ({
  licensePlate, status, showStatus = true, width = null,
}) => {
  const classes = useStyles({ status });

  return (
    <Box
      marginRight={1}
      width={width}
    >
      <Grid
        item
        sm={2}
        className={classes.textContainer}
      >
        <Typography
          component="div"
          variant="h4"
          color="primary"
          className={classes.licensePlate}
          noWrap
        >
          <Box
            component="span"
            fontStyle="normal"
            fontSize={20}
            className={classes.licensePlateBox}
          >
            {licensePlate}
          </Box>
        </Typography>
        {showStatus
          ? (
            <Box display="flex" alignItems="center">
              <FiberManualRecordIcon
                className={classes.statusIcon}
              />
              <Typography component="div" variant="body2" color="primary">
                <Box component="span" fontSize={10}>

                  {status}
                </Box>
              </Typography>
            </Box>
          )
          : null }
      </Grid>
    </Box>
  );
};

export default LicensePlateColumn;
