const styles = (theme) => ({
  notificationIcon: {
    '&:hover': {
      background: 'none',
      color: '#FFF',
    },
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
    marginRight: theme.spacing(2),
    transform: 'scale(1.4)',
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  icon: {
    '&:hover': {
      background: 'none',
    },
    transform: 'scale(1.4)',
  },
  title: {
    fontSize: 16,
    color: theme.palette.primary.main,
    // marginLeft: theme.spacing(1),
  },
  subTitle: {
    fontSize: 12,
    color: theme.palette.primary.main,
    // marginLeft: theme.spacing(1),
  },
  time: {
    fontSize: 12,
    color: theme.palette.primary.main,
    marginTop: theme.spacing(1),
  },
  box: {
    cursor: 'pointer',
  },
});

export default styles;
