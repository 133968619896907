const styles = (theme) => ({
  card: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paginationContainer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: ' end',
  },
  listTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  box1: {
    paddingTop: 16,
    paddingBottom: 16,
  },
  box2: {
    padding: 16,
  },
  gridDetailsCard: {
    marginBottom: 8,
  },
});

export default styles;
