import {
  Box, makeStyles, useMediaQuery, useTheme,
} from '@material-ui/core';
import React from 'react';

// Material
import { Pagination } from '@material-ui/lab';

import { randomString } from 'utils/functions';
import HeaderList from './index';
import HoldingCard from './HoldingCard';

const useStyles = makeStyles((theme) => ({
  paginationContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

function HoldingList(props) {
  const {
    isLoading, total, loading, tableControls, actions, holdings,
  } = props;
  const { page, total: totalPages } = tableControls.pagination;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();

  return (
    <>
      <Box
        display="grid"
        gridTemplateRows="auto"
        overflow="auto"
        padding={0}
        paddingBottom={1}
      >
        <HeaderList
          setOrderBy={actions.tableSetOrderBy}
          isLoading={isLoading}
          total={total}
          loading={loading}
        />
        {holdings && holdings.map((holding, index) => (
          <HoldingCard
            holding={holding}
            key={holding?.id || randomString()}
            index={index}
            isMobile={isMobile}
            // holding: {id, name, rut, isActive, Users }
            onClick={(h) => { actions.setHolding(h); }}
          />
        ))}
      </Box>

      {/* Paginación */}
      {!!totalPages && (
        <Box display="flex" justifyContent="center" alignItems="end" className={classes.paginationContainer}>
          <Pagination
            onChange={(e, p) => !isLoading && actions.tableSetPage(p)}
            hidePrevButton={!page}
            count={totalPages}
            page={page}
            color="primary"
            size={isMobile ? 'small' : 'large'}
          />
        </Box>
      )}
    </>
  );
}

export default HoldingList;
