// @ts-nocheck
/* eslint-disable no-undef */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import { connect } from 'react-redux';
import { ReactReduxContext } from 'config/configureStore';
import { useLocation, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { useMediaQuery } from '@material-ui/core';

import { useMemo } from 'react';
import { format } from 'date-fns';
import { actionProps, selectState } from './reduxActions';
import connectSaga from './saga';

export const isNotANumber = (value) => String(value) === 'NaN';

/**
 * It will check if a value is falsy but with slightly modifications.
 * @example
 * | type    |  description                    |
 * |---------|–--------------------------------|
 * | Objects | "{}" => true. "{a: 2}" => false |
 * |---------|---------------------------------|
 * | Arrays  | "[]" => true. "[2]" => false    |
 * |---------|---------------------------------|
 *
 * @param {Any} value Any value to be checked
 * @return Boolean
 */
export const falsy = (value) => {
  let isFalsy = false;

  function isPromise(object) {
    if (Promise && Promise.resolve) {
      return Promise.resolve(object) === object;
    }
    return false;
  }

  if (!isPromise(value) && !value) {
    isFalsy = true;
  } else if (!isPromise(value) && typeof value === 'object') {
    if (!(value instanceof Date)) { // check for dates
      isFalsy = !Object.keys(value).length;
    }
  }

  return isFalsy;
};

/**
 * It will check if a value is truthty but with slightly modifications.
 * @example
 * | type    |  description                    |
 * |---------|–--------------------------------|
 * | Objects | "{}" => false. "{a: 2}" => true |
 * |---------|---------------------------------|
 * | Arrays  | "[]" => false. "[2]" => true    |
 * |---------|---------------------------------|
 *
 * @param {Any} value Any value to be checked
 * @return Boolean
 */
export const truthty = (value) => {
  let isTruthy = false;

  if (/^\d+$/.test(value)) {
    value = Number(value);
  }

  if (value && !isNotANumber(value)) {
    isTruthy = true;
    if (typeof value === 'object' && Object.keys(value).length) {
      isTruthy = true;
    } else if (typeof value === 'object' && !Object.keys(value).length) {
      isTruthy = false;
    }
  }

  return isTruthy;
};

Object.defineProperty(Object, 'extractValues', {
  /**
   * It will return all the values from an Object or an alement from an specific
   * given position.
   *
   * @param {Object} element The object to extract all the values
   * @param {Number} position The position of the element we would like to get in return
   * @return Array of the object values.
   */
  value: function extractValues(element, position) {
    let el = [];

    if (truthty(element) && Object.values(element).length) {
      if (position >= 0 && position !== undefined && position !== null) {
        el = Object.values(element)[position];
      } else {
        el = Object.values(element);
      }
    }

    return el;
  },
  writable: true,
});

Object.defineProperty(Object, 'hasValues', {
  /**
   * It will check if the given object has any elements as a value
   *
   * @param {Object} element The element to check the values
   * @return Boolean
   */
  value: function hasValues(element) {
    return Boolean(Object.values(element).length);
  },
  writable: true,
});

Object.defineProperty(Object, 'findValue', {
  value: function findValue(element, fnc) {
    return truthty(element) ? Object.values(element).find(fnc) : [];
  },
  writable: true,
});

Object.defineProperty(Object, 'filterValues', {
  value: function filterValues(element, fnc) {
    return truthty(element) ? Object.values(element).filter(fnc) : [];
  },
  writable: true,
});

/**
 * It will turn out an object into an array of objects keeping the original
 * keys
 *
 * @param {object} obj the object to seek for
 * @return {array} An array of objects
 */
export const objectToArray = (obj) => Object.keys(obj)
  .reduce((prev, current) => (
    [
      ...prev,
      { [current]: obj[current] },
    ]
  ), []);

/**
 * It will turn out an array of object into a new array of objects or a new object of objects, but
 * the objects in the arrays are based on the passed `key` and `value` params
 * extracted from the objects in the initial array
 *
 * @param {array} array The array to seek for
 * @param {string} key The name of the atribute to use as a key
 * @param {string} value The name of the atribute to use as a value
 * @return {array} a new array of objects
 */
export const compressObject = (array, key, value) => array.reduce((prev, current) => (
  [
    ...prev,
    { [current[key]]: current[value] },
  ]
), []);

export const appendToObject = (
  array,
  extraKey,
  extraValue,
  objItself = false,
) => array.reduce((prev, current) => ([
  ...prev,
  {
    ...current,
    [extraKey]: objItself ? current[extraValue] : extraValue,
  },
]), []);

/**
 * It will turn out an array of objects to a single object
 *
 * @param {array} arr The array to seek for
 * @return {object} A new object
 */
export const flatObject = (arr) => arr.reduce((prev, current) => {
  const [[key, value]] = Object.entries(current);

  prev[key] = value;
  return prev;
}, {});

/**
 * HOC - It will return only one value from an object.
 * falsy values are not returned.
 * Eg: data.name - returned value is "name".
 * Useful to work with `map`
 *
 * @param {any} key The name of the chain attribute to get the value from
 * @param {any} content The content from where to extract the value
 * @return {any} The needed value
 */
export const uniqueObjValue = (key) => (content) => content[key];

export const setInStorage = (name, data, storeType) => (
  storeType === 'session'
    ? sessionStorage
    : localStorage
).setItem(name, JSON.stringify(data));

export const getItemInStorage = (name, storeType) => JSON.parse((storeType === 'session' ? sessionStorage : localStorage).getItem(name));

export const normalizeObj = (arr) => arr.reduce((prev, current) => {
  prev[current.id] = current;
  return prev;
}, {});

export const getObjectByKey = (element) => (key) => ({
  [key]: element[key],
});

/**
 * It will concat and execute several functions synchronously.
 * If you add more than one value, only the first function will recive them and
 * the result of it will be passed down through the rest of the functions
 *
 * @param {function} func - Functions.
 * @return {function} - The result of passing all the values through
 *               the functions.
 */
export const pipe = (...func) => func.reduce(
  (prevFunc, currentFunc) => (...values) => currentFunc(prevFunc(...values)),
);

export const removeKeyByFilter = (object, keyParam) => Object.keys(object)
  .filter((key) => key !== String(keyParam))
  .reduce((obj, key) => {
    obj[key] = object[key];
    return obj;
  }, {});

/**
 *
 * @param {object} object
 * @param {Array} keyParams list of keys to delete
 */
export const removeManyKeysByFilter = (object, keyParams) => Object.keys(object)
  .filter((key) => keyParams.indexOf(Number(key)) === -1)
  .reduce((obj, key) => {
    obj[key] = object[key];
    return obj;
  }, {});

export const formatNumber = (num) => {
  num = num ? num.toString().replace(/\./g, '') : 0;
  return num ? num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') : 0;
};

export const formatNumberDollar = (num) => (num ? num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : 0);

export const invertObj = (obj) => Object.entries(obj)
  .reduce((prev, current) => {
    const [key, value] = current;
    prev[value] = key;
    return prev;
  }, {});

// ------------------
export const clone = (objectType, ...obj) => (objectType === 'OBJECT'
  ? Object.assign({}, ...obj)
  : obj.reduce((prev, current) => prev.concat(current), []));

Object.defineProperty(clone, 'ARRAY', {
  value: 'ARRAY',
  writable: true,
});

Object.defineProperty(clone, 'OBJECT', {
  value: 'OBJECT',
  writable: true,
});

const regex = new RegExp('\\.\\s*\\w{1}', 'g');
export const humanizeText = (text, fullname = false) => {
  let tempText = '';
  if (truthty(text)) {
    tempText = text.toLowerCase();

    if (fullname) {
      tempText = tempText.split(/\s+/).map((a) => `${a[0].toUpperCase()}${a.slice(1)}`).join(' ');
    } else {
      tempText = `${tempText[0].toUpperCase()}${tempText.slice(1)}`;

      while (regex.exec(tempText) !== null) {
        tempText = `${tempText.slice(0, regex.lastIndex - 2)}${tempText[regex.lastIndex - 1]
          .toUpperCase()}${tempText.slice(regex.lastIndex)}`;
      }
    }
  }

  return tempText;
};

export const cleanString = (str) => str.toLowerCase()
  .replace(/\s+/g, '-')
  .replace(/á/g, 'a')
  .replace(/é/g, 'e')
  .replace(/í/g, 'i')
  .replace(/ó/g, 'o')
  .replace(/ú/g, 'u')
  .replace(/ñ/g, 'n');

/**
 * HOF that will check if one of the values is equal to the given one.
 * Works only with single params.
 *
 * @example
 * [1,2,3].filter(hasValue(2));
 *
 * @param {any} value Any type of value
 * @param {function} content
 */
export const hasValue = (value) => (content) => content === value;

/**
 * HOF that will check if one of the values is different to the given one.
 * Works only with single params.
 *
 * @example
 * [1,2,3].filter(hasNotValue(2));
 *
 * @param {any} value Any type of value
 * @param {function}
 */
export const hasNotValue = (value) => (content) => content !== value;

/**
 * HOF that will check if one of the values is equal to the given one.
 *
 * @example
 * [{id: 1}, {id: 2}, {id: 3}].filter(hasValueByKey('id', 3));
 *
 * @param {String} key the key of the object
 * @param {Any} value the value to match against the value of the object found by the given key.
 * @return true
 */
export const hasValueByKey = (key, value) => (content) => content[key] === value;

/**
 * HOF that will check if within the array are not the given value.
 *
 * @example
 * [{id: 1}, {id: 2}, {id: 3}].filter(hasNotValueByKey('id', 3));
 *
 * @param {String} key the key of the object
 * @param {Any} value the value to match against the value of the object found by the given key.
 * @return false
 */
export const hasNotValueByKey = (key, value) => (content) => content[key] !== value;

export const hasEveryValue = (element, values) => {
  let bool = false;

  for (let index = 0, size = values.length; index < size; index += 1) {
    bool = values === element;
  }

  return bool;
};

export const hasNotEveryValue = (element, values) => {
  let bool = false;

  for (let index = 0, size = values.length; index < size; index += 1) {
    bool = values !== element;
  }

  return bool;
};

/**
 * It will check a single value against N values. If at least one value is true
 * it will stop checking and return true, otherwise false
 *
 * @param {Any} element The element to match against with.
 * @param {Array} values All the values to match
 * @return Boolean
 */
export const hasSomeValue = (element, values) => values.indexOf(element) !== -1;

/**
* It will check a single value against N values. If at least one value is false
* it will stop checking and return true, otherwise false
*
* @param {Any} element The element to match against with.
* @param {Array} values All the values to match
* @return Boolean
*/
export const hasNotSomeValue = (element, values) => values.indexOf(element) === -1;

export const hasSomeValues = (arr, values) => {
  let bool = false;

  for (let index = 0, size = arr.length; index < size; index += 1) {
    if (values.indexOf(arr[index]) !== -1) {
      bool = true;
      break;
    }
  }

  return bool;
};

export const someValuesByKey = (key, values) => (content) => values.indexOf(content[key]) !== -1;

export const notEveryValueByKey = (key, values) => {
  let bool = false;
  return (content) => {
    for (let index = 0, size = values.length; index < size; index += 1) {
      bool = values[index] !== content[key];
    }

    return bool;
  };
};

/**
 * It will cancel the bubble execution.
 *
 * @param {Object} event the event object from any synthetic event
 * @return false
 */
export const cancelEvent = (event) => {
  event.stopPropagation();
  event.preventDefault();
  event.returnValue = false;
  event.cancelBubble = true;
  return false;
};

/**
 * It will create a single string of valid classes
 *
 * @example
 * appendClasses(['px-0 border-0', props.className, props.anotherClass && 'text-right']);
 *
 * @param {array} classes A list of string classes.
 * @return The joined classes. Eg.'px-0 border-0'
 */
export const appendClasses = (classes) => classes.filter((cls) => typeof cls === 'string' && cls).join(' ');

/**
 * Object with amount of objects with each month
 *
 */
export const amountLinesOfDate = (object) => (object !== undefined
  ? Object.values(object)
    .map(uniqueObjValue('date'))
    .reduce((prev, current) => {
      const year = current.split('-')[0];
      return {
        ...prev,
        [year]: {
          amount: Number(prev[year] ? prev[year].amount : 0) + 1,
          date: year,
        },
      };
    }, {})
  : []);

export const amountLinesOfYear = (object) => {
  const years = [...new Set(Object.values(object).map((item) => item.year))];
  const values = Object.values(object);

  return years.reduce((prev, current, index) => ({
    ...prev,
    [index * 2]: {
      amount: values.filter((item) => item.year === current && item.origin === 'USA').length,
      origin: 'USA',
      date: current,
    },
    [index * 2 + 1]: {
      amount: values.filter((item) => item.year === current && item.origin === 'Chile').length,
      origin: 'Chile',
      date: current,
    },
  }), {});
};

export const harvestsByYear = (object) => {
  const years = [...new Set(Object.values(object).map((item) => item.year))];
  const values = Object.values(object);

  return years.reduce((prev, current, index) => ({
    ...prev,
    [index * 2]: {
      amount: getAmountKg(values.filter((item) => item.year === current && item.origin === 'USA')),
      origin: 'USA',
      date: current,
    },
    [index * 2 + 1]: {
      amount: getAmountKg(values.filter((item) => item.year === current && item.origin === 'Chile')),
      origin: 'Chile',
      date: current,
    },
  }), {});
};

export const unformatTaxNumber = (taxNumber) => {
  let strTaxNumber = taxNumber.toString();

  while (strTaxNumber.indexOf('.') !== -1) {
    strTaxNumber = strTaxNumber.replace('.', '');
  }
  while (strTaxNumber.indexOf('-') !== -1) {
    strTaxNumber = strTaxNumber.replace('-', '');
  }

  return strTaxNumber;
};

export const fCurrency = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
export const fNumericDate = new Intl.DateTimeFormat('es-CL', {
  year: 'numeric', month: 'numeric', day: 'numeric', timeZone: 'UTC',
});
export const formatDate = (date) => date.toLocaleDateString('es-ES', {
  weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',
});

export const onChangeFnc = (onChange, event, date, withModule) => {
  event.persist();
  const value = event.target.type === 'date' || event.target.type === 'time' ? date : event.target.value;
  const id = event.target.type ? event.target.id : event.currentTarget.id;
  if (withModule) {
    onChange(event.currentTarget.dataset.module, { [id]: value }, { [id]: false });
  } else {
    onChange({ [id]: value }, { [id]: false });
  }
};

export const getParameterByName = (name, url) => {
  name = name.replace(/[[\]]/g, '\\$&');
  const regexName = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regexName.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export function composedComponent(component, saga, options) {
  const withSaga = connectSaga({ key: options.saga, saga });
  const withConnect = connect(
    options.selector ?? selectState(...(options.states ?? [])),
    actionProps(clone(clone.OBJECT, ...(options.actions ?? []))),
    null,
    { context: ReactReduxContext },
  );
  const composedComp = compose(
    ...(options.middlewares ?? []),
    withRouter,
    withConnect,
    withSaga,
  )(component);
  return composedComp;
}

export const validateEmail = (inputText) => {
  const mailformat = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i; return inputText.match(mailformat);
};

export const validatePassword = (inputText) => {
  const passwordFormat = /^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*)$/;
  return inputText.match(passwordFormat);
};

export const isValidUrl = (urlString) => {
  const urlPattern = new RegExp('^(https?:\\/\\/)' // validate protocol
  + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' // validate domain name
  + '((\\d{1,3}\\.){3}\\d{1,3}))' // validate OR ip (v4) address
  + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' // validate port and path
  + '(\\?[;&a-z\\d%_.~+=-]*)?'); // validate query string
  return !!urlPattern.test(urlString);
};

const clearTaxNumber = (taxNumber) => String(taxNumber).replace(/[^0-9a-z]/gi, '');

const calculateVd = (taxNumber) => {
  let sum = 0;
  const taxNumberReverse = clearTaxNumber(taxNumber).split('').reverse();

  for (let i = 0, j = 2; i < taxNumberReverse.length; i += 1, j < 7 ? j += 1 : j = 2) {
    sum += taxNumberReverse[i] * j;
  }

  const result = 11 - (sum % 11);
  if (result === 11) return '0';
  if (result === 10) return 'k';
  return String(result);
};

export const validateTaxNumber = (taxNumber) => {
  if (typeof taxNumber === 'string' || typeof taxNumber === 'number') {
    const taxNumberWithoutFormat = clearTaxNumber(taxNumber);
    const taxNumberWithoutVd = taxNumberWithoutFormat.slice(0, -1);
    const taxNumberVd = taxNumberWithoutFormat ? taxNumberWithoutFormat.split('').pop().toLowerCase() : '';

    return calculateVd(taxNumberWithoutVd) === taxNumberVd;
  }

  return false;
};

export const formatTaxNumber = (taxNumber) => {
  const current = taxNumber.replace(/^0+/, '');

  if (current !== '' && current.length > 1) {
    const unformatRun = current.replace(/\./g, '').replace(/-/g, '');
    const clearRun = unformatRun.substring(0, unformatRun.length - 1);

    let taxNumberNumberFormat = '';

    let i = 0;
    let j = 1;

    for (i = clearRun.length - 1; i >= 0; i -= 1) {
      taxNumberNumberFormat = clearRun.charAt(i) + taxNumberNumberFormat;
      if (j % 3 === 0 && j <= clearRun.length - 1) {
        taxNumberNumberFormat = `.${taxNumberNumberFormat}`;
      }
      j += 1;
    }

    const dv = unformatRun.substring(unformatRun.length - 1);
    taxNumberNumberFormat = `${taxNumberNumberFormat}-${dv}`;
    return taxNumberNumberFormat;
  }
  return current;
};

export const date2ddmmyyyy = (date) => {
  const today = new Date(date);
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
  const yyyy = today.getFullYear();

  return `${dd}/${mm}/${yyyy}`;
};

export const numberFormatter = (number) => {
  if (!number) return 0;
  const parts = number.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return parts.join('.');
};

export const formatPatent = (string) => {
  const parts = string.match(/.{1,2}/g);
  return parts.join('-');
};

export const formatDateForCards = (dateStr) => {
  if (!dateStr) return dateStr;

  return format(new Date(dateStr), 'dd/MM/yyyy');
};

export const changeFormatDate = (date) => {
  if (!date) return 'No informado';
  const newFormat = date.split('T');
  return newFormat[0];
};

export const toLowerCase = (string) => {
  if (!string) return string;
  return string[0] + string.slice(1).toLowerCase();
};

export const getMonthsBetween = (d1, d2) => {
  let months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
};

export const isDate = (date) => (new Date(date) !== 'Invalid Date') && !Number.isNaN(new Date(date)) && date;
export const isMobile = () => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  return isSmallScreen;
};

export const validateNumber = (value) => {
  const number = value || 0;

  const isNumber = !Number.isNaN(number);
  if (isNumber) {
    return parseInt(number, 10);
  }
  return 0;
};

export const onlyNumber = (value) => {
  if (value.length > 11) return value.substring(0, 11);

  return value.replace(/\D*/g, '');
};

export const phoneFormat = (value) => {
  let newValue = value;

  if (value.charAt(0) === '9' && value.length === 9) {
    newValue = `+56 9 ${value.slice(1, 5)} ${value.slice(5, 9)}`;
  }

  if (value.slice(0, 3) === '569' && value.length === 11) {
    newValue = `+56 9 ${value.slice(3, 7)} ${value.slice(7, 11)}`;
  }

  return newValue;
};

export const debounce = (func, timeout = 300, timer) => (...args) => {
  clearTimeout(timer.current);
  timer.current = setTimeout(() => { func.apply(this, args); }, timeout);
};

export const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

export const firstUppercase = (value) => value
  .toLowerCase()
  .split(' ')
  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
  .join(' ');

export const compareDate = (firstDate, secondDate) => {
  const date1 = new Date(firstDate);
  const date2 = new Date(secondDate);
  if (!isDate(date1) || !isDate(date2)) return false;

  return date1 <= date2;
};

export const randomString = () => Math.random().toString(36);
export const randomUUID = () => `${Math.random().toString(36)}${new Date().getTime()}`;

export const isFunction = (value) => typeof value === 'function';

export const isNumber = (value) => typeof value === 'number' && !Number.isNaN(value);
export const isString = (value) => typeof value === 'string';
export const isBoolean = (value) => typeof value === 'boolean';

export const isArray = (value) => Array.isArray(value);
export const uniqueArray = (array) => (isArray ? [...new Set(array)] : []);

export const isEmptyObj = (obj) => Object.entries(obj).length === 0;
export const getIds = (data) => [].concat(...data.map((d) => d.value));

export const generatePath = (data, name) => (data ? data.map((d) => `&${name}=${d}`).join('') : null);

export const dateFormat = (date) => format(new Date(date), 'yyyy-MM-dd');

export const addDays = (date, days) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const simulateEvent = (id, type, value) => {
  const obj = { id, type, value };
  return {
    currentTarget: { ...obj },
    target: { ...obj },
    persist: () => {},
  };
};

export const arrayOfObjectsToObject = (array) => Object.assign({}, ...array);

/**
 * Convert an ISO formatted date string to a human-readable elapsed time format.
 * For example, "2 hours ago", "3 days ago", etc.
 *
 * @param {string} isoDate - The ISO formatted date string to convert.
 * @returns {string} The human-readable elapsed time.
 */
export const formatNotificationTime = (isoDate) => {
  const currentDate = new Date();
  const pastDate = new Date(isoDate);

  const timeDifferenceMs = currentDate - pastDate;

  const second = 1000;
  const minute = 60 * second;
  const hour = 60 * minute;
  const day = 24 * hour;
  const month = 30 * day;
  const year = 365 * day;

  const yearsElapsed = Math.floor(timeDifferenceMs / year);
  const monthsElapsed = Math.floor(timeDifferenceMs / month);
  const daysElapsed = Math.floor(timeDifferenceMs / day);
  const hoursElapsed = Math.floor(timeDifferenceMs / hour);
  const minutesElapsed = Math.floor(timeDifferenceMs / minute);

  if (yearsElapsed >= 1) {
    return `hace ${yearsElapsed} ${yearsElapsed === 1 ? 'año' : 'años'}`;
  } if (monthsElapsed >= 1) {
    return `hace ${monthsElapsed} ${monthsElapsed === 1 ? 'mes' : 'meses'}`;
  } if (daysElapsed >= 1) {
    return `hace ${daysElapsed} ${daysElapsed === 1 ? 'día' : 'días'}`;
  } if (hoursElapsed >= 1) {
    return `hace ${hoursElapsed} ${hoursElapsed === 1 ? 'hora' : 'horas'}`;
  } if (minutesElapsed >= 1) {
    return `hace ${minutesElapsed} ${minutesElapsed === 1 ? 'minuto' : 'minutos'}`;
  }
  return 'hace menos de un minuto';
};
