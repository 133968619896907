import {
  Button,
  Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography, makeStyles,
} from '@material-ui/core';
import React from 'react';

// Icons
import CloseIcon from '@material-ui/icons/Close';

import styles from './styles';
import UsersTable from '../UsersTable';

const useStyles = makeStyles(styles);

function PermissionManagerDialog({
  isOpen, onClose, holdings, reportName, reportId, actions, users, loadingUsers,
}) {
  const classes = useStyles();

  return (
    <Dialog open={isOpen} fullWidth maxWidth="lg">
      <DialogTitle>
        <Grid container alignItems="center">
          <Grid item className={classes.title}>
            Administrador de Permisos
          </Grid>
          <Grid item>
            <IconButton
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Typography variant="h5" className={classes.subtitle}>
          {reportName}
        </Typography>
        <UsersTable
          actions={actions}
          loadingUsers={loadingUsers}
          holdings={holdings}
          reportId={reportId}
          users={users.map((usr) => ({
            ...usr,
            hasPermission: usr.reports.some((r) => r.id === reportId),
          }))}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  );
}

export default PermissionManagerDialog;
