import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Material
import Grid from '@material-ui/core/Grid';
import { Box, withStyles } from '@material-ui/core';

// Project
import Skeleton from 'screens/DashBoard/component/Skeleton';
import WORK_ORDER_TYPES from 'utils/workOrderType';
import CustomPieChart from 'commons/components/CustomPieChart';
import TotalCard from 'screens/DashBoard/component/TotalCard';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import CustomTooltip from 'commons/components/CustomTooltip';
import { composedComponent, numberFormatter } from 'utils/functions';
import { workOrderActions } from 'screens/WorkOrder/reducer';
import types from 'utils/type';
import { appActions } from 'commons/reducer';
import { screenTime } from 'utils/utils';
import ScreenTitle from '../../commons/components/ScreenTitle';
import styles from './styles';
import saga from './saga';
import { dashBoardActions } from './reducer';
import { vehicleActions } from '../Vehicle/reducer';
// Component
import DashBoardBarGraph from './component/DashBoardBarGraph/index';

import {
  AvailabilityText,
  CorrectiveText,
  LateMaintenanceText,
  PreventiveText,
  ReplacementText,
  SinisterText,
  ValidityText,
  WorkshopText,
} from './descriptions';
import HoldingList from './component/ContractAdminTable/HoldingList';
import HoldingSearch from './component/HoldinsSearch';
import TableSkeleton from './component/ContractAdminTable/TableSkeleton';

const useStyles = makeStyles(styles);

const useWidth = () => {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || 'xs'
  );
};

const ZoneTitle = ({ title }) => (
  <Box marginTop={1}>
    <Divider variant="middle" />
    <Typography component="div" color="primary" noWrap>
      <Box fontSize={20} marginTop={1} textAlign="center">
        {title}
      </Box>
    </Typography>
  </Box>
);

const size = {
  xs: { graphic: 100, font: 24 },
  sm: { graphic: 90, font: 24 },
  md: { graphic: 100, font: 24 },
  lg: { graphic: 100, font: 24 },
  xl: { graphic: 150, font: 24 },
};

const DashBoard = (props) => {
  const {
    actions, kpi, operationsZone, loading, loadingData,
    holding, socialReason, user, tableControls, tableHoldings,
  } = props;
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const loadingTable = tableControls.loading;

  const { workOrders } = kpi;
  const { page, size: pageSize } = tableControls.pagination;
  const { descending: desc, sortBy, search } = tableControls;

  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    if (holding?.id) {
      actions.getStateFromApiVehicle();
    }
  }, [holding, socialReason]);

  useEffect(() => {
    actions.getHoldingsPaginated({
      page, size: pageSize, desc, sortBy, search,
    });
  }, [page, pageSize, desc, sortBy, search]);

  useEffect(() => screenTime('dashboard'), []);

  const width = useWidth();

  const goToVehiclesOrServices = (key, value, url) => {
    actions.resetState();
    actions.setFilter({ key: 'resetValues', value: false });
    actions.setFilter({ key: `${key}`, value: [`${value}`] });
    history.push(url, { filterState: true });
  };

  const goToWorkOrders = (key, value) => {
    actions.workResetControls();
    actions.workSetFilter({ key: 'resetValues', value: false });
    actions.workSetFilter({ key: `${key}`, value: [`${value}`] });
    history.push('/work-orders');
  };
  // --------- Extra functions ----------------

  // --------Life Cycle functions ---------

  // ------------- onChange functions -------------

  // ------------- OnClick functions -----------------

  const handleClickAvailability = () => {
    if (!loading && kpi.available > 0) {
      goToVehiclesOrServices('', '', '/services');
    }
  };

  const handleClickWorkshop = () => {
    if (!loading && kpi.workshop?.percentage > 0) {
      goToVehiclesOrServices('', '', '/services');
    }
  };

  const handleClickReplacement = () => {
    if (!loading && kpi.replacement?.percentage > 0) {
      goToVehiclesOrServices('', '', '/services');
    }
  };

  const handleClickPreventive = () => {
    if (!loading && workOrders?.preventive > 0) {
      goToWorkOrders('type', WORK_ORDER_TYPES.ZRMA);
    }
  };
  const handleClickCorrective = () => {
    if (!loading && workOrders?.corrective > 0) {
      goToWorkOrders('type', WORK_ORDER_TYPES.ZRMC);
    }
  };

  const handleClickSinister = () => {
    if (!loading && workOrders?.sinister > 0) {
      goToWorkOrders('type', WORK_ORDER_TYPES.ZRSI);
    }
  };

  const handleClickLateValidity = () => {
    if (!loading && kpi?.lateValidity > 0) {
      goToVehiclesOrServices('dueDate', 'Vencida', '/vehicles');
    }
  };

  const handleClickLateMaintenance = () => {
    if (!loading && kpi.workshop?.percentage > 0) {
      goToVehiclesOrServices('maintenance', 'Atrasada', '/vehicles');
    }
  };

  const handleOpenSearch = () => {
    setIsSearchOpen(true);
  };

  return (
    !loadingData || loading ? <Skeleton />
      : (
        <>
          <Grid container justifyContent="space-between" alignItems="center">
            <ScreenTitle title="DASHBOARD" />
          </Grid>
          {/* total fleet and sinister */}
          <Box display="flex" marginTop={4}>
            <TotalCard
              totalFleet={numberFormatter(kpi.totalVehicles)}
              totalSinister={numberFormatter(parseInt(kpi.sinister, 10))}
            />
          </Box>
          {/* KPI Zone */}
          <Box className={classes.kpiContainer}>
            <Box
              className={classNames(
                classes.kpiContainer1,
                classes.kpiElement,
              )}
              boxShadow={3}
            >
              <Grid container>
                <Grid item xs={12} md={4}>
                  <CustomTooltip content={<AvailabilityText />} width={1000}>
                    <CustomPieChart
                      percentage={Math.round(kpi.available)}
                      text="% Disponibilidad"
                      subText="Mes"
                      onClick={handleClickAvailability}
                      size={size[width].graphic}
                      fontSize={size[width].font}
                    />
                  </CustomTooltip>
                </Grid>
                <Grid item xs={6} md={4}>
                  <CustomTooltip content={<WorkshopText />} width={1000}>
                    <CustomPieChart
                      percentage={Math.round(kpi.workshop?.percentage)}
                      text="% Taller"
                      extraText="sin reemplazo"
                      subText={`${kpi.workshop?.total} Vehículos`}
                      onClick={handleClickWorkshop}
                      opacity={0.75}
                      size={size[width].graphic}
                      fontSize={size[width].font}
                    />
                  </CustomTooltip>
                </Grid>
                <Grid item xs={6} md={4}>
                  <CustomTooltip content={<ReplacementText />}>
                    <CustomPieChart
                      percentage={Math.round(kpi.replacement?.percentage)}
                      text="% Taller"
                      extraText="con reemplazo"
                      subText={`${kpi.replacement?.total} Vehículos`}
                      onClick={handleClickReplacement}
                      opacity={0.35}
                      size={size[width].graphic}
                      fontSize={size[width].font}
                    />
                  </CustomTooltip>
                </Grid>
                <Grid item xs={12}>
                  <Box height={49} />
                </Grid>
              </Grid>
            </Box>
            <Box
              className={classNames(
                classes.kpiContainer2,
                classes.kpiElement,
              )}
              boxShadow={3}
            >
              <Grid container>
                <Grid item xs={6} sm={12} md={6}>
                  <CustomTooltip content={<ValidityText />} width={300}>
                    <CustomPieChart
                      percentage={Math.round((kpi?.lateValidity / kpi?.totalVehicles) * 100) || 0}
                      text="% Vigencia"
                      subText={`${kpi.lateValidity} Vehículos`}
                      onClick={handleClickLateValidity}
                      size={size[width].graphic}
                      fontSize={size[width].font}
                    />
                  </CustomTooltip>
                </Grid>
                <Grid item xs={6} sm={12} md={6}>
                  <CustomTooltip content={<LateMaintenanceText />} width={300}>
                    <CustomPieChart
                      percentage={
                        Math.round((kpi?.lateMaintenance / kpi?.totalVehicles) * 100)
                        || 0
                      }
                      text="% Mantención"
                      subText={`${kpi?.lateMaintenance} Vehículos`}
                      onClick={handleClickLateMaintenance}
                      opacity={0.75}
                      size={size[width].graphic}
                      fontSize={size[width].font}
                    />
                  </CustomTooltip>
                </Grid>
                <Grid item xs={12}>
                  <ZoneTitle title="Atrasos" />
                </Grid>
              </Grid>
            </Box>
            <Box
              className={classNames(
                classes.kpiContainer3,
                classes.kpiElement,
              )}
              boxShadow={3}
            >
              <Grid container>
                <Grid item xs={12} sm={12} md={4}>
                  <CustomTooltip content={<PreventiveText />} width={300}>
                    <CustomPieChart
                      percentage={Math.round(workOrders?.preventive)}
                      text="% Preventiva"
                      onClick={handleClickPreventive}
                      size={size[width].graphic}
                      fontSize={size[width].font}
                    />
                  </CustomTooltip>
                </Grid>
                <Grid item xs={6} sm={6} md={4}>
                  <CustomTooltip content={<CorrectiveText />} width={300}>
                    <CustomPieChart
                      percentage={Math.round(workOrders?.corrective)}
                      text="% Correctiva"
                      onClick={handleClickCorrective}
                      opacity={0.8}
                      size={size[width].graphic}
                      fontSize={size[width].font}
                    />
                  </CustomTooltip>
                </Grid>
                <Grid item xs={6} sm={6} md={4}>
                  <CustomTooltip content={<SinisterText />} width={300}>
                    <CustomPieChart
                      percentage={Math.round(workOrders?.sinister)}
                      text="% Siniestros"
                      onClick={handleClickSinister}
                      opacity={0.6}
                      size={size[width].graphic}
                      fontSize={size[width].font}
                    />
                  </CustomTooltip>
                </Grid>
                <Grid item xs={12}>
                  <ZoneTitle title="Tipo de detención" />
                </Grid>
              </Grid>
            </Box>
          </Box>
          {/* GRAPH Zone */}
          <Grid container spacing={3} justifyContent="space-between">
            <Grid item xs={12} md={12}>
              <Box
                boxShadow={3}
                marginTop={2}
                padding={3}
              >
                <DashBoardBarGraph operationsZone={operationsZone} />
              </Box>
            </Grid>

          </Grid>

          {/* Tabla para administradores de contrato */}
          {user.type === types.ADMINISTRADOR_DE_CONTRATO && (
            <>
              <Typography variant="h2" className={classes.title}>Holdings</Typography>
              <Grid container>
                <Grid item xs={12}>
                  <HoldingSearch
                    isOpen={isSearchOpen}
                    value={searchValue}
                    onChange={(text) => setSearchValue(text)}
                    onClickIcon={handleOpenSearch}
                    onSearch={(text) => actions.tableSetSearch(text)}
                  />
                </Grid>
              </Grid>
              {loadingTable ? (
                <TableSkeleton />
              ) : (
                <Box
                  display="grid"
                  gridTemplateRows="auto"
                  overflow="auto"
                  padding={0}
                  paddingBottom={1}
                >
                  <HoldingList
                    actions={actions}
                    tableControls={tableControls}
                    holdings={tableHoldings}
                  />
                </Box>
              )}
            </>
          )}
        </>
      )
  );
};

export default composedComponent(DashBoard, saga, {
  saga: 'sagaDashBoard',
  middlewares: [withStyles(styles)],
  states: [
    'dashBoard.vehicles',
    'dashBoard.kpi',
    'dashBoard.operationsZone',
    'dashBoard.loading',
    'dashBoard.loadingData',
    'dashBoard.error',
    'dashBoard.message',
    'dashBoard.tableHoldings',
    'dashBoard.tableControls',
    'app.holding',
    'app.socialReason',
    'app.user.user',
  ],
  actions: [dashBoardActions, vehicleActions, workOrderActions, appActions],
});
