const styles = (theme) => ({
  bodyContainer: {
    width: '90%',
    margin: 'auto',
    marginTop: '32px',
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'end',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
});

export default styles;
