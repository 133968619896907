export const HOLDING_COLS = {
  ID: 'Holding ID',
  RUT: 'Rut',
  NAME: 'Nombre',
  SR_COUNT: 'Cantidad RS',
  CREATED_AT: 'Fecha de creación',
  STATE: 'Estado',
};

export const HOLDING_ATTR = {
  ID: 'id',
  RUT: 'rut',
  NAME: 'name',
  SR_COUNT: 'count',
  CREATED_AT: 'createdAt',
  STATE: 'isActive',
};

export const FILTER_OPTIONS = {
  STATUS: 'status',
  DATE: 'date',
  NAME: 'holding',
};
