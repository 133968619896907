import {
  Box, Typography, makeStyles, useMediaQuery, useTheme,
} from '@material-ui/core';
import React from 'react';

import GeneralCard from 'commons/components/GeneralCard';
import classNames from 'classnames';
import GeneralMobileCard from 'commons/components/GeneralMobileCard';
import { randomString } from 'utils/functions';
import CustomCheckBox from 'commons/components/CustomCheckBox';
import styles from './styles';

const useStyles = makeStyles(styles);

const UserCardItem = ({
  icon,
  isMobile,
  width, colors, fontWeights, title,
  row, isCheckbox = false, isChecked, setActiveReport,
}) => {
  const classes = useStyles();

  return (
    <Box display={!isMobile ? 'inline-block' : 'block'}>
      <Box display="flex" width={!isMobile ? width : '100%'}>
        {icon ? <Box className={classes.itemIcon}>{icon}</Box> : null}
        <Box width={!isMobile ? width : '100%'} overflow="auto">
          <Typography align="center" component="div" color="primary" noWrap={!isMobile}>
            <Box
              component="span"
              display="flex"
              justifyContent={!isMobile ? 'center' : 'start'}
              alignItems="center"
              color={colors?.row}
              fontSize={15}
              fontWeight={fontWeights?.row}
              className={classes.text}
            >
              <Typography className={classes.attributeTitle} component="span" variant="h6">
                {isMobile && `${title}: `}
              </Typography>
              {isCheckbox ? <div className={classes.checkboxContainer}><CustomCheckBox onChange={(e, checked) => setActiveReport(checked)} checked={isChecked} /></div> : `${row}`}
            </Box>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const UserCard = ({
  dataCard, isMobile, index, allSelected, actions, user, reportId,
}) => {
  const classes = useStyles();

  const userListItems = dataCard.map((d) => (
    <UserCardItem
      key={d.key}
      isMobile={isMobile}
      icon={d.icon}
      row={d.row}
      isCheckbox={d.isCheckbox}
      title={d.title}
      width={d.width}
      colors={d.colors}
      fontWeights={d.fontWeights}
      centerText={d.center}
      isChecked={allSelected || d.row}
      setActiveReport={
        (checked) => actions.setActiveReport({
          userId: user.id,
          granted: checked,
          reportId,
        })
      }
      justifyContent="center"
    />
  ));

  return (
    <>
      {!isMobile ? (
        <GeneralCard
          className={classNames(classes.root, index % 2 === 0 ? classes.back1 : classes.back2)}
          onClick={() => {}}
        >
          {userListItems}
        </GeneralCard>
      ) : (
        <GeneralMobileCard
          className={classNames(classes.root, index % 2 === 0 ? classes.back1 : classes.back2)}
          flex={false}
          onClick={() => {}}
        >
          {userListItems}
        </GeneralMobileCard>
      )}
      <div />
    </>
  );
};

function UsersList({
  actions, users, getDataCard, allSelected, reportId,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (!users?.length) {
    return (
      <Typography>No hay usuarios disponibles</Typography>
    );
  }

  return users.map((user, index) => (
    <UserCard
      actions={actions}
      reportId={reportId}
      user={user}
      allSelected={allSelected}
      dataCard={getDataCard(user)}
      key={user?.id || randomString()}
      index={index}
      isMobile={isMobile}
    />
  ));
}

export default UsersList;
