import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { isFunction } from 'utils/functions';

import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import styles from './styles';

const useStyles = makeStyles(styles);

const BoxInformation = ({
  total, text, className, firstClassName, secondClassName, onClick = null,
}) => {
  const classes = useStyles({ callback: isFunction(onClick) });
  const handleOnClick = () => {
    if (isFunction(onClick)) onClick();
  };

  return (
    <Box
      className={classNames(className, classes.onMouseHover)}
      onClick={handleOnClick}
    >
      <Typography component="div" color="primary">
        <Box display="flex" flexDirection="row" alignItems="center">
          <Box
            fontWeight="fontWeightMedium"
            className={secondClassName}
            textAlign="center"
            marginRight={1}
          >
            {`${text}:`}
          </Box>
          <Box
            fontWeight="fontWeightBold"
            className={firstClassName}
            textAlign="center"
          >
            {total}
          </Box>
        </Box>
      </Typography>
    </Box>
  );
};

const TotalCard = ({ totalFleet, totalSinister }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Box className={classes.container}>
      <BoxInformation
        total={totalFleet}
        className={classes.fleetContainer}
        firstClassName={classes.firstText}
        secondClassName={classes.secondText}
        text="Flota total"
        onClick={() => history.push('/vehicles')}
      />
      <BoxInformation
        total={totalSinister}
        text="Siniestros"
        className={classes.sinisterContainer}
        firstClassName={classes.firstText}
        secondClassName={classes.secondText}
      />
    </Box>
  );
};

export default TotalCard;
