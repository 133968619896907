import React from 'react';
import { Box, Grid } from '@material-ui/core';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const GeneralMobileCard = ({
  spacing = 0,
  leftComponent = null,
  rightComponent = null,
  overflowX = 'hidden',
  overflowY = 'hidden',
  children,
  className = {},
  onClick,
  flex = true,
}) => {
  const classes = useStyles();

  return (
    <Box
      display={flex ? 'flex' : 'block'}
      flexDirection="row"
      alignItems="center"
      className={classNames(classes.root, className)}
      onClick={onClick}
    >
      {leftComponent || null}
      <Box
        flexGrow={1}
        style={{
          overflowX,
          overflowY,
        }}
      >
        {flex ? (
          <Grid container spacing={spacing}>
            {children}
          </Grid>
        ) : children}
      </Box>
      {rightComponent || null}
    </Box>
  );
};

export default GeneralMobileCard;
