import colors from 'utils/colors';

const styles = (theme) => ({
  textContainer: {
    marginLeft: theme.spacing(2),
    width: 230,
  },
  itemIcon: {
    color: theme.palette.primary.main,
  },
  statusIcon: {
    fontSize: 14.3,
    color: ({ status }) => colors[status],
  },
  licensePlate: {
    width: 230,
  },
  licensePlateBox: {
    overflowX: 'auto',
    overflowY: 'hidden',
  },
});

export default styles;
