import {
  takeLatest, spawn, put, select,
} from 'redux-saga/effects';
import { generatePath, getIds, isArray } from 'utils/functions';
import format from 'date-fns/format';
import {
  apiSuccess, downloadFileBlob, get, post, update,
} from 'utils/api';

import { VEHICLE } from '../reducer';
import * as APP from '../../../commons/reducer';

const getSize = (state) => state.vehicle.pagination.size;
const getPage = (state) => state.vehicle.pagination.page;
const getSocialReason = (state) => state.vehicle.controls.socialReason;
const getQuery = (state) => state.vehicle.query;
const getHolding = (state) => state.app.holding.id;

function* generatePathVehicle({
  page,
  size,
  route,
}) {
  const pageToSend = page > 0 ? page : (yield select(getPage));
  const sizeToSend = size > 0 ? size : (yield select(getSize));
  let path = `${route}?size=${sizeToSend}&page=${pageToSend}`;
  const {
    firstFetch,
    status,
    fleet,
    zone,
    maintenance,
    dueDate,
    orderBy,
    desc,
  } = yield select(getQuery);
  const holding = yield select(getHolding);
  const socialReasonIds = yield select(getSocialReason);
  const pathSocialReason = getIds(socialReasonIds).map((d) => `&socialReason=${d}`).join('').slice(1);

  if (!firstFetch) path += `&firstFetch=${firstFetch ? 'false' : 'true'}`;
  if (holding) path += `&holding=${holding}`;
  if (socialReasonIds.length > 0) path += `&${pathSocialReason}`;
  if (isArray(fleet) && fleet.length > 0) path += generatePath(fleet, 'fleet');
  if (isArray(status) && status.length > 0) path += generatePath(status, 'status');
  if (isArray(zone) && zone.length > 0) path += generatePath(zone, 'zone');
  if (isArray(maintenance) && maintenance.length > 0) path += generatePath(maintenance, 'maintenance');
  if (isArray(dueDate) && dueDate.length > 0) path += generatePath(dueDate, 'dueDate');
  if (orderBy) path += `&orderBy=${orderBy}`;
  path += `&desc=${desc ? 'true' : 'false'}`;
  return path;
}
// FIXME: Refactor all request in 1 method
function* requestVehicles(
  page = 0,
  size = 0,
) {
  const path = yield generatePathVehicle({
    page,
    size,
    route: 'api/v1/vehicles',
  });
  return { page, size, ...yield get(path) };
}

function* fetchVehicles() {
  yield takeLatest(VEHICLE.FETCH_DATA, function* () {
    const response = yield requestVehicles();
    if (!response.error) {
      yield put(apiSuccess(VEHICLE.FETCH_DATA_SUCCESS, response));
      yield put(apiSuccess(APP.GET_STATE_REPLACE_FROM_API_SUCCESS, response));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: 'Error en al cargar al vista de flota, por favor intentelo más tarde.' }));
    }
  });
}

function* fetchWithFilter() {
  yield takeLatest([VEHICLE.FETCH_DATA_WITH_FILTER, VEHICLE.RESET_FILTER], function* () {
    const page = 1;
    const response = yield requestVehicles(page);
    if (!response.error) {
      yield put(apiSuccess(VEHICLE.FETCH_DATA_WITH_FILTER_SUCCESS, response));
      yield put(apiSuccess(APP.GET_STATE_REPLACE_FROM_API_SUCCESS, response));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: 'Error en al cargar al vista de flota, por favor intentelo más tarde.' }));
    }
  });
}

function* changePage() {
  yield takeLatest(VEHICLE.CHANGE_PAGE, function* (action) {
    const page = action.payload;
    const response = yield requestVehicles(page);

    if (!response.error) {
      yield put(apiSuccess(VEHICLE.CHANGE_PAGE_SUCCESS, response));
      yield put(apiSuccess(APP.GET_STATE_REPLACE_FROM_API_SUCCESS, response));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: 'Error en al cargar al vista de flota, por favor intentelo más tarde.' }));
    }
  });
}
function* setOrderBy() {
  yield takeLatest(VEHICLE.SET_ORDER_BY, function* () {
    const response = yield requestVehicles();
    if (!response.error) {
      yield put(apiSuccess(VEHICLE.SET_ORDER_BY_SUCCESS));
      yield put(apiSuccess(APP.GET_STATE_REPLACE_FROM_API_SUCCESS, response));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: 'Error en al cargar al vista de flota, por favor intentelo más tarde.' }));
    }
  });
}

function* changeOrderBy() {
  yield takeLatest(VEHICLE.CHANGE_ORDER_BY, function* () {
    const response = yield requestVehicles();
    if (!response.error) {
      yield put(apiSuccess(VEHICLE.CHANGE_ORDER_BY_SUCCESS));
      yield put(apiSuccess(APP.GET_STATE_REPLACE_FROM_API_SUCCESS, response));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: 'Error en al cargar al vista de flota, por favor intentelo más tarde.' }));
    }
  });
}

function* downloadExcel() {
  yield takeLatest(VEHICLE.DOWNLOAD_EXCEL, function* () {
    const path = yield generatePathVehicle({
      route: 'api/v1/vehicles/generate-excel',
    });

    const date = format(new Date(), 'yyyy-MM-dd');
    const response = yield downloadFileBlob(path, `Vehículos_${date}`);

    if (!response.error) {
      yield put(apiSuccess(VEHICLE.DOWNLOAD_EXCEL_SUCCESS));
      yield put(apiSuccess(APP.SET_SUCCESS, { msg: 'Descarga exitosa' }));
    } else {
      yield put(apiSuccess(VEHICLE.DOWNLOAD_EXCEL_ERROR));
      yield put(apiSuccess(APP.SET_ERROR, { msg: 'Error en la descarga' }));
    }
  });
}

function* downloadDuplicatedExcel() {
  yield takeLatest(VEHICLE.DOWNLOAD_DUPLICATED_EXCEL, function* () {
    const path = yield generatePathVehicle({
      route: 'api/v1/vehicles/generate-duplicated-excel',
    });

    const date = format(new Date(), 'yyyy-MM-dd');
    const response = yield downloadFileBlob(path, `Vehículos_duplicados${date}`);

    if (!response.error) {
      yield put(apiSuccess(VEHICLE.DOWNLOAD_EXCEL_SUCCESS));
      yield put(apiSuccess(APP.SET_SUCCESS, { msg: 'Descarga exitosa' }));
    } else {
      yield put(apiSuccess(VEHICLE.DOWNLOAD_EXCEL_ERROR));
      yield put(apiSuccess(APP.SET_ERROR, { msg: 'Error en la descarga' }));
    }
  });
}

// Driver button
function* assignDriver() {
  yield takeLatest(VEHICLE.ASSIGN_DRIVER, function* (action) {
    const body = action.payload;
    const response = yield update('api/v1/driver/assign', body);
    if (!response.error) {
      yield put(apiSuccess(VEHICLE.ASSIGN_DRIVER_SUCCESS));
      yield put(apiSuccess(APP.SET_SUCCESS, { msg: 'Conductor asignado exitosamente.' }));
    } else {
      yield put(apiSuccess(VEHICLE.ASSIGN_DRIVER_ERROR));
      yield put(apiSuccess(APP.SET_ERROR, { msg: response.errorMsg }));
    }
  });
}
function* createRequest() {
  yield takeLatest(VEHICLE.CREATE_REQUEST, function* (action) {
    const body = { ...action.payload };
    body.run = body.run.replace(/[.]/g, '');

    const response = yield post('api/v1/request/driver', body);
    if (!response.error) {
      yield put(apiSuccess(VEHICLE.CREATE_REQUEST_SUCCESS));
      yield put(apiSuccess(APP.SET_SUCCESS, { msg: response.msg }));
    } else {
      yield put(apiSuccess(VEHICLE.CREATE_REQUEST_ERROR));
      yield put(apiSuccess(APP.SET_ERROR, { msg: response.errorMsg }));
    }
  });
}

function* fetchDrivers() {
  yield takeLatest(VEHICLE.FETCH_DRIVER, function* (action) {
    const socialReasonId = action.payload;

    const response = yield get(`api/v1/driver?socialReason=${socialReasonId}`);

    if (!response.error) {
      yield put(apiSuccess(VEHICLE.FETCH_DRIVER_SUCCESS, response));
    } else {
      yield put(apiSuccess(VEHICLE.FETCH_DRIVER_ERROR));
      yield put(apiSuccess(APP.SET_ERROR, { msg: response.errorMsg }));
    }
  });
}

export default function* root() {
  yield spawn(fetchVehicles);
  yield spawn(changePage);
  yield spawn(fetchWithFilter);
  yield spawn(changeOrderBy);
  yield spawn(setOrderBy);
  yield spawn(downloadExcel);
  yield spawn(downloadDuplicatedExcel);
  yield spawn(assignDriver);
  yield spawn(createRequest);
  yield spawn(fetchDrivers);
}
