import { alpha } from '@material-ui/core/styles/colorManipulator';

const styles = (theme) => ({
  back1: {
    backgroundColor: alpha(theme.palette.primary.main, 0.03),
  },
  back2: {
    backgroundColor: alpha(theme.palette.primary.main, 0.08),
  },
  driveIcon: {
    fontSize: 14.3,
  },
});

export default styles;
