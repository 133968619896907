import React from 'react';
import {
  Box, Dialog, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import Calendar from '@material-ui/icons/CalendarToday';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Transportation from '@material-ui/icons/EmojiTransportationOutlined';
import SalfaIcon from 'utils/salfaIcon';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { changeFormatDate } from 'utils/functions';
import Car from '@material-ui/icons/LocalShippingOutlined';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Arrow from '@material-ui/icons/ArrowForwardIos';
import { useHistory } from 'react-router-dom';
import styles from './styles';

const useStyles = makeStyles(styles);

const Item = ({
  icon, title, text, width,
}) => {
  const classes = useStyles();
  return (
    <Box
      display="inline-block"
      marginTop="16px"
      marginLeft="16px"
    >
      <Box
        display="flex"
        width={width}
      >
        {icon ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            className={classes.icon}
          >
            {icon}
          </Box>
        ) : null}
        <Box
          overflow="auto"
        >
          <Typography
            component="div"
            color="primary"
            noWrap
          >
            <Box
              fontSize={16}
              display="flex"
              justifyContent="start"
              alignItems="center"
              height={16}
              fontWeight={900}
            >
              {title}
            </Box>
            {text ? (
              <Box
                fontStyle="normal"
                fontSize={16}
                display="flex"
                justifyContent="start"
                alignItems="end"
                className={classes.text}
              >
                {text}
              </Box>
            ) : null}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const Date = ({ name, date }) => {
  const classes = useStyles();

  return (
    <Box
      marginTop="16px"
      marginLeft="16px"
    >
      <Typography color="primary" variant="h4">
        <Box
          className={classes.dateItem}
        >
          <Box
            fontWeight={500}
            fontSize={18}
            marginLeft="8px"
            alignItems="center"
            display="flex"
          >
            <Calendar color="primary" fontSize="medium" className={classes.calendar} />
            {name || ''}
          </Box>
          <Box className={classes.dateText}>
            {date || ''}
          </Box>
        </Box>
      </Typography>

    </Box>
  );
};

const Status = ({ status }) => {
  const classes = useStyles();
  return (
    <>
      <FiberManualRecordIcon className={status ? classes.statusIcon : classes.statusIcon2} />
      {status ? 'ABIERTO' : 'CERRADO' }
    </>
  );
};

const Detail = ({
  service, open, handleClose, actions,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const history = useHistory();
  const {
    id,
    externalId,
    status,
    workshopName,
    driverName,
    faultDateStart,
    faultDateEnd,
    wantedDateStart,
    wantedDateEnd,
    responsibleName,
    detentionDay,
    replacementDay,
    vehicle,
  } = service;

  const { licensePlate } = vehicle;

  const goToWorkOrders = (key, value) => {
    actions.workResetControls();
    actions.workSetFilter({ key: 'resetValues', value: false });
    actions.workSetFilter({ key: `${key}`, value: [value] });
    history.push('/work-orders');
  };

  const dataService = [
    {
      key: 1,
      title: externalId,
      text: <Status status={status} />,
      secondRow: '',
      width: 200,
    },
    {
      key: 2,
      icon: <Car fontSize="large" />,
      title: 'Vehículo',
      text: licensePlate,
      secondRow: '',
      width: 200,
    },
    {
      key: 3,
      icon: <AccountCircleIcon fontSize="large" />,
      title: 'Conductor',
      text: driverName || 'No informado',
      secondRow: '',
      width: 200,
    },
    {
      key: 4,
      icon: <SalfaIcon width="30px" />,
      title: 'Responsable',
      text: responsibleName || 'No informado',
      secondRow: '',
      width: 200,
    },
    {
      key: 5,
      icon: <Transportation fontSize="large" />,
      title: 'Taller',
      text: workshopName || 'No informado',
      width: 200,
    },
  ];

  const dataDate = [
    {
      key: 1,
      name: 'Entrada a Taller',
      date: faultDateStart ? changeFormatDate(faultDateStart) : 'No informado',
    },
    {
      key: 2,
      name: 'Salida de Taller',
      date: faultDateEnd ? changeFormatDate(faultDateEnd) : 'No informado',
    },
    {
      key: 3,
      name: 'Ingreso agendado',
      date: wantedDateStart ? changeFormatDate(wantedDateStart) : 'No informado',
    },
    {
      key: 4,
      name: 'Salida estimada',
      date: wantedDateEnd ? changeFormatDate(wantedDateEnd) : 'No informado',
    },
    {
      key: 5,
      name: 'Días Detenido',
      date: replacementDay || 'No informado',
    },
    {
      key: 6,
      name: 'Días Remplazado',
      date: detentionDay || 'No informado',
    },
  ];

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={fullScreen}
      maxWidth={false}
      className={classes.dialog}
    >
      <Box
        className={classes.container}
      >
        <Box className={classes.detail}>
          <Box className={classes.closeIconMobile}>
            <IconButton color="primary" onClick={() => handleClose()}>
              <CloseIcon fontSize="large" />
            </IconButton>
          </Box>
          {dataService.map((d) => (
            <Item
              key={d.key}
              icon={d.icon}
              width={200}
              title={d.title}
              text={d.text}
            />
          ))}
        </Box>
        <Box className={classes.date}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            marginTop="16px"
            paddingLeft="16px"
          >
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => goToWorkOrders('service', parseInt(id, 10))}
              className={classes.button}
              endIcon={<Arrow />}
            >
              TIPOS DE DETENCIÓN
            </Button>
          </Box>
          {dataDate.map((d) => (
            <Date
              key={d.key}
              name={d.name}
              date={d.date}
            />
          ))}
        </Box>
        <Box className={classes.closeIcon}>
          <IconButton color="primary" onClick={() => handleClose()}>
            <CloseIcon fontSize="large" />
          </IconButton>
        </Box>
      </Box>
    </Dialog>
  );
};

export default Detail;
