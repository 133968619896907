const styles = () => ({
  container: {
    height: '600px',
  },
  iframeContainer: {
    width: '100%',
    height: '100%',
    '& iframe': {
      width: '100%',
      height: '100%',
    },
  },
});

export default styles;
