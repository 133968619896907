import { createAction, createReducer } from 'utils/reducer';
// Local constants
const PATH = 'quote/';

const CHANGE_CONTROLS = `${PATH}CHANGE_CONTROLS`;

// Global constants for saga
export const QUOTE = {
  GET_SOCIAL_REASONS: `${PATH}GET_SOCIAL_REASONS`,
  GET_SOCIAL_REASONS_ERROR: `${PATH}GET_SOCIAL_REASONS_ERROR`,
  GET_SOCIAL_REASONS_SUCCESS: `${PATH}GET_SOCIAL_REASONS_SUCCESS`,
  SENT_QUOTE: `${PATH}SENT_QUOTE`,
  SENT_QUOTE_SUCCESS: `${PATH}SENT_QUOTE_SUCCESS`,
  SENT_QUOTE_ERROR: `${PATH}SENT_QUOTE_ERROR`,
  RESET_CONTROLS: `${PATH}RESET_CONTROLS`,
  CHANGE_VEHICLE_TYPES: `${PATH}CHANGE_VEHICLE_TYPES`,
  GET_INITIAL_DATA: `${PATH}GET_INITIAL_DATA`,
};

// actions
export const quoteActions = {
  getSocialReasons: createAction(QUOTE.GET_SOCIAL_REASONS),
  getInitialData: createAction(QUOTE.GET_INITIAL_DATA, 'payload'),
  sentQuote: createAction(QUOTE.SENT_QUOTE, 'payload'),
  changeControls: createAction(CHANGE_CONTROLS, 'payload'),
  changeVehicleTypes: createAction(QUOTE.CHANGE_VEHICLE_TYPES, 'payload'),
  resetControls: createAction(QUOTE.RESET_CONTROLS),
};

const vehicleTypes = {
  1: {
    name: 'Van',
    quantity: 1,
    checked: false,
  },
  2: {
    name: 'Minibús',
    quantity: 1,
    checked: false,
  },
  3: {
    name: 'Automóvil',
    quantity: 1,
    checked: false,
  },
  4: {
    name: 'Furgón',
    quantity: 1,
    checked: false,
  },
  5: {
    name: 'Bus',
    quantity: 1,
    checked: false,
  },
  6: {
    name: 'Suv 4x2',
    quantity: 1,
    checked: false,
  },
  7: {
    name: 'Suv 4x4',
    quantity: 1,
    checked: false,
  },
  8: {
    name: 'Camioneta 4x2',
    quantity: 1,
    checked: false,
  },
  9: {
    name: 'Camioneta 4x4',
    quantity: 1,
    checked: false,
  },
  10: {
    name: 'Otros',
    quantity: 1,
    checked: false,
  },
};

const initialState = {
  formControls: {
    taxNumber: '',
    ccu: '',
    contactClient: '',
    contactEmail: '',
    phone: '',
    requirementType: 'Contrato Nuevo',
    vehicleTypes,
    location: '',
    observations: '',
    color: '',
    deductible: '',
    equipment: '',
    monthlyKilometers: '',
    leaseTerm: '',
    useType: '',
    management: '',
  },
  socialReasons: [],
  success: false,
  loading: false,
  error: false,
};

const quote = createReducer(initialState, {
  [CHANGE_CONTROLS]: (state, action) => ({
    ...state,
    formControls: {
      ...state.formControls,
      ...action.payload,
    },
  }),
  [QUOTE.CHANGE_VEHICLE_TYPES]: (state, action) => ({
    ...state,
    formControls: {
      ...state.formControls,
      vehicleTypes: {
        ...state.formControls.vehicleTypes,
        [action.payload.key]: {
          ...state.formControls.vehicleTypes[action.payload.key],
          [action.payload.attribute]: action.payload.value,
        },
      },
    },
  }),
  [QUOTE.RESET_CONTROLS]: (state) => ({
    ...state,
    formControls: { ...initialState.formControls },
    success: false,
  }),
  [QUOTE.SENT_QUOTE]: (state) => ({
    ...state,
    loading: true,
    error: false,
  }),
  [QUOTE.SENT_QUOTE_ERROR]: (state) => ({
    ...state,
    loading: false,
    error: true,
  }),
  [QUOTE.SENT_QUOTE_SUCCESS]: (state) => ({
    ...state,
    loading: false,
    error: false,
    success: true,
  }),
  [QUOTE.GET_SOCIAL_REASONS]: (state) => ({
    ...state,
    loading: true,
    error: false,
  }),
  [QUOTE.GET_SOCIAL_REASONS_ERROR]: (state) => ({
    ...state,
    loading: false,
    error: true,
  }),
  [QUOTE.GET_SOCIAL_REASONS_SUCCESS]: (state, action) => ({
    ...state,
    socialReasons: action.payload.socialReasons,
    loading: false,
    error: false,
  }),
  [QUOTE.GET_INITIAL_DATA]: (state, action) => ({
    ...state,
    formControls: {
      ...state.formControls,
      ...action.payload,
    },
  }),

});

export default quote;
