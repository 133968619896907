import {
  Box, useMediaQuery, useTheme,
} from '@material-ui/core';
import React from 'react';

// Material
import { makeStyles } from '@material-ui/core/styles';
import { Pagination } from '@material-ui/lab';

import { randomString } from 'utils/functions';
import styles from './styles';
import HeaderList from '../HeaderList';
import HoldingCard from './HoldingCard';

const useStyles = makeStyles(styles);

function HoldingList(props) {
  const {
    holdings: allHoldings, actions, isLoading, controls, total, loading, handleDownload, pagination,
  } = props;
  const { page, size, totalPages } = pagination;
  const holdings = allHoldings.slice(Math.max(size * (page - 1), 0), Math.max(size * page, size));

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();

  return (
    <>
      <Box
        display="grid"
        gridTemplateRows="auto"
        overflow="auto"
        padding={0}
        paddingBottom={1}
      >
        <HeaderList
          orderByValue={controls.orderBy}
          setOrderBy={actions.setOrderBy}
          changeOrderby={actions.changeOrderBy}
          isLoading={isLoading}
          total={total}
          handleDownload={handleDownload}
          loading={loading}
        />
        {holdings && holdings.map((holding, index) => (
          <HoldingCard
            holding={holding}
            key={holding?.id || randomString()}
            index={index}
            isMobile={isMobile}
          />
        ))}
      </Box>

      {/* Paginación */}
      {!!totalPages && (
        <Box display="flex" justifyContent="center" alignItems="end" className={classes.paginationContainer}>
          <Pagination
            onChange={(e, p) => !isLoading && actions.changePage(p)}
            hidePrevButton={!page}
            count={totalPages}
            page={page}
            color="primary"
            size={isMobile ? 'small' : 'large'}
          />
        </Box>
      )}
    </>
  );
}

export default HoldingList;
