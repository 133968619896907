import ButtonWithLoader from 'commons/containers/ButtonWithLoader';
import React from 'react';

const ClientSettingsSubmitButton = ({ onClick }) => (
  <ButtonWithLoader
    buttonId="submit"
    onClick={onClick}
  >
    Guardar cambios

  </ButtonWithLoader>
);

export default ClientSettingsSubmitButton;
