import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { makeStyles } from '@material-ui/core';
import styles from 'commons/components/CustomAutoComplete/styles';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles(styles);

const AutoCompleteMultiple = ({
  id, label, name, value, options, onChange, error, helperText,
}) => {
  const classes = useStyles();

  const selectValue = options.filter((o) => value.includes(o.value));
  const getIds = (data) => [].concat(...data.map((d) => d.value));

  const handleChange = (e, newValue) => {
    const ids = getIds(newValue);
    onChange({ target: { id, value: ids, type: 'select' }, currentTarget: { id } });
  };

  return (
    <Autocomplete
      multiple
      value={selectValue}
      id={id}
      name={name}
      limitTags={1}
      onChange={handleChange}
      options={options}
      disableCloseOnSelect
      getOptionLabel={(option) => (option.label)}
      renderTags={() => <span />}
      renderOption={(option, { selected }) => (
        <>
          <Checkbox
            color="primary"
            icon={icon}
            checkedIcon={checkedIcon}
            className={classes.checkbox}
            checked={selected}
          />
          {option.label}
        </>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          id={id}
          name={name}
          label={label}
          error={error}
          helperText={helperText}
          className={classes.borderColor}
          variant="outlined"
          size="small"
        />
      )}
    />

  );
};

export default AutoCompleteMultiple;
