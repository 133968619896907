import { createAction, createReducer } from 'utils/reducer';

// Local constants
const PATH = 'recoveryPassword/';
const DISABLE_ERRORS = `${PATH}DISABLE_ERRORS`;
const DISABLE_MESSAGE = `${PATH}DISABLE_MESSAGE`;
// Global constants for saga
export const RECOVERYPASSWORD = {
  POST_VERIFY_EMAIL: `${PATH}POST_VERIFY_EMAIL`,
  POST_VERIFY_EMAIL_SUCCESS: `${PATH}POST_VERIFY_EMAIL_SUCCESS`,
  POST_UPDATE_PASSWORD: `${PATH}POST_UPDATE_PASSWORD`,
  POST_UPDATE_PASSWORD_SUCCESS: `${PATH}POST_UPDATE_PASSWORD_SUCCESS`,
  POST_UPDATE_PASSWORD_ERROR: `${PATH}POST_UPDATE_PASSWORD_ERROR`,
  POST_VERIFY_EMAIL_FAIL: `${PATH}POST_VERIFY_EMAIL_ERROR`,
  SET_CONTROLS: `${PATH}SET_CONTROLS`,
  RESET_CONTROLS: `${PATH}RESET_CONTROLS`,
};

// actions
export const recoveryPasswordActions = {
  verifyEmail: createAction(RECOVERYPASSWORD.POST_VERIFY_EMAIL, 'controls'),
  updatePassword: createAction(RECOVERYPASSWORD.POST_UPDATE_PASSWORD, 'controls'),
  setControls: createAction(RECOVERYPASSWORD.SET_CONTROLS, 'payload'),
  resetControls: createAction(RECOVERYPASSWORD.RESET_CONTROLS),
  disableErrors: createAction(DISABLE_ERRORS),
  disableMessage: createAction(DISABLE_MESSAGE),
};

const initialState = {
  controls: {
    password: '',
    verifyPassword: '',
    email: '',
    token: '',
  },
  successChangePassword: false,
  loading: false,
  message: '',
  error: false,
  emailVerify: false,
};

const recoveryPassword = createReducer(initialState, {
  [`${DISABLE_ERRORS}`]: (state) => ({
    ...state,
    error: false,
    successChangePassword: true,
    emailVerify: false,
    message: '',
  }),
  [`${DISABLE_MESSAGE}`]: (state) => ({
    ...state,
    error: false,
    emailVerify: false,
    message: '',
  }),
  // api
  [RECOVERYPASSWORD.POST_VERIFY_EMAIL]: (state) => ({
    ...state,
    loading: false,
    error: false,
    emailVerify: false,
    message: '',
  }),
  [RECOVERYPASSWORD.POST_VERIFY_EMAIL_SUCCESS]: (state) => ({
    ...state,
    loading: false,
    error: false,
    emailVerify: true,
    message: 'Si su e-mail se encuentra registrado, recibirá un correo con instrucciones para reiniciar su contraseña',
  }),
  [RECOVERYPASSWORD.POST_UPDATE_PASSWORD]: (state) => ({
    ...state,
    loading: true,
    error: false,
    emailVerify: false,
  }),
  [RECOVERYPASSWORD.POST_UPDATE_PASSWORD_SUCCESS]: (state) => ({
    ...state,
    loading: false,
    error: false,
    emailVerify: false,
    successChangePassword: true,
    message: 'Su contraseña ha sido actualizada correctamente, por favor inicie sesión',
  }),
  [RECOVERYPASSWORD.POST_UPDATE_PASSWORD_ERROR]: (state) => ({
    ...state,
    loading: false,
    error: false,
    emailVerify: false,
    successChangePassword: false,
  }),
  [RECOVERYPASSWORD.SET_CONTROLS]: (state, action) => ({
    ...state,
    controls: { ...state.controls, ...action.payload },
  }),
  [RECOVERYPASSWORD.RESET_CONTROLS]: (state, action) => ({
    ...state,
    controls: { ...action.controls, ...initialState.controls },
    successChangePassword: false,
  }),
});

export default recoveryPassword;
