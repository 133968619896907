import React from 'react';

import DateInput from 'commons/components/DateInput';
// Material
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import SvgIcon from '@material-ui/core/SvgIcon';

// Project
import CustomSelect from 'commons/components/CustomSelect';
import styles from './styles';

const useStyles = makeStyles(styles);

const ClearIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M16,11h-1V3c0-1.1-0.9-2-2-2h-2C9.9,1,9,1.9,9,3v8H8c-2.76,0-5,2.24-5,5v7h18v-7C21,13.24,18.76,11,16,11z M19,21h-2v-3 c0-0.55-0.45-1-1-1s-1,0.45-1,1v3h-2v-3c0-0.55-0.45-1-1-1s-1,0.45-1,1v3H9v-3c0-0.55-0.45-1-1-1s-1,0.45-1,1v3H5v-5 c0-1.65,1.35-3,3-3h8c1.65,0,3,1.35,3,3V21z" />
  </SvgIcon>
);

const Filter = ({
  controls, actions, roles, statusType, isLoading, pageLoading,
}) => {
  const { clearControls, changeControls, fetchWithFilter } = actions;
  const {
    startDate, disabledDate, type, status,
  } = controls;
  const classes = useStyles();
  return (
    <>
      <Grid container spacing={2} className={classes.container}>
        <Grid item sm={3} lg={2}>
          <DateInput
            value={startDate}
            className={classes.autoComplete}
            handleInput={(value) => {
              if (!value) changeControls('startDate', null);
              else changeControls('startDate', value);
            }}
            fullWidth
            label="Creación"
          />
        </Grid>
        <Grid item sm={3} lg={2}>
          <DateInput
            value={disabledDate}
            className={classes.autoComplete}
            handleInput={(value) => {
              if (!value) changeControls('disabledDate', null);
              else changeControls('disabledDate', value);
            }}
            fullWidth
            label="Deshabilitado"
          />
        </Grid>
        <Grid item sm={3} lg={2}>
          <CustomSelect
            label="Estado"
            id="status"
            name="status"
            variant="outlined"
            onChange={(target) => {
              changeControls('status', target.target.value);
            }}
            value={status}
            options={statusType}
            size="medium"
          />
        </Grid>
        <Grid item sm={3} lg={2}>
          <CustomSelect
            label="Rol"
            id="rol"
            name="rol"
            variant="outlined"
            onChange={(target) => {
              changeControls('type', target.target.value);
            }}
            value={type}
            options={roles}
            size="medium"
          />
        </Grid>
        <Grid item sm={12} lg={4}>
          <Box display="flex" justifyContent="flex-end" height={56}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => !isLoading && !pageLoading && fetchWithFilter()}
            >
              Buscar
            </Button>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              onClick={() => !isLoading && !pageLoading && clearControls()}
              className={classes.clearButton}
            >
              <ClearIcon color="primary" />
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Filter;
