const styles = (theme) => ({
  container: {
    display: 'none',
    borderLeft: '1px solid red',
    paddingLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  adminText: {
    marginBottom: 2,
  },
  text: {
    marginTop: -2,
  },
  linkContacts: {
    color: 'inherit',
    textDecoration: 'inherit',

  },
});

export default styles;
