const styles = (theme) => ({
  container: {
    marginTop: theme.spacing(2),
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  clearButton: {
    height: '100%',
    marginLeft: theme.spacing(2),
    border: '3px solid',
  },
  dialogContainer: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
  mobileHeader: {
    marginBottom: theme.spacing(2),
  },
  iconButton: {
    transform: 'scale(1.7)',
  },
  filterInputAutocomplete: {
    textTransform: 'none',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  mobileResetButton: {
    backgroundColor: theme.palette.primary.light,
  },
  inputs: {
    '& > *': {
      marginTop: theme.spacing(1.5),
    },
  },
  autoComplete: {
    '& .MuiOutlinedInput-input': {
      color: theme.palette.primary.main,
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.primary.main,
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
    '&:hover .MuiOutlinedInput-input': {
      color: theme.palette.primary.main,
    },
    '&:hover .MuiInputLabel-root': {
      color: theme.palette.primary.main,
    },
    '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
      color: theme.palette.primary.main,
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: theme.palette.primary.main,
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
  },
});

export default styles;
