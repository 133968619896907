import React from 'react';
import { composedComponent } from 'utils/functions';

// Material
import { Box, makeStyles, Typography } from '@material-ui/core';
import ScreenTitle from 'commons/components/ScreenTitle';
import { useHistory, useLocation } from 'react-router-dom';
import saga from './saga';

// Project
import { systemActions } from './reducer';

import styles from './styles';
import Notification from './components/Notification';
import Whatsapp from './components/Whatsapp';

const useStyles = makeStyles(styles);

const Button = ({ name, path, selected }) => {
  const classes = useStyles({ selected });
  const history = useHistory();
  return (
    <Box
      key={name}
      className={classes.button}
      color="primary"
      onClick={() => history.push(path)}
    >
      <Typography component="div" variant="h6" noWrap>
        <Box
          fontSize={16}
          className={classes.buttonText}
          fontWeight="normal"
        >
          {name}
        </Box>
      </Typography>
    </Box>
  );
};

const buttons = [
  {
    name: 'NOTIFICACIONES',
    path: '/system',
  },
  {
    name: 'WHATSAPP',
    path: '/system/whatsapp',
  },
];

const System = (props) => {
  const { loading, controls, actions } = props;
  const classes = useStyles(props);
  const location = useLocation();
  return (
    <>
      {loading}
      <ScreenTitle title="SISTEMA" />
      <Box className={classes.root}>
        <Box className={classes.menuContainer}>
          {buttons.map(({ name, path }) => (
            <Button
              key={path}
              name={name}
              path={path}
              selected={location.pathname === path}
            />
          ))}
        </Box>
        <Box className={classes.componentContainer}>
          {location.pathname === '/system'
            ? (<Notification controls={controls} actions={actions} />)
            : null }
          {location.pathname === '/system/whatsapp'
            ? (<Whatsapp controls={controls} actions={actions} />)
            : null }
        </Box>
      </Box>
    </>
  );
};

export default composedComponent(System, saga, {
  saga: 'sagaSystem',
  states: [
    'system.controls',
    'system.loading',
  ],
  actions: [systemActions],
});
