import { createAction, createReducer } from 'utils/reducer';
import { formatRut } from 'utils/rut';

// Local constants
const PATH = 'dashboard/';

// Global constants for saga
export const DASHBOARD = {
  GET_STATE_FROM_API_VEHICLE: `${PATH}GET_STATE_FROM_API_VEHICLE`,
  GET_STATE_FROM_API_VEHICLE_SUCCESS: `${PATH}GET_STATE_FROM_API_VEHICLE_SUCCESS`,
  GET_STATE_FROM_API_VEHICLE_ERROR: `${PATH}GET_STATE_FROM_API_VEHICLE_ERROR`,
  TABLE_GET_HOLDINGS: `${PATH}TABLE_GET_HOLDINGS`,
  TABLE_GET_HOLDINGS_SUCCESS: `${PATH}TABLE_GET_HOLDINGS_SUCCESS`,
  TABLE_GET_HOLDINGS_ERROR: `${PATH}TABLE_GET_HOLDINGS_ERROR`,
  TABLE_SET_ORDER_BY: `${PATH}TABLE_SET_ORDER_BY`,
  TABLE_SET_ORDER_BY_SUCCESS: `${PATH}TABLE_SET_ORDER_BY_SUCCESS`,
  TABLE_SET_PAGE: `${PATH}TABLE_SET_PAGE`,
  TABLE_SET_PAGE_SUCCESS: `${PATH}TABLE_SET_PAGE_SUCCESS`,
  TABLE_SET_SEARCH: `${PATH}TABLE_SET_SEARCH`,
};

// actions
export const dashBoardActions = {
  getStateFromApiVehicle: createAction(DASHBOARD.GET_STATE_FROM_API_VEHICLE),
  getHoldingsPaginated: createAction(DASHBOARD.TABLE_GET_HOLDINGS, 'payload'),
  tableSetOrderBy: createAction(DASHBOARD.TABLE_SET_ORDER_BY, 'payload'),
  tableSetPage: createAction(DASHBOARD.TABLE_SET_PAGE, 'payload'),
  tableSetSearch: createAction(DASHBOARD.TABLE_SET_SEARCH, 'payload'),
};

const initialState = {
  vehicles: [],
  operationsZone: {},
  kpi: {},
  loading: false,
  loadingData: false,
  error: false,
  message: '',
  tableHoldings: [],
  tableControls: {
    search: '',
    loading: false,
    pagination: {
      page: 1,
      size: 5,
      total: 1,
    },
    sortBy: 'rut',
    descending: false,
  },
};

const dashboard = createReducer(initialState, {
  [DASHBOARD.GET_STATE_FROM_API_VEHICLE]: (state) => ({
    ...state,
    loading: true,
  }),
  [DASHBOARD.GET_STATE_FROM_API_VEHICLE_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    loadingData: true,
    vehicles: action.payload.vehicles,
    operationsZone: action.payload.operationsZone,
    kpi: action.payload.kpi,
  }),
  [DASHBOARD.GET_STATE_FROM_API_VEHICLE_ERROR]: (state) => ({
    ...state,
    loading: false,
    loadingData: true,
    error: true,
  }),
  [DASHBOARD.TABLE_GET_HOLDINGS]: (state) => ({
    ...state,
    tableControls: {
      ...state.tableControls,
      loading: true,
    },
  }),
  [DASHBOARD.TABLE_GET_HOLDINGS_SUCCESS]: (state, action) => {
    const tableHoldings = action.payload.holdings.map((h) => ({
      id: h.id,
      rut: formatRut(h.rut),
      name: h.name,
      totalVehicles: h.kpi.totalVehicles, // flota total
      disponibility: h.kpi.available ? h.kpi.available.toFixed(1) : '0', // diponibilidad
      workshop: h.kpi.workshop.percentage ? h.kpi.workshop.percentage.toFixed(1) : '0', // en taller
      replacement: h.kpi.replacement.percentage ? h.kpi.replacement.percentage.toFixed(1) : '0', // reemplazado
      sinister: +h.kpi.sinister, // siniestros
      patents: h.kpi.workshop.total, // Patentes detenidas
    }));

    const totalPages = Math.ceil(action.payload.total / state.tableControls.pagination.size);

    return {
      ...state,
      tableHoldings,
      tableControls: {
        ...state.tableControls,
        loading: false,
        pagination: {
          ...state.tableControls.pagination,
          total: totalPages,
        },
      },
    };
  },
  [DASHBOARD.TABLE_SET_ORDER_BY]: (state, action) => {
    const { label } = action.payload;

    const labelMap = {
      RUT: 'rut',
      Holding: 'holding',
      'Flota Total': 'totalVehicles',
      '% Disponibilidad': 'disponibility',
      '% Taller': 'workshop',
      '% Reemplazos': 'replacement',
      Siniestros: 'sinister',
      'Patentes detenidas': 'patents',
    };

    return {
      ...state,
      tableControls: {
        ...state.tableControls,
        loading: true,
        sortBy: labelMap[label],
        descending: state.tableControls.sortBy === labelMap[label]
          ? !state.tableControls.descending : false,
      },
    };
  },
  [DASHBOARD.TABLE_SET_ORDER_BY_SUCCESS]: () => ({
  }),
  [DASHBOARD.TABLE_SET_PAGE]: (state, action) => ({
    ...state,
    tableControls: {
      ...state.tableControls,
      pagination: {
        ...state.tableControls.pagination,
        page: action.payload,
      },
    },
  }),
  [DASHBOARD.TABLE_SET_PAGE_SUCCESS]: () => ({}),
  [DASHBOARD.TABLE_SET_SEARCH]: (state, action) => ({
    ...state,
    tableControls: {
      ...state.tableControls,
      search: action.payload,
      pagination: {
        ...state.tableControls.pagination,
        page: 1,
      },
    },
  }),
});

export default dashboard;
