import camelize from 'camelcase-keys-deep';

export default class SocialReason {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  holding() {
    let response = {};
    if (this.holdingId) {
      response = this.env().Holding.findBy('id', this.holdingId);
    }
    return response;
  }
}
