import React from 'react';

import { Box, Typography } from '@material-ui/core';

const DateDescription = () => (
  <>
    <Typography color="primary" noWrap>
      <Box fontSize={12} fontWeight={700}>
        Ingreso
      </Box>
      <Box fontSize={12}>
        FECHA AGENDADA DE INGRESO A TALLER
      </Box>
      <Box fontSize={12} fontWeight={700}>
        Estimado
      </Box>
      <Box fontSize={12}>
        FECHA ESTIMADA DE SALIDA DE TALLER
      </Box>
    </Typography>
  </>
);

export default DateDescription;
