import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import styles from './styles';

const useStyles = makeStyles(styles);

function SocialReasonHeader() {
  const classes = useStyles();
  return (
    <Box
      className={classNames(classes.root, classes.header)}
    >
      <Grid container>
        <Grid
          item
          md={6}
          className={classes.item}
        >
          Razón social
        </Grid>
        <Grid
          item
          md={2}
          className={classes.item}
        >
          RUT
        </Grid>
      </Grid>
    </Box>
  );
}

export default SocialReasonHeader;
