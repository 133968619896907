import {
  Box, Button, Dialog, DialogActions, DialogContent, DialogTitle,
  Grid, IconButton, Link, Tooltip, Typography, makeStyles, useMediaQuery, useTheme,
} from '@material-ui/core';
import React from 'react';
import classNames from 'classnames';

import RemoveIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';

import GeneralCard from 'commons/components/GeneralCard';
import GeneralMobileCard from 'commons/components/GeneralMobileCard';
import { randomString } from 'utils/functions';
import { REPORTS_KEYS } from 'screens/ReportsMaintainer/constants';
import LinkIcon from '@material-ui/icons/Link';
import styles from './styles';
import PermissionManagerDialog from '../PermissionsManager';
import ReportsDialog from '../ReportsDialog';

const useStyles = makeStyles(styles);

const ReportCardItem = ({
  icon, isMobile, width, colors, fontWeights, title, row, isUrl,
}) => {
  const classes = useStyles();

  return (
    <Box display={!isMobile ? 'inline-block' : 'block'}>
      <Box display="flex" width={!isMobile ? width : '100%'}>
        {icon ? <Box className={classes.itemIcon}>{icon}</Box> : null}
        <Box width={!isMobile ? width : '100%'} overflow="auto">
          <Typography align="center" component="div" color="primary" noWrap={!isMobile}>
            <Box
              component="span"
              display="flex"
              justifyContent="center"
              alignItems="center"
              color={colors?.row}
              fontSize={15}
              fontWeight={fontWeights?.row}
              className={classes.text}
            >
              <Typography className={classes.attributeTitle} component="span" variant="h6">
                {isMobile && `${title}: `}
              </Typography>
              {isUrl ? (
                <Link href={row} target="_blank" underline="always">
                  <Tooltip title={row}>
                    <LinkIcon />
                  </Tooltip>
                </Link>
              ) : (
                <Tooltip title={row}>
                  <p>
                    {row}
                  </p>
                </Tooltip>
              )}
            </Box>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const ReportCard = ({
  dataCard, isMobile, index, holdings, actions, users, report, loadingUsers,
}) => {
  const classes = useStyles();
  const [isPermissionDialogOpen, setIsPermissionDialogOpen] = React.useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = React.useState(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = React.useState(false);
  const name = dataCard.find((d) => d.key === REPORTS_KEYS.NAME).row;
  const url = dataCard.find((d) => d.key === REPORTS_KEYS.URL).row;

  const handleCloseEditDialog = () => {
    setIsEditDialogOpen(false);
  };

  const handleSubmitEditReport = (reportTitle, reportUrl, handleClose) => {
    actions.editReport({
      id: report.id, title: reportTitle, url: reportUrl, handleClose,
    });
  };

  const handleCloseConfirmDialog = () => {
    setIsConfirmDialogOpen(false);
  };

  const handleOpenConfirmDialog = () => {
    setIsConfirmDialogOpen(true);
  };

  const handleDeleteReport = () => {
    handleCloseEditDialog();
    actions.deleteReport({ id: report.id, index });
  };

  const reportListItems = dataCard.map((d) => (
    <ReportCardItem
      key={d.key}
      isMobile={isMobile}
      icon={d.icon}
      row={d.row}
      title={d.title}
      width={d.width}
      colors={d.colors}
      fontWeights={d.fontWeights}
      centerText={d.center}
      isUrl={d.isUrl}
      justifyContent="center"
      my={2}
    />
  ));

  return (
    <>
      {!isMobile ? (
        <GeneralCard
          className={classNames(classes.root, index % 2 === 0 ? classes.back1 : classes.back2)}
          rightComponent={(
            <Box width="240px" display="flex" justifyContent="space-between" alignItems="center" flexDirection="row">
              <Button onClick={() => setIsPermissionDialogOpen(true)} variant="outlined">Permisos</Button>
              <Button onClick={() => setIsEditDialogOpen(true)} variant="outlined">Editar</Button>
              <IconButton
                color="secondary"
                className={classes.icon}
                onClick={handleOpenConfirmDialog}
              >
                <RemoveIcon />
              </IconButton>
            </Box>
           )}
        >
          {reportListItems}
        </GeneralCard>
      ) : (
        <GeneralMobileCard
          className={classNames(classes.root, index % 2 === 0 ? classes.back1 : classes.back2)}
          flex={false}
          onClick={() => {}}
        >
          {reportListItems}
        </GeneralMobileCard>
      )}
      <div />
      <PermissionManagerDialog
        isOpen={isPermissionDialogOpen}
        reportName={name}
        reportId={report.id}
        holdings={holdings}
        onClose={() => setIsPermissionDialogOpen(false)}
        actions={actions}
        users={users}
        loadingUsers={loadingUsers}
      />
      <ReportsDialog
        name={name}
        url={url}
        title="Editar Reporte"
        isOpen={isEditDialogOpen}
        onClose={handleCloseEditDialog}
        onSubmit={handleSubmitEditReport}
      />
      <Dialog open={isConfirmDialogOpen} fullWidth maxWidth="sm" onClose={handleCloseConfirmDialog}>
        <DialogTitle>
          <Grid container alignItems="center">
            <Grid item className={classes.title}>
              Confirmar
            </Grid>
            <Grid item>
              <IconButton
                onClick={handleCloseConfirmDialog}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Typography variant="h5" className={classes.subtitle}>
            {`¿Desea borrar el reporte ${name}?`}
          </Typography>
        </DialogContent>
        <DialogActions className={classes.actionsContainer}>
          <Button onClick={handleCloseConfirmDialog} color="secondary" variant="contained">
            Cancelar
          </Button>
          <Button onClick={handleDeleteReport} color="primary" variant="contained">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

function ReportsList({
  reports, getDataCard, holdings, actions, users, loadingUsers, page,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (!reports) {
    return (
      <Typography>No hay reportes disponibles</Typography>
    );
  }

  return reports.slice((page - 1) * 10, (page) * 10).map((report, index) => (
    <ReportCard
      report={report}
      dataCard={getDataCard(report)}
      holdings={holdings}
      key={report?.id || randomString()}
      index={index}
      actions={actions}
      isMobile={isMobile}
      users={users}
      loadingUsers={loadingUsers}
    />
  ));
}

export default ReportsList;
