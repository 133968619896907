import format from 'date-fns/format';
import isBefore from 'date-fns/isBefore';
import parse from 'date-fns/parse';
import { compareStrings } from 'screens/HoldingMaintainer/sort';
import { REPORTS_KEYS } from 'screens/ReportsMaintainer/constants';
import { isValidUrl, randomString } from 'utils/functions';
import { createAction, createReducer } from 'utils/reducer';

// Local constants
const PATH = 'reports/';

// Global constants for saga
export const REPORTS = {
  GET_REPORTS: `${PATH}GET_REPORTS`,
  GET_REPORTS_SUCCESS: `${PATH}GET_REPORTS_SUCCESS`,
  GET_REPORTS_ERROR: `${PATH}GET_REPORTS_ERROR`,
  CREATE_REPORT: `${PATH}CREATE_REPORT`,
  CREATE_REPORT_SUCCESS: `${PATH}CREATE_REPORT_SUCCESS`,
  CREATE_REPORT_ERROR: `${PATH}CREATE_REPORT_ERROR`,
  EDIT_REPORT: `${PATH}EDIT_REPORT`,
  EDIT_REPORT_SUCCESS: `${PATH}EDIT_REPORT_SUCCESS`,
  EDIT_REPORT_ERROR: `${PATH}EDIT_REPORT_ERROR`,
  DELETE_REPORT: `${PATH}DELETE_REPORT`,
  DELETE_REPORT_SUCCESS: `${PATH}DELETE_REPORT_SUCCESS`,
  DELETE_REPORT_ERROR: `${PATH}DELETE_REPORT_ERROR`,
  CLEAR_ERRORS: `${PATH}CLEAR_ERRORS`,
  SET_ACTIVE_REPORT: `${PATH}SET_ACTIVE_REPORT`,
  SET_ACTIVE_REPORT_SUCCESS: `${PATH}SET_ACTIVE_REPORT`,
  SET_ACTIVE_REPORT_USERS: `${PATH}SET_ACTIVE_REPORT_USERS`,
  SET_ACTIVE_REPORT_USERS_SUCCESS: `${PATH}SET_ACTIVE_REPORT_USERS_SUCCESS`,
  SET_FILTER: `${PATH}SET_FILTER`,
  SET_USER_FILTER: `${PATH}SET_USER_FILTER`,
  RESET_FILTERS: `${PATH}RESET_FILTERS`,
  RESET_USER_FILTERS: `${PATH}RESET_USER_FILTERS`,
  APPLY_FILTERS: `${PATH}APPLY_FILTERS`,
  APPLY_USER_FILTERS: `${PATH}APPLY_USER_FILTERS`,
  SET_ORDER_BY: `${PATH}SET_ORDER_BY`,
  CHANGE_ORDER_BY: `${PATH}CHANGE_ORDER_BY`,
  USERS_SET_SEARCH: `${PATH}USERS_SET_SEARCH`,
  CHANGE_PAGE: `${PATH}CHANGE_PAGE`,
};

// actions
export const reportsActions = {
  getReports: createAction(REPORTS.GET_REPORTS),
  getUserReports: createAction(REPORTS.GET_REPORTS, 'payload'),
  createReport: createAction(REPORTS.CREATE_REPORT, 'payload'),
  editReport: createAction(REPORTS.EDIT_REPORT, 'payload'),
  deleteReport: createAction(REPORTS.DELETE_REPORT, 'payload'),
  clearErrors: createAction(REPORTS.CLEAR_ERRORS),
  setActiveReport: createAction(REPORTS.SET_ACTIVE_REPORT, 'payload'),
  setActiveReportUsers: createAction(REPORTS.SET_ACTIVE_REPORT_USERS, 'payload'),
  setFilter: createAction(REPORTS.SET_FILTER, 'payload'),
  setUserFilter: createAction(REPORTS.SET_USER_FILTER, 'payload'),
  resetFilters: createAction(REPORTS.RESET_FILTERS),
  resetUserFilters: createAction(REPORTS.RESET_USER_FILTERS),
  applyFilters: createAction(REPORTS.APPLY_FILTERS),
  applyUserFilters: createAction(REPORTS.APPLY_USER_FILTERS),
  setOrderBy: createAction(REPORTS.SET_ORDER_BY, 'payload'),
  changeOrderBy: createAction(REPORTS.CHANGE_ORDER_BY, 'payload'),
  usersSetSearch: createAction(REPORTS.USERS_SET_SEARCH, 'payload'),
  changePage: createAction(REPORTS.CHANGE_PAGE, 'payload'),
};

const compareDates = (key, desc) => (a, b) => {
  const dateA = parse(a[key], 'dd/MM/yyyy', new Date());
  const dateB = parse(b[key], 'dd/MM/yyyy', new Date());

  if (dateA < dateB) return desc ? -1 : 1;
  if (dateA > dateB) return desc ? 1 : -1;

  return 0;
};

const initialState = {
  allReports: [],
  reports: [],
  loading: false,
  error: {
    name: '',
    url: '',
  },
  pagination: {
    size: 10,
    page: 1,
    totalPages: 20,
  },
  usersControls: {},
  controls: {
    page: 1,
    asc: true,
    orderBy: null,
    date: null,
    name: '',
  },
};

const reports = createReducer(initialState, {
  [REPORTS.GET_REPORTS]: (state) => ({
    ...state,
    loading: true,
  }),
  [REPORTS.GET_REPORTS_SUCCESS]: (state, action) => {
    const reportsBI = action.payload.map((report) => ({
      ...report,
      name: report.title,
      createdAt: format(new Date(report.createdAt), 'dd/MM/yyyy'),
    }));

    return {
      ...state,
      reports: reportsBI,
      allReports: reportsBI,
      loading: false,
      error: {
        name: '',
        url: '',
      },
      pagination: {
        size: 10,
        page: 1,
        totalPages: Math.ceil(reportsBI.length / 10),
      },
    };
  },
  [REPORTS.CREATE_REPORT]: (state, action) => {
    if (state.reports.some((report) => report.name === action.payload.title)) {
      // eslint-disable-next-line no-param-reassign
      state.error.name = 'El título ya existe';
      return;
    }
    // eslint-disable-next-line no-param-reassign
    state.error.name = '';

    const { title: name, url } = action.payload;
    if (!name || !url || !isValidUrl(url) || !url.includes('app.powerbi')) return;

    const id = randomString();
    const createdAt = format(new Date(), 'dd/MM/yyyy');
    state.reports.push({
      id, name, url, createdAt,
    });
    state.allReports.push({
      id, name, url, createdAt,
    });
  },
  [REPORTS.CREATE_REPORT_SUCCESS]: (state, action) => {
    // eslint-disable-next-line no-param-reassign
    state.error = {
      name: '',
      url: '',
    };
    // eslint-disable-next-line no-param-reassign
    state.pagination.totalPages = Math.ceil(state.reports.length / 10);
    let updatedReport = state.reports.find((report) => report.name === action.payload.title);
    updatedReport.id = action.payload.id;
    updatedReport = state.allReports.find((report) => report.name === action.payload.title);
    updatedReport.id = action.payload.id;
  },
  [REPORTS.CREATE_REPORT_ERROR]: (state, action) => {
    const { title, url } = action.payload;
    let titleError = state.error.name;
    let urlError = '';

    if (!title) titleError = 'El título es requerido';
    if (!url) urlError = 'La URL es requerida';
    if (url && (!isValidUrl(url) || !url.includes('app.powerbi'))) urlError = 'La URL no es válida';

    return {
      ...state,
      error: {
        name: titleError,
        url: urlError,
      },
    };
  },
  [REPORTS.EDIT_REPORT]: (state, action) => {
    const { id, title, url } = action.payload;
    if (state.reports.some((report) => report.name === title && report.id !== id)) return;
    if (!title || !url || !isValidUrl(url) || !url.includes('app.powerbi')) return;

    let updatedReport = state.reports.find((report) => report.id === id);
    updatedReport.url = url;
    updatedReport.name = title;
    updatedReport.title = title;
    updatedReport = state.allReports.find((report) => report.id === id);
    updatedReport.url = url;
    updatedReport.name = title;
    updatedReport.title = title;
  },
  [REPORTS.DELETE_REPORT]: (state, action) => {
    const { id } = action.payload;
    return {
      ...state,
      reports: state.reports.filter((report) => report.id !== id),
      pagination: {
        ...state.pagination,
        totalPages: Math.ceil((state.reports.length - 1) / 10),
      },
    };
  },
  [REPORTS.DELETE_REPORT_ERROR]: (state, action) => ({
    ...state,
    reports: [
      ...state.reports.slice(0, action.payload.index),
      state.allReports.find((report) => report.id === action.payload.id),
      ...state.reports.slice(action.payload.index),
    ],
  }),
  [REPORTS.CLEAR_ERRORS]: (state) => ({
    ...state,
    error: {
      name: '',
      url: '',
    },
  }),
  [REPORTS.SET_ACTIVE_REPORT]: (state, action) => ({
    ...state,
    activeReport: action.payload,
  }),
  [REPORTS.SET_FILTER]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      [action.payload.key]: action.payload.value,
      page: 1,
    },
  }),
  [REPORTS.SET_USER_FILTER]: (state, action) => ({
    ...state,
    usersControls: {
      ...state.usersControls,
      [action.payload.key]: action.payload.value,
      page: 1,
    },
  }),
  [REPORTS.RESET_FILTERS]: (state) => ({
    ...state,
    reports: state.allReports,
    pagination: {
      ...state.pagination,
      totalPages: Math.ceil(state.allReports.length / 10),
    },
    controls: initialState.controls,
  }),
  [REPORTS.RESET_USER_FILTERS]: (state) => ({
    ...state,
    usersControls: initialState.usersControls,
  }),
  [REPORTS.APPLY_FILTERS]: (state) => {
    const filteredReports = state.allReports.filter((report) => {
      const { date, name } = state.controls;
      const reportDate = parse(report.createdAt, 'dd/MM/yyyy', new Date());

      return (
        (!date || !isBefore(reportDate, new Date(date)))
        && (!name || report.name.toLowerCase().includes(name.toLowerCase()))
      );
    });

    return {
      ...state,
      pagination: {
        ...state.pagination,
        page: 1,
        totalPages: Math.ceil(filteredReports.length / 10),
      },
      reports: filteredReports,
    };
  },
  [REPORTS.SET_ORDER_BY]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      orderBy: action.payload.key === REPORTS_KEYS.PUBLISH_DATE
        ? REPORTS_KEYS.PUBLISH_DATE : REPORTS_KEYS.NAME,
    },
    reports: action.payload.key === REPORTS_KEYS.PUBLISH_DATE
      ? [...state.reports].sort(compareDates('createdAt', state.controls.asc))
      : [...state.reports].sort(compareStrings('name', state.controls.asc)),
  }),
  [REPORTS.CHANGE_ORDER_BY]: (state) => ({
    ...state,
    controls: {
      ...state.controls,
      asc: !state.controls.asc,
    },
    reports: state.controls.orderBy === REPORTS_KEYS.PUBLISH_DATE
      ? [...state.reports].sort(compareStrings('createdAt', !state.controls.asc))
      : [...state.reports].sort(compareStrings('name', !state.controls.asc)),
  }),
  [REPORTS.CHANGE_PAGE]: (state, action) => ({
    ...state,
    pagination: {
      ...state.pagination,
      page: action.payload,
    },
  }),
});

export default reports;
