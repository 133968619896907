import {
  put, select, spawn, takeLatest,
} from 'redux-saga/effects';
import apiRequest, {
  apiSuccess, destroy, get, post,
} from 'utils/api';

import { PAYMENT_STATUS_STATUS } from 'utils/enums';
import type from 'utils/type';
import { NEW_PAYMENT_STATUS, newPaymentStatusActions } from '../reducer';
import * as APP from '../../../commons/reducer';

const getHolding = (state) => state.app.holding.id;

function* requestPaymentsStatus(id) {
  let path = `api/v1/payment-status/${id}`;
  const holding = yield select(getHolding);
  if (holding) path += `?holdingId=${holding}`;

  return { ...yield get(path) };
}

function* fetchPaymentStatus() {
  yield takeLatest(NEW_PAYMENT_STATUS.FETCH_PAYMENT_STATUS, function* (action) {
    const { id } = action;
    const response = yield requestPaymentsStatus(id);
    if (!response.error) {
      yield put(apiSuccess(NEW_PAYMENT_STATUS.CHANGE_SOCIAL_REASON, Number(id)));
      yield put(apiSuccess(NEW_PAYMENT_STATUS.FETCH_PAYMENT_STATUS_SUCCESS, response));
    } else {
      yield put(apiSuccess(NEW_PAYMENT_STATUS.FETCH_PAYMENT_STATUS_FAIL));
      yield put(apiSuccess(APP.SET_ERROR, { msg: 'Estado de pago no encontrado.' }));
    }
  });
}

function* submitExtras() {
  yield takeLatest(NEW_PAYMENT_STATUS.SUBMIT_EXTRAS, function* (action) {
    yield put(APP.appActions.setLoader('extras-button', true));
    const { extrasDialogControls, psId } = action;
    const {
      selectedLease, extraType, amount, description, attachments,
    } = extrasDialogControls;

    const data = new FormData();
    const url = extraType === 0 ? `api/v1/payment-status/add-additional/${selectedLease}` : `api/v1/payment-status/add-discount/${selectedLease}`;

    attachments.forEach((att) => data.append('files', att, att.name));
    data.append('value', amount);
    data.append('description', description);
    data.append('type', extraType + 1);

    const response = yield apiRequest(
      url,
      { method: 'post', body: data },
      false,
    );

    if (!response.error) {
      yield put(newPaymentStatusActions.getPaymentStatus(psId));
      yield put(apiSuccess(NEW_PAYMENT_STATUS.SUBMIT_EXTRAS_SUCCESS));
      yield put(apiSuccess(APP.SET_SUCCESS, { msg: 'Extra añadido con éxito' }));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { msg: response.errorMsg }));
    }
    yield put(APP.appActions.setLoader('extras-button', false));
  });
}

function* submitDocuments() {
  yield takeLatest(NEW_PAYMENT_STATUS.SUBMIT_DOCUMENTS, function* (action) {
    yield put(APP.appActions.setLoader('documents-button', true));

    const { documentsDialogControls, psId } = action;
    const {
      attachments, filesIndex,
    } = documentsDialogControls;

    const filesIndexParsed = filesIndex.reduce((results, fi) => {
      // eslint-disable-next-line no-param-reassign
      (results[fi.id] = results[fi.id] || []).push(fi.name);
      return results;
    }, {});

    const data = new FormData();
    const url = 'api/v1/payment-status/document-fleet';

    attachments.forEach((att) => data.append('files', att, att.name));
    data.append('fleetAttachments', JSON.stringify(filesIndexParsed));

    const response = yield apiRequest(
      url,
      { method: 'post', body: data },
      false,
    );

    if (!response.error) {
      yield put(newPaymentStatusActions.getPaymentStatus(psId));
      yield put(apiSuccess(NEW_PAYMENT_STATUS.SUBMIT_DOCUMENTS_SUCCESS));
      yield put(apiSuccess(APP.SET_SUCCESS, { msg: 'Documentos añadidos con éxito' }));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { msg: response.errorMsg }));
    }
    yield put(APP.appActions.setLoader('documents-button', false));
  });
}

function* deleteExtrasDocument() {
  yield takeLatest(NEW_PAYMENT_STATUS.DELETE_EXTRAS_DOCUMENT, function* (action) {
    yield put(APP.appActions.setLoader('confirmation-button', true));
    const { id, psId } = action;

    const url = `api/v1/payment-status/document-extra-delete/${id}`;

    const response = yield destroy(url);

    if (!response.error) {
      yield put(newPaymentStatusActions.getPaymentStatus(psId));
      yield put(apiSuccess(NEW_PAYMENT_STATUS.DELETE_EXTRAS_DOCUMENT_SUCCESS, response));
      yield put(apiSuccess(APP.SET_SUCCESS, { msg: 'Documento eliminado con éxito' }));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { msg: response.errorMsg }));
    }
    yield put(APP.appActions.setLoader('confirmation-button', false));
  });
}

function* deleteFleetsDocument() {
  yield takeLatest(NEW_PAYMENT_STATUS.DELETE_FLEETS_DOCUMENT, function* (action) {
    yield put(APP.appActions.setLoader('confirmation-button', true));

    const { id, psId } = action;

    const url = `api/v1/payment-status/document-fleet/${id}`;

    const response = yield destroy(url);

    if (!response.error) {
      yield put(newPaymentStatusActions.getPaymentStatus(psId));
      yield put(apiSuccess(NEW_PAYMENT_STATUS.DELETE_FLEETS_DOCUMENT_SUCCESS, response));
      yield put(apiSuccess(APP.SET_SUCCESS, { msg: 'Documento eliminado con éxito' }));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { msg: response.errorMsg }));
    }
    yield put(APP.appActions.setLoader('confirmation-button', false));
  });
}

function* workPaymentStatus() {
  yield takeLatest(NEW_PAYMENT_STATUS.WORK_PAYMENT_STATUS, function* (action) {
    yield put(APP.appActions.setLoader('confirmation-button', true));

    const { status, psId } = action;

    if (status !== PAYMENT_STATUS_STATUS.NOT_INITIALIZED) {
      yield put(APP.appActions.setLoader('confirmation-button', false));
      yield put(apiSuccess(APP.SET_ERROR, { msg: 'Error estado EP no corresponde, por favor intentelo más tarde.' }));
      return;
    }

    const url = `api/v1/payment-status/initialize/${psId}`;

    const response = yield post(url);

    if (!response.error) {
      yield put(newPaymentStatusActions.getPaymentStatus(psId));
      yield put(apiSuccess(NEW_PAYMENT_STATUS.WORK_PAYMENT_STATUS_SUCCESS, response));
      yield put(apiSuccess(APP.SET_SUCCESS, { msg: 'Realizado con éxito' }));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { msg: response.errorMsg }));
    }
    yield put(APP.appActions.setLoader('confirmation-button', false));
  });
}

function* releasePaymentStatus() {
  yield takeLatest(NEW_PAYMENT_STATUS.RELEASE_PAYMENT_STATUS, function* (action) {
    yield put(APP.appActions.setLoader('confirmation-button', true));

    const { status, psId } = action;

    if (status !== PAYMENT_STATUS_STATUS.DRAFT) {
      yield put(APP.appActions.setLoader('confirmation-button', false));
      yield put(apiSuccess(APP.SET_ERROR, { msg: 'Error estado EP no corresponde, por favor intentelo más tarde.' }));
      return;
    }

    const url = `api/v1/payment-status/release/${psId}`;

    const response = yield post(url);

    if (!response.error) {
      yield put(newPaymentStatusActions.getPaymentStatus(psId));
      yield put(apiSuccess(NEW_PAYMENT_STATUS.RELEASE_PAYMENT_STATUS_SUCCESS, response));
      yield put(apiSuccess(APP.SET_SUCCESS, { msg: 'Realizado con éxito' }));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { msg: response.errorMsg }));
    }
    yield put(APP.appActions.setLoader('confirmation-button', false));
  });
}

function* submitPost() {
  yield takeLatest(NEW_PAYMENT_STATUS.SUBMIT_POST, function* (action) {
    yield put(APP.appActions.setLoader('post-button', true));
    const {
      postDialogControls, psId, psFleetId, role,
    } = action;
    const {
      observation, attachments,
    } = postDialogControls;
    const data = new FormData();
    const url = role === type.ENCARGADO_DE_FLOTA_SECCION
    || role === type.GESTOR_DE_FLOTA_DECISOR
      ? `api/v1/payment-status/request-revision/${psFleetId}`
      : `api/v1/payment-status/send-revision-to-client/${psFleetId}`;

    attachments.forEach((att) => data.append('files', att, att.name));
    data.append('description', observation);

    const response = yield apiRequest(
      url,
      { method: 'post', body: data },
      false,
    );

    if (!response.error) {
      yield put(newPaymentStatusActions.getPaymentStatus(psId));
      yield put(apiSuccess(NEW_PAYMENT_STATUS.SUBMIT_POST_SUCCESS));
      yield put(apiSuccess(APP.SET_SUCCESS, { msg: 'Enviado con éxito' }));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { msg: response.errorMsg }));
    }
    yield put(APP.appActions.setLoader('post-button', false));
  });
}

function* acceptFleet() {
  yield takeLatest(NEW_PAYMENT_STATUS.ACCEPT_FLEET, function* (action) {
    yield put(APP.appActions.setLoader('confirmation-button', true));

    const { psId, psFleetId } = action;

    const url = `api/v1/payment-status/accept-zone/${psFleetId}`;

    const response = yield post(url);

    if (!response.error) {
      yield put(newPaymentStatusActions.getPaymentStatus(psId));
      yield put(apiSuccess(NEW_PAYMENT_STATUS.ACCEPT_FLEET_SUCCESS, response));
      yield put(apiSuccess(APP.SET_SUCCESS, { msg: 'Realizado con éxito' }));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { msg: response.errorMsg }));
    }
    yield put(APP.appActions.setLoader('confirmation-button', false));
  });
}

function* approvePaymentStatus() {
  yield takeLatest(NEW_PAYMENT_STATUS.APPROVE_PAYMENT_STATUS, function* (action) {
    yield put(APP.appActions.setLoader('approve-button', true));

    const { approveDialogControls, psId, status } = action;
    const { oc, hes, attachments } = approveDialogControls;

    if (status !== PAYMENT_STATUS_STATUS.REVIEW) {
      yield put(APP.appActions.setLoader('approve-button', false));
      yield put(apiSuccess(APP.SET_ERROR, { msg: 'Error estado EP no corresponde, por favor intentelo más tarde.' }));
      return;
    }

    const data = new FormData();
    const url = `api/v1/payment-status/approve/${psId}`;

    attachments.forEach((att) => data.append('files', att, att.name));
    data.append('purchaseOrder', oc);
    data.append('hes', hes);

    const response = yield apiRequest(
      url,
      { method: 'post', body: data },
      false,
    );

    if (!response.error) {
      yield put(newPaymentStatusActions.getPaymentStatus(psId));
      yield put(apiSuccess(NEW_PAYMENT_STATUS.APPROVE_PAYMENT_STATUS_SUCCESS, response));
      yield put(apiSuccess(APP.SET_SUCCESS, { msg: 'Realizado con éxito' }));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { msg: response.errorMsg }));
    }
    yield put(APP.appActions.setLoader('approve-button', false));
  });
}

function* closePaymentStatus() {
  yield takeLatest(NEW_PAYMENT_STATUS.CLOSE_PAYMENT_STATUS, function* (action) {
    yield put(APP.appActions.setLoader('confirmation-button', true));

    const { status, psId } = action;

    if (status !== PAYMENT_STATUS_STATUS.APPROVED) {
      yield put(APP.appActions.setLoader('confirmation-button', false));
      yield put(apiSuccess(APP.SET_ERROR, { msg: 'Error estado EP no corresponde, por favor intentelo más tarde.' }));
      return;
    }

    const url = `api/v1/payment-status/close/${psId}`;

    const response = yield post(url);

    if (!response.error) {
      yield put(newPaymentStatusActions.getPaymentStatus(psId));
      yield put(apiSuccess(NEW_PAYMENT_STATUS.CLOSE_PAYMENT_STATUS_SUCCESS, response));
      yield put(apiSuccess(APP.SET_SUCCESS, { msg: 'Realizado con éxito' }));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { msg: response.errorMsg }));
    }
    yield put(APP.appActions.setLoader('confirmation-button', false));
  });
}

function* deleteExtra() {
  yield takeLatest(NEW_PAYMENT_STATUS.DELETE_EXTRA, function* (action) {
    yield put(APP.appActions.setLoader('confirmation-button', true));
    const { id, psId } = action;

    const url = `api/v1/payment-status/delete-additional/${id}`;

    const response = yield destroy(url);

    if (!response.error) {
      yield put(newPaymentStatusActions.getPaymentStatus(psId));
      yield put(apiSuccess(NEW_PAYMENT_STATUS.DELETE_EXTRA_SUCCESS, response));
      yield put(apiSuccess(APP.SET_SUCCESS, { msg: 'Extra eliminado con éxito' }));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { msg: response.errorMsg }));
    }
    yield put(APP.appActions.setLoader('confirmation-button', false));
  });
}

export default function* root() {
  yield spawn(fetchPaymentStatus);
  yield spawn(submitExtras);
  yield spawn(submitDocuments);
  yield spawn(deleteExtrasDocument);
  yield spawn(deleteFleetsDocument);
  yield spawn(workPaymentStatus);
  yield spawn(releasePaymentStatus);
  yield spawn(submitPost);
  yield spawn(acceptFleet);
  yield spawn(approvePaymentStatus);
  yield spawn(closePaymentStatus);
  yield spawn(deleteExtra);
}
