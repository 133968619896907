const styles = (theme) => ({
  borderColor: {
    '& .MuiFormLabel-root': {
      color: theme.palette.primary.main,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
      },
      '& fieldset': {
        borderColor: theme.palette.primary.main,
      },
    },
  },
  skeleton: {
    transform: 'scale(1)',
  },
});

export default styles;
