import React, { useEffect } from 'react';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert } from '@material-ui/lab';
import { composedComponent } from 'utils/functions';

// commons
import { appActions } from 'commons/reducer';
import type from 'utils/type';
import saga from '../commons/saga';

// Routes
import Routes from './components/Routes';
import theme from './theme';

const themeProvider = createTheme(theme);

const App = (props) => {
  const {
    actions,
    error,
    errorMsg,
    success,
    successMsg,
    loadingUser,
    user,
    holding,
  } = props;

  useEffect(() => {
    actions.getUser();
  }, []);

  useEffect(() => {
    const currentHolding = sessionStorage.getItem('holding') ? JSON.parse(sessionStorage.getItem('holding')) : null;
    if (!currentHolding && user) {
      actions.getInitialHolding();
    } else {
      actions.setHolding(currentHolding);
    }
  }, [user]);

  useEffect(() => {
    if (holding?.name && user?.user) {
      // eslint-disable-next-line no-undef
      gtag('config', 'G-9Q84NMLE5K', {
        user_id: user.user.email,
      });
      // eslint-disable-next-line no-undef
      gtag('set', 'user_properties', {
        user_email: user.user.email,
        user_holding: user.user.type === type.ADMINISTRADOR_PORTAL_SALFA || user.user.type === type.ADMINISTRADOR_DE_CONTRATO ? 'Admin' : holding.name,
        holding_rut: user.user.type === type.ADMINISTRADOR_PORTAL_SALFA || user.user.type === type.ADMINISTRADOR_DE_CONTRATO ? 'Admin' : holding.rut,
      });
    }
  }, [holding]);

  const toggleError = () => {
    actions.toggleError();
  };

  const toggleSuccess = () => {
    actions.toggleSuccess();
  };

  return loadingUser ? <div /> : (
    <ThemeProvider theme={themeProvider}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={error}
        autoHideDuration={3000}
        onClose={toggleError}
      >
        <Alert onClose={toggleError} severity="error">
          {errorMsg}
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={success}
        autoHideDuration={3000}
        onClose={toggleSuccess}
      >
        <Alert onClose={toggleSuccess} severity="success">
          {successMsg}
        </Alert>
      </Snackbar>

      <Routes
        user={user?.user}
        loading={loadingUser}
      />
    </ThemeProvider>
  );
};

export default composedComponent(App, saga, {
  saga: 'sagaApp',
  states: [
    'app.drawerIsOpen',
    'app.errorMsg',
    'app.error',
    'app.successMsg',
    'app.success',
    'app.user',
    'app.loadingUser',
    'app.holding',
  ],
  actions: [appActions],
});
