import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

// material
// import MuiAlert from '@material-ui/lab/Alert';
// import Snackbar from '@material-ui/core/Snackbar';
import { Box, makeStyles } from '@material-ui/core';

// Project
import Footer from 'commons/components/Footer';
import type from 'utils/type';
import { composedComponent } from 'utils/functions';
// import saga from '../../saga';
import NavBar from '../../components/NavBar';
import SideBar from '../../components/SideBar';
import { appActions } from '../../reducer';
import styles from './styles';

const useStyles = makeStyles(styles);

// function Alert(props) {
//   return <MuiAlert elevation={6} variant="filled" {...props} />;
// }

const Layout = (props) => {
  const {
    drawerIsOpen,
    actions,
    children,
    // errorMsg,
    // successMsg,
    // error,
    // success,
    user,
    holdings,
    dataVehicles,
    loadingSearch,
    search,
    isOpenNotification,
    notifications, // Objects.notifications
    executive,
  } = props;
  const history = useHistory();
  const classes = useStyles(props);

  const logOut = () => {
    localStorage.clear();
    sessionStorage.clear();
    actions.setUser(null);
    history.push('/');
  };

  const handleItemClick = (module) => () => {
    history.push(module);
    actions.toggleDrawer();
  };

  const handleGetVehicles = (value) => {
    actions.matchVehicles(value);
  };

  const handleOpenNotification = () => {
    actions.openNotification();
  };
  const handleClearNotification = () => {
    actions.clearNotification();
  };

  useEffect(() => {
    actions.clearDrawer();
    actions.getNotification();
  }, []);

  useEffect(() => {
    if (user.user.type === type.GESTOR_DE_FLOTA_DECISOR) {
      actions.getExecutive();
    }
  }, [user]);

  const arrayNotifications = Object.values(notifications).map((n) => ({
    id: n.id,
    value: n.value,
    valueClick: n.valueClick,
    extraValue: n.extraValue,
    holdingId: n.holdingId,
    holdingName: n.holdingName,
    licensePlate: n.licensePlate,
    type: n.type,
    read: n.read,
    idUserNotification: n.idUserNotification,
    createdAt: n.createdAt,
  }));

  const countNotifications = useMemo(() => arrayNotifications.filter((n) => !n.read),
    [arrayNotifications]);

  const handleClickNotification = (
    valueClick, id, actionType, value, notificationType, holding,
  ) => {
    if (actionType === 'read') {
      if (notificationType === 'DRIVER_REQUEST') {
        history.push(`/request?id=${valueClick}`);
      } else if (notificationType === 'DUE_CONTRACT' || notificationType === 'MAINTENANCE') {
        if (holding) {
          actions.setHolding({
            id: holding.id,
            name: holding.name,
            cb: history.push(`/vehicles/${valueClick}`),
          });
        } else {
          history.push(`/vehicles/${valueClick}`);
        }
      } else if (notificationType === 'PAYMENT_STATUS_RELEASED'
        || notificationType === 'PAYMENT_STATUS_FIX_ISSUE'
        || notificationType === 'PAYMENT_STATUS_ISSUE_FIXED'
        || notificationType === 'PAYMENT_STATUS_APPROVED'
        || notificationType === 'PAYMENT_STATUS_CLOSED') {
        if (holding) {
          actions.setHolding({
            id: holding.id,
            name: holding.name,
            cb: history.push(`/payment-status/${valueClick}`),
          });
        } else {
          history.push(`/payment-status/${valueClick}`);
        }
      }

      if (!value) {
        actions.updateNotification({ id, actionType });
      }
    }
    if (actionType === 'deleted') {
      actions.updateNotification({ id, actionType });
    }
  };

  return (
    <Box className={classes.boxContainer} minHeight={1} display="flex" flexDirection="column">
      <NavBar
        toggleDrawer={actions.toggleDrawer}
        user={user}
        handleGetVehicles={handleGetVehicles}
        loadingSearch={loadingSearch}
        dataVehicles={dataVehicles}
        search={search}
        openSearch={actions.openSearch}
        closeSearch={actions.closeSearch}
        logOut={logOut}
        notifications={arrayNotifications}
        totalNotification={countNotifications}
        handleClickNotification={handleClickNotification}
        executive={executive}
      />

      <SideBar
        user={user}
        holdings={holdings}
        isOpen={drawerIsOpen}
        toggleDrawer={actions.toggleDrawer}
        openHolding={actions.openModal}
        handleItemClick={handleItemClick}
        logOut={logOut}
        isOpenNotification={isOpenNotification}
        handleOpenNotification={handleOpenNotification}
        handleClearNotification={handleClearNotification}
        notifications={arrayNotifications}
        totalNotification={countNotifications}
        updateNotification={actions.updateNotification}
        executive={executive}
        handleClickNotification={handleClickNotification}
      />
      {/* All screens */}
      <Box className={classes.globalContainer}>
        {children}
      </Box>
      <Footer />
    </Box>
  );
};
const sagaDefault = function* root() {};
export default composedComponent(Layout, sagaDefault, {
  saga: 'sagaLayout',
  states: [
    'app.drawerIsOpen',
    'app.deviceError',
    'app.device',
    'app.errorMsg',
    'app.error',
    'app.successMsg',
    'app.success',
    'app.user',
    'app.dataVehicles',
    'app.loadingSearch',
    'app.search',
    'app.executive',
    'app.isOpenNotification',
    'app.objects.notifications',
    'app.objects.holdings',
  ],
  actions: [appActions],
});
