import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';
import {
  Box, TextField, useMediaQuery, useTheme,
} from '@material-ui/core';
import CustomFilterButton from 'commons/components/CustomFilterButton';
import DialogFilter from 'commons/components/DialogFilter';
import { MobileDatePicker } from '@material-ui/pickers';
import { format as fndFormat } from 'date-fns';
import DateRangeIcon from '@material-ui/icons/DateRange';
import TextInputFilter from 'commons/components/TextInputFilter';
import styles from './styles';

const useStyles = makeStyles(styles);

function Filter({
  actions,
  controls,
  reports,
  handleFilter,
  handleClearFilter,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const handleOpenDialog = () => setIsFilterOpen(true);
  const handleCloseDialog = () => setIsFilterOpen(false);
  const handleSubmit = () => {
    handleFilter();
    handleCloseDialog();
  };

  const filterFields = (
    <Grid container spacing={2} className={classes.container}>
      <Grid item xs={12} md={4} lg={4}>
        <MobileDatePicker
          label="Fecha de publicación"
          renderInput={(props) => (
            <Box className={classes.dateContainer}>
              <TextField
                {...props}
                className={classes.inputDate}
                fullWidth
                variant="outlined"
                helperText={false}
                size="small"
                InputProps={{
                  endAdornment: (
                    <DateRangeIcon color="primary" />
                  ),
                }}
              />
            </Box>
          )}
          value={controls.date}
          onChange={(value) => {
            const format = 'yyyy/MM/dd';
            actions.setFilter({ key: 'date', value: fndFormat(value, format) });
          }}
        />
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <TextInputFilter
          id="name"
          label="Reporte"
          options={reports.map((report) => ({ ...report, value: report.name, label: report.name }))}
          value={controls.name}
          onChange={actions.setFilter}
        />
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <CustomFilterButton
          onClick={handleSubmit}
          clear={handleClearFilter}
        />
      </Grid>
    </Grid>
  );

  if (!isMobile) return filterFields;

  return (
    <DialogFilter
      isOpen={isFilterOpen}
      open={handleOpenDialog}
      close={handleCloseDialog}
    >
      {filterFields}
    </DialogFilter>
  );
}

export default Filter;
