import { Box, makeStyles, TextField } from '@material-ui/core';
import {
  MobileDateRangePicker,
} from '@material-ui/pickers';

import React from 'react';

// Icons
import DateRangeIcon from '@material-ui/icons/DateRange';

import { dateFormat } from 'utils/functions';
import { addDays } from 'date-fns';
import styles from './styles';

const useStyles = makeStyles(styles);

function CustomDateRangePicker({
  value = [null, null],
  size = 'small',
  fullWidth = false,
  startText = 'Inicio de fecha',
  endText = 'Fin de fecha',
  onChange,
}) {
  const classes = useStyles();
  return (
    <MobileDateRangePicker
      clearable
      toolbarTitle="Seleccione un rango"
      startText={startText}
      endText={endText}
      value={value}
      onChange={(newValue) => {
        const [date1, date2] = newValue;

        onChange([
          date1 && !Number.isNaN(date1.getTime()) ? dateFormat(addDays(date1, 1)) : null,
          date2 && !Number.isNaN(date2.getTime()) ? dateFormat(addDays(date2, 1)) : null,
        ]);
      }}
      renderInput={(startProps, endProps) => (
        <Box className={classes.container}>
          <TextField
            {...startProps}
            className={classes.input}
            fullWidth
            variant="outlined"
            helperText={false}
            size={size}
            InputProps={{
              endAdornment: (
                <DateRangeIcon color="primary" />
              ),
            }}
          />
          <TextField
            {...endProps}
            className={classes.input}
            fullWidth={fullWidth}
            variant="outlined"
            helperText={false}
            size={size}
            InputProps={{
              endAdornment: (
                <DateRangeIcon color="primary" />
              ),
            }}
          />
        </Box>
      )}
    />
  );
}

export default CustomDateRangePicker;
