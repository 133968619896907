import { Skeleton } from '@material-ui/lab';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { randomString } from './functions';

export const Title = ({ width }) => (
  <Typography component="div" variant="h4">
    <Skeleton width={width} />
  </Typography>
);

export const Circle = ({ width, height }) => (
  <Skeleton
    variant="circle"
    width={width}
    height={height}
  />
);

export const Table = ({ cells, height = 40 }) => (
  Array.from(new Array(cells)).map(() => (
    <Skeleton
      key={randomString()}
      variant="rect"
      width="100%"
      height={height}
      style={{ marginTop: '5px' }}
    />
  ))
);
