import {
  Box, FormControlLabel, FormGroup, makeStyles, Typography, Switch, Button,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import styles from './styles';

// Material
const useStyles = makeStyles(styles);

const SaveChangeButton = ({ title, onClick }) => {
  const classes = useStyles();
  return (
    <Box>
      <Button onClick={onClick} fullWidth={false} className={classes.button} variant="contained" color="secondary">
        {title}
      </Button>
    </Box>
  );
};

const Whatsapp = ({ controls, actions }) => {
  const classes = useStyles();
  const { getWhatsappData, saveWhatsappConfiguration } = actions;

  useEffect(() => {
    getWhatsappData();
  }, []);

  const [system, setSystem] = useState({
    whatsapp: false,
  });

  useEffect(() => {
    const { whatsapp } = controls;
    if (whatsapp) setSystem({ ...whatsapp.system });
  }, [controls]);

  const handleSystemChange = ({ target }) => {
    setSystem({ ...system, [target.name]: target.checked });
  };
  const handleSaveChanges = () => {
    saveWhatsappConfiguration({ system });
  };

  const {
    whatsapp,
  } = system;

  return (
    <>
      <Box className={classes.root}>
        <Typography component="div" color="primary">
          <Box marginBottom={3} fontSize={22}>
            WHATSAPP
          </Box>
        </Typography>
        <FormGroup onChange={handleSystemChange}>
          <FormControlLabel
            control={(
              <Switch
                color="primary"
                checked={whatsapp}
                name="whatsapp"
              />
)}
            label={whatsapp ? 'Habilitado' : 'Desabilitado'}
          />

        </FormGroup>
      </Box>
      <SaveChangeButton title="Guardar Cambios" onClick={() => handleSaveChanges()} />
    </>
  );
};

export default Whatsapp;
