import React from 'react';
import {
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// Material
import Typography from '@material-ui/core/Typography';
import { currencyForm } from 'utils/utils';
import styles from './style';

const useStyles = makeStyles(styles);

// icons

const CardItem = ({ title, content }) => {
  const classes = useStyles(); return (
    <Grid container className={classes.cardContainer}>
      <Grid item xs={6}>
        <Typography className={classes.cardTitle}>
          {title}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography className={classes.cardContent}>
          {content}
        </Typography>
      </Grid>
    </Grid>
  );
};

const TotalDetailsCard = ({
  totalCharges, totalDiscounts, totalNet, totalBase,
}) => {
  const porcIVA = 0.19;
  const porcIVAPlus = 1.19;
  const classes = useStyles();
  return (
    <Grid container justifyContent="space-around" className={classes.gridContainer}>
      <CardItem title="TOTAL ARRIENDOS: " content={currencyForm.format(totalBase)} />
      <CardItem title="TOTAL CARGOS: " content={currencyForm.format(totalCharges)} />
      <CardItem title="TOTAL DESCUENTOS: " content={currencyForm.format(totalDiscounts)} />
      <CardItem title="TOTAL NETO: " content={currencyForm.format(totalNet)} />
      <CardItem title="I.V.A: " content={currencyForm.format(totalNet * porcIVA)} />
      <CardItem title="TOTAL: " content={currencyForm.format(totalNet * porcIVAPlus)} />
    </Grid>
  );
};

export default TotalDetailsCard;
