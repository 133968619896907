import React from 'react';
import Pagination from '@material-ui/lab/Pagination';
import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const CustomPagination = ({ totalPages, page, onChange }) => {
  const classes = useStyles();
  return (
    <Pagination
      className={classes.pagination}
      onChange={(e, p) => onChange(p)}
      hidePrevButton={false}
      hideNextButton={false}
      count={totalPages}
      page={page}
      color="primary"
      size="large"
    />
  );
};

export default CustomPagination;
