import React from 'react';
import { Button, CircularProgress, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import CustomTextField from 'commons/components/CustomTextField';
import { firstUppercase, onlyNumber } from 'utils/functions';
import { formatRut } from 'utils/rut';

const RequestDriver = ({
  socialReason, onChange, handleSubmit, errors, controls, loadingRequest,
}) => {
  const inputs = [
    {
      name: 'run', label: 'RUT', type: 'text', format: formatRut,
    },
    {
      name: 'name', label: 'Nombre', format: firstUppercase,
    },
    {
      name: 'email', label: 'Email',
    },
    {
      name: 'phone', label: 'Télefono', format: onlyNumber,
    },
  ];

  return (
    <>
      <Typography color="primary" component="span">
        <Box
          fontSize={16}
        >
          La solicitud del nuevo conductor se
          asociará a la razón social a la que pertenece el vehículo.
          En este caso será para
          {' '}
          {socialReason}
        </Box>
      </Typography>
      <Typography color="primary" component="span">
        <Box
          fontSize={16}
        >
          La solicitud generada será recibida por su administrador de contrato
          para su revisión.
        </Box>
      </Typography>
      <form onSubmit={handleSubmit} autoComplete="off">
        <Grid container spacing={1} justifyContent="center" alignItems="center">
          {
          inputs.map((i) => (
            <Grid key={i.name} item xs={12} align="center">
              <CustomTextField
                id={i.name}
                name={i.name}
                label={i.label}
                variant="outlined"
                fullWidth
                size="medium"
                value={i.format ? i.format(controls[i.name]) : controls[i.name]}
                onChange={(e) => onChange(e, i.format)}
                error={!!errors[i.name]}
                helperText={errors[i.name] && errors[i.name][0]}
              />
            </Grid>
          ))
        }
          <Grid align="right" item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color={loadingRequest ? 'default' : 'secondary'}
              disabled={loadingRequest}
              style={{ marginTop: '16px' }}
            >
              {loadingRequest ? <CircularProgress size={24} /> : 'enviar solicitud'}
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default RequestDriver;
