import React from 'react';

// Material

import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Container } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
  link: {
    color: '#fff',
    textDecoration: 'none',
    '&:hover': {
      color: '#4db6ac',
    },
  },
}));

const Footer = () => {
  const classes = useStyles();
  const data = new Date();
  const year = data.getFullYear();

  return (
    <AppBar position="static" style={{ backgroundColor: '#021c30', marginTop: '32px' }}>
      <Container maxWidth="md">
        <Toolbar>
          <Typography
            component="span"
            variant="body1"
            color="inherit"
            align="center"
            style={{
              flexGrow: 1,
              alignItems: 'center',
            }}
          >
            {`© COPYRIGHT ${year} | All Rights Reserved | Desarrollado por `}
            <a
              href="https://www.tcit.cl/"
              target="_blank"
              rel="noreferrer"
              className={classes.link}
            >
              TCIT
            </a>
          </Typography>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Footer;
