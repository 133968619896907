import {
  Box, Button, Typography, makeStyles, useMediaQuery, useTheme,
} from '@material-ui/core';
import React from 'react';
import classNames from 'classnames';

import GeneralCard from 'commons/components/GeneralCard';
import styles from './styles';

const useStyles = makeStyles(styles);

const HeaderItem = ({
  label, width, center = false,
}) => {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="center"
      width={width}
    >
      <Button
        fullWidth
        disabled
        color="primary"
        className={classNames(classes.button, center && classes.center)}
        size="small"
      >
        <Box fontWeight={700} color="white">
          {label}
        </Box>
      </Button>
    </Box>
  );
};

function UsersListHeader({
  headers, orderByValue, setOrderBy, changeOrderby,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (!isMobile ? (
    <GeneralCard
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      paddingRight={1}
      paddingLeft={2}
      className={classes.root}
    >
      {headers.map((header) => (
        <HeaderItem
          orderByValue={orderByValue}
          setOrderBy={setOrderBy}
          changeOrderby={changeOrderby}
          label={header.label}
          keyValue={header.key}
          key={header.key}
          width={header.width}
          center={header.center}
        />
      ))}
    </GeneralCard>
  ) : (
    <Box>
      <Box
        justifyContent="space-between"
      >
        <Box
          className={classes.mobileListTitle}
        >
          <Typography variant="h5" color="primary">
            Listado de Reportes
          </Typography>
        </Box>
      </Box>
    </Box>
  ));
}

export default UsersListHeader;
