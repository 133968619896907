import { Grid, Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import styles from './styles';

const useStyles = makeStyles(styles);

const CardItem = ({ title, content }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} className={classes.cardGrid}>
      <Typography display="inline" className={classes.cardTitle}>
        {title}
      </Typography>
      <Typography display="inline">
        {content}
      </Typography>
    </Grid>
  );
};

export default CardItem;
