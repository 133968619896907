import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';

import ButtonWithLoader from 'commons/containers/ButtonWithLoader';
import styles from './styles';

const useStyles = makeStyles(styles);

const ReassignHoldingDialog = ({
  isOpen, title, onClose, holdings, onSubmit,
}) => {
  const [selectedHolding, setSelectedHolding] = useState(null);
  const [error, setError] = useState(false);
  const classes = useStyles();

  const handleClose = () => {
    setSelectedHolding(null);
    onClose();
  };
  const handleSelectHolding = (e, value) => {
    setError(false);
    setSelectedHolding(value);
  };
  const handleSubmit = () => {
    if (!selectedHolding) {
      setError(true);
    } else {
      setError(false);
      onSubmit(selectedHolding.id, handleClose);
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle className={classes.dialogTitle}>
        <Grid container alignItems="center">
          <Grid item className={classes.title}>
            {title}
          </Grid>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Autocomplete
          noOptionsText="No se encontraron resultados"
          options={holdings}
          value={selectedHolding}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Holding"
              variant="outlined"
              error={error}
              helperText={error ? 'Seleccione un holding' : ''}
              required
            />
          )}
          onChange={handleSelectHolding}
        />
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <ButtonWithLoader
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          className={classes.button}
        >
          Aceptar
        </ButtonWithLoader>
      </DialogActions>
    </Dialog>
  );
};

export default ReassignHoldingDialog;
