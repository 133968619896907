import {
  Box, Button, FormControl, InputLabel, MenuItem, Select,
  Typography, makeStyles, useMediaQuery, useTheme,
} from '@material-ui/core';
import React from 'react';
import classNames from 'classnames';

// icons
import ImportExportIcon from '@material-ui/icons/ImportExport';
import SwapVertIcon from '@material-ui/icons/SwapVert';

import { REPORTS_KEYS, REPORTS_LABELS } from 'screens/ReportsMaintainer/constants';
import GeneralCard from 'commons/components/GeneralCard';
import styles from './styles';

const useStyles = makeStyles(styles);

const OptionMenu = ({
  position = 'center',
}) => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      alignItems={position}
      justifyContent="center"
      className={classes.option}
    />
  );
};

const HeaderItem = ({
  setOrderBy, changeOrderby, label, keyValue, orderByValue, width, canOrder, center = false,
}) => {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="center"
      width={width}
    >
      <Button
        fullWidth
        color="primary"
        className={classNames(classes.button, center && classes.center)}
        size="small"
        endIcon={canOrder && <ImportExportIcon className={classes.icon} />}
        disabled={!canOrder}
        onClick={() => {
          if (orderByValue === keyValue) {
            changeOrderby();
          } else if (orderByValue === '' || orderByValue !== keyValue) {
            setOrderBy({ key: keyValue });
          }
        }}
      >
        <Box fontWeight={700} color="white">
          {label}
        </Box>
      </Button>
    </Box>
  );
};

function Header({
  actions, orderByValue, headers,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (!isMobile ? (
    <GeneralCard
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      paddingRight={1}
      paddingLeft={2}
      className={classes.root}
      rightComponent={(<OptionMenu />)}
    >
      {headers.map((header) => (
        <HeaderItem
          orderByValue={orderByValue}
          setOrderBy={actions.setOrderBy}
          changeOrderby={actions.changeOrderBy}
          label={header.label}
          keyValue={header.key}
          key={header.key}
          width={header.width}
          center={header.center}
          canOrder={header.canOrder}
        />
      ))}
    </GeneralCard>
  ) : (
    <Box>
      <Box display="flex" className={classes.mobileOrderFilter}>
        <FormControl variant="outlined" fullWidth className={classes.mobileOrderInput}>
          <InputLabel htmlFor="outlined-age-native-simple">Ordenar Por</InputLabel>
          <Select
            value={orderByValue ?? ''}
            color="primary"
            onChange={(e) => actions.setOrderBy({ key: e.target.value })}
            label="Ordernar por"
            inputProps={{
              name: 'Ordenar por',
              id: 'outlined-age-native-simple',
            }}
          >
            <MenuItem aria-label="None" value="">Ninguno</MenuItem>
            <MenuItem value={REPORTS_KEYS.PUBLISH_DATE}>{REPORTS_LABELS.PUBLISH_DATE}</MenuItem>
            <MenuItem value={REPORTS_KEYS.NAME}>{REPORTS_LABELS.NAME}</MenuItem>
          </Select>
        </FormControl>
        {false && (
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => actions.changeOrderby()}
        >
          <SwapVertIcon />
        </Button>
        )}
      </Box>
      <Box
        justifyContent="space-between"
      >
        <Box
          className={classes.mobileListTitle}
        >
          <Typography variant="h5" color="primary">
            Listado de Reportes
          </Typography>
        </Box>
      </Box>
    </Box>
  ));
}

export default Header;
