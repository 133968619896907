import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';

const useStyles = makeStyles(styles);
const CardItem = ({
  title, content, xs, md, isMobile, right = false, center = false,
}) => {
  let align;
  if (center) {
    align = 'center';
  } else if (right && !isMobile) {
    align = 'right';
  } else {
    align = 'left';
  }
  const classes = useStyles();

  return (
    <Grid item xs={xs} md={md} style={{ textAlign: align }}>
      { isMobile
      && (
      <Typography className={classes.titleCard}>
        {title}
      </Typography>
      )}
      <Typography>
        {content}
      </Typography>
    </Grid>
  );
};

export default CardItem;
