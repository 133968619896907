import React from 'react';
import Button from '@material-ui/core/Button';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styles from '../styles';

const useStyles = makeStyles(styles);
// Material

// icons

const BtnRequireRevision = ({ actions, disabled }) => {
  const classes = useStyles();
  return (
    <Tooltip interactive placement="top" title={disabled ? 'Flota no seleccionada o flota está en estado Revisión salfa' : ''}>
      <div className={classes.divWidth}>
        <Button
          variant="contained"
          color="secondary"
          fullWidth
          disabled={disabled}
          onClick={() => {
            actions.changeControls('postDialog', true);
          }}
        >
          SOLICITAR CORRECCIÓN
        </Button>
      </div>
    </Tooltip>

  );
};

export default BtnRequireRevision;
