import React from 'react';
import PersonIcon from '@material-ui/icons/Person';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import {
  Box, InputAdornment, TextField, Tooltip, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const UserSearch = ({
  onClickIcon, isOpen, onChange, value, onKeyPress,
}) => {
  const classes = useStyles();

  const handleChange = (e) => {
    onChange(e.target.id, e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      onKeyPress();
    }
  };
  return (
    <div>
      {!isOpen
        ? (
          <Tooltip title={(
            <Typography component="div">
              <Box fontSize={12}>
                Buscar Usuario
              </Box>
            </Typography>
          )}
          >
            <IconButton
              onClick={onClickIcon}
            >
              <SearchIcon
                color="primary"
              />
            </IconButton>
          </Tooltip>
        )
        : (
          <TextField
            id="userName"
            value={value}
            variant="outlined"
            placeholder="Usuario"
            fullWidth
            onChange={handleChange}
            className={classes.inputField}
            onKeyPress={handleKeyPress}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonIcon color="primary" />
                </InputAdornment>
              ),
            }}
          />
        )}
    </div>
  );
};

export default UserSearch;
