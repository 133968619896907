import React from 'react';
import {
  Box, Dialog, IconButton, Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/styles';
import FilterListOutlinedIcon from '@material-ui/icons/FilterListOutlined';
import Button from '@material-ui/core/Button';
import styles from './styles';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const useStyles = makeStyles(styles);

const DialogFilter = ({
  children, isOpen, open, close,
}) => {
  const classes = useStyles();
  return (
    <>
      <Button
        variant="contained"
        color="default"
        size="large"
        fullWidth
        onClick={open}
        className={classes.filterButton}
        startIcon={<FilterListOutlinedIcon />}
      >
        Filtros
      </Button>
      <Dialog
        fullScreen
        open={isOpen}
        keepMounted
        TransitionComponent={Transition}
      >
        <Box className={classes.dialogContainer}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            className={classes.mobileHeader}
          >
            <Typography variant="h4" color="primary">
              Filtros
            </Typography>
            <IconButton
              className={classes.iconButton}
              color="primary"
              onClick={close}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            className={classes.inputs}
          >
            {children}
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default DialogFilter;
