import { alpha } from '@material-ui/core/styles/colorManipulator';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    boxShadow: theme.shadows[4],
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  back1: {
    backgroundColor: alpha(theme.palette.primary.main, 0.03),
  },
  back2: {
    backgroundColor: alpha(theme.palette.primary.main, 0.08),
  },
  paginationContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  text: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  attributeTitle: {
    marginRight: theme.spacing(1),
  },
  option: {
    width: '200px',
  },
  title: {
    flex: 1,
  },
  actionsContainer: {
    marginTop: theme.spacing(2),
  },
  button: {
    boxShadow: 'none',
    justifyContent: 'flex-start',
  },
  center: {
    justifyContent: 'center',
  },
  mobileOrderInput: {
    marginRight: theme.spacing(2),
  },
  icon: {
    height: '100%',
  },
  container: {
    height: '750px',
    marginTop: '20px',
  },
  iframeContainer: {
    width: '100%',
    height: '100%',
    '& iframe': {
      width: '100%',
      height: '100%',
    },
  },
});

export default styles;
