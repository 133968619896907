import { alpha } from '@material-ui/core/styles/colorManipulator';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(1),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
    },
  },
  nameContainer: {
    marginLeft: theme.spacing(2),
    width: 230,
  },
  optionIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  itemIcon: {
    color: theme.palette.primary.main,
  },
  statusIcon: {
    fontSize: 20,
  },
  statusMobileIcon: {
    fontSize: 20,
  },
  mobileIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
    fontSize: 24,
  },
  HABILITADO: { color: 'green' },
  DESABILITADO: { color: 'red' },
  iconSquare: {
    display: 'flex',
    justifyContent: 'end',
    color: theme.palette.primary.main,
  },
  back1: {
    backgroundColor: theme.palette.primary.secondary,
    marginBottom: theme.spacing(1),
    borderRadius: 8,
    minHeight: theme.spacing(10),
    padding: 8,
  },
  listTitle: {
    marginBottom: theme.spacing(2),
  },
  tooltip: {
    backgroundColor: 'white',
    border: `1px solid ${theme.palette.primary.main}`,
  },
  arrow: {
    color: alpha(theme.palette.primary.main, 0.2),
  },
  cardGrid: {
    minWidth: '300px',
  },
  cardTitle: {
    fontWeight: 600,
  },
  label: {
    cursor: 'default',
  },
  chip: {
    maxWidth: '100%',
  },
});

export default styles;
