import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import {
  Box, ListItemIcon, MenuItem, MenuList,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import ApartmentIcon from '@material-ui/icons/Apartment';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Divider from '@material-ui/core/Divider';
import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined';
import { useHistory } from 'react-router-dom';
import ModalHolding from 'commons/components/NavBar/components/ModalHolding';
import styles from './styles';

const useStyles = makeStyles(styles);
const MenuUser = ({
  email,
  isOpen,
  open,
  close,
  holdings,
  setHolding,
  setSocialReason,
  currentHoldingId,
  logOut,
  socialReasons,
  getSocialReason,
  currentSocialReason,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenModal = () => {
    open('openHolding');
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);

  return (
    <div>
      <Box className={classes.profileContainer}>
        <IconButton className={classes.profileButton} onClick={handleClick}>
          <AccountCircleOutlinedIcon fontSize="large" color="primary" className={classes.profileIcon} />
          <Typography variant="body1" color="primary">
            {email}
          </Typography>
        </IconButton>
      </Box>
      <Popover
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuList>
          <MenuItem onClick={() => {
            history.push('/account');
          }}
          >
            <ListItemIcon>
              <PermIdentityOutlinedIcon fontSize="small" color="primary" />
            </ListItemIcon>
            <Typography variant="inherit">Mi cuenta</Typography>
          </MenuItem>

          <MenuItem onClick={() => handleOpenModal()}>
            <ListItemIcon>
              <ApartmentIcon fontSize="small" color="primary" />
            </ListItemIcon>
            <Typography variant="inherit">Holding</Typography>
          </MenuItem>

          <Divider />

          <MenuItem onClick={() => logOut()}>
            <ListItemIcon>
              <ExitToAppIcon fontSize="small" color="primary" />
            </ListItemIcon>
            <Typography variant="inherit" noWrap>
              Cerrar sesión
            </Typography>
          </MenuItem>
        </MenuList>
      </Popover>
      <ModalHolding
        isOpen={isOpen}
        close={close}
        options={holdings}
        socialReasons={socialReasons}
        setHolding={setHolding}
        setSocialReason={setSocialReason}
        currentHoldingId={currentHoldingId}
        currentSocialReason={currentSocialReason}
        getSocialReason={getSocialReason}
      />
    </div>
  );
};

export default MenuUser;
