import React from 'react';
import {
  Button,
  Dialog, DialogContent,
  DialogContentText,
  DialogTitle, Grid, IconButton, Input, InputAdornment,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CustomTextField from 'commons/components/CustomTextField';
import { AttachFile, FileCopy, RemoveCircle } from '@material-ui/icons';
import { v4 as uuidv4 } from 'uuid';
import ButtonWithLoader from 'commons/containers/ButtonWithLoader';
import { fileSizeLimit } from 'utils/utils';
import styles from './styles';

const useStyles = makeStyles(styles);

const ApproveDialog = ({
  controls, actions, approveDialogControls, psId, status,
}) => {
  const classes = useStyles();

  const handleClose = () => {
    actions.changeControls('approveDialog', false);
  };

  const handleFile = (e) => {
    if (!e.target.files) {
      return;
    }
    let updatedAttachments = approveDialogControls.attachments;
    if (updatedAttachments?.length === 0) {
      [...e.target.files].forEach((file) => {
        if (file.size <= fileSizeLimit) {
          const renamedFile = new File([file], `${uuidv4()}-${file.name}`, { type: file.type });
          updatedAttachments = [...updatedAttachments, renamedFile];
        }
      });
    }
    actions.changeApproveDialogControls('attachments', updatedAttachments);
    e.target.value = null;
  };

  const deleteFile = (attName) => () => {
    const updatedAttachments = approveDialogControls.attachments.filter(
      (att) => att.name !== attName,
    );
    actions.changeApproveDialogControls('attachments', updatedAttachments);
  };

  const handleSubmit = () => {
    actions.approvePaymentStatus(approveDialogControls, psId, status);
  };

  const disabledSubmit = !approveDialogControls.oc.length > 0
    && !approveDialogControls.hes.length > 0;
  return (
    <>
      <Dialog open={controls.approveDialog} onClose={handleClose} fullWidth>
        <DialogTitle>
          <Typography
            variant="h6"
            color="primary"
            className={classes.listTitle}
          >
            ACEPTAR ESTADO DE PAGO
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Button
                className={classes.fileButton}
                variant="contained"
                component="label"
                endIcon={<AttachFile />}
                fullWidth
              >
                Adjuntar orden de compra
                <input
                  type="file"
                  hidden
                  onChange={handleFile}
                />
              </Button>
              <Typography
                variant="subtitle2"
                color="gray"
                className={classes.fileSize}
              >
                El tamaño máximo de cada archivo es de 25MB.
                Si supera este límite, el archivo no se adjuntará.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {approveDialogControls.attachments?.map((att) => (
                <Input
                  key={att.name}
                  className={classes.fileName}
                  value={att.name.slice(37, att.name.length)}
                  fullWidth
                  disabled
                  startAdornment={(
                    <InputAdornment position="start">
                      <FileCopy color="primary" />
                    </InputAdornment>
                  )}
                  endAdornment={(
                    <InputAdornment position="end">
                      <IconButton color="primary" onClick={deleteFile(att.name)}>
                        <RemoveCircle />
                      </IconButton>
                    </InputAdornment>
                  )}
                />
              ))}
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                color="primary"
                className={classes.listTitle}
              >
                Observaciones
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                id="oc"
                autoFocus
                color="primary"
                value={approveDialogControls.oc}
                onChange={(e) => {
                  actions.changeApproveDialogControls('oc', e.target.value);
                }}
                required
                label="N° de orden de compra"
                variant="outlined"
                size="medium"
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                id="hes"
                autoFocus
                color="primary"
                value={approveDialogControls.hes}
                onChange={(e) => {
                  actions.changeApproveDialogControls('hes', e.target.value);
                }}
                required
                label="Código HES"
                variant="outlined"
                size="medium"
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClose}
              >
                CANCELAR
              </Button>
              <ButtonWithLoader
                className={classes.button}
                buttonId="approve-button"
                onClick={handleSubmit}
                disabled={disabledSubmit}
                color="primary"
                variant="contained"
              >
                ENVIAR
              </ButtonWithLoader>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default ApproveDialog;
