import React, { useState } from 'react';
import {
  Button,
  CircularProgress, LinearProgress,
  makeStyles,
  Typography,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { createFilterOptions } from '@material-ui/lab';
import styles from './styles';

const useStyles = makeStyles(styles);

const ChangeDriver = ({
  value,
  drivers = [],
  loadingDrivers = false,
  loadingAssign = false,
  onChange,
  assignDriver,
  errorDrivers,
  setIsChange,
  driverExist = false,
}) => {
  const classes = useStyles();
  const [error, setError] = useState(false);

  const handleOnclick = () => {
    if (!value) {
      setError(true);
    } else {
      assignDriver();
    }
  };

  const onChangeDriver = (e, data) => {
    onChange({
      driverRun: data ? data.value : null,
      driverName: data ? data.label : null,
    });

    if (!data) {
      setError(true);
    } else {
      setError(false);
    }
  };

  const filterOptions = createFilterOptions({
    trim: true,
    ignoreCase: true,
    stringify: (option) => `${option.label} ${option.value} `,
  });

  const AutocompleteDrivers = () => (
    errorDrivers
      ? (
        <Typography color="secondary" component="span">
          <Box
            fontSize={16}
          >
            El listado de conductores no se encuentra disponible, por favor intente más tarde.
          </Box>
        </Typography>
      ) : (
        <Autocomplete
          noOptionsText="No se encontraron resultados"
          value={drivers.find((h) => h.value === value) ?? null}
          className={classes.autoComplete}
          id="driverRun"
          onChange={onChangeDriver}
          options={drivers}
          filterOptions={filterOptions}
          getOptionLabel={(option) => option.label}
          renderOption={(option) => (
            <Box>
              <Typography color="primary" component="span">
                <Box
                  fontWeight={600}
                >
                  {option.label}
                </Box>
              </Typography>
              <Typography color="primary" component="span">
                {option.value}
              </Typography>
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Conductor"
              variant="outlined"
              error={error}
              helperText={error && 'Seleccione un conductor'}
            />
          )}
        />
      )
  );

  return (
    <>
      <Typography color="primary" component="span">
        <Box
          fontSize={16}
        >
          A continuación se le dará la opción de seleccionar dentro de un
          listado,
          a un conductor que estará a cargo del vehículo.
        </Box>
      </Typography>
      <Typography color="primary" component="span">
        <Box
          fontSize={16}
        >
          Seleccione un conductor dentro del listado.
        </Box>
      </Typography>
      {driverExist
      && (
      <Typography color="secondary" component="span">
        <Box
          fontSize={16}
        >
          El RUT ingresado ya pertenece a un conductor,
          por favor realice una nueva búsqueda en el listado.
        </Box>
      </Typography>
      )}
      <Box>
        {loadingDrivers ? <LinearProgress />
          : (
            <AutocompleteDrivers />
          )}
      </Box>
      {!errorDrivers
      && (
      <Typography color="primary" component="span">
        <Box
          display="flex"
          fontSize={16}
          justifyContent="center"
          onClick={() => setIsChange(false)}
          className={classes.link}
        >
          ¿No encuentras tu conductor? Solicita uno nuevo aquí.
        </Box>
      </Typography>
      )}
      <Box
        display="flex"
        justifyContent="right"
      >
        <Button
          type="submit"
          variant="contained"
          color={loadingDrivers || loadingAssign ? 'default' : 'secondary'}
          onClick={handleOnclick}
          disabled={loadingDrivers || loadingAssign}
          className={classes.button}
        >
          {loadingDrivers || loadingAssign ? <CircularProgress size={24} /> : 'CAMBIAR CONDUCTOR'}
        </Button>
      </Box>
    </>
  );
};

export default ChangeDriver;
