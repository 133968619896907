const styles = (theme) => ({
  dialogContainer: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(5),
    marginBottom: theme.spacing(4),
  },
  mobileHeader: {
    marginBottom: theme.spacing(2),
  },
  iconButton: {
    transform: 'scale(1.7)',
  },
  content: {
    '& > *': {
      marginTop: theme.spacing(1.5),
    },
  },
  divider: {
    backgroundColor: theme.palette.primary.main,
    height: '2px',
  },
});

export default styles;
