const styles = (theme) => ({
  container: {
    marginTop: theme.spacing(6),
    height: '90%',
  },
  listContainer: {
    [theme.breakpoints.up('sm')]: {
      borderRight: '1px solid #A1A1A1',
    },
  },
});

export default styles;
