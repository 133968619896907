import { createReducer, createAction } from 'utils/reducer';
import { LOGIN } from 'screens/Login/reducer';
import { NAVBAR } from 'commons/components/NavBar/reducer';
import { REPORTS } from 'screens/Reports/reducer';

// GLOBAL
export const GET_USER = 'app/GET_USER';
export const GET_USER_SUCCESS = 'app/GET_USER_SUCCESS';
export const GET_USER_FAIL = 'app/GET_USER_FAIL';
export const GET_USERS = 'app/GET_USERS';
export const GET_USERS_SUCCESS = 'app/GET_USERS_SUCCESS';
export const GET_USERS_FAIL = 'app/GET_USERS_FAIL';
export const SET_CONTROLS = 'app/SET_CONTROLS';
export const GET_STATE_FROM_API = 'app/GET_API_FROM_STATE';
export const GET_STATE_FROM_API_SUCCESS = 'app/GET_API_FROM_STATE_SUCCESS';
export const GET_STATE_REPLACE_FROM_API_SUCCESS = 'app/GET_STATE_REPLACE_FROM_API_SUCCESS';
// export const LOG_OUT = 'app/LOG_OUT';
export const CLEAR_DRAWER = 'app/CLEAR_DRAWER';
export const TOGGLE_DRAWER = 'app/TOGGLE_DRAWER';
export const TOGGLE_ERROR = 'app/TOGGLE_ERROR';
export const TOGGLE_SUCCESS = 'app/TOGGLE_SUCCESS';
export const SET_ERROR = 'app/SET_ERROR';
export const SET_SUCCESS = 'app/SET_SUCCESS';
export const SET_LOADER = 'app/SET_LOADER';
export const MATCH_VEHICLES = 'app/MATCH_VEHICLES';
export const MATCH_VEHICLES_ERROR = 'app/MATCH_VEHICLES_ERROR';
export const MATCH_VEHICLES_SUCCESS = 'app/MATCH_VEHICLES_SUCCESS';
export const OPEN_SEARCH = 'app/OPEN_SEARCH';
export const CLOSE_SEARCH = 'app/CLOSE_SEARCH';
export const OPEN_NOTIFICATION = 'app/OPEN_NOTIFICATION';
export const CLEAR_NOTIFICATION = 'app/CLEAR_NOTIFICATION';
export const GET_NOTIFICATION = 'app/GET_NOTIFICATION';
export const GET_NOTIFICATION_SUCCESS = 'app/GET_NOTIFICATION_SUCCESS';
export const GET_NOTIFICATION_ERROR = 'app/GET_NOTIFICATION_ERROR';
export const UPDATE_NOTIFICATION = 'app/READ_NOTIFICATION';
export const UPDATE_NOTIFICATION_ERROR = 'app/READ_NOTIFICATION_ERROR';
export const DELETED_NOTIFICATION = 'app/DELETED_NOTIFICATION';

export const SET_HOLDING = 'app/SET_HOLDING';
export const SET_HOLDING_SUCCESS = 'app/SET_HOLDING_SUCCESS';
export const ADD_HOLDING_SUCCESS = 'app/ADD_HOLDING_SUCCESS';
export const UPDATE_HOLDING_SUCCESS = 'app/UPDATE_HOLDING_SUCCESS';
export const UPDATE_SOCIAL_REASON_SUCCESS = 'app/UPDATE_SOCIAL_REASON_SUCCESS';
export const SET_SOCIAL_REASON = 'app/SET_SOCIAL_REASON';
export const SET_SOCIAL_REASON_SUCCESS = 'app/SET_SOCIAL_REASON_SUCCESS';
export const OPEN_MODAL = 'app/OPEN_MODAL';
export const CLOSE_MODAL = 'app/CLOSE_MODAL';
export const GET_INITIAL_HOLDING = 'app/GET_INITIAL_HOLDING';
export const GET_INITIAL_HOLDING_SUCCESS = 'app/GET_INITIAL_HOLDING_SUCCESS';
export const GET_INITIAL_HOLDING_ERROR = 'app/GET_INITIAL_HOLDING_ERROR';
export const GET_EXECUTIVE = 'app/GET_EXECUTIVE';
export const GET_EXECUTIVE_SUCCESS = 'app/GET_EXECUTIVE_SUCCESS';
export const GET_EXECUTIVE_ERROR = 'app/GET_EXECUTIVE_ERROR';
export const RESET_OBJECT = 'app/RESET_OBJECT';
export const GET_USER_REPORTS = 'app/GET_USER_REPORTS';
export const GET_USER_REPORTS_SUCCESS = 'app/GET_USER_REPORTS_SUCCESS';

export const appActions = {
  // api fetch
  // logOut: createAction(LOG_OUT),
  getUser: createAction(GET_USER),
  setUser: createAction(GET_USER_SUCCESS, 'payload'),
  getUserReports: createAction(GET_USER_REPORTS),
  getUsers: createAction(GET_USERS, 'payload'),
  // search vehicles
  matchVehicles: createAction(MATCH_VEHICLES, 'payload'),
  openSearch: createAction(OPEN_SEARCH),
  closeSearch: createAction(CLOSE_SEARCH),
  // notifications
  getNotification: createAction(GET_NOTIFICATION),
  updateNotification: createAction(UPDATE_NOTIFICATION, 'payload'),
  deletedNotification: createAction(DELETED_NOTIFICATION),
  // others
  toggleError: createAction(TOGGLE_ERROR),
  toggleSuccess: createAction(TOGGLE_SUCCESS),
  toggleDrawer: createAction(TOGGLE_DRAWER),
  clearDrawer: createAction(CLEAR_DRAWER),
  openNotification: createAction(OPEN_NOTIFICATION),
  clearNotification: createAction(CLEAR_NOTIFICATION),
  setError: createAction(SET_ERROR, 'msg'),
  setSuccess: createAction(SET_SUCCESS, 'msg'),
  setLoader: createAction(SET_LOADER, 'id', 'state'),
  setHolding: createAction(SET_HOLDING, 'payload'),
  setSocialReason: createAction(SET_SOCIAL_REASON, 'payload'),
  // Modals
  openModal: createAction(OPEN_MODAL, 'payload'),
  closeModal: createAction(CLOSE_MODAL, 'payload'),
  // Get initial holding
  getInitialHolding: createAction(GET_INITIAL_HOLDING),
  // Get executive
  getExecutive: createAction(GET_EXECUTIVE),
  resetObject: createAction(RESET_OBJECT, 'payload'),

  updateObjects: createAction(GET_STATE_REPLACE_FROM_API_SUCCESS, 'payload'),
};

const initialState = {
  drawerIsOpen: false,
  isOpenNotification: false,
  loadingUser: true,
  loadingUsers: false,
  errorMsg: '',
  error: false,
  successMsg: '',
  success: false,
  allUsers: [],
  objects: {
    clients: {},
    holdings: {},
    users: {},
    socialReasons: {},
    fleets: {},
    contracts: {},
    tariffPackages: {},
    vehicles: {},
    notifications: {},
    services: {},
    workOrders: {},
    paymentStatus: {},
    paymentStatusFleets: {},
    leases: {},
    leaseDetails: {},
    additionals: {},
    extras: {},
  },
  order: {
    services: [],
    vehicles: [],
  },
  holding: {
    id: null,
    name: null,
  },
  socialReason: [],
  executive: {},
  modals: {
    openHolding: false,
  },
  loadingReports: true,
  user: null,
  loaders: {},
  dataVehicles: [],
  loadingSearch: false,
  search: false,
};

const mergeObjects = (state, action) => {
  const newObjects = {};
  Object.keys({ ...state.objects, ...action.payload.objects }).forEach((key) => {
    newObjects[key] = {
      ...state.objects[key],
      ...(action.payload.objects[key] || {}),
    };
  });

  return {
    ...state,
    objects: newObjects,
  };
};

const replaceObjects = (state, action) => {
  const newObjects = { ...state.objects };
  Object.keys(action.payload.objects).forEach((key) => {
    newObjects[key] = {
      ...(action.payload.objects[key] || {}),
    };
  });

  const newState = {
    ...state,
    objects: newObjects,
  };

  if (action.payload.order) {
    newState.order = { ...state.order, ...action.payload.order };
  }
  return newState;
};

const app = createReducer(initialState, {
  [GET_STATE_FROM_API_SUCCESS]: mergeObjects,
  [GET_STATE_REPLACE_FROM_API_SUCCESS]: replaceObjects,
  [LOGIN.LOGIN_USER_SUCCESS]: (state, action) => ({
    ...state,
    user: action.payload,
    loadingUser: false,
  }),
  [GET_USER_SUCCESS]: (state, action) => ({
    ...state,
    user: action.payload,
    loadingUser: false,
  }),
  [GET_USER]: (state) => ({
    ...state,
    loadingUser: true,
  }),
  [GET_USER_FAIL]: (state) => ({
    ...state,
    user: null,
    loadingUser: false,
  }),
  [GET_USERS]: (state) => ({
    ...state,
    loadingUsers: true,
  }),
  [GET_USERS_SUCCESS]: (state, action) => ({
    ...state,
    objects: {
      ...state.objects,
      users: action.payload,
    },
    allUsers: action.payload,
    loadingUsers: false,
  }),
  [GET_USERS_FAIL]: (state) => ({
    ...state,
    loadingUsers: false,
  }),
  [REPORTS.SET_ACTIVE_REPORT_USERS]: (state, action) => {
    let users;
    if (action.payload.granted) {
      users = state.objects.users.map((usr) => ({
        ...usr,
        reports: [...usr.reports, { id: action.payload.reportId }],
      }));
    } else {
      users = state.objects.users.map((usr) => ({
        ...usr,
        reports: usr.reports.filter((report) => report.id !== action.payload.reportId),
      }));
    }

    return {
      ...state,
      objects: {
        ...state.objects,
        users,
      },
    };
  },
  [REPORTS.SET_ACTIVE_REPORT]: (state, action) => {
    if (action.payload.granted) {
      const userUpdated = state.objects.users.find((usr) => usr.id === action.payload.userId);
      if (!userUpdated) return;
      userUpdated.reports = [...userUpdated.reports, { id: action.payload.reportId }];
    } else {
      const userUpdated = state.objects.users.find((usr) => usr.id === action.payload.userId);
      if (!userUpdated) return;
      userUpdated.reports = userUpdated.reports
        .filter((report) => report.id !== action.payload.reportId);
    }
  },
  [REPORTS.USERS_SET_SEARCH]: (state, action) => ({
    ...state,
    objects: {
      ...state.objects,
      users: state.allUsers.filter(
        (user) => user.name.toLowerCase().includes(action.payload.toLowerCase()),
      ),
    },
  }),
  [GET_USER_REPORTS]: (state) => ({
    ...state,
    loadingUserReports: true,
  }),
  [GET_USER_REPORTS_SUCCESS]: (state, action) => ({
    ...state,
    loadingUserReports: false,
    user: {
      ...state.user,
      reports: action.payload,
      selectedReport: action.payload[0],
    },
  }),
  [OPEN_NOTIFICATION]: (state) => ({
    ...state,
    isOpenNotification: true,
  }),
  [CLEAR_NOTIFICATION]: (state) => ({
    ...state,
    isOpenNotification: false,
  }),
  [TOGGLE_DRAWER]: (state) => ({
    ...state,
    drawerIsOpen: !state.drawerIsOpen,
  }),
  [CLEAR_DRAWER]: (state) => ({
    ...state,
    drawerIsOpen: false,
  }),
  [TOGGLE_ERROR]: (state) => ({
    ...state,
    error: false,
    errorMsg: !state.errorMsg,
  }),
  [SET_ERROR]: (state, action) => ({
    ...state,
    error: true,
    errorMsg: action.payload.msg,
  }),
  [TOGGLE_SUCCESS]: (state) => ({
    ...state,
    success: false,
    successMsg: !state.successMsg,
  }),
  [SET_SUCCESS]: (state, action) => ({
    ...state,
    success: true,
    successMsg: action.payload.msg,
  }),
  [SET_LOADER]: (state, action) => ({
    ...state,
    loaders: {
      ...state.loaders,
      [action.id]: action.state,
    },
  }),
  [SET_HOLDING_SUCCESS]: (state, action) => ({
    ...state,
    holding: action.payload,
    socialReason: [],
  }),
  [ADD_HOLDING_SUCCESS]: (state, action) => ({
    ...state,
    objects: {
      ...state.objects,
      holdings: {
        ...state.objects.holdings,
        [action.payload.id]: {
          count: action.payload.count ? action.payload.count : 0,
          ...action.payload,
        },
      },
    },
  }),
  [UPDATE_HOLDING_SUCCESS]: (state, action) => ({
    ...state,
    objects: {
      ...state.objects,
      holdings: {
        ...state.objects.holdings,
        [action.payload.id]: {
          ...state.objects.holdings[action.payload.id],
          ...action.payload,
        },
      },
    },
  }),
  [UPDATE_SOCIAL_REASON_SUCCESS]: (state, action) => ({
    ...state,
    objects: {
      ...state.objects,
      socialReasons: {
        ...state.objects.socialReasons,
        [action.payload.id]: {
          ...state.objects.socialReasons[action.payload.id],
          ...action.payload,
        },
      },
    },
  }),
  [SET_SOCIAL_REASON_SUCCESS]: (state, action) => ({
    ...state,
    socialReason: action.payload,
  }),
  [MATCH_VEHICLES]: (state) => ({
    ...state,
    loadingSearch: true,
  }),
  [MATCH_VEHICLES_SUCCESS]: (state, action) => ({
    ...state,
    dataVehicles: action.payload,
    loadingSearch: false,
  }),
  [OPEN_SEARCH]: (state) => ({
    ...state,
    search: true,
  }),
  [CLOSE_SEARCH]: (state) => ({
    ...state,
    search: false,
  }),
  [OPEN_MODAL]: (state, action) => ({
    ...state,
    modals: {
      ...state.modals,
      [action.payload]: true,
    },
  }),
  [CLOSE_MODAL]: (state, action) => ({
    ...state,
    modals: {
      ...state.modals,
      [action.payload]: false,
    },
  }),
  [GET_INITIAL_HOLDING_SUCCESS]: (state, action) => ({
    ...state,
    holding: action.payload.holding,
  }),
  [GET_EXECUTIVE_SUCCESS]: (state, action) => ({
    ...state,
    executive: action.payload.executive,
  }),
  [RESET_OBJECT]: (state, action) => ({
    ...state,
    objects: {
      ...state.objects,
      [action.payload]: initialState.objects[action.payload],
    },
  }),
  [NAVBAR.CLEAN_NOTIFICATIONS_SUCCESS]: (state) => ({
    ...state,
    objects: {
      ...state.objects,
      notifications: {},
    },
  }),
});

export default app;
