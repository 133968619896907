import {
  takeLatest, spawn, put, select,
} from 'redux-saga/effects';
import {
  apiSuccess, get, post, update,
} from 'utils/api';

import { VEHICLE_DETAIL } from '../reducer';
import * as APP from '../../../commons/reducer';

const getHolding = (state) => state.app.holding;
const getVehicle = (state) => state.vehicleDetail.vehicle;

function* fetchVehicleDetail() {
  yield takeLatest(VEHICLE_DETAIL.FETCH_VEHICLE_DETAIL, function* (action) {
    const { licensePlate } = action;
    const pathname = licensePlate.slice(10);
    const response = yield get(`api/v1/vehicles/detail/${pathname}`);

    if (!response.error) {
      yield put(apiSuccess(VEHICLE_DETAIL.FETCH_VEHICLE_DETAIL_SUCCESS, response));
    } else {
      yield put(apiSuccess(VEHICLE_DETAIL.FETCH_VEHICLE_DETAIL_ERROR));
      yield put(apiSuccess(APP.SET_ERROR, {
        msg: response.statusCode === 403 ? 'Acceso denegado' : 'Error al obtener los datos',
      }));
    }
  });
}

function* goToServices() {
  yield takeLatest(VEHICLE_DETAIL.GO_TO_SERVICES, function* (action) {
    const { id } = yield select(getHolding);
    const vehicle = yield select(getVehicle);
    const holdingId = vehicle['ContractVehicle.SocialReason.Holding.id'];
    const { history } = action.payload;

    if (id !== holdingId) {
      const holdingName = vehicle['ContractVehicle.SocialReason.Holding.name'];
      yield put(apiSuccess(APP.SET_HOLDING, { id: holdingId, name: holdingName }));
      yield put(apiSuccess(APP.SET_SUCCESS, { msg: `Holding cambiado a ${holdingName}` }));
    }
    const { id: currentId } = yield select(getHolding);
    const { id: vehicleId } = vehicle;
    history.push(`/services?vehicle=${vehicleId}&h=${currentId}`);
  });
}

function* fetchDrivers() {
  yield takeLatest(VEHICLE_DETAIL.FETCH_DRIVER, function* (action) {
    const socialReasonId = action.payload;

    const response = yield get(`api/v1/driver?socialReason=${socialReasonId}`);

    if (!response.error) {
      yield put(apiSuccess(VEHICLE_DETAIL.FETCH_DRIVER_SUCCESS, response));
    } else {
      yield put(apiSuccess(VEHICLE_DETAIL.FETCH_DRIVER_ERROR));
      yield put(apiSuccess(APP.SET_ERROR, { msg: response.errorMsg }));
    }
  });
}

function* assignDriver() {
  yield takeLatest(VEHICLE_DETAIL.ASSIGN_DRIVER, function* (action) {
    const body = action.payload;
    const response = yield update('api/v1/driver/assign', body);
    if (!response.error) {
      yield put(apiSuccess(VEHICLE_DETAIL.ASSIGN_DRIVER_SUCCESS));
      yield put(apiSuccess(APP.SET_SUCCESS, { msg: 'Conductor asignado exitosamente.' }));
    } else {
      yield put(apiSuccess(VEHICLE_DETAIL.ASSIGN_DRIVER_ERROR));
      yield put(apiSuccess(APP.SET_ERROR, { msg: response.errorMsg }));
    }
  });
}

function* createRequest() {
  yield takeLatest(VEHICLE_DETAIL.CREATE_REQUEST, function* (action) {
    const body = { ...action.payload };
    body.run = body.run.replace(/[.]/g, '');

    const response = yield post('api/v1/request/driver', body);
    if (!response.error) {
      yield put(apiSuccess(VEHICLE_DETAIL.CREATE_REQUEST_SUCCESS));
      yield put(apiSuccess(APP.SET_SUCCESS, { msg: response.msg }));
    } else {
      yield put(apiSuccess(VEHICLE_DETAIL.CREATE_REQUEST_ERROR));
      yield put(apiSuccess(APP.SET_ERROR, { msg: response.errorMsg }));
    }
  });
}

function* setShared() {
  yield takeLatest(VEHICLE_DETAIL.SET_SHARED, function* (action) {
    const body = action.payload;

    const response = yield post('api/v1/vehicles/shared', body);
    if (!response.error) {
      yield put(apiSuccess(VEHICLE_DETAIL.SET_SHARED_SUCCESS, response));
      yield put(apiSuccess(APP.SET_SUCCESS, { msg: 'La asignación fue cambiada exitosamente' }));
    } else {
      yield put(apiSuccess(VEHICLE_DETAIL.SET_SHARED_ERROR));
      yield put(apiSuccess(APP.SET_ERROR, { msg: 'Error al cambiar la asignación' }));
    }
  });
}

function* fetchDocuments() {
  yield takeLatest(VEHICLE_DETAIL.FETCH_DOCUMENTS, function* (action) {
    const { payload } = action;
    const pathname = payload.slice(10);

    const response = yield get(`api/v1/document/${pathname}`);

    if (!response.error) {
      yield put(apiSuccess(VEHICLE_DETAIL.FETCH_DOCUMENTS_SUCCESS, response));
    } else {
      yield put(apiSuccess(VEHICLE_DETAIL.FETCH_DOCUMENTS_ERROR));
      yield put(apiSuccess(APP.SET_ERROR, {
        msg: response.statusCode === 403 ? 'Acceso denegado' : 'Error al obtener los datos',
      }));
    }
  });
}

function* fetchDocumentUrl() {
  yield takeLatest(VEHICLE_DETAIL.FETCH_DOCUMENT_URL, function* (action) {
    const { documentId } = action.payload;

    const response = yield get(`api/v1/document/url/${documentId}`);
    if (response) {
      yield put(apiSuccess(VEHICLE_DETAIL.FETCH_DOCUMENT_URL_SUCCESS, response.url));
    } else {
      yield put(apiSuccess(VEHICLE_DETAIL.FETCH_DOCUMENT_URL_ERROR));
      yield put(apiSuccess(APP.SET_ERROR, {
        msg: response.statusCode === 403 ? 'Acceso denegado' : 'Error al obtener los datos',
      }));
    }
  });
}

export default function* root() {
  yield spawn(fetchVehicleDetail);
  yield spawn(goToServices);
  yield spawn(fetchDrivers);
  yield spawn(assignDriver);
  yield spawn(createRequest);
  yield spawn(setShared);
  yield spawn(fetchDocuments);
  yield spawn(fetchDocumentUrl);
}
