import { alpha } from '@material-ui/core/styles/colorManipulator';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(1),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    boxShadow: theme.shadows[4],
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
    },
  },
  nameContainer: {
    marginLeft: theme.spacing(2),
    width: 230,
  },
  optionIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  itemIcon: {
    color: theme.palette.primary.main,
  },
  statusIcon: {
    fontSize: 20,
  },
  statusMobileIcon: {
    fontSize: 20,
  },
  mobileIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
    fontSize: 24,
  },
  HABILITADO: { color: 'green' },
  DESABILITADO: { color: 'red' },
  iconSquare: {
    display: 'flex',
    justifyContent: 'end',
    color: theme.palette.primary.main,
  },
  back1: {
    backgroundColor: alpha(theme.palette.primary.main, 0.03),
  },
  back2: {
    backgroundColor: alpha(theme.palette.primary.main, 0.08),
  },
  content: {
    overflowX: 'auto',
  },
  nameStatus: {
    paddingRight: 8,
  },
  name: {
    width: 230,
  },
  boxName: {
    overflowX: 'auto',
    overflowY: 'hidden',
  },
  iconButton: {
    padding: 0,
  },
});

export default styles;
