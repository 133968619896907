const styles = () => ({
  papper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    p: 4,
    width: '35%',
    height: 'auto',
    overflow: 'hidden',
  },
  header: {
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '8px',
  },
  subTitle: {
    justifyContent: 'flex-start',
    paddingLeft: '16px',
  },
  body: {
    marginTop: '16px',
  },
  rowContainer: {
    justifyContent: 'space-between',
    marginTop: '8px',
  },
  download: {
    justifyContent: 'flex-end',
    cursor: 'pointer',
  },
  buttonContainer: {
    justifyContent: 'flex-end',
    margin: '16px',
  },
  button: {
    justifyContent: 'flex-end',
  },
  notAvailable: {
    color: '#c4c4c4',
    cursor: 'default',
  },
  skeleton: {
    margin: '16px',
    width: '60%',
  },
});

export default styles;
