import { createAction, createReducer } from 'utils/reducer';

// Local constants
const PATH = 'vehicleDetails/';

// Global constants for saga
export const VEHICLE_DETAIL = {
  FETCH_VEHICLE_DETAIL: `${PATH}FETCH_VEHICLE_DETAIL`,
  FETCH_VEHICLE_DETAIL_SUCCESS: `${PATH}FETCH_VEHICLE_DETAIL_SUCCESS`,
  FETCH_VEHICLE_DETAIL_ERROR: `${PATH}FETCH_VEHICLE_DETAIL_ERROR`,
  GO_TO_SERVICES: `${PATH}GO_TO_SERVICES`,
  RESET_CONTROLS: `${PATH}RESET_CONTROLS`,
  OPEN_MODAL: `${PATH}OPEN_MODAL`,
  CLOSE_MODAL: `${PATH}CLOSE_MODAL`,
  FETCH_DRIVER: `${PATH}FETCH_DRIVER`,
  FETCH_DRIVER_ERROR: `${PATH}FETCH_DRIVER_ERROR`,
  FETCH_DRIVER_SUCCESS: `${PATH}FETCH_DRIVER_SUCCESS`,
  SELECT_DRIVER: `${PATH}SELECT_DRIVER`,
  ASSIGN_DRIVER: `${PATH}ASSIGN_DRIVER`,
  ASSIGN_DRIVER_ERROR: `${PATH}ASSIGN_DRIVER_ERROR`,
  ASSIGN_DRIVER_SUCCESS: `${PATH}ASSIGN_DRIVER_SUCCESS`,
  CHANGE_DRIVER: `${PATH}CHANGE_DRIVER`,
  SET_IS_CHANGE: `${PATH}SET_IS_CHANGE`,
  CHANGE_CONTROLS_ASSIGN: `${PATH}CHANGE_CONTROLS_ASSIGN`,
  CREATE_REQUEST: `${PATH}CREATE_REQUEST`,
  CREATE_REQUEST_SUCCESS: `${PATH}CREATE_REQUEST_SUCCESS`,
  CREATE_REQUEST_ERROR: `${PATH}CREATE_REQUEST_ERROR`,
  VALIDATE_DRIVER_RUN: `${PATH}VALIDATE_DRIVER_RUN`,
  SET_SHARED: `${PATH}SET_SHARED`,
  SET_SHARED_SUCCESS: `${PATH}SET_SHARED_SUCCESS`,
  SET_SHARED_ERROR: `${PATH}SET_SHARED_ERROR`,
  FETCH_DOCUMENTS: `${PATH}FETCH_DOCUMENTS`,
  FETCH_DOCUMENTS_SUCCESS: `${PATH}FETCH_DOCUMENTS_SUCCESS`,
  FETCH_DOCUMENTS_ERROR: `${PATH}FETCH_DOCUMENTS_ERROR`,
  FETCH_DOCUMENT_URL: `${PATH}FETCH_DOCUMENT_URL`,
  FETCH_DOCUMENT_URL_SUCCESS: `${PATH}FETCH_DOCUMENT_URL_SUCCESS`,
  FETCH_DOCUMENT_URL_ERROR: `${PATH}FETCH_DOCUMENT_URL_ERROR`,
};

// actions
export const vehicleDetailActions = {
  getVehicleDetail: createAction(VEHICLE_DETAIL.FETCH_VEHICLE_DETAIL, 'licensePlate'),
  resetControls: createAction(VEHICLE_DETAIL.RESET_CONTROLS),
  goToServices: createAction(VEHICLE_DETAIL.GO_TO_SERVICES, 'payload'),
  openModal: createAction(VEHICLE_DETAIL.OPEN_MODAL),
  closeModal: createAction(VEHICLE_DETAIL.CLOSE_MODAL),
  fetchDrivers: createAction(VEHICLE_DETAIL.FETCH_DRIVER, 'payload'),
  selectDriver: createAction(VEHICLE_DETAIL.SELECT_DRIVER, 'payload'),
  assignDriver: createAction(VEHICLE_DETAIL.ASSIGN_DRIVER, 'payload'),
  changeDriver: createAction(VEHICLE_DETAIL.CHANGE_DRIVER, 'payload'),
  setIsChange: createAction(VEHICLE_DETAIL.SET_IS_CHANGE, 'payload'),
  changeControls: createAction(VEHICLE_DETAIL.CHANGE_CONTROLS_ASSIGN, 'payload'),
  createRequest: createAction(VEHICLE_DETAIL.CREATE_REQUEST, 'payload'),
  validateDriverRun: createAction(VEHICLE_DETAIL.VALIDATE_DRIVER_RUN),
  setShared: createAction(VEHICLE_DETAIL.SET_SHARED, 'payload'),
  getDocuments: createAction(VEHICLE_DETAIL.FETCH_DOCUMENTS, 'payload'),
  getDocumentUrl: createAction(VEHICLE_DETAIL.FETCH_DOCUMENT_URL, 'payload'),
};

const initialState = {
  vehicle: {},
  controls: {
    holding: [],
  },
  formControlsChange: {
    vehicle: null,
    driverRun: null,
    driverName: null,
  },
  formControlsRequest: {
    run: '',
    name: '',
    email: '',
    phone: '',
    socialReason: null,
  },
  orderBy: '',
  loading: false,
  error: false,
  message: '',
  isOpen: false,
  drivers: [],
  loadingDrivers: false,
  errorDrivers: false,
  loadingAssign: false,
  errorAssign: false,
  successAssign: false,
  isChange: true,
  driverExist: false,
  loadingRequest: false,
  successRequest: false,
  isShared: false,
  loadingShared: false,
  documents: [],
  loadingDocuments: false,
  errorDocuments: false,
  documentUrl: '',
  loadingDocumentUrl: false,
  errorDocumentUrl: false,
};

const vehicleDetail = createReducer(initialState, {
  [VEHICLE_DETAIL.CHANGE_CONTROLS_ASSIGN]: (state, action) => ({
    ...state,
    formControlsRequest: {
      ...state.formControlsRequest,
      ...action.payload,
    },
  }),
  [VEHICLE_DETAIL.FETCH_VEHICLE_DETAIL]: (state) => ({
    ...state,
    loading: true,
  }),
  [VEHICLE_DETAIL.FETCH_VEHICLE_DETAIL_SUCCESS]: (state, action) => ({
    ...state,
    vehicle: action.payload,
    isShared: action.payload.isShared,
    loading: false,
  }),
  [VEHICLE_DETAIL.FETCH_VEHICLE_DETAIL_ERROR]: (state) => ({
    ...state,
    loading: false,
    error: true,
  }),
  [VEHICLE_DETAIL.RESET_CONTROLS]: (state) => ({
    ...state,
    controls: initialState.controls,
    formControlsChange: initialState.formControlsChange,
    formControlsRequest: initialState.formControlsRequest,
    loading: false,
    error: false,
    loadingDrivers: false,
    loadingAssign: false,
    errorAssign: false,
    successAssign: false,
    isChange: true,
    driverExist: false,
    loadingRequest: false,
    successRequest: false,
  }),
  [VEHICLE_DETAIL.GO_TO_SERVICES]: (state) => ({
    ...state,
  }),
  [VEHICLE_DETAIL.OPEN_MODAL]: (state) => ({
    ...state,
    isOpen: true,
  }),
  [VEHICLE_DETAIL.CLOSE_MODAL]: (state) => ({
    ...state,
    isOpen: false,
    isChange: true,
  }),
  [VEHICLE_DETAIL.FETCH_DRIVER]: (state) => ({
    ...state,
    loadingDrivers: true,
    errorDrivers: false,
  }),
  [VEHICLE_DETAIL.FETCH_DRIVER_SUCCESS]: (state, action) => ({
    ...state,
    drivers: action.payload,
    loadingDrivers: false,
    errorDrivers: false,
  }),
  [VEHICLE_DETAIL.FETCH_DRIVER_ERROR]: (state) => ({
    ...state,
    loadingDrivers: false,
    errorDrivers: true,
  }),
  [VEHICLE_DETAIL.SELECT_DRIVER]: (state, action) => ({
    ...state,
    formControlsChange: {
      ...state.formControlsChange,
      ...action.payload,
    },
  }),

  [VEHICLE_DETAIL.ASSIGN_DRIVER]: (state) => ({
    ...state,
    loadingAssign: true,
    errorAssign: false,
  }),
  [VEHICLE_DETAIL.ASSIGN_DRIVER_SUCCESS]: (state) => ({
    ...state,
    successAssign: true,
    isOpen: false,
    loadingAssign: false,
    errorAssign: false,
  }),
  [VEHICLE_DETAIL.ASSIGN_DRIVER_ERROR]: (state) => ({
    ...state,
    loadingAssign: false,
    errorAssign: true,
  }),
  [VEHICLE_DETAIL.CREATE_REQUEST]: (state) => ({
    ...state,
    loadingRequest: true,
  }),
  [VEHICLE_DETAIL.CREATE_REQUEST_SUCCESS]: (state) => ({
    ...state,
    loadingRequest: false,
    successRequest: true,
  }),
  [VEHICLE_DETAIL.CREATE_REQUEST_ERROR]: (state) => ({
    ...state,
    loadingRequest: false,
  }),
  [VEHICLE_DETAIL.CHANGE_DRIVER]: (state, action) => ({
    ...state,
    vehicle: {
      ...state.vehicle,
      ...action.payload,
    },
  }),
  [VEHICLE_DETAIL.SET_IS_CHANGE]: (state, action) => ({
    ...state,
    isChange: action.payload,
  }),
  [VEHICLE_DETAIL.VALIDATE_DRIVER_RUN]: (state) => ({
    ...state,
    isChange: true,
    driverExist: true,
  }),
  [VEHICLE_DETAIL.SET_SHARED]: (state) => ({
    ...state,
    loadingShared: true,
  }),
  [VEHICLE_DETAIL.SET_SHARED_SUCCESS]: (state, action) => ({
    ...state,
    isShared: action.payload.shared,
    loadingShared: false,
  }),
  [VEHICLE_DETAIL.SET_SHARED_ERROR]: (state) => ({
    ...state,
    loadingShared: false,
  }),
  [VEHICLE_DETAIL.FETCH_DOCUMENTS]: (state) => ({
    ...state,
    loadingDocuments: true,
  }),
  [VEHICLE_DETAIL.FETCH_DOCUMENTS_SUCCESS]: (state, action) => ({
    ...state,
    documents: action.payload,
    loadingDocuments: false,
  }),
  [VEHICLE_DETAIL.FETCH_DOCUMENTS_ERROR]: (state) => ({
    ...state,
    loadingDocuments: false,
    errorDocuments: true,
  }),
  [VEHICLE_DETAIL.FETCH_DOCUMENT_URL]: (state) => ({
    ...state,
    loadingDocumentUrl: true,
  }),
  [VEHICLE_DETAIL.FETCH_DOCUMENT_URL_SUCCESS]: (state, action) => ({
    ...state,
    documentUrl: action.payload,
    loadingDocumentUrl: false,
  }),
  [VEHICLE_DETAIL.FETCH_DOCUMENT_URL_ERROR]: (state) => ({
    ...state,
    loadingDocumentUrl: false,
    errorDocumentUrl: true,
  }),
});

export default vehicleDetail;
