import { alpha } from '@material-ui/core/styles';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'column',
      height: '100%',
    },
  },
  leftIcon: {
    backgroundColor: alpha(theme.palette.secondary.main, 0.9),
    height: 56,
    width: 56,
    boxShadow: theme.shadows[3],
    transform: 'rotate(90deg)',
    marginRight: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(4),
      marginRight: theme.spacing(0),
      transform: 'rotate(0deg)',
    },
    '&:hover': {
      backgroundColor: alpha(theme.palette.secondary.main, 0.7),
      cursor: 'pointer',
    },
  },
  rightIcon: {
    backgroundColor: alpha(theme.palette.secondary.main, 0.9),
    height: 56,
    width: 56,
    boxShadow: theme.shadows[3],
    transform: 'rotate(90deg)',
    [theme.breakpoints.up('sm')]: {
      transform: 'rotate(0deg)',
    },
    '&:hover': {
      backgroundColor: alpha(theme.palette.secondary.main, 0.7),
      cursor: 'pointer',
    },
  },
});

export default styles;
