import {
  Checkbox, Chip, Grid, TextField, makeStyles,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import React from 'react';
import styles from './styles';
import MultipleFilter from '.';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles(styles);

function SocialReasonsFilter({
  limitTags = 1, value, options, onChange, filterField = false, ...props
}) {
  const classes = useStyles();

  const handleFilterFieldChange = (data) => {
    const filterValue = options.filter((item) => data.value.includes(item.value));
    onChange({ value: filterValue });
  };

  const handleChange = (e, data) => {
    if (data) {
      onChange({ value: data });
    }
  };

  if (filterField) {
    return (
      <Grid item {...props}>
        <MultipleFilter
          limitTags={limitTags}
          id="socialReasons"
          label="Razón social"
          options={options}
          value={value.map((item) => item.value)}
          onChange={handleFilterFieldChange}
        />
      </Grid>
    );
  }

  return (
    <Grid item xs={12} {...props}>
      <Autocomplete
        limitTags={limitTags}
        multiple
        id="socialReason"
        name="socialReason"
        value={value}
        options={options}
        onChange={handleChange}
        disableCloseOnSelect
        getOptionSelected={(option, v) => option.value === v.value}
        getOptionLabel={(option) => option.label}
        renderTags={(v, getTagProps) => v.map((option, index) => (
          <Chip color="primary" variant="default" label={option.label} {...getTagProps({ index })} />
        ))}
        renderOption={(option, { selected }) => (
          <>
            <Checkbox
              color="primary"
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.label}
          </>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            variant="outlined"
            label="Razón social"
            className={classes.borderColor}
          />
        )}
      />
    </Grid>
  );
}

export default SocialReasonsFilter;
