import { takeLatest, spawn, put } from 'redux-saga/effects';

import apiRequest, { apiSuccess } from '../../../utils/api';
import * as APP from '../../../commons/reducer';
import { LOGIN } from '../reducer';

function* login() {
  yield takeLatest(LOGIN.LOGIN_USER, function* loginUserApi(action) {
    const body = JSON.stringify(action.controls);
    const response = yield apiRequest('api/v1/auth/login', { method: 'post', body }, true, true);

    if (!response.error) {
      localStorage.setItem('user', JSON.stringify({ token: response.token }));
      yield put(apiSuccess(LOGIN.LOGIN_USER_SUCCESS, response));
    } else {
      yield put(apiSuccess(LOGIN.LOGIN_USER_FAIL, response));
      yield put(apiSuccess(APP.SET_ERROR, { msg: 'Falló en el inicio de sesión' }));
    }
  });
}

export default function* root() {
  yield spawn(login);
}
