import { createAction, createReducer } from 'utils/reducer';
import * as APP from 'commons/reducer';
import { LOGIN } from 'screens/Login/reducer';

// Local constants
const PATH = 'user/';

// Global constants for saga
export const USER = {
  FETCH_INITIAL_DATA: `${PATH}FETCH_DATA`,
  FETCH_INITIAL_DATA_SUCCESS: `${PATH}FETCH_DATA_SUCCESS`,
  CHANGE_CONTROLS: `${PATH}CHANGE_CONTROLS`,
  CLEAR_CONTROLS: `${PATH}CLEAR_CONTROLS`,
  CLEAR_CONTROLS_SUCCESS: `${PATH}CLEAR_CONTROLS_SUCCESS`,
  CHANGE_PAGE: `${PATH}CHANGE_PAGE`,
  CHANGE_PAGE_SUCCESS: `${PATH}CHANGE_PAGE_SUCCESS`,
  FETCH_DATA_WITH_FILTER: `${PATH}FETCH_DATA_WITH_FILTER`,
  FETCH_DATA_WITH_FILTER_SUCCESS: `${PATH}FETCH_DATA_WITH_FILTER_SUCCESS`,
  CHANGE_STATUS: `${PATH}CHANGE_STATUS`,
  CHANGE_STATUS_SUCCESS: `${PATH}CHANGE_STATUS_SUCCESS`,
  GET_USER_HOLDING: `${PATH}GET_USER_HOLDING`,
  GET_USER_HOLDING_SUCCESS: `${PATH}GET_USER_HOLDING_SUCCESS`,
  CLOSE_HOLDING_DIALOG: `${PATH}CLOSE_HOLDING_DIALOG`,
  OPEN_SEARCH_USER: `${PATH}OPEN_SEARCH_USER`,
  DOWNLOAD_EXCEL: `${PATH}DOWNLOAD_EXCEL`,
  DOWNLOAD_EXCEL_ERROR: `${PATH}DOWNLOAD_EXCEL_ERROR`,
  DOWNLOAD_EXCEL_SUCCESS: `${PATH}DOWNLOAD_EXCEL_SUCCESS`,
};

// actions
export const userActions = {
  getInitialData: createAction(USER.FETCH_INITIAL_DATA),
  changeControls: createAction(USER.CHANGE_CONTROLS, 'key', 'value'),
  clearControls: createAction(USER.CLEAR_CONTROLS),
  fetchWithFilter: createAction(USER.FETCH_DATA_WITH_FILTER),
  changePage: createAction(USER.CHANGE_PAGE, 'payload'),
  changeStatus: createAction(USER.CHANGE_STATUS, 'payload'),
  getHoldings: createAction(USER.GET_USER_HOLDING, 'payload'),
  closeHoldingDialog: createAction(USER.CLOSE_HOLDING_DIALOG),
  openUserSearch: createAction(USER.OPEN_SEARCH_USER),
  downloadExcel: createAction(USER.DOWNLOAD_EXCEL),
};

const initialState = {
  controls: {
    holdingDialog: false,
    holding: null,
    socialReason: null,
    startDate: null,
    disabledDate: null,
    status: '',
    type: '',
    userName: '',
  },
  query: {
    holding: null,
    socialReason: null,
    startDate: null,
    disabledDate: null,
    status: '',
    type: '',
    userName: '',
  },
  pagination: {
    size: 10,
    page: 1,
    totalPages: 20,
  },
  userHoldings: [],
  roles: {},
  firstFetch: true,
  pageLoading: false,
  loading: false,
  error: false,
  message: '',
  isOpen: false,
  loadingDownload: false,
};

const user = createReducer(initialState, {
  [USER.FETCH_INITIAL_DATA]: (state) => ({
    ...state,
    firstFetch: true,
    loading: true,
  }),
  [USER.FETCH_INITIAL_DATA_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    roles: action.payload.roles,
    pagination: {
      ...state.pagination,
      totalPages: action.payload.totalPages,
    },
    firstFetch: false,
  }),
  [USER.CHANGE_CONTROLS]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      [action.key]: action.value,
    },
  }),
  [USER.FETCH_DATA_WITH_FILTER]: (state) => ({
    ...state,
    pagination: {
      ...initialState.pagination,
      totalPages: state.pagination.totalPages,
    },
    query: state.controls,
    pageLoading: true,
  }),
  [USER.FETCH_DATA_WITH_FILTER_SUCCESS]: (state, action) => ({
    ...state,
    pagination: {
      ...state.pagination,
      totalPages: action.payload.totalPages,
    },
    pageLoading: false,
  }),
  [USER.CLEAR_CONTROLS]: (state) => ({
    ...state,
    controls: {
      ...initialState.controls,
    },
    query: initialState.query,
    pageLoading: true,
    isOpen: false,
  }),
  [USER.CLEAR_CONTROLS_SUCCESS]: (state, action) => ({
    ...state,
    controls: {
      ...initialState.controls,
    },
    pagination: {
      ...initialState.pagination,
      totalPages: action.payload.totalPages,
    },
    pageLoading: false,
  }),
  [USER.CHANGE_PAGE]: (state) => ({
    ...state,
    pageLoading: true,
  }),
  [USER.CHANGE_PAGE_SUCCESS]: (state, action) => ({
    ...state,
    pagination: {
      ...state.pagination,
      totalPages: action.payload.totalPages,
      page: action.payload.page,
    },
    pageLoading: false,
  }),
  [USER.CHANGE_STATUS]: (state) => ({
    ...state,
    pageLoading: true,
  }),
  [USER.CHANGE_STATUS_SUCCESS]: (state) => ({
    ...state,
    pageLoading: false,
  }),
  [USER.GET_USER_HOLDING_SUCCESS]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      holdingDialog: true,
    },
    userHoldings: action.payload,
    loading: false,
  }),
  [USER.CLOSE_HOLDING_DIALOG]: (state) => ({
    ...state,
    controls: {
      ...state.controls,
      holdingDialog: false,
    },
  }),
  [USER.OPEN_SEARCH_USER]: (state) => ({
    ...state,
    isOpen: true,
  }),
  [USER.DOWNLOAD_EXCEL]: (state) => ({
    ...state,
    loadingDownload: true,
  }),
  [USER.DOWNLOAD_EXCEL_SUCCESS]: (state) => ({
    ...state,
    loadingDownload: false,
  }),
  [USER.DOWNLOAD_EXCEL_ERROR]: (state) => ({
    ...state,
    loadingDownload: false,
  }),
  [APP.SET_HOLDING]: (state) => ({
    ...state,
    controls: {
      ...initialState.controls,
    },
    query: {
      ...initialState.query,
    },
    firstFetch: true,
  }),
  [APP.SET_SOCIAL_REASON]: (state) => ({
    ...state,
    controls: {
      ...initialState.controls,
    },
    query: {
      ...initialState.query,
    },
    firstFetch: true,
  }),
  [LOGIN.LOGIN_USER]: (state) => ({
    ...state,
    controls: {
      ...initialState.controls,
    },
    query: {
      ...initialState.query,
    },
    firstFetch: true,
  }),
});

export default user;
