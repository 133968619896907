const styles = (theme) => ({
  notificationIcon: {
    '&:hover': {
      background: 'none',
      color: '#FFF',
    },
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
    marginLeft: theme.spacing(1),
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  cleanNotifications: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  icon: {
    '&:hover': {
      background: 'none',
    },
    transform: 'scale(1.4)',
  },
  title: {
    fontSize: 16,
    color: theme.palette.primary.main,
  // marginLeft: theme.spacing(1),
  },
  subTitle: {
    fontSize: 12,
    color: theme.palette.primary.main,
    // marginLeft: theme.spacing(1),
  },
  typography: {
    padding: theme.spacing(2),
  },
});

export default styles;
