/* eslint-disable no-use-before-define */
import React, { useState } from 'react';
import {
  Box, Grid, Hidden,
} from '@material-ui/core';

import ScreenTitle from 'commons/components/ScreenTitle';
import { appActions } from 'commons/reducer';
import { composedComponent } from 'utils/functions';
import CustomFloatButton from 'commons/components/CustomFloatButton';
import DownloadButton from 'commons/components/DownloadButton';
import HoldingList from './components/HoldingList';
import { holdingsActions } from './reducer';
import saga from './saga';
import Filter from './components/Filter';
import CreateHoldingDialog from './components/CreateHoldingDialog';
import HoldingMaintainerSkeleton from './components/Skeleton';
import useHoldings from './useHoldings';

function HoldingMaintainer({
  objects,
  loading,
  actions,
  pagination,
  controls,
}) {
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const {
    processedHoldings: holdings,
    holdings: allHoldings,
    handleFilter, handleClearFilter, handleCreateHolding,
  } = useHoldings(objects.holdings, controls, actions);

  const closeCreateDialogHandler = () => setIsCreateDialogOpen(false);

  if (loading) {
    return <HoldingMaintainerSkeleton />;
  }

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <ScreenTitle title="HOLDINGS" showSubTitle={false} />
      </Box>
      <Filter
        controls={controls}
        actions={actions}
        holdings={allHoldings}
        handleFilter={handleFilter}
        handleClearFilter={handleClearFilter}
      />
      <Hidden smDown>
        <Grid container justifyContent="flex-end">
          <Grid item xs={12} md={6}>
            <Box
              marginTop={1}
              marginBottom={0.3}
              display="flex"
              justifyContent="end"
            >
              <DownloadButton
                total={2}
                onClick={() => actions.generateReport()}
                loading={false}
                text="Descargar reporte"
              />
            </Box>
          </Grid>
        </Grid>
      </Hidden>
      <HoldingList
        holdings={holdings}
        actions={actions}
        controls={controls}
        isLoading={loading}
        total={holdings ? holdings.length : 0}
        handleDownload={actions.generateReport}
        pagination={pagination}
      />
      <CreateHoldingDialog
        isOpen={isCreateDialogOpen}
        onClose={closeCreateDialogHandler}
        onCreate={handleCreateHolding}
      />
      <CustomFloatButton
        handleClick={() => setIsCreateDialogOpen(true)}
      />
    </>
  );
}

export default composedComponent(HoldingMaintainer, saga, {
  saga: 'sagaHolding',
  states: [
    'app.objects',
    'navbar.loading',
    'holdingMaintainer.pagination',
    'holdingMaintainer.controls',
  ],
  actions: [holdingsActions, appActions],
});
