// @ts-nocheck
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
// Project
import { selectState } from 'utils/reduxActions';
import { ReactReduxContext } from 'config/configureStore';
// material
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

const ButtonWithLoader = ({
  loaders,
  buttonId,
  color,
  onClick,
  disabled,
  startIcon,
  autoFocus,
  fullWidth,
  className,
  size,
  children,
  variant = 'contained',
}) => {
  const loading = loaders[buttonId];

  return (
    <Button
      color={color || 'primary'}
      onClick={onClick}
      variant={variant}
      disabled={disabled || loading}
      startIcon={loading ? <CircularProgress size={15} /> : startIcon}
      autoFocus={autoFocus}
      fullWidth={fullWidth}
      className={className}
      size={size}
    >
      {children}
    </Button>
  );
};

// ------------------ CONFIG CONTAINER ------------------

const withConnect = connect(
  selectState(
    'app.loaders',
  ),
  null,
  null,
  { context: ReactReduxContext },
);

export default compose(
  withRouter,
  withConnect,
)(ButtonWithLoader);
