import React from 'react';
import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import BuildIcon from '@material-ui/icons/Build';
import SubjectIcon from '@material-ui/icons/Subject';
import SpeedIcon from '@material-ui/icons/Speed';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { makeStyles } from '@material-ui/styles';
import { changeFormatDate, numberFormatter } from 'utils/functions';
import DownloadDocument from 'commons/icons/components/DownloadDocument';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import classNames from 'classnames';
import workOrderType from 'utils/workOrderType';
import styles from './styles';

const useStyles = makeStyles(styles);
const CardMobile = ({ workOrder }) => {
  const classes = useStyles();

  const {
    externalId,
    date,
    type,
    description,
    km,
    status,
  } = workOrder;

  return (
    <Box width="100%">
      <Box
        display="flex"
        justifyContent="space-between"
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="start"
        >
          <FiberManualRecordIcon
            className={classNames(classes.statusIcon,
              status ? classes.enabled : classes.disabled)}
          />
          <Typography component="div" color="primary" noWrap>
            <Box overflow="auto">
              <Box component="span" fontSize={16} fontWeight={600}>
                {externalId || 'No informado'}
              </Box>
              <Box component="span" fontWeight="fontWeightMedium">
                {` | ${status ? 'ABIERTO' : 'CERRADO'}`}
              </Box>
            </Box>
          </Typography>
        </Box>
        <Box>
          { type === workOrderType.ZRMA && <DownloadDocument />}
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
      >
        <Box display="flex" marginTop="8px">
          <BuildIcon className={classes.mobileIcon} />
          <Typography component="div" color="primary" noWrap>
            <Box overflow="auto">
              <Box component="span" fontSize={16} fontWeight={600}>
                {'Tipo | '}

              </Box>
              <Box component="span" fontSize={16} alignItems="center">
                { type || 'No informado'}
              </Box>
            </Box>
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between">

          <Box display="flex" marginTop="8px">
            <DateRangeIcon className={classes.mobileIcon} />
            <Typography component="div" color="primary" noWrap>
              <Box overflow="auto">
                <Box component="span" fontSize={16}>
                  {changeFormatDate(date)}
                </Box>
              </Box>
            </Typography>
          </Box>
          <Box display="flex" marginTop="8px">
            <SpeedIcon className={classes.mobileIcon} />
            <Typography component="div" color="primary" noWrap>
              <Box overflow="auto">

                <Box component="span" fontSize={16}>
                  { km ? `${numberFormatter(km)} Km` : 'No informado'}
                </Box>
              </Box>
            </Typography>
          </Box>
        </Box>
        <Box display="flex" marginTop="8px">
          <SubjectIcon className={classes.mobileIcon} />
          <Typography component="div" color="primary" noWrap>
            <Box overflow="auto">
              <Box component="span" fontSize={16}>
                { description || 'No informado'}
              </Box>
            </Box>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default CardMobile;
