import React, { useEffect, useMemo } from 'react';

// Project
import ScreenTitle from 'commons/components/ScreenTitle';
import Skeleton from 'screens/User/components/Skeleton';
import { composedComponent } from 'utils/functions';
import { newPaymentStatusActions } from 'screens/GeneratePaymentStatus/reducer';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import ExtrasDialog
  from 'screens/GeneratePaymentStatus/components/ExtrasDialog';
import DocumentsDialog
  from 'screens/GeneratePaymentStatus/components/DocumentsDialog';
import ButtonsOptions
  from 'screens/GeneratePaymentStatus/components/ButtonsOptions';
import {
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router-dom';
import PostDialog from 'screens/GeneratePaymentStatus/components/PostDialog';
import ApproveDialog
  from 'screens/GeneratePaymentStatus/components/ApproveDialog';
import { Cached } from '@material-ui/icons';
import Filter from './components/Filter';
import saga from './saga';
import PaymentStatusPreview from './components/PaymentStatusList';
// Material

// icons
const GeneratePaymentStatus = ({
  loading,
  controls,
  actions,
  extrasDialogControls,
  documentsDialogControls,
  postDialogControls,
  approveDialogControls,
  paymentStatusDTO,
  socialReasonPaymentStatusDTO,
  user,
  holding,
  socialReason,
  psSettingsDTO,
  psSectionsDTO,
  error,
}) => {
  const { id } = useParams();
  const history = useHistory();
  const role = user?.user?.type;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (holding?.id) {
      actions.getPaymentStatus(id);
    }
  }, [id, holding, socialReason, role]);

  useEffect(() => {
    if (paymentStatusDTO.fleets?.length === 1) {
      actions.changeControls('fleet', paymentStatusDTO.fleets[0].id);
    } else {
      actions.changeControls('fleet', '0');
    }
  }, [id, holding, socialReason, role, paymentStatusDTO]);

  useEffect(() => {
    if (error) {
      actions.resetControls();
      history.push('/payment-status');
    }
  }, [error, id, holding, socialReason, role]);

  const fleetPaymentStatusDTO = useMemo(() => (
    controls.fleet !== '0'
      ? {
        ...paymentStatusDTO,
        fleets: paymentStatusDTO.fleets?.filter((fleet) => fleet.id === Number(controls.fleet)),
      }
      : paymentStatusDTO
  ), [controls.fleet, paymentStatusDTO, holding, socialReason, role]);

  const topButtons = (
    <>
      <IconButton
        disableRipple
        onClick={() => { history.push('/payment-status'); }}
      >
        <ArrowBackIcon color="secondary" />
        <Typography color="primary">Volver</Typography>
      </IconButton>
      <IconButton
        disableRipple
        onClick={() => { history.go(0); }}
      >
        <Cached color="secondary" />
        <Typography color="primary">Recargar</Typography>
      </IconButton>
    </>
  );

  return (
    loading ? (
      <>
        {topButtons}
        <Skeleton />
      </>
    )
      : (
        <>
          {!isMobile && topButtons}
          <ScreenTitle title="ESTADO DE PAGO" />
          <PostDialog
            controls={controls}
            actions={actions}
            postDialogControls={postDialogControls}
            psId={id}
            role={role}
          />
          <ApproveDialog
            controls={controls}
            actions={actions}
            approveDialogControls={approveDialogControls}
            status={paymentStatusDTO?.status}
            psId={id}
          />
          <ExtrasDialog
            controls={controls}
            actions={actions}
            extrasDialogControls={extrasDialogControls}
            paymentStatusDTO={fleetPaymentStatusDTO}
            psId={id}
          />
          <DocumentsDialog
            controls={controls}
            actions={actions}
            documentsDialogControls={documentsDialogControls}
            paymentStatusDTO={fleetPaymentStatusDTO}
            psId={id}
          />
          <Filter
            controls={controls}
            actions={actions}
            fleets={paymentStatusDTO?.fleets}
            paymentStatusDTO={fleetPaymentStatusDTO}
            role={role}
            socialReasonPaymentStatusDTO={socialReasonPaymentStatusDTO}
            isMobile={isMobile}
          />
          <PaymentStatusPreview
            paymentStatusDTO={fleetPaymentStatusDTO}
            actions={actions}
            controls={controls}
            psId={id}
            role={role}
            isMobile={isMobile}
            psSettings={psSettingsDTO}
            psSections={psSectionsDTO}
          />
          <ButtonsOptions
            role={role}
            paymentStatusDTO={fleetPaymentStatusDTO}
            controls={controls}
            actions={actions}
            psId={id}
            isMobile={isMobile}
          />
        </>
      )
  );
};

export default composedComponent(GeneratePaymentStatus, saga, {
  saga: 'sagaNewPaymentStatus',
  states: [
    'newPaymentStatus.controls',
    'newPaymentStatus.actions',
    'newPaymentStatus.extrasDialogControls',
    'newPaymentStatus.documentsDialogControls',
    'newPaymentStatus.postDialogControls',
    'newPaymentStatus.approveDialogControls',
    'newPaymentStatus.loading',
    'newPaymentStatus.paymentStatusDTO',
    'newPaymentStatus.socialReasonPaymentStatusDTO',
    'newPaymentStatus.psSettingsDTO',
    'newPaymentStatus.psSectionsDTO',
    'newPaymentStatus.error',
    'app.user',
    'app.holding',
    'app.socialReason',
  ],
  actions: [newPaymentStatusActions],
});
