import React from 'react';
import {
  Card,
  Grid, IconButton, Input, InputAdornment,
} from '@material-ui/core';

// Material
import Typography from '@material-ui/core/Typography';
// icons
import {
  GetApp,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import type from 'utils/type';
import styles from './styles';

const useStyles = makeStyles(styles);

const PostCard = ({
  isMobile, data, fleets,
}) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.grid1}>
      <Grid item xs={12} md={12}>
        <Typography variant="h6" color="primary">
          HISTORIAL DE OBSERVACIONES
        </Typography>
      </Grid>
      {data?.map((post, idx) => (
        <Grid key={post.id} item xs={12} md={12}>
          <Card key={post.id} className={idx % 2 === 0 ? classes.back1 : classes.back2}>
            <Grid container className={classes.grid2} spacing={1}>
              <Grid item xs={12} md={6}>
                <Typography variant="h6" color="primary">
                  {post.userType === type.ADMINISTRADOR_DE_CONTRATO ? 'Administrador de contrato' : `${post.userName} - ${fleets?.find((fl) => fl.id === post.paymentStatusFleetId).name || 'Vehículos sin flota'}`}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} container justifyContent={isMobile ? 'flex-start' : 'flex-end'}>
                <Typography variant="subtitle1" color="primary">
                  {moment.utc(post.date).format('DD-MM-YYYY')}
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography variant="subtitle1" color="primary">
                  {post.message}
                </Typography>
              </Grid>
              {post.documents?.map((document) => (
                <Grid key={document.id} item>
                  <Input
                    key={document.name}
                    className={classes.fileName}
                    value={document.name.slice(37, document.name.length)}
                    fullWidth
                    disabled
                    disableUnderline
                    startAdornment={(
                      <InputAdornment position="start">
                        <IconButton color="primary" onClick={() => window.open(document.path, '_blank', 'noopener,noreferrer')}>
                          <GetApp />
                        </IconButton>
                      </InputAdornment>
                      )}
                  />
                </Grid>
              ))}
            </Grid>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default PostCard;
