import {
  Grid, Typography,

} from '@material-ui/core';
import React from 'react';
import Card from '@material-ui/core/Card';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import CardContent from '@material-ui/core/CardContent';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import {
  formatDateForCards, numberFormatter,
} from 'utils/functions';

export default function VehicleDetailCardsMobile({ vehicle }) {
  const {
    km, deliveryDate, dueDate,
  } = vehicle;
  const matches = useMediaQuery('(min-width:360px)');

  const replacementHours = vehicle['VehicleTariff.replacementHours'];
  function cardGenerator(content) {
    const {
      title, body, date, outOfDate,
    } = content;
    return (
      <Card style={{ minWidth: '150px', height: '108px', marginTop: '20px' }}>
        <CardContent style={{ textAlign: 'center' }}>
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
            {date ? <CalendarTodayIcon color={outOfDate ? 'secondary' : 'primary'} style={{ fontSize: '1em', paddingRight: '6px', paddingTop: '4px' }} /> : null}

            <Typography style={{ fontSize: '1em', fontWeight: 'bold' }} color={outOfDate ? 'secondary' : 'primary'}>{title}</Typography>
          </Grid>
          <Typography color={outOfDate ? 'secondary' : 'primary'}>{body}</Typography>
        </CardContent>
      </Card>
    );
  }

  function cardRowGenerator({ leftCard, rightCard }) {
    return (
      <Grid item xs={12}>
        <Grid
          container
          spacing={2}
          justifyContent="space-evenly"
        >
          <Grid item xs={6}>
            {leftCard}
          </Grid>
          {(!matches) ? <Grid item xs={12} /> : null}
          <Grid item xs={6}>
            {rightCard}
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      {cardRowGenerator({
        leftCard: cardGenerator({
          title: '-', body: '...', date: false,
        }),
        rightCard: cardGenerator({ title: `${numberFormatter(km) || '-'} KM`, body: 'Kilometraje', date: true }),
      })}

      {cardRowGenerator({
        leftCard: cardGenerator({
          title: `${replacementHours || 0} Hrs.`, body: 'Horas de reemplazo', date: true,
        }),
        rightCard: cardGenerator({ title: (deliveryDate) ? formatDateForCards(deliveryDate) : 'No informado', body: 'Entrega', date: true }),
      })}

      {cardRowGenerator({
        leftCard: cardGenerator({
          title: (dueDate) ? formatDateForCards(dueDate) : 'No informado', body: 'Vencimiento', date: true,
        }),
      })}
    </>

  );
}
