import React, { useEffect, useCallback } from 'react';
import {
  Route, Switch, useHistory,
} from 'react-router-dom';

import {
  Snackbar, withStyles,
} from '@material-ui/core';

import { getParameterByName, composedComponent } from 'utils/functions';
import { appActions } from 'commons/reducer';
import Alert from '@material-ui/lab/Alert';
import Paths from 'utils/paths';
import styles from './styles';
import { recoveryPasswordActions } from './reducer';
import saga from './saga';

import RecoveryForm from './components/recoveryForm';
import UpdatePasswordForm from './components/updatePasswordForm';

const RecoveryPassword = (props) => {
  const {
    classes,
    error,
    message,
    emailVerify,
    successChangePassword,
    actions,
    location,
    controls,
    loading,
  } = props;
  const history = useHistory();
  const pathLogin = Paths.shared[0].path;
  useEffect(() => {
    actions.resetControls();
    actions.disableMessage();
    const token = getParameterByName('token', location.search);
    const email = getParameterByName('email', location.search)?.replace(' ', '+'); // TODO: borrar replace después.
    actions.setControls({ token, email: (email == null) ? '' : email });
  }, [actions, location.seaprops, location.search]);

  const onChangeText = useCallback((name, value) => {
    actions.setControls({ [name]: value });
  }, []);

  const toggleError = () => {
    actions.disableErrors();
  };

  const toggleSuccess = () => {
    actions.disableMessage();
    actions.toggleSuccess();
  };

  return (
    <div>
      <div>
        <Switch>
          <Route
            exact
            path="/recovery-password"
            render={() => (
              <RecoveryForm
                onChangeText={onChangeText}
                email={controls.email}
                classes={classes}
                loading={loading}
                controls={controls}
                actions={actions}
                history={history}
              />
            )}
          />
          <Route
            exact
            path="/update-password"
            render={() => (
              <UpdatePasswordForm
                onChangeText={onChangeText}
                password={controls.password}
                verifyPassword={controls.verifyPassword}
                classes={classes}
                loading={loading}
                controls={controls}
                actions={actions}
              />
            )}
          />
        </Switch>
      </div>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={error}
        autoHideDuration={3000}
        onClose={toggleError}
      >
        <Alert onClose={toggleError} severity="error">
          {message}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={successChangePassword || emailVerify}
        autoHideDuration={3000}
        onClose={() => {
          actions.disableMessage();
          if (successChangePassword || emailVerify) {
            history.push(pathLogin);
          }
        }}
      >
        <Alert onClose={toggleSuccess} severity="success">
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default composedComponent(RecoveryPassword, saga, {
  saga: 'sagaRecoveryPassword',
  middlewares: [withStyles(styles)],
  states: [
    'recoveryPassword.controls',
    'recoveryPassword.message',
    'recoveryPassword.error',
    'recoveryPassword.emailVerify',
    'recoveryPassword.successChangePassword',
    'recoveryPassword.loading',

  ],
  actions: [recoveryPasswordActions, appActions],
});
