import { useHistory, useLocation } from 'react-router-dom';
import { Box, makeStyles, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo } from 'react';

// Material
import Grid from '@material-ui/core/Grid';

// Project
import ScreenTitle from 'commons/components/ScreenTitle';
import type from 'utils/type';
import { composedComponent } from '../../utils/functions';
import saga from './saga';
import { accountActions } from './reducer';
import styles from './styles';
import Details from './component/Details';
import ChangePassword from './component/ChangePassword';
import Notification from './component/Notification';

const useStyles = makeStyles(styles);

const buttons = [
  {
    name: 'CUENTA',
    path: '/account',
  },
  {
    name: 'CONTRASEÑA',
    path: '/account/change-password',
  },
  {
    name: 'NOTIFICACIONES',
    path: '/account/notification',
    avoid: type.ADMINISTRADOR_PORTAL_SALFA,
  },
];

const Button = ({ name, path, selected }) => {
  const classes = useStyles({ selected });
  const history = useHistory();
  return (
    <Box
      key={name}
      className={classes.button}
      maxWidth
      color="primary"
      onClick={() => history.push(path)}
    >
      <Typography variant="h6" noWrap>
        <Box
          fontSize={16}
          className={classes.buttonText}
          fontWeight="normal"
        >
          {name}
        </Box>
      </Typography>
    </Box>
  );
};

const Account = (props) => {
  const {
    actions, formControl, user, holdings, loading,
  } = props;
  const { type: userType } = user.user;
  const classes = useStyles(props);
  const location = useLocation();

  useEffect(() => {
    actions.getStateFromApiUser();
  }, []);

  const onChangeInput = useCallback((name, value) => {
    actions.setFormControls({ [name]: value });
  }, []);

  const holdingsData = useMemo(() => Object.values(holdings).filter((h) => h.isActive).map((h) => ({
    name: h.name,
    id: h.id,
  })), [holdings]);

  return (
    <Box className={classes.screenContainer} height={1}>
      <ScreenTitle title="Mi cuenta" showSubTitle={false} />
      <Box className={classes.gridContainer}>
        <Grid container spacing={1} className={classes.grid}>
          <Grid item xs={12} md={4} xl={3}>
            <Box className={classes.buttonGroup}>
              {buttons.map(({ name, path, avoid }) => (
                <>
                  {avoid !== userType
                    ? (
                      <Button
                        key={name}
                        name={name}
                        path={path}
                        selected={location.pathname === path}
                      />
                    ) : null}
                </>
              ))}
            </Box>
          </Grid>
          <Grid item xs={12} md={8} xl={9}>
            <Box className={classes.componentZone}>
              {location.pathname === '/account'
                ? (
                  <Details
                    classes={classes}
                    user={user.user}
                    holdings={holdingsData}
                  />
                ) : null }
              {location.pathname === '/account/change-password'
                ? (
                  <ChangePassword
                    classes={classes}
                    formControl={formControl}
                    onChangeInput={onChangeInput}
                    changePassword={actions.changePassword}
                  />
                ) : null }
              {location.pathname === '/account/notification'
              && userType !== type.ADMINISTRADOR_PORTAL_SALFA
                ? (
                  <Notification
                    loading={loading}
                    actions={actions}
                    formControl={formControl}
                  />
                ) : null }
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default composedComponent(Account, saga, {
  saga: 'sagaAccount',
  states: [
    'account.controls',
    'account.formControl',
    'account.loading',
    'account.error',
    'account.message',
    'account.notification',
    'app.user',
    'app.objects.holdings',
  ],
  actions: [accountActions],
});
