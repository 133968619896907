import {
  takeLatest, spawn, put, select,
} from 'redux-saga/effects';
import {
  apiSuccess, get, post, update,
} from 'utils/api';

import { FLEETS } from '../reducer';
import * as APP from '../../../commons/reducer';

const getControls = (state) => state.fleets.controls;
const getHolding = (state) => state.app.holding;

function* fetchData() {
  yield takeLatest(FLEETS.FETCH_DATA, function* () {
    const { id } = yield select(getHolding);
    let path = 'api/v1/fleets';
    if (id) path += `?holding=${id}`;

    const response = yield get(path);
    if (!response.error) {
      yield put(apiSuccess(FLEETS.FETCH_DATA_SUCCESS, response));
      yield put(apiSuccess(APP.GET_STATE_REPLACE_FROM_API_SUCCESS, response));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { msg: 'Error en al cargar al vista de flota, por favor intentelo más tarde.' }));
    }
  });
}

function* createFleet() {
  yield takeLatest(FLEETS.CREATE_FLEET, function* () {
    yield put(APP.appActions.setLoader('create-fleet', true));
    const {
      inputFleet, userId, socialReasonId,
    } = yield select(getControls);
    const { id } = yield select(getHolding);
    const path = 'api/v1/fleets';

    const response = yield post(path, {
      holding: id,
      inputFleet,
      userId,
      socialReasonId,
    });

    if (!response.error) {
      yield put(apiSuccess(APP.SET_SUCCESS, { msg: 'Flota creada con éxito.' }));
      yield put(apiSuccess(APP.GET_STATE_REPLACE_FROM_API_SUCCESS, response));
      yield put(apiSuccess(FLEETS.CREATE_FLEET_SUCCESS, response.fleet));
    } else if (response.errorMsg) {
      yield put(apiSuccess(APP.SET_ERROR, { msg: response.errorMsg }));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { msg: 'Error interno, inténtelo más tarde' }));
    }
    yield put(APP.appActions.setLoader('create-fleet', false));
  });
}

function* editFleet() {
  yield takeLatest(FLEETS.EDIT_FLEET, function* () {
    yield put(APP.appActions.setLoader('edit-fleet', true));
    const {
      userId, socialReasonId, fleet, inputFleet,
    } = yield select(getControls);
    const { id } = yield select(getHolding);
    const path = 'api/v1/fleets';

    const response = yield update(path, {
      userId,
      socialReasonId,
      holding: id,
      fleet,
      inputFleet,
    });

    if (response.objects) yield put(apiSuccess(APP.GET_STATE_REPLACE_FROM_API_SUCCESS, response));
    if (!response.error) {
      yield put(apiSuccess(APP.SET_SUCCESS, { msg: 'Flota editada con éxito.' }));
      yield put(apiSuccess(FLEETS.EDIT_FLEET_SUCCESS));
    } else if (response.errorMsg) {
      yield put(apiSuccess(APP.SET_ERROR, { msg: response.errorMsg }));
      // yield put(apiSuccess(FLEETS.CLEAR_DATA));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { msg: 'Error interno, inténtelo más tarde' }));
      yield put(apiSuccess(FLEETS.CLEAR_DATA));
    }
    yield put(APP.appActions.setLoader('edit-fleet', false));
  });
}
function* deleteFleet() {
  yield takeLatest(FLEETS.DELETE_FLEET, function* () {
    const { fleet, inputFleet, userId } = yield select(getControls);
    const { id } = yield select(getHolding);
    const path = 'api/v1/fleets/delete';

    const response = yield post(path, {
      holding: id,
      fleet,
      inputFleet,
      userId,
    });

    if (response.objects) yield put(apiSuccess(APP.GET_STATE_REPLACE_FROM_API_SUCCESS, response));
    if (!response.error) {
      yield put(apiSuccess(APP.SET_SUCCESS, { msg: 'Flota eliminada con éxito.' }));
      yield put(apiSuccess(FLEETS.DELETE_FLEET_SUCCESS));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { msg: response.errorMsg }));
      yield put(apiSuccess(FLEETS.CLEAR_DATA));
    }
  });
}

function* sendAssign() {
  yield takeLatest(FLEETS.SEND_ASSIGN, function* () {
    const { fleet, toAssign } = yield select(getControls);
    const { id } = yield select(getHolding);

    const path = 'api/v1/fleets/assign';

    const response = yield post(path, {
      holding: id,
      fleet,
      toAssign,
    });

    if (response.objects) yield put(apiSuccess(APP.GET_STATE_REPLACE_FROM_API_SUCCESS, response));
    if (!response.error) {
      yield put(apiSuccess(APP.SET_SUCCESS, { msg: 'Vehículo(s) asignado(s) con éxito.' }));
      yield put(apiSuccess(FLEETS.SEND_ASSIGN_SUCCESS));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { msg: 'Error interno, inténtelo más tarde' }));
      yield put(apiSuccess(FLEETS.CLEAR_DATA));
    }
  });
}

function* sendDeallocate() {
  yield takeLatest(FLEETS.SEND_DEALLOCATE, function* () {
    const { toDeallocate } = yield select(getControls);
    const { id } = yield select(getHolding);

    const path = 'api/v1/fleets/deallocate';

    const response = yield post(path, {
      holding: id,
      toDeallocate,
    });
    if (response.objects) yield put(apiSuccess(APP.GET_STATE_REPLACE_FROM_API_SUCCESS, response));
    if (!response.error) {
      yield put(apiSuccess(APP.SET_SUCCESS, { msg: 'Vehículo(s) desasignado(s) con éxito.' }));
      yield put(apiSuccess(FLEETS.SEND_DEALLOCATE_SUCCESS));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { msg: 'Error interno, inténtelo más tarde' }));
      yield put(apiSuccess(FLEETS.CLEAR_DATA));
    }
  });
}

export default function* root() {
  yield spawn(fetchData);
  yield spawn(createFleet);
  yield spawn(editFleet);
  yield spawn(deleteFleet);
  yield spawn(sendAssign);
  yield spawn(sendDeallocate);
}
