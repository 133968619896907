import {
  Box,
  Button,
  Grid, IconButton, Modal, Paper, Typography, makeStyles,
} from '@material-ui/core';
import classNames from 'classnames';

import CloseIcon from '@material-ui/icons/Close';
import GetAppIcon from '@material-ui/icons/GetApp';

import React from 'react';
import { capitalize } from 'utils/utils';
import { Skeleton } from '@material-ui/lab';
import styles from './styles';

const useStyles = makeStyles(styles);

function DownloadModal({
  isOpen, handleClose, documents, handleClickDownload, vehicles, isLoading,
}) {
  const haveVehicles = vehicles?.length !== 0;
  const classes = useStyles();
  const handleClick = (haveDocs, zip) => () => {
    if (haveDocs) {
      handleClickDownload(zip[0].id, zip[0].fileName);
    }
  };
  const handleClickDownloadAll = (all) => () => {
    if (all?.[0]?.haveDocs) {
      handleClickDownload(all[0].zip[0].id, all[0].zip[0].fileName);
    }
  };
  return (
    <div>
      <Modal open={isOpen} onClose={handleClose}>
        <Paper className={classes.papper}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item container md={12} className={classes.header}>
              <Grid item style={{ paddingLeft: '16px' }}>
                <Typography
                  variant="h4"
                  fontWeight="bold"
                >
                  Seleccionar documentos
                </Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item container className={classes.subTitle}>
              <Grid item>
                <Typography>
                  Selecione los documentos que desea descargar
                </Typography>
              </Grid>
            </Grid>
            {isLoading ? (
              <Box className={classes.skeleton}>
                <Skeleton animation="wave" />
              </Box>

            )
              : (
                <Grid item container md={8} className={classes.body}>
                  {documents?.types?.map((document) => (
                    <Grid item container md={12} key={document.id} className={classes.rowContainer}>
                      <Grid item md={8}>
                        <Typography className={(!document.haveDocs || !haveVehicles) ? classes.notAvailable : ''}>
                          {capitalize(document.name)}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        container
                        md={4}
                        className={
                      classNames(
                        classes.download,
                        (!document.haveDocs || !haveVehicles) && classes.notAvailable,
                      )
                    }
                        onClick={handleClick(document.haveDocs && haveVehicles, document.zip)}
                      >
                        <Grid>
                          <GetAppIcon />
                        </Grid>
                        <Typography className={(!document.haveDocs || !haveVehicles) ? classes.notAvailable : ''}>
                          Descargar
                        </Typography>
                        <Grid />
                      </Grid>

                    </Grid>
                  ))}
                </Grid>
              )}
            <Grid item container md={10} className={classes.buttonContainer}>
              <Grid item container sm={4} className={classes.button}>
                <Grid item>

                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleClose}
                  >
                    <Box fontWeight="bold">
                      CANCELAR
                    </Box>
                  </Button>
                </Grid>
              </Grid>
              <Grid item container sm={4} className={classes.button}>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={!documents.all?.[0].haveDocs || !haveVehicles}
                    onClick={handleClickDownloadAll(documents.all)}
                  >
                    <Box fontWeight="bold">
                      DESCARGAR
                    </Box>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Modal>
    </div>
  );
}

export default DownloadModal;
