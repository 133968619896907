import React, { useEffect, useRef } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import {
  Box, TextField, Tooltip, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { debounce } from 'utils/functions';
import styles from './styles';

const useStyles = makeStyles(styles);

const UserSearch = ({
  onClickIcon, isOpen, onChange, value, onSearch,
}) => {
  const classes = useStyles();
  const timer = useRef();

  const debouncedChange = debounce(onSearch, 600, timer);
  useEffect(() => {
    debouncedChange(value);
  }, [value]);

  const handleChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <div style={{ flex: 1 }}>
      {!isOpen
        ? (
          <Tooltip title={(
            <Typography component="div">
              <Box fontSize={12}>
                Buscar Usuario
              </Box>
            </Typography>
          )}
          >
            <IconButton
              onClick={onClickIcon}
            >
              <SearchIcon
                color="primary"
              />
            </IconButton>
          </Tooltip>
        )
        : (
          <TextField
            id="userName"
            value={value}
            variant="outlined"
            placeholder="Usuario"
            onChange={handleChange}
            className={classes.inputField}
          />
        )}
    </div>
  );
};

export default UserSearch;
