import React from 'react';
// Material
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';

// Project
import MultipleFilter from 'commons/components/MultipleFilter';
import ButtonWithLoader from 'commons/containers/ButtonWithLoader';
import FilterSkeleton from 'screens/PaymentStatus/components/FilterSkeleton';
import styles from './styles';

const useStyles = makeStyles(styles);

const ClearIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M16,11h-1V3c0-1.1-0.9-2-2-2h-2C9.9,1,9,1.9,9,3v8H8c-2.76,0-5,2.24-5,5v7h18v-7C21,13.24,18.76,11,16,11z M19,21h-2v-3 c0-0.55-0.45-1-1-1s-1,0.45-1,1v3h-2v-3c0-0.55-0.45-1-1-1s-1,0.45-1,1v3H9v-3c0-0.55-0.45-1-1-1s-1,0.45-1,1v3H5v-5 c0-1.65,1.35-3,3-3h8c1.65,0,3,1.35,3,3V21z" />
  </SvgIcon>
);

const Filter = ({
  controls, actions, isLoading, pageLoading, clients, periods, status, loadingFilter, isMobile,
}) => {
  const { clearControls, changeControls, fetchWithFilter } = actions;

  const classes = useStyles();

  return (loadingFilter ? <FilterSkeleton />
    : (
      <Grid
        container
        spacing={2}
        className={isMobile ? classes.filterContainer1 : classes.filterContainer2}
      >
        <Grid item md={3} xs={12}>
          <MultipleFilter
            id="status"
            label="Estado"
            value={controls.status}
            options={status}
            onChange={changeControls}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <MultipleFilter
            id="client"
            label="Cliente"
            value={controls.client}
            options={clients}
            onChange={changeControls}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <MultipleFilter
            id="period"
            label="Período"
            value={controls.period}
            options={periods}
            onChange={changeControls}
          />
        </Grid>
        <Grid item md={1} xs={12}>
          <ButtonWithLoader
            className={classes.clearButton}
            buttonId="clear-button"
            onClick={() => !isLoading && !pageLoading && clearControls()}
            color="primary"
            variant="outlined"
            fullWidth
          >
            <ClearIcon color="primary" />
          </ButtonWithLoader>
        </Grid>
        <Grid item md={2} xs={12}>
          <ButtonWithLoader
            buttonId="find-button"
            onClick={() => !isLoading && !pageLoading && fetchWithFilter()}
            color="primary"
            variant="contained"
            fullWidth
          >
            Buscar
          </ButtonWithLoader>
        </Grid>
      </Grid>
    ));
};

export default Filter;
