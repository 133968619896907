const styles = (theme) => ({
  container: {
    marginTop: theme.spacing(8),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  filterContainer: {
    marginBottom: theme.spacing(2),
  },
  itemsContainer: {
    height: '100%',
    overflow: 'auto',
  },
  listItem: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    borderRadius: 10,
  },
  thirdContainer: {
    marginBottom: theme.spacing(2),
  },
  searchField: {
    '& fieldset': {
      borderRadius: 50,
      color: theme.palette.primary.main,
    },
    '& label.Mui-focused': {
      color: theme.palette.primary.main,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.primary.main,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.primary.main,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.primary.main,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
      },
    },
  },
  checkBox: {
    borderColor: theme.palette.primary.main,
  },
  typography: {
    textAlign: 'center',
  },
  licensePlate: {
    fontSize: 16,
    fontWeight: 'normal',
  },
  vehicleModel: {
    fontSize: 12,
  },
  assignedToName: {
    fontSize: 12,
    fontWeight: 600,
  },
});

export default styles;
