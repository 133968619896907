import {
  Button,
  Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, makeStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';

// Icons
import CloseIcon from '@material-ui/icons/Close';

import CustomTextField from 'commons/components/CustomTextField';
import { REPORTS_LABELS } from 'screens/ReportsMaintainer/constants';
import { isValidUrl } from 'utils/functions';
import styles from './styles';

const useStyles = makeStyles(styles);

function ReportsDialog({
  isOpen, onClose, onSubmit, errors = {}, title = 'Crear Reporte', name = '', url = '',
}) {
  const classes = useStyles();
  const [reportName, setReportName] = useState(name);
  const [reportUrl, setReportUrl] = useState(url);
  const [nameErrorEdit, setNameErrorEdit] = useState('');
  const [urlErrorEdit, setUrlErrorEdit] = useState('');
  const { name: nameError, url: urlError } = errors;

  useEffect(() => {
    setReportName(name);
    setReportUrl(url);
  }, [name, url]);

  const handleClose = () => {
    onClose();
    setReportName(name);
    setReportUrl(url);
    setNameErrorEdit('');
    setUrlErrorEdit('');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let titleError = '';
    let urlInputError = '';

    if (!reportName) titleError = 'El título es requerido';
    if (!reportUrl) urlInputError = 'La URL es requerida';
    if (reportUrl && (!isValidUrl(reportUrl) || !reportUrl.includes('app.powerbi'))) urlInputError = 'La URL no es válida';

    setNameErrorEdit(titleError);
    setUrlErrorEdit(urlInputError);

    if ((titleError || urlInputError) && title !== 'Crear Reporte') return;

    if (title === 'Crear Reporte') {
      onSubmit(reportName, reportUrl, handleClose);
      return;
    }
    onSubmit(reportName, reportUrl, onClose);
  };

  return (
    <Dialog open={isOpen} fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          <Grid container alignItems="center">
            <Grid item className={classes.title}>
              {title}
            </Grid>
            <Grid item>
              <IconButton
                onClick={handleClose}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container className={classes.inputContainer}>
            <CustomTextField
              id="2"
              autoFocus
              disableAutoComplete
              color="primary"
              value={reportName}
              onChange={(e) => setReportName(e.target.value)}
              required
              label={REPORTS_LABELS.NAME.toUpperCase()}
              variant="outlined"
              error={title === 'Crear Reporte' ? Boolean(nameError) : Boolean(nameErrorEdit)}
              helperText={title === 'Crear Reporte' ? nameError : nameErrorEdit}
            />
          </Grid>
          <Grid container className={classes.inputContainer}>
            <CustomTextField
              id="3"
              autoFocus
              disableAutoComplete
              color="primary"
              value={reportUrl}
              onChange={(e) => setReportUrl(e.target.value)}
              required
              label={REPORTS_LABELS.URL.toUpperCase()}
              variant="outlined"
              error={title === 'Crear Reporte' ? Boolean(urlError) : Boolean(urlErrorEdit)}
              helperText={title === 'Crear Reporte' ? urlError : urlErrorEdit}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="secondary" onClick={handleClose}>Cancelar</Button>
          <Button variant="contained" color="primary" type="submit">Guardar</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default ReportsDialog;
