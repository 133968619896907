export const REPORTS_LABELS = {
  PUBLISH_DATE: 'Fecha Publicación',
  NAME: 'nombre',
  URL: 'Url reporte',
};

export const REPORTS_KEYS = {
  PUBLISH_DATE: 'date',
  NAME: 'name',
  URL: 'url',
};

export const USERS_LABELS = {
  EMAIL: 'email',
  ROLE: 'rol',
  NAME: 'nombre',
  IS_REPORT_AVAILABLE: 'Reporte habilitado',
};

export const USERS_KEYS = {
  EMAIL: 'email',
  ROLE: 'role',
  NAME: 'name',
  IS_REPORT_AVAILABLE: 'reportAvailable',
};
