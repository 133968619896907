import { takeLatest, spawn, put } from 'redux-saga/effects';
import {
  apiSuccess, post, get,
} from 'utils/api';
import * as APP from 'commons/reducer';

import { QUOTE } from 'screens/Quote/reducer';

function* getSocialReasons() {
  yield takeLatest(QUOTE.GET_SOCIAL_REASONS, function* () {
    const response = yield get('api/v1/quotes/social-reasons');
    if (!response.error) {
      yield put(apiSuccess(QUOTE.GET_SOCIAL_REASONS_SUCCESS, response));
    } else {
      yield put(apiSuccess(QUOTE.GET_SOCIAL_REASONS_ERROR));
      yield put(apiSuccess(APP.SET_ERROR, { msg: 'Error al obtener los datos' }));
    }
  });
}

function* sentQuote() {
  yield takeLatest(QUOTE.SENT_QUOTE, function* (action) {
    const body = action.payload;
    const response = yield post('api/v1/quotes/create', body);
    if (!response.error) {
      yield put(apiSuccess(QUOTE.SENT_QUOTE_SUCCESS));
      yield put(apiSuccess(APP.SET_SUCCESS, { msg: 'Cotización enviada correctamente' }));
    } else {
      yield put(apiSuccess(QUOTE.SENT_QUOTE_ERROR));
      yield put(apiSuccess(APP.SET_ERROR, { msg: response.errorMsg }));
    }
  });
}

export default function* root() {
  yield spawn(sentQuote);
  yield spawn(getSocialReasons);
}
