import {
  takeLatest, spawn, put, select,
} from 'redux-saga/effects';
import { apiSuccess, get, post } from 'utils/api';

import { generatePath } from 'utils/functions';
import { PAYMENT_STATUS } from '../reducer';
import * as APP from '../../../commons/reducer';

const getSize = (state) => state.paymentStatus.pagination.size;
const getPage = (state) => state.paymentStatus.pagination.page;
const getQuery = (state) => state.paymentStatus.query;
const getHolding = (state) => state.app.holding.id;

function* generatePathPaymentStatus({
  page,
  size,
  route,
}) {
  const pageToSend = page > 0 ? page : (yield select(getPage));
  const sizeToSend = size > 0 ? size : (yield select(getSize));
  let path = `${route}?size=${sizeToSend}&page=${pageToSend}`;

  const {
    period,
    status,
    client,
  } = yield select(getQuery);

  const holding = yield select(getHolding);
  const periodPath = generatePath(period, 'period');
  const statusPath = generatePath(status, 'status');
  const clientPath = generatePath(client, 'socialReasonId');
  if (holding) path += `&holdingId=${holding}`;
  if (period) path += periodPath;
  if (status) path += statusPath;
  if (client) path += clientPath;

  return path;
}

function* requestPaymentsStatus(
  page = 0,
  size = 0,
) {
  const path = yield generatePathPaymentStatus({
    page, size, route: 'api/v1/payment-status',
  });
  return { page, size, ...yield get(path) };
}

function* getInitialData() {
  yield takeLatest(PAYMENT_STATUS.FETCH_INITIAL_DATA, function* () {
    const response = yield requestPaymentsStatus();
    if (!response.error) {
      yield put(apiSuccess(PAYMENT_STATUS.FETCH_INITIAL_DATA_SUCCESS, response));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { msg: response.errorMsg }));
    }
  });
}

function* getFilterData() {
  yield takeLatest(PAYMENT_STATUS.GET_FILTER_DATA, function* () {
    const holding = yield select(getHolding);
    const path = `api/v1/payment-status/list-filter-values/${holding}`;
    const response = yield get(path);
    if (!response.error) {
      yield put(apiSuccess(PAYMENT_STATUS.GET_FILTER_DATA_SUCCESS, response));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { msg: response.errorMsg }));
    }
  });
}

function* changePage() {
  yield takeLatest(PAYMENT_STATUS.CHANGE_PAGE, function* (action) {
    const page = action.payload;
    const response = yield requestPaymentsStatus(page);

    if (!response.error) {
      yield put(apiSuccess(PAYMENT_STATUS.CHANGE_PAGE_SUCCESS, response));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { msg: response.errorMsg }));
    }
  });
}

function* fetchWithFilter() {
  yield takeLatest(PAYMENT_STATUS.FETCH_DATA_WITH_FILTER, function* () {
    yield put(APP.appActions.setLoader('find-button', true));
    const page = 1;
    const response = yield requestPaymentsStatus(page);
    if (!response.error) {
      yield put(apiSuccess(PAYMENT_STATUS.FETCH_DATA_WITH_FILTER_SUCCESS, response));
      yield put(apiSuccess(APP.SET_SUCCESS, { msg: 'Filtrado con éxito' }));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { msg: response.errorMsg }));
    }
    yield put(APP.appActions.setLoader('find-button', false));
  });
}

function* clearControls() {
  yield takeLatest(PAYMENT_STATUS.CLEAR_CONTROLS, function* () {
    yield put(APP.appActions.setLoader('clear-button', true));
    const page = 1;
    const response = yield requestPaymentsStatus(page);
    if (!response.error) {
      yield put(apiSuccess(PAYMENT_STATUS.CLEAR_CONTROLS_SUCCESS, response));
      yield put(apiSuccess(APP.SET_SUCCESS, { msg: 'Filtros limpiados con éxito' }));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { msg: response.errorMsg }));
    }
    yield put(APP.appActions.setLoader('clear-button', false));
  });
}

function* closePrevious() {
  yield takeLatest(PAYMENT_STATUS.CLOSE_PREVIOUS, function* () {
    yield put(APP.appActions.setLoader('close-previous', true));
    const holdingId = yield select(getHolding);
    const response = yield post('api/v1/payment-status/close-previous', { holdingId });
    if (!response.error) {
      yield put(apiSuccess(PAYMENT_STATUS.CLOSE_PREVIOUS_SUCCESS, response));
      yield put(apiSuccess(APP.SET_SUCCESS, { msg: 'Estados de pago cerrados' }));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { msg: response.errorMsg }));
    }
    yield put(APP.appActions.setLoader('close-previous', false));
  });
}

function* closeCurrent() {
  yield takeLatest(PAYMENT_STATUS.CLOSE_CURRENT, function* () {
    yield put(APP.appActions.setLoader('close-current', true));
    const holdingId = yield select(getHolding);
    const response = yield post('api/v1/payment-status/close-current', { holdingId });
    if (!response.error) {
      yield put(apiSuccess(PAYMENT_STATUS.CLOSE_CURRENT_SUCCESS, response));
      yield put(apiSuccess(APP.SET_SUCCESS, { msg: 'Estados de pago cerrados' }));
    } else {
      yield put(apiSuccess(PAYMENT_STATUS.CLOSE_CURRENT_ERROR));
      yield put(apiSuccess(APP.SET_ERROR, { msg: response.errorMsg }));
    }
    yield put(APP.appActions.setLoader('close-current', false));
  });
}

export default function* root() {
  yield spawn(getInitialData);
  yield spawn(getFilterData);
  yield spawn(fetchWithFilter);
  yield spawn(clearControls);
  yield spawn(changePage);
  yield spawn(closePrevious);
  yield spawn(closeCurrent);
}
