import colors from './colors';

// Para arreglar la coma flotante al redondear ciertos valores.
const fpFix = (n) => Math.round(n * 100) / 100;

const memoize = (fn) => {
  const cache = {};
  return (...args) => {
    const n = args[0];
    if (n in cache) {
      return cache[n];
    }

    const result = fn(...args);
    cache[n] = result;
    return result;
  };
};

export const createStylePatten = memoize(
  (prefix, attr, length, multiply = 10, unit = 'px', important = false) => {
    const keysArr = [...Array(length).keys()];
    const attrsArr = keysArr.map((k) => {
      const key = `${prefix}${fpFix((k + 1) * multiply)
        .toString()
        .replace('.', '')}`;
      const value = `${fpFix((k + 1) * multiply)}${unit}${important ? ' !important' : ''}`;
      return { [key]: { [attr]: value } };
    });
    return Object.assign({}, ...attrsArr);
  },
);

const mt = createStylePatten('mt', 'marginTop', 11);
const mb = createStylePatten('mb', 'marginBottom', 10);
const p = createStylePatten('p', 'padding', 11);
const pb = createStylePatten('pb', 'paddingBottom', 11);
const h = createStylePatten('h', 'height', 201, 5);
const minh = createStylePatten('minh', 'minHeight', 201, 5);
const w = createStylePatten('w', 'width', 201, 5);

const ml = createStylePatten('ml', 'marginLeft', 101, 5, 'px', true);
const mr = createStylePatten('mr', 'marginRight', 101, 5);
const opacity = createStylePatten('opacity', 'opacity', 10, 0.1, '');
const weight = createStylePatten('weight', 'fontWeight', 9, 100, '');

const additionalStyles = {
  fullHeight: { height: '100%' },
  fullWidth: { width: '100%' },
  colorPrimary: { color: colors.primary },
  flex1: { flex: '1' },
  absolute: { position: 'absolute' },
  relative: { position: 'relative' },
  bgOrange: { background: 'orange' },
  bgPurple: { background: 'purple' },
  bgPink: { background: 'pink' },
  bgPrimary: { background: colors.primary },
};

const obj = {
  ...mt,
  ...mb,
  ...p,
  ...pb,
  ...h,
  ...minh,
  ...w,
  ...ml,
  ...mr,
  ...opacity,
  ...weight,
  ...additionalStyles,
};

export default obj;
