const styles = (theme) => ({
  container: {
    display: 'flex',
    // margin: theme.spacing(4),
    minWidth: 564,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      minWidth: 0,
    },
  },
  detail: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#ebeef2',
    minWidth: 200,
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
    },
  },
  date: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 300,
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
    },
  },
  icon: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(1),
  },
  dateItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'start',
    },
  },
  dateText: {
    fontWeight: 900,
    fontSize: '24px',
    marginLeft: '8px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '40px',
    },
  },
  closeIconMobile: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'end',
      flexDirection: 'column',
      padding: theme.spacing(1),
    },
  },
  closeIcon: {
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  button: {
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(2),
    },
  },
  statusIcon: {
    color: 'green',
  },
  statusIcon2: {
    color: 'red',
  },
  calendar: {
    marginRight: '8px',
  },
  text: {
    overflowX: 'auto',
  },
});

export default styles;
