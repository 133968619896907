import { alpha } from '@material-ui/core/styles/colorManipulator';

const styles = (theme) => ({
  back1: {
    backgroundColor: theme.palette.primary.secondary,
    marginBottom: theme.spacing(1),
    borderRadius: 0,
    cursor: 'pointer',
    paddingLeft: 8,
    paddingRight: 8,
  },
  back2: {
    backgroundColor: alpha(theme.palette.primary.main, 0.08),
    marginBottom: theme.spacing(1),
    borderRadius: 0,
    cursor: 'pointer',
    paddingLeft: 8,
    paddingRight: 8,
  },
  back3: {
    backgroundColor: theme.palette.primary.main,
    marginBottom: theme.spacing(1),
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    paddingLeft: 8,
    paddingRight: 8,
  },
  whiteTypography: {
    color: '#FFFFFF',
  },
  titleIcon: {
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  cardTitle: {
    fontWeight: 600,
  },
  grid: {
    margin: 4,
  },
  grid2: {
    margin: 0,
  },
});

export default styles;
