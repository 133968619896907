const styles = (theme) => ({
  dialogContainer: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
  mobileHeader: {
    marginBottom: theme.spacing(2),
  },
  iconButton: {
    transform: 'scale(1.7)',
  },
  inputs: {
    '& > *': {
      marginTop: theme.spacing(1.5),
    },
  },
  filterButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: '#fff',
    color: theme.palette.primary.main,
    display: 'flex',
    '&  > *': {
      justifyContent: 'space-between',
    },
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
});

export default styles;
