import React, { useCallback, useEffect, useMemo } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import Form from 'screens/UserSetting/Components/Form';
import {
  composedComponent,
  validateEmail,
} from 'utils/functions';
import saga from 'screens/UserSetting/saga';
import { userSettingActions } from 'screens/UserSetting/reducer';
import { appActions } from 'commons/reducer';
import useForm from 'utils/hooks/useForm';

const UserSetting = (props) => {
  const {
    actions,
    roles,
    holdings, // Objects.holdings
    contractAdmins,
    formControls,
    initialLoading,
    loading,
    error,
    success,
    match,
  } = props;

  const history = useHistory();
  const { id } = match.params;

  useEffect(() => {
    actions.fetchData();
  }, []);

  useEffect(() => {
    if (id) {
      actions.fetchUser(id);
    }
  }, [id]);

  useEffect(() => {
    if (success) {
      actions.resetControls();
      history.push('/user');
    }
  }, [success]);

  useEffect(() => {
    actions.resetControls();
  }, []);

  const rolesTypes = useMemo(() => Object.values(roles).map((t, i) => ({
    id: i,
    value: t,
    label: t.replace(/_/g, ' '),
  })), [roles]);

  const holdingsData = useMemo(() => Object.values(holdings).filter((h) => h.isActive).map((h) => ({
    label: h.name,
    value: h.id,
  })), [holdings]);

  const changeControls = useCallback((name, value) => {
    actions.changeControls({ [name]: value });
  }, []);

  const { onChange, onSubmit, errors } = useForm(formControls, null, {
    onChange: changeControls,
    validations: {
      rol: [
        {
          check: (value) => !!value && value.trim().length > 0,
          message: 'El Rol es requerido',
        },
      ],
      name: [
        {
          check: (value) => !!value && value.trim().length > 0,
          message: 'El Nombre es requerido',
        },
      ],
      lastName: [
        {
          check: (value) => !!value && value.trim().length > 0,
          message: 'El Apellido es requerido',
        },
      ],
      email: [
        {
          check: (value) => !!value && value.trim().length > 0,
          message: 'El Email es requerido',
        },
        {
          check: (value) => validateEmail(value),
          message: 'EL Mail es inválido',
        },
      ],
      phone: [
        {
          check: (value) => !!value && value.trim().length > 0,
          message: 'El Teléfono es requerido',
        },
        {
          check: (value) => value.length === 12,
          message: 'El Teléfono es inválido. Utilice el formato +56912345678',
        },
      ],
      holdings: [
        {
          check: (value) => formControls.rol !== 'ADMINISTRADOR_DE_CONTRATO' || value.length > 0,
          message: 'El Holding es requerido',
        },
      ],
      holding: [
        {
          check: (value) => !['GESTOR_DE_FLOTA/DECISOR', 'ENCARGADO_DE_FLOTA/SECCION'].includes(formControls.rol) || (value),
          message: 'El Holding es requerido',
        },
      ],
    },
  });

  const handleSubmitCreate = (e) => {
    e.preventDefault();
    onSubmit(() => actions.createUser(formControls));
  };

  const handleSubmitEdit = (e) => {
    e.preventDefault();
    onSubmit(() => actions.editUser(formControls));
  };

  const handleSelectAdminHoldings = (adminId) => {
    if (!adminId) return;

    actions.fetchUserHoldings(adminId);
  };

  const handleToggleAdminHolding = (hid) => {
    actions.toggleTempHolding(hid);
  };

  return (
    <Switch>
      <Route
        exact
        path="/user/create"
        render={() => (
          <Form
            isEdit={false}
            contractAdmins={contractAdmins}
            onSelectAdmin={handleSelectAdminHoldings}
            toggleHolding={handleToggleAdminHolding}
            onAddHoldingsTemplate={actions.addTempHoldings}
            resetTempHoldings={actions.resetTempHoldings}
            fetchContractAdmins={actions.fetchContractAdmins}
            buttonMessage="Crear usuario"
            roles={rolesTypes}
            holdings={holdingsData}
            controls={formControls}
            onChange={onChange}
            initialLoading={initialLoading}
            onSubmit={handleSubmitCreate}
            errors={errors}
            loading={loading}
            error={error}
          />
        )}
      />
      <Route
        exact
        path="/user/edit/:id"
        render={() => (
          <Form
            isEdit
            contractAdmins={contractAdmins}
            onSelectAdmin={handleSelectAdminHoldings}
            toggleHolding={handleToggleAdminHolding}
            onAddHoldingsTemplate={actions.addTempHoldings}
            resetTempHoldings={actions.resetTempHoldings}
            fetchContractAdmins={actions.fetchContractAdmins}
            buttonMessage="Editar usuario"
            roles={rolesTypes}
            holdings={holdingsData}
            controls={formControls}
            onChange={onChange}
            initialLoading={initialLoading}
            onSubmit={handleSubmitEdit}
            errors={errors}
            loading={loading}
            error={error}
          />
        )}
      />
    </Switch>
  );
};

export default composedComponent(UserSetting, saga, {
  states: [
    'userSetting.error',
    'userSetting.loading',
    'userSetting.initialLoading',
    'userSetting.success',
    'userSetting.formControls',
    'userSetting.roles',
    'userSetting.contractAdmins',
    'app.objects.holdings',
  ],
  actions: [userSettingActions, appActions],
  saga: 'userSettingSaga',
});
