const styles = () => ({
  container: {
    height: '750px',
    marginTop: '20px',
  },
  iframeContainer: {
    width: '100%',
    height: '100%',
    '& iframe': {
      width: '100%',
      height: '100%',
    },
  },
});

export default styles;
