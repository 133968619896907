import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import Grid from '@material-ui/core/Grid';
import {
  Box, Typography, useMediaQuery, useTheme,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import DialogFilter from 'commons/components/DialogFilter';
import ServiceList from 'screens/Service/component/List';
import CustomOrder from 'commons/components/CustomOrder';
import Detail from 'screens/Service/component/Detail';
import DownloadButton from 'commons/components/DownloadButton';
import {
  composedComponent,
  useQuery,
} from 'utils/functions';
import { workOrderActions } from 'screens/WorkOrder/reducer';
import useSocialReasonFilter from 'commons/components/MultipleFilter/useSocialReasonFilter';
import { screenTime } from 'utils/utils';
import Skeleton from './component/Skeleton';
import { serviceActions } from './reducer';

import ScreenTitle from '../../commons/components/ScreenTitle';
import IndicatorZone from './component/IndicatorZone';

import Filter from './component/Filter';
import saga from './saga/index';
import styles from './styles';

const useStyles = makeStyles(styles);

const filterOptions = [
  {
    key: 1,
    value: 'name',
    label: 'Cliente',
  },
  {
    key: 2,
    value: 'licensePlate',
    label: 'Vehículo',
  },
  {
    key: 3,
    value: 'driverName',
    label: 'Conductor',
  },
  {
    key: 4,
    value: 'responsibleName',
    label: 'Responsable',
  },
  {
    key: 5,
    value: 'workshopName',
    label: 'Taller',
  },
  {
    key: 6,
    value: 'wantedDateStart',
    label: 'Inicio estimado',
  },
  {
    key: 7,
    value: 'wantedDateEnd',
    label: 'Salida estimada',
  },
];

const Service = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [serviceId, setServiceId] = useState(null);

  const {
    actions,
    controls,
    objects,
    order,
    isOpen,
    openDetail,
    workshops,
    pagination,
    holding,
    kpi,
    loading,
    loadingData,
    socialReason,
    loadingDownload,
    history,
  } = props;

  const { optionsSocialReason } = useSocialReasonFilter(objects.socialReasons);
  const selectValue = controls.socialReason;
  const handleChangeSocialReason = ({ value }) => actions.changeControls({ key: 'socialReason', value });

  const query = useQuery();

  useEffect(() => {
    if (history.location.state?.filterState) {
      actions.changeControls({ key: 'status', value: [true] });
      actions.fetchWithFilter();
    }
  }, []);

  useEffect(() => screenTime('services'), []);

  useEffect(() => {
    const currentHolding = holding?.id;
    const queryHolding = Number.parseInt(query.get('h'), 10);
    const vehicle = Number.parseInt(query.get('vehicle'), 10);

    if (holding?.id) {
      if (currentHolding !== queryHolding) {
        actions.getInitialData();
        if (!history.location.state?.filterState) actions.resetControl();
      } else {
        actions.getInitialData([vehicle]);
      }
    }
  }, [holding, socialReason]);

  const vehicles = useMemo(() => Object.values(objects.vehicles).map((h) => ({
    value: h.id,
    label: h.licensePlate,
  })), [objects.vehicles]);

  const dataWorkshops = useMemo(() => Object.values(workshops).map((w) => ({
    value: w,
    label: w,
  })), [workshops]);

  const fleets = useMemo(() => Object.values(objects.fleets).map((h) => ({
    value: h.id,
    label: h.name,
  })), [objects.fleets]);

  const services = useMemo(() => (Object.values(objects.services).length > 0
    ? order?.services?.map((s) => objects.services[s]).filter((f) => f)
    : []),
  [objects.services]);

  const filterServices = () => {
    actions.fetchWithFilter();
  };

  const handleOpenDetail = (id) => {
    setServiceId(id);
    actions.openDetail();
  };

  const handleChangePage = (page) => {
    actions.changePage(page);
  };

  const handleClearFilter = useCallback(() => {
    actions.clearControls();
  }, []);

  const handleOrderBy = (value) => {
    actions.setOrderBy(value);
  };

  const handleChangeOrderBy = () => {
    actions.changeOrderBy();
  };

  const handleDownload = () => {
    actions.downloadExcel();
  };

  return (
    !loadingData || loading ? <Skeleton />
      : (
        <>
          <Grid
            container
            justifyContent="space-between"
          >
            <Grid item>
              <ScreenTitle title="DETENCIONES" />
            </Grid>
            <Grid item>
              <Typography color="primary" variant="h4">
                <Box
                  display="flex"
                  flexDirection="columns"
                  alignItems="center"
                  justifyContent="start"
                >
                  <Box fontWeight={500} fontSize={18}>
                    <Link to="/work-orders" className={classes.link}>VER TIPOS DE DETENCIÓN</Link>
                  </Box>
                  <ArrowForwardIosIcon color="primary" fontSize="medium" />
                </Box>
              </Typography>
            </Grid>
          </Grid>
          <Box className={classes.exteriorSection}>
            <Box className={classes.insideSection}>
              <IndicatorZone kpi={kpi} />
              {!isMobile
                ? (
                  <Filter
                    vehicles={vehicles}
                    workshops={dataWorkshops}
                    fleets={fleets}
                    controls={controls}
                    onChange={actions.changeControls}
                    filterServices={filterServices}
                    handleClearFilter={handleClearFilter}
                    handleChangeSocialReason={handleChangeSocialReason}
                    optionsSocialReason={optionsSocialReason}
                    selectValue={selectValue}
                  />
                )
                : (
                  <DialogFilter
                    isOpen={isOpen}
                    open={actions.openFilter}
                    close={actions.closeFilter}
                  >
                    <Filter
                      vehicles={vehicles}
                      workshops={dataWorkshops}
                      fleets={fleets}
                      controls={controls}
                      onChange={actions.changeControls}
                      filterServices={filterServices}
                      handleClearFilter={handleClearFilter}
                      socialReasons={objects.socialReasons}
                      currentSocialReason={socialReason}
                    />
                  </DialogFilter>
                )}
            </Box>
          </Box>

          <Grid container>
            <Grid item xs={12} md={6} align="left">
              <CustomOrder
                orderByValue={controls.orderBy}
                options={filterOptions}
                onChange={handleOrderBy}
                ChangeOrderBy={handleChangeOrderBy}
              />
            </Grid>
            <Grid item xs={12} md={6} align="right">
              <DownloadButton
                total={services.length}
                onClick={handleDownload}
                loading={loadingDownload}
              />
            </Grid>
          </Grid>
          <ServiceList
            services={services}
            pagination={pagination}
            handleOpenDetail={handleOpenDetail}
            handleChangePage={handleChangePage}
            isMobile={isMobile}
          />
          {objects.services[serviceId]
      && (
      <Detail
        open={openDetail}
        service={objects?.services[serviceId]}
        handleClose={actions.closeDetail}
        actions={actions}
      />
      )}
        </>
      )
  );
};

export default composedComponent(Service, saga, {
  saga: 'sagaService',
  states: [
    'service.loading',
    'service.loadingData',
    'service.controls',
    'service.pagination',
    'service.isOpen',
    'service.openDetail',
    'service.workshops',
    'service.kpi',
    'service.query',
    'service.loadingDownload',
    'app.objects',
    'app.order',
    'app.holding',
    'app.socialReason',
  ],
  actions: [serviceActions, workOrderActions],
});
