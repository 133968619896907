export const compareStrings = (key, desc) => (a, b) => {
  if (desc) {
    return a[key].localeCompare(b[key], 'es', { sensitivity: 'base' });
  }

  return b[key].localeCompare(a[key], 'es', { sensitivity: 'base' });
};

const compareNums = (key, desc) => (a, b) => {
  const numA = parseInt(a[key], 10);
  const numB = parseInt(b[key], 10);

  if (numA < numB) return desc ? -1 : 1;
  if (numA > numB) return desc ? 1 : -1;

  return 0;
};

export const compareDates = (key, desc) => (a, b) => {
  const dateA = new Date(a[key]);
  const dateB = new Date(b[key]);

  if (dateA < dateB) return desc ? -1 : 1;
  if (dateA > dateB) return desc ? 1 : -1;

  return 0;
};

const compareRut = (key, desc) => (a, b) => {
  const numA = parseInt(a[key].replace(/\.|-/g, '').replace(/k/i, '0'), 10);
  const numB = parseInt(b[key].replace(/\.|-/g, '').replace(/k/i, '0'), 10);

  if (numA < numB) return desc ? -1 : 1;
  if (numA > numB) return desc ? 1 : -1;

  return 0;
};

const compareBoolean = (key, desc) => (a, b) => {
  if (a[key] < b[key]) return desc ? -1 : 1;
  if (b[key] < a[key]) return desc ? 1 : -1;

  return 0;
};

const sortHoldingsBy = (holdings, key, desc) => {
  if (key === 'createdAt') return [...holdings].sort(compareDates(key, desc));
  if (key === 'rut') return [...holdings].sort(compareRut(key, desc));
  if (key === 'count' || key === 'id') return [...holdings].sort(compareNums(key, desc));
  if (key === 'isActive') return [...holdings].sort(compareBoolean(key, desc));

  return [...holdings].sort(compareStrings(key, desc));
};

export default sortHoldingsBy;
