import {
  Box, useMediaQuery, useTheme,
} from '@material-ui/core';
import React from 'react';

// Material
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

// Project
import SwapVertIcon from '@material-ui/icons/SwapVert';

// icons
import ImportExportIcon from '@material-ui/icons/ImportExport';
import DownloadButton from 'commons/components/DownloadButton';
import type from 'utils/type';
import styles from './styles';

const useStyles = makeStyles(styles);

const headers = [
  { label: 'Patente', key: 'licensePlate', width: 120 },
  { label: 'Vehículo', key: 'model', width: 370 },
  { label: 'Asignación', key: 'fleet', width: 200 },
  { label: 'Encargado', key: 'assignedToRun', width: 200 },
  { label: 'Vigencia', key: 'dueDate', width: 215 },
  { label: 'Mantención', key: 'km', width: 140 },
];

const Header = ({
  setOrderBy, changeOrderby, label, keyValue, orderByValue, width,
}) => {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      flexDirection="row"
      minWidth={width}

    >
      <Button
        fullWidth
        color="primary"
        className={classes.button}
        size="small"
        endIcon={<ImportExportIcon className={classes.icon} />}
        onClick={() => {
          if (orderByValue === keyValue) {
            changeOrderby();
          } else if (orderByValue === '' || orderByValue !== keyValue) {
            setOrderBy(keyValue);
          }
        }}
      >
        <Box fontWeight={700} color="white">
          {label}
        </Box>
      </Button>
    </Box>
  );
};

const HeaderList = ({
  setOrderBy, changeOrderby, orderByValue = null, loading, handleDownload,
  total, handleDownloadDuplicated, role,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (!isMobile ? (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      className={classes.root}
    >
      {headers.map((header) => (
        <Header
          orderByValue={orderByValue}
          setOrderBy={setOrderBy}
          changeOrderby={changeOrderby}
          label={header.label}
          keyValue={header.key}
          key={header.key}
          width={header.width}
        />
      ))}
    </Box>
  ) : (
    <Box>
      <Box display="flex" className={classes.mobileOrderFilter}>
        <FormControl variant="outlined" fullWidth className={classes.mobileOrderInput}>
          <InputLabel htmlFor="outlined-age-native-simple">Ordenar Por</InputLabel>
          <Select
            value={orderByValue}
            color="primary"
            onChange={(e) => setOrderBy(e.target.value)}
            label="Ordernar por"
            inputProps={{
              name: 'Ordenar por',
              id: 'outlined-age-native-simple',
            }}
          >
            <MenuItem aria-label="None" value="">Ninguno</MenuItem>
            <MenuItem value="licensePlate">Patente</MenuItem>
            <MenuItem value="dada">Asignacion</MenuItem>
            <MenuItem value="dueDate">Fecha vencimiento</MenuItem>
          </Select>
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => changeOrderby()}
        >
          <SwapVertIcon />
        </Button>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        width="100%"
      >
        <Box
          className={classes.mobileListTitle}
        >
          <Typography variant="h5" color="primary">
            Listado de vehículos
          </Typography>
        </Box>
        <Box>
          {role === type.ADMINISTRADOR_PORTAL_SALFA && (
          <DownloadButton
            total={total}
            onClick={handleDownloadDuplicated}
            loading={loading}
            text="Descargar duplicados"
          />
          )}
          <DownloadButton
            total={total}
            onClick={handleDownload}
            loading={loading}
          />
        </Box>
      </Box>
    </Box>
  ));
};

export default HeaderList;
