import React from 'react';
import {
  Checkbox,
  //  Divider,
  FormControlLabel, FormGroup, Grid,
  // Hidden,
  Typography,
} from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';
// import CustomTextField from 'commons/components/CustomTextField';
import ButtonWithLoader from 'commons/containers/ButtonWithLoader';

const Form = ({
  classes, controls, handleFieldChange, handleSubmit, width,
}) => {
  const labels = {
    brand: 'Marca',
    model: 'Modelo',
    fleetName: 'Flota',
    monthDeadline: 'Mes/Plazo',
    currency: 'Moneda',
    totalValue: 'Valor base',
    totalDays: 'Días arriendo',
    additionals: 'Cargo adicional',
    discounts: 'Descuento',
  };
  const fieldsList = Object.entries(controls.fields)
    .map(([key, checked]) => ({ checked, name: key, label: labels[key] }));

  // const fleets = ['Flota por defecto', 'Zona Centro', 'Zona Norte'];
  const isMobileWidth = () => width === 'xs' || width === 'sm';

  return (
    <div className={classes.formRoot}>
      <Typography variant="h4" color="primary" className={classes.mb20}>Campos del estado de pago</Typography>
      <FormGroup row={!isMobileWidth()} className={classes.checkbox}>
        {fieldsList.map((field) => (
          <FormControlLabel
            key={field.name}
            control={(
              <Checkbox
                color="primary"
                checked={field.checked}
                onChange={handleFieldChange}
                name={field.name}
              />
                )}
            label={field.label}
          />
        ))}
      </FormGroup>
      {/* <Typography variant="h4"
       style={{ marginBottom: '18px' }}>Encargados de las flotas</Typography>
      <Grid container style={{ marginBottom: '24px' }}>
        { fleets.map((fleet) => (
          <React.Fragment key={fleet}>
            <Grid container item xs={12} style={{ borderBottom: '0px solid grey' }}>
              <Grid item xs={12} md={6}>
                <Typography variant="h6" style={{ fontSize: '16px' }}>
                  {fleet}
                  :
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  id="backup"
                  name="backup"
                  label="Email"
                  type="text"
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: '12px', marginBottom: '12px' }}>
              <Hidden smDown>
                <Divider />
              </Hidden>
            </Grid>
          </React.Fragment>
        ))}
      </Grid>

      <Typography variant="h4" style={{ marginBottom: '12px' }}>Cuenta backup</Typography>
      <Grid container xs={12} sm={6} md={6}
      lg={4} style={{ paddingRight: '16px', marginBottom: '18px' }}>
        <CustomTextField
          id="backup"
          name="backup"
          label="Email"
          type="text"
          variant="outlined"
          // value={controls.backup}
          // onChange={onChange}
          // error={!!errors.backup}
          // helperText={errors.backup && errors.backup[0]}
          // disabled={isEdit}
        />
      </Grid> */}
      <Grid container justifyContent="center">
        <ButtonWithLoader
          id="submit_settings"
          variant="contained"
          color="secondary"
          size="large"
          onClick={handleSubmit}
        >
          Guardar cambios
        </ButtonWithLoader>
      </Grid>
    </div>
  );
};

export default withWidth()(Form);
