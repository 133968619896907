import React from 'react';
import { TextField } from '@material-ui/core';

const TextFieldFilled = ({
  id,
  name,
  label,
  value,
  error,
  inputPropsClassName,
  helperText,
  disabled = false,
  type = 'text',
  onChange = (e) => e.preventDefault(),
}) => (
  <TextField
    id={id}
    name={name}
    value={value}
    label={label}
    type={type}
    InputProps={{ className: inputPropsClassName }}
    disabled={disabled}
    fullWidth
    error={error}
    helperText={helperText}
    variant="filled"
    size="small"
    onChange={onChange}
  />
);

export default TextFieldFilled;
