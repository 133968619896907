import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Skeleton } from '@material-ui/lab';
import Typography from '@material-ui/core/Typography';

const UserSkeleton = () => {
  const Select = () => (
    <Grid item xs={3}>
      <Skeleton variant="text" height={93} />
    </Grid>
  );

  const Card = () => (
    <Grid item xs={12}>
      <Skeleton variant="rect" width="100%" height={93} />
    </Grid>
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography component="div" variant="h4">
          <Skeleton width={370} />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Select />
          <Select />
          <Select />
          <Select />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Card />
          <Card />
          <Card />
          <Card />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UserSkeleton;
