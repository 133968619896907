import React, { useEffect, useMemo } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid, IconButton, InputLabel, MenuItem, Select, TextField, Typography, makeStyles,
} from '@material-ui/core';
// Icons
import CloseIcon from '@material-ui/icons/Close';

import CustomTextField from 'commons/components/CustomTextField';
import ButtonWithLoader from 'commons/containers/ButtonWithLoader';
import { simulateEvent } from 'utils/functions';
import { Alert, Autocomplete } from '@material-ui/lab';
import { Link } from 'react-router-dom';
import styles from './styles';

const useStyle = makeStyles(styles);

const FleetForm = (props) => {
  const {
    open, controls, errors, onChange, handleClose,
    handleCreateFleet, handleEditFleet, resetErrors, availableFleetAdmins, allFleetAdmins,
    socialReasons,
  } = props;
  const { inputFleet, selectedFleet, socialReasonId } = controls;
  const classes = useStyle();

  useEffect(() => {
    if (!open) resetErrors();
  }, [open, resetErrors]);

  const optionsSocialReason = useMemo(() => Object.values(socialReasons).map((sr) => ({
    label: sr.name,
    value: sr.id,
  })), [socialReasons]);

  const selectValue = useMemo(() => optionsSocialReason
    .find((o) => o.value === socialReasonId) ?? null,
  [socialReasonId]);

  const handleChangeUser = (selectedUserId) => {
    onChange(simulateEvent('userId', 'string', selectedUserId));
  };

  const handleChangeSocialReason = (e, data) => {
    const selectedSocialReasonId = Number(data?.value) || '';
    onChange(simulateEvent('socialReasonId', 'string', selectedSocialReasonId));
  };

  // We adding the current fleet admin to the list if user keeps the same fleet admin.
  const fleetAdminsOptions = selectedFleet?.userId
    ? [...availableFleetAdmins, allFleetAdmins.find((user) => user.id === selectedFleet.userId)]
    : availableFleetAdmins;

  const oldSocialReasonId = selectedFleet?.socialReason?.id;

  return (
    <Dialog
      open={open}
      fullWidth
    >
      <Grid container>
        <Grid item xs={11}>
          <DialogTitle>{`${controls?.editing ? 'Editar' : 'Crear'} flota`}</DialogTitle>
        </Grid>
        <Grid item xs={1}>
          <IconButton
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
      <DialogContent>
        <Grid container className={classes.fleetGrid}>
          <CustomTextField
            id="inputFleet"
            autoFocus
            color="primary"
            value={inputFleet}
            onChange={onChange}
            required
            label="Ingrese el nuevo nombre"
            variant="outlined"
            error={!!errors.inputFleet}
            helperText={errors.inputFleet && errors.inputFleet[0]}
          />
        </Grid>

        <Grid container className={classes.fleetGrid}>
          <Autocomplete
            fullWidth
            size="small"
            id="socialReason"
            name="socialReason"
            value={selectValue}
            options={optionsSocialReason}
            onChange={handleChangeSocialReason}
            getOptionLabel={(option) => (option.label ? option.label : '')}
            disabled={!!(controls.editing && oldSocialReasonId)}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                className={classes.autoComplete2}
                variant="outlined"
                label="Razón Social"
                error={!!errors.socialReasonId}
                helperText={errors.socialReasonId && errors.socialReasonId[0]}
              />
            )}
          />
        </Grid>

        <FormControl
          fullWidth
          variant="outlined"
          size="small"
        >
          <InputLabel
            className={classes.inputLabel}
            id="select-user-label"
          >
            Seleccione un encargado de flota

          </InputLabel>
          <Select
            color="primary"
            className={classes.select}
            labelId="select-user-label"
            id="select-user"
            value={controls.userId}
            onChange={(e) => { handleChangeUser(e.target.value); }}
            label="Seleccione un encargado de flota"
            error={!!errors.userId}
          >
            <MenuItem key="" value="">
              <em>Ninguno</em>
            </MenuItem>
            {fleetAdminsOptions
              ? fleetAdminsOptions
                .map((f) => <MenuItem key={f.id} value={f.id}>{f.email}</MenuItem>)
              : []}
          </Select>
        </FormControl>
        <FormHelperText
          id="userIdError"
          className={classes.formHelperText}
        >
          {errors?.userId && errors?.userId[0]}
        </FormHelperText>

        <div className={classes.fleetDiv}>
          <Alert severity="info">
            <Typography>
              Para agregar nuevos encargados de flota,
              ingrese
              {' '}
              <Link className={classes.link} to="/user/create">acá</Link>
              . Luego regrese a este formulario.
            </Typography>
          </Alert>
        </div>

      </DialogContent>
      <DialogActions>
        <div className={classes.fleetDiv2}>
          { controls.creating && (
          <ButtonWithLoader
            buttonId="create-fleet"
            onClick={handleCreateFleet}
          >
            Crear flota
          </ButtonWithLoader>
          )}
          { controls.editing && (
          <ButtonWithLoader
            buttonId="edit-fleet"
            onClick={handleEditFleet}
          >
            Editar datos de flota
          </ButtonWithLoader>
          )}
        </div>
      </DialogActions>
    </Dialog>
  );
};
export default FleetForm;
