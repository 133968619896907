import React from 'react';
import {
  Card, Chip, Grid, Step, StepLabel, Stepper, Tooltip,
} from '@material-ui/core';

// Material
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

// icons
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment';
import {
  PAYMENT_STATUS_FLEET,
  PAYMENT_STATUS_STATUS,
} from 'utils/enums';
import type from 'utils/type';
import styles from './style';
import stylesTooltip from './styleTooltip';

const useStyles = makeStyles(styles);
const useStylesTooltip = makeStyles(stylesTooltip);

const CardItem = ({
  title, content,
}) => {
  const classes = useStyles();
  return (
    <Grid item xs={12} className={classes.cardGrid}>
      <Typography display="inline" className={classes.cardTitle}>
        {title}
      </Typography>
      <Typography display="inline">
        {content}
      </Typography>
    </Grid>
  );
};

const ClientDetailsCard = ({ data, role }) => {
  const classes = useStyles();
  const classesTooltip = useStylesTooltip();

  const statusStepper = ['No inicializado', 'Borrador', 'Revisión', 'Aprobado', 'Cerrado'];

  const isSalfaUser = role === type.ADMINISTRADOR_PORTAL_SALFA
    || role === type.ADMINISTRADOR_DE_CONTRATO;

  const message = (fleetStatus) => {
    switch (fleetStatus) {
      case 0:
        return '';
      case 1:
        return '';
      case 2:
        return isSalfaUser ? '- Aprobación en espera' : '- Requiere revisión';
      case 3:
        return isSalfaUser ? '- Requiere revisión' : '- Corrección en espera';
      case 4:
        return '- Aprobada';
      default:
        return '';
    }
  };

  const tooltipTitleColor = (fleet) => {
    if (isSalfaUser) return fleet.status === PAYMENT_STATUS_FLEET.ADMIN_REVIEW ? 'secondary' : 'primary';
    return fleet.status === PAYMENT_STATUS_FLEET.CUSTOMER_REVIEW ? 'secondary' : 'primary';
  };

  const titleTooltip = data.status === PAYMENT_STATUS_STATUS.REVIEW ? (
    <>
      {data.fleets?.map((fleet) => (
        <Typography key={fleet.id} color={tooltipTitleColor(fleet)} className={classes.cardTitle}>
          Flota
          {' '}
          {fleet.name !== '' ? fleet.name : 'Vehículos sin flota '}
          {' '}
          {message(fleet.status)}
        </Typography>
      ))}
    </>
  ) : '';

  return (
    <>
      <Typography
        variant="h6"
        color="primary"
        className={classes.listTitle}
      >
        ESTADO DE PAGO
      </Typography>
      <Card key={data.id} className={classes.back1}>
        <Grid container alignItems="flex-start" className={classes.back1}>
          <Grid item md={3} xs={12} container>
            <CardItem title="CLIENTE: " content={data.client} />
            <CardItem title="RUT: " content={data.rut} />
            <CardItem title="PERÍODO: " content={moment.utc(data.startDate).format('MM-YYYY')} />
            <CardItem title="FECHA DE EMISIÓN: " content={moment.utc(data.releaseDate).format('DD-MM-YYYY')} />
          </Grid>
          <Grid item md={3} xs={12} container>
            <CardItem title="HES: " content={data.hes || 'Sin código HES'} />
            <CardItem title="OC: " content={data.oc || 'Sin número de OC'} />
            <CardItem
              md={3}
              xs={12}
              title="ARCHIVO OC: "
              content={data?.purchaseOrder?.find(Boolean) ? (
                <Chip
                  onClick={() => window.open(data?.purchaseOrder[0]?.path, '_blank', 'noopener,noreferrer')}
                  key={data?.purchaseOrder[0]?.id}
                  variant="outlined"
                  className={classes.chip}
                  label={data?.purchaseOrder[0]?.name?.slice(37, data?.purchaseOrder[0]?.name?.length) || 'Sin nombre'}
                  size="small"
                  color="primary"
                />
              ) : 'Sin adjunto'}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Stepper activeStep={data.status} alternativeLabel>
              {statusStepper.map((label, index) => (
                <Step key={label}>
                  <Tooltip
                    interactive
                    placement="top"
                    classes={classesTooltip}
                    arrow
                    title={index === PAYMENT_STATUS_STATUS.REVIEW ? titleTooltip : ''}
                  >
                    <StepLabel className={classes.label}>
                      {label}
                    </StepLabel>
                  </Tooltip>
                </Step>
              ))}
            </Stepper>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default ClientDetailsCard;
