const styles = (theme) => ({
  container: {
    marginLeft: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(4),
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  detailContainer: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(1),
    boxShadow: theme.shadows[4],
    borderRadius: 8,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  contentContainer: {
    overflowX: 'auto',
  },
  itemContainer: {
    display: 'inline-block',
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  buttonContainer: {
    marginTop: theme.spacing(2),
    float: 'right',
  },
  confirmationContainer: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    gap: theme.spacing(1),
  },
});

export default styles;
