import { createAction, createReducer } from 'utils/reducer';
import sortHoldingsBy from 'screens/HoldingMaintainer/sort';
import { filterDate, filterName, filterStatus } from '../components/Filter/functions';
import { SOCIAL_REASON_ATTR } from '../constants';

// Local constants
const PATH = 'socialReason/';

// Global constants for saga
export const SOCIAL_REASON = {
  GET_SOCIAL_REASONS: `${PATH}GET_SOCIAL_REASONS`,
  GET_SOCIAL_REASONS_SUCCESS: `${PATH}GET_SOCIAL_REASONS_SUCCESS`,
  GET_SOCIAL_REASONS_ERROR: `${PATH}GET_SOCIAL_REASONS_ERROR`,
  ADD_SOCIAL_REASON: `${PATH}ADD_SOCIAL_REASON`,
  ADD_SOCIAL_REASON_SUCCESS: `${PATH}ADD_SOCIAL_REASON_SUCCESS`,
  ADD_SOCIAL_REASON_ERROR: `${PATH}ADD_SOCIAL_REASON_ERROR`,
  UPDATE_SOCIAL_REASON: `${PATH}UPDATE_SOCIAL_REASON`,
  UPDATE_SOCIAL_REASON_SUCCESS: `${PATH}UPDATE_SOCIAL_REASON_SUCCESS`,
  REASSIGN_HOLDING: `${PATH}REASSIGN_HOLDING`,
  REASSIGN_HOLDING_SUCCESS: `${PATH}REASSIGN_HOLDING_SUCCESS`,
  REASSIGN_HOLDING_ERROR: `${PATH}REASSIGN_HOLDING_ERROR`,
  REASSIGN_HOLDING_SRS: `${PATH}REASSIGN_HOLDING_SRS`,
  REASSIGN_HOLDING_SRS_SUCCESS: `${PATH}REASSIGN_HOLDING_SRS_SUCCESS`,
  REASSIGN_HOLDING_SRS_ERROR: `${PATH}REASSIGN_HOLDING_SRS_ERROR`,
  CHANGE_PAGE: `${PATH}CHANGE_PAGE`,
  SET_MOBILE_FILTER: `${PATH}SET_MOBILE_FILTER`,
  SET_FILTER: `${PATH}SET_FILTER`,
  SET_ORDER_BY: `${PATH}SET_ORDER_BY`,
  CHANGE_ORDER_BY: `${PATH}CHANGE_ORDER_BY`,
  RESET_FILTER: `${PATH}RESET_FILTER`,
  APPLY_FILTER: `${PATH}APPLY_FILTER`,
  RESET_STATE: `${PATH}RESET_STATE`,
  RESET_CONTROLS: `${PATH}RESET_CONTROLS`,
};

//
export const socialReasonActions = {
  getSocialReasons: createAction(SOCIAL_REASON.GET_SOCIAL_REASONS, 'holdingId'),
  getSocialReasonsSuccess: createAction(SOCIAL_REASON.GET_SOCIAL_REASONS_SUCCESS, 'payload'),
  getSocialReasonsFail: createAction(SOCIAL_REASON.GET_SOCIAL_REASONS_ERROR, 'payload'),
  addSocialReason: createAction(SOCIAL_REASON.ADD_SOCIAL_REASON, 'payload'),
  addSocialReasonSuccess: createAction(SOCIAL_REASON.ADD_SOCIAL_REASON_SUCCESS, 'payload'),
  updateSocialReason: createAction(SOCIAL_REASON.UPDATE_SOCIAL_REASON, 'payload'),
  updateSocialReasonSuccess: createAction(SOCIAL_REASON.UPDATE_SOCIAL_REASON_SUCCESS, 'payload'),
  reassignHolding: createAction(SOCIAL_REASON.REASSIGN_HOLDING, 'payload'),
  reassignHoldingSuccess: createAction(SOCIAL_REASON.REASSIGN_HOLDING_SUCCESS, 'payload'),
  reassignHoldingSrs: createAction(SOCIAL_REASON.REASSIGN_HOLDING_SRS, 'payload'),
  reassignHoldingSrsSuccess: createAction(SOCIAL_REASON.REASSIGN_HOLDING_SRS_SUCCESS, 'payload'),
  changePage: createAction(SOCIAL_REASON.CHANGE_PAGE, 'payload'),
  setMobileFilter: createAction(SOCIAL_REASON.SET_MOBILE_FILTER),
  setFilter: createAction(SOCIAL_REASON.SET_FILTER, 'payload'),
  resetFilter: createAction(SOCIAL_REASON.RESET_FILTER),
  applyFilter: createAction(SOCIAL_REASON.APPLY_FILTER),
  setOrderBy: createAction(SOCIAL_REASON.SET_ORDER_BY, 'payload'),
  changeOrderby: createAction(SOCIAL_REASON.CHANGE_ORDER_BY),
  resetState: createAction(SOCIAL_REASON.RESET_STATE),
};

const initialState = {
  socialReasons: [],
  filteredSocialReasons: [],
  controls: {
    socialReason: '',
    status: [],
    date: null,
    mobileFilter: false,
    orderBy: 'rut',
    desc: true,
    reset: false,
    resetValues: true,
  },
  pagination: {
    size: 10,
    page: 1,
    totalPages: 20,
  },
  firstFetch: true,
  loading: false,
  loadingNewSR: false,
  pageLoading: false,
};

const holdingMaintainerDetail = createReducer(initialState, {
  [SOCIAL_REASON.GET_SOCIAL_REASONS]: (state) => ({
    ...state,
    loading: true,
  }),
  [SOCIAL_REASON.GET_SOCIAL_REASONS_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    holding: action.payload.holding,
    socialReasons: action.payload.socialReasons,
    filteredSocialReasons:
      sortHoldingsBy(action.payload.socialReasons, state.controls.orderBy, state.controls.desc),
    pagination: {
      ...state.pagination,
      page: 1,
      totalPages: action.payload.socialReasons.length > 1
        ? Math.trunc(action.payload.socialReasons.length / state.pagination.size) : 0,
    },
  }),
  [SOCIAL_REASON.GET_SOCIAL_REASONS_ERROR]: (state) => ({
    ...state,
    loading: false,
  }),
  [SOCIAL_REASON.ADD_SOCIAL_REASON_SUCCESS]: (state, action) => ({
    ...state,
    socialReasons: [...state.socialReasons, action.payload],
  }),
  [SOCIAL_REASON.ADD_SOCIAL_REASON_ERROR]: () => {},
  [SOCIAL_REASON.UPDATE_SOCIAL_REASON_SUCCESS]: (state, action) => {
    const updatedIndex = state.socialReasons.findIndex((sr) => sr.id === action.payload.id);
    const updatedSocialReasons = [...state.socialReasons];
    updatedSocialReasons[updatedIndex] = action.payload;
    return {
      ...state,
      socialReasons: updatedSocialReasons,
    };
  },
  [SOCIAL_REASON.REASSIGN_HOLDING_SUCCESS]: (state, action) => ({
    ...state,
    socialReasons: state.socialReasons.filter((sr) => sr.id !== action.payload.id),
    filteredSocialReasons: state.filteredSocialReasons.filter((sr) => sr.id !== action.payload.id),
  }),
  [SOCIAL_REASON.REASSIGN_HOLDING_SRS_SUCCESS]: (state) => ({
    ...state,
    holding: { ...state.holding, isActive: false },
    socialReasons: state.socialReasons.filter((sr) => !sr.isActive),
    filteredSocialReasons: state.filteredSocialReasons.filter((sr) => !sr.isActive),
  }),
  [SOCIAL_REASON.CHANGE_PAGE]: (state, action) => ({
    ...state,
    pagination: {
      ...state.pagination,
      page: action.payload,
    },
  }),
  [SOCIAL_REASON.SET_FILTER]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      [action.payload.key]: action.payload.value,
    },
  }),
  [SOCIAL_REASON.RESET_FILTER]: (state) => ({
    ...state,
    filteredSocialReasons: state.socialReasons,
    controls: {
      ...state.controls,
      orderBy: 'rut',
      socialReason: '',
      status: [],
      date: null,
    },
    pagination: {
      ...state.pagination,
      page: 1,
      totalPages: state.socialReasons.length > 1
        ? Math.trunc(state.socialReasons.length / state.pagination.size) : 0,
    },
  }),
  [SOCIAL_REASON.APPLY_FILTER]: (state) => {
    const filteredSocialReasons = state.socialReasons
      .filter(filterName(state.controls.socialReason))
      .filter(filterStatus(state.controls.status))
      .filter(filterDate(state.controls.date));

    return {
      ...state,
      filteredSocialReasons,
      pagination: {
        ...state.pagination,
        page: 1,
        totalPages: filteredSocialReasons.length > 1
          ? Math.trunc(filteredSocialReasons.length / state.pagination.size) : 0,
      },
    };
  },
  [SOCIAL_REASON.SET_MOBILE_FILTER]: (state) => ({
    ...state,
    controls: {
      ...state.controls,
      mobileFilter: !state.controls.mobileFilter,
    },
  }),
  [SOCIAL_REASON.SET_ORDER_BY]: (state, action) => {
    if (!(Object.values(SOCIAL_REASON_ATTR).includes(action.payload))) return state;

    return {
      ...state,
      filteredSocialReasons:
        sortHoldingsBy(state.filteredSocialReasons, action.payload, state.controls.desc),
      controls: {
        ...state.controls,
        orderBy: action.payload,
      },
    };
  },
  [SOCIAL_REASON.CHANGE_ORDER_BY]: (state) => ({
    ...state,
    filteredSocialReasons:
        sortHoldingsBy(state.filteredSocialReasons, state.controls.orderBy, !state.controls.desc),
    controls: {
      ...state.controls,
      desc: !state.controls.desc,
    },
  }),
});

export default holdingMaintainerDetail;
