export const valueHasData = (value) => value !== null
&& value !== undefined
&& value.toString().trim().length > 0;

export const numberBetween = (value, min, max) => Number(value.toString().trim()) >= min
&& Number(value.toString().trim()) <= max;

export const minStringValue = (value, min) => value.trim().length >= min;

export const maxStringValue = (value, max) => value.trim().length <= max;
