import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Grid, Typography } from '@material-ui/core';
import styles from './style';

const useStyles = makeStyles(styles);

const BlueCardItem = ({
  title, content, xs, md, right = false,
}) => {
  const classes = useStyles();
  return (
    <Grid item xs={xs} md={md} style={{ textAlign: right ? 'right' : 'left' }}>
      <Typography className={classes.whiteTypography}>
        {title}
      </Typography>

      <Typography className={classes.whiteTypography} style={{ textAlign: right ? 'right' : 'left' }}>
        {content}
      </Typography>
    </Grid>
  );
};
export default BlueCardItem;
