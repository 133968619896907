const styles = (theme) => ({
  clearButton: {
    marginRight: theme.spacing(2),
    border: '1px solid',
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'right',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 235,
    },
  },
});

export default styles;
