import {
  takeLatest, spawn, put, select,
} from 'redux-saga/effects';
import { getIds } from 'utils/functions';
import * as APP from 'commons/reducer';
import { apiSuccess, get } from '../../../utils/api';
import { DASHBOARD } from '../reducer';

const getHolding = (state) => state.app.holding.id;
const getSocialReason = (state) => state.app.socialReason;
const getSize = (state) => state.dashBoard.tableControls.pagination.size;
const getPage = (state) => state.dashBoard.tableControls.pagination.page;
const getSortBy = (state) => state.dashBoard.tableControls.sortBy;
const getDesc = (state) => state.dashBoard.tableControls.descending;
const getSearch = (state) => state.dashBoard.tableControls.search;

function* getTableControls() {
  const size = yield select(getSize);
  const page = yield select(getPage);
  const sortBy = yield select(getSortBy);
  const desc = yield select(getDesc);
  const search = yield select(getSearch);

  return {
    size, page, sortBy, desc, search,
  };
}

function* buildHoldingsPath() {
  const base = 'api/v1/holdings/kpis';

  const {
    size, page, sortBy, desc, search,
  } = yield getTableControls();
  const pathPage = page ? `page=${page}` : '';
  const pathSize = size ? `&limit=${size}` : '';
  const pathSortBy = sortBy ? `&sortBy=${sortBy}` : '';
  const pathDesc = desc ? `&desc=${desc}` : '';
  const pathSearch = search ? `&search=${search}` : '';

  return `${base}?${pathPage}${pathSize}${pathSortBy}${pathDesc}${pathSearch}`;
}

function* getStateFromApiVehicle() {
  yield takeLatest(DASHBOARD.GET_STATE_FROM_API_VEHICLE, function* () {
    const holding = yield select(getHolding);
    const socialReasonIds = yield select(getSocialReason);
    const pathSocialReason = getIds(socialReasonIds).map((d) => `&socialReason=${d}`).join('').slice(1);
    let queryString = `holding=${holding}`;

    if (socialReasonIds.length > 0) queryString += `&${pathSocialReason}`;

    const path = `api/v1/kpis/dashboard?${queryString}`;

    const response = yield get(path);

    if (!response.error) {
      yield put(apiSuccess(DASHBOARD.GET_STATE_FROM_API_VEHICLE_SUCCESS, response));
    } else {
      yield put(apiSuccess(DASHBOARD.GET_STATE_FROM_API_VEHICLE_ERROR));
    }
  });
}

function* getTableHoldings() {
  yield takeLatest(DASHBOARD.TABLE_GET_HOLDINGS, function* () {
    const path = yield buildHoldingsPath();

    const response = yield get(path);
    if (!response.error) {
      yield put(apiSuccess(DASHBOARD.TABLE_GET_HOLDINGS_SUCCESS, response));
    } else {
      yield put(apiSuccess(DASHBOARD.TABLE_GET_HOLDINGS_ERROR));
      yield put(apiSuccess(APP.SET_ERROR, { msg: 'Error al obtener los datos' }));
    }
  });
}

export default function* root() {
  yield spawn(getStateFromApiVehicle);
  yield spawn(getTableHoldings);
}
