import { alpha } from '@material-ui/core/styles/colorManipulator';

const styles = (theme) => ({
  back1: {
    backgroundColor: alpha(theme.palette.primary.main, 0.03),
  },
  back2: {
    backgroundColor: alpha(theme.palette.primary.main, 0.08),
  },
  itemIcon: {
    color: theme.palette.primary.main,
  },
  statusIcon: {
    fontSize: 14.3,
  },
  enabled: { color: 'green' },
  disabled: { color: 'red' },
  text: {
    overflowX: 'auto',
  },
  box: {
    paddingRight: 8,
  },
  personIcon: {
    fontSize: 14.3,
  },
  text2: {
    overflowX: 'auto',
    overflowY: 'hidden',
  },
});

export default styles;
