import { Grid } from '@material-ui/core';
import React from 'react';

const ClientSettingsButtonGroup = ({ children, classes }) => (
  <Grid
    container
    className={`${classes.mt20} ${classes.buttonGroup}`}
    justifyContent="flex-end"
    spacing={1}
  >
    { Array.isArray(children) ? children.map((child, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <Grid key={i} item>{child}</Grid>
    )) : children }

  </Grid>
);

export default ClientSettingsButtonGroup;
