import { isBefore } from 'date-fns';

/**
 * @param {string} name filter input
 */
export const filterName = (name) => (socialReason) => {
  if (!name) return true;

  return socialReason.name.toLowerCase().includes(name.toLowerCase());
};

/**
  * @param {string[]} status filter input
  */
export const filterStatus = (status) => (socialReason) => {
  if (!status.length) return true;

  return status.includes(socialReason.isActive);
};

/**
 * @param {string} date filter input (format: yyyy/mm/dd)
 */
export const filterDate = (date) => (socialReason) => {
  if (!date) return true;

  return isBefore(new Date(date), new Date(socialReason.createdAt));
};
