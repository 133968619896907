import React from 'react';
import {
  Box,
  Card, Grid,
} from '@material-ui/core';

// Material
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

// icons
import {
  Assessment,
  AttachMoney,
  DriveEtaOutlined, EmojiTransportationOutlined, EventAvailableOutlined,
  Note,
  Person,
} from '@material-ui/icons';
import { PAYMENT_STATUS } from 'utils/enums';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { currencyForm } from 'utils/utils';
import styles from './style';

const useStyles = makeStyles(styles);

const CardItem = ({
  title, content, xs, md, icon, extraContent, isMobile, align = 'left',
}) => {
  const classes = useStyles();
  return (
    <Grid item xs={xs} md={md} style={{ textAlign: isMobile ? 'left' : align }}>
      { isMobile
        && (
        <Box className={classes.titleIcon}>
          {icon}
          <Typography className={classes.cardTitle}>
            {title}
          </Typography>
        </Box>
        )}
      <Typography>
        {content}
      </Typography>
      <Typography>
        {extraContent}
      </Typography>
    </Grid>
  );
};

const BlueCardItem = ({
  title, content, xs, md, align = 'left',
}) => {
  const classes = useStyles();
  return (
    <Grid item xs={xs} md={md} style={{ textAlign: align }}>
      <Typography className={classes.whiteTypography}>
        {title}
      </Typography>

      <Typography className={classes.whiteTypography}>
        {content}
      </Typography>
    </Grid>
  );
};

const PaymentStatusCard = ({ paymentStatusDTOList, isMobile }) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <>
      {!isMobile && (
        <Card className={classes.back3}>
          <Grid container alignItems="center" justifyContent="space-evenly">
            <BlueCardItem md={1} xs={6} title="Estado de pago" />
            <BlueCardItem md={1} xs={6} title="Estado" />
            <BlueCardItem md={2} xs={12} title="Cliente" />
            <BlueCardItem md={1} xs={6} title="Vehículos" align="center" />
            <BlueCardItem md={1} xs={6} title="Flotas" />
            <BlueCardItem md={1} xs={6} title="Período" />
            <BlueCardItem md={1} xs={6} title="Emisión" />
            <BlueCardItem md={1} xs={6} title="Total" align="right" />
          </Grid>
        </Card>
      ) }
      {paymentStatusDTOList?.length > 0 && (
        paymentStatusDTOList.map((ps, index) => (
          <Card key={ps.id} className={index % 2 === 0 ? classes.back1 : classes.back2} onClick={() => { history.push(`/payment-status/${ps.id}`); }}>
            <Grid container alignItems="center" justifyContent="space-evenly" className={isMobile ? classes.grid : classes.grid2}>
              <CardItem title="Estado de pago" content={ps.id} md={1} xs={6} icon={<Assessment />} isMobile={isMobile} />
              <CardItem title="Estado" content={PAYMENT_STATUS[ps.status]} md={1} xs={6} icon={<Note />} isMobile={isMobile} />
              <CardItem title="Cliente" content={ps.holding} extraContent={ps.client} md={2} xs={6} icon={<Person />} isMobile={isMobile} />
              <CardItem title="Vehículos" content={ps.totalVehicles} md={1} xs={6} icon={<DriveEtaOutlined />} isMobile={isMobile} align="center" />
              <CardItem title="Flotas" content={`${ps.totalFleets} ${ps.totalFleets === 1 ? 'Flota' : 'Flotas'}`} md={1} xs={6} icon={<EmojiTransportationOutlined />} isMobile={isMobile} />
              <CardItem title="Período" content={moment.utc(ps.period).format('MM-YYYY')} md={1} xs={6} icon={<EventAvailableOutlined />} isMobile={isMobile} />
              <CardItem title="Emisión" content={moment.utc(ps.releaseDate).format('DD-MM-YYYY')} md={1} xs={6} icon={<EventAvailableOutlined />} isMobile={isMobile} />
              <CardItem title="Total" content={currencyForm.format(Math.round((ps.totalClp - ps.totalPreviousBalancesClp) * 1.19))} md={1} xs={6} icon={<AttachMoney />} isMobile={isMobile} align="right" />
            </Grid>
          </Card>
        )))}

      {paymentStatusDTOList?.length === 0 && (
        isMobile
          ? (
            <Grid container justifyContent="center">
              <Typography>No hay registros disponibles</Typography>
            </Grid>
          ) : (
            <Card className={classes.back1}>
              <Grid container justifyContent="center">
                <CardItem content="No hay registros disponibles" md={12} xs={12} isMobile={isMobile} align="center" />
              </Grid>
            </Card>
          )
      )}
    </>
  );
};

export default PaymentStatusCard;
