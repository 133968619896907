import React from 'react';
import Button from '@material-ui/core/Button';
import ConfirmationDialog from 'commons/components/ConfirmationDialog';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styles from '../styles';

const useStyles = makeStyles(styles);
// Material

// icons

const BtnAcceptFleet = ({
  controls, actions, psId, disabled,
}) => {
  const classes = useStyles();
  const handleClose = () => {
    actions.changeControls('confirmationAcceptFleetDialog', false);
  };

  const handleSubmit = () => {
    actions.acceptFleet(psId, controls.fleet);
  };
  return (
    <>
      <ConfirmationDialog open={controls.confirmationAcceptFleetDialog} onClose={handleClose} onConfirm={handleSubmit} content="¿Desea aceptar la flota?" />
      <Tooltip interactive placement="top" title={disabled ? 'Flota no seleccionada o flota está en espera de respuesta del administrador de contrato' : ''}>
        <div className={classes.divWidth}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            disabled={disabled}
            onClick={() => {
              actions.changeControls('confirmationAcceptFleetDialog', true);
            }}
          >
            ACEPTAR ESTADO DE PAGO
          </Button>
        </div>
      </Tooltip>

    </>
  );
};

export default BtnAcceptFleet;
