import { Skeleton } from '@material-ui/lab';
import React, { useState, useEffect } from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';

import BackButton from 'commons/components/BackButton';
import { composedComponent } from 'utils/functions';
import { appActions } from 'commons/reducer';
import TextInputFilter from 'commons/components/TextInputFilter';
import ScreenTitle from 'commons/components/ScreenTitle';
import { screenTime } from 'utils/utils';
import styles from './styles';
import saga from './saga';
import { reportsActions } from './reducer';

const useStyles = makeStyles(styles);

function Reports({ reports = [], selectedReport }) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [searchReport, setSearchReport] = useState(selectedReport);
  const handleLoadIFrame = () => {
    setIsLoading(false);
  };

  const handleChange = (e) => {
    setSearchReport({
      ...searchReport, title: e.value,
    });
  };

  const handleSelect = (e) => {
    setSearchReport({
      url: e.url,
      title: e.label,
    });
  };

  useEffect(() => {
    setSearchReport(selectedReport);
  }, [selectedReport]);

  useEffect(() => screenTime('reports'), []);

  return (
    <>
      <BackButton path="/" />
      <Box display="flex" justifyContent="space-between" mb={2}>
        <ScreenTitle title="REPORTES" showSubTitle={false} />
      </Box>
      <Grid item xs={12}>
        {searchReport && (
        <TextInputFilter
          id="report"
          label="Reporte"
          options={reports.map((report) => ({
            label: report.title,
            value: report.id,
            url: report.url,
          }))}
          value={searchReport?.title}
          onSelect={handleSelect}
          onChange={handleChange}
        />
        )}
      </Grid>
      <div className={classes.container}>
        <div className={classes.iframeContainer}>
          {isLoading && <Skeleton variant="rect" width="100%" height="100%" />}
          <iframe
            src={searchReport?.url}
            style={{ display: isLoading ? 'none' : 'block' }}
            onLoad={handleLoadIFrame}
            title="Portal Agendamiento"
          />
        </div>
      </div>
    </>
  );
}

export default composedComponent(Reports, saga, {
  saga: 'sagaRecoveryPassword',
  states: ['app.user.reports', 'app.user.selectedReport', 'app.loadingUserReports'],
  actions: [reportsActions, appActions],
});
