import React from 'react';
import Grid from '@material-ui/core/Grid';

import { makeStyles } from '@material-ui/core/styles';
import MultipleFilter from 'commons/components/MultipleFilter';
import CustomFilterButton from 'commons/components/CustomFilterButton';
import DateRangePicker from 'commons/components/DateRangePicker';
import CustomSelect from 'commons/components/CustomSelect';
import Hidden from '@material-ui/core/Hidden';
import SocialReasonsFilter from 'commons/components/MultipleFilter/SocialReasonsFilter';
import styles from './styles';

const useStyles = makeStyles(styles);

const optionStatus = [
  { id: 1, value: true, label: 'ABIERTO' },
  { id: 2, value: false, label: 'CERRADO' },
];

const dateOptions = [
  { id: 1, value: 'faultDateStart', label: 'ENTRADA DE VEHICULO A TALLER' },
  { id: 2, value: 'faultDateEnd', label: 'SALIDA DE TALLER' },
  { id: 3, value: 'wantedDateStart', label: 'FECHA AGENDADA DE INGRESO A TALLER' },
  { id: 4, value: 'wantedDateEnd', label: 'FECHA ESTIMADA DE SALIDA DE TALLER' },
];

const Filter = ({
  vehicles, workshops, controls, onChange, fleets, filterServices, handleClearFilter,
  handleChangeSocialReason, optionsSocialReason, selectValue,
}) => {
  const classes = useStyles();

  const handleRangePicker = (newValue) => {
    onChange({ key: 'dateRange', value: newValue });
  };

  const handleDateSelect = ({ target }) => {
    const { id, value } = target;
    onChange({ key: id, value });
  };

  return (
    <Grid container spacing={2} className={classes.container}>
      <Grid item xs={12} sm={3} md={3}>
        <MultipleFilter
          id="status"
          label="Estado"
          value={controls.status}
          options={optionStatus}
          onChange={onChange}
        />
      </Grid>
      <Grid item xs={12} sm={3} md={3}>
        <MultipleFilter
          id="fleet"
          label="Flota"
          onChange={onChange}
          options={fleets}
          value={controls.fleet}
        />
      </Grid>
      <Grid item xs={12} sm={3} md={2}>
        <MultipleFilter
          id="vehicle"
          label="Patente"
          onChange={onChange}
          options={vehicles}
          value={controls.vehicle}
        />
      </Grid>
      <Grid item xs={12} sm={3} md={2}>
        <MultipleFilter
          id="workshop"
          label="Taller"
          value={controls.workshop}
          options={workshops}
          onChange={onChange}
        />
      </Grid>
      <Hidden smDown>
        <Grid item xs={12} sm={2}>
          <CustomFilterButton
            onClick={filterServices}
            clear={handleClearFilter}
          />
        </Grid>
        <SocialReasonsFilter
          xs={12}
          sm={3}
          md={3}
          filterField
          value={selectValue}
          options={optionsSocialReason}
          onChange={handleChangeSocialReason}
        />
        <Grid item sm={2} md={3}>
          <CustomSelect
            label="Fecha"
            id="selectedDate"
            name="selectedDate"
            variant="outlined"
            onChange={handleDateSelect}
            value={controls.selectedDate}
            options={dateOptions}
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <DateRangePicker
            value={controls.dateRange}
            fullWidth
            onChange={handleRangePicker}
          />
        </Grid>
      </Hidden>
      <Hidden smDown mdUp>
        <SocialReasonsFilter
          xs={12}
          sm={3}
          md={2}
          filterField
          value={selectValue}
          options={optionsSocialReason}
          onChange={handleChangeSocialReason}
        />
        <Grid item xs={12} sm={2}>
          <CustomSelect
            label="Fecha"
            id="selectedDate"
            name="selectedDate"
            variant="outlined"
            onChange={handleDateSelect}
            value={controls.selectedDate}
            options={dateOptions}
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <DateRangePicker
            value={controls.dateRange}
            fullWidth
            onChange={handleRangePicker}
          />
        </Grid>
      </Hidden>
      <Hidden xsDown mdUp>
        <SocialReasonsFilter
          xs={12}
          sm={3}
          md={2}
          filterField
          value={[]}
          options={[]}
          onChange={handleChangeSocialReason}
        />
        <Grid item sm={3}>
          <CustomSelect
            label="Fecha"
            id="selectedDate"
            name="selectedDate"
            variant="outlined"
            onChange={handleDateSelect}
            value={controls.selectedDate}
            options={dateOptions}
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DateRangePicker
            value={controls.dateRange}
            fullWidth
            onChange={handleRangePicker}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <CustomFilterButton
            onClick={filterServices}
            clear={handleClearFilter}
          />
        </Grid>
      </Hidden>
      <Hidden smUp>
        <SocialReasonsFilter
          xs={12}
          sm={3}
          md={2}
          filterField
          value={[]}
          options={[]}
          onChange={handleChangeSocialReason}
        />
        <Grid item xs={12}>
          <CustomSelect
            label="Fecha"
            id="selectedDate"
            name="selectedDate"
            variant="outlined"
            onChange={handleDateSelect}
            value={controls.selectedDate}
            options={dateOptions}
            size="small"
          />
        </Grid>
        <Grid item xs={12}>

          <DateRangePicker
            value={controls.dateRange}
            fullWidth
            onChange={handleRangePicker}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomFilterButton
            onClick={filterServices}
            clear={handleClearFilter}
          />
        </Grid>
      </Hidden>

    </Grid>
  );
};

export default Filter;
