const styles = (theme) => ({
  gridContainer: {
    marginTop: theme.spacing(6),
  },
  gridItem: {
    height: '55vh',
    [theme.breakpoints.up('sm')]: {
      height: '60vh',
    },
  },
  gridActionItem: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
  },
});

export default styles;
