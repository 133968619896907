import Quote from 'screens/Quote';
import User from 'screens/User';
import UserSetting from 'screens/UserSetting';
import Service from 'screens/Service';
import WorkOrder from 'screens/WorkOrder';
import Request from 'screens/Request';
import System from 'screens/System';
import PaymentStatus from 'screens/PaymentStatus';
import NewPaymentStatus from 'screens/GeneratePaymentStatus';
import PaymentStatusConfig from 'screens/PaymentStatusConfig';
import HoldingMaintainer from 'screens/HoldingMaintainer';
import HoldingMaintainerDetail from 'screens/HoldingMaintainerDetail';
import PaymentStatusSections from 'screens/PaymentStatusSections';
import ClientSettings from 'screens/ClientSettings';
import HomeMarketing from 'screens/HomeMarketing';
import Scheduling from 'screens/Scheduling';
import Reports from 'screens/Reports';
import ReportsMaintainer from 'screens/ReportsMaintainer';
import VideoTutorials from 'screens/VideoTutorials';
import Newsletter from '../screens/Newsletter';
import Fleets from '../screens/Fleets';
import Login from '../screens/Login';
import DashBoard from '../screens/DashBoard';
import Upload from '../screens/Upload/Upload';
import RecoveryPassword from '../screens/RecoveryPassword';
import Account from '../screens/Account';
import Vehicle from '../screens/Vehicle';
import VehicleDetail from '../screens/VehicleDetail/index';
import Document from '../screens/Document/index';

const Paths = {
  ADMINISTRADOR_PORTAL_SALFA: [
    { path: '/', component: HomeMarketing },
    { path: '/dashboard', component: DashBoard },
    { path: '/reports', component: Reports },
    { path: '/newsletter', component: Newsletter },
    { path: '/scheduling', component: Scheduling },
    { path: '/upload', component: Upload },
    { path: '/account', component: Account },
    { path: '/account/*', component: Account },
    { path: '/vehicles/', component: Vehicle },
    { path: '/vehicles/:id', component: VehicleDetail },
    { path: '/payment-status', component: PaymentStatus },
    { path: '/payment-status/:id', component: NewPaymentStatus },
    { path: '/fleets/', component: Fleets },
    { path: '/user', component: User },
    { path: '/user/create', component: UserSetting },
    { path: '/user/edit/:id', component: UserSetting },
    { path: '/services', component: Service },
    { path: '/work-orders', component: WorkOrder },
    { path: '/request', component: Request },
    { path: '/system', component: System },
    { path: '/system/*', component: System },
    { path: '/payment-status-config', component: PaymentStatusConfig },
    { path: '/payment-status-config/*', component: PaymentStatusConfig },
    { path: '/holding-maintainer', component: HoldingMaintainer },
    { path: '/holding-maintainer/:id/social-reasons', component: HoldingMaintainerDetail },
    { path: '/reports-maintainer', component: ReportsMaintainer },
    { path: '/video-tutorials', component: VideoTutorials },
    { path: '/document', component: Document },
  ],
  ADMINISTRADOR_DE_CONTRATO: [
    { path: '/', component: HomeMarketing },
    { path: '/dashboard', component: DashBoard },
    { path: '/reports', component: Reports },
    { path: '/newsletter', component: Newsletter },
    { path: '/scheduling', component: Scheduling },
    { path: '/upload', component: Upload },
    { path: '/account', component: Account },
    { path: '/account/*', component: Account },
    { path: '/vehicles/', component: Vehicle },
    { path: '/vehicles/:id', component: VehicleDetail },
    { path: '/fleets/', component: Fleets },
    { path: '/user', component: User },
    { path: '/user/create', component: UserSetting },
    { path: '/user/edit/:id', component: UserSetting },
    { path: '/services', component: Service },
    { path: '/work-orders', component: WorkOrder },
    { path: '/request', component: Request },
    { path: '/payment-status', component: PaymentStatus },
    { path: '/payment-status/:id', component: NewPaymentStatus },
    { path: '/payment-status-config', component: PaymentStatusConfig },
    { path: '/payment-status-config/*', component: PaymentStatusConfig },
    { path: '/payment-status-sections', component: PaymentStatusSections },
    { path: '/payment-status-sections/*', component: PaymentStatusSections },
    { path: '/video-tutorials', component: VideoTutorials },
    { path: '/document', component: Document },
  ],
  'GESTOR_DE_FLOTA/DECISOR': [
    { path: '/', component: HomeMarketing },
    { path: '/dashboard', component: DashBoard },
    { path: '/reports', component: Reports },
    { path: '/newsletter', component: Newsletter },
    { path: '/scheduling', component: Scheduling },
    { path: '/upload', component: Upload },
    { path: '/account', component: Account },
    { path: '/account/*', component: Account },
    { path: '/vehicles/', component: Vehicle },
    { path: '/vehicles/:id', component: VehicleDetail },
    { path: '/fleets/', component: Fleets },
    { path: '/quote', component: Quote },
    { path: '/services', component: Service },
    { path: '/work-orders', component: WorkOrder },
    { path: '/payment-status', component: PaymentStatus },
    { path: '/payment-status/:id', component: NewPaymentStatus },
    { path: '/payment-status-config', component: PaymentStatusConfig },
    { path: '/payment-status-config/*', component: PaymentStatusConfig },
    { path: '/client-settings', component: ClientSettings },
    { path: '/user', component: User },
    { path: '/user/create', component: UserSetting },
    { path: '/user/edit/:id', component: UserSetting },
    { path: '/video-tutorials', component: VideoTutorials },
    { path: '/document', component: Document },
  ],
  'ENCARGADO_DE_FLOTA/SECCION': [
    { path: '/', component: HomeMarketing },
    { path: '/dashboard', component: DashBoard },
    { path: '/reports', component: Reports },
    { path: '/newsletter', component: Newsletter },
    { path: '/scheduling', component: Scheduling },
    { path: '/upload', component: Upload },
    { path: '/account', component: Account },
    { path: '/account/*', component: Account },
    { path: '/vehicles/', component: Vehicle },
    { path: '/vehicles/:id', component: VehicleDetail },
    { path: '/quote', component: Quote },
    { path: '/services', component: Service },
    { path: '/work-orders', component: WorkOrder },
    { path: '/payment-status', component: PaymentStatus },
    { path: '/payment-status/:id', component: NewPaymentStatus },
    { path: '/payment-status-config', component: PaymentStatusConfig },
    { path: '/payment-status-config/*', component: PaymentStatusConfig },
    { path: '/video-tutorials', component: VideoTutorials },
    { path: '/document', component: Document },
  ],
  shared: [
    { path: '/login', component: Login },
    { path: '/recovery-password', component: RecoveryPassword },
    { path: '/update-password', component: RecoveryPassword },
  ],
};

export default Paths;
