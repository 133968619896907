import { createAction, createReducer } from 'utils/reducer';

// Local constants
const PATH = 'fleet/';

// Global constants for saga
export const FLEETS = {
  TOGGLE_MODALS: `${PATH}TOOGLE_MODALS`,
  CLEAR_DATA: `${PATH}CLEAR_DATA`,
  FETCH_DATA: `${PATH}FETCH_DATA`,
  FETCH_DATA_SUCCESS: `${PATH}FETCH_DATA_SUCCESS`,
  CHANGE_FLEET: `${PATH}CHANGE_FLEET`,
  SET_CREATE_FLEET_MODE: `${PATH}SET_CREATE_FLEET_MODE`,
  CREATE_FLEET: `${PATH}CREATE_FLEET`,
  CREATE_FLEET_SUCCESS: `${PATH}CREATE_FLEET_SUCCESS`,
  SET_EDIT_FLEET_MODE: `${PATH}SET_EDIT_FLEET_MODE`,
  EDIT_FLEET: `${PATH}EDIT_FLEET`,
  EDIT_FLEET_SUCCESS: `${PATH}EDIT_FLEET_SUCCESS`,
  CANCEL_FLEET: `${PATH}CANCEL_FLEET`,
  DELETE_FLEET: `${PATH}DELETE_FLEET`,
  DELETE_FLEET_SUCCESS: `${PATH}DELETE_FLEET_SUCCESS`,
  SET_FORM_CONTROLS: `${PATH}SET_FORM_CONTROLS`,
  SET_FLEET_NAME: `${PATH}SET_FLEET_NAME`,
  ADD_ASSIGN: `${PATH}ADD_ASSIGN`,
  REMOVE_ASSIGN: `${PATH}REMOVE_ASSIGN`,
  ASSIGN_ALL: `${PATH}ASSIGN_ALL`,
  SEND_ASSIGN: `${PATH}SEND_ASSIGN`,
  SEND_ASSIGN_SUCCESS: `${PATH}SEND_ASSIGN_SUCCESS`,
  ADD_DEALLOCATE: `${PATH}ADD_DEALLOCATE`,
  REMOVE_DEALLOCATE: `${PATH}REMOVE_DEALLOCATE`,
  DEALLOCATE_ALL: `${PATH}DEALLOCATE_ALL`,
  SEND_DEALLOCATE: `${PATH}SEND_DEALLOCATE`,
  SEND_DEALLOCATE_SUCCESS: `${PATH}SEND_DEALLOCATE_SUCCESS`,
};

// actions
export const fleetsActions = {
  toggleModals: createAction(FLEETS.TOGGLE_MODALS, 'payload'),
  getData: createAction(FLEETS.FETCH_DATA),
  changeFleet: createAction(FLEETS.CHANGE_FLEET, 'payload'),
  setCreateMode: createAction(FLEETS.SET_CREATE_FLEET_MODE),
  createFleet: createAction(FLEETS.CREATE_FLEET),
  setEditMode: createAction(FLEETS.SET_EDIT_FLEET_MODE, 'payload'),
  editFleet: createAction(FLEETS.EDIT_FLEET),
  setFormControls: createAction(FLEETS.SET_FORM_CONTROLS, 'payload'),
  cancelFleet: createAction(FLEETS.CANCEL_FLEET),
  deleteFleet: createAction(FLEETS.DELETE_FLEET),
  addAssign: createAction(FLEETS.ADD_ASSIGN, 'payload'),
  removeAssign: createAction(FLEETS.REMOVE_ASSIGN, 'payload'),
  assignAll: createAction(FLEETS.ASSIGN_ALL, 'payload'),
  sendAssign: createAction(FLEETS.SEND_ASSIGN),
  addDeallocate: createAction(FLEETS.ADD_DEALLOCATE, 'payload'),
  removeDeallocate: createAction(FLEETS.REMOVE_DEALLOCATE, 'payload'),
  deallocateAll: createAction(FLEETS.DEALLOCATE_ALL, 'payload'),
  sendDeallocate: createAction(FLEETS.SEND_DEALLOCATE),
};

const initialState = {
  controls: {
    fleet: '',
    inputFleet: '',
    userId: '',
    socialReasonId: '',
    creating: false,
    editing: false,
    toAssign: [],
    toDeallocate: [],
    selectedFleet: {},
  },
  modals: {
    fleetForm: false,
  },
  firstFetch: true,
  isAdmin: true,
  loading: false,
  loadingData: false,
  error: false,
  message: '',
};

const fleets = createReducer(initialState, {
  [FLEETS.TOGGLE_MODALS]: (state, action) => ({
    ...state,
    modals: {
      ...state.modals,
      [action.payload]: !state.modals[action.payload],
    },
  }),
  [FLEETS.CLEAR_DATA]: (state) => ({
    ...initialState,
    controls: {
      ...initialState.controls,
      holding: state.controls.holding,
    },
  }),
  [FLEETS.FETCH_DATA]: () => ({
    ...initialState,
    loading: true,
  }),
  [FLEETS.FETCH_DATA_SUCCESS]: (state) => ({
    ...state,
    loading: false,
    loadingData: true,
    firstFetch: false,
  }),
  [FLEETS.CHANGE_FLEET]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      fleet: action.payload,
    },
  }),
  [FLEETS.SET_FLEET_NAME]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      inputFleet: action.payload,
    },
  }),
  [FLEETS.SET_FORM_CONTROLS]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      ...action.payload,
    },
  }),
  [FLEETS.SET_CREATE_FLEET_MODE]: (state) => ({
    ...state,
    controls: {
      ...initialState.controls,
      holding: state.controls.holding,
      fleet: state.controls.fleet,
      inputFleet: '',
      creating: true,
      editing: false,
    },
  }),
  [FLEETS.CREATE_FLEET]: (state) => ({
    ...state,
    loading: true,
  }),
  [FLEETS.CREATE_FLEET_SUCCESS]: (state, action) => ({
    ...state,
    controls: {
      ...initialState.controls,
      holding: state.controls.holding,
      fleet: action.payload,
    },
    modals: {
      ...state.modals,
      fleetForm: false,
    },
    loading: false,
  }),
  [FLEETS.SET_EDIT_FLEET_MODE]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      inputFleet: action.payload.inputFleet,
      userId: action.payload.userId,
      socialReasonId: action.payload.socialReasonId,
      creating: false,
      editing: true,
      selectedFleet: action.payload.selectedFleet,
    },
  }),
  [FLEETS.EDIT_FLEET]: (state) => ({
    ...state,
    loading: true,
  }),
  [FLEETS.EDIT_FLEET_SUCCESS]: (state) => ({
    ...state,
    controls: {
      ...initialState.controls,
      holding: state.controls.holding,
      fleet: state.controls.fleet,
    },
    modals: {
      ...state.modals,
      fleetForm: false,
    },
    loading: false,
  }),
  [FLEETS.DELETE_FLEET]: (state) => ({
    ...state,
    loading: true,
  }),
  [FLEETS.DELETE_FLEET_SUCCESS]: (state) => ({
    ...state,
    controls: {
      ...initialState.controls,
      holding: state.controls.holding,
    },
    loading: false,
  }),
  [FLEETS.CANCEL_FLEET]: (state) => ({
    ...state,
    controls: {
      ...initialState.controls,
      holding: state.controls.holding,
      fleet: state.controls.fleet,
    },
    loading: false,
  }),
  [FLEETS.ADD_ASSIGN]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      toAssign: [...new Set(state.controls.toAssign).add(action.payload)],
    },
  }),
  [FLEETS.REMOVE_ASSIGN]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      toAssign: state.controls.toAssign.filter((d) => d !== action.payload),
    },
  }),
  [FLEETS.ASSIGN_ALL]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      toAssign: action.payload,
    },
  }),
  [FLEETS.ADD_DEALLOCATE]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      toDeallocate: [...new Set(state.controls.toDeallocate).add(action.payload)],
    },
  }),
  [FLEETS.REMOVE_DEALLOCATE]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      toDeallocate: state.controls.toDeallocate.filter((d) => d !== action.payload),
    },
  }),
  [FLEETS.DEALLOCATE_ALL]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      toDeallocate: action.payload,
    },
  }),
  [FLEETS.SEND_ASSIGN]: (state) => ({
    ...state,
    loading: true,
  }),
  [FLEETS.SEND_ASSIGN_SUCCESS]: (state) => ({
    ...state,
    controls: {
      ...initialState.controls,
      holding: state.controls.holding,
      fleet: state.controls.fleet,
    },
    loading: false,
  }),
});

export default fleets;
