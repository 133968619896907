import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  Grid,
} from '@material-ui/core';

// Material
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

// icons
import { currencyForm } from 'utils/utils';
import { ExpandMore } from '@material-ui/icons';
import styles from './style';
import BlueCardItem from '../BlueCardItem';
import CardItem from '../CardItem';

const useStyles = makeStyles(styles);

const PaymentStatusCard = ({
  title,
  total,
  data,
  type,
  isMobile,
  psSettings,
}) => {
  const classes = useStyles();

  const content = (
    <>
      {!isMobile && (
      <Card className={classes.back3}>
        <Grid container justifyContent="space-around" alignItems="center" className={classes.back3}>
          <BlueCardItem md={1} xs={6} title="Patente" />
          {psSettings.brand && <BlueCardItem md={1} xs={6} title="Marca" />}
          {psSettings.model && <BlueCardItem md={2} xs={12} title="Modelo" />}
          {psSettings.fleetName && <BlueCardItem md={1} xs={6} title="Flota" />}
          {psSettings.monthDeadline && <BlueCardItem md={1} xs={6} title="Mes / Plazo" />}
          {psSettings.currency && <BlueCardItem md={1} xs={6} title="Moneda" />}
          {psSettings.totalValue && <BlueCardItem md={1} xs={6} title="Valor base" right />}
          {psSettings.totalDays && <BlueCardItem md={1} xs={6} title="Días arriendo" right />}
          {psSettings.additionals && <BlueCardItem md={1} xs={6} title="Cargo adicional" right />}
          {psSettings.discounts && <BlueCardItem md={1} xs={6} title="Descuento" right />}
          <BlueCardItem md={1} xs={6} title="Total" right />
        </Grid>
      </Card>
      ) }
      {type === 'leases' && data?.length > 0
  && (
    <Box className={classes.scrolling}>
      {data?.map((lease) => (
        <Card key={lease.id} className={classes.back1}>
          <Grid container justifyContent="space-around">
            <CardItem title="Patente" content={lease.licensePlate} md={1} xs={6} isMobile={isMobile} />
            {psSettings.brand && <CardItem title="Marca" content={lease.brand} md={1} xs={6} isMobile={isMobile} />}
            {psSettings.model && <CardItem title="Modelo" content={lease.model} md={2} xs={12} isMobile={isMobile} />}
            {psSettings.fleetName && (
            <CardItem
              title="Flota"
              content={lease.fleetName}
              md={1}
              xs={6}
              isMobile={isMobile}
            />
            )}
            {psSettings.monthDeadline && <CardItem title="Mes / Plazo" content={lease.monthDeadline} md={1} xs={6} isMobile={isMobile} />}
            {psSettings.currency && <CardItem title="Moneda" content={lease.currency} md={1} xs={6} isMobile={isMobile} />}
            {psSettings.totalValue && <CardItem title="Valor base" content={lease.totalValue} md={1} xs={6} isMobile={isMobile} right />}
            {psSettings.totalDays && <CardItem title="Días arriendo" content={lease.totalDays} md={1} xs={6} isMobile={isMobile} right />}
            {psSettings.additionals && <CardItem title="Cargo adicional" content={currencyForm.format(lease.totalCharges)} md={1} xs={6} isMobile={isMobile} right />}
            {psSettings.discounts && <CardItem title="Descuento" content={currencyForm.format(lease.totalDiscounts)} md={1} xs={6} isMobile={isMobile} right />}
            <CardItem title="Total" content={currencyForm.format(lease.totalValueClp)} md={1} xs={6} isMobile={isMobile} right />
          </Grid>
        </Card>
      ))}
    </Box>
  )}
      {type === 'previousBalance' && data?.length > 0
  && (
    <Box className={classes.scrolling}>
      {data?.map((balance) => (
        <Card key={balance.id} className={classes.back1}>
          <Grid container justifyContent="space-around">
            <CardItem title="Patente" content={balance.licensePlate} md={1} xs={6} isMobile={isMobile} />
            {psSettings.brand && <CardItem title="Marca" content={balance.brand} md={2} xs={6} isMobile={isMobile} />}
            {psSettings.model && <CardItem title="Modelo" content={balance.model} md={2} xs={12} isMobile={isMobile} />}
            {psSettings.fleetName && <CardItem title="Flota" content={balance.fleetName} md={1} xs={6} isMobile={isMobile} />}
            {psSettings.monthDeadline && <CardItem title="Mes / Plazo" content={balance.monthDeadline} md={1} xs={6} isMobile={isMobile} />}
            {psSettings.currency && <CardItem title="Moneda" content={balance.currency} md={1} xs={6} isMobile={isMobile} />}
            {psSettings.totalValue && <CardItem title="Valor base" content={balance.totalValue} md={1} xs={6} isMobile={isMobile} right />}
            {psSettings.totalDays && <CardItem title="Días arriendo" content={balance.totalDays} md={1} xs={6} isMobile={isMobile} right />}
            {psSettings.additionals && <CardItem title="Cargo adicional" content={currencyForm.format(0)} md={1} xs={6} isMobile={isMobile} right />}
            {psSettings.discounts && <CardItem title="Descuento" content={currencyForm.format(0)} md={1} xs={6} isMobile={isMobile} right />}
            <CardItem title="Total" content={currencyForm.format(balance.totalValueClp)} md={1} xs={6} isMobile={isMobile} right />
          </Grid>
        </Card>
      ))}
    </Box>
  )}

      {data?.length === 0 && (
      <Box className={classes.scrolling}>
        {isMobile
          ? (
            <Grid container justifyContent="center">
              <Typography>No hay registros disponibles</Typography>
            </Grid>
          ) : (
            <Card className={classes.back1}>
              <Grid container justifyContent="center">
                <CardItem content="No hay registros disponibles" md={12} xs={12} isMobile={isMobile} center />
              </Grid>
            </Card>
          )}
      </Box>
      )}
    </>
  );

  return (
    <>
      {isMobile ? (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              variant="h6"
              color="primary"
            >
              {title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {content}
          </AccordionDetails>
        </Accordion>
      )
        : (
          <>
            <Typography
              variant="h6"
              color="primary"
              className={classes.listTitle}
            >
              {title}
            </Typography>
            { content }
          </>
        )}
      <Card className={classes.back2}>
        <Grid container justifyContent="space-evenly" alignItems="center" className={classes.back2}>
          <BlueCardItem md={1} xs={6} />
          <BlueCardItem md={2} xs={6} />
          <BlueCardItem md={1} xs={6} />
          <BlueCardItem md={1} xs={6} />
          <BlueCardItem md={1} xs={6} />
          <BlueCardItem md={1} xs={6} />
          <BlueCardItem md={1} xs={6} />
          <BlueCardItem md={1} xs={6} />
          <BlueCardItem
            title="SUBTOTAL NETO MENSUAL"
            md={2}
            xs={8}
          />

          <BlueCardItem
            title={total}
            md={1}
            xs={4}
            right
          />
        </Grid>
      </Card>
    </>
  );
};

export default PaymentStatusCard;
