import { alpha } from '@material-ui/core/styles/colorManipulator';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    boxShadow: theme.shadows[4],
    '&:hover': {
      cursor: 'pointer',
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
    },
  },
  upperElement: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(0),
    },
  },
  rootItem: {
    paddingLeft: 0,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '22%',
    },
  },
  noIconLine: {
    paddingLeft: theme.spacing(0),
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(5),
    },
  },
  normalIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
    fontSize: 32,
    display: 'none',
    [theme.breakpoints.up('lg')]: {
      display: 'block',
    },
  },
  mobileIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
    fontSize: 32,
    marginLeft: '-0.3rem',
    paddingRight: '0.3rem',
  },
  statusIcon: {
    marginRight: theme.spacing(1),
    fontSize: 20,
  },
  OPERATIVO: { color: 'green' },
  ARRENDADO: { color: 'green' },
  REEMPLAZADO: { color: 'orange' },
  'EN TRANSFERENCIA': { color: 'green' },
  TALLER: { color: 'red' },
  VENDIDO: { color: 'yellow' },
  back1: {
    backgroundColor: alpha(theme.palette.primary.main, 0.03),
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.12),
    },
  },
  back2: {
    backgroundColor: alpha(theme.palette.primary.main, 0.08),
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.12),
    },
  },
  paginationContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  personIcon: {
    fontSize: 14.3,
  },
  noData: {
    backgroundColor: theme.palette.primary.secondary,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    borderRadius: 0,
    cursor: 'pointer',
    paddingLeft: 8,
    paddingRight: 8,
  },
  boxChangeDriver: {
    backgroundColor: 'none',
    marginLeft: theme.spacing(1),
    borderRadius: '20px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.8),
    },
  },
  buttonChangeDriver: {
    color: theme.palette.primary.main,
    fontSize: 14,
  },
});

export default styles;
