import React from 'react';
import {
  Checkbox, FormControlLabel, Grid, Radio,
} from '@material-ui/core';

const ClientSettingsAdminRow = ({
  classes, data, handleChangeCheckBox, handleChangeRadio,
}) => (

  <Grid container item xs={12} className={classes.listRow}>
    <Grid item xs={12} sm={6}>
      <FormControlLabel
        control={(
          <Checkbox
            color="primary"
            name={data.adminId.toString()}
            checked={data.checked}
            onChange={handleChangeCheckBox}
          />
)}
        label={data.name}
      />

    </Grid>
    <Grid item xs={12} sm={5} className={classes.alignCenter}>
      <FormControlLabel
        value="end"
        control={(
          <Radio
            checked={data.isMainManager}
            onChange={handleChangeRadio}
            value={data.adminId}
            name={data.adminId.toString()}
            inputProps={{ 'aria-label': 'Seleccionar ejecutivo principal' }}
            disabled={!data.checked}
          />
)}
        label={data.isMainManager && 'Ejecutivo principal'}
      />
    </Grid>
  </Grid>
);

export default ClientSettingsAdminRow;
