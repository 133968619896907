const styles = (theme) => ({
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
  },
  exteriorSection: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(-8),
    marginRight: theme.spacing(-8),
    paddingTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(-2),
      marginRight: theme.spacing(-2),
    },
  },
  insideSection: {
    marginLeft: theme.spacing(8),
    marginRight: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.down('xs')]: {
      paddingBottom: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },

});

export default styles;
