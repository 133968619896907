import { useCallback, useState } from 'react';

function deconstructEvent(event, date) {
  if (event.persist) event.persist();
  let value = null;
  const { target, currentTarget } = event;
  const { id: currentTargetId } = currentTarget;
  const {
    type, files, checked, value: targetValue, id: targetId,
  } = target;
  if (type === 'file') {
    const { 0: newValue } = files;
    value = newValue;
  } else if (type === 'checkbox') {
    value = checked;
  } else {
    value = type === 'date' || type === 'time' ? date : targetValue;
  }
  const id = type ? targetId : currentTargetId;
  return { id, value };
}
const useForm = (controls, actions, options) => {
  const [errors, setErrors] = useState({});
  if (actions?.controlsChanged == null && options?.onChange == null) {
    throw new Error('Parameters are missing change callback');
  }

  const isValidValue = useCallback(
    (id, value) => options.validations[id] == null
      || options.validations[id].every((validation) => validation.check(value)),
    [options],
  );

  const validate = useCallback(
    (id, value) => {
      let isValid = true;
      const newErrors = [];
      options.validations[id].forEach((validation) => {
        const isVal = validation.check(value);
        if (!isVal) {
          isValid = false;
          newErrors.push(validation.message ?? 'Campo inválido');
        }
      });
      setErrors((state) => ({ ...state, [id]: newErrors.length === 0 ? undefined : newErrors }));

      return isValid;
    },
    [options.validations],
  );

  const validateAll = useCallback(() => {
    const ids = Object.keys(options.validations);
    let isValid = true;
    const newErrors = {};
    ids.forEach((id) => {
      const value = controls[id];
      options.validations[id].forEach((validation) => {
        const isVal = validation.check(value);
        if (!isVal) {
          if (newErrors[id] == null) {
            newErrors[id] = [];
          }
          isValid = false;
          newErrors[id].push(validation.message ?? 'Campo inválido');
        }
      });
    });
    setErrors(newErrors);
    return isValid;
  }, [options.validations, controls]);

  const onChange = useCallback(
    (event, format, useformat = true) => {
      const hasControlsChanged = actions && 'controlsChanged' in actions;
      const { id, value } = deconstructEvent(event, null); // TODO: Pass date
      const formattedValue = useformat && format ? format(value) : value;

      if (id == null) {
        throw new Error('Input is missing id');
      }

      // Either validate on change or clear errors if it is valid
      if (options?.validateOnChange) {
        validate(id, formattedValue);
      } else if (isValidValue(id, formattedValue)) {
        setErrors((errs) => ({ ...errs, [id]: undefined }));
      }

      if (hasControlsChanged) {
        actions.controlsChanged({ [id]: formattedValue });
      } else {
        options.onChange(id, formattedValue);
      }
    },
    [options, actions, isValidValue, validate],
  );

  const onSubmit = useCallback(
    (callback) => {
      const valid = validateAll();
      if (valid) {
        callback();
      }
    },
    [validateAll],
  );

  const resetErrors = useCallback(() => {
    setErrors({});
  }, [setErrors]);

  return {
    errors, onChange, onSubmit, resetErrors,
  };
};

export default useForm;
